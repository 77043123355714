import {
  Avatar,
  CardContent,
  createTheme,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
  makeStyles,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import MUIDataTable from "mui-datatables";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import TemplateDisplay from "../Components/TemplateDisplay.tsx";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from "@mui/material/DialogContent";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";

import {
  createParentPageContentRequest,
  createChildPageContentRequest,
  createContentDetailWithLanguageRequest,
  createContentDetailRequest,
  createImageDetailRequest,
  createVideoDetailRequest,
  createAudioDetailRequest,
  createPDFDetailRequest,
  updateContentDetailRequest,
  updateImageDetailRequest,
  updateVideoDetailRequest,
  updateAudioDetailRequest,
  updatePDFDetailRequest,
} from "../Graphql/request.ts";

import PhotoCamera from "@mui/icons-material/PhotoCamera";
import EditorDraft from "../Components/EditorDraft.tsx";
import API from "src/app/helper/ApiHelper.ts";

import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading.js";
import AlertDialog from "../Components/Alert.tsx";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UploadIcon from "@mui/icons-material/Upload";
import { condense } from "../graphql.ts";
import jwtDecode from "jwt-decode";
import { updatePageContent } from "../Functions/updateContent.ts";

import { showMessage } from "app/store/fuse/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import compressImage from "browser-image-compression";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import PushPinIcon from '@mui/icons-material/PushPin';
// import { PushPinIcon as NotPushedPinIcon } from '@mui/icons-material/PushPinOutlined';
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";

type NewCardArr = {
  newCardID: string;
};

const PagesContent: React.FC = () => {
  const dispatch = useDispatch();

  let { id } = useParams();
  // const API = require("../../../../api.config.json");
  const templateList = require("../../../../template.json");

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const moduleNumber = urlParams.get("module");
  const sub = urlParams.get("sub");

  const token = Cookies.get("jwt_authorization") || "not_login";
  const endPoint = API.local;
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  const [languageIDList, setLanguageIDList] = useState([] as any);

  const [newCardArr, setNewCardArr] = useState([] as NewCardArr[]);

  async function getLangLabel() {
    const request = `{languages{
      lang
      index
      id
    }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query ${request}`,
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        setLanguageIDList(res.data.data.languages);
      })
      .catch((err) => console.error("err", err));
  }


  const [master, setMaster] = useState(false);
  const [role, setRole] = useState("");
  const [session, setSession] = useState() as any;
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [sessionList, setSessionList] = useState([]);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  // Alert Pop up
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [popUpMsg, setPopUpMsg] = useState("");



  let updateSession: any;
  let updateSessionList: any;
  //[section] format date
  function dateFormat(date: string) {
    if (date == "" || !date) return ["", ""]
    let formatDate: any[] = [];
    const [day, month, year] = date.split("/");

    const formattedDate = new Date(+year, parseInt(month) - 1, +day);

    const monthName = new Intl.DateTimeFormat("en", { month: "short" }).format(
      formattedDate
    );

    const formattedDateEn = `${day} ${monthName} ${year}`;
    const formattedDateZh = `${year}年${month}月${day}日`;
    formatDate.push(formattedDateEn);
    formatDate.push(formattedDateZh);

    return formatDate;
  }

  function dateIntoEngFormatting(inputDate: string) {
    const parsedDate = new Date(inputDate); // Parse the input date string

    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1 and pad with leading zeros if necessary
    const day = String(parsedDate.getDate()).padStart(2, "0"); // Pad the day with leading zeros if necessary

    return `${year}/${month}/${day}`;
  }

  //[section] JSON obj formatting
  async function checkChildOrParent() {
    setIsLoading(true);
    if (token != "not_login") {
      let userInfo: any = jwtDecode(token);
      if (userInfo) {
        setRole(userInfo.role);
      }
    }
    // console.log(id);
    if (id === undefined) {
      if (moduleNumber) {
        id = `Module${moduleNumber}`;
      } else {
        id = "";
      }
    }

    const check = `{menuBar(where: $where){
        title
    }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: condense(`query Query($where: MenuBarWhereUniqueInput!) ${check}`),
      variables: {
        where: {
          title: id,
        },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        if (!res.data.data.menuBar) {
          const request = `{childMenu(where: $where){
            id,
            childContent(orderBy: $orderBy) {
              title
              session
              template
              images(orderBy: $imagesOrderBy2,where:$imagesWhere2) {
                url
                priority
                category
                position
                id
                title
                type
                isDeleted
              }
              id
              contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
                priority
                id
                content
                category
                position
                title
                type
                langLabel {
                  lang
                  index
                  id
                }
                isDeleted
              }
              videos(orderBy: $videosOrderBy2, where: $videosWhere2) {
                url
                priority
                position
                category
                title
                id
                type
                isDeleted
              }
              audios(orderBy: $audiosOrderBy2, where: $audiosWhere2) {
                url
                priority
                position
                category
                title
                id
                type
                isDeleted
              }
              pdf(orderBy: $pdfOrderBy2, where: $pdfWhere2){
                originalFileName
                position
                priority
                title
                url
                category
                id
                type
                isDeleted
              }
              sessionImage
            }
          }}`;
          const graphqlQuery = {
            operationName: "Query",
            query:
              condense(`query Query($where: ChildMenuWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!,$contentsWhere2: ContentDetailWhereInput!, $imagesOrderBy2: [ImageDetailOrderByInput!]!,$imagesWhere2:ImageDetailWhereInput!,
               $audiosOrderBy2: [AudioDetailOrderByInput!]!,$audiosWhere2: AudioDetailWhereInput!, $videosOrderBy2: [VideoDetailOrderByInput!]!,$videosWhere2: VideoDetailWhereInput!, $pdfOrderBy2: [PdfDetailOrderByInput!]!,$pdfWhere2: PdfDetailWhereInput) ${request}`),
            variables: {
              where: { title: id },
              contentsWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              imagesWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              videosWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              audiosWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              pdfWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              orderBy: [
                {
                  session: "asc",
                },
              ],
              contentsOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              imagesOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              audiosOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              videosOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              pdfOrderBy2: [
                {
                  priority: "asc",
                },
              ],
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              console.log(res.data.data.childMenu);

              // console.log(res);
              updateSession = res.data.childMenu;
              setSession(res.data.data.childMenu);
              setMaster(false);
              if (moduleNumber) {
                id = `Module${+moduleNumber + 1}`;
              }
              if (sub != null) {
                // console.log(res.data.data.childMenu.childContent);
                for (
                  let i = res.data.data.childMenu.childContent.length - 1;
                  i >= 0;
                  i--
                ) {
                  if (res.data.data.childMenu.childContent[i].title != sub) {
                    res.data.data.childMenu.childContent.splice(i, 1);
                  }
                }
              }

              let pageContentArr = [] as any;
              res.data.data.childMenu.childContent.forEach(
                (sessionElement: any, sessionIDX: number) => {
                  let totalCardIDArr = [];
                  let totalCardTreeIDArr = [];
                  let totalTreeIDArr = [];
                  let combineContentsArr = [];
                  sessionElement.contents.forEach(
                    (contentElement: any, contentIDX: number) => {
                      contentElement.originalContent = contentElement.content;
                      combineContentsArr.push(contentElement);
                    }
                  );
                  sessionElement.images.forEach(
                    (imageElement: any, imageIDX: number) => {
                      // console.log(imageElement);
                      combineContentsArr.push({
                        category: imageElement.category,
                        id: imageElement.id,
                        priority: imageElement.priority,
                        title: imageElement.title,
                        type: imageElement.type,
                        url: `${API.getImage}${imageElement.url}`,
                        previewUrl: "",
                      });
                    }
                  );
                  sessionElement.videos.forEach(
                    (videoElement: any, imageIDX: number) => {
                      combineContentsArr.push({
                        category: videoElement.category,
                        id: videoElement.id,
                        priority: videoElement.priority,
                        title: videoElement.title,
                        type: videoElement.type,
                        url: videoElement.url,
                        previewUrl: videoElement.url,
                      });
                    }
                  );
                  sessionElement.audios.forEach(
                    (audioElement: any, imageIDX: number) => {
                      combineContentsArr.push({
                        category: audioElement.category,
                        id: audioElement.id,
                        priority: audioElement.priority,
                        title: audioElement.title,
                        type: audioElement.type,
                        url: `${API.getAudio}${audioElement.url}`,
                        previewUrl: "",
                      });
                    }
                  );
                  sessionElement.pdf.forEach(
                    (pdfElement: any, pdfIDX: number) => {
                      combineContentsArr.push({
                        category: pdfElement.category,
                        id: pdfElement.id,
                        priority: pdfElement.priority,
                        title: pdfElement.title,
                        type: pdfElement.type,
                        url: `${API.getPDF}${pdfElement.url}`,
                        originalFileName: pdfElement.originalFileName,
                        previewUrl: "",
                      });
                    }
                  );

                  let sortCardArr = [];
                  let sortCardTreeArr = [];
                  let sortTreeArr = [];

                  combineContentsArr.forEach((combineElement: any) => {
                    if (
                      combineElement.category.includes("card") &&
                      !combineElement.category.includes("@@") &&
                      combineElement.position != "cardPosition"
                    ) {
                      // console.log(combineElement);
                      sortCardArr.push(combineElement);
                    } else if (
                      combineElement.category.includes("@@") &&
                      combineElement.category.includes("treeView") &&
                      combineElement.position != "cardPosition"
                    ) {
                      sortCardTreeArr.push(combineElement);
                    } else if (
                      combineElement.category.includes("treeView") &&
                      !combineElement.category.includes("@@") &&
                      combineElement.position != "cardPosition"
                    ) {
                      // console.log("here");
                      sortTreeArr.push(combineElement);
                    }
                  });
                  // console.log(sortCardTreeArr);
                  let cardPriority = null;
                  let cardTreePriority = null;
                  let treePriority = null;
                  if (sortTreeArr[0]) {
                    treePriority = sortTreeArr[0].priority - 1;
                  }
                  if (sortCardArr[0]) {
                    cardPriority = sortCardArr[0].priority - 1;
                  }
                  if (sortCardTreeArr[0]) {
                    // console.log(sortCardTreeArr);
                    cardTreePriority = sortCardTreeArr[0].priority - 1;
                  }
                  let cardTreeCount = sortCardTreeArr.reduce((acc, cur) => {
                    if (!acc[cur.category]) {
                      acc[cur.category] = 1;
                    } else {
                      acc[cur.category]++;
                    }
                    return acc;
                  }, {});
                  // console.log(cardTreeCount);
                  let treeCount = sortTreeArr.reduce((acc, cur) => {
                    if (!acc[cur.category]) {
                      acc[cur.category] = 1;
                    } else {
                      acc[cur.category]++;
                    }
                    return acc;
                  }, {});

                  let count = sortCardArr.reduce((acc, cur) => {
                    if (!acc[cur.category]) {
                      acc[cur.category] = 1;
                    } else {
                      acc[cur.category]++;
                    }
                    return acc;
                  }, {});
                  Object.keys(count).forEach((e) => {

                    totalCardIDArr.push({
                      category: e,
                      id: "",
                      priority: e.split("_card_")[1].split("*")[0],
                    });
                  });
                  Object.keys(cardTreeCount).forEach((e) => {
                    // console.log(e);
                    totalCardTreeIDArr.push({
                      category: e,
                      id: "",
                      priority: +e.split("_treeView_")[1].split("*")[0],
                    });
                  });
                  Object.keys(treeCount).forEach((e) => {
                    // console.log(e);
                    totalTreeIDArr.push({
                      category: e,
                      id: "",
                      priority: treePriority,
                    });
                  });
                  // console.log(totalCardTreeIDArr);
                  let sortedCombineContentArr = [];
                  sessionElement.contents.forEach((e: any, idx: number) => {
                    if (e.category === id) {
                      sortedCombineContentArr.push(e);
                    }
                  });
                  sessionElement.images.forEach((e: any, idx: number) => {
                    if (e.category === id) {
                      sortedCombineContentArr.push({
                        category: e.category,
                        id: e.id,
                        priority: e.priority,
                        title: e.title,
                        type: e.type,
                        url: `${API.getImage}${e.url}`,
                        previewUrl: "",
                      });
                    }
                  });
                  sessionElement.videos.forEach((e: any, idx: number) => {
                    if (e.category === id) {

                      sortedCombineContentArr.push({
                        category: e.category,
                        id: e.id,
                        priority: e.priority,
                        title: e.title,
                        type: e.type,
                        url: e.url,
                        previewUrl: e.url,
                      });
                    }
                  });
                  sessionElement.audios.forEach((e: any, idx: number) => {
                    if (e.category === id) {

                      sortedCombineContentArr.push({
                        category: e.category,
                        id: e.id,
                        priority: e.priority,
                        title: e.title,
                        type: e.type,
                        url: `${API.getAudio}${e.url}`,
                        previewUrl: "",
                      });
                    }
                  });
                  totalTreeIDArr.forEach((treeElement: any, idx: number) => {
                    sortedCombineContentArr.push({
                      category: treeElement.category,
                      id: treeElement.id,
                      priority: treeElement.priority,
                      type: "treeView",
                      treeContents: [],
                    });
                  });
                  // console.log(
                  //   "sortedCombineContentArr",
                  //   sortedCombineContentArr
                  // );
                  totalCardIDArr.forEach((cardElement: any, idx: number) => {

                    sortedCombineContentArr.push({
                      category: cardElement.category,
                      id: cardElement.id,
                      priority: cardElement.priority,
                      type: "card",
                      cardContent: [],
                      expand: false,
                      minTitle: "",
                      minImage: "",
                      cardPositionID: "",
                      cardPosition: null,
                    });
                  });
                  // console.log(totalCardIDArr);

                  // totalTreeIDArr.forEach(
                  //   (treeElement: any, treeElementIdx: number) => {
                  //     sortedCombineContentArr.forEach((e: any, idx: number) => {
                  //       if (treeElement.category === e.category) {
                  //         sortedCombineContentArr[idx].treeContents.push(
                  //           treeElement
                  //         );
                  //       }
                  //     });
                  //   }
                  // );
                  let existedCount = 0;
                  // console.log(
                  //   "sortedCombineContentArr",
                  //   sortedCombineContentArr
                  // );
                  let nonExistedArr = [];
                  totalCardTreeIDArr.forEach((e: any, idx: number) => {
                    sortedCombineContentArr.forEach(
                      (sortedContent: any, sortedIDX: number) => {

                        // console.log(existedCount);
                        if (
                          sortedContent.category === e.category.split("@@")[0]
                        ) {
                          existedCount++;
                        } else {
                          nonExistedArr.push(e);
                          // if (existedCount > 0) {
                          // } else if (existedCount === 0) {
                          //   sortedCombineContentArr.push({
                          //     category: e.category.split("@@")[0],
                          //     id: e.id,
                          //     priority: e.priority,
                          //     type: "card",
                          //     cardContent: [],
                          //   });
                          //   existedCount++;
                          // }
                          // existedCount++;
                        }
                      }
                    );
                  });
                  let totalCardTreeArr = [];
                  // console.log(nonExistedArr);
                  let treeCardCount = nonExistedArr.reduce((acc, cur) => {
                    if (!acc[cur.category]) {
                      acc[cur.category] = 1;
                    } else {
                      acc[cur.category]++;
                    }
                    return acc;
                  }, {});
                  Object.keys(treeCardCount).forEach((e) => {
                    // console.log(e);
                    // console.log(cardTreePriority);
                    totalCardTreeArr.push({
                      category: e,
                      id: "",
                      priority: cardTreePriority,
                    });
                  });
                  if (existedCount === 0) {
                    totalCardTreeArr.forEach((e: any, idx: number) => {

                      sortedCombineContentArr.push({
                        category: e.category.split("@@")[0],
                        id: e.id,
                        priority: e.priority,
                        position: e?.position,
                        type: "card",
                        cardContent: [],
                        expand: false,
                        minTitle: "",
                        minImage: "",
                        cardPositionID: "",
                        cardPosition: null,
                      });
                    });
                  }
                  // console.log(existedCount);
                  // console.log("totalCardTreeIDArr", totalCardTreeIDArr);
                  totalCardTreeIDArr.forEach(
                    (cardTreeElement: any, idx: number) => {

                      sortedCombineContentArr.forEach(
                        (cardElement: any, cardIDX: number) => {
                          if (
                            cardTreeElement.category.split("@@")[0] ===
                            cardElement.category
                          ) {
                            sortedCombineContentArr[cardIDX].cardContent.push({
                              category: cardTreeElement.category,
                              content: "",
                              id: "",
                              language: "",
                              position: "",
                              priority: cardTreeElement.priority,
                              title: "",
                              treeContents: [],
                              type: "treeView",
                            });
                          }
                        }
                      );
                    }
                  );
                  // console.log(
                  //   "sortedCombineContentArr",
                  //   sortedCombineContentArr
                  // );
                  let contentsArr = [];
                  combineContentsArr.sort((a, b) => a.priority - b.priority);
                  // console.log(sortedCombineContentArr);
                  combineContentsArr.forEach((e: any, idx: number) => {
                    sortedCombineContentArr.forEach(
                      (element: any, cardIDX: number) => {

                        if (
                          e.category === element.category &&
                          e.category.includes("card")
                        ) {
                          if (
                            sortedCombineContentArr[cardIDX].minTitle === "" &&
                            e.type === "text"
                          ) {
                            sortedCombineContentArr[cardIDX].minTitle =
                              e.content;
                          }
                          if (
                            sortedCombineContentArr[cardIDX].minImage === "" &&
                            e.type === "image"
                          ) {
                            sortedCombineContentArr[cardIDX].minImage = e.url;
                          }
                          if (e.position === "cardPosition") {
                            sortedCombineContentArr[cardIDX].cardPosition =
                              +e.content;
                            sortedCombineContentArr[cardIDX].cardPositionID =
                              e.id;
                          }
                          if (e.position != "cardPosition") {
                            sortedCombineContentArr[cardIDX].cardContent.push(
                              e
                            );
                          }
                        } else if (
                          e.category === element.category &&
                          e.category.includes("treeView") &&
                          !e.category.includes("@@")
                        ) {

                          sortedCombineContentArr[cardIDX].treeContents.push(e);
                        }
                        // } else if (
                        //   !e.category.includes("@@") ||
                        //   !e.category.includes("card")
                        // ) {
                        //   contentsArr.push(e);
                        // }
                      }
                    );
                  });

                  // console.log(contentsArr);
                  // console.log("combineContentsArr", combineContentsArr);
                  // console.log(
                  //   "sortedCombineContentArr",
                  //   sortedCombineContentArr
                  // );

                  combineContentsArr.forEach((e: any, idx: number) => {
                    sortedCombineContentArr.forEach(
                      (element: any, cardIDX: number) => {

                        if (element.cardContent) {
                          element.cardContent.forEach(
                            (
                              cardContentElement: any,
                              cardContentID: number
                            ) => {
                              if (
                                cardContentElement.treeContents &&
                                cardContentElement.category === e.category &&
                                cardContentElement.category.includes("@@") &&
                                cardContentElement.category.includes("treeView")
                              ) {
                                cardContentElement.treeContents.push(e);
                              }
                            }
                          );
                        }
                      }
                    );
                  });

                  contentsArr.forEach((e: any) => {
                    sortedCombineContentArr.push(e);
                  });
                  sortedCombineContentArr.sort(
                    (a, b) => a.priority - b.priority
                  );
                  sortedCombineContentArr.forEach((e: any) => {
                    if (e.cardContent) {
                      e.cardContent.sort(
                        (a: any, b: any) => a.priority - b.priority
                      );
                      e.cardContent.forEach((cardElement: any) => {
                        if (cardElement.treeContents) {
                          cardElement.treeContents.sort(
                            (a: any, b: any) => a.priority - b.priority
                          );
                        }
                      });
                    }
                    if (e.treeContents) {
                      e.treeContents.sort(
                        (a: any, b: any) => a.priority - b.priority
                      );
                    }
                  });
                  // console.log("combineContentsArr", combineContentsArr);

                  // console.log(
                  //   "sortedCombineContentArr",
                  //   sortedCombineContentArr
                  // );
                  // combineContentsArr.forEach((e: any, idx: number) => {
                  //   if (e.category.includes("card")) {
                  //     sortedCombineContentArr.push({});
                  //   }
                  // });
                  // if(id === "Publicity") {
                  //   sortedCombineContentArr.forEach((e:any, idx:number)=>{
                  //     if(e.type === "card"){
                  //       e.cardContent.forEach((cardElement:any, cardIDX:number)=>{
                  //         if(cardElement.includes("treeView")){
                  //           let title = ""
                  //           if(cardElement.treeContents.forEach((treeElement:any,treeIDX:number)=>{
                  //             if(treeElement.type === "video"){
                  //               title =
                  //             }
                  //           }))
                  //         }
                  //       })
                  //     }
                  //   })
                  // }
                  if (id === "Publicity") {
                    sortedCombineContentArr.forEach((e: any, idx: number) => {
                      if (e.type === "card") {
                        e.cardContent.forEach(
                          (cardElement: any, cardIDX: number) => {
                            let title = "";
                            if (cardElement.category.includes("treeView")) {
                              cardElement.treeContents.forEach(
                                (treeElement: any, treeIDX: number) => {
                                  if (treeElement.type === "video") {
                                    title = "Video Session";
                                  } else if (treeElement.type === "image") {
                                    title = "Image Session";
                                  } else if (treeElement.type === "audio") {
                                    title = "Audio Session";
                                  }
                                }
                              );
                              if (cardElement.treeContents.length === 20) {
                                title = "Paragraph Session";
                              }
                              cardElement.title = title;
                            }
                          }
                        );
                      }
                    });
                  }
                  let subSessionTitle = "";
                  if (sub === "Reading Material") {
                    sortedCombineContentArr.forEach((e: any, idx: number) => {
                      if (e.type === "card") {
                        e.cardContent.forEach(
                          (cardElement: any, cardIDX: number) => {
                            let title = "";
                            if (cardElement.category.includes("treeView")) {
                              cardElement.treeContents.forEach(
                                (treeElement: any, treeIDX: number) => {
                                  if (treeElement.position === "subTitle") {
                                    subSessionTitle = treeElement.content;
                                  }
                                  title = `Sub Session - ${subSessionTitle}`;
                                }
                              );
                              cardElement.title = title;
                            }
                          }
                        );
                      }
                    });
                  }
                  if (sessionElement.title === "Recruitment") {
                    sessionElement.title = "Positions Available";
                  }

                  sortedCombineContentArr.sort((a, b) => {
                    if (a.cardPosition && b.cardPosition) {
                      return b.cardPosition - a.cardPosition;
                    }
                  });

                  pageContentArr.push({
                    audios: sessionElement.audios,
                    cardID: totalCardIDArr,
                    combineContents: sortedCombineContentArr,
                    contents: sessionElement.contents,
                    id: sessionElement.id,
                    images: sessionElement.images,
                    session: sessionElement.session,
                    sessionImage: "",
                    template: "",
                    title: sessionElement.title,
                    videos: sessionElement.videos,
                  });
                }
              );

              // console.log(pageContentArr);

              // if (id === "Training") {
              //   pageContentArr.forEach((e: any) => {
              //     // console.log(e);
              //   });
              // }
              updateSessionList = pageContentArr;
              setSessionList(pageContentArr);
              setIsLoading(false);

            })
            .catch((err) => console.error("err", err));
        } else {
          const request = `{menuBar(where: $where) {
            id,
            title,
            parentContent(orderBy: $orderBy) {
              title
              session
              template
              images(orderBy: $imagesOrderBy2,where:$imagesWhere2) {
                url
                priority
                position
                category
                id
                title
                type
                isDeleted
              }
              id
              contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
                priority
                id
                content
                category
                position
                title
                type
                langLabel {
                  lang
                  index
                  id
                }
                isDeleted
              }
              videos(orderBy: $videosOrderBy2, where: $videosWhere2) {
                url
                priority
                position
                category
                title
                id
                type
                isDeleted
              }
              audios(orderBy: $audiosOrderBy2, where: $audiosWhere2) {
                url
                priority
                position
                category
                title
                id
                type
                isDeleted
              }
              pdf(orderBy: $pdfOrderBy2, where: $pdfWhere2){
                originalFileName
                position
                priority
                title
                url
                category
                id
                type
                isDeleted
              }
              sessionImage
            }
            }
        }`;
          const graphqlQuery = {
            operationName: "Query",
            query: condense(
              `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!,$contentsWhere2: ContentDetailWhereInput!, $imagesOrderBy2: [ImageDetailOrderByInput!]!,$imagesWhere2:ImageDetailWhereInput!,
                $audiosOrderBy2: [AudioDetailOrderByInput!]!,$audiosWhere2: AudioDetailWhereInput!, $videosOrderBy2: [VideoDetailOrderByInput!]!,$videosWhere2: VideoDetailWhereInput!, $pdfOrderBy2: [PdfDetailOrderByInput!]!,$pdfWhere2: PdfDetailWhereInput!) ${request}`
            ),
            variables: {
              where: { title: id },
              contentsWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              imagesWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              videosWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              audiosWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              pdfWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              orderBy: [
                {
                  session: "asc",
                },
              ],
              contentsOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              imagesOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              audiosOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              videosOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              pdfOrderBy2: [
                {
                  priority: "asc",
                },
              ],
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              setMaster(true);
              updateSession = res.data.data.menuBar;
              setSession(res.data.data.menuBar);
              let pinnedArr = []
              // console.log(res.data.data.menuBar);
              let pageContentArr = [] as any;
              res.data.data.menuBar.parentContent.forEach(
                (sessionElement: any, sessionIDX: number) => {

                  let totalCardIDArr = [];
                  let totalCardTreeIDArr = [];
                  let totalTreeIDArr = [];
                  let combineContentsArr = [];
                  sessionElement.contents.forEach(
                    (contentElement: any, contentIDX: number) => {
                      contentElement.originalContent = contentElement.content;
                      if (contentElement.position === "pinned" && contentElement.content == "true") {
                        // add pinned card into pinnedArr
                        pinnedArr.push(contentElement)
                      }
                      combineContentsArr.push(contentElement);
                    }
                  );

                  sessionElement.images.forEach(
                    (imageElement: any, imageIDX: number) => {
                      // console.log(imageElement);
                      combineContentsArr.push({
                        category: imageElement.category,
                        id: imageElement.id,
                        priority: imageElement.priority,
                        title: imageElement.title,
                        type: imageElement.type,
                        url: `${API.getImage}${imageElement.url}`,
                        previewUrl: "",
                      });
                    }
                  );
                  sessionElement.videos.forEach(
                    (videoElement: any, imageIDX: number) => {
                      combineContentsArr.push({
                        category: videoElement.category,
                        id: videoElement.id,
                        priority: videoElement.priority,
                        title: videoElement.title,
                        type: videoElement.type,
                        url: videoElement.url,
                        previewUrl: videoElement.url,
                      });
                    }
                  );
                  sessionElement.audios.forEach(
                    (audioElement: any, imageIDX: number) => {
                      combineContentsArr.push({
                        category: audioElement.category,
                        id: audioElement.id,
                        priority: audioElement.priority,
                        title: audioElement.title,
                        type: audioElement.type,
                        url: `${API.getAudio}${audioElement.url}`,
                        previewUrl: "",
                      });
                    }
                  );
                  sessionElement.pdf.forEach(
                    (pdfElement: any, pdfIDX: number) => {
                      combineContentsArr.push({
                        category: pdfElement.category,
                        id: pdfElement.id,
                        priority: pdfElement.priority,
                        title: pdfElement.title,
                        type: pdfElement.type,
                        url: `${API.getPDF}${pdfElement.url}`,
                        originalFileName: pdfElement.originalFileName,
                        previewUrl: "",
                      });
                    }
                  );

                  let sortCardArr = [];
                  let sortCardTreeArr = [];
                  let sortTreeArr = [];
                  // console.log("cherererere", combineContentsArr);
                  combineContentsArr.forEach((combineElement: any) => {
                    if (
                      combineElement.category.includes("card") &&
                      !combineElement.category.includes("@@") &&
                      combineElement.position != "cardPosition"
                    ) {
                      // console.log(combineElement);
                      sortCardArr.push(combineElement);
                    } else if (
                      combineElement.category.includes("@@") &&
                      combineElement.category.includes("treeView") &&
                      combineElement.position != "cardPosition"
                    ) {
                      sortCardTreeArr.push(combineElement);
                    } else if (
                      combineElement.category.includes("treeView") &&
                      !combineElement.category.includes("@@") &&
                      combineElement.position != "cardPosition"
                    ) {
                      // console.log("here");
                      sortTreeArr.push(combineElement);
                    }
                  });
                  // console.log(sortCardTreeArr);
                  let cardPriority = null;
                  let cardTreePriority = null;
                  let treePriority = null;
                  if (sortTreeArr[0]) {
                    treePriority = sortTreeArr[0].priority - 1;
                  }
                  if (sortCardArr[0]) {
                    cardPriority = sortCardArr[0].priority - 1;
                  }
                  if (sortCardTreeArr[0]) {
                    // console.log(sortCardTreeArr);
                    cardTreePriority = sortCardTreeArr[0].priority - 1;
                  }
                  let cardTreeCount = sortCardTreeArr.reduce((acc, cur) => {
                    if (!acc[cur.category]) {
                      acc[cur.category] = 1;
                    } else {
                      acc[cur.category]++;
                    }
                    return acc;
                  }, {});
                  // console.log(cardTreeCount);
                  let treeCount = sortTreeArr.reduce((acc, cur) => {
                    if (!acc[cur.category]) {
                      acc[cur.category] = 1;
                    } else {
                      acc[cur.category]++;
                    }
                    return acc;
                  }, {});

                  let count = sortCardArr.reduce((acc, cur) => {
                    if (!acc[cur.category]) {
                      acc[cur.category] = 1;
                    } else {
                      acc[cur.category]++;
                    }
                    return acc;
                  }, {});
                  Object.keys(count).forEach((e) => {
                    // console.log(e);
                    totalCardIDArr.push({
                      category: e,
                      id: "",
                      priority: e.split("_card_")[1].split("*")[0]
                    });
                  });
                  Object.keys(cardTreeCount).forEach((e) => {
                    // console.log(e);
                    totalCardTreeIDArr.push({
                      category: e,
                      id: "",
                      priority: +e.split("_treeView_")[1].split("*")[0],
                    });
                  });
                  Object.keys(treeCount).forEach((e) => {
                    // console.log(e);
                    totalTreeIDArr.push({
                      category: e,
                      id: "",
                      priority: treePriority,
                    });
                  });
                  // console.log(totalCardTreeIDArr);
                  let sortedCombineContentArr = [];
                  sessionElement.contents.forEach((e: any, idx: number) => {
                    if (e.category === id) {
                      sortedCombineContentArr.push(e);
                    }
                  });
                  sessionElement.images.forEach((e: any, idx: number) => {
                    if (e.category === id) {
                      sortedCombineContentArr.push({
                        category: e.category,
                        id: e.id,
                        priority: e.priority,
                        title: e.title,
                        type: e.type,
                        url: `${API.getImage}${e.url}`,
                        previewUrl: "",
                        realposition: e?.position
                      });
                    }
                  });
                  sessionElement.videos.forEach((e: any, idx: number) => {
                    if (e.category === id) {
                      sortedCombineContentArr.push({
                        category: e.category,
                        id: e.id,
                        priority: e.priority,
                        title: e.title,
                        type: e.type,
                        url: e.url,
                        previewUrl: e.url,
                      });
                    }
                  });
                  sessionElement.audios.forEach((e: any, idx: number) => {
                    if (e.category === id) {
                      sortedCombineContentArr.push({
                        category: e.category,
                        id: e.id,
                        priority: e.priority,
                        title: e.title,
                        type: e.type,
                        url: `${API.getAudio}${e.url}`,
                        previewUrl: "",
                      });
                    }
                  });
                  totalTreeIDArr.forEach((treeElement: any, idx: number) => {
                    sortedCombineContentArr.push({
                      category: treeElement.category,
                      id: treeElement.id,
                      priority: treeElement.priority,
                      type: "treeView",
                      treeContents: [],
                    });
                  });
                  // console.log(
                  //   "sortedCombineContentArr",
                  //   sortedCombineContentArr
                  // );
                  totalCardIDArr.forEach((cardElement: any, idx: number) => {
                    sortedCombineContentArr.push({
                      category: cardElement.category,
                      id: cardElement.id,
                      priority: cardElement.priority,
                      type: "card",
                      cardContent: [],
                      expand: false,
                      minTitle: "",
                      minImage: "",
                      cardPosition: null,
                      cardPositionID: "",
                    });
                  });
                  // console.log(totalCardIDArr);

                  // totalTreeIDArr.forEach(
                  //   (treeElement: any, treeElementIdx: number) => {
                  //     sortedCombineContentArr.forEach((e: any, idx: number) => {
                  //       if (treeElement.category === e.category) {
                  //         sortedCombineContentArr[idx].treeContents.push(
                  //           treeElement
                  //         );
                  //       }
                  //     });
                  //   }
                  // );
                  let existedCount = 0;
                  // console.log(
                  //   "sortedCombineContentArr",
                  //   sortedCombineContentArr
                  // );
                  let nonExistedArr = [];
                  totalCardTreeIDArr.forEach((e: any, idx: number) => {
                    sortedCombineContentArr.forEach(
                      (sortedContent: any, sortedIDX: number) => {
                        // console.log(existedCount);
                        if (
                          sortedContent.category === e.category.split("@@")[0]
                        ) {
                          existedCount++;
                        } else {
                          nonExistedArr.push(e);
                          // if (existedCount > 0) {
                          // } else if (existedCount === 0) {
                          //   sortedCombineContentArr.push({
                          //     category: e.category.split("@@")[0],
                          //     id: e.id,
                          //     priority: e.priority,
                          //     type: "card",
                          //     cardContent: [],
                          //   });
                          //   existedCount++;
                          // }
                          // existedCount++;
                        }
                      }
                    );
                  });
                  let totalCardTreeArr = [];
                  // console.log(nonExistedArr);
                  let treeCardCount = nonExistedArr.reduce((acc, cur) => {
                    if (!acc[cur.category]) {
                      acc[cur.category] = 1;
                    } else {
                      acc[cur.category]++;
                    }
                    return acc;
                  }, {});
                  Object.keys(treeCardCount).forEach((e) => {
                    // console.log(e);
                    // console.log(cardTreePriority);
                    totalCardTreeArr.push({
                      category: e,
                      id: "",
                      priority: cardTreePriority,
                    });
                  });
                  if (existedCount === 0) {
                    totalCardTreeArr.forEach((e: any, idx: number) => {
                      sortedCombineContentArr.push({
                        category: e.category.split("@@")[0],
                        id: e.id,
                        priority: e.priority,
                        type: "card",
                        cardContent: [],
                      });
                    });
                  }
                  // console.log(existedCount);
                  // console.log("totalCardTreeIDArr", totalCardTreeIDArr);
                  totalCardTreeIDArr.forEach(
                    (cardTreeElement: any, idx: number) => {
                      sortedCombineContentArr.forEach(
                        (cardElement: any, cardIDX: number) => {
                          if (
                            cardTreeElement.category.split("@@")[0] ===
                            cardElement.category &&
                            cardTreeElement.position != "cardPosition"
                          ) {
                            sortedCombineContentArr[cardIDX].cardContent.push({
                              category: cardTreeElement.category,
                              content: "",
                              id: "",
                              language: "",
                              position: "",
                              priority: cardTreeElement.priority,
                              title: "",
                              treeContents: [],
                              type: "treeView",
                            });
                          }
                        }
                      );
                    }
                  );
                  // console.log(
                  //   "sortedCombineContentArr",
                  //   sortedCombineContentArr
                  // );
                  let contentsArr = [];

                  combineContentsArr.sort((a, b) => a.priority - b.priority);
                  // console.log(sortedCombineContentArr);
                  combineContentsArr.forEach((e: any, idx: number) => {
                    sortedCombineContentArr.forEach(
                      (element: any, cardIDX: number) => {
                        if (
                          e.category === element.category &&
                          e.category.includes("card")
                        ) {
                          if (
                            sortedCombineContentArr[cardIDX].minTitle === "" &&
                            e.type === "text"
                          ) {
                            sortedCombineContentArr[cardIDX].minTitle =
                              e.content;
                          }
                          if (
                            sortedCombineContentArr[cardIDX].minImage === "" &&
                            e.type === "image"
                          ) {
                            sortedCombineContentArr[cardIDX].minImage = e.url;
                          }
                          if (e.position === "cardPosition") {
                            sortedCombineContentArr[cardIDX].cardPosition =
                              +e.content;
                            sortedCombineContentArr[cardIDX].cardPositionID =
                              e.id;
                          }
                          if (e.position != "cardPosition") {
                            sortedCombineContentArr[cardIDX].cardContent.push(
                              e
                            );
                          }
                        } else if (
                          e.category === element.category &&
                          e.category.includes("treeView") &&
                          !e.category.includes("@@")
                        ) {
                          sortedCombineContentArr[cardIDX].treeContents.push(e);
                        }
                        // } else if (
                        //   !e.category.includes("@@") ||
                        //   !e.category.includes("card")
                        // ) {
                        //   contentsArr.push(e);
                        // }
                      }
                    );
                  });

                  combineContentsArr.forEach((e: any, idx: number) => {
                    sortedCombineContentArr.forEach(
                      (element: any, cardIDX: number) => {
                        if (element.cardContent) {
                          element.cardContent.forEach(
                            (
                              cardContentElement: any,
                              cardContentID: number
                            ) => {
                              if (
                                cardContentElement.treeContents &&
                                cardContentElement.category === e.category &&
                                cardContentElement.category.includes("@@") &&
                                cardContentElement.category.includes("treeView")
                              ) {
                                cardContentElement.treeContents.push(e);
                              }
                            }
                          );
                        }
                      }
                    );
                  });

                  contentsArr.forEach((e: any) => {
                    sortedCombineContentArr.push(e);
                  });
                  sortedCombineContentArr.sort(
                    (a, b) => a.priority - b.priority
                  );
                  sortedCombineContentArr.forEach((e: any) => {
                    if (e.cardContent) {
                      e.cardContent.sort(
                        (a: any, b: any) => a.priority - b.priority
                      );
                      e.cardContent.forEach((cardElement: any) => {
                        if (cardElement.treeContents) {
                          cardElement.treeContents.sort(
                            (a: any, b: any) => a.priority - b.priority
                          );
                        }
                      });
                    }
                    if (e.treeContents) {
                      e.treeContents.sort(
                        (a: any, b: any) => a.priority - b.priority
                      );
                    }
                  });

                  if (id === "Publicity") {
                    sortedCombineContentArr.forEach((e: any, idx: number) => {
                      if (e.type === "card") {
                        e.cardContent.forEach(
                          (cardElement: any, cardIDX: number) => {
                            let title = "";
                            if (cardElement.category.includes("treeView")) {
                              cardElement.treeContents.forEach(
                                (treeElement: any, treeIDX: number) => {
                                  if (treeElement.type === "video") {
                                    title = "Video Session";
                                  } else if (treeElement.type === "image") {
                                    title = "Image Session";
                                  } else if (treeElement.type === "audio") {
                                    title = "Audio Session";
                                  }
                                }
                              );
                              if (cardElement.treeContents.length === 20) {
                                title = "Paragraph Session";
                              }
                              cardElement.title = title;

                            }

                          }
                        );
                      }
                      const filteredDateObj = e?.cardContent?.filter(el =>
                        el?.position === "date"
                      )
                      e.dateForPinSection = filteredDateObj?.length > 0 ? dateFormat(filteredDateObj[0]?.content)?.[0] : null
                    });
                  }
                  if (sessionElement.title === "Publicity Main Title") {
                    sessionElement.title = "News & Events";
                  } else if (sessionElement.title === "Publicity News") {
                    sessionElement.title = "What's new?";
                  } else if (sessionElement.title === "Publicity Radio") {
                    sessionElement.title = "Broadcast Interview";
                  } else if (
                    sessionElement.title === "Publicity Broadcast Interview"
                  ) {
                    sessionElement.title = "Press Releases";
                  }
                  sortedCombineContentArr.sort((a, b) => {
                    if (a.cardPosition && b.cardPosition) {
                      return b.cardPosition - a.cardPosition;
                    }
                  });

                  pageContentArr.push({
                    audios: sessionElement.audios,
                    cardID: totalCardIDArr,
                    combineContents: sortedCombineContentArr,
                    contents: sessionElement.contents,
                    id: sessionElement.id,
                    images: sessionElement.images,
                    session: sessionElement.session,
                    sessionImage: "",
                    template: "",
                    title: sessionElement.title,
                    videos: sessionElement.videos
                  });
                }
              );
              // console.log(id);
              // console.log(pageContentArr);
              if (id === "Training") {
                pageContentArr.forEach((e: any, index: number) => {
                  if (index === 1 || index === 2) {
                    e.combineContents.forEach((element: any, idx: number) => {
                      if (element.type === "card") {
                        element.cardContent.forEach(
                          (cardElement: any, treeIDX: number) => {
                            if (cardElement.type === "treeView") {
                              if (cardElement.treeContents.length === 8) {
                                cardElement.title = "More Info Session";
                              }
                              if (cardElement.treeContents.length === 4) {
                                cardElement.title = "Training Sub Session";
                              }
                            }
                          }
                        );
                      }
                    });
                  } else if (index === 3) {
                    e.combineContents.forEach((element: any, idx: number) => {
                      if (element.type === "card") {
                        element.cardContent.forEach(
                          (cardElement: any, treeIDX: number) => {
                            if (cardElement.type === "treeView") {
                              if (treeIDX === 0) {
                                cardElement.title = "Session 1 With Image";
                              }
                              if (treeIDX === 1) {
                                cardElement.title = "Session 2 With Video";
                              }
                              if (treeIDX === 2) {
                                cardElement.title = "Session 3 With Image";
                              }
                              if (treeIDX === 3) {
                                cardElement.title = "Session 4 With Video";
                              }
                            }
                          }
                        );
                      }
                    });
                  }
                });
              }
              if (id === "Research") {
                pageContentArr.forEach((e: any, index: number) => {
                  if (index === 1) {
                    e.combineContents.forEach((element: any, idx: number) => {
                      if (element.type === "card") {
                        element.cardContent.forEach(
                          (cardElement: any, treeIDX: number) => {
                            if (cardElement.type === "treeView") {
                              if (cardElement.treeContents.length === 11) {
                                cardElement.title = "Project Summary";
                              }
                              if (cardElement.treeContents.length === 22) {
                                cardElement.title = "Abstracts";
                              }
                              if (
                                cardElement.treeContents.length === 6 &&
                                treeIDX === 9
                              ) {
                                cardElement.title = "Publication";
                              }
                              if (
                                cardElement.treeContents.length === 6 &&
                                treeIDX === 10
                              ) {
                                cardElement.title = "Deliverables";
                              }
                            }
                          }
                        );
                      }
                    });
                  }
                });
              }

              updateSessionList = pageContentArr;
              // is pinned cards' arr
              let pinnedCardsArr = []

              let nonePinCardsArr = pageContentArr
                ?.slice(1, 4)
                ?.map((el: any) => {
                  let comebineContents = el?.combineContents;
                  //get all sessionID and store it
                  comebineContents?.map((contentEl: any) => { contentEl.sessionID = el.id })
                  return comebineContents;
                })
                ?.filter((contents: any) => {
                  return contents !== undefined;
                })
                ?.flat()
                ?.filter((contents: any) => {
                  return contents.type === 'card';
                })
                ?.map((contents: any, idx: number) => {
                  const isPinnedObject = pinnedArr?.find((item: any) => item.category === contents.category && item.position === "pinned"); //see if current card is pinned position
                  if (isPinnedObject) {
                    pinnedCardsArr.push({ ...contents, pin: true, sessionListCardIDX: idx, position: isPinnedObject ? "pinned" : "", pinnedId: isPinnedObject?.id ?? "", priority: isPinnedObject?.priority ?? contents.priority })
                    return null
                  } else {
                    return { ...contents, pin: true, sessionListCardIDX: idx, position: isPinnedObject ? "pinned" : "", pinnedId: isPinnedObject?.id ?? "", priority: isPinnedObject?.priority ?? contents.priority };
                  }
                })
                ?.filter((contents: any) => contents !== null) // Filter out null values
                ?.sort((a: any, b: any) => {
                  //sort according to descending date
                  if (a.dateForPinSection === '') return 1;
                  if (b.dateForPinSection === '') return -1;

                  const dateForPinSectionA = new Date(a.dateForPinSection);
                  const dateForPinSectionB = new Date(b.dateForPinSection);

                  // Compare the dateForPinSections
                  if (dateForPinSectionA > dateForPinSectionB) return -1;
                  if (dateForPinSectionA < dateForPinSectionB) return 1;

                  return 0;
                })

              // sort pinned cards according to priority
              pinnedCardsArr.sort((a, b) => {
                if (a.priority < b.priority) {
                  return -1; // a comes before b
                } else if (a.priority > b.priority) {
                  return 1; // a comes after b
                }
              })
                ;

              //[section] only allow pintotop section at publicity section
              if (id === "Publicity") {
                const pinToTopCombineContents = [{
                  title: "Pin to top",
                  type: "textWithoutField"
                }, {
                }
                  // {
                  //   category: "Publicity",
                  //   content: "置頂",
                  //   id: "pinToTop",
                  //   isDeleted: false,
                  //   langLabel: [{ lang: 'zh_hant', index: 1, id: 'pinToTopZH' }],
                  //   originalContent: "置頂",
                  //   position: "pageTitle",
                  //   priority: 5,
                  //   title: "Page Title (繁)",
                  //   type: "text",
                  // },
                  // {
                  //   category: "Publicity",
                  //   content: "Pin To Top",
                  //   id: "pinToTop",
                  //   isDeleted: false,
                  //   langLabel: [{ lang: 'zh_hant', index: 1, id: 'pinToTopZH' }],
                  //   originalContent: "Pin To Top",
                  //   position: "pageTitle",
                  //   priority: 5,
                  //   title: "Page Title (En)",
                  //   type: "text",
                  // }
                ]

                pageContentArr.push(
                  {
                    audio: [],
                    cardID: [],
                    combineContents: pinToTopCombineContents.concat(pinnedCardsArr.concat(nonePinCardsArr ?? []) ?? []),
                    contents: [{
                      title: "Pin to top",
                      type: "textWithoutField"
                    }, {}
                      // {
                      //   category: "Publicity",
                      //   content: "置頂",
                      //   id: "pinToTop",
                      //   isDeleted: false,
                      //   langLabel: [{ lang: 'zh_hant', index: 1, id: 'pinToTopZH' }],
                      //   originalContent: "置頂",
                      //   position: "pageTitle",
                      //   priority: 5,
                      //   title: "Page Title (繁)",
                      //   type: "text",
                      // },
                      // {
                      //   category: "Publicity",
                      //   content: "Pin To Top",
                      //   id: "pinToTop",
                      //   isDeleted: false,
                      //   langLabel: [{ lang: 'zh_hant', index: 1, id: 'pinToTopZH' }],
                      //   originalContent: "Pin To Top",
                      //   position: "pageTitle",
                      //   priority: 5,
                      //   title: "Page Title (En)",
                      //   type: "text",
                      // }
                    ],
                    id: 'manualAddNoID',
                    images: [],
                    pdf: [],
                    session: 5,
                    sessionImage: "",
                    template: '',
                    title: "Pin to top",
                    videos: []
                  })
              }



              setSessionList(pageContentArr);
              setIsLoading(false);
            })
            .catch((err) => console.error("err", err));
        }
      })
      .catch((err) => console.error(err));
  }
  const [modified, setModified] = useState(false);

  const [parentID, setParentID] = useState("");

  const [deleteModify, setDeleteModify] = useState(false);

  useEffect(() => {
    setExpanded([]);
  }, [id]);

  useEffect(() => {
    if (
      id === "Get Involved" ||
      id === "Recruitment" ||
      id === "Cooperation" ||
      id === "Volunteer" ||
      id === "Position Available"
    ) {
      setParentID("Get Involved");
    } else {
      setParentID("");
    }

    checkChildOrParent();
    getLangLabel();
  }, [id, deleteModify]);

  function openDialog() {
    setIsOpen(true);
  }

  function handleCloseDetailsDialog() {
    setIsOpen(false);
  }

  function addSession() {
    let sessionNumber: number;
    if (sessionList.length > 0) {
      sessionNumber = sessionList[sessionList.length - 1].session + 1;
      // console.log("here", sessionNumber);
    } else {
      sessionNumber = sessionList.length + 1;
    }
    setSessionList([
      ...sessionList,
      {
        id: `newCreateSession_${sessionNumber}`,
        contents: [],
        images: [],
        audios: [],
        videos: [],
        session: sessionNumber,
        sessionImage: "",
        template: "",
        title: sessionTitle,
        combineContents: [],
        cardID: [],
      },
    ]);
    updateSession = [
      ...updateSession,
      {
        id: `newCreateSession_${sessionNumber}`,
        contents: [],
        images: [],
        audios: [],
        videos: [],
        session: sessionNumber,
        sessionImage: "",
        template: "",
        title: sessionTitle,
        combineContents: [],
        cardID: [],
      },
    ];


  }

  const setRawHTML = (idx: number, elements: any) => (html: string) => {
    setModified(!modified);
    // console.log(html);
    // console.log(idx);
    // console.log(elements);
    elements.content = html;
    // console.log(elements);
  };

  // for checking update process done or not
  function checkValues(values: any[]) {
    return new Promise<void>((resolve, reject) => {
      if (values.every((value) => value === true)) {
        resolve();
      }
    });
  }

  const handleFileSelect = (elements: any) => async (event: any) => {
    console.log(elements);

    const options = { maxSizeMB: 1, maxWidthOrHeight: 1500 };
    let file: any;
    if (elements.type === "image" || elements.type == "icon") {
      file = await compressImage(event.target.files[0], options);
    } else {
      file = event.target.files[0];
    }
    elements.url = file;
    const reader = new FileReader();

    reader.onload = () => {
      elements.previewUrl = reader.result;
      dispatch(
        showMessage({
          message: `Selected File is not Save`,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "warning",
        })
      );
      setModified(!modified);
    };

    reader.readAsDataURL(file);
  };

  // to create a dialog to pop up while add content to define position & title & language
  const [openCreate, setOpenCreate] = useState(false);
  const [contentPosition, setContentPosition] = useState("");
  const [contentTitle, setContentTitle] = useState("");
  const [contentLanguage, setContentLanguage] = useState("");
  const [contentType, setContentType] = useState("");
  const [selectedSession, setSelectedSession] = useState(0);
  const [currentCardID, setCurrentCardID] = useState("");
  const [treeID, setTreeID] = useState("");
  const [treeIDX, setTreeIDX] = useState(0);
  const [cardTreeID, setCardTreeID] = useState("");
  const [cardTreeIDX, setCardTreeIDX] = useState(null as number);
  const [cardIDX, setCardIDX] = useState(null as number);
  const [cardContentIDX, setCardContentIDX] = useState(null as number);
  const [selectedCardSessionIDX, setSelectedCardSessionIDX] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCountEng, setTotalCountEng] = useState(0);

  function openCreateDialog(number: number, card?: string) {
    if (card) {
      setCurrentCardID(card);
    } else {
      setCurrentCardID("");
    }
    setTreeID("");
    setCardTreeID("");
    setSelectedSession(number);
    setContentPosition("");
    setContentTitle("");
    setContentLanguage("");
    setContentType("");
    setOpenCreate(true);
  }

  function openTreeDialog(number: number, treeID: string) {
    setTreeID(treeID);
    setTreeIDX(number);
    setCardTreeID("");
    setCurrentCardID("");
    setContentPosition("");
    setContentTitle("");
    setContentLanguage("");
    setContentType("");
    setOpenCreate(true);
  }

  function openCardTreeDialog(
    number: number,
    treeID: string,
    cardIDX: number,
    cardContentIDX: number
  ) {
    // console.log(number);
    setCurrentCardID("");
    setTreeID("");
    setCardTreeID(treeID);
    setCardTreeIDX(number);
    setSelectedSession(number);
    setCardIDX(cardIDX);
    setCardContentIDX(cardContentIDX);
    setContentPosition("");
    setContentTitle("");
    setContentLanguage("");
    setContentType("");
    setOpenCreate(true);
  }

  function closeCreateDialog() {
    setOpenCreate(false);
  }

  function handleContentType(event: any) {
    setContentType(event.target.value);
  }

  function handleContentLanguage(event: any) {
    setContentLanguage(event.target.value);
  }

  const contentTypeList = [
    { display: "Text", value: "text" },
    { display: "Word Editor", value: "wordEditor" },
    { display: "Image", value: "image" },
    { display: "Video", value: "video" },
    { display: "Audio", value: "audio" },
    { display: "Card", value: "card" },
    { display: "Icon", value: "icon" },
    { display: "Tree View", value: "treeView" },
  ];

  const languageList = [
    { display: "繁中", value: "zh_hant" },
    { display: "En", value: "en" },
  ];

  function addSessionContent(sessionIDX: number, contentType: string) {
    if (id === undefined) {
      if (moduleNumber) {
        id = `Module${+moduleNumber + 1}`;
      }
    }
    // console.log(id);
    setModified(!modified);

    // console.log(sessionIDX);
    // console.log(sessionList[sessionIDX]);

    let contentsLastPriority: number;
    let imagesLastPriority: number;
    let audiosLastPriority: number;
    let videosLastPriority: number;
    let cardLastPriority: number;

    if (sessionList[sessionIDX].contents.length > 0) {
      contentsLastPriority =
        sessionList[sessionIDX].contents[
          sessionList[sessionIDX].contents.length - 1
        ].priority;
    } else {
      contentsLastPriority = 0;
    }
    if (sessionList[sessionIDX].images.length > 0) {
      imagesLastPriority =
        sessionList[sessionIDX].images[
          sessionList[sessionIDX].images.length - 1
        ].priority;
    } else {
      imagesLastPriority = 0;
    }
    if (sessionList[sessionIDX].audios.length > 0) {
      audiosLastPriority =
        sessionList[sessionIDX].audios[
          sessionList[sessionIDX].audios.length - 1
        ].priority;
    } else {
      audiosLastPriority = 0;
    }
    if (sessionList[sessionIDX].videos.length > 0) {
      videosLastPriority =
        sessionList[sessionIDX].videos[
          sessionList[sessionIDX].videos.length - 1
        ].priority;
    } else {
      videosLastPriority = 0;
    }
    if (sessionList[sessionIDX].cardID.length > 0) {
      cardLastPriority =
        sessionList[sessionIDX].cardID[
          sessionList[sessionIDX].cardID.length - 1
        ].priority;
    } else {
      cardLastPriority = 0;
    }

    const nextPriority =
      Math.max(
        contentsLastPriority,
        imagesLastPriority,
        audiosLastPriority,
        videosLastPriority,
        cardLastPriority
      ) + 1;
    // currentCardID === "" mean this is not a card
    // console.log(currentCardID);
    if (currentCardID === "" && treeID === "" && cardTreeID === "") {
      if (contentType === "wordEditor") {
        sessionList[sessionIDX].contents.push({
          category: id,
          content: "",
          position: contentPosition,
          id: `newCreateWordEditor_${nextPriority}`,
          priority: nextPriority,
          type: "wordEditor",
          title: contentTitle,
          language: contentLanguage,
        });
        sessionList[sessionIDX].combineContents.push({
          category: id,
          content: "",
          position: contentPosition,
          id: `newCreateWordEditor_${nextPriority}`,
          priority: nextPriority,
          type: "wordEditor",
          title: contentTitle,
          language: contentLanguage,
        });
        // console.log(nextPriority);
      }
      if (contentType === "image") {
        sessionList[sessionIDX].images.push({
          category: id,
          id: `newCreateImage_${nextPriority}`,
          url: "",
          priority: nextPriority,
          position: contentPosition,
          title: contentTitle,
          type: "image",
        });
        sessionList[sessionIDX].combineContents.push({
          category: id,
          id: `newCreateImage_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "image",
          previewUrl: "",
          title: contentTitle,
          position: contentPosition,
        });
      }
      if (contentType === "icon") {
        sessionList[sessionIDX].images.push({
          category: id,
          id: `newCreateIcon_${nextPriority}`,
          url: "",
          priority: nextPriority,
          position: contentPosition,
          type: "icon",
          title: contentTitle,
        });
        sessionList[sessionIDX].combineContents.push({
          category: id,
          id: `newCreateIcon_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "icon",
          previewUrl: "",
          position: contentPosition,
          title: contentTitle,
        });
      }
      if (contentType === "audio") {
        sessionList[sessionIDX].audios.push({
          category: id,
          id: `newCreateAudio_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "audio",
          position: contentPosition,
          previewUrl: "",
          title: contentTitle,
        });
        sessionList[sessionIDX].combineContents.push({
          category: id,
          id: `newCreateAudio_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "audio",
          position: contentPosition,
          previewUrl: "",
          title: contentTitle,
        });
      }
      if (contentType === "video") {
        sessionList[sessionIDX].videos.push({
          category: id,
          id: `newCreateVideo_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "video",
          position: contentPosition,
          previewUrl: "",
          title: contentTitle,
        });
        sessionList[sessionIDX].combineContents.push({
          category: id,
          id: `newCreateVideo_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "video",
          position: contentPosition,
          previewUrl: "",
          title: contentTitle,
        });
      }
      if (contentType === "card") {
        sessionList[sessionIDX].cardID.push({
          category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
          id: `newCreateCard_${nextPriority}`,
          priority: nextPriority,
        });
        sessionList[sessionIDX].combineContents.push({
          category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
          id: `newCreateCard_${nextPriority}`,
          priority: nextPriority,
          type: "card",
          cardContent: [],
        });
      }
      if (contentType === "text") {
        sessionList[sessionIDX].contents.push({
          category: id,
          id: `newCreateText_${nextPriority}`,
          content: "",
          position: contentPosition,
          priority: nextPriority,
          type: "text",
          title: contentTitle,
          language: contentLanguage,
        });
        sessionList[sessionIDX].combineContents.push({
          category: id,
          id: `newCreateText_${nextPriority}`,
          content: "",
          position: contentPosition,
          priority: nextPriority,
          type: "text",
          title: contentTitle,
          language: contentLanguage,
        });
      }
      if (contentType === "treeView") {
        sessionList[sessionIDX].contents.push({
          category:
            id + "_treeView_" + nextPriority + "*" + +new Date(Date.now()),
          id: `newCreateTreeView_${nextPriority}`,
          content: "",
          position: contentPosition,
          priority: nextPriority,
          type: "treeView",
          title: contentTitle,
          language: contentLanguage,
        });
        sessionList[sessionIDX].combineContents.push({
          category:
            id + "_treeView_" + nextPriority + "*" + +new Date(Date.now()),
          id: `newCreateTreeView_${nextPriority}`,
          content: "",
          position: contentPosition,
          priority: nextPriority,
          type: "treeView",
          title: contentTitle,
          language: contentLanguage,
          treeContents: [],
        });
      }
    } else if (treeID != "") {
      sessionList[sessionIDX].combineContents[treeIDX];
      if (contentType === "text") {
        sessionList[sessionIDX].contents.push({
          category: treeID,
          id: `newCreateText_${nextPriority}`,
          content: "",
          position: contentPosition,
          priority: nextPriority,
          type: "text",
          title: contentTitle,
          language: contentLanguage,
        });
        sessionList[sessionIDX].combineContents[treeIDX].treeContents.push({
          category: treeID,
          id: `newCreateText_${nextPriority}`,
          content: "",
          position: contentPosition,
          priority: nextPriority,
          type: "text",
          title: contentTitle,
          language: contentLanguage,
        });
      }
      if (contentType === "wordEditor") {
        sessionList[sessionIDX].contents.push({
          category: treeID,
          content: "",
          position: contentPosition,
          id: `newCreateWordEditor_${nextPriority}`,
          priority: nextPriority,
          type: "wordEditor",
          title: contentTitle,
          language: contentLanguage,
        });
        sessionList[sessionIDX].combineContents[treeIDX].treeContents.push({
          category: treeID,
          content: "",
          position: contentPosition,
          id: `newCreateWordEditor_${nextPriority}`,
          priority: nextPriority,
          type: "wordEditor",
          title: contentTitle,
          language: contentLanguage,
        });
        // console.log(nextPriority);
      }
      if (contentType === "image") {
        sessionList[sessionIDX].images.push({
          category: treeID,
          id: `newCreateImage_${nextPriority}`,
          url: "",
          priority: nextPriority,
          position: contentPosition,
          title: contentTitle,
          type: "image",
        });
        sessionList[sessionIDX].combineContents[treeIDX].treeContents.push({
          category: treeID,
          id: `newCreateImage_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "image",
          previewUrl: "",
          position: contentPosition,
          title: contentTitle,
        });
      }
      if (contentType === "icon") {
        sessionList[sessionIDX].images.push({
          category: treeID,
          id: `newCreateIcon_${nextPriority}`,
          url: "",
          priority: nextPriority,
          position: contentPosition,
          title: contentTitle,

          type: "icon",
        });
        sessionList[sessionIDX].combineContents[treeIDX].treeContents.push({
          category: treeID,
          id: `newCreateIcon_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "icon",
          previewUrl: "",
          title: contentTitle,
          position: contentPosition,
        });
      }
      if (contentType === "audio") {
        sessionList[sessionIDX].audios.push({
          category: treeID,
          id: `newCreateAudio_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "audio",
          position: contentPosition,
          title: contentTitle,

          previewUrl: "",
        });
        sessionList[sessionIDX].combineContents[treeIDX].treeContents.push({
          category: treeID,
          id: `newCreateAudio_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "audio",
          position: contentPosition,
          title: contentTitle,
          previewUrl: "",
        });
      }
      if (contentType === "video") {
        sessionList[sessionIDX].videos.push({
          category: treeID,
          id: `newCreateVideo_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "video",
          position: contentPosition,
          title: contentTitle,
          previewUrl: "",
        });
        sessionList[sessionIDX].combineContents[treeIDX].treeContents.push({
          category: treeID,
          id: `newCreateVideo_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "video",
          position: contentPosition,
          title: contentTitle,
          previewUrl: "",
        });
      }
    } else if (cardTreeID != "") {
      // console.log(sessionList[sessionIDX].combineContents[cardIDX]);
      if (contentType === "text") {
        sessionList[sessionIDX].contents.push({
          category: cardTreeID,
          id: `newCreateText_${nextPriority}`,
          content: "",
          position: contentPosition,
          priority: nextPriority,
          type: "text",
          title: contentTitle,
          language: contentLanguage,
        });
        // console.log(sessionList[sessionIDX].combineContents);
        sessionList[sessionIDX].combineContents[cardIDX].cardContent[
          cardContentIDX
        ].treeContents.push({
          category: cardTreeID,
          id: `newCreateText_${nextPriority}`,
          content: "",
          position: contentPosition,
          priority: nextPriority,
          type: "text",
          title: contentTitle,
          language: contentLanguage,
        });
      }
      if (contentType === "wordEditor") {
        sessionList[sessionIDX].contents.push({
          category: cardTreeID,
          content: "",
          position: contentPosition,
          id: `newCreateWordEditor_${nextPriority}`,
          priority: nextPriority,
          type: "wordEditor",
          title: contentTitle,
          language: contentLanguage,
        });
        sessionList[sessionIDX].combineContents[cardIDX].cardContent[
          cardContentIDX
        ].treeContents.push({
          category: cardTreeID,
          content: "",
          position: contentPosition,
          id: `newCreateWordEditor_${nextPriority}`,
          priority: nextPriority,
          type: "wordEditor",
          title: contentTitle,
          language: contentLanguage,
        });
        // console.log(nextPriority);
      }
      if (contentType === "image") {
        sessionList[sessionIDX].images.push({
          category: cardTreeID,
          id: `newCreateImage_${nextPriority}`,
          url: "",
          priority: nextPriority,
          position: contentPosition,
          title: contentTitle,
          type: "image",
        });
        sessionList[sessionIDX].combineContents[cardIDX].cardContent[
          cardContentIDX
        ].treeContents.push({
          category: cardTreeID,
          id: `newCreateImage_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "image",
          previewUrl: "",
          position: contentPosition,
          title: contentTitle,
        });
      }
      if (contentType === "icon") {
        sessionList[sessionIDX].images.push({
          category: cardTreeID,
          id: `newCreateIcon_${nextPriority}`,
          url: "",
          priority: nextPriority,
          position: contentPosition,
          title: contentTitle,

          type: "icon",
        });
        sessionList[sessionIDX].combineContents[cardIDX].cardContent[
          cardContentIDX
        ].treeContents.push({
          category: cardTreeID,
          id: `newCreateIcon_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "icon",
          previewUrl: "",
          title: contentTitle,

          position: contentPosition,
        });
      }
      if (contentType === "audio") {
        sessionList[sessionIDX].audios.push({
          category: cardTreeID,
          id: `newCreateAudio_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "audio",
          position: contentPosition,
          title: contentTitle,

          previewUrl: "",
        });
        sessionList[sessionIDX].combineContents[cardIDX].cardContent[
          cardContentIDX
        ].treeContents.push({
          category: cardTreeID,
          id: `newCreateAudio_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "audio",
          position: contentPosition,
          title: contentTitle,
          previewUrl: "",
        });
      }
      if (contentType === "video") {
        sessionList[sessionIDX].videos.push({
          category: cardTreeID,
          id: `newCreateVideo_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "video",
          position: contentPosition,
          title: contentTitle,
          previewUrl: "",
        });
        sessionList[sessionIDX].combineContents[cardIDX].cardContent[
          cardContentIDX
        ].treeContents.push({
          category: cardTreeID,
          id: `newCreateVideo_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "video",
          position: contentPosition,
          title: contentTitle,
          previewUrl: "",
        });
      }
    } else {
      let currentCardIDX = 0;
      sessionList[sessionIDX].combineContents.forEach((e: any, idx: number) => {
        // console.log(e.category);
        // console.log(currentCardID);
        if (e.category === currentCardID) {
          currentCardIDX = idx;
          return;
        }
      });
      // console.log(sessionList[sessionIDX].combineContents[currentCardIDX]);

      if (contentType === "wordEditor") {
        sessionList[sessionIDX].contents.push({
          category: currentCardID,
          content: "",
          position: contentPosition,
          id: `newCreateWordEditor_${nextPriority}`,
          priority: nextPriority,
          type: "wordEditor",
          title: contentTitle,
          language: contentLanguage,
        });
        sessionList[sessionIDX].combineContents[
          currentCardIDX
        ].cardContent.push({
          category: currentCardID,
          content: "",
          position: contentPosition,
          id: `newCreateWordEditor_${nextPriority}`,
          priority: nextPriority,
          type: "wordEditor",
          title: contentTitle,
          language: contentLanguage,
        });
        // console.log(nextPriority);
      }
      if (contentType === "image") {
        sessionList[sessionIDX].images.push({
          category: currentCardID,
          id: `newCreateImage_${nextPriority}`,
          url: "",
          priority: nextPriority,
          position: contentPosition,
          title: contentTitle,
          type: "image",
        });
        sessionList[sessionIDX].combineContents[
          currentCardIDX
        ].cardContent.push({
          category: currentCardID,
          id: `newCreateImage_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "image",
          previewUrl: "",
          position: contentPosition,
          title: contentTitle,
        });
      }
      if (contentType === "icon") {
        sessionList[sessionIDX].images.push({
          category: currentCardID,
          id: `newCreateIcon_${nextPriority}`,
          url: "",
          priority: nextPriority,
          position: contentPosition,
          title: contentTitle,

          type: "icon",
        });
        sessionList[sessionIDX].combineContents[
          currentCardIDX
        ].cardContent.push({
          category: currentCardID,
          id: `newCreateIcon_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "icon",
          previewUrl: "",
          title: contentTitle,

          position: contentPosition,
        });
      }
      if (contentType === "audio") {
        sessionList[sessionIDX].audios.push({
          category: currentCardID,
          id: `newCreateAudio_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "audio",
          position: contentPosition,
          title: contentTitle,

          previewUrl: "",
        });
        sessionList[sessionIDX].combineContents[
          currentCardIDX
        ].cardContent.push({
          category: currentCardID,
          id: `newCreateAudio_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "audio",
          position: contentPosition,
          title: contentTitle,
          previewUrl: "",
        });
      }
      if (contentType === "video") {
        sessionList[sessionIDX].videos.push({
          category: currentCardID,
          id: `newCreateVideo_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "video",
          position: contentPosition,
          title: contentTitle,
          previewUrl: "",
        });
        sessionList[sessionIDX].combineContents[
          currentCardIDX
        ].cardContent.push({
          category: currentCardID,
          id: `newCreateVideo_${nextPriority}`,
          url: "",
          priority: nextPriority,
          type: "video",
          position: contentPosition,
          title: contentTitle,
          previewUrl: "",
        });
      }
      if (contentType === "treeView") {
        // console.log("here");
        sessionList[sessionIDX].contents.push({
          category:
            currentCardID +
            "@@" +
            `_treeView_${nextPriority}` +
            "*" +
            +new Date(Date.now()),
          id: `newCreateTreeView_${nextPriority}`,
          content: "",
          position: contentPosition,
          priority: nextPriority,
          type: "treeView",
          title: contentTitle,
          language: contentLanguage,
        });
        sessionList[sessionIDX].combineContents[
          currentCardIDX
        ].cardContent.push({
          category:
            currentCardID +
            "@@" +
            `_treeView_${nextPriority}` +
            "*" +
            +new Date(Date.now()),
          id: `newCreateTreeView_${nextPriority}`,
          content: "",
          position: contentPosition,
          priority: nextPriority,
          type: "treeView",
          title: contentTitle,
          language: contentLanguage,
          treeContents: [],
        });
      }
      if (contentType === "text") {
        sessionList[sessionIDX].contents.push({
          category: currentCardID,
          id: `newCreateText_${nextPriority}`,
          content: "",
          position: contentPosition,
          priority: nextPriority,
          type: "text",
          title: contentTitle,
          language: contentLanguage,
        });
        sessionList[sessionIDX].combineContents[
          currentCardIDX
        ].cardContent.push({
          category: currentCardID,
          id: `newCreateText_${nextPriority}`,
          content: "",
          position: contentPosition,
          priority: nextPriority,
          type: "text",
          title: contentTitle,
          language: contentLanguage,
        });
      }
    }
    sessionList[sessionIDX].combineContents.sort(
      (a: any, b: any) => a.priority - b.priority
    );
    // console.log(sessionList[sessionIDX]);
  }

  function createSessionContent() {
    // console.log("selectedSession", selectedSession);
    // console.log("contentPosition", contentPosition);
    // console.log("contentTitle", contentTitle);
    // console.log("contentLanguage", contentLanguage);
    // console.log("contentType", contentType);
    addSessionContent(selectedSession, contentType);
  }

  // for modify content in both combineArray & __Arr (depend on the type)
  function modifyContent(
    id: string,
    idx: number,
    type: string,
    content: string,
    sessionIDX?: number,
    cardIDX?: number,
    title?: string
  ) {
    // console.log("id", id);
    // console.log("idx", idx);
    // console.log("type", type);
    // console.log("content", content);
    // console.log("cardIDX", cardIDX);

    if (cardIDX != undefined) {
      // console.log("only card should be here");
      let newSession = [...sessionList];

      newSession[sessionIDX].combineContents[idx].cardContent[cardIDX].content =
        content;
      setSessionList(newSession);
      updateSessionList = newSession;
    } else {
      // console.log("the outer should be here");
      let newSession = [...sessionList];
      newSession[sessionIDX].combineContents[idx].content = content;
      setSessionList(newSession);
      updateSessionList = newSession;
    }
  }

  function modifyVideoContent(
    id: string,
    idx: number,
    type: string,
    url: string,
    sessionIDX?: number,
    cardIDX?: number
  ) {
    // console.log("id", id);
    // console.log("idx", idx);
    // console.log("type", type);
    // console.log("content", content);
    // console.log("cardIDX", cardIDX);
    // console.log(url.split("watch?v=")[1]);

    let youtubeID: string;
    if (url.includes("youtu.be")) {
      youtubeID = url.split("be/")[1];
    } else if (url.includes("embed/")) {
      youtubeID = url.split("embed/")[1];
    } else {
      youtubeID = url.split("watch?v=")[1];
    }
    if (youtubeID && youtubeID.includes("&list=")) {
      youtubeID = youtubeID.split("&list=")[0];
    } else if (youtubeID && youtubeID.includes("&")) {
      youtubeID = youtubeID.split("&")[0];
    }

    let newUrl: string;
    if (youtubeID) {
      newUrl = `https://www.youtube.com/embed/${youtubeID}`;
    } else {
      newUrl = "";
    }
    if (cardIDX != undefined) {
      // console.log("only card should be here");
      let newSession = [...sessionList];

      newSession[sessionIDX].combineContents[idx].cardContent[cardIDX].url =
        newUrl;
      newSession[sessionIDX].combineContents[idx].cardContent[
        cardIDX
      ].previewUrl = url;
      setSessionList(newSession);
      updateSessionList = newSession;
    } else {
      // console.log("the outer should be here");
      let newSession = [...sessionList];
      newSession[sessionIDX].combineContents[idx].url = newUrl;
      newSession[sessionIDX].combineContents[idx].previewUrl = url;
      setSessionList(newSession);
      updateSessionList = newSession;
    }
  }

  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: any, nodeIds: any) => {
    setSelected(nodeIds);
  };

  function modifyTreeContent(
    id: string,
    idx: number,
    type: string,
    content: string,
    sessionIDX: number,
    treeContentIDX: number,
    cardIDX?: number
  ) {
    if (cardIDX === undefined) {
      let newSession = [...sessionList];

      newSession[sessionIDX].combineContents[idx].treeContents[
        treeContentIDX
      ].content = content;
      setSessionList(newSession);
      updateSessionList = newSession;
    } else {
      let newSession = [...sessionList];
      // console.log(newSession[sessionIDX].combineContents[idx].cardContent);
      // console.log(cardIDX);
      // console.log(treeContentIDX);
      newSession[sessionIDX].combineContents[idx].cardContent[
        cardIDX
      ].treeContents[treeContentIDX].content = content;
      setSessionList(newSession);
      updateSessionList = newSession;
    }
  }

  function modifyTreeVideoContent(
    id: string,
    idx: number,
    type: string,
    content: string,
    sessionIDX: number,
    treeContentIDX: number,
    cardIDX?: number
  ) {
    let youtubeID: string;
    if (content.includes("youtu.be")) {
      youtubeID = content.split("be/")[1];
    } else if (content.includes("embed/")) {
      youtubeID = content.split("embed/")[1];
    } else {
      youtubeID = content.split("watch?v=")[1];
    }
    if (youtubeID && youtubeID.includes("&list=")) {
      youtubeID = youtubeID.split("&list=")[0];
    } else if (youtubeID && youtubeID.includes("&")) {
      youtubeID = youtubeID.split("&")[0];
    }

    let newUrl: string;
    if (!youtubeID) {
      newUrl = "";
    } else {
      newUrl = `https://www.youtube.com/embed/${youtubeID}`;
    }

    if (cardIDX === undefined) {
      let newSession = [...sessionList];

      newSession[sessionIDX].combineContents[idx].treeContents[
        treeContentIDX
      ].url = newUrl;

      newSession[sessionIDX].combineContents[idx].treeContents[
        treeContentIDX
      ].previewUrl = content;
      setSessionList(newSession);
      updateSessionList = newSession;
    } else {
      let newSession = [...sessionList];
      // console.log(newSession[sessionIDX].combineContents[idx].cardContent);
      // console.log(cardIDX);
      // console.log(treeContentIDX);
      newSession[sessionIDX].combineContents[idx].cardContent[
        cardIDX
      ].treeContents[treeContentIDX].url = newUrl;
      newSession[sessionIDX].combineContents[idx].cardContent[
        cardIDX
      ].treeContents[treeContentIDX].previewUrl = content;
      setSessionList(newSession);
      updateSessionList = newSession;
    }
  }

  async function delSession(id: string) {
    const request = `{deletePageContent(where: $where) {
      id
    }}`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($where: PageContentWhereUniqueInput!) ${request}`,
      variables: {
        where: { id: id },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => { })
      .catch((err) => console.error("err", err));
  }

  async function delContent(id: string) {
    const request = `{deleteContentDetail(where: $where) {
      id
    }}`;
    const graphqlQuery = {
      operationName: "DeleteContentDetail",
      query: `mutation DeleteContentDetail($where: ContentDetailWhereUniqueInput!) ${request}`,
      variables: {
        where: { id: id },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => { })
      .catch((err) => console.error("err", err));
  }

  function modifySessionPriority(id: string) {
    const request = `{
      updatePageContent(where: $where, data: $data) {
        session
        id
      }
    }`;
    const graphqlQuery = {
      operationName: "UpdatePageContent",
      query: `mutation UpdatePageContent ($where: PageContentWhereUniqueInput!, $data: PageContentUpdateInput!) ${request}`,
      variables: {
        where: { id: id },
        data: { session: 2 },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => { })
      .catch((err) => console.error("err", err));
  }

  async function modifyContentDetail(id: string) {
    const request = `{
      updateContentDetail(where: $where, data: $data) {
        type
        id
      }
    }`;
    const graphqlQuery = {
      operationName: "UpdateContentDetail",
      query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${request}`,
      variables: {
        where: { id: id },
        data: {
          type: "wordEditor",
        },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => { })
      .catch((err) => console.error("err", err));
  }

  // for open session dialog
  const [openCreateSession, setOpenCreateSession] = useState(false);
  const [sessionTitle, setSessionTitle] = useState("");

  const closeCreateSession = () => {
    setOpenCreateSession(false);
  };

  const openCreateSessionDialog = () => {
    setSessionTitle("");
    setOpenCreateSession(true);
  };

  function modifySessionTitle(id: string, title: string) {
    const request = `{
      updatePageContent(where: $where, data: $data) {
        id
        title
      }
    }`;
    const graphqlQuery = {
      operationName: "UpdatePageContent",
      query: `mutation UpdatePageContent($where: PageContentWhereUniqueInput!, $data: PageContentUpdateInput!) ${request}`,
      variables: {
        where: { id: id },
        data: { title: title },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => { })
      .catch((err) => console.error("err", err));
  }

  // to create a new card template
  function createPublicityCard(sessionIDX: number) {
    setModified(!modified);
    // console.log(sessionList);
    // console.log(sessionList[sessionIDX].combineContents);

    // to count the next priority
    let contentsLastPriority: number;
    let imagesLastPriority: number;
    let audiosLastPriority: number;
    let videosLastPriority: number;
    let cardLastPriority: number;

    const createTime = +new Date(Date.now());

    if (sessionList[sessionIDX].contents.length > 0) {
      contentsLastPriority =
        sessionList[sessionIDX].contents[
          sessionList[sessionIDX].contents.length - 1
        ].priority;
    } else {
      contentsLastPriority = 0;
    }
    if (sessionList[sessionIDX].images.length > 0) {
      imagesLastPriority =
        sessionList[sessionIDX].images[
          sessionList[sessionIDX].images.length - 1
        ].priority;
    } else {
      imagesLastPriority = 0;
    }
    if (sessionList[sessionIDX].audios.length > 0) {
      audiosLastPriority =
        sessionList[sessionIDX].audios[
          sessionList[sessionIDX].audios.length - 1
        ].priority;
    } else {
      audiosLastPriority = 0;
    }
    if (sessionList[sessionIDX].videos.length > 0) {
      videosLastPriority =
        sessionList[sessionIDX].videos[
          sessionList[sessionIDX].videos.length - 1
        ].priority;
    } else {
      videosLastPriority = 0;
    }
    if (sessionList[sessionIDX].cardID.length > 0) {
      cardLastPriority =
        sessionList[sessionIDX].cardID[
          sessionList[sessionIDX].cardID.length - 1
        ].priority;
    } else {
      cardLastPriority = 0;
    }

    // sum up all the current exist contents priority
    const nextPriority =
      Math.max(
        contentsLastPriority,
        imagesLastPriority,
        audiosLastPriority,
        videosLastPriority,
        cardLastPriority
      ) + 1;
    sessionList[sessionIDX].audios.push({
      category:
        id +
        "_card_" +
        nextPriority +
        "*" +
        +createTime +
        "@@" +
        `_treeView_${nextPriority + 21}` +
        "*" +
        +createTime +
        3333,
      id: `newCreateAudio_${nextPriority + 22} `,
      position: "audio",
      previewUrl: "",
      priority: nextPriority + 22,
      type: "audio",
      url: "",
    });
    sessionList[sessionIDX].cardID.push({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
    });
    sessionList[sessionIDX].images.push(
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateImage_${nextPriority + 1}`,
        url: "",
        priority: nextPriority + 1,
        previewUrl: "",
        position: "image",
        title: "Image",
        type: "image",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +createTime +
          2222,
        id: `newCreateImage_${nextPriority + 12}`,
        url: "",
        priority: nextPriority + 12,
        previewUrl: "",
        position: "image1",
        title: "Image1",
        type: "image",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +createTime +
          2222,
        id: `newCreateImage_${nextPriority + 15}`,
        url: "",
        priority: nextPriority + 15,
        previewUrl: "",
        position: "image2",
        title: "Image2",
        type: "image",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +createTime +
          2222,
        id: `newCreateImage_${nextPriority + 18}`,
        url: "",
        priority: nextPriority + 18,
        previewUrl: "",
        position: "image3",
        title: "Image3",
        type: "image",
      }
    );
    sessionList[sessionIDX].videos.push({
      category:
        id +
        "_card_" +
        nextPriority +
        "*" +
        +createTime +
        "@@" +
        `_treeView_${nextPriority + 5}` +
        "*" +
        +createTime +
        1111,
      id: `newCreateVideo_${nextPriority + 8}`,
      position: "video",
      previewUrl: "",
      priority: nextPriority + 8,
      title: "Video",
      type: "video",
      url: "",
    });
    sessionList[sessionIDX].contents.push(
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateText_${nextPriority + 2}`,
        content: "",
        priority: nextPriority + 2,
        language: "zh_hant",
        position: "title",
        title: "Title (繁)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateText_${nextPriority + 3}`,
        content: "",
        priority: nextPriority + 3,
        language: "zh_hant",
        position: "title",
        title: "Title (En)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateText_${nextPriority + 4}`,
        content: "",
        priority: nextPriority + 4,
        language: "",
        position: "date",
        title: "Date",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 5}` +
          "*" +
          +createTime +
          1111,
        id: `newCreateTree_${nextPriority + 5}`,
        content: "",
        priority: nextPriority + 5,
        language: "",
        position: "",
        title: "Video Session",
        treeContents: [],
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 5}` +
          "*" +
          +createTime +
          1111,
        id: `newCreateText_${nextPriority + 6}`,
        content: "",
        priority: nextPriority + 6,
        language: "zh_hant",
        position: "title",
        title: "Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 5}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 7}`,
        content: "",
        priority: nextPriority + 7,
        language: "en",
        position: "title",
        title: "Title (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 5}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 9}`,
        content: "",
        priority: nextPriority + 9,
        language: "zh_hant",
        position: "caption",
        title: "Caption (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 5}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 10}`,
        content: "",
        priority: nextPriority + 10,
        language: "en",
        position: "caption",
        title: "Caption (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateTree_${nextPriority + 11}`,
        content: "",
        priority: nextPriority + 11,
        language: "",
        position: "",
        title: "Image Session",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 13}`,
        content: "",
        priority: nextPriority + 13,
        language: "zh_hant",
        position: "caption1",
        title: "Caption 1 (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 14}`,
        content: "",
        priority: nextPriority + 14,
        language: "en",
        position: "caption1",
        title: "Caption 1 (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 16}`,
        content: "",
        priority: nextPriority + 16,
        language: "zh_hant",
        position: "caption2",
        title: "Caption 2 (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 17}`,
        content: "",
        priority: nextPriority + 17,
        language: "en",
        position: "caption2",
        title: "Caption 2 (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 19}`,
        content: "",
        priority: nextPriority + 19,
        language: "zh_hant",
        position: "caption3",
        title: "Caption 3 (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 20}`,
        content: "",
        priority: nextPriority + 20,
        language: "en",
        position: "caption3",
        title: "Caption 3 (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 21}` +
          "*" +
          +new Date(Date.now() + 3333),
        id: `newCreateTree_${nextPriority + 21}`,
        content: "",
        priority: nextPriority + 21,
        language: "",
        position: "",
        title: "Audio Session",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 21}` +
          "*" +
          +new Date(Date.now() + 3333),
        id: `newCreateText_${nextPriority + 23}`,
        content: "",
        priority: nextPriority + 23,
        language: "zh_hant",
        position: "caption",
        title: "Caption (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 21}` +
          "*" +
          +new Date(Date.now() + 3333),
        id: `newCreateText_${nextPriority + 24}`,
        content: "",
        priority: nextPriority + 24,
        language: "en",
        position: "caption",
        title: "Caption (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        id: `newCreateTree_${nextPriority + 25}`,
        content: "",
        priority: nextPriority + 25,
        language: "",
        position: "",
        title: "Paragraph Session",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 26}`,
        language: "zh_hant",
        position: "subTitle1",
        priority: nextPriority + 26,
        title: "Sub Title 1 (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 27}`,
        language: "en",
        position: "subTitle1",
        priority: nextPriority + 27,
        title: "Sub Title 1 (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 28}`,
        language: "zh_hant",
        position: "content1",
        priority: nextPriority + 28,
        title: "Content 1 (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 29}`,
        language: "en",
        position: "content1",
        priority: nextPriority + 29,
        title: "Content 1 (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 30}`,
        language: "zh_hant",
        position: "subTitle2",
        priority: nextPriority + 30,
        title: "Sub Title 2 (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 31}`,
        language: "en",
        position: "subTitle2",
        priority: nextPriority + 31,
        title: "Sub Title 2 (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 32}`,
        language: "zh_hant",
        position: "content2",
        priority: nextPriority + 32,
        title: "Content 2 (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 33}`,
        language: "en",
        position: "content2",
        priority: nextPriority + 33,
        title: "Content 2 (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 34}`,
        language: "zh_hant",
        position: "subTitle3",
        priority: nextPriority + 34,
        title: "Sub Title 3 (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 35}`,
        language: "en",
        position: "subTitle3",
        priority: nextPriority + 35,
        title: "Sub Title 3 (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 36}`,
        language: "zh_hant",
        position: "content3",
        priority: nextPriority + 36,
        title: "Content 3 (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 37}`,
        language: "en",
        position: "content3",
        priority: nextPriority + 37,
        title: "Content 3 (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 38}`,
        language: "zh_hant",
        position: "subTitle4",
        priority: nextPriority + 38,
        title: "Sub Title 4 (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 39}`,
        language: "en",
        position: "subTitle4",
        priority: nextPriority + 39,
        title: "Sub Title 4 (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 40}`,
        language: "zh_hant",
        position: "content4",
        priority: nextPriority + 40,
        title: "Content 4 (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 41}`,
        language: "en",
        position: "content4",
        priority: nextPriority + 41,
        title: "Content 4 (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 42}`,
        language: "zh_hant",
        position: "subTitle5",
        priority: nextPriority + 42,
        title: "Sub Title 5 (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 43}`,
        language: "en",
        position: "subTitle5",
        priority: nextPriority + 43,
        title: "Sub Title 5 (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 44}`,
        language: "zh_hant",
        position: "content5",
        priority: nextPriority + 44,
        title: "Content 5 (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 25}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 45}`,
        language: "en",
        position: "content5",
        priority: nextPriority + 45,
        title: "Content 5 (En)",
        type: "wordEditor",
      }
    );

    let newCardID = id + "_card_" + nextPriority + "*" + +createTime;

    let originalSessionContents = [] as any;

    for (
      let i = sessionList[sessionIDX].combineContents.length - 1;
      i >= 0;
      i--
    ) {
      if (sessionList[sessionIDX].combineContents[i].type != "card") {
        originalSessionContents.push(
          sessionList[sessionIDX].combineContents[i]
        );
        sessionList[sessionIDX].combineContents.splice(i, 1);
      }
    }

    sessionList[sessionIDX].combineContents.unshift({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateImage_${nextPriority + 1}`,
          url: "",
          priority: nextPriority + 1,
          previewUrl: "",
          position: "image",
          title: "Image",
          type: "image",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 2}`,
          content: "",
          priority: nextPriority + 2,
          language: "zh_hant",
          position: "title",
          title: "Title (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 3}`,
          content: "",
          priority: nextPriority + 3,
          language: "en",
          position: "title",
          title: "Title (En)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 4}`,
          content: "",
          priority: nextPriority + 4,
          language: "",
          position: "date",
          title: "Date",
          type: "text",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 5}` +
            "*" +
            +createTime +
            1111,
          id: `newCreateTree_${nextPriority + 5}`,
          content: "",
          priority: nextPriority + 5,
          language: "",
          position: "",
          title: "Video Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 5}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 6}`,
              content: "",
              priority: nextPriority + 6,
              language: "zh_hant",
              position: "title",
              title: "Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 5}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 7}`,
              content: "",
              priority: nextPriority + 7,
              language: "en",
              position: "title",
              title: "Title (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 5}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateVideo_${nextPriority + 8}`,
              position: "video",
              previewUrl: "",
              priority: nextPriority + 8,
              title: "Video",
              type: "video",
              url: "",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 5}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 9}`,
              content: "",
              priority: nextPriority + 9,
              language: "zh_hant",
              position: "caption",
              title: "Caption (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 5}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 10}`,
              content: "",
              priority: nextPriority + 10,
              language: "en",
              position: "caption",
              title: "Caption (En)",
              type: "text",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 11}` +
            "*" +
            +createTime +
            2222,
          id: `newCreateTree_${nextPriority + 11}`,
          content: "",
          priority: nextPriority + 11,
          language: "",
          position: "",
          title: "Image Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateImage_${nextPriority + 12}`,
              url: "",
              priority: nextPriority + 12,
              previewUrl: "",
              position: "image1",
              title: "Image1",
              type: "image",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 13}`,
              content: "",
              priority: nextPriority + 13,
              language: "zh_hant",
              position: "caption1",
              title: "Caption 1 (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 14}`,
              content: "",
              priority: nextPriority + 14,
              language: "en",
              position: "caption1",
              title: "Caption 1 (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateImage_${nextPriority + 15}`,
              url: "",
              priority: nextPriority + 15,
              previewUrl: "",
              position: "image2",
              title: "Image2",
              type: "image",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 16}`,
              content: "",
              priority: nextPriority + 16,
              language: "zh_hant",
              position: "caption2",
              title: "Caption 2 (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 17}`,
              content: "",
              priority: nextPriority + 17,
              language: "en",
              position: "caption2",
              title: "Caption 2 (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateImage_${nextPriority + 18}`,
              url: "",
              priority: nextPriority + 18,
              previewUrl: "",
              position: "image3",
              title: "Image3",
              type: "image",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 19}`,
              content: "",
              priority: nextPriority + 19,
              language: "zh_hant",
              position: "caption3",
              title: "Caption 3 (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 20}`,
              content: "",
              priority: nextPriority + 20,
              language: "en",
              position: "caption3",
              title: "Caption 3 (En)",
              type: "text",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 21}` +
            "*" +
            +createTime +
            3333,
          id: `newCreateTree_${nextPriority + 21}`,
          content: "",
          priority: nextPriority + 21,
          language: "",
          position: "",
          title: "Audio Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 21}` +
                "*" +
                +createTime +
                3333,
              id: `newCreateAudio_${nextPriority + 22} `,
              position: "audio",
              previewUrl: "",
              priority: nextPriority + 22,
              type: "audio",
              url: "",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 21}` +
                "*" +
                +createTime +
                3333,
              id: `newCreateText_${nextPriority + 23}`,
              content: "",
              priority: nextPriority + 23,
              language: "zh_hant",
              position: "caption",
              title: "Caption (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 21}` +
                "*" +
                +createTime +
                3333,
              id: `newCreateText_${nextPriority + 24}`,
              content: "",
              priority: nextPriority + 24,
              language: "en",
              position: "caption",
              title: "Caption (En)",
              type: "text",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 25}` +
            "*" +
            +createTime +
            5555,
          id: `newCreateTree_${nextPriority + 25}`,
          content: "",
          priority: nextPriority + 25,
          language: "",
          position: "",
          title: "Paragraph Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 26}`,
              language: "zh_hant",
              position: "subTitle1",
              priority: nextPriority + 26,
              title: "Sub Title 1 (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 27}`,
              language: "en",
              position: "subTitle1",
              priority: nextPriority + 27,
              title: "Sub Title 1 (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 28}`,
              language: "zh_hant",
              position: "content1",
              priority: nextPriority + 28,
              title: "Content 1 (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 29}`,
              language: "en",
              position: "content1",
              priority: nextPriority + 29,
              title: "Content 1 (En)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 30}`,
              language: "zh_hant",
              position: "subTitle2",
              priority: nextPriority + 30,
              title: "Sub Title 2 (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 31}`,
              language: "en",
              position: "subTitle2",
              priority: nextPriority + 31,
              title: "Sub Title 2 (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 32}`,
              language: "zh_hant",
              position: "content2",
              priority: nextPriority + 32,
              title: "Content 2 (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 33}`,
              language: "en",
              position: "content2",
              priority: nextPriority + 33,
              title: "Content 2 (En)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 34}`,
              language: "zh_hant",
              position: "subTitle3",
              priority: nextPriority + 34,
              title: "Sub Title 3 (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 35}`,
              language: "en",
              position: "subTitle3",
              priority: nextPriority + 35,
              title: "Sub Title 3 (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 36}`,
              language: "zh_hant",
              position: "content3",
              priority: nextPriority + 36,
              title: "Content 3 (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 37}`,
              language: "en",
              position: "content3",
              priority: nextPriority + 37,
              title: "Content 3 (En)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 38}`,
              language: "zh_hant",
              position: "subTitle4",
              priority: nextPriority + 38,
              title: "Sub Title 4 (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 39}`,
              language: "en",
              position: "subTitle4",
              priority: nextPriority + 39,
              title: "Sub Title 4 (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 40}`,
              language: "zh_hant",
              position: "content4",
              priority: nextPriority + 40,
              title: "Content 4 (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 41}`,
              language: "en",
              position: "content4",
              priority: nextPriority + 41,
              title: "Content 4 (En)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 42}`,
              language: "zh_hant",
              position: "subTitle5",
              priority: nextPriority + 42,
              title: "Sub Title 5 (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 43}`,
              language: "en",
              position: "subTitle5",
              priority: nextPriority + 43,
              title: "Sub Title 5 (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 44}`,
              language: "zh_hant",
              position: "content5",
              priority: nextPriority + 44,
              title: "Content 5 (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 25}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 45}`,
              language: "en",
              position: "content5",
              priority: nextPriority + 45,
              title: "Content 5 (En)",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
      ],
      cardPosition: nextPriority + nextPriority,
      cardPositionID: "newCreateCardPosition",
    });
    originalSessionContents.forEach((e: any) => {
      sessionList[sessionIDX].combineContents.unshift(e);
    });
    setNewCardArr([...newCardArr, { newCardID: newCardID }]);
  }

  function createResearchCard(sessionIDX: number) {
    setModified(!modified);
    // console.log(sessionList);
    // console.log("combineContents", sessionList[sessionIDX].combineContents);
    // to count the next priority
    let contentsLastPriority: number;
    let imagesLastPriority: number;
    let audiosLastPriority: number;
    let videosLastPriority: number;
    let cardLastPriority: number;

    const createTime = +new Date(Date.now());

    if (sessionList[sessionIDX].contents.length > 0) {
      contentsLastPriority =
        sessionList[sessionIDX].contents[
          sessionList[sessionIDX].contents.length - 1
        ].priority;
    } else {
      contentsLastPriority = 0;
    }
    if (sessionList[sessionIDX].images.length > 0) {
      imagesLastPriority =
        sessionList[sessionIDX].images[
          sessionList[sessionIDX].images.length - 1
        ].priority;
    } else {
      imagesLastPriority = 0;
    }
    if (sessionList[sessionIDX].audios.length > 0) {
      audiosLastPriority =
        sessionList[sessionIDX].audios[
          sessionList[sessionIDX].audios.length - 1
        ].priority;
    } else {
      audiosLastPriority = 0;
    }
    if (sessionList[sessionIDX].videos.length > 0) {
      videosLastPriority =
        sessionList[sessionIDX].videos[
          sessionList[sessionIDX].videos.length - 1
        ].priority;
    } else {
      videosLastPriority = 0;
    }
    if (sessionList[sessionIDX].cardID.length > 0) {
      cardLastPriority =
        sessionList[sessionIDX].cardID[
          sessionList[sessionIDX].cardID.length - 1
        ].priority;
    } else {
      cardLastPriority = 0;
    }

    // sum up all the current exist contents priority
    const nextPriority =
      Math.max(
        contentsLastPriority,
        imagesLastPriority,
        audiosLastPriority,
        videosLastPriority,
        cardLastPriority
      ) + 1;

    sessionList[sessionIDX].cardID.push({
      category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
    });
    sessionList[sessionIDX].images.push({
      category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
      id: `newCreateImage_${nextPriority + 1}`,
      url: "",
      priority: nextPriority + 1,
      previewUrl: "",
      position: "cardImage",
      title: "Card Image",
      type: "image",
    });
    sessionList[sessionIDX].contents.push(
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreateText_${nextPriority + 2}`,
        content: "",
        priority: nextPriority + 2,
        language: "zh_hant",
        position: "cardTopic",
        title: "Topic (繁)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreateText_${nextPriority + 3}`,
        content: "",
        priority: nextPriority + 3,
        language: "en",
        position: "cardTopic",
        title: "Topic (En)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreateText_${nextPriority + 4}`,
        content: "",
        priority: nextPriority + 4,
        language: "",
        position: "duration",
        title: "Duration",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreateText_${nextPriority + 5}`,
        content: "",
        priority: nextPriority + 5,
        language: "zh_hant",
        position: "sponsor",
        title: "Sponsor (繁)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreateText_${nextPriority + 6}`,
        content: "",
        priority: nextPriority + 6,
        language: "en",
        position: "sponsor",
        title: "Sponsor (En)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreateText_${nextPriority + 7}`,
        content: "",
        priority: nextPriority + 7,
        language: "",
        position: "budget",
        title: "Budget",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 8}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateTree_${nextPriority + 8}`,
        content: "",
        priority: nextPriority + 8,
        language: "",
        position: "",
        title: "Invention Project Introduction",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 8}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 9}`,
        content: "",
        priority: nextPriority + 9,
        language: "zh_hant",
        position: "projectName",
        title: "Project Title and Lead Investigator (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 8}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 10}`,
        content: "",
        priority: nextPriority + 10,
        language: "en",
        position: "projectName",
        title: "Project Title and Lead Investigator (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 8}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 11}`,
        content: "",
        priority: nextPriority + 11,
        language: "",
        position: "duration",
        title: "Project Period",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 8}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 12}`,
        content: "",
        priority: nextPriority + 12,
        language: "zh_hant",
        position: "target",
        title: "Participants (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 8}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 13}`,
        content: "",
        priority: nextPriority + 13,
        language: "en",
        position: "target",
        title: "Participants (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 8}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 14}`,
        content: "",
        priority: nextPriority + 14,
        language: "zh_hant",
        position: "introduction",
        title: "Aims (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 8}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 15}`,
        content: "",
        priority: nextPriority + 15,
        language: "en",
        position: "introduction",
        title: "Aims (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 8}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 16}`,
        content: "",
        priority: nextPriority + 16,
        language: "zh_hant",
        position: "partnerOrganization",
        title: "Collaborators (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 8}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 17}`,
        content: "",
        priority: nextPriority + 17,
        language: "en",
        position: "partnerOrganization",
        title: "Collaborators (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 8}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 18}`,
        content: "",
        priority: nextPriority + 18,
        language: "zh_hant",
        position: "funding",
        title: "Funder (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 8}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateText_${nextPriority + 19}`,
        content: "",
        priority: nextPriority + 19,
        language: "en",
        position: "funding",
        title: "Funder (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateTree_${nextPriority + 20}`,
        content: "",
        priority: nextPriority + 20,
        language: "",
        position: "",
        title: "Research Project Summary",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 21}`,
        content: "",
        priority: nextPriority + 21,
        language: "zh_hant",
        position: "mainTitle",
        title: "Main Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 22}`,
        content: "",
        priority: nextPriority + 22,
        language: "en",
        position: "mainTitle",
        title: "Main Title (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 23}`,
        content: "",
        priority: nextPriority + 23,
        language: "zh_hant",
        position: "title1",
        title: "Title 1 (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 24}`,
        content: "",
        priority: nextPriority + 24,
        language: "en",
        position: "title1",
        title: "Title 1 (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateWordEditor_${nextPriority + 25}`,
        content: "",
        priority: nextPriority + 25,
        language: "zh_hant",
        position: "content1",
        title: "Content 1 (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateWordEditor_${nextPriority + 26}`,
        content: "",
        priority: nextPriority + 26,
        language: "en",
        position: "content1",
        title: "Content 1 (en)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 27}`,
        content: "",
        priority: nextPriority + 27,
        language: "zh_hant",
        position: "title2",
        title: "Title 2 (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 28}`,
        content: "",
        priority: nextPriority + 28,
        language: "en",
        position: "title2",
        title: "Title 2 (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateWordEditor_${nextPriority + 29}`,
        content: "",
        priority: nextPriority + 29,
        language: "zh_hant",
        position: "content2",
        title: "Content 2 (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 30}`,
        content: "",
        priority: nextPriority + 30,
        language: "en",
        position: "content2",
        title: "Content 2 (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 31}`,
        content: "",
        priority: nextPriority + 31,
        language: "zh_hant",
        position: "title3",
        title: "Title 3 (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 32}`,
        content: "",
        priority: nextPriority + 32,
        language: "en",
        position: "title3",
        title: "Title 3 (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateWordEditor_${nextPriority + 33}`,
        content: "",
        priority: nextPriority + 33,
        language: "zh_hant",
        position: "content3",
        title: "Content 3 (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateWordEditor_${nextPriority + 34}`,
        content: "",
        priority: nextPriority + 34,
        language: "en",
        position: "content3",
        title: "Content 3 (en)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 35}`,
        content: "",
        priority: nextPriority + 35,
        language: "zh_hant",
        position: "title4",
        title: "Title 4 (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 36}`,
        content: "",
        priority: nextPriority + 36,
        language: "en",
        position: "title4",
        title: "Title 4 (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateWordEditor_${nextPriority + 37}`,
        content: "",
        priority: nextPriority + 37,
        language: "zh_hant",
        position: "content4",
        title: "Content 4 (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 38}`,
        content: "",
        priority: nextPriority + 38,
        language: "en",
        position: "content4",
        title: "Content 4 (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 39}`,
        content: "",
        priority: nextPriority + 39,
        language: "zh_hant",
        position: "title5",
        title: "Title 5 (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 40}`,
        content: "",
        priority: nextPriority + 40,
        language: "en",
        position: "title5",
        title: "Title 5 (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateWordEditor_${nextPriority + 41}`,
        content: "",
        priority: nextPriority + 41,
        language: "zh_hant",
        position: "content5",
        title: "Content 5 (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 20}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 42}`,
        content: "",
        priority: nextPriority + 42,
        language: "en",
        position: "content5",
        title: "Content 5 (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 43}` +
          "*" +
          +new Date(Date.now() + 3333),
        id: `newCreateTree_${nextPriority + 43}`,
        content: "",
        priority: nextPriority + 43,
        language: "",
        position: "",
        title: "Research Project Additional Session 1",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 43}` +
          "*" +
          +new Date(Date.now() + 3333),
        content: "",
        id: `newCreateText_${nextPriority + 44}`,
        language: "zh_hant",
        position: "mainTitle",
        priority: nextPriority + 44,
        title: "Main Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 43}` +
          "*" +
          +new Date(Date.now() + 3333),
        content: "",
        id: `newCreateText_${nextPriority + 45}`,
        language: "en",
        position: "mainTitle",
        priority: nextPriority + 45,
        title: "Main Title (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 43}` +
          "*" +
          +new Date(Date.now() + 3333),
        content: "",
        id: `newCreateText_${nextPriority + 46}`,
        language: "zh_hant",
        position: "subTitle",
        priority: nextPriority + 46,
        title: "Sub Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 43}` +
          "*" +
          +new Date(Date.now() + 3333),
        content: "",
        id: `newCreateText_${nextPriority + 47}`,
        language: "en",
        position: "subTitle",
        priority: nextPriority + 47,
        title: "Sub Title (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 43}` +
          "*" +
          +new Date(Date.now() + 3333),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 48}`,
        language: "zh_hant",
        position: "content",
        priority: nextPriority + 48,
        title: "Content (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 43}` +
          "*" +
          +new Date(Date.now() + 3333),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 49}`,
        language: "en",
        position: "content",
        priority: nextPriority + 49,
        title: "Content (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 50}` +
          "*" +
          +new Date(Date.now() + 5555),
        id: `newCreateTree_${nextPriority + 50}`,
        content: "",
        priority: nextPriority + 50,
        language: "",
        position: "",
        title: "Research Project Additional Session 2",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 50}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 51}`,
        language: "zh_hant",
        position: "mainTitle",
        priority: nextPriority + 51,
        title: "Main Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 50}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 52}`,
        language: "en",
        position: "mainTitle",
        priority: nextPriority + 52,
        title: "Main Title (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 50}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 53}`,
        language: "zh_hant",
        position: "subTitle",
        priority: nextPriority + 53,
        title: "Sub Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 50}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateText_${nextPriority + 54}`,
        language: "en",
        position: "subTitle",
        priority: nextPriority + 54,
        title: "Sub Title (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 50}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 55}`,
        language: "zh_hant",
        position: "content",
        priority: nextPriority + 55,
        title: "Content (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 50}` +
          "*" +
          +new Date(Date.now() + 5555),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 56}`,
        language: "en",
        position: "content",
        priority: nextPriority + 56,
        title: "Content (En)",
        type: "wordEditor",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreatePDF_${nextPriority + 57}`,
        url: "",
        priority: nextPriority + 57,
        previewUrl: "",
        position: "pdf",
        title: "PDF",
        type: "pdf",
      }
    );

    let newCardID = id + "_card_" + nextPriority + "*" + +createTime;

    let originalSessionContents = [] as any;

    for (
      let i = sessionList[sessionIDX].combineContents.length - 1;
      i >= 0;
      i--
    ) {
      if (sessionList[sessionIDX].combineContents[i].type != "card") {
        originalSessionContents.push(
          sessionList[sessionIDX].combineContents[i]
        );
        sessionList[sessionIDX].combineContents.splice(i, 1);
      }
    }

    sessionList[sessionIDX].combineContents.unshift({
      category: newCardID,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateImage_${nextPriority + 1}`,
          url: "",
          priority: nextPriority + 1,
          previewUrl: "",
          position: "cardImage",
          title: "Card Image",
          type: "image",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 2}`,
          content: "",
          priority: nextPriority + 2,
          language: "zh_hant",
          position: "cardTopic",
          title: "Topic (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 3}`,
          content: "",
          priority: nextPriority + 3,
          language: "en",
          position: "cardTopic",
          title: "Topic (En)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 4}`,
          content: "",
          priority: nextPriority + 4,
          language: "",
          position: "duration",
          title: "Duration",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 5}`,
          content: "",
          priority: nextPriority + 5,
          language: "zh_hant",
          position: "sponsor",
          title: "Sponsor (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 6}`,
          content: "",
          priority: nextPriority + 6,
          language: "en",
          position: "sponsor",
          title: "Sponsor (En)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 7}`,
          content: "",
          priority: nextPriority + 7,
          language: "",
          position: "budget",
          title: "Budget",
          type: "text",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 8}` +
            "*" +
            +createTime +
            1111,
          id: `newCreateTree_${nextPriority + 8}`,
          content: "",
          priority: nextPriority + 8,
          language: "",
          position: "",
          title: "Project Summary",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 8}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 9}`,
              content: "",
              priority: nextPriority + 9,
              language: "zh_hant",
              position: "projectName",
              title: "Project Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 8}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 10}`,
              content: "",
              priority: nextPriority + 10,
              language: "en",
              position: "projectName",
              title: "Project Title (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 8}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 11}`,
              content: "",
              priority: nextPriority + 11,
              language: "",
              position: "duration",
              title: "Project Period",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 8}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 12}`,
              content: "",
              priority: nextPriority + 12,
              language: "zh_hant",
              position: "target",
              title: "Participants (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 8}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 13}`,
              content: "",
              priority: nextPriority + 13,
              language: "en",
              position: "target",
              title: "Participants (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 8}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 14}`,
              content: "",
              priority: nextPriority + 14,
              language: "zh_hant",
              position: "introduction",
              title: "Aims (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 8}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 15}`,
              content: "",
              priority: nextPriority + 15,
              language: "en",
              position: "introduction",
              title: "Aims (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 8}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 16}`,
              content: "",
              priority: nextPriority + 16,
              language: "zh_hant",
              position: "partnerOrganization",
              title: "Collaborators (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 8}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 17}`,
              content: "",
              priority: nextPriority + 17,
              language: "en",
              position: "partnerOrganization",
              title: "Collaborators (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 8}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 18}`,
              content: "",
              priority: nextPriority + 18,
              language: "zh_hant",
              position: "funding",
              title: "Funder (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 8}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 19}`,
              content: "",
              priority: nextPriority + 19,
              language: "en",
              position: "funding",
              title: "Funder (En)",
              type: "text",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 20}` +
            "*" +
            +createTime +
            2222,
          id: `newCreateTree_${nextPriority + 20}`,
          content: "",
          priority: nextPriority + 20,
          language: "",
          position: "",
          title: " Abstracts",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 21}`,
              content: "",
              priority: nextPriority + 21,
              language: "zh_hant",
              position: "mainTitle",
              title: "Main Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 22}`,
              content: "",
              priority: nextPriority + 22,
              language: "en",
              position: "mainTitle",
              title: "Main Title (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 23}`,
              content: "",
              priority: nextPriority + 23,
              language: "zh_hant",
              position: "title1",
              title: "Title 1 (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 24}`,
              content: "",
              priority: nextPriority + 24,
              language: "en",
              position: "title1",
              title: "Title 1 (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateWordEditor_${nextPriority + 25}`,
              content: "",
              priority: nextPriority + 25,
              language: "zh_hant",
              position: "content1",
              title: "Content 1 (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateWordEditor_${nextPriority + 26}`,
              content: "",
              priority: nextPriority + 26,
              language: "en",
              position: "content1",
              title: "Content 1 (en)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 27}`,
              content: "",
              priority: nextPriority + 27,
              language: "zh_hant",
              position: "title2",
              title: "Title 2 (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 28}`,
              content: "",
              priority: nextPriority + 28,
              language: "en",
              position: "title2",
              title: "Title 2 (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateWordEditor_${nextPriority + 29}`,
              content: "",
              priority: nextPriority + 29,
              language: "zh_hant",
              position: "content2",
              title: "Content 2 (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 30}`,
              content: "",
              priority: nextPriority + 30,
              language: "en",
              position: "content2",
              title: "Content 2 (En)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 31}`,
              content: "",
              priority: nextPriority + 31,
              language: "zh_hant",
              position: "title3",
              title: "Title 3 (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 32}`,
              content: "",
              priority: nextPriority + 32,
              language: "en",
              position: "title3",
              title: "Title 3 (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateWordEditor_${nextPriority + 33}`,
              content: "",
              priority: nextPriority + 33,
              language: "zh_hant",
              position: "content3",
              title: "Content 3 (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateWordEditor_${nextPriority + 34}`,
              content: "",
              priority: nextPriority + 34,
              language: "en",
              position: "content3",
              title: "Content 3 (en)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 35}`,
              content: "",
              priority: nextPriority + 35,
              language: "zh_hant",
              position: "title4",
              title: "Title 4 (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 36}`,
              content: "",
              priority: nextPriority + 36,
              language: "en",
              position: "title4",
              title: "Title 4 (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateWordEditor_${nextPriority + 37}`,
              content: "",
              priority: nextPriority + 37,
              language: "zh_hant",
              position: "content4",
              title: "Content 4 (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 38}`,
              content: "",
              priority: nextPriority + 38,
              language: "en",
              position: "content4",
              title: "Content 4 (En)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 39}`,
              content: "",
              priority: nextPriority + 39,
              language: "zh_hant",
              position: "title5",
              title: "Title 5 (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 40}`,
              content: "",
              priority: nextPriority + 40,
              language: "en",
              position: "title5",
              title: "Title 5 (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateWordEditor_${nextPriority + 41}`,
              content: "",
              priority: nextPriority + 41,
              language: "zh_hant",
              position: "content5",
              title: "Content 5 (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 20}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 42}`,
              content: "",
              priority: nextPriority + 42,
              language: "en",
              position: "content5",
              title: "Content 5 (En)",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 43}` +
            "*" +
            +createTime +
            3333,
          id: `newCreateTree_${nextPriority + 43}`,
          content: "",
          priority: nextPriority + 43,
          language: "",
          position: "",
          title: "Publication",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 43}` +
                "*" +
                +createTime +
                3333,
              content: "",
              id: `newCreateText_${nextPriority + 44}`,
              language: "zh_hant",
              position: "mainTitle",
              priority: nextPriority + 44,
              title: "Main Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 43}` +
                "*" +
                +createTime +
                3333,
              content: "",
              id: `newCreateText_${nextPriority + 45}`,
              language: "en",
              position: "mainTitle",
              priority: nextPriority + 45,
              title: "Main Title (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 43}` +
                "*" +
                +createTime +
                3333,
              content: "",
              id: `newCreateText_${nextPriority + 46}`,
              language: "zh_hant",
              position: "subTitle",
              priority: nextPriority + 46,
              title: "Sub Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 43}` +
                "*" +
                +createTime +
                3333,
              content: "",
              id: `newCreateText_${nextPriority + 47}`,
              language: "en",
              position: "subTitle",
              priority: nextPriority + 47,
              title: "Sub Title (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 43}` +
                "*" +
                +createTime +
                3333,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 48}`,
              language: "zh_hant",
              position: "content",
              priority: nextPriority + 48,
              title: "Content (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 43}` +
                "*" +
                +createTime +
                3333,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 49}`,
              language: "en",
              position: "content",
              priority: nextPriority + 49,
              title: "Content (En)",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 50}` +
            "*" +
            +createTime +
            5555,
          id: `newCreateTree_${nextPriority + 50}`,
          content: "",
          priority: nextPriority + 50,
          language: "",
          position: "",
          title: "Deliverables",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 50}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 51}`,
              language: "zh_hant",
              position: "mainTitle",
              priority: nextPriority + 51,
              title: "Main Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 50}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 52}`,
              language: "en",
              position: "mainTitle",
              priority: nextPriority + 52,
              title: "Main Title (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 50}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 53}`,
              language: "zh_hant",
              position: "subTitle",
              priority: nextPriority + 53,
              title: "Sub Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 50}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 54}`,
              language: "en",
              position: "subTitle",
              priority: nextPriority + 54,
              title: "Sub Title (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 50}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 55}`,
              language: "zh_hant",
              position: "content",
              priority: nextPriority + 55,
              title: "Content (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 50}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 56}`,
              language: "en",
              position: "content",
              priority: nextPriority + 56,
              title: "Content (En)",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreatePDF_${nextPriority + 57}`,
          url: "",
          priority: nextPriority + 57,
          previewUrl: "",
          position: "pdf",
          title: "PDF",
          type: "pdf",
        },
      ],
      cardPosition: nextPriority + nextPriority,
      cardPositionID: "newCreateCardPosition",
      // cardPositionPriority: nextPriority + 58,
    });

    originalSessionContents.forEach((e: any) => {
      sessionList[sessionIDX].combineContents.unshift(e);
    });

    setNewCardArr([...newCardArr, { newCardID: newCardID }]);
  }

  const [selectedCard, setSelectedCard] = useState() as any;

  const [deleteDialog, setDeleteDialog] = useState(false);

  function createWhatIsACTGestureCard(sessionIDX: number) {
    setModified(!modified);
    // console.log(
    //   "combineContents",
    //   sessionList[sessionIDX].combineContents.length
    // );
    const createTime = new Date(Date.now());
    let spliceArr = [];
    const combineContentsIDX =
      sessionList[sessionIDX].combineContents.length - 1;
    for (
      let i = sessionList[sessionIDX].combineContents.length - 1;
      i > combineContentsIDX - 2;
      i--
    ) {
      // console.log(i);
      spliceArr.push(sessionList[sessionIDX].combineContents.splice(i, 1));
    }
    // console.log(spliceArr);
    // console.log(sessionList[sessionIDX]);

    let nextPriority =
      +sessionList[sessionIDX].combineContents[
        sessionList[sessionIDX].combineContents.length - 1
      ].cardContent[
        sessionList[sessionIDX].combineContents[
          sessionList[sessionIDX].combineContents.length - 1
        ].cardContent.length - 1
      ].priority + 1;

    sessionList[sessionIDX].images.push({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateImage_${nextPriority + 3}`,
      url: "",
      priority: nextPriority + 3,
      previewUrl: "",
      position: "image",
      title: "Image",
      type: "image",
    });

    sessionList[sessionIDX].contents.push(
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateText_${nextPriority + 1}`,
        content: "",
        priority: nextPriority + 1,
        language: "zh_hant",
        position: "title",
        title: "Title (繁)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateText_${nextPriority + 2}`,
        content: "",
        priority: nextPriority + 2,
        language: "en",
        position: "title",
        title: "Title (En)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateText_${nextPriority + 4}`,
        content: "",
        priority: nextPriority + 4,
        language: "zh_hant",
        position: "hand",
        title: "手勢 (繁)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateText_${nextPriority + 5}`,
        content: "",
        priority: nextPriority + 5,
        language: "en",
        position: "hand",
        title: "手勢 (En)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateText_${nextPriority + 6}`,
        content: "",
        priority: nextPriority + 6,
        language: "zh_hant",
        position: "meaning",
        title: "意思 (繁)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreateText_${nextPriority + 7}`,
        content: "",
        priority: nextPriority + 7,
        language: "en",
        position: "meaning",
        title: "意思 (En)",
        type: "text",
      }
    );

    sessionList[sessionIDX].cardID.push({
      category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [],
    });

    let newCardID = id + "_card_" + nextPriority + "*" + +createTime;

    // console.log("push", sessionList[sessionIDX].combineContents);

    let originalSessionContents = [] as any;

    for (
      let i = sessionList[sessionIDX].combineContents.length - 1;
      i >= 0;
      i--
    ) {
      if (sessionList[sessionIDX].combineContents[i].type != "card") {
        originalSessionContents.push(
          sessionList[sessionIDX].combineContents[i]
        );
        sessionList[sessionIDX].combineContents.splice(i, 1);
      }
    }

    sessionList[sessionIDX].combineContents.unshift({
      category: newCardID,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 1}`,
          content: "",
          priority: nextPriority + 1,
          language: "zh_hant",
          position: "title",
          title: "Title (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 2}`,
          content: "",
          priority: nextPriority + 2,
          language: "en",
          position: "title",
          title: "Title (En)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateImage_${nextPriority + 3}`,
          url: "",
          priority: nextPriority + 3,
          previewUrl: "",
          position: "image",
          title: "Image",
          type: "image",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 4}`,
          content: "",
          priority: nextPriority + 4,
          language: "zh_hant",
          position: "hand",
          title: "Hand Gestures (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 5}`,
          content: "",
          priority: nextPriority + 5,
          language: "en",
          position: "hand",
          title: "Hand Gestures (En)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 6}`,
          content: "",
          priority: nextPriority + 6,
          language: "zh_hant",
          position: "meaning",
          title: "Symbolizing (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 7}`,
          content: "",
          priority: nextPriority + 7,
          language: "en",
          position: "meaning",
          title: "Symbolizing (En)",
          type: "text",
        },
      ],
      cardPosition: nextPriority + nextPriority,
      cardPositionID: "newCreateCardPosition",
    });

    originalSessionContents.forEach((e: any) => {
      sessionList[sessionIDX].combineContents.unshift(e);
    });

    setNewCardArr([...newCardArr, { newCardID: newCardID }]);

    spliceArr.forEach((e: any) => {
      // console.log(e);
      sessionList[sessionIDX].combineContents.push({
        category: e[0].category,
        content: e[0].content,
        id: e[0].id,
        langLabel: e[0].langLabel,
        position: e[0].position,
        priority: e[0].priority + 9,
        title: e[0].title,
        type: e[0].type,
      });
    });
  }

  function createTrainingMaterialsCard(sessionIDX: number) {
    setModified(!modified);
    // console.log(sessionList);
    // console.log("combineContents", sessionList[sessionIDX].combineContents);
    // to count the next priority
    let contentsLastPriority: number;
    let imagesLastPriority: number;
    let audiosLastPriority: number;
    let videosLastPriority: number;
    let cardLastPriority: number;

    const createTime = +new Date(Date.now());

    if (sessionList[sessionIDX].contents.length > 0) {
      contentsLastPriority =
        sessionList[sessionIDX].contents[
          sessionList[sessionIDX].contents.length - 1
        ].priority;
    } else {
      contentsLastPriority = 0;
    }
    if (sessionList[sessionIDX].images.length > 0) {
      imagesLastPriority =
        sessionList[sessionIDX].images[
          sessionList[sessionIDX].images.length - 1
        ].priority;
    } else {
      imagesLastPriority = 0;
    }
    if (sessionList[sessionIDX].audios.length > 0) {
      audiosLastPriority =
        sessionList[sessionIDX].audios[
          sessionList[sessionIDX].audios.length - 1
        ].priority;
    } else {
      audiosLastPriority = 0;
    }
    if (sessionList[sessionIDX].videos.length > 0) {
      videosLastPriority =
        sessionList[sessionIDX].videos[
          sessionList[sessionIDX].videos.length - 1
        ].priority;
    } else {
      videosLastPriority = 0;
    }
    if (sessionList[sessionIDX].cardID.length > 0) {
      cardLastPriority =
        sessionList[sessionIDX].cardID[
          sessionList[sessionIDX].cardID.length - 1
        ].priority;
    } else {
      cardLastPriority = 0;
    }

    // sum up all the current exist contents priority
    const nextPriority =
      Math.max(
        contentsLastPriority,
        imagesLastPriority,
        audiosLastPriority,
        videosLastPriority,
        cardLastPriority
      ) + 1;

    sessionList[sessionIDX].cardID.push({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
    });

    sessionList[sessionIDX].images.push(
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 1}` +
          "*" +
          +createTime +
          1111,
        id: `newCreateImage_${nextPriority + 6}`,
        url: "",
        priority: nextPriority + 6,
        previewUrl: "",
        position: "image",
        title: "Image",
        type: "image",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 17}` +
          "*" +
          +createTime +
          3333,
        id: `newCreateImage_${nextPriority + 18}`,
        url: "",
        priority: nextPriority + 18,
        previewUrl: "",
        position: "image",
        title: "Image",
        type: "image",
      }
    );

    sessionList[sessionIDX].contents.push(
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 1}` +
          "*" +
          +createTime +
          1111,
        id: `newCreateTree_${nextPriority + 1}`,
        content: "",
        priority: nextPriority + 1,
        language: "",
        position: "",
        title: "First Session",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 1}` +
          "*" +
          +createTime +
          1111,
        id: `newCreateText_${nextPriority + 2}`,
        content: "",
        priority: nextPriority + 2,
        language: "zh_hant",
        position: "title",
        title: "Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 1}` +
          "*" +
          +createTime +
          1111,
        id: `newCreateText_${nextPriority + 3}`,
        content: "",
        priority: nextPriority + 3,
        language: "en",
        position: "title",
        title: "Title (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 1}` +
          "*" +
          +createTime +
          1111,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 4}`,
        language: "zh_hant",
        position: "content1",
        priority: nextPriority + 4,
        title: "Content 1 (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 1}` +
          "*" +
          +createTime +
          1111,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 5}`,
        language: "en",
        position: "content1",
        priority: nextPriority + 5,
        title: "Content 1 (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 1}` +
          "*" +
          +createTime +
          1111,
        id: `newCreateText_${nextPriority + 6}`,
        content: "",
        priority: nextPriority + 6,
        language: "zh_hant",
        position: "iCaption",
        title: "iCaption (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 1}` +
          "*" +
          +createTime +
          1111,
        id: `newCreateText_${nextPriority + 7}`,
        content: "",
        priority: nextPriority + 7,
        language: "en",
        position: "iCaption",
        title: "iCaption (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 1}` +
          "*" +
          +createTime +
          1111,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 8}`,
        language: "zh_hant",
        position: "content2",
        priority: nextPriority + 8,
        title: "Content 2 (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 1}` +
          "*" +
          +createTime +
          1111,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 9}`,
        language: "en",
        position: "content2",
        priority: nextPriority + 9,
        title: "Content 2 (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +createTime +
          2222,
        id: `newCreateTree_${nextPriority + 11}`,
        content: "",
        priority: nextPriority + 11,
        language: "",
        position: "",
        title: "Second Session",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +createTime +
          2222,
        id: `newCreateText_${nextPriority + 13}`,
        content: "",
        priority: nextPriority + 13,
        language: "zh_hant",
        position: "vCaption",
        title: "vCaption (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +createTime +
          2222,
        id: `newCreateText_${nextPriority + 14}`,
        content: "",
        priority: nextPriority + 14,
        language: "en",
        position: "vCaption",
        title: "vCaption (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +createTime +
          2222,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 15}`,
        language: "zh_hant",
        position: "content",
        priority: nextPriority + 15,
        title: "Content (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +createTime +
          2222,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 16}`,
        language: "en",
        position: "content",
        priority: nextPriority + 16,
        title: "Content (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 17}` +
          "*" +
          +createTime +
          3333,
        id: `newCreateTree_${nextPriority + 17}`,
        content: "",
        priority: nextPriority + 17,
        language: "",
        position: "",
        title: "Third Session",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 17}` +
          "*" +
          +createTime +
          3333,
        id: `newCreateText_${nextPriority + 19}`,
        content: "",
        priority: nextPriority + 19,
        language: "zh_hant",
        position: "iCaption",
        title: "iCaption (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 17}` +
          "*" +
          +createTime +
          3333,
        id: `newCreateText_${nextPriority + 20}`,
        content: "",
        priority: nextPriority + 20,
        language: "en",
        position: "iCaption",
        title: "iCaption (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 17}` +
          "*" +
          +createTime +
          3333,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 21}`,
        language: "zh_hant",
        position: "content",
        priority: nextPriority + 21,
        title: "Content (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 17}` +
          "*" +
          +createTime +
          3333,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 22}`,
        language: "en",
        position: "content",
        priority: nextPriority + 22,
        title: "Content (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 23}` +
          "*" +
          +createTime +
          5555,
        id: `newCreateTree_${nextPriority + 23}`,
        content: "",
        priority: nextPriority + 23,
        language: "",
        position: "",
        title: "Fourth Session",
        treeContents: [{}],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 23}` +
          "*" +
          +createTime +
          5555,
        id: `newCreateText_${nextPriority + 25}`,
        content: "",
        priority: nextPriority + 25,
        language: "zh_hant",
        position: "vCaption",
        title: "vCaption (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 23}` +
          "*" +
          +createTime +
          5555,
        id: `newCreateText_${nextPriority + 26}`,
        content: "",
        priority: nextPriority + 26,
        language: "en",
        position: "vCaption",
        title: "vCaption (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 23}` +
          "*" +
          +createTime +
          5555,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 27}`,
        language: "zh_hant",
        position: "content",
        priority: nextPriority + 27,
        title: "Content (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 23}` +
          "*" +
          +createTime +
          5555,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 28}`,
        language: "en",
        position: "content",
        priority: nextPriority + 28,
        title: "Content (En)",
        type: "wordEditor",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreatePDF_${nextPriority + 29}`,
        url: "",
        priority: nextPriority + 29,
        previewUrl: "",
        position: "pdf",
        title: "PDF",
        type: "pdf",
      }
    );

    sessionList[sessionIDX].audios.push({});

    sessionList[sessionIDX].videos.push(
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 11}` +
          "*" +
          +createTime +
          2222,
        id: `newCreateVideo_${nextPriority + 12}`,
        position: "video",
        previewUrl: "",
        priority: nextPriority + 12,
        title: "Video",
        type: "video",
        url: "",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 23}` +
          "*" +
          +createTime +
          5555,
        id: `newCreateVideo_${nextPriority + 24}`,
        position: "video",
        previewUrl: "",
        priority: nextPriority + 24,
        title: "Video",
        type: "video",
        url: "",
      }
    );

    let newCardID = id + "_card_" + nextPriority + "*" + +createTime;

    let originalSessionContents = [] as any;

    for (
      let i = sessionList[sessionIDX].combineContents.length - 1;
      i >= 0;
      i--
    ) {
      if (sessionList[sessionIDX].combineContents[i].type != "card") {
        originalSessionContents.push(
          sessionList[sessionIDX].combineContents[i]
        );
        sessionList[sessionIDX].combineContents.splice(i, 1);
      }
    }

    sessionList[sessionIDX].combineContents.unshift({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 1}` +
            "*" +
            +createTime +
            1111,
          id: `newCreateTree_${nextPriority + 1}`,
          content: "",
          priority: nextPriority + 1,
          language: "",
          position: "",
          title: "Session 1 With Image",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 1}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 2}`,
              content: "",
              priority: nextPriority + 2,
              language: "zh_hant",
              position: "title",
              title: "Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 1}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 3}`,
              content: "",
              priority: nextPriority + 3,
              language: "en",
              position: "title",
              title: "Title (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 1}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 4}`,
              language: "zh_hant",
              position: "content1",
              priority: nextPriority + 4,
              title: "Content 1 (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 1}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 5}`,
              language: "en",
              position: "content1",
              priority: nextPriority + 5,
              title: "Content 1 (En)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 1}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateImage_${nextPriority + 6}`,
              url: "",
              priority: nextPriority + 6,
              previewUrl: "",
              position: "image",
              title: "Image",
              type: "image",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 1}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 7}`,
              content: "",
              priority: nextPriority + 7,
              language: "zh_hant",
              position: "iCaption",
              title: "iCaption (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 1}` +
                "*" +
                +createTime +
                1111,
              id: `newCreateText_${nextPriority + 8}`,
              content: "",
              priority: nextPriority + 8,
              language: "en",
              position: "iCaption",
              title: "iCaption (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 1}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 9}`,
              language: "zh_hant",
              position: "content2",
              priority: nextPriority + 9,
              title: "Content 2 (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 1}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 10}`,
              language: "en",
              position: "content2",
              priority: nextPriority + 10,
              title: "Content 2 (En)",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 11}` +
            "*" +
            +createTime +
            2222,
          id: `newCreateTree_${nextPriority + 11}`,
          content: "",
          priority: nextPriority + 11,
          language: "",
          position: "",
          title: "Session 2 With Video",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateVideo_${nextPriority + 12}`,
              position: "video",
              previewUrl: "",
              priority: nextPriority + 12,
              title: "Video",
              type: "video",
              url: "",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 13}`,
              content: "",
              priority: nextPriority + 13,
              language: "zh_hant",
              position: "vCaption",
              title: "vCaption (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 14}`,
              content: "",
              priority: nextPriority + 14,
              language: "en",
              position: "vCaption",
              title: "vCaption (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 15}`,
              language: "zh_hant",
              position: "content",
              priority: nextPriority + 15,
              title: "Content (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 11}` +
                "*" +
                +createTime +
                2222,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 16}`,
              language: "en",
              position: "content",
              priority: nextPriority + 16,
              title: "Content (En)",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 17}` +
            "*" +
            +createTime +
            3333,
          id: `newCreateTree_${nextPriority + 17}`,
          content: "",
          priority: nextPriority + 17,
          language: "",
          position: "",
          title: "Session 3 With Image",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 17}` +
                "*" +
                +createTime +
                3333,
              id: `newCreateImage_${nextPriority + 18}`,
              url: "",
              priority: nextPriority + 18,
              previewUrl: "",
              position: "image",
              title: "Image",
              type: "image",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 17}` +
                "*" +
                +createTime +
                3333,
              id: `newCreateText_${nextPriority + 19}`,
              content: "",
              priority: nextPriority + 19,
              language: "zh_hant",
              position: "iCaption",
              title: "iCaption (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 17}` +
                "*" +
                +createTime +
                3333,
              id: `newCreateText_${nextPriority + 20}`,
              content: "",
              priority: nextPriority + 20,
              language: "en",
              position: "iCaption",
              title: "iCaption (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 17}` +
                "*" +
                +createTime +
                3333,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 21}`,
              language: "zh_hant",
              position: "content",
              priority: nextPriority + 21,
              title: "Content (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 17}` +
                "*" +
                +createTime +
                3333,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 22}`,
              language: "en",
              position: "content",
              priority: nextPriority + 22,
              title: "Content (En)",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 23}` +
            "*" +
            +createTime +
            5555,
          id: `newCreateTree_${nextPriority + 23}`,
          content: "",
          priority: nextPriority + 23,
          language: "",
          position: "",
          title: "Session 4 With Video",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 23}` +
                "*" +
                +createTime +
                5555,
              id: `newCreateVideo_${nextPriority + 24}`,
              position: "video",
              previewUrl: "",
              priority: nextPriority + 24,
              title: "Video",
              type: "video",
              url: "",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 23}` +
                "*" +
                +createTime +
                5555,
              id: `newCreateText_${nextPriority + 25}`,
              content: "",
              priority: nextPriority + 25,
              language: "zh_hant",
              position: "vCaption",
              title: "vCaption (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 23}` +
                "*" +
                +createTime +
                5555,
              id: `newCreateText_${nextPriority + 26}`,
              content: "",
              priority: nextPriority + 26,
              language: "en",
              position: "vCaption",
              title: "vCaption (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 23}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 27}`,
              language: "zh_hant",
              position: "content",
              priority: nextPriority + 27,
              title: "Content (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 23}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 28}`,
              language: "en",
              position: "content",
              priority: nextPriority + 28,
              title: "Content (En)",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreatePDF_${nextPriority + 29}`,
          url: "",
          priority: nextPriority + 29,
          previewUrl: "",
          position: "pdf",
          title: "PDF",
          type: "pdf",
        },
      ],
      cardPosition: nextPriority + nextPriority,
      cardPositionID: "newCreateCardPosition",
    });

    originalSessionContents.forEach((e: any) => {
      sessionList[sessionIDX].combineContents.unshift(e);
    });

    setNewCardArr([...newCardArr, { newCardID: newCardID }]);
  }

  function createModuleStoryTellingCard(sessionIDX: number) {
    if (id === undefined) {
      if (moduleNumber) {
        id = `Module${+moduleNumber + 1}`;
      }
    }
    // console.log(id);
    setModified(!modified);
    // console.log(sessionList[sessionIDX]);
    // to count the next priority
    let contentsLastPriority: number;
    let imagesLastPriority: number;
    let audiosLastPriority: number;
    let videosLastPriority: number;
    let cardLastPriority: number;

    const createTime = +new Date(Date.now());

    if (sessionList[sessionIDX].contents.length > 0) {
      contentsLastPriority =
        sessionList[sessionIDX].contents[
          sessionList[sessionIDX].contents.length - 1
        ].priority;
    } else {
      contentsLastPriority = 0;
    }
    if (sessionList[sessionIDX].images.length > 0) {
      imagesLastPriority =
        sessionList[sessionIDX].images[
          sessionList[sessionIDX].images.length - 1
        ].priority;
    } else {
      imagesLastPriority = 0;
    }
    if (sessionList[sessionIDX].audios.length > 0) {
      audiosLastPriority =
        sessionList[sessionIDX].audios[
          sessionList[sessionIDX].audios.length - 1
        ].priority;
    } else {
      audiosLastPriority = 0;
    }
    if (sessionList[sessionIDX].videos.length > 0) {
      videosLastPriority =
        sessionList[sessionIDX].videos[
          sessionList[sessionIDX].videos.length - 1
        ].priority;
    } else {
      videosLastPriority = 0;
    }
    if (sessionList[sessionIDX].cardID.length > 0) {
      cardLastPriority =
        sessionList[sessionIDX].cardID[
          sessionList[sessionIDX].cardID.length - 1
        ].priority;
    } else {
      cardLastPriority = 0;
    }

    // sum up all the current exist contents priority
    const nextPriority =
      Math.max(
        contentsLastPriority,
        imagesLastPriority,
        audiosLastPriority,
        videosLastPriority,
        cardLastPriority
      ) + 1;

    sessionList[sessionIDX].cardID.push({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [],
    });

    sessionList[sessionIDX].images.push({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateImage_${nextPriority + 1}`,
      url: "",
      priority: nextPriority + 1,
      previewUrl: "",
      position: "image",
      title: "Image",
      type: "image",
    });

    sessionList[sessionIDX].contents.push({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateWordEditor_${nextPriority + 2}`,
      content: "",
      priority: nextPriority + 2,
      language: "",
      position: "content",
      title: "Content (繁)",
      type: "wordEditor",
    });

    let newCardID = id + "_card_" + nextPriority + "*" + +createTime;

    let originalSessionContents = [] as any;

    for (
      let i = sessionList[sessionIDX].combineContents.length - 1;
      i >= 0;
      i--
    ) {
      if (sessionList[sessionIDX].combineContents[i].type != "card") {
        originalSessionContents.push(
          sessionList[sessionIDX].combineContents[i]
        );
        sessionList[sessionIDX].combineContents.splice(i, 1);
      }
    }

    sessionList[sessionIDX].combineContents.unshift({
      category: newCardID,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateImage_${nextPriority + 1}`,
          url: "",
          priority: nextPriority + 1,
          previewUrl: "",
          position: "image",
          title: "Image",
          type: "image",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateWordEditor_${nextPriority + 2}`,
          content: "",
          priority: nextPriority + 2,
          language: "",
          position: "content",
          title: "Content (繁)",
          type: "wordEditor",
        },
      ],
      cardPosition: nextPriority + nextPriority,
      cardPositionID: "newCreateCardPosition",
    });

    originalSessionContents.forEach((e: any) => {
      sessionList[sessionIDX].combineContents.unshift(e);
    });

    setNewCardArr([...newCardArr, { newCardID: newCardID }]);
  }

  function createModuleReadingMaterialCard(sessionIDX: number) {
    if (id === undefined) {
      if (moduleNumber) {
        id = `Module${+moduleNumber + 1}`;
      }
    }
    // console.log(id);
    setModified(!modified);
    // console.log(sessionList[sessionIDX]);
    // to count the next priority
    let contentsLastPriority: number;
    let imagesLastPriority: number;
    let audiosLastPriority: number;
    let videosLastPriority: number;
    let cardLastPriority: number;

    const createTime = +new Date(Date.now());

    if (sessionList[sessionIDX].contents.length > 0) {
      contentsLastPriority =
        sessionList[sessionIDX].contents[
          sessionList[sessionIDX].contents.length - 1
        ].priority;
    } else {
      contentsLastPriority = 0;
    }
    if (sessionList[sessionIDX].images.length > 0) {
      imagesLastPriority =
        sessionList[sessionIDX].images[
          sessionList[sessionIDX].images.length - 1
        ].priority;
    } else {
      imagesLastPriority = 0;
    }
    if (sessionList[sessionIDX].audios.length > 0) {
      audiosLastPriority =
        sessionList[sessionIDX].audios[
          sessionList[sessionIDX].audios.length - 1
        ].priority;
    } else {
      audiosLastPriority = 0;
    }
    if (sessionList[sessionIDX].videos.length > 0) {
      videosLastPriority =
        sessionList[sessionIDX].videos[
          sessionList[sessionIDX].videos.length - 1
        ].priority;
    } else {
      videosLastPriority = 0;
    }
    if (sessionList[sessionIDX].cardID.length > 0) {
      cardLastPriority =
        sessionList[sessionIDX].cardID[
          sessionList[sessionIDX].cardID.length - 1
        ].priority;
    } else {
      cardLastPriority = 0;
    }

    // sum up all the current exist contents priority
    const nextPriority =
      Math.max(
        contentsLastPriority,
        imagesLastPriority,
        audiosLastPriority,
        videosLastPriority,
        cardLastPriority
      ) + 1;

    sessionList[sessionIDX].cardID.push({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [],
    });

    sessionList[sessionIDX].images.push({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateImage_${nextPriority + 2}`,
      url: "",
      priority: nextPriority + 2,
      previewUrl: "",
      position: "image",
      title: "Image",
      type: "image",
    });

    sessionList[sessionIDX].contents.push(
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateWordEditor_${nextPriority + 1}`,
        content: "",
        priority: nextPriority + 1,
        language: "",
        position: "content",
        title: "Content (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 3}` +
          "*" +
          +createTime +
          1111,
        id: `newCreateTree_${nextPriority + 3}`,
        content: "",
        priority: nextPriority + 3,
        language: "",
        position: "",
        title: "Abstracts",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 3}` +
          "*" +
          +createTime +
          1111,
        content: "",
        id: `newCreateText_${nextPriority + 4}`,
        language: "",
        position: "subTitle",
        priority: nextPriority + 4,
        title: "Sub Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 3}` +
          "*" +
          +createTime +
          1111,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 5}`,
        language: "en",
        position: "content",
        priority: nextPriority + 5,
        title: "Content",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 6}` +
          "*" +
          +createTime +
          2222,
        id: `newCreateTree_${nextPriority + 6}`,
        content: "",
        priority: nextPriority + 6,
        language: "",
        position: "",
        title: "Abstracts",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 6}` +
          "*" +
          +createTime +
          2222,
        content: "",
        id: `newCreateText_${nextPriority + 7}`,
        language: "",
        position: "subTitle",
        priority: nextPriority + 7,
        title: "Sub Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 6}` +
          "*" +
          +createTime +
          2222,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 8}`,
        language: "en",
        position: "content",
        priority: nextPriority + 8,
        title: "Content",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 9}` +
          "*" +
          +createTime +
          3333,
        id: `newCreateTree_${nextPriority + 9}`,
        content: "",
        priority: nextPriority + 9,
        language: "",
        position: "",
        title: "Abstracts",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 9}` +
          "*" +
          +createTime +
          3333,
        content: "",
        id: `newCreateText_${nextPriority + 10}`,
        language: "",
        position: "subTitle",
        priority: nextPriority + 10,
        title: "Sub Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 9}` +
          "*" +
          +createTime +
          3333,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 11}`,
        language: "en",
        position: "content",
        priority: nextPriority + 11,
        title: "Content",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 12}` +
          "*" +
          +createTime +
          5555,
        id: `newCreateTree_${nextPriority + 12}`,
        content: "",
        priority: nextPriority + 12,
        language: "",
        position: "",
        title: "Abstracts",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 12}` +
          "*" +
          +createTime +
          5555,
        content: "",
        id: `newCreateText_${nextPriority + 13}`,
        language: "",
        position: "subTitle",
        priority: nextPriority + 13,
        title: "Sub Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 12}` +
          "*" +
          +createTime +
          5555,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 14}`,
        language: "en",
        position: "content",
        priority: nextPriority + 14,
        title: "Content",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 15}` +
          "*" +
          +createTime +
          6666,
        id: `newCreateTree_${nextPriority + 15}`,
        content: "",
        priority: nextPriority + 15,
        language: "",
        position: "",
        title: "Abstracts",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 15}` +
          "*" +
          +createTime +
          6666,
        content: "",
        id: `newCreateText_${nextPriority + 16}`,
        language: "",
        position: "subTitle",
        priority: nextPriority + 16,
        title: "Sub Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 15}` +
          "*" +
          +createTime +
          6666,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 17}`,
        language: "en",
        position: "content",
        priority: nextPriority + 17,
        title: "Content",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 18}` +
          "*" +
          +createTime +
          7777,
        id: `newCreateTree_${nextPriority + 18}`,
        content: "",
        priority: nextPriority + 18,
        language: "",
        position: "",
        title: "Abstracts",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 18}` +
          "*" +
          +createTime +
          7777,
        content: "",
        id: `newCreateText_${nextPriority + 19}`,
        language: "",
        position: "subTitle",
        priority: nextPriority + 19,
        title: "Sub Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 18}` +
          "*" +
          +createTime +
          7777,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 20}`,
        language: "",
        position: "content",
        priority: nextPriority + 20,
        title: "Content",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 21}` +
          "*" +
          +createTime +
          8888,
        id: `newCreateTree_${nextPriority + 21}`,
        content: "",
        priority: nextPriority + 21,
        language: "",
        position: "",
        title: "Abstracts",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 21}` +
          "*" +
          +createTime +
          8888,
        content: "",
        id: `newCreateText_${nextPriority + 22}`,
        language: "",
        position: "subTitle",
        priority: nextPriority + 22,
        title: "Sub Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 21}` +
          "*" +
          +createTime +
          8888,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 22}`,
        language: "",
        position: "content",
        priority: nextPriority + 22,
        title: "Content",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 23}` +
          "*" +
          +createTime +
          9999,
        id: `newCreateTree_${nextPriority + 23}`,
        content: "",
        priority: nextPriority + 23,
        language: "",
        position: "",
        title: "Abstracts",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 23}` +
          "*" +
          +createTime +
          9999,
        content: "",
        id: `newCreateText_${nextPriority + 24}`,
        language: "",
        position: "subTitle",
        priority: nextPriority + 24,
        title: "Sub Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 23}` +
          "*" +
          +createTime +
          9999,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 25}`,
        language: "",
        position: "content",
        priority: nextPriority + 25,
        title: "Content",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 26}` +
          "*" +
          +createTime +
          10101010,
        id: `newCreateTree_${nextPriority + 26}`,
        content: "",
        priority: nextPriority + 26,
        language: "",
        position: "",
        title: "Abstracts",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 26}` +
          "*" +
          +createTime +
          10101010,
        content: "",
        id: `newCreateText_${nextPriority + 27}`,
        language: "",
        position: "subTitle",
        priority: nextPriority + 27,
        title: "Sub Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 26}` +
          "*" +
          +createTime +
          10101010,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 28}`,
        language: "",
        position: "content",
        priority: nextPriority + 28,
        title: "Content",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 29}` +
          "*" +
          +createTime +
          11111111,
        id: `newCreateTree_${nextPriority + 29}`,
        content: "",
        priority: nextPriority + 29,
        language: "",
        position: "",
        title: "Sub Session",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 29}` +
          "*" +
          +createTime +
          11111111,
        content: "",
        id: `newCreateText_${nextPriority + 30}`,
        language: "",
        position: "subTitle",
        priority: nextPriority + 30,
        title: "Sub Title (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +createTime +
          "@@" +
          `_treeView_${nextPriority + 29}` +
          "*" +
          +createTime +
          11111111,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 31}`,
        language: "",
        position: "content",
        priority: nextPriority + 31,
        title: "Content",
        type: "wordEditor",
      }
    );

    let newCardID = id + "_card_" + nextPriority + "*" + +createTime;

    let originalSessionContents = [] as any;

    for (
      let i = sessionList[sessionIDX].combineContents.length - 1;
      i >= 0;
      i--
    ) {
      if (sessionList[sessionIDX].combineContents[i].type != "card") {
        originalSessionContents.push(
          sessionList[sessionIDX].combineContents[i]
        );
        sessionList[sessionIDX].combineContents.splice(i, 1);
      }
    }

    sessionList[sessionIDX].combineContents.unshift({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 1}`,
          content: "",
          priority: nextPriority + 1,
          language: "",
          position: "title",
          title: "Title (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateImage_${nextPriority + 2}`,
          url: "",
          priority: nextPriority + 2,
          previewUrl: "",
          position: "image",
          title: "Image",
          type: "image",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 3}` +
            "*" +
            +createTime +
            1111,
          id: `newCreateTree_${nextPriority + 3}`,
          content: "",
          priority: nextPriority + 3,
          language: "",
          position: "",
          title: "Sub Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 3}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateText_${nextPriority + 4}`,
              language: "",
              position: "subTitle",
              priority: nextPriority + 4,
              title: "Sub Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 3}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 5}`,
              language: "",
              position: "content",
              priority: nextPriority + 5,
              title: "Content",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 6}` +
            "*" +
            +createTime +
            2222,
          id: `newCreateTree_${nextPriority + 6}`,
          content: "",
          priority: nextPriority + 6,
          language: "",
          position: "",
          title: "Sub Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 6}` +
                "*" +
                +createTime +
                2222,
              content: "",
              id: `newCreateText_${nextPriority + 7}`,
              language: "",
              position: "subTitle",
              priority: nextPriority + 7,
              title: "Sub Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 6}` +
                "*" +
                +createTime +
                2222,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 8}`,
              language: "",
              position: "content",
              priority: nextPriority + 8,
              title: "Content",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 9}` +
            "*" +
            +createTime +
            3333,
          id: `newCreateTree_${nextPriority + 9}`,
          content: "",
          priority: nextPriority + 9,
          language: "",
          position: "",
          title: "Sub Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 9}` +
                "*" +
                +createTime +
                3333,
              content: "",
              id: `newCreateText_${nextPriority + 10}`,
              language: "",
              position: "subTitle",
              priority: nextPriority + 10,
              title: "Sub Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 9}` +
                "*" +
                +createTime +
                3333,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 11}`,
              language: "",
              position: "content",
              priority: nextPriority + 11,
              title: "Content",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 12}` +
            "*" +
            +createTime +
            5555,
          id: `newCreateTree_${nextPriority + 12}`,
          content: "",
          priority: nextPriority + 12,
          language: "",
          position: "",
          title: "Sub Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 12}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateText_${nextPriority + 13}`,
              language: "",
              position: "subTitle",
              priority: nextPriority + 13,
              title: "Sub Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 12}` +
                "*" +
                +createTime +
                5555,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 14}`,
              language: "",
              position: "content",
              priority: nextPriority + 14,
              title: "Content",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 15}` +
            "*" +
            +createTime +
            6666,
          id: `newCreateTree_${nextPriority + 15}`,
          content: "",
          priority: nextPriority + 15,
          language: "",
          position: "",
          title: "Sub Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 15}` +
                "*" +
                +createTime +
                6666,
              content: "",
              id: `newCreateText_${nextPriority + 16}`,
              language: "",
              position: "subTitle",
              priority: nextPriority + 16,
              title: "Sub Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 15}` +
                "*" +
                +createTime +
                6666,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 17}`,
              language: "",
              position: "content",
              priority: nextPriority + 17,
              title: "Content",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 18}` +
            "*" +
            +createTime +
            7777,
          id: `newCreateTree_${nextPriority + 18}`,
          content: "",
          priority: nextPriority + 18,
          language: "",
          position: "",
          title: "Sub Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 18}` +
                "*" +
                +createTime +
                7777,
              content: "",
              id: `newCreateText_${nextPriority + 19}`,
              language: "",
              position: "subTitle",
              priority: nextPriority + 19,
              title: "Sub Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 18}` +
                "*" +
                +createTime +
                7777,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 20}`,
              language: "",
              position: "content",
              priority: nextPriority + 20,
              title: "Content",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 21}` +
            "*" +
            +createTime +
            8888,
          id: `newCreateTree_${nextPriority + 21}`,
          content: "",
          priority: nextPriority + 21,
          language: "",
          position: "",
          title: "Sub Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 21}` +
                "*" +
                +createTime +
                8888,
              content: "",
              id: `newCreateText_${nextPriority + 22}`,
              language: "",
              position: "subTitle",
              priority: nextPriority + 22,
              title: "Sub Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 21}` +
                "*" +
                +createTime +
                8888,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 22}`,
              language: "",
              position: "content",
              priority: nextPriority + 22,
              title: "Content",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 23}` +
            "*" +
            +createTime +
            9999,
          id: `newCreateTree_${nextPriority + 23}`,
          content: "",
          priority: nextPriority + 23,
          language: "",
          position: "",
          title: "Sub Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 23}` +
                "*" +
                +createTime +
                9999,
              content: "",
              id: `newCreateText_${nextPriority + 24}`,
              language: "",
              position: "subTitle",
              priority: nextPriority + 24,
              title: "Sub Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 23}` +
                "*" +
                +createTime +
                9999,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 25}`,
              language: "",
              position: "content",
              priority: nextPriority + 25,
              title: "Content",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 26}` +
            "*" +
            +createTime +
            10101010,
          id: `newCreateTree_${nextPriority + 26}`,
          content: "",
          priority: nextPriority + 26,
          language: "",
          position: "",
          title: "Sub Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 26}` +
                "*" +
                +createTime +
                10101010,
              content: "",
              id: `newCreateText_${nextPriority + 27}`,
              language: "",
              position: "subTitle",
              priority: nextPriority + 27,
              title: "Sub Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 26}` +
                "*" +
                +createTime +
                10101010,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 28}`,
              language: "",
              position: "content",
              priority: nextPriority + 28,
              title: "Content",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 29}` +
            "*" +
            +createTime +
            11111111,
          id: `newCreateTree_${nextPriority + 29}`,
          content: "",
          priority: nextPriority + 29,
          language: "",
          position: "",
          title: "Sub Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 29}` +
                "*" +
                +createTime +
                11111111,
              content: "",
              id: `newCreateText_${nextPriority + 30}`,
              language: "",
              position: "subTitle",
              priority: nextPriority + 30,
              title: "Sub Title (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 29}` +
                "*" +
                +createTime +
                11111111,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 31}`,
              language: "",
              position: "content",
              priority: nextPriority + 31,
              title: "Content",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
      ],
      cardPosition: nextPriority + nextPriority,
      cardPositionID: "newCreateCardPosition",
    });
    originalSessionContents.forEach((e: any) => {
      sessionList[sessionIDX].combineContents.unshift(e);
    });
    setNewCardArr([...newCardArr, { newCardID: newCardID }]);
  }

  async function deleteCard() {
    let promiseArray = [];
    let deleteContent = false;
    setDeleteDialog(false);
    setIsLoading(true);

    // console.log(selectedCard);
    // if (selectedCard.cardContent)
    if (selectedCard.id && selectedCard.id.includes("new")) {
      sessionList[selectedCardSessionIDX].combineContents.forEach(
        (e: any, idx: number) => {
          if (e.id === selectedCard.id) {
            sessionList[selectedCardSessionIDX].combineContents.splice(idx, 1);
          }
        }
      );
    }
    if (newCardArr.length > 0) {
      await updatePageContent(true);
      newCardArr.splice(0, newCardArr.length);
      setSessionList(sessionList);
    }
    // console.log(selectedCard);
    if (selectedCard.cardPositionID) {
      const request = `{
              deleteContentDetail(where: $where){
                id
              }
            }`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($where: ContentDetailWhereUniqueInput!) ${request}`,
        variables: {
          where: { id: selectedCard.cardPositionID },
        },
      };
      await axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      });
    }
    await Promise.all(
      selectedCard.cardContent.map(async (e: any) => {
        // console.log(e);
        if (
          e.type === "text" ||
          e.type === "wordEditor" ||
          e.position === "cardPosition"
        ) {
          const request = `{
          deleteContentDetail(where: $where){
            id
          }
        }`;
          const graphqlQuery = {
            operationName: "Mutation",
            query: `mutation Mutation($where: ContentDetailWhereUniqueInput!) ${request}`,
            variables: {
              where: { id: e.id },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              // console.log("deleteRes", res);
              promiseArray.push({ content: res });
            })
            .catch((err) => {
              console.error("deleteError", err);
            });
        } else if (e.type === "image" || e.type === "icon") {
          const request = `{
          deleteImageDetail(where: $where){
            id
          }
        }`;
          const graphqlQuery = {
            operationName: "Mutation",
            query: `mutation Mutation($where: ImageDetailWhereUniqueInput!) ${request}`,
            variables: {
              where: { id: e.id },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              // console.log("deleteRes", res);
              promiseArray.push({ content: res });
            })
            .catch((err) => {
              console.error("deleteError", err);
            });
        } else if (e.type === "video") {
          const request = `{
          deleteVideoDetail(where: $where){
            id
          }
        }`;
          const graphqlQuery = {
            operationName: "Mutation",
            query: `mutation Mutation($where: VideoDetailWhereUniqueInput!) ${request}`,
            variables: {
              where: { id: e.id },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              // console.log("deleteRes", res);
              promiseArray.push({ content: res });
            })
            .catch((err) => {
              console.error("deleteError", err);
            });
        } else if (e.type === "audio") {
          const request = `{
          deleteAudioDetail(where: $where){
            id
          }
        }`;
          const graphqlQuery = {
            operationName: "Mutation",
            query: `mutation Mutation($where: AudioDetailWhereUniqueInput!) ${request}`,
            variables: {
              where: { id: e.id },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              // console.log("deleteRes", res);
              promiseArray.push({ content: res });
            })
            .catch((err) => {
              console.error("deleteError", err);
            });
        } else if (e.type === "treeView") {
          await Promise.all(
            e.treeContents.map((treeElement: any) => {
              if (
                treeElement.type === "text" ||
                treeElement.type === "wordEditor"
              ) {
                const request = `{
              deleteContentDetail(where: $where){
                id
              }
            }`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($where: ContentDetailWhereUniqueInput!) ${request}`,
                  variables: {
                    where: { id: treeElement.id },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((res) => {
                    // console.log("deleteRes", res);
                    promiseArray.push({ content: res });
                  })
                  .catch((err) => {
                    console.error("deleteError", err);
                  });
              } else if (treeElement.type === "image") {
                const request = `{
              deleteImageDetail(where: $where){
                id
              }
            }`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($where: ImageDetailWhereUniqueInput!) ${request}`,
                  variables: {
                    where: { id: treeElement.id },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((res) => {
                    // console.log("deleteRes", res);
                    promiseArray.push({ content: res });
                  })
                  .catch((err) => {
                    console.error("deleteError", err);
                  });
              } else if (treeElement.type === "video") {
                const request = `{
              deleteVideoDetail(where: $where){
                id
              }
            }`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($where: VideoDetailWhereUniqueInput!) ${request}`,
                  variables: {
                    where: { id: treeElement.id },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((res) => {
                    // console.log("deleteRes", res);
                    promiseArray.push({ content: res });
                  })
                  .catch((err) => {
                    console.error("deleteError", err);
                  });
              } else if (treeElement.type === "audio") {
                const request = `{
              deleteAudioDetail(where: $where){
                id
              }
            }`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($where: AudioDetailWhereUniqueInput!) ${request}`,
                  variables: {
                    where: { id: treeElement.id },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((res) => {
                    // console.log("deleteRes", res);
                    promiseArray.push({ content: res });
                  })
                  .catch((err) => {
                    console.error("deleteError", err);
                  });
              }
            })
          );
        } else if (e.type === "pdf") {
          const request = `{
          deletePdfDetail(where: $where){
            id
          }
        }`;
          const graphqlQuery = {
            operationName: "Mutation",
            query: `mutation Mutation($where: PdfDetailWhereUniqueInput!) ${request}`,
            variables: {
              where: { id: e.id },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              // console.log("deleteRes", res);
              promiseArray.push({ content: res });
            })
            .catch((err) => {
              console.error("deleteError", err);
            });
        }
      })
    );
    if (id === undefined) {
      id = `Module${moduleNumber}`;
    }
    if (id && id.includes("Module")) {
      id = `Module${+id.split("Module")[1] - 1}`;
    }
    setTimeout(() => {
      dispatch(
        showMessage({
          message: `${id} delete Complete`,
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        })
      );
      setIsLoading(false);
      setIsDisabled(false);
    }, 1000);

    setDeleteModify(!deleteModify);
  }

  useEffect(() => {
    if (isOpenAlert) {
      setIsOpenAlert(true);
      const timeoutId = setTimeout(() => {
        setIsOpenAlert(false);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpenAlert]);

  function createWhatIsACT_CESCard(sessionIDX: number) {
    // setModified(!modified);

    // console.log(sessionList[sessionIDX]);
    setModified(!modified);
    // console.log(sessionList);
    // console.log("combineContents", sessionList[sessionIDX].combineContents);
    // to count the next priority
    const createTime = new Date(Date.now());
    let contentsLastPriority: number;
    let imagesLastPriority: number;
    let audiosLastPriority: number;
    let videosLastPriority: number;
    let cardLastPriority: number;

    if (sessionList[sessionIDX].contents.length > 0) {
      contentsLastPriority =
        sessionList[sessionIDX].contents[
          sessionList[sessionIDX].contents.length - 1
        ].priority;
    } else {
      contentsLastPriority = 0;
    }
    if (sessionList[sessionIDX].images.length > 0) {
      imagesLastPriority =
        sessionList[sessionIDX].images[
          sessionList[sessionIDX].images.length - 1
        ].priority;
    } else {
      imagesLastPriority = 0;
    }
    if (sessionList[sessionIDX].audios.length > 0) {
      audiosLastPriority =
        sessionList[sessionIDX].audios[
          sessionList[sessionIDX].audios.length - 1
        ].priority;
    } else {
      audiosLastPriority = 0;
    }
    if (sessionList[sessionIDX].videos.length > 0) {
      videosLastPriority =
        sessionList[sessionIDX].videos[
          sessionList[sessionIDX].videos.length - 1
        ].priority;
    } else {
      videosLastPriority = 0;
    }
    if (sessionList[sessionIDX].cardID.length > 0) {
      cardLastPriority =
        sessionList[sessionIDX].cardID[
          sessionList[sessionIDX].cardID.length - 1
        ].priority;
    } else {
      cardLastPriority = 0;
    }

    // sum up all the current exist contents priority
    const nextPriority =
      Math.max(
        contentsLastPriority,
        imagesLastPriority,
        audiosLastPriority,
        videosLastPriority,
        cardLastPriority
      ) + 1;

    sessionList[sessionIDX].images.push({
      category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
      id: `newCreateImage_${nextPriority + 1}`,
      url: "",
      priority: nextPriority + 1,
      previewUrl: "",
      position: "cardImage",
      title: "Card Image",
      type: "image",
    });

    sessionList[sessionIDX].contents.push(
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreateText_${nextPriority + 2}`,
        content: "",
        priority: nextPriority + 2,
        language: "zh_hant",
        position: "title",
        title: "Title (繁)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreateText_${nextPriority + 3}`,
        content: "",
        priority: nextPriority + 3,
        language: "en",
        position: "title",
        title: "Title (En)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 4}`,
        language: "zh_hant",
        position: "description",
        priority: nextPriority + 4,
        title: "Description (繁)",
        type: "wordEditor",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 5}`,
        language: "en",
        position: "description",
        priority: nextPriority + 5,
        title: "Description (En)",
        type: "wordEditor",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreateText_${nextPriority + 6}`,
        content: "",
        priority: nextPriority + 6,
        language: "zh_hant",
        position: "sponsor",
        title: "Sponsor (繁)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreateText_${nextPriority + 7}`,
        content: "",
        priority: nextPriority + 7,
        language: "en",
        position: "sponsor",
        title: "Sponsor (En)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreateText_${nextPriority + 8}`,
        content: "",
        priority: nextPriority + 8,
        language: "",
        position: "year",
        title: "Year",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreatePDF_${nextPriority + 9}`,
        url: "",
        priority: nextPriority + 9,
        previewUrl: "",
        position: "pdf",
        title: "PDF",
        type: "pdf",
      }
    );

    sessionList[sessionIDX].cardID.push({
      category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [],
    });

    let newCardID = id + "_card_" + nextPriority + "*" + +createTime;

    let originalSessionContents = [] as any;

    for (
      let i = sessionList[sessionIDX].combineContents.length - 1;
      i >= 0;
      i--
    ) {
      if (sessionList[sessionIDX].combineContents[i].type != "card") {
        originalSessionContents.push(
          sessionList[sessionIDX].combineContents[i]
        );
        sessionList[sessionIDX].combineContents.splice(i, 1);
      }
    }

    sessionList[sessionIDX].combineContents.unshift({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateImage_${nextPriority + 1}`,
          url: "",
          priority: nextPriority + 1,
          previewUrl: "",
          position: "cardImage",
          title: "Card Image",
          type: "image",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 2}`,
          content: "",
          priority: nextPriority + 2,
          language: "zh_hant",
          position: "title",
          title: "Title (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 3}`,
          content: "",
          priority: nextPriority + 3,
          language: "en",
          position: "title",
          title: "Title (En)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateWordEditor_${nextPriority + 4}`,
          language: "zh_hant",
          position: "description",
          priority: nextPriority + 4,
          title: "Description (繁)",
          type: "wordEditor",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateWordEditor_${nextPriority + 5}`,
          language: "en",
          position: "description",
          priority: nextPriority + 5,
          title: "Description (En)",
          type: "wordEditor",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 6}`,
          content: "",
          priority: nextPriority + 6,
          language: "zh_hant",
          position: "sponsor",
          title: "Sponsor (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 7}`,
          content: "",
          priority: nextPriority + 7,
          language: "en",
          position: "sponsor",
          title: "Sponsor (En)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateText_${nextPriority + 8}`,
          content: "",
          priority: nextPriority + 8,
          language: "",
          position: "year",
          title: "Year",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreatePDF_${nextPriority + 9}`,
          url: "",
          priority: nextPriority + 9,
          previewUrl: "",
          position: "pdf",
          title: "PDF",
          type: "pdf",
        },
      ],
      cardPosition: nextPriority + nextPriority,
      cardPositionID: "newCreateCardPosition",
    });

    originalSessionContents.forEach((e: any) => {
      sessionList[sessionIDX].combineContents.unshift(e);
    });

    setNewCardArr([...newCardArr, { newCardID: newCardID }]);
  }

  function createTrainingCard(sessionIDX: number) {
    setModified(!modified);

    let contentsLastPriority: number;
    let imagesLastPriority: number;
    let audiosLastPriority: number;
    let videosLastPriority: number;
    let cardLastPriority: number;

    const createTime = new Date(Date.now());

    if (sessionList[sessionIDX].contents.length > 0) {
      contentsLastPriority =
        sessionList[sessionIDX].contents[
          sessionList[sessionIDX].contents.length - 1
        ].priority;
    } else {
      contentsLastPriority = 0;
    }
    if (sessionList[sessionIDX].images.length > 0) {
      imagesLastPriority =
        sessionList[sessionIDX].images[
          sessionList[sessionIDX].images.length - 1
        ].priority;
    } else {
      imagesLastPriority = 0;
    }
    if (sessionList[sessionIDX].audios.length > 0) {
      audiosLastPriority =
        sessionList[sessionIDX].audios[
          sessionList[sessionIDX].audios.length - 1
        ].priority;
    } else {
      audiosLastPriority = 0;
    }
    if (sessionList[sessionIDX].videos.length > 0) {
      videosLastPriority =
        sessionList[sessionIDX].videos[
          sessionList[sessionIDX].videos.length - 1
        ].priority;
    } else {
      videosLastPriority = 0;
    }
    if (sessionList[sessionIDX].cardID.length > 0) {
      cardLastPriority =
        sessionList[sessionIDX].cardID[
          sessionList[sessionIDX].cardID.length - 1
        ].priority;
    } else {
      cardLastPriority = 0;
    }

    // sum up all the current exist contents priority
    const nextPriority =
      Math.max(
        contentsLastPriority,
        imagesLastPriority,
        audiosLastPriority,
        videosLastPriority,
        cardLastPriority
      ) + 1;

    // for count the next priority
    sessionList[sessionIDX].contents.push(
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 2}`,
        language: "zh_hant",
        position: "title",
        priority: nextPriority + 2,
        title: "Title 1 (繁)",
        type: "wordEditor",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 3}`,
        language: "en",
        position: "title",
        priority: nextPriority + 3,
        title: "Title 1 (En)",
        type: "wordEditor",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        content: "",
        id: `newCreateText_${nextPriority + 4}`,
        language: "",
        position: "time",
        priority: nextPriority + 4,
        title: "Time",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        content: "",
        id: `newCreateText_${nextPriority + 5}`,
        language: "",
        position: "date",
        priority: nextPriority + 5,
        title: "Date",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 6}` +
          "*" +
          +new Date(Date.now() + 1111),
        id: `newCreateTree_${nextPriority + 6}`,
        content: "",
        priority: nextPriority + 6,
        language: "",
        position: "",
        title: "More Info Session",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 6}` +
          "*" +
          +new Date(Date.now() + 1111),
        content: "",
        id: `newCreateText_${nextPriority + 7}`,
        language: "zh_hant",
        position: "organization",
        priority: nextPriority + 7,
        title: "Organization (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 6}` +
          "*" +
          +new Date(Date.now() + 1111),
        content: "",
        id: `newCreateText_${nextPriority + 8}`,
        language: "en",
        position: "organization",
        priority: nextPriority + 8,
        title: "Organization (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 6}` +
          "*" +
          +new Date(Date.now() + 1111),
        content: "zh_hant",
        id: `newCreateText_${nextPriority + 9}`,
        language: "",
        position: "venue",
        priority: nextPriority + 9,
        title: "Venue (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 6}` +
          "*" +
          +new Date(Date.now() + 1111),
        content: "",
        id: `newCreateText_${nextPriority + 10}`,
        language: "en",
        position: "venue",
        priority: nextPriority + 10,
        title: "Venue (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 6}` +
          "*" +
          +new Date(Date.now() + 1111),
        content: "",
        id: `newCreateText_${nextPriority + 11}`,
        language: "zh_hant",
        position: "activity",
        priority: nextPriority + 11,
        title: "Activity (繁)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 6}` +
          "*" +
          +new Date(Date.now() + 1111),
        content: "",
        id: `newCreateText_${nextPriority + 12}`,
        language: "en",
        position: "activity",
        priority: nextPriority + 12,
        title: "Activity (En)",
        type: "text",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 6}` +
          "*" +
          +new Date(Date.now() + 1111),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 13}`,
        language: "zh_hant",
        position: "description",
        priority: nextPriority + 13,
        title: "Description (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 6}` +
          "*" +
          +new Date(Date.now() + 1111),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 14}`,
        language: "en",
        position: "description",
        priority: nextPriority + 14,
        title: "Description (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 15}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateTree_${nextPriority + 15}`,
        content: "",
        priority: nextPriority + 15,
        language: "",
        position: "",
        title: "Training Sub Session",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 15}` +
          "*" +
          +new Date(Date.now() + 2222),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 17}`,
        language: "zh_hant",
        position: "paragraph",
        priority: nextPriority + 17,
        title: "Paragraph (繁)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 15}` +
          "*" +
          +new Date(Date.now() + 2222),
        content: "",
        id: `newCreateWordEditor_${nextPriority + 18}`,
        language: "en",
        position: "paragraph",
        priority: nextPriority + 18,
        title: "Paragraph (En)",
        type: "wordEditor",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 15}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateText_${nextPriority + 19}`,
        content: "",
        priority: nextPriority + 19,
        language: "",
        position: "url",
        title: "Join Url",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreatePDF_${nextPriority + 20}`,
        url: "",
        priority: nextPriority + 20,
        previewUrl: "",
        position: "pdf",
        title: "PDF",
        type: "pdf",
      }
    );
    // for count the next priority
    sessionList[sessionIDX].images.push(
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        id: `newCreateImage_${nextPriority + 1}`,
        url: "",
        priority: nextPriority + 1,
        previewUrl: "",
        position: "image",
        title: "Image",
        type: "image",
      },
      {
        category:
          id +
          "_card_" +
          nextPriority +
          "*" +
          +new Date(Date.now()) +
          "@@" +
          `_treeView_${nextPriority + 15}` +
          "*" +
          +new Date(Date.now() + 2222),
        id: `newCreateImage_${nextPriority + 16}`,
        url: "",
        priority: nextPriority + 16,
        previewUrl: "",
        position: "posterOrImage",
        title: "Poster / Image",
        type: "image",
      }
    );
    // for count the next priority
    sessionList[sessionIDX].cardID.push({
      category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [],
    });

    let newCardID = id + "_card_" + nextPriority + "*" + +createTime;

    let originalSessionContents = [] as any;

    for (
      let i = sessionList[sessionIDX].combineContents.length - 1;
      i >= 0;
      i--
    ) {
      if (sessionList[sessionIDX].combineContents[i].type != "card") {
        originalSessionContents.push(
          sessionList[sessionIDX].combineContents[i]
        );
        sessionList[sessionIDX].combineContents.splice(i, 1);
      }
    }

    // for display contents
    sessionList[sessionIDX].combineContents.unshift({
      category: newCardID,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateImage_${nextPriority + 1}`,
          url: "",
          priority: nextPriority + 1,
          previewUrl: "",
          position: "image",
          title: "Image",
          type: "image",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 2}`,
          language: "zh_hant",
          position: "title",
          priority: nextPriority + 2,
          title: "Title 1 (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 3}`,
          language: "en",
          position: "title",
          priority: nextPriority + 3,
          title: "Title 1 (En)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 4}`,
          language: "",
          position: "time",
          priority: nextPriority + 4,
          title: "Time",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 5}`,
          language: "",
          position: "date",
          priority: nextPriority + 5,
          title: "Date",
          type: "text",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 6}` +
            "*" +
            +createTime +
            1111,
          id: `newCreateTree_${nextPriority + 6}`,
          content: "",
          priority: nextPriority + 6,
          language: "",
          position: "",
          title: "More Info Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 6}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateText_${nextPriority + 7}`,
              language: "zh_hant",
              position: "organization",
              priority: nextPriority + 7,
              title: "Organization (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 6}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateText_${nextPriority + 8}`,
              language: "en",
              position: "organization",
              priority: nextPriority + 8,
              title: "Organization (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 6}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateText_${nextPriority + 9}`,
              language: "zh_hant",
              position: "venue",
              priority: nextPriority + 9,
              title: "Venue (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 6}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateText_${nextPriority + 10}`,
              language: "en",
              position: "venue",
              priority: nextPriority + 10,
              title: "Venue (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 6}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateText_${nextPriority + 11}`,
              language: "zh_hant",
              position: "activity",
              priority: nextPriority + 11,
              title: "Activity (繁)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 6}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateText_${nextPriority + 12}`,
              language: "en",
              position: "activity",
              priority: nextPriority + 12,
              title: "Activity (En)",
              type: "text",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 6}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 13}`,
              language: "zh_hant",
              position: "description",
              priority: nextPriority + 13,
              title: "Description (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 6}` +
                "*" +
                +createTime +
                1111,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 14}`,
              language: "en",
              position: "description",
              priority: nextPriority + 14,
              title: "Description (En)",
              type: "wordEditor",
            },
          ],
          type: "treeView",
        },
        {
          category:
            id +
            "_card_" +
            nextPriority +
            "*" +
            +createTime +
            "@@" +
            `_treeView_${nextPriority + 15}` +
            "*" +
            +createTime +
            2222,
          id: `newCreateTree_${nextPriority + 15}`,
          content: "",
          priority: nextPriority + 15,
          language: "",
          position: "",
          title: "Training Sub Session",
          treeContents: [
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 15}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateImage_${nextPriority + 16}`,
              url: "",
              priority: nextPriority + 16,
              previewUrl: "",
              position: "posterOrImage",
              title: "Poster / Image",
              type: "image",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 15}` +
                "*" +
                +createTime +
                2222,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 17}`,
              language: "zh_hant",
              position: "paragraph",
              priority: nextPriority + 17,
              title: "Paragraph (繁)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 15}` +
                "*" +
                +createTime +
                2222,
              content: "",
              id: `newCreateWordEditor_${nextPriority + 18}`,
              language: "en",
              position: "paragraph",
              priority: nextPriority + 18,
              title: "Paragraph (En)",
              type: "wordEditor",
            },
            {
              category:
                id +
                "_card_" +
                nextPriority +
                "*" +
                +createTime +
                "@@" +
                `_treeView_${nextPriority + 15}` +
                "*" +
                +createTime +
                2222,
              id: `newCreateText_${nextPriority + 19}`,
              content: "",
              priority: nextPriority + 19,
              language: "",
              position: "url",
              title: "Join Url",
              type: "text",
            },
          ],
          type: "treeView",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreatePDF_${nextPriority + 20}`,
          url: "",
          priority: nextPriority + 20,
          previewUrl: "",
          position: "pdf",
          title: "PDF",
          type: "pdf",
        },
      ],
      cardPosition: nextPriority + nextPriority,
      cardPositionID: "newCreateCardPosition",
    });
    originalSessionContents.forEach((e: any) => {
      sessionList[sessionIDX].combineContents.unshift(e);
    });

    setNewCardArr([...newCardArr, { newCardID: newCardID }]);
  }

  function createJobsCard(sessionIDX: number) {
    setModified(!modified);

    // console.log(sessionList[sessionIDX]);
    let contentsLastPriority: number;
    let imagesLastPriority: number;
    let audiosLastPriority: number;
    let videosLastPriority: number;
    let cardLastPriority: number;

    const createTime = new Date(Date.now());

    if (sessionList[sessionIDX].contents.length > 0) {
      contentsLastPriority =
        sessionList[sessionIDX].contents[
          sessionList[sessionIDX].contents.length - 1
        ].priority;
    } else {
      contentsLastPriority = 0;
    }
    if (sessionList[sessionIDX].images.length > 0) {
      imagesLastPriority =
        sessionList[sessionIDX].images[
          sessionList[sessionIDX].images.length - 1
        ].priority;
    } else {
      imagesLastPriority = 0;
    }
    if (sessionList[sessionIDX].audios.length > 0) {
      audiosLastPriority =
        sessionList[sessionIDX].audios[
          sessionList[sessionIDX].audios.length - 1
        ].priority;
    } else {
      audiosLastPriority = 0;
    }
    if (sessionList[sessionIDX].videos.length > 0) {
      videosLastPriority =
        sessionList[sessionIDX].videos[
          sessionList[sessionIDX].videos.length - 1
        ].priority;
    } else {
      videosLastPriority = 0;
    }
    if (sessionList[sessionIDX].cardID.length > 0) {
      cardLastPriority =
        sessionList[sessionIDX].cardID[
          sessionList[sessionIDX].cardID.length - 1
        ].priority;
    } else {
      cardLastPriority = 0;
    }

    // sum up all the current exist contents priority
    const nextPriority =
      Math.max(
        contentsLastPriority,
        imagesLastPriority,
        audiosLastPriority,
        videosLastPriority,
        cardLastPriority
      ) + 1;

    sessionList[sessionIDX].contents.push(
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        content: "",
        id: `newCreateText_${nextPriority + 1}`,
        language: "",
        position: "date",
        priority: nextPriority + 1,
        title: "Publish date",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        content: "",
        id: `newCreateText_${nextPriority + 2}`,
        language: "zh_hant",
        position: "jobTitle",
        priority: nextPriority + 2,
        title: "Job Title (繁)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        content: "",
        id: `newCreateText_${nextPriority + 3}`,
        language: "en",
        position: "jobTitle",
        priority: nextPriority + 3,
        title: "Job Title (En)",
        type: "text",
      }
    );

    sessionList[sessionIDX].cardID.push({
      category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [],
    });

    let newCardID = id + "_card_" + nextPriority + "*" + +createTime;

    let originalSessionContents = [] as any;

    for (
      let i = sessionList[sessionIDX].combineContents.length - 1;
      i >= 0;
      i--
    ) {
      if (sessionList[sessionIDX].combineContents[i].type != "card") {
        originalSessionContents.push(
          sessionList[sessionIDX].combineContents[i]
        );
        sessionList[sessionIDX].combineContents.splice(i, 1);
      }
    }

    sessionList[sessionIDX].combineContents.unshift({
      category: newCardID,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 1}`,
          language: "",
          position: "date",
          priority: nextPriority + 1,
          title: "Published date",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 2}`,
          language: "zh_hant",
          position: "jobTitle",
          priority: nextPriority + 2,
          title: "Open Positions (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 3}`,
          language: "en",
          position: "jobTitle",
          priority: nextPriority + 3,
          title: "Open Positions (En)",
          type: "text",
        },
      ],
      cardPosition: nextPriority + nextPriority,
      cardPositionID: "newCreateCardPosition",
    });

    originalSessionContents.forEach((e: any) => {
      sessionList[sessionIDX].combineContents.unshift(e);
    });

    setNewCardArr([...newCardArr, { newCardID: newCardID }]);
  }

  function createCardCard(sessionIDX: number) {
    setModified(!modified);

    let contentsLastPriority: number;
    let imagesLastPriority: number;
    let audiosLastPriority: number;
    let videosLastPriority: number;
    let cardLastPriority: number;

    const createTime = new Date(Date.now());

    if (sessionList[sessionIDX].contents.length > 0) {
      contentsLastPriority =
        sessionList[sessionIDX].contents[
          sessionList[sessionIDX].contents.length - 1
        ].priority;
    } else {
      contentsLastPriority = 0;
    }
    if (sessionList[sessionIDX].images.length > 0) {
      imagesLastPriority =
        sessionList[sessionIDX].images[
          sessionList[sessionIDX].images.length - 1
        ].priority;
    } else {
      imagesLastPriority = 0;
    }
    if (sessionList[sessionIDX].audios.length > 0) {
      audiosLastPriority =
        sessionList[sessionIDX].audios[
          sessionList[sessionIDX].audios.length - 1
        ].priority;
    } else {
      audiosLastPriority = 0;
    }
    if (sessionList[sessionIDX].videos.length > 0) {
      videosLastPriority =
        sessionList[sessionIDX].videos[
          sessionList[sessionIDX].videos.length - 1
        ].priority;
    } else {
      videosLastPriority = 0;
    }
    if (sessionList[sessionIDX].cardID.length > 0) {
      cardLastPriority =
        sessionList[sessionIDX].cardID[
          sessionList[sessionIDX].cardID.length - 1
        ].priority;
    } else {
      cardLastPriority = 0;
    }

    // sum up all the current exist contents priority
    const nextPriority =
      Math.max(
        contentsLastPriority,
        imagesLastPriority,
        audiosLastPriority,
        videosLastPriority,
        cardLastPriority
      ) + 1;

    sessionList[sessionIDX].contents.push(
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        content: "",
        id: `newCreateText_${nextPriority + 1}`,
        language: "zh_hant",
        position: "title",
        priority: nextPriority + 1,
        title: "Title (繁)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        content: "",
        id: `newCreateText_${nextPriority + 2}`,
        language: "en",
        position: "title",
        priority: nextPriority + 2,
        title: "Title (En)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        content: "",
        id: `newCreateText_${nextPriority + 3}`,
        language: "zh_hant",
        position: "content",
        priority: nextPriority + 3,
        title: "Content (繁)",
        type: "wordEditor",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
        content: "",
        id: `newCreateText_${nextPriority + 4}`,
        language: "en",
        position: "content",
        priority: nextPriority + 4,
        title: "Content (En)",
        type: "wordEditor",
      }
    );

    sessionList[sessionIDX].cardID.push({
      category: id + "_card_" + nextPriority + "*" + +new Date(Date.now()),
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [],
    });

    let newCardID = id + "_card_" + nextPriority + "*" + +createTime;

    let originalSessionContents = [] as any;

    for (
      let i = sessionList[sessionIDX].combineContents.length - 1;
      i >= 0;
      i--
    ) {
      if (sessionList[sessionIDX].combineContents[i].type != "card") {
        originalSessionContents.push(
          sessionList[sessionIDX].combineContents[i]
        );
        sessionList[sessionIDX].combineContents.splice(i, 1);
      }
    }

    sessionList[sessionIDX].combineContents.unshift({
      category: newCardID,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 1}`,
          language: "zh_hant",
          position: "title",
          priority: nextPriority + 1,
          title: "Title (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 2}`,
          language: "en",
          position: "title",
          priority: nextPriority + 2,
          title: "Title (En)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 3}`,
          language: "zh_hant",
          position: "content",
          priority: nextPriority + 3,
          title: "Content (繁)",
          type: "wordEditor",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 4}`,
          language: "en",
          position: "content",
          priority: nextPriority + 4,
          title: "Content (En)",
          type: "wordEditor",
        },
      ],
      cardPosition: nextPriority + nextPriority,
      cardPositionID: "newCreateCardPosition",
    });
    originalSessionContents.forEach((e: any) => {
      sessionList[sessionIDX].combineContents.unshift(e);
    });

    setNewCardArr([...newCardArr, { newCardID: newCardID }]);
  }

  const confirmDelete = (cardElement: any, sessionIDX: number) => {
    // console.log(cardElement);
    setSelectedCard(cardElement);
    setSelectedCardSessionIDX(sessionIDX);
    setDeleteDialog(true);
  };

  const deleteDialogClose = () => {
    setDeleteDialog(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  function createReadingMaterialTreeView(sessionIDX: number, cardIDX: number) {
    if (id === undefined) {
      if (moduleNumber) {
        id = `Module${+moduleNumber + 1}`;
      }
    }
    // console.log(sessionList[sessionIDX].combineContents[cardIDX]);

    setModified(!modified);

    let contentsLastPriority: number;
    let imagesLastPriority: number;
    let audiosLastPriority: number;
    let videosLastPriority: number;
    let cardLastPriority: number;

    const createTime = new Date(Date.now());

    if (sessionList[sessionIDX].contents.length > 0) {
      contentsLastPriority =
        sessionList[sessionIDX].contents[
          sessionList[sessionIDX].contents.length - 1
        ].priority;
    } else {
      contentsLastPriority = 0;
    }
    if (sessionList[sessionIDX].images.length > 0) {
      imagesLastPriority =
        sessionList[sessionIDX].images[
          sessionList[sessionIDX].images.length - 1
        ].priority;
    } else {
      imagesLastPriority = 0;
    }
    if (sessionList[sessionIDX].audios.length > 0) {
      audiosLastPriority =
        sessionList[sessionIDX].audios[
          sessionList[sessionIDX].audios.length - 1
        ].priority;
    } else {
      audiosLastPriority = 0;
    }
    if (sessionList[sessionIDX].videos.length > 0) {
      videosLastPriority =
        sessionList[sessionIDX].videos[
          sessionList[sessionIDX].videos.length - 1
        ].priority;
    } else {
      videosLastPriority = 0;
    }
    if (sessionList[sessionIDX].cardID.length > 0) {
      cardLastPriority =
        sessionList[sessionIDX].cardID[
          sessionList[sessionIDX].cardID.length - 1
        ].priority;
    } else {
      cardLastPriority = 0;
    }

    // sum up all the current exist contents priority
    const nextPriority =
      Math.max(
        contentsLastPriority,
        imagesLastPriority,
        audiosLastPriority,
        videosLastPriority,
        cardLastPriority
      ) + 1;

    sessionList[sessionIDX].contents.push(
      {
        category:
          sessionList[sessionIDX].combineContents[cardIDX].category +
          "@@" +
          `_treeView_${nextPriority}` +
          "*" +
          +createTime +
          1111,
        id: `newCreateTree_${nextPriority}`,
        content: "",
        priority: nextPriority,
        language: "",
        position: "",
        title: "Sub Session",
        treeContents: [],
        type: "treeView",
      },
      {
        category:
          sessionList[sessionIDX].combineContents[cardIDX].category +
          "@@" +
          `_treeView_${nextPriority}` +
          "*" +
          +createTime +
          1111,
        content: "",
        id: `newCreateText_${nextPriority + 1}`,
        language: "",
        position: "subTitle",
        priority: nextPriority + 1,
        title: "Sub Title (繁)",
        type: "text",
      },
      {
        category:
          sessionList[sessionIDX].combineContents[cardIDX].category +
          "@@" +
          `_treeView_${nextPriority}` +
          "*" +
          +createTime +
          1111,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 2}`,
        language: "",
        position: "content",
        priority: nextPriority + 2,
        title: "Content",
        type: "wordEditor",
      }
    );

    let newCardID =
      sessionList[sessionIDX].combineContents[cardIDX].category +
      "@@" +
      `_treeView_${nextPriority}` +
      "*" +
      +createTime +
      1111;

    sessionList[sessionIDX].combineContents[cardIDX].cardContent.push({
      category: newCardID,
      id: `newCreateTree_${nextPriority}`,
      content: "",
      priority: nextPriority,
      language: "",
      position: "",
      title: "Sub Session",
      treeContents: [
        {
          category:
            sessionList[sessionIDX].combineContents[cardIDX].category +
            "@@" +
            `_treeView_${nextPriority}` +
            "*" +
            +createTime +
            1111,
          content: "",
          id: `newCreateText_${nextPriority + 1}`,
          language: "",
          position: "subTitle",
          priority: nextPriority + 1,
          title: "Sub Title (繁)",
          type: "text",
        },
        {
          category:
            sessionList[sessionIDX].combineContents[cardIDX].category +
            "@@" +
            `_treeView_${nextPriority}` +
            "*" +
            +createTime +
            1111,
          content: "",
          id: `newCreateWordEditor_${nextPriority + 2}`,
          language: "",
          position: "content",
          priority: nextPriority + 2,
          title: "Content",
          type: "wordEditor",
        },
      ],
      type: "treeView",
    });
    setNewCardArr([...newCardArr, { newCardID: newCardID }]);
  }

  function createSelfLearningCard(sessionIDX: number) {
    if (id === undefined) {
      if (moduleNumber) {
        id = `Module${+moduleNumber + 1}`;
      }
    }
    setModified(!modified);

    let contentsLastPriority: number;
    let imagesLastPriority: number;
    let audiosLastPriority: number;
    let videosLastPriority: number;
    let cardLastPriority: number;

    const createTime = new Date(Date.now());

    if (sessionList[sessionIDX].contents.length > 0) {
      contentsLastPriority =
        sessionList[sessionIDX].contents[
          sessionList[sessionIDX].contents.length - 1
        ].priority;
    } else {
      contentsLastPriority = 0;
    }
    if (sessionList[sessionIDX].images.length > 0) {
      imagesLastPriority =
        sessionList[sessionIDX].images[
          sessionList[sessionIDX].images.length - 1
        ].priority;
    } else {
      imagesLastPriority = 0;
    }
    if (sessionList[sessionIDX].audios.length > 0) {
      audiosLastPriority =
        sessionList[sessionIDX].audios[
          sessionList[sessionIDX].audios.length - 1
        ].priority;
    } else {
      audiosLastPriority = 0;
    }
    if (sessionList[sessionIDX].videos.length > 0) {
      videosLastPriority =
        sessionList[sessionIDX].videos[
          sessionList[sessionIDX].videos.length - 1
        ].priority;
    } else {
      videosLastPriority = 0;
    }
    if (sessionList[sessionIDX].cardID.length > 0) {
      cardLastPriority =
        sessionList[sessionIDX].cardID[
          sessionList[sessionIDX].cardID.length - 1
        ].priority;
    } else {
      cardLastPriority = 0;
    }

    // sum up all the current exist contents priority
    const nextPriority =
      Math.max(
        contentsLastPriority,
        imagesLastPriority,
        audiosLastPriority,
        videosLastPriority,
        cardLastPriority
      ) + 1;

    sessionList[sessionIDX].contents.push(
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        content: "",
        id: `newCreateText_${nextPriority + 1}`,
        language: "",
        position: "title",
        priority: nextPriority + 1,
        title: "Title (繁)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 2}`,
        language: "",
        position: "content1",
        priority: nextPriority + 2,
        title: "Content 1 (繁)",
        type: "wordEditor",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 4}`,
        language: "",
        position: "content2",
        priority: nextPriority + 4,
        title: "Content 2 (繁)",
        type: "wordEditor",
      }
    );

    sessionList[sessionIDX].videos.push(
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateVideo_${nextPriority + 3}`,
        url: "",
        priority: nextPriority + 3,
        type: "video",
        position: "video1",
        previewUrl: "",
        title: "Video",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateVideo_${nextPriority + 5}`,
        url: "",
        priority: nextPriority + 5,
        type: "video",
        position: "video2",
        previewUrl: "",
        title: "Video",
      }
    );

    sessionList[sessionIDX].cardID.push({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [],
    });

    let newCardID = id + "_card_" + nextPriority + "*" + +createTime;

    let originalSessionContents = [] as any;

    for (
      let i = sessionList[sessionIDX].combineContents.length - 1;
      i >= 0;
      i--
    ) {
      if (sessionList[sessionIDX].combineContents[i].type != "card") {
        originalSessionContents.push(
          sessionList[sessionIDX].combineContents[i]
        );
        sessionList[sessionIDX].combineContents.splice(i, 1);
      }
    }

    sessionList[sessionIDX].combineContents.unshift({
      category: newCardID,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 1}`,
          language: "",
          position: "title",
          priority: nextPriority + 1,
          title: "Title (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateWordEditor_${nextPriority + 2}`,
          language: "",
          position: "content1",
          priority: nextPriority + 2,
          title: "Content 1 (繁)",
          type: "wordEditor",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateVideo_${nextPriority + 3}`,
          url: "",
          priority: nextPriority + 3,
          type: "video",
          position: "video1",
          previewUrl: "",
          title: "Video",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateWordEditor_${nextPriority + 4}`,
          language: "",
          position: "content2",
          priority: nextPriority + 4,
          title: "Content 2 (繁)",
          type: "wordEditor",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateVideo_${nextPriority + 5}`,
          url: "",
          priority: nextPriority + 5,
          type: "video",
          position: "video2",
          previewUrl: "",
          title: "Video",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateImage_${nextPriority + 6}`,
          url: "",
          priority: nextPriority + 6,
          type: "image",
          position: "image",
          previewUrl: "",
          title: "Image",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateWordEditor_${nextPriority + 7}`,
          language: "",
          position: "content3",
          priority: nextPriority + 7,
          title: "Content 3 (繁)",
          type: "wordEditor",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateAudio_${nextPriority + 8}`,
          url: "",
          priority: nextPriority + 8,
          type: "audio",
          position: "audio",
          previewUrl: "",
          title: "Audio",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateWordEditor_${nextPriority + 9}`,
          language: "",
          position: "content4",
          priority: nextPriority + 9,
          title: "Content 4 (繁)",
          type: "wordEditor",
        },
      ],
      cardPosition: nextPriority + nextPriority,
      cardPositionID: "newCreateCardPosition",
    });

    originalSessionContents.forEach((e: any) => {
      sessionList[sessionIDX].combineContents.unshift(e);
    });

    setNewCardArr([...newCardArr, { newCardID: newCardID }]);
  }

  function createLandingOrderCard(sessionIDX: number) {
    setModified(!modified);

    let contentsLastPriority: number;
    let imagesLastPriority: number;
    let audiosLastPriority: number;
    let videosLastPriority: number;
    let cardLastPriority: number;

    const createTime = new Date(Date.now());
    // console.log(sessionList[sessionIDX]);

    if (sessionList[sessionIDX].contents.length > 0) {
      contentsLastPriority =
        sessionList[sessionIDX].contents[
          sessionList[sessionIDX].contents.length - 1
        ].priority;
    } else {
      contentsLastPriority = 0;
    }
    if (sessionList[sessionIDX].images.length > 0) {
      imagesLastPriority =
        sessionList[sessionIDX].images[
          sessionList[sessionIDX].images.length - 1
        ].priority;
    } else {
      imagesLastPriority = 0;
    }
    if (sessionList[sessionIDX].audios.length > 0) {
      audiosLastPriority =
        sessionList[sessionIDX].audios[
          sessionList[sessionIDX].audios.length - 1
        ].priority;
    } else {
      audiosLastPriority = 0;
    }
    if (sessionList[sessionIDX].videos.length > 0) {
      videosLastPriority =
        sessionList[sessionIDX].videos[
          sessionList[sessionIDX].videos.length - 1
        ].priority;
    } else {
      videosLastPriority = 0;
    }
    if (sessionList[sessionIDX].cardID.length > 0) {
      cardLastPriority =
        sessionList[sessionIDX].cardID[
          sessionList[sessionIDX].cardID.length - 1
        ].priority;
    } else {
      cardLastPriority = 0;
    }

    // sum up all the current exist contents priority
    const nextPriority =
      Math.max(
        contentsLastPriority,
        imagesLastPriority,
        audiosLastPriority,
        videosLastPriority,
        cardLastPriority
      ) + 1;

    sessionList[sessionIDX].contents.push(
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        content: "",
        id: `newCreateText_${nextPriority + 2}`,
        language: "zh_hant",
        position: "name",
        priority: nextPriority + 2,
        title: "Name (繁)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        content: "",
        id: `newCreateText_${nextPriority + 3}`,
        language: "en",
        position: "name",
        priority: nextPriority + 3,
        title: "Name (En)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        content: "",
        id: `newCreateText_${nextPriority + 4}`,
        language: "zh_hant",
        position: "title",
        priority: nextPriority + 4,
        title: "Title (繁)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        content: "",
        id: `newCreateText_${nextPriority + 5}`,
        language: "en",
        position: "title",
        priority: nextPriority + 5,
        title: "Title (En)",
        type: "text",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 6}`,
        language: "zh_hant",
        position: "preface",
        priority: nextPriority + 6,
        title: "Preface (繁)",
        type: "wordEditor",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        content: "",
        id: `newCreateWordEditor_${nextPriority + 7}`,
        language: "en",
        position: "preface",
        priority: nextPriority + 7,
        title: "Preface (En)",
        type: "wordEditor",
      }
    );

    sessionList[sessionIDX].images.push(
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateImage_${nextPriority + 1}`,
        url: "",
        priority: nextPriority + 1,
        type: "icon",
        position: "icon",
        previewUrl: "",
        title: "Icon",
      },
      {
        category: id + "_card_" + nextPriority + "*" + +createTime,
        id: `newCreateImage_${nextPriority + 8}`,
        url: "",
        priority: nextPriority + 8,
        type: "image",
        position: "signature",
        previewUrl: "",
        title: "Signature",
      }
    );

    sessionList[sessionIDX].cardID.push({
      category: id + "_card_" + nextPriority + "*" + +createTime,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [],
    });

    let newCardID = id + "_card_" + nextPriority + "*" + +createTime;

    let originalSessionContents = [] as any;

    for (
      let i = sessionList[sessionIDX].combineContents.length - 1;
      i >= 0;
      i--
    ) {
      if (sessionList[sessionIDX].combineContents[i].type != "card") {
        originalSessionContents.push(
          sessionList[sessionIDX].combineContents[i]
        );
        sessionList[sessionIDX].combineContents.splice(i, 1);
      }
    }

    sessionList[sessionIDX].combineContents.unshift({
      category: newCardID,
      id: `newCreateCard_${nextPriority}`,
      priority: nextPriority,
      type: "card",
      cardContent: [
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateImage_${nextPriority + 1}`,
          url: "",
          priority: nextPriority + 1,
          type: "icon",
          position: "icon",
          previewUrl: "",
          title: "Icon",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 2}`,
          language: "zh_hant",
          position: "name",
          priority: nextPriority + 2,
          title: "Title (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 3}`,
          language: "en",
          position: "name",
          priority: nextPriority + 3,
          title: "Title (En)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 4}`,
          language: "zh_hant",
          position: "title",
          priority: nextPriority + 4,
          title: "Name (繁)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateText_${nextPriority + 5}`,
          language: "en",
          position: "title",
          priority: nextPriority + 5,
          title: "Name (En)",
          type: "text",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateWordEditor_${nextPriority + 6}`,
          language: "zh_hant",
          position: "preface",
          priority: nextPriority + 6,
          title: "Preface (繁)",
          type: "wordEditor",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          content: "",
          id: `newCreateWordEditor_${nextPriority + 7}`,
          language: "en",
          position: "preface",
          priority: nextPriority + 7,
          title: "Preface (En)",
          type: "wordEditor",
        },
        {
          category: id + "_card_" + nextPriority + "*" + +createTime,
          id: `newCreateImage_${nextPriority + 8}`,
          url: "",
          priority: nextPriority + 8,
          type: "image",
          position: "signature",
          previewUrl: "",
          title: "Signature",
        },
      ],
      cardPosition: nextPriority + nextPriority,
      cardPositionID: "newCreateCardPosition",
    });

    originalSessionContents.forEach((e: any) => {
      sessionList[sessionIDX].combineContents.unshift(e);
    });
    setNewCardArr([...newCardArr, { newCardID: newCardID }]);
  }

  function edit() {
    const chinese_zh_id = languageIDList.filter(
      (obj: any) => obj.lang === "zh_hant"
    )[0].id;
    const eng_id = languageIDList.filter((obj: any) => obj.lang === "en")[0].id;
    const request = `{
      updateContentDetail(where: $where, data: $data) {
        langLabel {
          id
        }
        id
      }
    }`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${request}`,
      variables: {
        where: { id: "clivi1dx50082lg0hggi77fj6" },
        data: {
          langLabel: {
            disconnect: { id: chinese_zh_id },
          },
        },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => { })
      .catch((err) => console.error(err));
  }

  function delManyAudio() {
    const request = `{
      deleteAudioDetails(where: $where){
        id
      }
    }`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($where: [AudioDetailWhereUniqueInput!]!) ${request}`,
      variables: {
        where: [
          {
            id: "clj5akits021mlm0ix8z30pc1",
          },
          {
            id: "clj5anwjr0274lm0ilsfaiwg6",
          },
        ],
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log("deleteRes", res);
      })
      .catch((err) => {
        console.error("deleteError", err);
      });
  }

  function delManyContent() {
    const request = `{
      deleteContentDetails(where: $where){
        id
      }
    }`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($where: [ContentDetailWhereUniqueInput!]!) ${request}`,
      variables: {
        where: [
          {
            id: "clj5anvol022ylm0i7osp0m9h",
          },
        ],
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log("deleteRes", res);
      })
      .catch((err) => {
        console.error("deleteError", err);
      });
  }

  const [doubleConfirm, setDoubleConfirm] = useState(false);
  const [deleteSessionIDX, setDeleteSessionIDX] = useState(null);
  const [deleteIDX, setDeleteIDX] = useState(null);
  const [deleteCardIDX, setDeleteCardIDX] = useState(null);

  function doubleConfirmDeletePops(
    sessionIDX: number,
    idx: number,
    cardIDX: number
  ) {
    setDeleteSessionIDX(sessionIDX);
    setDeleteIDX(idx);
    setDeleteCardIDX(cardIDX);
    setDoubleConfirm(true);
  }

  function doubleConfirmClose() {
    setDoubleConfirm(false);
  }

  async function deleteTreeView() {
    let promiseArray = [];
    let deleteContent = false;
    let newCreate = false;
    setDoubleConfirm(false);
    // setIsLoading(true);
    // console.log(
    //   sessionList[deleteSessionIDX].combineContents[deleteIDX].cardContent[
    //     deleteCardIDX
    //   ].treeContents
    // );
    sessionList[deleteSessionIDX].combineContents[deleteIDX].cardContent[
      deleteCardIDX
    ].treeContents.forEach((e: any) => {
      if (e.id.includes("newCreate")) {
        newCreate = true;
        sessionList[deleteSessionIDX].combineContents[
          deleteIDX
        ].cardContent.splice(deleteCardIDX, 1);
      } else {
        const request = `{
          updateContentDetail(where: $where, data: $data) {
            isDeleted
            id
          }
        }`;
        const graphqlQuery = {
          operationName: "Mutation",
          query: condense(
            `mutation Mutation($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!)${request}`
          ),
          variables: {
            where: { id: e.id },
            data: { isDeleted: true },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        })
          .then((res) => {
            promiseArray.push({ res });
          })
          .catch((err) => console.error(err));
      }
    });
    if (!newCreate) {
      Promise.all(promiseArray).then((res) => {
        deleteContent = true;
      });
      setTimeout(() => {
        checkValues([deleteContent]).then(() => {
          setTimeout(() => {
            dispatch(
              showMessage({
                message: `Sub Session Delete Complete`,
                autoHideDuration: 6000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                variant: "success",
              })
            );
            setIsLoading(false);
          }, 2000);
        });
      }, 1000);
      setDeleteModify(!deleteModify);
    } else {
      setTimeout(() => {
        dispatch(
          showMessage({
            message: `Sub Session Delete Complete`,
            autoHideDuration: 6000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "success",
          })
        );
        setIsLoading(false);
      }, 500);
    }
  }

  function moveToTheTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  async function updatePageContent(autosave?: boolean) {
    setIsDisabled(true);
    setIsLoading(true);
    let createFirstArr = false;
    let createSecondArr = false;
    let createThirdArr = false;
    let createFourthArr = false;
    let createSumArr = false;
    let updateFirstArr = false;
    let updateSecondArr = false;
    let updateThirdArr = false;
    let updateSumArr = false;

    if (id === undefined) {
      if (moduleNumber) {
        id = `Module${+moduleNumber + 1}`;
      }
    }

    const chinese_zh_id = languageIDList.filter(
      (obj: any) => obj.lang === "zh_hant"
    )[0].id;
    const eng_id = languageIDList.filter((obj: any) => obj.lang === "en")[0].id;
    if (master) {
      const promises = await Promise.all(
        sessionList.map(async (e: any) => {
          if (e.id.includes("newCreateSession")) {
            const graphqlQuery = {
              operationName: "Mutation",
              query: condense(
                `mutation Mutation($data: PageContentCreateInput!) ${createParentPageContentRequest}`
              ),
              variables: {
                data: {
                  title: e.title,
                  session: e.session,
                  sessionImage: "",
                  template: "",
                  belongToParent: {
                    connect: { id: session.id },
                  },
                },
              },
            };
            const createPageContentResult = await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });
            const masterID =
              createPageContentResult.data.data.createPageContent.id;
            const createElementPromises = await Promise.all(
              e.combineContents.map(async (element: any) => {
                if (
                  (element.type === "text" || element.type === "wordEditor") &&
                  element.language === "zh_hant"
                ) {
                  if (element.content.trim() === "<p></p>") {
                    element.content = "";
                  }
                  const elementGraphqlQuery = {
                    operationName: "Mutation",
                    query: condense(
                      `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                    ),
                    variables: {
                      data: {
                        priority: element.priority,
                        position: element.position,
                        content: element.content,
                        category: id,
                        master: {
                          connect: {
                            id: masterID,
                          },
                        },
                        title: element.title,
                        langLabel: {
                          connect: { id: chinese_zh_id },
                        },
                        type: element.type,
                      },
                    },
                  };
                  const createContentDetailResult_zh = await axios({
                    url: endPoint,
                    method: "post",
                    data: elementGraphqlQuery,
                    headers: headers,
                  });
                  // console.log(
                  //   "createContentDetailResult_zh",
                  //   createContentDetailResult_zh
                  // );
                } else if (
                  (element.type === "text" || element.type === "wordEditor") &&
                  element.language === "en"
                ) {
                  if (element.content.trim() === "<p></p>") {
                    element.content = "";
                  }
                  const elementGraphqlQuery = {
                    operationName: "Mutation",
                    query: condense(
                      `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                    ),
                    variables: {
                      data: {
                        priority: element.priority,
                        position: element.position,
                        content: element.content,
                        type: element.type,
                        category: id,
                        master: {
                          connect: {
                            id: masterID,
                          },
                        },
                        title: element.title,
                        langLabel: {
                          connect: { id: eng_id },
                        },
                      },
                    },
                  };
                  const createContentDetailResult_en = await axios({
                    url: endPoint,
                    method: "post",
                    data: elementGraphqlQuery,
                    headers: headers,
                  });
                  // console.log(
                  //   "createContentDetailResult_en",
                  //   createContentDetailResult_en
                  // );
                } else if (
                  element.type === "wordEditor" &&
                  element.language === ""
                ) {
                  if (element.content.trim() === "<p></p>") {
                    element.content = "";
                  }
                  const elementGraphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`,
                    variables: {
                      data: {
                        priority: element.priority,
                        position: element.position,
                        content: element.content,
                        category: id,
                        master: {
                          connect: {
                            id: masterID,
                          },
                        },
                        title: element.title,

                        type: element.type,
                      },
                    },
                  };
                  const createContentDetailResult = await axios({
                    url: endPoint,
                    method: "post",
                    data: elementGraphqlQuery,
                    headers: headers,
                  });
                  // console.log(
                  //   "createContentDetailResult",
                  //   createContentDetailResult
                  // );
                } else if (element.type === "text" && element.language === "") {
                  const elementGraphqlQuery = {
                    operationName: "Mutation",
                    query: condense(
                      `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                    ),
                    variables: {
                      data: {
                        priority: element.priority,
                        position: element.position,
                        content: element.content,
                        type: element.type,
                        category: id,
                        master: {
                          connect: {
                            id: masterID,
                          },
                        },
                        title: element.title,
                      },
                    },
                  };
                  const createContentDetailTextResult = await axios({
                    url: endPoint,
                    method: "post",
                    data: elementGraphqlQuery,
                    headers: headers,
                  });
                  // console.log(
                  //   "createContentDetailTextResult",
                  //   createContentDetailTextResult
                  // );
                } else if (
                  element.type === "image" ||
                  element.type === "icon"
                ) {
                  if (typeof element.url === "string") {
                    const imageGraphqlQuery = {
                      operationName: "Mutation",
                      query: condense(
                        `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                      ),
                      variables: {
                        data: {
                          url: "",
                          category: id,
                          priority: element.priority,
                          master: {
                            connect: {
                              id: masterID,
                            },
                          },
                          position: element.position,
                          title: element.title,
                          type: element.type,
                        },
                      },
                    };
                    const createImageResult = await axios({
                      url: endPoint,
                      method: "post",
                      data: imageGraphqlQuery,
                      headers: headers,
                    });
                    // console.log("createImageResult", createImageResult);
                  } else {
                    let formData = new FormData();
                    formData.append("image", element.url);
                    const uploadImageResult = await axios.post(
                      API.image,
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    );
                    const imageGraphqlQuery = {
                      operationName: "Mutation",
                      query: condense(
                        `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                      ),
                      variables: {
                        data: {
                          url: uploadImageResult.data.url,
                          category: id,
                          priority: element.priority,
                          master: {
                            connect: {
                              id: masterID,
                            },
                          },
                          position: element.position,
                          title: element.title,
                          type: element.type,
                        },
                      },
                    };
                    const createImageDetailResult = await axios({
                      url: endPoint,
                      method: "post",
                      data: imageGraphqlQuery,
                      headers: headers,
                    });
                    // console.log(
                    //   "createImageDetailResult",
                    //   createImageDetailResult
                    // );
                  }
                } else if (element.type === "video") {
                  if (typeof element.url === "string") {
                    const videoGraphqlQuery = {
                      operationName: "CreateVideoDetail",
                      query: condense(
                        `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                      ),
                      variables: {
                        data: {
                          url: element.url,
                          category: id,
                          priority: element.priority,
                          master: {
                            connect: {
                              id: masterID,
                            },
                          },
                          position: element.position,
                          title: element.title,
                          type: element.type,
                        },
                      },
                    };
                    const createVideoStringResult = await axios({
                      url: endPoint,
                      method: "post",
                      data: videoGraphqlQuery,
                      headers: headers,
                    });
                    // console.log(
                    //   "createVideoStringResult",
                    //   createVideoStringResult
                    // );
                  } else {
                    let formData = new FormData();
                    formData.append("video", element.url);
                    const uploadVideoResult = await axios.post(
                      API.video,
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    );

                    const videoGraphqlQuery = {
                      operationName: "CreateVideoDetail",
                      query: condense(
                        `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                      ),
                      variables: {
                        data: {
                          url: uploadVideoResult.data.url,
                          category: id,
                          priority: element.priority,
                          master: {
                            connect: {
                              id: masterID,
                            },
                          },
                          position: element.position,
                          title: element.title,
                          type: element.type,
                        },
                      },
                    };
                    const createVideoResult = await axios({
                      url: endPoint,
                      method: "post",
                      data: videoGraphqlQuery,
                      headers: headers,
                    });
                    // console.log("createVideoResult", createVideoResult);
                  }
                } else if (element.type === "audio") {
                  if (typeof element.url === "string") {
                    const audioGraphqlQuery = {
                      operationName: "CreateAudioDetail",
                      query: condense(
                        `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                      ),
                      variables: {
                        data: {
                          url: "",
                          category: id,
                          priority: element.priority,
                          master: {
                            connect: {
                              id: masterID,
                            },
                          },
                          position: element.position,
                          title: element.title,
                          type: element.type,
                        },
                      },
                    };
                    const createAudioDetailStringResult = await axios({
                      url: endPoint,
                      method: "post",
                      data: audioGraphqlQuery,
                      headers: headers,
                    });
                    // console.log(
                    //   "createAudioDetailStringResult",
                    //   createAudioDetailStringResult
                    // );
                  } else {
                    let formData = new FormData();
                    formData.append("audio", element.url);

                    const uploadAudioResult = await axios.post(
                      API.audio,
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    );
                    const audioGraphqlQuery = {
                      operationName: "CreateAudioDetail",
                      query: condense(
                        `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                      ),
                      variables: {
                        data: {
                          url: uploadAudioResult.data.url,
                          category: id,
                          priority: element.priority,
                          master: {
                            connect: {
                              id: masterID,
                            },
                          },
                          position: element.position,
                          title: element.title,
                          type: element.type,
                        },
                      },
                    };
                    const createAudioDetailResult = await axios({
                      url: endPoint,
                      method: "post",
                      data: audioGraphqlQuery,
                      headers: headers,
                    });
                    // console.log(
                    //   "createAudioDetailResult",
                    //   createAudioDetailResult
                    // );
                  }
                } else if (element.type === "treeView") {
                  const createTreePromises = element.treeContents
                    .map(async (treeElement: any) => {
                      if (
                        treeElement.type === "text" ||
                        (treeElement.type === "wordEditor" &&
                          treeElement.language === "zh_hant")
                      ) {
                        if (treeElement.content.trim() === "<p></p>") {
                          treeElement.content = "";
                        }
                        const treeContentGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                          ),
                          variables: {
                            data: {
                              priority: treeElement.priority,
                              position: treeElement.position,
                              content: treeElement.content,
                              category: treeElement.category,
                              type: treeElement.type,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: treeElement.title,
                              langLabel: {
                                connect: { id: chinese_zh_id },
                              },
                            },
                          },
                        };
                        const createTreeViewContentDetailResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: treeContentGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createTreeViewContentDetailResult",
                        //   createTreeViewContentDetailResult
                        // );
                      } else if (
                        (treeElement.type === "text" ||
                          treeElement.type === "wordEditor") &&
                        treeElement.language === "en"
                      ) {
                        if (treeElement.content.trim() === "<p></p>") {
                          treeElement.content = "";
                        }
                        const treeContentGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                          ),
                          variables: {
                            data: {
                              priority: treeElement.priority,
                              position: treeElement.position,
                              content: treeElement.content,
                              category: treeElement.category,
                              type: treeElement.type,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: treeElement.title,
                              langLabel: {
                                connect: { id: eng_id },
                              },
                            },
                          },
                        };
                        const createTreeViewContentDetailResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: treeContentGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createTreeViewContentDetailResult",
                        //   createTreeViewContentDetailResult
                        // );
                      } else if (
                        treeElement.type === "wordEditor" &&
                        treeElement.language === ""
                      ) {
                        if (treeElement.content.trim() === "<p></p>") {
                          treeElement.content = "";
                        }
                        const elementGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                          ),
                          variables: {
                            data: {
                              priority: treeElement.priority,
                              position: treeElement.position,
                              content: treeElement.content,
                              category: treeElement.category,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: treeElement.title,

                              type: treeElement.type,
                            },
                          },
                        };
                        const createContentDetailResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: elementGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createContentDetailResult",
                        //   createContentDetailResult
                        // );
                      } else if (
                        treeElement.type === "text" &&
                        treeElement.language === ""
                      ) {
                        const elementGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                          ),
                          variables: {
                            data: {
                              priority: treeElement.priority,
                              position: treeElement.position,
                              content: treeElement.content,
                              type: treeElement.type,
                              category: treeElement.category,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: treeElement.title,
                            },
                          },
                        };
                        const createContentDetailResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: elementGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createContentDetailResult",
                        //   createContentDetailResult
                        // );
                      } else if (
                        treeElement.type === "image" ||
                        treeElement.type === "icon"
                      ) {
                        if (typeof treeElement.url === "string") {
                          const imageGraphqlQuery = {
                            operationName: "Mutation",
                            query: condense(
                              `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: "",
                                category: treeElement.category,
                                priority: treeElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: treeElement.position,
                                title: treeElement.title,
                                type: treeElement.type,
                              },
                            },
                          };
                          const createTreeViewImageResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: imageGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createTreeViewImageResult",
                          //   createTreeViewImageResult
                          // );
                        } else {
                          let formData = new FormData();
                          formData.append("image", treeElement.url);
                          const uploadTreeViewImageResult = await axios.post(
                            API.image,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );

                          const imageGraphqlQuery = {
                            operationName: "Mutation",
                            query: condense(
                              `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadTreeViewImageResult.data.url,
                                category: treeElement.category,
                                priority: treeElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: treeElement.position,
                                title: treeElement.title,
                                type: treeElement.type,
                              },
                            },
                          };
                          const createTreeViewContentDetail = await axios({
                            url: endPoint,
                            method: "post",
                            data: imageGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createTreeViewContentDetail",
                          //   createTreeViewContentDetail
                          // );
                        }
                      } else if (treeElement.type === "audio") {
                        if (typeof treeElement.url === "string") {
                          const audioGraphqlQuery = {
                            operationName: "CreateAudioDetail",
                            query: condense(
                              `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: "",
                                category: treeElement.category,
                                priority: treeElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: treeElement.position,
                                title: treeElement.title,
                                type: treeElement.type,
                              },
                            },
                          };
                          const createTreeViewAudioResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: audioGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createTreeViewAudioResult",
                          //   createTreeViewAudioResult
                          // );
                        } else {
                          let formData = new FormData();
                          formData.append("audio", treeElement.url);
                          const uploadTreeViewAudioResult = await axios.post(
                            API.audio,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );

                          const audioGraphqlQuery = {
                            operationName: "CreateAudioDetail",
                            query: condense(
                              `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadTreeViewAudioResult.data.url,
                                category: treeElement.category,
                                priority: treeElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: treeElement.position,
                                title: treeElement.title,
                                type: treeElement.type,
                              },
                            },
                          };
                          const createTreeViewAudioDetailResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: audioGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createTreeViewAudioDetailResult",
                          //   createTreeViewAudioDetailResult
                          // );
                        }
                      } else if (treeElement.type === "video") {
                        if (typeof treeElement.url === "string") {
                          const videoGraphqlQuery = {
                            operationName: "CreateVideoDetail",
                            query: condense(
                              `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: treeElement.url,
                                category: treeElement.category,
                                priority: treeElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: treeElement.position,
                                title: treeElement.title,
                                type: treeElement.type,
                              },
                            },
                          };
                          const createTreeViewVideoResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: videoGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createTreeViewVideoResult",
                          //   createTreeViewVideoResult
                          // );
                        } else {
                          let formData = new FormData();
                          formData.append("video", treeElement.url);
                          const uploadTreeViewVideoResult = await axios.post(
                            API.video,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );

                          const videoGraphqlQuery = {
                            operationName: "CreateVideoDetail",
                            query: condense(
                              `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadTreeViewVideoResult.data.url,
                                category: treeElement.category,
                                priority: treeElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: treeElement.position,
                                title: treeElement.title,
                                type: treeElement.type,
                              },
                            },
                          };
                          const uploadTreeViewVideoDetailResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: videoGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "uploadTreeViewVideoDetailResult",
                          //   uploadTreeViewVideoDetailResult
                          // );
                        }
                      }
                    })
                    .then((res) => (createSecondArr = true));
                } else if (element.type === "card") {
                  if (element.cardPosition) {
                    const cardContentGraphqlQuery = {
                      operationName: "Mutation",
                      query: condense(
                        `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                      ),
                      variables: {
                        data: {
                          position: "cardPosition",
                          content: `${element.cardPosition}`,
                          category: element.category,
                          master: {
                            connect: {
                              id: masterID,
                            },
                          },
                          title: "",

                          type: "",
                        },
                      },
                    };
                    await axios({
                      url: endPoint,
                      method: "post",
                      data: cardContentGraphqlQuery,
                      headers: headers,
                    });
                  }
                  const createCardPromises = await Promise.all(
                    element.cardContent.map(async (cardElement: any) => {
                      if (
                        (cardElement.type === "text" ||
                          cardElement.type === "wordEditor") &&
                        cardElement.language === "zh_hant"
                      ) {
                        if (cardElement.content.trim() === "<p></p>") {
                          cardElement.content = "";
                        }
                        const cardContentGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                          ),
                          variables: {
                            data: {
                              priority: cardElement.priority,
                              position: cardElement.position,
                              content: cardElement.content,
                              category: cardElement.category,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: cardElement.title,
                              langLabel: {
                                connect: { id: chinese_zh_id },
                              },
                              type: cardElement.type,
                            },
                          },
                        };
                        const createContentDetailResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: cardContentGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createContentDetailResult",
                        //   createContentDetailResult
                        // );
                      } else if (
                        (cardElement.type === "text" ||
                          cardElement.type === "wordEditor") &&
                        cardElement.language === "en"
                      ) {
                        if (cardElement.content.trim() === "<p></p>") {
                          cardElement.content = "";
                        }
                        const cardContentEngGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                          ),
                          variables: {
                            data: {
                              priority: cardElement.priority,
                              position: cardElement.position,
                              content: cardElement.content,
                              category: cardElement.category,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: cardElement.title,
                              langLabel: {
                                connect: { id: eng_id },
                              },
                              type: cardElement.type,
                            },
                          },
                        };
                        const createCardContentResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: cardContentEngGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createCardContentResult",
                        //   createCardContentResult
                        // );
                      } else if (
                        cardElement.type === "wordEditor" &&
                        cardElement.language === ""
                      ) {
                        if (cardElement.content.trim() === "<p></p>") {
                          cardElement.content = "";
                        }
                        const elementGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                          ),
                          variables: {
                            data: {
                              priority: cardElement.priority,
                              position: cardElement.position,
                              content: cardElement.content,
                              category: cardElement.category,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: cardElement.title,

                              type: cardElement.type,
                            },
                          },
                        };
                        const createCardContentResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: elementGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createCardContentResult",
                        //   createCardContentResult
                        // );
                      } else if (
                        cardElement.type === "text" &&
                        cardElement.language === ""
                      ) {
                        const elementGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                          ),
                          variables: {
                            data: {
                              priority: cardElement.priority,
                              position: cardElement.position,
                              content: cardElement.content,
                              type: cardElement.type,
                              category: cardElement.category,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: cardElement.title,
                            },
                          },
                        };
                        const createCardContentDetailResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: elementGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createCardContentDetailResult",
                        //   createCardContentDetailResult
                        // );
                      } else if (
                        cardElement.type === "image" ||
                        cardElement.type === "icon"
                      ) {
                        if (typeof cardElement.url === "string") {
                          const imageGraphqlQuery = {
                            operationName: "Mutation",
                            query: condense(
                              `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: "",
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                              },
                            },
                          };
                          const createImageDetailResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: imageGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createImageDetailResult",
                          //   createImageDetailResult
                          // );
                        } else {
                          let formData = new FormData();
                          formData.append("image", cardElement.url);
                          const uploadCardImageResult = await axios.post(
                            API.image,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );
                          const imageGraphqlQuery = {
                            operationName: "Mutation",
                            query: condense(
                              `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadCardImageResult.data.url,
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                              },
                            },
                          };
                          const createCardImageDetailResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: imageGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createCardImageDetailResult",
                          //   createCardImageDetailResult
                          // );
                        }
                      } else if (cardElement.type === "treeView") {
                        const createCardTreePromises = await Promise.all(
                          cardElement.treeContents.map(
                            async (cardTreeContent: any) => {
                              if (
                                (cardTreeContent.type === "text" ||
                                  cardTreeContent.type === "wordEditor") &&
                                cardTreeContent.language === "zh_hant"
                              ) {
                                if (
                                  cardTreeContent.content.trim() === "<p></p>"
                                ) {
                                  cardTreeContent.content = "";
                                }
                                const cardTreeContentGraphqlQuery = {
                                  operationName: "Mutation",
                                  query: condense(
                                    `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                  ),
                                  variables: {
                                    data: {
                                      priority: cardTreeContent.priority,
                                      position: cardTreeContent.position,
                                      content: cardTreeContent.content,
                                      category: cardTreeContent.category,
                                      master: {
                                        connect: {
                                          id: masterID,
                                        },
                                      },
                                      title: cardTreeContent.title,
                                      langLabel: {
                                        connect: {
                                          id: chinese_zh_id,
                                        },
                                      },
                                      type: cardTreeContent.type,
                                    },
                                  },
                                };
                                const createCardContentDetailResult =
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: cardTreeContentGraphqlQuery,
                                    headers: headers,
                                  });
                                // console.log(
                                //   "createCardContentDetailResult",
                                //   createCardContentDetailResult
                                // );
                              } else if (
                                (cardTreeContent.type === "text" ||
                                  cardTreeContent.type === "wordEditor") &&
                                cardTreeContent.language === "en"
                              ) {
                                if (
                                  cardTreeContent.content.trim() === "<p></p>"
                                ) {
                                  cardTreeContent.content = "";
                                }
                                const cardTreeContentGraphqlQuery = {
                                  operationName: "Mutation",
                                  query: condense(
                                    `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                  ),
                                  variables: {
                                    data: {
                                      priority: cardTreeContent.priority,
                                      position: cardTreeContent.position,
                                      content: cardTreeContent.content,
                                      category: cardTreeContent.category,
                                      master: {
                                        connect: {
                                          id: masterID,
                                        },
                                      },
                                      title: cardTreeContent.title,
                                      langLabel: {
                                        connect: {
                                          id: eng_id,
                                        },
                                      },
                                      type: cardTreeContent.type,
                                    },
                                  },
                                };
                                await axios({
                                  url: endPoint,
                                  method: "post",
                                  data: cardTreeContentGraphqlQuery,
                                  headers: headers,
                                });
                              } else if (
                                cardTreeContent.type === "wordEditor" &&
                                cardTreeContent.language === ""
                              ) {
                                if (
                                  cardTreeContent.content.trim() === "<p></p>"
                                ) {
                                  cardTreeContent.content = "";
                                }
                                const elementGraphqlQuery = {
                                  operationName: "Mutation",
                                  query: condense(
                                    `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                                  ),
                                  variables: {
                                    data: {
                                      priority: cardTreeContent.priority,
                                      position: cardTreeContent.position,
                                      content: cardTreeContent.content,
                                      category: cardTreeContent.category,
                                      master: {
                                        connect: {
                                          id: masterID,
                                        },
                                      },
                                      title: cardTreeContent.title,
                                      type: cardTreeContent.type,
                                    },
                                  },
                                };
                                await axios({
                                  url: endPoint,
                                  method: "post",
                                  data: elementGraphqlQuery,
                                  headers: headers,
                                });
                              } else if (
                                cardTreeContent.type === "text" &&
                                cardTreeContent.language === ""
                              ) {
                                const elementGraphqlQuery = {
                                  operationName: "Mutation",
                                  query: condense(
                                    `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                                  ),
                                  variables: {
                                    data: {
                                      priority: cardTreeContent.priority,
                                      position: cardTreeContent.position,
                                      content: cardTreeContent.content,
                                      type: cardTreeContent.type,
                                      category: cardTreeContent.category,
                                      master: {
                                        connect: {
                                          id: masterID,
                                        },
                                      },
                                      title: cardTreeContent.title,
                                    },
                                  },
                                };
                                await axios({
                                  url: endPoint,
                                  method: "post",
                                  data: elementGraphqlQuery,
                                  headers: headers,
                                });
                              } else if (
                                cardTreeContent.type === "image" ||
                                cardTreeContent.type === "icon"
                              ) {
                                if (typeof cardTreeContent.url === "string") {
                                  const imageGraphqlQuery = {
                                    operationName: "Mutation",
                                    query: condense(
                                      `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                    ),
                                    variables: {
                                      data: {
                                        url: "",
                                        category: cardTreeContent.category,
                                        priority: cardTreeContent.priority,
                                        master: {
                                          connect: {
                                            id: masterID,
                                          },
                                        },
                                        position: cardTreeContent.position,
                                        title: cardTreeContent.title,
                                        type: cardTreeContent.type,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: imageGraphqlQuery,
                                    headers: headers,
                                  });
                                } else {
                                  let formData = new FormData();
                                  formData.append("image", cardTreeContent.url);
                                  const uploadCardTreeImageResult =
                                    await axios.post(API.image, formData, {
                                      headers: {
                                        "Content-Type": "multipart/form-data",
                                      },
                                    });

                                  const imageGraphqlQuery = {
                                    operationName: "Mutation",
                                    query: condense(
                                      `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                    ),
                                    variables: {
                                      data: {
                                        url: uploadCardTreeImageResult.data.url,
                                        category: cardTreeContent.category,
                                        priority: cardTreeContent.priority,
                                        master: {
                                          connect: {
                                            id: masterID,
                                          },
                                        },
                                        position: cardTreeContent.position,
                                        title: cardTreeContent.title,
                                        type: cardTreeContent.type,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: imageGraphqlQuery,
                                    headers: headers,
                                  });
                                }
                              } else if (cardTreeContent.type === "audio") {
                                if (typeof cardTreeContent.url === "string") {
                                  const audioGraphqlQuery = {
                                    operationName: "CreateAudioDetail",
                                    query: condense(
                                      `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                    ),
                                    variables: {
                                      data: {
                                        url: "",
                                        category: cardTreeContent.category,
                                        priority: cardTreeContent.priority,
                                        master: {
                                          connect: {
                                            id: masterID,
                                          },
                                        },
                                        position: cardTreeContent.position,
                                        title: cardTreeContent.title,
                                        type: cardTreeContent.type,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: audioGraphqlQuery,
                                    headers: headers,
                                  });
                                } else {
                                  let formData = new FormData();
                                  formData.append("audio", cardTreeContent.url);

                                  const uploadCardTreeContentResult =
                                    await axios.post(API.audio, formData, {
                                      headers: {
                                        "Content-Type": "multipart/form-data",
                                      },
                                    });
                                  const audioGraphqlQuery = {
                                    operationName: "CreateAudioDetail",
                                    query: condense(
                                      `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                    ),
                                    variables: {
                                      data: {
                                        url: uploadCardTreeContentResult.data
                                          .url,
                                        category: cardTreeContent.category,
                                        priority: cardTreeContent.priority,
                                        master: {
                                          connect: {
                                            id: masterID,
                                          },
                                        },
                                        position: cardTreeContent.position,
                                        title: cardTreeContent.title,
                                        type: cardTreeContent.type,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: audioGraphqlQuery,
                                    headers: headers,
                                  });
                                }
                              } else if (cardTreeContent.type === "video") {
                                if (typeof cardTreeContent.url === "string") {
                                  // console.log(cardTreeContent.url);

                                  const audioGraphqlQuery = {
                                    operationName: "CreateVideoDetail",
                                    query: condense(
                                      `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createAudioDetailRequest}`
                                    ),
                                    variables: {
                                      data: {
                                        url: cardTreeContent.url,
                                        category: cardTreeContent.category,
                                        priority: cardTreeContent.priority,
                                        master: {
                                          connect: {
                                            id: masterID,
                                          },
                                        },
                                        position: cardTreeContent.position,
                                        title: cardTreeContent.title,
                                        type: cardTreeContent.type,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: audioGraphqlQuery,
                                    headers: headers,
                                  });
                                } else {
                                  // console.log("here");
                                  let formData = new FormData();
                                  formData.append("video", cardTreeContent.url);

                                  const uploadCardTreeContentResult =
                                    await axios.post(API.video, formData, {
                                      headers: {
                                        "Content-Type": "multipart/form-data",
                                      },
                                    });
                                  const audioGraphqlQuery = {
                                    operationName: "CreateVideoDetail",
                                    query: condense(
                                      `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                    ),
                                    variables: {
                                      data: {
                                        url: uploadCardTreeContentResult.data
                                          .url,
                                        category: cardTreeContent.category,
                                        priority: cardTreeContent.priority,
                                        master: {
                                          connect: {
                                            id: masterID,
                                          },
                                        },
                                        position: cardTreeContent.position,
                                        title: cardTreeContent.title,
                                        type: cardTreeContent.type,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: audioGraphqlQuery,
                                    headers: headers,
                                  });
                                }
                              }
                            }
                          )
                        ).then((res) => (createFourthArr = true));
                      } else if (cardElement.type === "audio") {
                        if (typeof cardElement.url === "string") {
                          const audioGraphqlQuery = {
                            operationName: "CreateAudioDetail",
                            query: condense(
                              `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: "",
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                              },
                            },
                          };
                          await axios({
                            url: endPoint,
                            method: "post",
                            data: audioGraphqlQuery,
                            headers: headers,
                          });
                        } else {
                          let formData = new FormData();
                          formData.append("audio", cardElement.url);

                          const uploadCardElementAudio = await axios.post(
                            API.audio,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );

                          const audioGraphqlQuery = {
                            operationName: "CreateAudioDetail",
                            query: condense(
                              `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadCardElementAudio.data.url,
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                              },
                            },
                          };
                          await axios({
                            url: endPoint,
                            method: "post",
                            data: audioGraphqlQuery,
                            headers: headers,
                          });
                        }
                      } else if (cardElement.type === "video") {
                        if (typeof cardElement.url === "string") {
                          const videoGraphqlQuery = {
                            operationName: "CreateVideoDetail",
                            query: condense(
                              `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: cardElement.url,
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                              },
                            },
                          };
                          await axios({
                            url: endPoint,
                            method: "post",
                            data: videoGraphqlQuery,
                            headers: headers,
                          });
                        } else {
                          let formData = new FormData();
                          formData.append("video", cardElement.url);

                          const uploadCardElementVideo = await axios.post(
                            API.video,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );
                          const audioGraphqlQuery = {
                            operationName: "CreateVideoDetail",
                            query: condense(
                              `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createAudioDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadCardElementVideo.data.url,
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                              },
                            },
                          };
                          await axios({
                            url: endPoint,
                            method: "post",
                            data: audioGraphqlQuery,
                            headers: headers,
                          });
                        }
                      } else if (cardElement.type === "pdf") {
                        if (typeof cardElement.url === "string") {
                          const pdfGraphqlQuery = {
                            operationName: "CreatePdfDetail",
                            query: condense(
                              `mutation CreatePdfDetail($data: PdfDetailCreateInput!) ${createPDFDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: "",
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                                originalFileName: cardElement.url.name,
                              },
                            },
                          };
                          await axios({
                            url: endPoint,
                            method: "post",
                            data: pdfGraphqlQuery,
                            headers: headers,
                          });
                        } else {
                          let formData = new FormData();
                          formData.append("pdf", cardElement.url);

                          const uploadCardElementPDFResult = await axios.post(
                            API.pdf,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );

                          const pdfGraphqlQuery = {
                            operationName: "CreatePdfDetail",
                            query: condense(
                              `mutation CreatePdfDetail($data: PdfDetailCreateInput!) ${createPDFDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadCardElementPDFResult.data.url,
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                                originalFileName: cardElement.url.name,
                              },
                            },
                          };
                          await axios({
                            url: endPoint,
                            method: "post",
                            data: pdfGraphqlQuery,
                            headers: headers,
                          });
                        }
                      }
                    })
                  ).then((res) => (createThirdArr = true));
                }
              })
            ).then((res) => (createFirstArr = true));
          } else {
            await Promise.all(
              e.combineContents.map(async (element: any) => {
                if (element.type === "text" || element.type === "wordEditor") {
                  if (element.content.trim() === "<p></p>") {
                    element.content = "";
                  }
                  if (element.id.includes("new")) {
                    if (element.language === "en") {
                      const elementGraphqlQuery = {
                        operationName: "Mutation",
                        query: condense(
                          `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                        ),
                        variables: {
                          data: {
                            priority: element.priority,
                            position: element.position,
                            content: element.content,
                            category: id,
                            type: element.type,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            title: element.title,
                            langLabel: {
                              connect: { id: eng_id },
                            },
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: elementGraphqlQuery,
                        headers: headers,
                      });
                    } else if (element.language === "zh_hant") {
                      const elementGraphqlQuery = {
                        operationName: "Mutation",
                        query: condense(
                          `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                        ),
                        variables: {
                          data: {
                            priority: element.priority,
                            position: element.position,
                            content: element.content,
                            category: id,
                            type: element.type,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            title: element.title,
                            langLabel: {
                              connect: { id: chinese_zh_id },
                            },
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: elementGraphqlQuery,
                        headers: headers,
                      });
                    } else {
                      const elementGraphqlQuery = {
                        operationName: "Mutation",
                        query: condense(
                          `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                        ),
                        variables: {
                          data: {
                            priority: element.priority,
                            position: element.position,
                            content: element.content,
                            category: id,
                            type: element.type,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            title: element.title,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: elementGraphqlQuery,
                        headers: headers,
                      });
                    }
                  } else {
                    if (element.originalContent === element.content) {
                    } else {
                      const graphqlQuery = {
                        operationName: "UpdateContentDetail",
                        query: condense(
                          `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${updateContentDetailRequest}`
                        ),
                        variables: {
                          where: { id: element.id },
                          data: {
                            content: element.content,
                            priority: element.priority,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: graphqlQuery,
                        headers: headers,
                      });
                    }
                  }
                } else if (
                  element.type === "image" ||
                  element.type === "icon"
                ) {
                  if (element.id.includes("new")) {
                    if (typeof element.url === "string") {
                      const imageGraphqlQuery = {
                        operationName: "Mutation",
                        query: condense(
                          `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                        ),
                        variables: {
                          data: {
                            url: "",
                            category: id,
                            priority: element.priority,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            position: element.position,
                            title: element.title,
                            type: element.type,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: imageGraphqlQuery,
                        headers: headers,
                      });
                    } else {
                      let formData = new FormData();
                      formData.append("image", element.url);
                      const uploadNewImageResult = await axios.post(
                        API.image,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );
                      const imageGraphqlQuery = {
                        operationName: "Mutation",
                        query: condense(
                          `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                        ),
                        variables: {
                          data: {
                            url: uploadNewImageResult.data.url,
                            category: id,
                            priority: element.priority,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            position: element.position,
                            title: element.title,
                            type: element.type,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: imageGraphqlQuery,
                        headers: headers,
                      });
                    }
                  } else {
                    if (typeof element.url === "string") {
                    } else {
                      let formData = new FormData();
                      formData.append("image", element.url);
                      const uploadNewImageResult = await axios.post(
                        API.image,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );

                      const imageUpdateGraphqlQuery = {
                        operationName: "UpdateImageDetail",
                        query: condense(
                          `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, $data: ImageDetailUpdateInput!) ${updateImageDetailRequest}`
                        ),
                        variables: {
                          where: { id: element.id },
                          data: {
                            url: uploadNewImageResult.data.url,
                            priority: element.priority,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: imageUpdateGraphqlQuery,
                        headers: headers,
                      });
                    }
                  }
                } else if (element.type === "video") {
                  if (element.id.includes("new")) {
                    if (typeof element.url === "string") {
                      const videoGraphqlQuery = {
                        operationName: "CreateVideoDetail",
                        query: condense(
                          `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                        ),
                        variables: {
                          data: {
                            url: element.url,
                            category: id,
                            priority: element.priority,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            position: element.position,
                            title: element.title,
                            type: element.type,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: videoGraphqlQuery,
                        headers: headers,
                      });
                    } else {
                      let formData = new FormData();
                      formData.append("video", element.url);
                      const uploadNewVideoResult = await axios.post(
                        API.video,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );

                      const videoGraphqlQuery = {
                        operationName: "CreateVideoDetail",
                        query: condense(
                          `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                        ),
                        variables: {
                          data: {
                            url: uploadNewVideoResult.data.url,
                            category: id,
                            priority: element.priority,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            position: element.position,
                            title: element.title,
                            type: element.type,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: videoGraphqlQuery,
                        headers: headers,
                      });
                    }
                  } else {
                    if (typeof element.url === "string") {
                      const videoUpdateGraphqlQuery = {
                        operationName: "UpdateVideoDetail",
                        query: condense(
                          `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${updateVideoDetailRequest}`
                        ),
                        variables: {
                          where: { id: element.id },
                          data: {
                            url: element.url,
                            priority: element.priority,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: videoUpdateGraphqlQuery,
                        headers: headers,
                      });
                    } else {
                      let formData = new FormData();
                      formData.append("video", element.url);
                      const uploadNewVideoResult = await axios.post(
                        API.video,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );
                      const videoUpdateGraphqlQuery = {
                        operationName: "UpdateVideoDetail",
                        query: condense(
                          `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${updateVideoDetailRequest}`
                        ),
                        variables: {
                          where: { id: element.id },
                          data: {
                            url: uploadNewVideoResult.data.url,
                            priority: element.priority,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: videoUpdateGraphqlQuery,
                        headers: headers,
                      });
                    }
                  }
                } else if (element.type === "audio") {
                  if (element.id.includes("new")) {
                    if (typeof element.url === "string") {
                      const videoGraphqlQuery = {
                        operationName: "CreateAudioDetail",
                        query: condense(
                          `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                        ),
                        variables: {
                          data: {
                            url: "",
                            category: id,
                            priority: element.priority,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            position: element.position,
                            title: element.title,
                            type: element.type,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: videoGraphqlQuery,
                        headers: headers,
                      });
                    } else {
                      let formData = new FormData();
                      formData.append("audio", element.url);
                      const uploadNewAudioResult = await axios.post(
                        API.audio,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );
                      const videoGraphqlQuery = {
                        operationName: "CreateAudioDetail",
                        query: condense(
                          `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                        ),
                        variables: {
                          data: {
                            url: uploadNewAudioResult.data.url,
                            category: id,
                            priority: element.priority,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            position: element.position,
                            title: element.title,
                            type: element.type,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: videoGraphqlQuery,
                        headers: headers,
                      });
                    }
                  } else {
                    if (typeof element.url === "string") {
                    } else {
                      let formData = new FormData();
                      formData.append("audio", element.url);
                      const uploadNewAudioResult = await axios.post(
                        API.audio,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );
                      const audioUpdateGraphqlQuery = {
                        operationName: "UpdateAudioDetail",
                        query: condense(
                          `mutation UpdateAudioDetail($where: AudioDetailWhereUniqueInput!, $data: AudioDetailUpdateInput!) ${updateAudioDetailRequest}`
                        ),
                        variables: {
                          where: { id: element.id },
                          data: {
                            url: uploadNewAudioResult.data.url,
                            priority: element.priority,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: audioUpdateGraphqlQuery,
                        headers: headers,
                      });
                    }
                  }
                } else if (element.type === "card") {
                  // for cardPosition
                  // console.log(element);
                  if (
                    element.cardPositionID &&
                    element.cardPositionID.includes("new")
                  ) {
                    // console.log(element.cardPosition);
                    const cardPositionGraphqlQuery = {
                      operationName: "Mutation",
                      query: condense(
                        `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                      ),
                      variables: {
                        data: {
                          position: "cardPosition",
                          type: "cardPosition",
                          master: {
                            connect: {
                              id: e.id,
                            },
                          },
                          content: `${element.cardPosition}`,
                          category: element.category,
                        },
                      },
                    };
                    let result = await axios({
                      url: endPoint,
                      method: "post",
                      data: cardPositionGraphqlQuery,
                      headers: headers,
                    });
                    // console.log("result", result);
                  }
                  const cardPromise = await Promise.all(
                    element.cardContent.map(
                      async (cardElement: any, cardIDX: number) => {
                        if (
                          cardElement.type === "text" ||
                          cardElement.type === "wordEditor"
                        ) {
                          if (cardElement.content.trim() === "<p></p>") {
                            cardElement.content = "";
                          }
                          if (cardElement.id.includes("new")) {
                            if (cardElement.language === "en") {
                              const elementGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                ),
                                variables: {
                                  data: {
                                    priority: cardElement.priority,
                                    position: cardElement.position,
                                    content: cardElement.content,
                                    category: cardElement.category,
                                    type: cardElement.type,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    title: cardElement.title,
                                    langLabel: {
                                      connect: { id: eng_id },
                                    },
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: elementGraphqlQuery,
                                headers: headers,
                              });
                            } else if (cardElement.language === "zh_hant") {
                              const elementGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                ),
                                variables: {
                                  data: {
                                    priority: cardElement.priority,
                                    position: cardElement.position,
                                    content: cardElement.content,
                                    category: cardElement.category,
                                    type: cardElement.type,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    title: cardElement.title,
                                    langLabel: {
                                      connect: { id: chinese_zh_id },
                                    },
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: elementGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              const elementGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    priority: cardElement.priority,
                                    position: cardElement.position,
                                    content: cardElement.content,
                                    category: cardElement.category,
                                    type: cardElement.type,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    title: cardElement.title,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: elementGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (
                              cardElement.content ===
                              cardElement.originalContent
                            ) {
                            } else {
                              const graphqlQuery = {
                                operationName: "UpdateContentDetail",
                                query: condense(
                                  `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${updateContentDetailRequest}`
                                ),
                                variables: {
                                  where: { id: cardElement.id },
                                  data: {
                                    content: cardElement.content,
                                    priority: cardElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: graphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (
                          cardElement.type === "image" ||
                          cardElement.type === "icon"
                        ) {
                          if (cardElement.id.includes("new")) {
                            if (typeof cardElement.url === "string") {
                              const imageGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: "",
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: imageGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("image", cardElement.url);
                              const uploadCardElementImageResult =
                                await axios.post(API.image, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const imageRequest = `{createImageDetail(data:$data){
                                        url
                                        category
                                        priority
                                        master{
                                          id
                                        }
                                        position
                                        title
                                        type
                                        }}`;
                              const imageGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadCardElementImageResult.data.url,
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                  },
                                },
                              };
                              return await axios({
                                url: endPoint,
                                method: "post",
                                data: imageGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof cardElement.url === "string") {
                            } else {
                              // console.log("here");
                              // console.log(cardElement.url);
                              let formData = new FormData();
                              formData.append("image", cardElement.url);
                              const uploadNewCardElementImageResult =
                                await axios.post(API.image, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const imageUpdateGraphqlQuery = {
                                operationName: "UpdateImageDetail",
                                query: condense(
                                  `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, $data: ImageDetailUpdateInput!) ${updateImageDetailRequest}`
                                ),
                                variables: {
                                  where: { id: cardElement.id },
                                  data: {
                                    url: uploadNewCardElementImageResult.data
                                      .url,
                                    priority: cardElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: imageUpdateGraphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (cardElement.type === "video") {
                          if (cardElement.id.includes("new")) {
                            if (typeof cardElement.url === "string") {
                              const videoGraphqlQuery = {
                                operationName: "CreateVideoDetail",
                                query: condense(
                                  `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: cardElement.url,
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("video", cardElement.url);
                              const uploadNewVideoResult = await axios.post(
                                API.video,
                                formData,
                                {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                }
                              );

                              const videoGraphqlQuery = {
                                operationName: "CreateVideoDetail",
                                query: condense(
                                  `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadNewVideoResult.data.url,
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof cardElement.url === "string") {
                              const videoUpdateGraphqlQuery = {
                                operationName: "UpdateVideoDetail",
                                query: condense(
                                  `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${updateVideoDetailRequest}`
                                ),
                                variables: {
                                  where: { id: cardElement.id },
                                  data: {
                                    url: cardElement.url,
                                    priority: cardElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoUpdateGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("video", cardElement.url);
                              const uploadCardElementVideoResult =
                                await axios.post(API.video, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const videoUpdateGraphqlQuery = {
                                operationName: "UpdateVideoDetail",
                                query: condense(
                                  `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${updateVideoDetailRequest}`
                                ),
                                variables: {
                                  where: { id: cardElement.id },
                                  data: {
                                    url: uploadCardElementVideoResult.data.url,
                                    priority: cardElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoUpdateGraphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (cardElement.type === "audio") {
                          if (cardElement.id.includes("new")) {
                            if (typeof cardElement.url === "string") {
                              const videoGraphqlQuery = {
                                operationName: "CreateAudioDetail",
                                query: condense(
                                  `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: "",
                                    category: id,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("audio", cardElement.url);
                              const uploadNewCardElementAudioResult =
                                await axios.post(API.audio, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });
                              const videoGraphqlQuery = {
                                operationName: "CreateAudioDetail",
                                query: condense(
                                  `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadNewCardElementAudioResult.data
                                      .url,
                                    category: id,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof cardElement.url === "string") {
                            } else {
                              let formData = new FormData();
                              formData.append("audio", cardElement.url);
                              const uploadUpdateAudioResult = await axios.post(
                                API.audio,
                                formData,
                                {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                }
                              );

                              const audioUpdateGraphqlQuery = {
                                operationName: "UpdateAudioDetail",
                                query: condense(
                                  `mutation UpdateAudioDetail($where: AudioDetailWhereUniqueInput!, $data: AudioDetailUpdateInput!) ${updateAudioDetailRequest}`
                                ),
                                variables: {
                                  where: { id: cardElement.id },
                                  data: {
                                    url: uploadUpdateAudioResult.data.url,
                                    priority: cardElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: audioUpdateGraphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (cardElement.type === "treeView") {
                          const cardTreePromise = await Promise.all(
                            cardElement.treeContents.map(
                              async (treeElement: any, treeIDX: number) => {
                                if (
                                  treeElement.type === "text" ||
                                  treeElement.type === "wordEditor"
                                ) {
                                  if (
                                    treeElement.content.trim() === "<p></p>"
                                  ) {
                                    treeElement.content = "";
                                  }
                                  if (treeElement.id.includes("new")) {
                                    if (treeElement.language === "en") {
                                      const elementGraphqlQuery = {
                                        operationName: "Mutation",
                                        query: condense(
                                          `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            priority: treeElement.priority,
                                            position: treeElement.position,
                                            content: treeElement.content,
                                            type: treeElement.type,
                                            category: treeElement.category,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            title: treeElement.title,
                                            langLabel: {
                                              connect: { id: eng_id },
                                            },
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: elementGraphqlQuery,
                                        headers: headers,
                                      });
                                    } else if (
                                      treeElement.language === "zh_hant"
                                    ) {
                                      const elementGraphqlQuery = {
                                        operationName: "Mutation",
                                        query: condense(
                                          `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            priority: treeElement.priority,
                                            position: treeElement.position,
                                            content: treeElement.content,
                                            category: treeElement.category,
                                            type: treeElement.type,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            title: treeElement.title,
                                            langLabel: {
                                              connect: { id: chinese_zh_id },
                                            },
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: elementGraphqlQuery,
                                        headers: headers,
                                      });
                                    } else {
                                      const elementGraphqlQuery = {
                                        operationName: "Mutation",
                                        query: condense(
                                          `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            priority: treeElement.priority,
                                            position: treeElement.position,
                                            content: treeElement.content,
                                            type: treeElement.type,
                                            category: treeElement.category,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            title: treeElement.title,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: elementGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  } else {
                                    if (
                                      treeElement.content ===
                                      treeElement.originalContent
                                    ) {
                                    } else {
                                      const graphqlQuery = {
                                        operationName: "Mutation",
                                        query: condense(
                                          `mutation Mutation($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${updateContentDetailRequest}`
                                        ),
                                        variables: {
                                          where: { id: treeElement.id },
                                          data: {
                                            content: treeElement.content,
                                            priority: treeElement.priority,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: graphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  }
                                } else if (
                                  treeElement.type === "image" ||
                                  treeElement.type === "icon"
                                ) {
                                  if (treeElement.id.includes("new")) {
                                    if (typeof treeElement.url === "string") {
                                      const imageGraphqlQuery = {
                                        operationName: "Mutation",
                                        query: condense(
                                          `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            url: "",
                                            category: treeElement.category,
                                            priority: treeElement.priority,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            position: treeElement.position,
                                            title: treeElement.title,
                                            type: treeElement.type,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: imageGraphqlQuery,
                                        headers: headers,
                                      });
                                    } else {
                                      let formData = new FormData();
                                      formData.append("image", treeElement.url);
                                      const uploadTreeElementImageResult =
                                        await axios.post(API.image, formData, {
                                          headers: {
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        });
                                      const imageGraphqlQuery = {
                                        operationName: "Mutation",
                                        query: condense(
                                          `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            url: uploadTreeElementImageResult
                                              .data.url,
                                            category: treeElement.category,
                                            priority: treeElement.priority,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            position: treeElement.position,
                                            title: treeElement.title,
                                            type: treeElement.type,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: imageGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  } else {
                                    if (typeof treeElement.url === "string") {
                                    } else {
                                      let formData = new FormData();
                                      formData.append("image", treeElement.url);
                                      const uploadTreeElementImageResult =
                                        await axios.post(API.image, formData, {
                                          headers: {
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        });
                                      const imageUpdateGraphqlQuery = {
                                        operationName: "UpdateImageDetail",
                                        query: condense(
                                          `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, $data: ImageDetailUpdateInput!) ${updateImageDetailRequest}`
                                        ),
                                        variables: {
                                          where: { id: treeElement.id },
                                          data: {
                                            url: uploadTreeElementImageResult
                                              .data.url,
                                            priority: treeElement.priority,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: imageUpdateGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  }
                                } else if (treeElement.type === "video") {
                                  if (treeElement.id.includes("new")) {
                                    if (typeof treeElement.url === "string") {
                                      const videoGraphqlQuery = {
                                        operationName: "CreateVideoDetail",
                                        query: condense(
                                          `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            url: treeElement.url,
                                            category: treeElement.category,
                                            priority: treeElement.priority,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            position: treeElement.position,
                                            title: treeElement.title,
                                            type: treeElement.type,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: videoGraphqlQuery,
                                        headers: headers,
                                      });
                                    } else {
                                      let formData = new FormData();
                                      formData.append("video", treeElement.url);
                                      const uploadTreeElementVideoResult =
                                        await axios.post(API.video, formData, {
                                          headers: {
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        });
                                      const videoGraphqlQuery = {
                                        operationName: "CreateVideoDetail",
                                        query: condense(
                                          `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            url: uploadTreeElementVideoResult
                                              .data.url,
                                            category: treeElement.category,
                                            priority: treeElement.priority,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            position: treeElement.position,
                                            title: treeElement.title,
                                            type: treeElement.type,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: videoGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  } else {
                                    if (typeof treeElement.url === "string") {
                                      const videoUpdateRequest = `{
                                        updateVideoDetail(where: $where, data: $data){
                                        url
                                        priority
                                        id
                                      }
                                    }`;
                                      const videoUpdateGraphqlQuery = {
                                        operationName: "UpdateVideoDetail",
                                        query: condense(
                                          `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${videoUpdateRequest}`
                                        ),
                                        variables: {
                                          where: { id: treeElement.id },
                                          data: {
                                            url: treeElement.url,
                                            priority: treeElement.priority,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: videoUpdateGraphqlQuery,
                                        headers: headers,
                                      });
                                    } else {
                                      // console.log("here?????????????????????");
                                      let formData = new FormData();
                                      formData.append("video", treeElement.url);
                                      // console.log(
                                      //   "treeElement.url",
                                      //   treeElement.url
                                      // );
                                      const uploadTreeElementVideoResult =
                                        await axios.post(API.video, formData, {
                                          headers: {
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        });
                                      // console.log(
                                      //   "uploadTreeElementVideoResult",
                                      //   uploadTreeElementVideoResult
                                      // );
                                      const videoUpdateRequest = `{
                                            updateVideoDetail(where: $where, data: $data){
                                            url
                                            priority
                                            id
                                          }
                                        }`;
                                      const videoUpdateGraphqlQuery = {
                                        operationName: "UpdateVideoDetail",
                                        query: condense(
                                          `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${videoUpdateRequest}`
                                        ),
                                        variables: {
                                          where: { id: treeElement.id },
                                          data: {
                                            url: uploadTreeElementVideoResult
                                              .data.url,
                                            priority: treeElement.priority,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: videoUpdateGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  }
                                } else if (treeElement.type === "audio") {
                                  if (treeElement.id.includes("new")) {
                                    if (typeof treeElement.url === "string") {
                                      const videoGraphqlQuery = {
                                        operationName: "CreateAudioDetail",
                                        query: condense(
                                          `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            url: "",
                                            category: treeElement.category,
                                            priority: treeElement.priority,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            position: treeElement.position,
                                            title: treeElement.title,
                                            type: treeElement.type,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: videoGraphqlQuery,
                                        headers: headers,
                                      });
                                    } else {
                                      let formData = new FormData();
                                      formData.append("audio", treeElement.url);
                                      const uploadTreeElementAudioResult =
                                        await axios.post(API.audio, formData, {
                                          headers: {
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        });

                                      const videoGraphqlQuery = {
                                        operationName: "CreateAudioDetail",
                                        query: condense(
                                          `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            url: uploadTreeElementAudioResult
                                              .data.url,
                                            category: treeElement.category,
                                            priority: treeElement.priority,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            position: treeElement.position,
                                            title: treeElement.title,
                                            type: treeElement.type,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: videoGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  } else {
                                    if (typeof treeElement.url === "string") {
                                    } else {
                                      let formData = new FormData();
                                      formData.append("audio", treeElement.url);
                                      const uploadTreeElementAudioResult =
                                        await axios.post(API.audio, formData, {
                                          headers: {
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        });

                                      const audioUpdateRequest = `{
                                            updateAudioDetail(where: $where, data: $data){
                                            url
                                            priority
                                            id
                                          }
                                        }`;
                                      const audioUpdateGraphqlQuery = {
                                        operationName: "UpdateAudioDetail",
                                        query: condense(
                                          `mutation UpdateAudioDetail($where: AudioDetailWhereUniqueInput!, $data: AudioDetailUpdateInput!) ${audioUpdateRequest}`
                                        ),
                                        variables: {
                                          where: { id: treeElement.id },
                                          data: {
                                            url: uploadTreeElementAudioResult
                                              .data.url,
                                            priority: treeElement.priority,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: audioUpdateGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  }
                                }
                              }
                            )
                          ).then((res) => (updateSecondArr = true));
                        } else if (cardElement.type === "pdf") {
                          if (cardElement.id.includes("new")) {
                            if (typeof cardElement.url === "string") {
                              const pdfGraphqlQuery = {
                                operationName: "CreatePdfDetail",
                                query: condense(
                                  `mutation CreatePdfDetail($data: PdfDetailCreateInput!) ${createPDFDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: "",
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                    originalFileName: cardElement.url.name,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: pdfGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("pdf", cardElement.url);
                              const uploadCardElementPDFResult =
                                await axios.post(API.pdf, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const pdfRequest = `{createPdfDetail(data:$data){
                                        url
                                        category
                                        priority
                                        master{
                                          id
                                        }
                                        position
                                        title
                                        type
                                        originalFileName
                                        }}`;
                              const pdfGraphqlQuery = {
                                operationName: "CreatePdfDetail",
                                query: condense(
                                  `mutation CreatePdfDetail($data: PdfDetailCreateInput!) ${createPDFDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadCardElementPDFResult.data.url,
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                    originalFileName: cardElement.url.name,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: pdfGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof cardElement.url === "string") {
                            } else {
                              let formData = new FormData();
                              formData.append("pdf", cardElement.url);
                              await axios
                                .post(API.pdf, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                })
                                .then(async (pdfRes) => {
                                  const pdfUpdateRequest = `{
                                      updatePdfDetail(where: $where, data: $data){
                                        url
                                        priority
                                        id
                                        originalFileName
                                      }
                                    }`;
                                  const pdfUpdateGraphqlQuery = {
                                    operationName: "UpdatePdfDetail",
                                    query: condense(
                                      `mutation UpdatePdfDetail($where: PdfDetailWhereUniqueInput!, $data: PdfDetailUpdateInput!) ${updatePDFDetailRequest}`
                                    ),
                                    variables: {
                                      where: { id: cardElement.id },
                                      data: {
                                        url: pdfRes.data.url,
                                        priority: cardElement.priority,
                                        originalFileName: cardElement.url.name,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: pdfUpdateGraphqlQuery,
                                    headers: headers,
                                  });
                                });
                            }
                          }
                        }
                      }
                    )
                  ).then((res) => (updateFirstArr = true));
                } else if (element.type === "treeView") {
                  const treePromise = await Promise.all(
                    element.treeContents.map(
                      async (treeElement: any, treeIDX: number) => {
                        if (
                          treeElement.type === "text" ||
                          treeElement.type === "wordEditor"
                        ) {
                          if (treeElement.content.trim() === "<p></p>") {
                            treeElement.content = "";
                          }
                          if (treeElement.id.includes("new")) {
                            if (treeElement.language === "en") {
                              const elementGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                ),
                                variables: {
                                  data: {
                                    priority: treeElement.priority,
                                    position: treeElement.position,
                                    content: treeElement.content,
                                    type: treeElement.type,
                                    category: treeElement.category,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    title: treeElement.title,
                                    langLabel: {
                                      connect: { id: eng_id },
                                    },
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: elementGraphqlQuery,
                                headers: headers,
                              });
                            } else if (treeElement.language === "zh_hant") {
                              const elementGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                ),
                                variables: {
                                  data: {
                                    priority: treeElement.priority,
                                    position: treeElement.position,
                                    content: treeElement.content,
                                    category: treeElement.category,
                                    type: treeElement.type,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    title: treeElement.title,
                                    langLabel: {
                                      connect: { id: chinese_zh_id },
                                    },
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: elementGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              const elementGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    priority: treeElement.priority,
                                    position: treeElement.position,
                                    content: treeElement.content,
                                    type: treeElement.type,
                                    category: treeElement.category,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    title: treeElement.title,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: elementGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (
                              treeElement.content ===
                              treeElement.originalContent
                            ) {
                            } else {
                              const graphqlQuery = {
                                operationName: "UpdateContentDetail",
                                query: condense(
                                  `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${updateContentDetailRequest}`
                                ),
                                variables: {
                                  where: { id: treeElement.id },
                                  data: {
                                    content: treeElement.content,
                                    priority: treeElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: graphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (
                          treeElement.type === "image" ||
                          treeElement.type === "icon"
                        ) {
                          if (treeElement.id.includes("new")) {
                            if (typeof treeElement.url === "string") {
                              const imageGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: "",
                                    category: treeElement.category,
                                    priority: treeElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: treeElement.position,
                                    title: treeElement.title,
                                    type: treeElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: imageGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("image", treeElement.url);
                              const uploadTreeElementImageResult =
                                await axios.post(API.image, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const imageGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadTreeElementImageResult.data.url,
                                    category: treeElement.category,
                                    priority: treeElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: treeElement.position,
                                    title: treeElement.title,
                                    type: treeElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: imageGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof treeElement.url === "string") {
                            } else {
                              let formData = new FormData();
                              formData.append("image", treeElement.url);
                              const uploadTreeElementImageResult =
                                await axios.post(API.image, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });
                              const imageUpdateGraphqlQuery = {
                                operationName: "UpdateImageDetail",
                                query: condense(
                                  `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, $data: ImageDetailUpdateInput!) ${updateImageDetailRequest}`
                                ),
                                variables: {
                                  where: { id: treeElement.id },
                                  data: {
                                    url: uploadTreeElementImageResult.data.url,
                                    priority: treeElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: imageUpdateGraphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (treeElement.type === "video") {
                          if (treeElement.id.includes("new")) {
                            if (typeof treeElement.url === "string") {
                              const videoGraphqlQuery = {
                                operationName: "CreateVideoDetail",
                                query: condense(
                                  `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: treeElement.url,
                                    category: treeElement.category,
                                    priority: treeElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: treeElement.position,
                                    title: treeElement.title,
                                    type: treeElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("video", treeElement.url);
                              const uploadTreeElementVideoResult =
                                await axios.post(API.video, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });
                              const videoGraphqlQuery = {
                                operationName: "CreateVideoDetail",
                                query: condense(
                                  `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadTreeElementVideoResult.data.url,
                                    category: treeElement.category,
                                    priority: treeElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: treeElement.position,
                                    title: treeElement.title,
                                    type: treeElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof treeElement.url === "string") {
                              const videoUpdateGraphqlQuery = {
                                operationName: "UpdateVideoDetail",
                                query: condense(
                                  `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${updateVideoDetailRequest}`
                                ),
                                variables: {
                                  where: { id: treeElement.id },
                                  data: {
                                    url: treeElement.url,
                                    priority: treeElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoUpdateGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("video", treeElement.url);
                              const uploadTreeElementVideoResult =
                                await axios.post(API.video, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const videoUpdateGraphqlQuery = {
                                operationName: "UpdateVideoDetail",
                                query: condense(
                                  `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${updateVideoDetailRequest}`
                                ),
                                variables: {
                                  where: { id: treeElement.id },
                                  data: {
                                    url: uploadTreeElementVideoResult.data.url,
                                    priority: treeElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoUpdateGraphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (treeElement.type === "audio") {
                          if (treeElement.id.includes("new")) {
                            if (typeof treeElement.url === "string") {
                              const videoGraphqlQuery = {
                                operationName: "CreateVideoDetail",
                                query: condense(
                                  `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: treeElement.url,
                                    category: treeElement.category,
                                    priority: treeElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: treeElement.position,
                                    title: treeElement.title,
                                    type: treeElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("audio", treeElement.url);
                              const uploadTreeElementAudioRequest =
                                await axios.post(API.audio, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const videoGraphqlQuery = {
                                operationName: "CreateVideoDetail",
                                query: condense(
                                  `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadTreeElementAudioRequest.data.url,
                                    category: treeElement.category,
                                    priority: treeElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: treeElement.position,
                                    title: treeElement.title,
                                    type: treeElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof treeElement.url === "string") {
                            } else {
                              let formData = new FormData();
                              formData.append("audio", treeElement.url);
                              const uploadTreeElementAudioResult =
                                await axios.post(API.audio, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });
                              const audioUpdateGraphqlQuery = {
                                operationName: "UpdateAudioDetail",
                                query: condense(
                                  `mutation UpdateAudioDetail($where: AudioDetailWhereUniqueInput!, $data: AudioDetailUpdateInput!) ${updateAudioDetailRequest}`
                                ),
                                variables: {
                                  where: { id: treeElement.id },
                                  data: {
                                    url: uploadTreeElementAudioResult.data.url,
                                    priority: treeElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: audioUpdateGraphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        }
                      }
                    )
                  ).then((res) => (updateThirdArr = true));
                }
              })
            ).then((res) => (updateSumArr = true));
          }
        })
      ).then((res) => (createSumArr = true));
    } else {
      const promises = await Promise.all(
        sessionList.map(async (e: any) => {
          if (e.id.includes("newCreateSession")) {
            const graphqlQuery = {
              operationName: "Mutation",
              query: condense(
                `mutation Mutation($data: PageContentCreateInput!) ${createChildPageContentRequest}`
              ),
              variables: {
                data: {
                  title: e.title,
                  session: e.session,
                  sessionImage: "",
                  template: "",
                  belongToChild: {
                    connect: { id: session.id },
                  },
                },
              },
            };
            const createPageContentResult = await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });
            const masterID =
              createPageContentResult.data.data.createPageContent.id;
            const createElementPromises = await Promise.all(
              e.combineContents.map(async (element: any) => {
                if (
                  (element.type === "text" || element.type === "wordEditor") &&
                  element.language === "zh_hant"
                ) {
                  if (element.content.trim() === "<p></p>") {
                    element.content = "";
                  }
                  const elementGraphqlQuery = {
                    operationName: "Mutation",
                    query: condense(
                      `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                    ),
                    variables: {
                      data: {
                        priority: element.priority,
                        position: element.position,
                        content: element.content,
                        category: id,
                        master: {
                          connect: {
                            id: masterID,
                          },
                        },
                        title: element.title,
                        langLabel: {
                          connect: { id: chinese_zh_id },
                        },
                        type: element.type,
                      },
                    },
                  };
                  const createContentDetailResult_zh = await axios({
                    url: endPoint,
                    method: "post",
                    data: elementGraphqlQuery,
                    headers: headers,
                  });
                } else if (
                  (element.type === "text" || element.type === "wordEditor") &&
                  element.language === "en"
                ) {
                  if (element.content.trim() === "<p></p>") {
                    element.content = "";
                  }
                  const elementGraphqlQuery = {
                    operationName: "Mutation",
                    query: condense(
                      `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                    ),
                    variables: {
                      data: {
                        priority: element.priority,
                        position: element.position,
                        content: element.content,
                        type: element.type,
                        category: id,
                        master: {
                          connect: {
                            id: masterID,
                          },
                        },
                        title: element.title,
                        langLabel: {
                          connect: { id: eng_id },
                        },
                      },
                    },
                  };
                  const createContentDetailResult_en = await axios({
                    url: endPoint,
                    method: "post",
                    data: elementGraphqlQuery,
                    headers: headers,
                  });
                  // console.log(
                  //   "createContentDetailResult_en",
                  //   createContentDetailResult_en
                  // );
                } else if (
                  element.type === "wordEditor" &&
                  element.language === ""
                ) {
                  if (element.content.trim() === "<p></p>") {
                    element.content = "";
                  }
                  const elementGraphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`,
                    variables: {
                      data: {
                        priority: element.priority,
                        position: element.position,
                        content: element.content,
                        category: id,
                        master: {
                          connect: {
                            id: masterID,
                          },
                        },
                        title: element.title,

                        type: element.type,
                      },
                    },
                  };
                  const createContentDetailResult = await axios({
                    url: endPoint,
                    method: "post",
                    data: elementGraphqlQuery,
                    headers: headers,
                  });
                  // console.log(
                  //   "createContentDetailResult",
                  //   createContentDetailResult
                  // );
                } else if (element.type === "text" && element.language === "") {
                  const elementGraphqlQuery = {
                    operationName: "Mutation",
                    query: condense(
                      `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                    ),
                    variables: {
                      data: {
                        priority: element.priority,
                        position: element.position,
                        content: element.content,
                        type: element.type,
                        category: id,
                        master: {
                          connect: {
                            id: masterID,
                          },
                        },
                        title: element.title,
                      },
                    },
                  };
                  const createContentDetailTextResult = await axios({
                    url: endPoint,
                    method: "post",
                    data: elementGraphqlQuery,
                    headers: headers,
                  });
                  // console.log(
                  //   "createContentDetailTextResult",
                  //   createContentDetailTextResult
                  // );
                } else if (
                  element.type === "image" ||
                  element.type === "icon"
                ) {
                  if (typeof element.url === "string") {
                    const imageGraphqlQuery = {
                      operationName: "Mutation",
                      query: condense(
                        `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                      ),
                      variables: {
                        data: {
                          url: "",
                          category: id,
                          priority: element.priority,
                          master: {
                            connect: {
                              id: masterID,
                            },
                          },
                          position: element.position,
                          title: element.title,
                          type: element.type,
                        },
                      },
                    };
                    const createImageResult = await axios({
                      url: endPoint,
                      method: "post",
                      data: imageGraphqlQuery,
                      headers: headers,
                    });
                    // console.log("createImageResult", createImageResult);
                  } else {
                    let formData = new FormData();
                    formData.append("image", element.url);
                    const uploadImageResult = await axios.post(
                      API.image,
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    );
                    const imageGraphqlQuery = {
                      operationName: "Mutation",
                      query: condense(
                        `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                      ),
                      variables: {
                        data: {
                          url: uploadImageResult.data.url,
                          category: id,
                          priority: element.priority,
                          master: {
                            connect: {
                              id: masterID,
                            },
                          },
                          position: element.position,
                          title: element.title,
                          type: element.type,
                        },
                      },
                    };
                    const createImageDetailResult = await axios({
                      url: endPoint,
                      method: "post",
                      data: imageGraphqlQuery,
                      headers: headers,
                    });
                    // console.log(
                    //   "createImageDetailResult",
                    //   createImageDetailResult
                    // );
                  }
                } else if (element.type === "video") {
                  if (typeof element.url === "string") {
                    const videoGraphqlQuery = {
                      operationName: "CreateVideoDetail",
                      query: condense(
                        `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                      ),
                      variables: {
                        data: {
                          url: element.url,
                          category: id,
                          priority: element.priority,
                          master: {
                            connect: {
                              id: masterID,
                            },
                          },
                          position: element.position,
                          title: element.title,
                          type: element.type,
                        },
                      },
                    };
                    const createVideoStringResult = await axios({
                      url: endPoint,
                      method: "post",
                      data: videoGraphqlQuery,
                      headers: headers,
                    });
                    // console.log(
                    //   "createVideoStringResult",
                    //   createVideoStringResult
                    // );
                  } else {
                    let formData = new FormData();
                    formData.append("video", element.url);
                    const uploadVideoResult = await axios.post(
                      API.video,
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    );

                    const videoGraphqlQuery = {
                      operationName: "CreateVideoDetail",
                      query: condense(
                        `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                      ),
                      variables: {
                        data: {
                          url: uploadVideoResult.data.url,
                          category: id,
                          priority: element.priority,
                          master: {
                            connect: {
                              id: masterID,
                            },
                          },
                          position: element.position,
                          title: element.title,
                          type: element.type,
                        },
                      },
                    };
                    const createVideoResult = await axios({
                      url: endPoint,
                      method: "post",
                      data: videoGraphqlQuery,
                      headers: headers,
                    });
                    // console.log("createVideoResult", createVideoResult);
                  }
                } else if (element.type === "audio") {
                  if (typeof element.url === "string") {
                    const audioGraphqlQuery = {
                      operationName: "CreateAudioDetail",
                      query: condense(
                        `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                      ),
                      variables: {
                        data: {
                          url: "",
                          category: id,
                          priority: element.priority,
                          master: {
                            connect: {
                              id: masterID,
                            },
                          },
                          position: element.position,
                          title: element.title,
                          type: element.type,
                        },
                      },
                    };
                    const createAudioDetailStringResult = await axios({
                      url: endPoint,
                      method: "post",
                      data: audioGraphqlQuery,
                      headers: headers,
                    });
                    // console.log(
                    //   "createAudioDetailStringResult",
                    //   createAudioDetailStringResult
                    // );
                  } else {
                    let formData = new FormData();
                    formData.append("audio", element.url);

                    const uploadAudioResult = await axios.post(
                      API.audio,
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    );
                    const audioGraphqlQuery = {
                      operationName: "CreateAudioDetail",
                      query: condense(
                        `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                      ),
                      variables: {
                        data: {
                          url: uploadAudioResult.data.url,
                          category: id,
                          priority: element.priority,
                          master: {
                            connect: {
                              id: masterID,
                            },
                          },
                          position: element.position,
                          title: element.title,
                          type: element.type,
                        },
                      },
                    };
                    const createAudioDetailResult = await axios({
                      url: endPoint,
                      method: "post",
                      data: audioGraphqlQuery,
                      headers: headers,
                    });
                    // console.log(
                    //   "createAudioDetailResult",
                    //   createAudioDetailResult
                    // );
                  }
                } else if (element.type === "treeView") {
                  const createTreePromises = element.treeContents
                    .map(async (treeElement: any) => {
                      if (
                        treeElement.type === "text" ||
                        (treeElement.type === "wordEditor" &&
                          treeElement.language === "zh_hant")
                      ) {
                        if (treeElement.content.trim() === "<p></p>") {
                          treeElement.content = "";
                        }
                        const treeContentGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                          ),
                          variables: {
                            data: {
                              priority: treeElement.priority,
                              position: treeElement.position,
                              content: treeElement.content,
                              category: treeElement.category,
                              type: treeElement.type,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: treeElement.title,
                              langLabel: {
                                connect: { id: chinese_zh_id },
                              },
                            },
                          },
                        };
                        const createTreeViewContentDetailResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: treeContentGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createTreeViewContentDetailResult",
                        //   createTreeViewContentDetailResult
                        // );
                      } else if (
                        (treeElement.type === "text" ||
                          treeElement.type === "wordEditor") &&
                        treeElement.language === "en"
                      ) {
                        if (treeElement.content.trim() === "<p></p>") {
                          treeElement.content = "";
                        }
                        const treeContentGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                          ),
                          variables: {
                            data: {
                              priority: treeElement.priority,
                              position: treeElement.position,
                              content: treeElement.content,
                              category: treeElement.category,
                              type: treeElement.type,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: treeElement.title,
                              langLabel: {
                                connect: { id: eng_id },
                              },
                            },
                          },
                        };
                        const createTreeViewContentDetailResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: treeContentGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createTreeViewContentDetailResult",
                        //   createTreeViewContentDetailResult
                        // );
                      } else if (
                        treeElement.type === "wordEditor" &&
                        treeElement.language === ""
                      ) {
                        if (treeElement.content.trim() === "<p></p>") {
                          treeElement.content = "";
                        }
                        const elementGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                          ),
                          variables: {
                            data: {
                              priority: treeElement.priority,
                              position: treeElement.position,
                              content: treeElement.content,
                              category: treeElement.category,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: treeElement.title,

                              type: treeElement.type,
                            },
                          },
                        };
                        const createContentDetailResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: elementGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createContentDetailResult",
                        //   createContentDetailResult
                        // );
                      } else if (
                        treeElement.type === "text" &&
                        treeElement.language === ""
                      ) {
                        const elementGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                          ),
                          variables: {
                            data: {
                              priority: treeElement.priority,
                              position: treeElement.position,
                              content: treeElement.content,
                              type: treeElement.type,
                              category: treeElement.category,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: treeElement.title,
                            },
                          },
                        };
                        const createContentDetailResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: elementGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createContentDetailResult",
                        //   createContentDetailResult
                        // );
                      } else if (
                        treeElement.type === "image" ||
                        treeElement.type === "icon"
                      ) {
                        if (typeof treeElement.url === "string") {
                          const imageGraphqlQuery = {
                            operationName: "Mutation",
                            query: condense(
                              `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: "",
                                category: treeElement.category,
                                priority: treeElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: treeElement.position,
                                title: treeElement.title,
                                type: treeElement.type,
                              },
                            },
                          };
                          const createTreeViewImageResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: imageGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createTreeViewImageResult",
                          //   createTreeViewImageResult
                          // );
                        } else {
                          let formData = new FormData();
                          formData.append("image", treeElement.url);
                          const uploadTreeViewImageResult = await axios.post(
                            API.image,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );

                          const imageGraphqlQuery = {
                            operationName: "Mutation",
                            query: condense(
                              `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadTreeViewImageResult.data.url,
                                category: treeElement.category,
                                priority: treeElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: treeElement.position,
                                title: treeElement.title,
                                type: treeElement.type,
                              },
                            },
                          };
                          const createTreeViewContentDetail = await axios({
                            url: endPoint,
                            method: "post",
                            data: imageGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createTreeViewContentDetail",
                          //   createTreeViewContentDetail
                          // );
                        }
                      } else if (treeElement.type === "audio") {
                        if (typeof treeElement.url === "string") {
                          const audioGraphqlQuery = {
                            operationName: "CreateAudioDetail",
                            query: condense(
                              `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: "",
                                category: treeElement.category,
                                priority: treeElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: treeElement.position,
                                title: treeElement.title,
                                type: treeElement.type,
                              },
                            },
                          };
                          const createTreeViewAudioResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: audioGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createTreeViewAudioResult",
                          //   createTreeViewAudioResult
                          // );
                        } else {
                          let formData = new FormData();
                          formData.append("audio", treeElement.url);
                          const uploadTreeViewAudioResult = await axios.post(
                            API.audio,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );

                          const audioGraphqlQuery = {
                            operationName: "CreateAudioDetail",
                            query: condense(
                              `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadTreeViewAudioResult.data.url,
                                category: treeElement.category,
                                priority: treeElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: treeElement.position,
                                title: treeElement.title,
                                type: treeElement.type,
                              },
                            },
                          };
                          const createTreeViewAudioDetailResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: audioGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createTreeViewAudioDetailResult",
                          //   createTreeViewAudioDetailResult
                          // );
                        }
                      } else if (treeElement.type === "video") {
                        if (typeof treeElement.url === "string") {
                          const videoGraphqlQuery = {
                            operationName: "CreateVideoDetail",
                            query: condense(
                              `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: treeElement.url,
                                category: treeElement.category,
                                priority: treeElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: treeElement.position,
                                title: treeElement.title,
                                type: treeElement.type,
                              },
                            },
                          };
                          const createTreeViewVideoResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: videoGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createTreeViewVideoResult",
                          //   createTreeViewVideoResult
                          // );
                        } else {
                          let formData = new FormData();
                          formData.append("video", treeElement.url);
                          const uploadTreeViewVideoResult = await axios.post(
                            API.video,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );

                          const videoGraphqlQuery = {
                            operationName: "CreateVideoDetail",
                            query: condense(
                              `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadTreeViewVideoResult.data.url,
                                category: treeElement.category,
                                priority: treeElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: treeElement.position,
                                title: treeElement.title,
                                type: treeElement.type,
                              },
                            },
                          };
                          const uploadTreeViewVideoDetailResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: videoGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "uploadTreeViewVideoDetailResult",
                          //   uploadTreeViewVideoDetailResult
                          // );
                        }
                      }
                    })
                    .then((res) => (createSecondArr = true));
                } else if (element.type === "card") {
                  const createCardPromises = await Promise.all(
                    element.cardContent.map(async (cardElement: any) => {
                      if (
                        (cardElement.type === "text" ||
                          cardElement.type === "wordEditor") &&
                        cardElement.language === "zh_hant"
                      ) {
                        if (cardElement.content.trim() === "<p></p>") {
                          cardElement.content = "";
                        }
                        const cardContentGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                          ),
                          variables: {
                            data: {
                              priority: cardElement.priority,
                              position: cardElement.position,
                              content: cardElement.content,
                              category: cardElement.category,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: cardElement.title,
                              langLabel: {
                                connect: { id: chinese_zh_id },
                              },
                              type: cardElement.type,
                            },
                          },
                        };
                        const createContentDetailResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: cardContentGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createContentDetailResult",
                        //   createContentDetailResult
                        // );
                      } else if (
                        (cardElement.type === "text" ||
                          cardElement.type === "wordEditor") &&
                        cardElement.language === "en"
                      ) {
                        if (cardElement.content.trim() === "<p></p>") {
                          cardElement.content = "";
                        }
                        const cardContentEngGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                          ),
                          variables: {
                            data: {
                              priority: cardElement.priority,
                              position: cardElement.position,
                              content: cardElement.content,
                              category: cardElement.category,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: cardElement.title,
                              langLabel: {
                                connect: { id: eng_id },
                              },
                              type: cardElement.type,
                            },
                          },
                        };
                        const createCardContentResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: cardContentEngGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createCardContentResult",
                        //   createCardContentResult
                        // );
                      } else if (
                        cardElement.type === "wordEditor" &&
                        cardElement.language === ""
                      ) {
                        if (cardElement.content.trim() === "<p></p>") {
                          cardElement.content = "";
                        }
                        const elementGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                          ),
                          variables: {
                            data: {
                              priority: cardElement.priority,
                              position: cardElement.position,
                              content: cardElement.content,
                              category: cardElement.category,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: cardElement.title,

                              type: cardElement.type,
                            },
                          },
                        };
                        const createCardContentResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: elementGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createCardContentResult",
                        //   createCardContentResult
                        // );
                      } else if (
                        cardElement.type === "text" &&
                        cardElement.language === ""
                      ) {
                        const elementGraphqlQuery = {
                          operationName: "Mutation",
                          query: condense(
                            `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                          ),
                          variables: {
                            data: {
                              priority: cardElement.priority,
                              position: cardElement.position,
                              content: cardElement.content,
                              type: cardElement.type,
                              category: cardElement.category,
                              master: {
                                connect: {
                                  id: masterID,
                                },
                              },
                              title: cardElement.title,
                            },
                          },
                        };
                        const createCardContentDetailResult = await axios({
                          url: endPoint,
                          method: "post",
                          data: elementGraphqlQuery,
                          headers: headers,
                        });
                        // console.log(
                        //   "createCardContentDetailResult",
                        //   createCardContentDetailResult
                        // );
                      } else if (
                        cardElement.type === "image" ||
                        cardElement.type === "icon"
                      ) {
                        if (typeof cardElement.url === "string") {
                          const imageGraphqlQuery = {
                            operationName: "Mutation",
                            query: condense(
                              `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: "",
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                              },
                            },
                          };
                          const createImageDetailResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: imageGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createImageDetailResult",
                          //   createImageDetailResult
                          // );
                        } else {
                          let formData = new FormData();
                          formData.append("image", cardElement.url);
                          const uploadCardImageResult = await axios.post(
                            API.image,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );
                          const imageGraphqlQuery = {
                            operationName: "Mutation",
                            query: condense(
                              `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadCardImageResult.data.url,
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                              },
                            },
                          };
                          const createCardImageDetailResult = await axios({
                            url: endPoint,
                            method: "post",
                            data: imageGraphqlQuery,
                            headers: headers,
                          });
                          // console.log(
                          //   "createCardImageDetailResult",
                          //   createCardImageDetailResult
                          // );
                        }
                      } else if (cardElement.type === "treeView") {
                        const createCardTreePromises = await Promise.all(
                          cardElement.treeContents.map(
                            async (cardTreeContent: any) => {
                              if (
                                (cardTreeContent.type === "text" ||
                                  cardTreeContent.type === "wordEditor") &&
                                cardTreeContent.language === "zh_hant"
                              ) {
                                if (
                                  cardTreeContent.content.trim() === "<p></p>"
                                ) {
                                  cardTreeContent.content = "";
                                }
                                const cardTreeContentGraphqlQuery = {
                                  operationName: "Mutation",
                                  query: condense(
                                    `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                  ),
                                  variables: {
                                    data: {
                                      priority: cardTreeContent.priority,
                                      position: cardTreeContent.position,
                                      content: cardTreeContent.content,
                                      category: cardTreeContent.category,
                                      master: {
                                        connect: {
                                          id: masterID,
                                        },
                                      },
                                      title: cardTreeContent.title,
                                      langLabel: {
                                        connect: {
                                          id: chinese_zh_id,
                                        },
                                      },
                                      type: cardTreeContent.type,
                                    },
                                  },
                                };
                                const createCardContentDetailResult =
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: cardTreeContentGraphqlQuery,
                                    headers: headers,
                                  });
                                // console.log(
                                //   "createCardContentDetailResult",
                                //   createCardContentDetailResult
                                // );
                              } else if (
                                (cardTreeContent.type === "text" ||
                                  cardTreeContent.type === "wordEditor") &&
                                cardTreeContent.language === "en"
                              ) {
                                if (
                                  cardTreeContent.content.trim() === "<p></p>"
                                ) {
                                  cardTreeContent.content = "";
                                }
                                const cardTreeContentGraphqlQuery = {
                                  operationName: "Mutation",
                                  query: condense(
                                    `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                  ),
                                  variables: {
                                    data: {
                                      priority: cardTreeContent.priority,
                                      position: cardTreeContent.position,
                                      content: cardTreeContent.content,
                                      category: cardTreeContent.category,
                                      master: {
                                        connect: {
                                          id: masterID,
                                        },
                                      },
                                      title: cardTreeContent.title,
                                      langLabel: {
                                        connect: {
                                          id: eng_id,
                                        },
                                      },
                                      type: cardTreeContent.type,
                                    },
                                  },
                                };
                                await axios({
                                  url: endPoint,
                                  method: "post",
                                  data: cardTreeContentGraphqlQuery,
                                  headers: headers,
                                });
                              } else if (
                                cardTreeContent.type === "wordEditor" &&
                                cardTreeContent.language === ""
                              ) {
                                if (
                                  cardTreeContent.content.trim() === "<p></p>"
                                ) {
                                  cardTreeContent.content = "";
                                }
                                const elementGraphqlQuery = {
                                  operationName: "Mutation",
                                  query: condense(
                                    `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                                  ),
                                  variables: {
                                    data: {
                                      priority: cardTreeContent.priority,
                                      position: cardTreeContent.position,
                                      content: cardTreeContent.content,
                                      category: cardTreeContent.category,
                                      master: {
                                        connect: {
                                          id: masterID,
                                        },
                                      },
                                      title: cardTreeContent.title,
                                      type: cardTreeContent.type,
                                    },
                                  },
                                };
                                await axios({
                                  url: endPoint,
                                  method: "post",
                                  data: elementGraphqlQuery,
                                  headers: headers,
                                });
                              } else if (
                                cardTreeContent.type === "text" &&
                                cardTreeContent.language === ""
                              ) {
                                const elementGraphqlQuery = {
                                  operationName: "Mutation",
                                  query: condense(
                                    `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                                  ),
                                  variables: {
                                    data: {
                                      priority: cardTreeContent.priority,
                                      position: cardTreeContent.position,
                                      content: cardTreeContent.content,
                                      type: cardTreeContent.type,
                                      category: cardTreeContent.category,
                                      master: {
                                        connect: {
                                          id: masterID,
                                        },
                                      },
                                      title: cardTreeContent.title,
                                    },
                                  },
                                };
                                await axios({
                                  url: endPoint,
                                  method: "post",
                                  data: elementGraphqlQuery,
                                  headers: headers,
                                });
                              } else if (
                                cardTreeContent.type === "image" ||
                                cardTreeContent.type === "icon"
                              ) {
                                if (typeof cardTreeContent.url === "string") {
                                  const imageGraphqlQuery = {
                                    operationName: "Mutation",
                                    query: condense(
                                      `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                    ),
                                    variables: {
                                      data: {
                                        url: "",
                                        category: cardTreeContent.category,
                                        priority: cardTreeContent.priority,
                                        master: {
                                          connect: {
                                            id: masterID,
                                          },
                                        },
                                        position: cardTreeContent.position,
                                        title: cardTreeContent.title,
                                        type: cardTreeContent.type,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: imageGraphqlQuery,
                                    headers: headers,
                                  });
                                } else {
                                  let formData = new FormData();
                                  formData.append("image", cardTreeContent.url);
                                  const uploadCardTreeImageResult =
                                    await axios.post(API.image, formData, {
                                      headers: {
                                        "Content-Type": "multipart/form-data",
                                      },
                                    });

                                  const imageGraphqlQuery = {
                                    operationName: "Mutation",
                                    query: condense(
                                      `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                    ),
                                    variables: {
                                      data: {
                                        url: uploadCardTreeImageResult.data.url,
                                        category: cardTreeContent.category,
                                        priority: cardTreeContent.priority,
                                        master: {
                                          connect: {
                                            id: masterID,
                                          },
                                        },
                                        position: cardTreeContent.position,
                                        title: cardTreeContent.title,
                                        type: cardTreeContent.type,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: imageGraphqlQuery,
                                    headers: headers,
                                  });
                                }
                              } else if (cardTreeContent.type === "audio") {
                                if (typeof cardTreeContent.url === "string") {
                                  const audioGraphqlQuery = {
                                    operationName: "CreateAudioDetail",
                                    query: condense(
                                      `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                    ),
                                    variables: {
                                      data: {
                                        url: "",
                                        category: cardTreeContent.category,
                                        priority: cardTreeContent.priority,
                                        master: {
                                          connect: {
                                            id: masterID,
                                          },
                                        },
                                        position: cardTreeContent.position,
                                        title: cardTreeContent.title,
                                        type: cardTreeContent.type,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: audioGraphqlQuery,
                                    headers: headers,
                                  });
                                } else {
                                  let formData = new FormData();
                                  formData.append("audio", cardTreeContent.url);

                                  const uploadCardTreeContentResult =
                                    await axios.post(API.audio, formData, {
                                      headers: {
                                        "Content-Type": "multipart/form-data",
                                      },
                                    });
                                  const audioGraphqlQuery = {
                                    operationName: "CreateAudioDetail",
                                    query: condense(
                                      `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                    ),
                                    variables: {
                                      data: {
                                        url: uploadCardTreeContentResult.data
                                          .url,
                                        category: cardTreeContent.category,
                                        priority: cardTreeContent.priority,
                                        master: {
                                          connect: {
                                            id: masterID,
                                          },
                                        },
                                        position: cardTreeContent.position,
                                        title: cardTreeContent.title,
                                        type: cardTreeContent.type,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: audioGraphqlQuery,
                                    headers: headers,
                                  });
                                }
                              } else if (cardTreeContent.type === "video") {
                                if (typeof cardTreeContent.url === "string") {
                                  // console.log(cardTreeContent.url);

                                  const audioGraphqlQuery = {
                                    operationName: "CreateVideoDetail",
                                    query: condense(
                                      `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createAudioDetailRequest}`
                                    ),
                                    variables: {
                                      data: {
                                        url: cardTreeContent.url,
                                        category: cardTreeContent.category,
                                        priority: cardTreeContent.priority,
                                        master: {
                                          connect: {
                                            id: masterID,
                                          },
                                        },
                                        position: cardTreeContent.position,
                                        title: cardTreeContent.title,
                                        type: cardTreeContent.type,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: audioGraphqlQuery,
                                    headers: headers,
                                  });
                                } else {
                                  // console.log("here");
                                  let formData = new FormData();
                                  formData.append("video", cardTreeContent.url);

                                  const uploadCardTreeContentResult =
                                    await axios.post(API.video, formData, {
                                      headers: {
                                        "Content-Type": "multipart/form-data",
                                      },
                                    });
                                  const audioGraphqlQuery = {
                                    operationName: "CreateVideoDetail",
                                    query: condense(
                                      `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                    ),
                                    variables: {
                                      data: {
                                        url: uploadCardTreeContentResult.data
                                          .url,
                                        category: cardTreeContent.category,
                                        priority: cardTreeContent.priority,
                                        master: {
                                          connect: {
                                            id: masterID,
                                          },
                                        },
                                        position: cardTreeContent.position,
                                        title: cardTreeContent.title,
                                        type: cardTreeContent.type,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: audioGraphqlQuery,
                                    headers: headers,
                                  });
                                }
                              }
                            }
                          )
                        ).then((res) => (createFourthArr = true));
                      } else if (cardElement.type === "audio") {
                        if (typeof cardElement.url === "string") {
                          const audioGraphqlQuery = {
                            operationName: "CreateAudioDetail",
                            query: condense(
                              `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: "",
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                              },
                            },
                          };
                          await axios({
                            url: endPoint,
                            method: "post",
                            data: audioGraphqlQuery,
                            headers: headers,
                          });
                        } else {
                          let formData = new FormData();
                          formData.append("audio", cardElement.url);

                          const uploadCardElementAudio = await axios.post(
                            API.audio,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );

                          const audioGraphqlQuery = {
                            operationName: "CreateAudioDetail",
                            query: condense(
                              `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadCardElementAudio.data.url,
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                              },
                            },
                          };
                          await axios({
                            url: endPoint,
                            method: "post",
                            data: audioGraphqlQuery,
                            headers: headers,
                          });
                        }
                      } else if (cardElement.type === "video") {
                        if (typeof cardElement.url === "string") {
                          const audioGraphqlQuery = {
                            operationName: "CreateVideoDetail",
                            query: condense(
                              `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: cardElement.url,
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                              },
                            },
                          };
                          await axios({
                            url: endPoint,
                            method: "post",
                            data: audioGraphqlQuery,
                            headers: headers,
                          });
                        } else {
                          let formData = new FormData();
                          formData.append("video", cardElement.url);

                          const uploadCardElementVideo = await axios.post(
                            API.video,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );
                          const audioGraphqlQuery = {
                            operationName: "CreateVideoDetail",
                            query: condense(
                              `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadCardElementVideo.data.url,
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                              },
                            },
                          };
                          await axios({
                            url: endPoint,
                            method: "post",
                            data: audioGraphqlQuery,
                            headers: headers,
                          });
                        }
                      } else if (cardElement.type === "pdf") {
                        if (typeof cardElement.url === "string") {
                          const pdfGraphqlQuery = {
                            operationName: "CreatePdfDetail",
                            query: condense(
                              `mutation CreatePdfDetail($data: PdfDetailCreateInput!) ${createPDFDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: "",
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                                originalFileName: cardElement.url.name,
                              },
                            },
                          };
                          await axios({
                            url: endPoint,
                            method: "post",
                            data: pdfGraphqlQuery,
                            headers: headers,
                          });
                        } else {
                          let formData = new FormData();
                          formData.append("pdf", cardElement.url);

                          const uploadCardElementPDFResult = await axios.post(
                            API.pdf,
                            formData,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );

                          const pdfGraphqlQuery = {
                            operationName: "CreatePdfDetail",
                            query: condense(
                              `mutation CreatePdfDetail($data: PdfDetailCreateInput!) ${createPDFDetailRequest}`
                            ),
                            variables: {
                              data: {
                                url: uploadCardElementPDFResult.data.url,
                                category: cardElement.category,
                                priority: cardElement.priority,
                                master: {
                                  connect: {
                                    id: masterID,
                                  },
                                },
                                position: cardElement.position,
                                title: cardElement.title,
                                type: cardElement.type,
                                originalFileName: cardElement.url.name,
                              },
                            },
                          };
                          await axios({
                            url: endPoint,
                            method: "post",
                            data: pdfGraphqlQuery,
                            headers: headers,
                          });
                        }
                      }
                    })
                  ).then((res) => (createThirdArr = true));
                }
              })
            ).then((res) => (createFirstArr = true));
          } else {
            await Promise.all(
              e.combineContents.map(async (element: any) => {
                if (element.type === "text" || element.type === "wordEditor") {
                  if (element.content.trim() === "<p></p>") {
                    element.content = "";
                  }
                  if (element.id.includes("new")) {
                    if (element.language === "en") {
                      const elementGraphqlQuery = {
                        operationName: "Mutation",
                        query: condense(
                          `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                        ),
                        variables: {
                          data: {
                            priority: element.priority,
                            position: element.position,
                            content: element.content,
                            category: id,
                            type: element.type,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            title: element.title,
                            langLabel: {
                              connect: { id: eng_id },
                            },
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: elementGraphqlQuery,
                        headers: headers,
                      });
                    } else if (element.language === "zh_hant") {
                      const elementGraphqlQuery = {
                        operationName: "Mutation",
                        query: condense(
                          `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                        ),
                        variables: {
                          data: {
                            priority: element.priority,
                            position: element.position,
                            content: element.content,
                            category: id,
                            type: element.type,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            title: element.title,
                            langLabel: {
                              connect: { id: chinese_zh_id },
                            },
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: elementGraphqlQuery,
                        headers: headers,
                      });
                    } else {
                      const elementGraphqlQuery = {
                        operationName: "Mutation",
                        query: condense(
                          `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                        ),
                        variables: {
                          data: {
                            priority: element.priority,
                            position: element.position,
                            content: element.content,
                            category: id,
                            type: element.type,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            title: element.title,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: elementGraphqlQuery,
                        headers: headers,
                      });
                    }
                  } else {
                    if (element.originalContent === element.content) {
                    } else {
                      const graphqlQuery = {
                        operationName: "UpdateContentDetail",
                        query: condense(
                          `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${updateContentDetailRequest}`
                        ),
                        variables: {
                          where: { id: element.id },
                          data: {
                            content: element.content,
                            priority: element.priority,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: graphqlQuery,
                        headers: headers,
                      });
                    }
                  }
                } else if (
                  element.type === "image" ||
                  element.type === "icon"
                ) {
                  if (element.id.includes("new")) {
                    if (typeof element.url === "string") {
                      const imageGraphqlQuery = {
                        operationName: "Mutation",
                        query: condense(
                          `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                        ),
                        variables: {
                          data: {
                            url: "",
                            category: id,
                            priority: element.priority,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            position: element.position,
                            title: element.title,
                            type: element.type,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: imageGraphqlQuery,
                        headers: headers,
                      });
                    } else {
                      let formData = new FormData();
                      formData.append("image", element.url);
                      const uploadNewImageResult = await axios.post(
                        API.image,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );
                      const imageGraphqlQuery = {
                        operationName: "Mutation",
                        query: condense(
                          `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                        ),
                        variables: {
                          data: {
                            url: uploadNewImageResult.data.url,
                            category: id,
                            priority: element.priority,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            position: element.position,
                            title: element.title,
                            type: element.type,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: imageGraphqlQuery,
                        headers: headers,
                      });
                    }
                  } else {
                    if (typeof element.url === "string") {
                    } else {
                      let formData = new FormData();
                      formData.append("image", element.url);
                      const uploadNewImageResult = await axios.post(
                        API.image,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );

                      const imageUpdateGraphqlQuery = {
                        operationName: "UpdateImageDetail",
                        query: condense(
                          `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, $data: ImageDetailUpdateInput!) ${updateImageDetailRequest}`
                        ),
                        variables: {
                          where: { id: element.id },
                          data: {
                            url: uploadNewImageResult.data.url,
                            priority: element.priority,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: imageUpdateGraphqlQuery,
                        headers: headers,
                      });
                    }
                  }
                } else if (element.type === "video") {
                  if (element.id.includes("new")) {
                    if (typeof element.url === "string") {
                      const videoGraphqlQuery = {
                        operationName: "CreateVideoDetail",
                        query: condense(
                          `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                        ),
                        variables: {
                          data: {
                            url: element.url,
                            category: id,
                            priority: element.priority,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            position: element.position,
                            title: element.title,
                            type: element.type,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: videoGraphqlQuery,
                        headers: headers,
                      });
                    } else {
                      let formData = new FormData();
                      formData.append("video", element.url);
                      const uploadNewVideoResult = await axios.post(
                        API.video,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );

                      const videoGraphqlQuery = {
                        operationName: "CreateVideoDetail",
                        query: condense(
                          `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                        ),
                        variables: {
                          data: {
                            url: uploadNewVideoResult.data.url,
                            category: id,
                            priority: element.priority,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            position: element.position,
                            title: element.title,
                            type: element.type,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: videoGraphqlQuery,
                        headers: headers,
                      });
                    }
                  } else {
                    if (typeof element.url === "string") {
                      const videoUpdateGraphqlQuery = {
                        operationName: "UpdateVideoDetail",
                        query: condense(
                          `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${updateVideoDetailRequest}`
                        ),
                        variables: {
                          where: { id: element.id },
                          data: {
                            url: element.url,
                            priority: element.priority,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: videoUpdateGraphqlQuery,
                        headers: headers,
                      });
                    } else {
                      let formData = new FormData();
                      formData.append("video", element.url);
                      const uploadNewVideoResult = await axios.post(
                        API.video,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );
                      const videoUpdateGraphqlQuery = {
                        operationName: "UpdateVideoDetail",
                        query: condense(
                          `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${updateVideoDetailRequest}`
                        ),
                        variables: {
                          where: { id: element.id },
                          data: {
                            url: uploadNewVideoResult.data.url,
                            priority: element.priority,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: videoUpdateGraphqlQuery,
                        headers: headers,
                      });
                    }
                  }
                } else if (element.type === "audio") {
                  if (element.id.includes("new")) {
                    if (typeof element.url === "string") {
                      const videoGraphqlQuery = {
                        operationName: "CreateAudioDetail",
                        query: condense(
                          `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                        ),
                        variables: {
                          data: {
                            url: "",
                            category: id,
                            priority: element.priority,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            position: element.position,
                            title: element.title,
                            type: element.type,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: videoGraphqlQuery,
                        headers: headers,
                      });
                    } else {
                      let formData = new FormData();
                      formData.append("audio", element.url);
                      const uploadNewAudioResult = await axios.post(
                        API.audio,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );
                      const videoGraphqlQuery = {
                        operationName: "CreateAudioDetail",
                        query: condense(
                          `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                        ),
                        variables: {
                          data: {
                            url: uploadNewAudioResult.data.url,
                            category: id,
                            priority: element.priority,
                            master: {
                              connect: {
                                id: e.id,
                              },
                            },
                            position: element.position,
                            title: element.title,
                            type: element.type,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: videoGraphqlQuery,
                        headers: headers,
                      });
                    }
                  } else {
                    if (typeof element.url === "string") {
                    } else {
                      let formData = new FormData();
                      formData.append("audio", element.url);
                      const uploadNewAudioResult = await axios.post(
                        API.audio,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );
                      const audioUpdateGraphqlQuery = {
                        operationName: "UpdateAudioDetail",
                        query: condense(
                          `mutation UpdateAudioDetail($where: AudioDetailWhereUniqueInput!, $data: AudioDetailUpdateInput!) ${updateAudioDetailRequest}`
                        ),
                        variables: {
                          where: { id: element.id },
                          data: {
                            url: uploadNewAudioResult.data.url,
                            priority: element.priority,
                          },
                        },
                      };
                      await axios({
                        url: endPoint,
                        method: "post",
                        data: audioUpdateGraphqlQuery,
                        headers: headers,
                      });
                    }
                  }
                } else if (element.type === "card") {
                  if (
                    element.cardPositionID.includes("newCreateCardPosition")
                  ) {
                    const cardPositionGraphqlQuery = {
                      operationName: "Mutation",
                      query: condense(
                        `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                      ),
                      variables: {
                        data: {
                          position: "cardPosition",
                          type: "cardPosition",
                          master: {
                            connect: {
                              id: e.id,
                            },
                          },
                          content: `${element.cardPosition}`,
                          category: element.category,
                        },
                      },
                    };
                    let result = await axios({
                      url: endPoint,
                      method: "post",
                      data: cardPositionGraphqlQuery,
                      headers: headers,
                    });
                    // console.log("result", result);
                  }
                  const cardPromise = await Promise.all(
                    element.cardContent.map(
                      async (cardElement: any, cardIDX: number) => {
                        if (
                          cardElement.type === "text" ||
                          cardElement.type === "wordEditor"
                        ) {
                          if (cardElement.content.trim() === "<p></p>") {
                            cardElement.content = "";
                          }
                          if (cardElement.id.includes("new")) {
                            if (cardElement.language === "en") {
                              const elementGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                ),
                                variables: {
                                  data: {
                                    priority: cardElement.priority,
                                    position: cardElement.position,
                                    content: cardElement.content,
                                    category: cardElement.category,
                                    type: cardElement.type,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    title: cardElement.title,
                                    langLabel: {
                                      connect: { id: eng_id },
                                    },
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: elementGraphqlQuery,
                                headers: headers,
                              });
                            } else if (cardElement.language === "zh_hant") {
                              const elementGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                ),
                                variables: {
                                  data: {
                                    priority: cardElement.priority,
                                    position: cardElement.position,
                                    content: cardElement.content,
                                    category: cardElement.category,
                                    type: cardElement.type,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    title: cardElement.title,
                                    langLabel: {
                                      connect: { id: chinese_zh_id },
                                    },
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: elementGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              const elementGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    priority: cardElement.priority,
                                    position: cardElement.position,
                                    content: cardElement.content,
                                    category: cardElement.category,
                                    type: cardElement.type,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    title: cardElement.title,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: elementGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (
                              cardElement.content ===
                              cardElement.originalContent
                            ) {
                            } else {
                              const graphqlQuery = {
                                operationName: "UpdateContentDetail",
                                query: condense(
                                  `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${updateContentDetailRequest}`
                                ),
                                variables: {
                                  where: { id: cardElement.id },
                                  data: {
                                    content: cardElement.content,
                                    priority: cardElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: graphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (
                          cardElement.type === "image" ||
                          cardElement.type === "icon"
                        ) {
                          if (cardElement.id.includes("new")) {
                            if (typeof cardElement.url === "string") {
                              const imageGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: "",
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: imageGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("image", cardElement.url);
                              const uploadCardElementImageResult =
                                await axios.post(API.image, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const imageRequest = `{createImageDetail(data:$data){
                                        url
                                        category
                                        priority
                                        master{
                                          id
                                        }
                                        position
                                        title
                                        type
                                        }}`;
                              const imageGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadCardElementImageResult.data.url,
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                  },
                                },
                              };
                              return await axios({
                                url: endPoint,
                                method: "post",
                                data: imageGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof cardElement.url === "string") {
                            } else {
                              // console.log("here");
                              // console.log(cardElement.url);
                              let formData = new FormData();
                              formData.append("image", cardElement.url);
                              const uploadNewCardElementImageResult =
                                await axios.post(API.image, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const imageUpdateGraphqlQuery = {
                                operationName: "UpdateImageDetail",
                                query: condense(
                                  `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, $data: ImageDetailUpdateInput!) ${updateImageDetailRequest}`
                                ),
                                variables: {
                                  where: { id: cardElement.id },
                                  data: {
                                    url: uploadNewCardElementImageResult.data
                                      .url,
                                    priority: cardElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: imageUpdateGraphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (cardElement.type === "video") {
                          if (cardElement.id.includes("new")) {
                            if (typeof cardElement.url === "string") {
                              const videoGraphqlQuery = {
                                operationName: "CreateVideoDetail",
                                query: condense(
                                  `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: cardElement.url,
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("video", cardElement.url);
                              const uploadNewVideoResult = await axios.post(
                                API.video,
                                formData,
                                {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                }
                              );

                              const videoGraphqlQuery = {
                                operationName: "CreateVideoDetail",
                                query: condense(
                                  `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadNewVideoResult.data.url,
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof cardElement.url === "string") {
                              const videoUpdateGraphqlQuery = {
                                operationName: "UpdateVideoDetail",
                                query: condense(
                                  `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${updateVideoDetailRequest}`
                                ),
                                variables: {
                                  where: { id: cardElement.id },
                                  data: {
                                    url: cardElement.url,
                                    priority: cardElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoUpdateGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("video", cardElement.url);
                              const uploadCardElementVideoResult =
                                await axios.post(API.video, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const videoUpdateGraphqlQuery = {
                                operationName: "UpdateVideoDetail",
                                query: condense(
                                  `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${updateVideoDetailRequest}`
                                ),
                                variables: {
                                  where: { id: cardElement.id },
                                  data: {
                                    url: uploadCardElementVideoResult.data.url,
                                    priority: cardElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoUpdateGraphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (cardElement.type === "audio") {
                          if (cardElement.id.includes("new")) {
                            if (typeof cardElement.url === "string") {
                              const videoGraphqlQuery = {
                                operationName: "CreateAudioDetail",
                                query: condense(
                                  `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: "",
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("audio", cardElement.url);
                              const uploadNewCardElementAudioResult =
                                await axios.post(API.audio, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });
                              const videoGraphqlQuery = {
                                operationName: "CreateAudioDetail",
                                query: condense(
                                  `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadNewCardElementAudioResult.data
                                      .url,
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof cardElement.url === "string") {
                            } else {
                              let formData = new FormData();
                              formData.append("audio", cardElement.url);
                              const uploadUpdateAudioResult = await axios.post(
                                API.audio,
                                formData,
                                {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                }
                              );

                              const audioUpdateGraphqlQuery = {
                                operationName: "UpdateAudioDetail",
                                query: condense(
                                  `mutation UpdateAudioDetail($where: AudioDetailWhereUniqueInput!, $data: AudioDetailUpdateInput!) ${updateAudioDetailRequest}`
                                ),
                                variables: {
                                  where: { id: cardElement.id },
                                  data: {
                                    url: uploadUpdateAudioResult.data.url,
                                    priority: cardElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: audioUpdateGraphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (cardElement.type === "treeView") {
                          const cardTreePromise = await Promise.all(
                            cardElement.treeContents.map(
                              async (treeElement: any, treeIDX: number) => {
                                if (
                                  treeElement.type === "text" ||
                                  treeElement.type === "wordEditor"
                                ) {
                                  if (
                                    treeElement.content.trim() === "<p></p>"
                                  ) {
                                    treeElement.content = "";
                                  }

                                  if (treeElement.id.includes("new")) {
                                    if (treeElement.language === "en") {
                                      const elementGraphqlQuery = {
                                        operationName: "Mutation",
                                        query: condense(
                                          `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            priority: treeElement.priority,
                                            position: treeElement.position,
                                            content: treeElement.content,
                                            type: treeElement.type,
                                            category: treeElement.category,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            title: treeElement.title,
                                            langLabel: {
                                              connect: { id: eng_id },
                                            },
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: elementGraphqlQuery,
                                        headers: headers,
                                      });
                                    } else if (
                                      treeElement.language === "zh_hant"
                                    ) {
                                      const elementGraphqlQuery = {
                                        operationName: "Mutation",
                                        query: condense(
                                          `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            priority: treeElement.priority,
                                            position: treeElement.position,
                                            content: treeElement.content,
                                            category: treeElement.category,
                                            type: treeElement.type,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            title: treeElement.title,
                                            langLabel: {
                                              connect: { id: chinese_zh_id },
                                            },
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: elementGraphqlQuery,
                                        headers: headers,
                                      });
                                    } else {
                                      const elementGraphqlQuery = {
                                        operationName: "Mutation",
                                        query: condense(
                                          `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            priority: treeElement.priority,
                                            position: treeElement.position,
                                            content: treeElement.content,
                                            type: treeElement.type,
                                            category: treeElement.category,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            title: treeElement.title,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: elementGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  } else {
                                    if (
                                      treeElement.content ===
                                      treeElement.originalContent
                                    ) {
                                    } else {
                                      const graphqlQuery = {
                                        operationName: "Mutation",
                                        query: condense(
                                          `mutation Mutation($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${updateContentDetailRequest}`
                                        ),
                                        variables: {
                                          where: { id: treeElement.id },
                                          data: {
                                            content: treeElement.content,
                                            priority: treeElement.priority,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: graphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  }
                                } else if (
                                  treeElement.type === "image" ||
                                  treeElement.type === "icon"
                                ) {
                                  if (treeElement.id.includes("new")) {
                                    if (typeof treeElement.url === "string") {
                                      const imageGraphqlQuery = {
                                        operationName: "Mutation",
                                        query: condense(
                                          `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            url: "",
                                            category: treeElement.category,
                                            priority: treeElement.priority,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            position: treeElement.position,
                                            title: treeElement.title,
                                            type: treeElement.type,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: imageGraphqlQuery,
                                        headers: headers,
                                      });
                                    } else {
                                      let formData = new FormData();
                                      formData.append("image", treeElement.url);
                                      const uploadTreeElementImageResult =
                                        await axios.post(API.image, formData, {
                                          headers: {
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        });
                                      const imageGraphqlQuery = {
                                        operationName: "Mutation",
                                        query: condense(
                                          `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            url: uploadTreeElementImageResult
                                              .data.url,
                                            category: treeElement.category,
                                            priority: treeElement.priority,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            position: treeElement.position,
                                            title: treeElement.title,
                                            type: treeElement.type,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: imageGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  } else {
                                    if (typeof treeElement.url === "string") {
                                    } else {
                                      let formData = new FormData();
                                      formData.append("image", treeElement.url);
                                      const uploadTreeElementImageResult =
                                        await axios.post(API.image, formData, {
                                          headers: {
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        });
                                      const imageUpdateGraphqlQuery = {
                                        operationName: "UpdateImageDetail",
                                        query: condense(
                                          `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, $data: ImageDetailUpdateInput!) ${updateImageDetailRequest}`
                                        ),
                                        variables: {
                                          where: { id: treeElement.id },
                                          data: {
                                            url: uploadTreeElementImageResult
                                              .data.url,
                                            priority: treeElement.priority,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: imageUpdateGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  }
                                } else if (treeElement.type === "video") {
                                  if (treeElement.id.includes("new")) {
                                    if (typeof treeElement.url === "string") {
                                      const videoGraphqlQuery = {
                                        operationName: "CreateVideoDetail",
                                        query: condense(
                                          `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            url: treeElement.url,
                                            category: treeElement.category,
                                            priority: treeElement.priority,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            position: treeElement.position,
                                            title: treeElement.title,
                                            type: treeElement.type,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: videoGraphqlQuery,
                                        headers: headers,
                                      });
                                    } else {
                                      let formData = new FormData();
                                      formData.append("video", treeElement.url);
                                      const uploadTreeElementVideoResult =
                                        await axios.post(API.video, formData, {
                                          headers: {
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        });
                                      const videoGraphqlQuery = {
                                        operationName: "CreateVideoDetail",
                                        query: condense(
                                          `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            url: uploadTreeElementVideoResult
                                              .data.url,
                                            category: treeElement.category,
                                            priority: treeElement.priority,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            position: treeElement.position,
                                            title: treeElement.title,
                                            type: treeElement.type,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: videoGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  } else {
                                    if (typeof treeElement.url === "string") {
                                      const videoUpdateRequest = `{
                                        updateVideoDetail(where: $where, data: $data){
                                        url
                                        priority
                                        id
                                      }
                                    }`;
                                      const videoUpdateGraphqlQuery = {
                                        operationName: "UpdateVideoDetail",
                                        query: condense(
                                          `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${videoUpdateRequest}`
                                        ),
                                        variables: {
                                          where: { id: treeElement.id },
                                          data: {
                                            url: treeElement.url,
                                            priority: treeElement.priority,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: videoUpdateGraphqlQuery,
                                        headers: headers,
                                      });
                                    } else {
                                      // console.log("here?????????????????????");
                                      let formData = new FormData();
                                      formData.append("video", treeElement.url);
                                      // console.log(
                                      //   "treeElement.url",
                                      //   treeElement.url
                                      // );
                                      const uploadTreeElementVideoResult =
                                        await axios.post(API.video, formData, {
                                          headers: {
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        });
                                      // console.log(
                                      //   "uploadTreeElementVideoResult",
                                      //   uploadTreeElementVideoResult
                                      // );
                                      const videoUpdateRequest = `{
                                            updateVideoDetail(where: $where, data: $data){
                                            url
                                            priority
                                            id
                                          }
                                        }`;
                                      const videoUpdateGraphqlQuery = {
                                        operationName: "UpdateVideoDetail",
                                        query: condense(
                                          `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${videoUpdateRequest}`
                                        ),
                                        variables: {
                                          where: { id: treeElement.id },
                                          data: {
                                            url: uploadTreeElementVideoResult
                                              .data.url,
                                            priority: treeElement.priority,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: videoUpdateGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  }
                                } else if (treeElement.type === "audio") {
                                  if (treeElement.id.includes("new")) {
                                    if (typeof treeElement.url === "string") {
                                      const videoGraphqlQuery = {
                                        operationName: "CreateAudioDetail",
                                        query: condense(
                                          `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            url: "",
                                            category: treeElement.category,
                                            priority: treeElement.priority,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            position: treeElement.position,
                                            title: treeElement.title,
                                            type: treeElement.type,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: videoGraphqlQuery,
                                        headers: headers,
                                      });
                                    } else {
                                      let formData = new FormData();
                                      formData.append("audio", treeElement.url);
                                      const uploadTreeElementAudioResult =
                                        await axios.post(API.audio, formData, {
                                          headers: {
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        });

                                      const videoGraphqlQuery = {
                                        operationName: "CreateAudioDetail",
                                        query: condense(
                                          `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                        ),
                                        variables: {
                                          data: {
                                            url: uploadTreeElementAudioResult
                                              .data.url,
                                            category: treeElement.category,
                                            priority: treeElement.priority,
                                            master: {
                                              connect: {
                                                id: e.id,
                                              },
                                            },
                                            position: treeElement.position,
                                            title: treeElement.title,
                                            type: treeElement.type,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: videoGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  } else {
                                    if (typeof treeElement.url === "string") {
                                    } else {
                                      let formData = new FormData();
                                      formData.append("audio", treeElement.url);
                                      const uploadTreeElementAudioResult =
                                        await axios.post(API.audio, formData, {
                                          headers: {
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        });

                                      const audioUpdateRequest = `{
                                            updateAudioDetail(where: $where, data: $data){
                                            url
                                            priority
                                            id
                                          }
                                        }`;
                                      const audioUpdateGraphqlQuery = {
                                        operationName: "UpdateAudioDetail",
                                        query: condense(
                                          `mutation UpdateAudioDetail($where: AudioDetailWhereUniqueInput!, $data: AudioDetailUpdateInput!) ${audioUpdateRequest}`
                                        ),
                                        variables: {
                                          where: { id: treeElement.id },
                                          data: {
                                            url: uploadTreeElementAudioResult
                                              .data.url,
                                            priority: treeElement.priority,
                                          },
                                        },
                                      };
                                      await axios({
                                        url: endPoint,
                                        method: "post",
                                        data: audioUpdateGraphqlQuery,
                                        headers: headers,
                                      });
                                    }
                                  }
                                }
                              }
                            )
                          ).then((res) => (updateSecondArr = true));
                        } else if (cardElement.type === "pdf") {
                          if (cardElement.id.includes("new")) {
                            if (typeof cardElement.url === "string") {
                              const pdfGraphqlQuery = {
                                operationName: "CreatePdfDetail",
                                query: condense(
                                  `mutation CreatePdfDetail($data: PdfDetailCreateInput!) ${createPDFDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: "",
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                    originalFileName: cardElement.url.name,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: pdfGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("pdf", cardElement.url);
                              const uploadCardElementPDFResult =
                                await axios.post(API.pdf, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const pdfRequest = `{createPdfDetail(data:$data){
                                        url
                                        category
                                        priority
                                        master{
                                          id
                                        }
                                        position
                                        title
                                        type
                                        originalFileName
                                        }}`;
                              const pdfGraphqlQuery = {
                                operationName: "CreatePdfDetail",
                                query: condense(
                                  `mutation CreatePdfDetail($data: PdfDetailCreateInput!) ${createPDFDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadCardElementPDFResult.data.url,
                                    category: cardElement.category,
                                    priority: cardElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: cardElement.position,
                                    title: cardElement.title,
                                    type: cardElement.type,
                                    originalFileName: cardElement.url.name,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: pdfGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof cardElement.url === "string") {
                            } else {
                              let formData = new FormData();
                              formData.append("pdf", cardElement.url);
                              await axios
                                .post(API.pdf, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                })
                                .then(async (pdfRes) => {
                                  const pdfUpdateRequest = `{
                                      updatePdfDetail(where: $where, data: $data){
                                        url
                                        priority
                                        id
                                        originalFileName
                                      }
                                    }`;
                                  const pdfUpdateGraphqlQuery = {
                                    operationName: "UpdatePdfDetail",
                                    query: condense(
                                      `mutation UpdatePdfDetail($where: PdfDetailWhereUniqueInput!, $data: PdfDetailUpdateInput!) ${updatePDFDetailRequest}`
                                    ),
                                    variables: {
                                      where: { id: cardElement.id },
                                      data: {
                                        url: pdfRes.data.url,
                                        priority: cardElement.priority,
                                        originalFileName: cardElement.url.name,
                                      },
                                    },
                                  };
                                  await axios({
                                    url: endPoint,
                                    method: "post",
                                    data: pdfUpdateGraphqlQuery,
                                    headers: headers,
                                  });
                                });
                            }
                          }
                        }
                      }
                    )
                  ).then((res) => (updateFirstArr = true));
                } else if (element.type === "treeView") {
                  const treePromise = await Promise.all(
                    element.treeContents.map(
                      async (treeElement: any, treeIDX: number) => {
                        if (
                          treeElement.type === "text" ||
                          treeElement.type === "wordEditor"
                        ) {
                          if (treeElement.content.trim() === "<p></p>") {
                            treeElement.content = "";
                          }
                          if (treeElement.id.includes("new")) {
                            if (treeElement.language === "en") {
                              const elementGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                ),
                                variables: {
                                  data: {
                                    priority: treeElement.priority,
                                    position: treeElement.position,
                                    content: treeElement.content,
                                    type: treeElement.type,
                                    category: treeElement.category,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    title: treeElement.title,
                                    langLabel: {
                                      connect: { id: eng_id },
                                    },
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: elementGraphqlQuery,
                                headers: headers,
                              });
                            } else if (treeElement.language === "zh_hant") {
                              const elementGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailWithLanguageRequest}`
                                ),
                                variables: {
                                  data: {
                                    priority: treeElement.priority,
                                    position: treeElement.position,
                                    content: treeElement.content,
                                    category: treeElement.category,
                                    type: treeElement.type,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    title: treeElement.title,
                                    langLabel: {
                                      connect: { id: chinese_zh_id },
                                    },
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: elementGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              const elementGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ContentDetailCreateInput!) ${createContentDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    priority: treeElement.priority,
                                    position: treeElement.position,
                                    content: treeElement.content,
                                    type: treeElement.type,
                                    category: treeElement.category,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    title: treeElement.title,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: elementGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (
                              treeElement.content ===
                              treeElement.originalContent
                            ) {
                            } else {
                              const graphqlQuery = {
                                operationName: "UpdateContentDetail",
                                query: condense(
                                  `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${updateContentDetailRequest}`
                                ),
                                variables: {
                                  where: { id: treeElement.id },
                                  data: {
                                    content: treeElement.content,
                                    priority: treeElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: graphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (
                          treeElement.type === "image" ||
                          treeElement.type === "icon"
                        ) {
                          if (treeElement.id.includes("new")) {
                            if (typeof treeElement.url === "string") {
                              const imageGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: "",
                                    category: treeElement.category,
                                    priority: treeElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: treeElement.position,
                                    title: treeElement.title,
                                    type: treeElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: imageGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("image", treeElement.url);
                              const uploadTreeElementImageResult =
                                await axios.post(API.image, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const imageGraphqlQuery = {
                                operationName: "Mutation",
                                query: condense(
                                  `mutation Mutation($data: ImageDetailCreateInput!) ${createImageDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadTreeElementImageResult.data.url,
                                    category: treeElement.category,
                                    priority: treeElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: treeElement.position,
                                    title: treeElement.title,
                                    type: treeElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: imageGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof treeElement.url === "string") {
                            } else {
                              let formData = new FormData();
                              formData.append("image", treeElement.url);
                              const uploadTreeElementImageResult =
                                await axios.post(API.image, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });
                              const imageUpdateGraphqlQuery = {
                                operationName: "UpdateImageDetail",
                                query: condense(
                                  `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, $data: ImageDetailUpdateInput!) ${updateImageDetailRequest}`
                                ),
                                variables: {
                                  where: { id: treeElement.id },
                                  data: {
                                    url: uploadTreeElementImageResult.data.url,
                                    priority: treeElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: imageUpdateGraphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (treeElement.type === "video") {
                          if (treeElement.id.includes("new")) {
                            if (typeof treeElement.url === "string") {
                              const videoGraphqlQuery = {
                                operationName: "CreateVideoDetail",
                                query: condense(
                                  `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: treeElement.url,
                                    category: treeElement.category,
                                    priority: treeElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: treeElement.position,
                                    title: treeElement.title,
                                    type: treeElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("video", treeElement.url);
                              const uploadTreeElementVideoResult =
                                await axios.post(API.video, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });
                              const videoGraphqlQuery = {
                                operationName: "CreateVideoDetail",
                                query: condense(
                                  `mutation CreateVideoDetail($data: VideoDetailCreateInput!) ${createVideoDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadTreeElementVideoResult.data.url,
                                    category: treeElement.category,
                                    priority: treeElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: treeElement.position,
                                    title: treeElement.title,
                                    type: treeElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof treeElement.url === "string") {
                              const videoUpdateGraphqlQuery = {
                                operationName: "UpdateVideoDetail",
                                query: condense(
                                  `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${updateVideoDetailRequest}`
                                ),
                                variables: {
                                  where: { id: treeElement.id },
                                  data: {
                                    url: treeElement.url,
                                    priority: treeElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoUpdateGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("video", treeElement.url);
                              const uploadTreeElementVideoResult =
                                await axios.post(API.video, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const videoUpdateGraphqlQuery = {
                                operationName: "UpdateVideoDetail",
                                query: condense(
                                  `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${updateVideoDetailRequest}`
                                ),
                                variables: {
                                  where: { id: treeElement.id },
                                  data: {
                                    url: uploadTreeElementVideoResult.data.url,
                                    priority: treeElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoUpdateGraphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        } else if (treeElement.type === "audio") {
                          if (treeElement.id.includes("new")) {
                            if (typeof treeElement.url === "string") {
                              const videoGraphqlQuery = {
                                operationName: "CreateVideoDetail",
                                query: condense(
                                  `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: treeElement.url,
                                    category: treeElement.category,
                                    priority: treeElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: treeElement.position,
                                    title: treeElement.title,
                                    type: treeElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            } else {
                              let formData = new FormData();
                              formData.append("audio", treeElement.url);
                              const uploadTreeElementAudioRequest =
                                await axios.post(API.audio, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });

                              const videoGraphqlQuery = {
                                operationName: "CreateVideoDetail",
                                query: condense(
                                  `mutation CreateAudioDetail($data: AudioDetailCreateInput!) ${createAudioDetailRequest}`
                                ),
                                variables: {
                                  data: {
                                    url: uploadTreeElementAudioRequest.data.url,
                                    category: treeElement.category,
                                    priority: treeElement.priority,
                                    master: {
                                      connect: {
                                        id: e.id,
                                      },
                                    },
                                    position: treeElement.position,
                                    title: treeElement.title,
                                    type: treeElement.type,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: videoGraphqlQuery,
                                headers: headers,
                              });
                            }
                          } else {
                            if (typeof treeElement.url === "string") {
                            } else {
                              let formData = new FormData();
                              formData.append("audio", treeElement.url);
                              const uploadTreeElementAudioResult =
                                await axios.post(API.audio, formData, {
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                });
                              const audioUpdateGraphqlQuery = {
                                operationName: "UpdateAudioDetail",
                                query: condense(
                                  `mutation UpdateAudioDetail($where: AudioDetailWhereUniqueInput!, $data: AudioDetailUpdateInput!) ${updateAudioDetailRequest}`
                                ),
                                variables: {
                                  where: { id: treeElement.id },
                                  data: {
                                    url: uploadTreeElementAudioResult.data.url,
                                    priority: treeElement.priority,
                                  },
                                },
                              };
                              await axios({
                                url: endPoint,
                                method: "post",
                                data: audioUpdateGraphqlQuery,
                                headers: headers,
                              });
                            }
                          }
                        }
                      }
                    )
                  ).then((res) => (updateThirdArr = true));
                }
              })
            ).then((res) => (updateSumArr = true));
          }
        })
      ).then((res) => (createSumArr = true));
    }
    if (autosave) {
    } else {
      setDeleteModify(!deleteModify);
      setIsLoading(false);
      if (id.includes("Module")) {
        id = `Module${+id.split("Module")[1] - 1}`;
      }
      setTimeout(() => {
        dispatch(
          showMessage({
            message: `${id} is updated`,
            autoHideDuration: 6000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "success",
          })
        );
        setIsDisabled(false);
      }, 1000);
    }
  }

  const [showDiv, setShowDiv] = useState(false);

  const handleMouseEnter = () => {
    setShowDiv(true);
  };

  const handleMouseLeave = () => {
    setShowDiv(false);
  };

  async function del() {
    const request = `{
      deleteContentDetail(where: $where) {
        id
      }
    }`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($where: ContentDetailWhereUniqueInput!) ${request}`,
      variables: {
        where: { id: "clk26tzxz0028pf0ipw4al262" },
      },
    };
    let result = await axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    });
    // console.log(result);
  }

  async function clearNewsImage() {
    // console.log(sessionList);
    if (sessionList[0]) {
      if (sessionList[0].combineContents[0]) {
        const newsImageID = sessionList[0].combineContents[0].id;
        sessionList[0].combineContents[0].previewUrl = "";
        const grahpqlQuery = {
          operationName: "UpdateImageDetail",
          query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, $data: ImageDetailUpdateInput!) ${updateImageDetailRequest}`,
          variables: {
            where: { id: newsImageID },
            data: { url: "" },
          },
        };
        await axios({
          url: endPoint,
          method: "post",
          data: grahpqlQuery,
          headers: headers,
        });
      }
    }
    setDeleteModify(!deleteModify);
  }

  async function changeWordEditorToText(element: any) {
    let type = "";
    if (element.type === "wordEditor") {
      type = "text";
    } else if (element.type === "text") {
      type = "wordEditor";
    }
    // console.log(element);
    // console.log(type);
    const graphqlQuery = {
      operationName: "UpdateContentDetail",
      query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${updateContentDetailRequest}`,
      variables: {
        where: { id: element.id },
        data: { type: type },
      },
    };
    const result = await axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    });
    // console.log(result);
  }

  const [currentMediaInfo, setCurrentMediaInfo] = useState() as any;
  const [openDoubleConfirmClear, setOpenDoubleConfirmClear] = useState(false);

  const doubleConfirmClearMedia = (element: any) => {
    setCurrentMediaInfo(element);
    setOpenDoubleConfirmClear(true);
  };

  const closeDoubleConfirmClearMedia = () => {
    setOpenDoubleConfirmClear(false);
  };

  async function clearMedia() {
    // console.log(currentMediaInfo);
    setIsLoading(true);
    if (currentMediaInfo.id.includes("newCreate")) {
      currentMediaInfo.previewUrl = "";
      currentMediaInfo.url = "";
    } else if (
      currentMediaInfo.type === "image" ||
      currentMediaInfo.type === "icon"
    ) {
      const grahpqlQuery = {
        operationName: "UpdateImageDetail",
        query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, $data: ImageDetailUpdateInput!) ${updateImageDetailRequest}`,
        variables: {
          where: { id: currentMediaInfo.id },
          data: { url: "" },
        },
      };
      await axios({
        url: endPoint,
        method: "post",
        data: grahpqlQuery,
        headers: headers,
      });
    } else if (currentMediaInfo.type === "pdf") {
      const grahpqlQuery = {
        operationName: "UpdatePdfDetail",
        query: `mutation UpdatePdfDetail($where: PdfDetailWhereUniqueInput!, $data: PdfDetailUpdateInput!) ${updatePDFDetailRequest}`,
        variables: {
          where: { id: currentMediaInfo.id },
          data: { url: "" },
        },
      };
      await axios({
        url: endPoint,
        method: "post",
        data: grahpqlQuery,
        headers: headers,
      });
    } else if (currentMediaInfo.type === "video") {
      const grahpqlQuery = {
        operationName: "UpdateVideoDetail",
        query: `mutation UpdateVideoDetail($where: VideoDetailWhereUniqueInput!, $data: VideoDetailUpdateInput!) ${updateVideoDetailRequest}`,
        variables: {
          where: { id: currentMediaInfo.id },
          data: { url: "" },
        },
      };
      await axios({
        url: endPoint,
        method: "post",
        data: grahpqlQuery,
        headers: headers,
      });
    } else if (currentMediaInfo.type === "audio") {
      const grahpqlQuery = {
        operationName: "UpdateAudioDetail",
        query: `mutation UpdateAudioDetail($where: AudioDetailWhereUniqueInput!, $data: AudioDetailUpdateInput!) ${updateAudioDetailRequest}`,
        variables: {
          where: { id: currentMediaInfo.id },
          data: { url: "" },
        },
      };
      await axios({
        url: endPoint,
        method: "post",
        data: grahpqlQuery,
        headers: headers,
      });
    } else {
      // console.log("undefined media type");
    }
    setOpenDoubleConfirmClear(false);
    setDeleteModify(!deleteModify);
    setIsLoading(false);
    setTimeout(() => {
      dispatch(
        showMessage({
          message: `Media is cleared`,
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        })
      );
    });
  }

  async function addNewItemInExistedCard() { }

  async function modifyCategory(id: string) {
    const request = `{
      updateAudioDetail(where: $where, data: $data) {
        category
        id
      }
    }`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: condense(
        `mutation Mutation($where: AudioDetailWhereUniqueInput!, $data: AudioDetailUpdateInput!)${request}`
      ),
      variables: {
        where: { id: id },
        data: { category: "Module2_card_2*1687168018095" },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => console.error(err));
  }

  async function changeLanguage(element: any) {
    // console.log("todo");
  }

  //[section] logic of swapping card 'real time'
  async function switchCardPriority(
    direction: string,
    element: any,
    cardIDX: number,
    sessionIDX: number
  ) {
    try {
      setIsLoading(true);
      // console.log(element);
      // console.log(sessionList[sessionIDX].combineContents[cardIDX]);
      // console.log(sessionList[sessionIDX].combineContents[cardIDX + 1]);
      if (newCardArr.length > 0) {
        await updatePageContent(true);
      }

      if (direction === "up") {
        if (
          sessionList[sessionIDX].combineContents[cardIDX - 1] &&
          sessionList[sessionIDX].combineContents[cardIDX - 1].cardPositionID
        ) {
          if (
            !sessionList[sessionIDX].combineContents[
              cardIDX
            ].cardPositionID.includes("newCreateCardPosition") &&
            !sessionList[sessionIDX].combineContents[
              cardIDX - 1
            ].cardPositionID.includes("newCreateCardPosition")
          ) {
            const currentCard =
              sessionList[sessionIDX].combineContents[cardIDX];
            const previousCard =
              sessionList[sessionIDX].combineContents[cardIDX - 1];
            if (currentCard.cardPosition === previousCard.cardPosition) {
              currentCard.cardPosition = currentCard.cardPosition + 0.1;
            }
            const request = `{updateContentDetails(data: $data){
              id,
              content
            }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
              variables: {
                data: [
                  {
                    where: { id: currentCard.cardPositionID },
                    data: {
                      content: `${previousCard.cardPosition}`,
                    },
                  },
                  {
                    where: { id: previousCard.cardPositionID },
                    data: {
                      content: `${currentCard.cardPosition}`,
                    },
                  },
                ],
              },
            };
            await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });
          } else {
          }
        } else {
          setIsLoading(false);
        }
      } else if (direction === "down") {
        if (
          sessionList[sessionIDX].combineContents[cardIDX + 1] &&
          sessionList[sessionIDX].combineContents[cardIDX + 1].cardPositionID
        ) {
          if (
            !sessionList[sessionIDX].combineContents[
              cardIDX
            ].cardPositionID.includes("newCreateCardPosition") &&
            !sessionList[sessionIDX].combineContents[
              cardIDX + 1
            ].cardPositionID.includes("newCreateCardPosition")
          ) {
            const currentCard =
              sessionList[sessionIDX].combineContents[cardIDX];
            const previousCard =
              sessionList[sessionIDX].combineContents[cardIDX + 1];
            if (currentCard.cardPosition === previousCard.cardPosition) {
              previousCard.cardPosition = previousCard.cardPosition + 0.1;
            }
            const request = `{updateContentDetails(data: $data){
              id,
              content
            }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
              variables: {
                data: [
                  {
                    where: { id: currentCard.cardPositionID },
                    data: {
                      content: `${previousCard.cardPosition}`,
                    },
                  },
                  {
                    where: { id: previousCard.cardPositionID },
                    data: {
                      content: `${currentCard.cardPosition}`,
                    },
                  },
                ],
              },
            };
            await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });
          } else {
          }
        } else {
          setIsLoading(false);
        }
      }

      setDeleteModify(!deleteModify);
      setTimeout(() => {
        dispatch(
          showMessage({
            message: `Card Position has changed`,
            autoHideDuration: 6000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "success",
          })
        );
        setIsLoading(false);
        setIsDisabled(false);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  }

  type CardElement = {
    cardContent: [];
    category: string;
    expand: boolean;
    id: string;
    minImage: string;
    minTitle: string;
    priority: string;
    type: string;
  };

  function openCard(element: CardElement) {
    element.expand = true;
    setModified(!modified);
  }

  function closeCard(element: CardElement) {
    element.expand = false;
    setModified(!modified);
  }

  async function pushPinCard(element: any, idx: number) {
    // if pin new card
    let updatedSessionList = sessionList[4];
    let graphqlQuery;
    let sessionID = element.sessionID;
    setIsLoading(true)
    if (element.position != 'pinned') { // if originally not pinned
      updatedSessionList['combineContents'][idx] = { ...element, position: "pinned" }
      const poppedObject = updatedSessionList['combineContents'].splice(idx, 1)[0]; // Remove object at index and store it in a variable
      updatedSessionList['combineContents'].splice(2, 0, poppedObject); // Insert the removed object at index 2
      const request = `{
          createContentDetail(data: $data) {
          priority
          position
          content
          category
          master {
            id
          }
        }
      }`;
      graphqlQuery = {
        operationName: "CreateContentDetail",
        query: `mutation CreateContentDetail($data: ContentDetailCreateInput!) ${request}`,
        variables: {
          data: {
            content: "true",
            position: "pinned",
            category: element.category,
            master: {
              connect: { id: sessionID },
            },
            priority: 0, //at first
          },
        },
      };

    }
    // if is unpin
    else {
      updatedSessionList['combineContents'][idx] = { ...element, position: "", pinnedId: "", priority: "" }
      const poppedObject = updatedSessionList['combineContents'].splice(idx, 1)[0]; // Remove object at index and store it in a variable
      updatedSessionList['combineContents'].push(poppedObject); // Insert the removed object at index 2
      const request = `{
        updateContentDetail(where: $where, data: $data) {
        position
      }
    }`;
      graphqlQuery = {
        operationName: "updateContentDetail",
        query: `mutation updateContentDetail($data: ContentDetailUpdateInput!, $where: ContentDetailWhereUniqueInput!) ${request}`,
        variables: {
          where: { id: element?.pinnedId },
          data: {
            // priority: +element.priority + increaseNumber,
            position: "",
            content: "false",
            master: {
              connect: { id: sessionID },
            },
          },
        },
      };
    };

    try {
      let result = await axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      });


      // if is pinned, get the pinnedId
      if (result.status == 200 && element.position != 'pinned') {
        const checkPinnedId = `{
            contentDetails(where: $where) {
              id
            }
            }`;
        const graphqlQuery = {
          operationName: "Query",
          query: condense(`query Query($where: ContentDetailWhereInput!) ${checkPinnedId}`),
          variables: {
            where: {
              "position": {
                "equals": "pinned"
              },
              "category": {
                "equals": `${element.category}`
              },
              "content": {
                "equals": "true"
              }
            },
          },
        };
        let pinnedResult = await axios({
          url: endPoint,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        });
        // update new added at index 2 
        updatedSessionList['combineContents'][2] = { ...updatedSessionList['combineContents'][2], pinnedId: pinnedResult?.data?.data?.contentDetails[0]?.id, priority: 0 }


        updatedSessionList['combineContents'].map((el: any, index: number) => {
          if (el.position === 'pinned' && index != 2) {
            el.priority = el.priority + 1 //move the priority to 1 further
          }
        })


        // update all other pinned items' priority
        await Promise.all(
          updatedSessionList['combineContents'].map(async (e: any, index: number) => {
            if (e.position == 'pinned' && index != 2) {
              const request = `{
                updateContentDetail(where: $where, data: $data) {
                priority
              }
            }`;
              let otherPinnedItemGraphqlQuery = {
                operationName: "updateContentDetail",
                query: `mutation updateContentDetail($data: ContentDetailUpdateInput!, $where: ContentDetailWhereUniqueInput!) ${request}`,
                variables: {
                  where: { id: e?.pinnedId },
                  data: {
                    priority: e.priority,
                    master: {
                      connect: { id: e.sessionID }, //[section] sessionId
                    },
                  },
                },
              };
              let result = await axios({
                url: endPoint,
                method: "post",
                data: otherPinnedItemGraphqlQuery,
                headers: headers,
              });
            }
          })
        )

      }
    } catch (err) {
      console.log(err, '==err')
    }


    // update card message
    setTimeout(() => {
      dispatch(
        showMessage({
          message: `Card Position has updated`,
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        })
      );
      setIsLoading(false);
    }, 500);

    // update sessionList
    let updatedAllSessionList = [
      ...sessionList.slice(0, 4), // Copy the objects before the updated object
      updatedSessionList
    ];
    setSessionList(updatedAllSessionList)
    setIsLoading(false)

  }


  async function switchPinCardPriority(position: string, element: any, idx: number) {
    let updatedSessionList = JSON.parse(JSON.stringify(sessionList[4]));
    let currentElement = JSON.parse(JSON.stringify(element))
    let arrToUpdate = [];

    // [section] switch pinned card priority in ascending order: smallest priority at frontest
    setIsLoading(true)
    if (position === 'up' && idx === 2) {  //if is first, change nothing
      setIsLoading(false)
      return
    }
    else if (position === 'down' && updatedSessionList['combineContents'][idx + 1].position === 'pinned') {
      // switch priority
      updatedSessionList['combineContents'][idx].priority = updatedSessionList['combineContents'][idx + 1]['priority'];
      updatedSessionList['combineContents'][idx + 1]['priority'] = currentElement.priority;
      arrToUpdate.push(updatedSessionList['combineContents'][idx])
      arrToUpdate.push(updatedSessionList['combineContents'][idx + 1])
      const poppedObject = updatedSessionList['combineContents'].splice(idx, 1)[0]; // Remove object at index and store it in a variable
      updatedSessionList['combineContents'].splice(idx + 1, 0, poppedObject); // Insert the removed object at index 2

    } else if (position === 'up' && updatedSessionList['combineContents'][idx - 1].position === 'pinned') {
      // switch priority
      updatedSessionList['combineContents'][idx].priority = updatedSessionList['combineContents'][idx - 1]['priority'];
      updatedSessionList['combineContents'][idx - 1]['priority'] = currentElement.priority;
      arrToUpdate.push(updatedSessionList['combineContents'][idx])
      arrToUpdate.push(updatedSessionList['combineContents'][idx - 1])

      const poppedObject = updatedSessionList['combineContents'].splice(idx, 1)[0]; // Remove object at index and store it in a variable
      updatedSessionList['combineContents'].splice(idx != 0 ? idx - 1 : 0, 0, poppedObject);
    }

    // update other changed pinned items' priority
    await Promise.all(
      arrToUpdate.map(async (e: any, index: number) => {

        const request = `{
            updateContentDetail(where: $where, data: $data) {
            priority
          }
        }`;
        let otherPinnedItemGraphqlQuery = {
          operationName: "updateContentDetail",
          query: `mutation updateContentDetail($data: ContentDetailUpdateInput!, $where: ContentDetailWhereUniqueInput!) ${request}`,
          variables: {
            where: { id: e?.pinnedId },
            data: {
              priority: e.priority,
              master: {
                connect: { id: e.sessionID },
              },
            },
          },
        };
        let result = await axios({
          url: endPoint,
          method: "post",
          data: otherPinnedItemGraphqlQuery,
          headers: headers,
        });

      })
    )

    setIsLoading(false)

    let updatedAllSessionList = [
      ...sessionList.slice(0, 4), // Copy the objects before the updated object
      updatedSessionList
    ];
    setSessionList(updatedAllSessionList)

  }




  async function addResearchNewFields() {
    let insertPriority: number;
    let cardSessionCategory: string;
    let masterID: string;
    let increaseNumber = 2;
    const chinese_zh_id = languageIDList.filter(
      (obj: any) => obj.lang === "zh_hant"
    )[0].id;
    const eng_id = languageIDList.filter((obj: any) => obj.lang === "en")[0].id;
    await Promise.all(
      sessionList[1].combineContents.map(async (e: any, idx: number) => {
        masterID = sessionList[1].id;
        await Promise.all(
          e.cardContent.map(async (cardElement: any, cardIDX: number) => {
            if (cardElement.title === "Project Summary") {
              // console.log(cardElement.category);
              insertPriority = cardElement.treeContents[1].priority;
              cardSessionCategory = cardElement.treeContents[1].category;
              const createRequest = `{createContentDetail(data:$data){
                priority
                position
                content
                category
                type
                master {
                  id
                }
                title
              }}`;
              const createGraphqlQuery = {
                operationName: "CreateContentDetails",
                query: `mutation CreateContentDetails($data: [ContentDetailCreateInput!]!) ${createRequest}`,
                variables: {
                  data: [
                    {
                      priority: insertPriority,
                      content: "",
                      type: "text",
                      title: "test",
                      category: cardSessionCategory,
                      master: {
                        connect: {
                          id: masterID,
                        },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      priority: insertPriority + 1,
                      content: "",
                      type: "text",
                      title: "test",
                      category: cardSessionCategory,
                      master: {
                        connect: {
                          id: masterID,
                        },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                  ],
                },
              };
              await Promise.all(
                cardElement.treeContents.map(
                  async (treeElement: any, treeIDX: number) => {
                    if (treeIDX != 0) {
                      const request = `{updateContentDetail(where: $where,data: $data){
                      priority
                      id
                    }}`;
                      const graphqlQuery = {
                        operationName: "UpdateContentDetail",
                        query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!)${request}`,
                        variables: {
                          where: { id: treeElement.id },
                          data: {
                            priority: +treeElement.priority + increaseNumber,
                          },
                        },
                      };
                      let result = await axios({
                        url: endPoint,
                        method: "post",
                        data: graphqlQuery,
                        headers: headers,
                      });
                      // console.log(result);
                    }
                  }
                )
              );
            }
          })
        );
      })
    );
  }

  async function addCardPosition() {
    // console.log(sessionList);
    setIsLoading(true);
    await Promise.all(
      sessionList.map(async (e: any, idx: number) => {
        let sessionID = e.id;
        // console.log(sessionID);
        await Promise.all(
          e.combineContents.map(async (cardElement: any, cardIDX: number) => {
            if (cardElement.type === "card") {
              // console.log(cardElement);
              // console.log(cardIDX);
              let cardPositionPriority =
                cardElement.cardContent[cardElement.cardContent.length - 1]
                  .priority;
              const request = `{
                    createContentDetail(data: $data) {
                    priority
                    position
                    content
                    category
                    master {
                      id
                    }
                  }
                }`;
              const graphqlQuery = {
                operationName: "CreateContentDetail",
                query: `mutation CreateContentDetail($data: ContentDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    content: `${+cardIDX + 1}`,
                    position: "cardPosition",
                    category: cardElement.category,
                    master: {
                      connect: { id: sessionID },
                    },
                    priority: +cardPositionPriority + 1,
                  },
                },
              };
              let result = await axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              });
              console.log(result);
            }
          })
        );
      })
    );
    setTimeout(() => {
      dispatch(
        showMessage({
          message: `Card Position has added`,
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        })
      );
      setIsLoading(false);
    }, 500);
  }

  return (
    // <></>
    <>
      {/* <button onClick={() => addCardPosition()}>click me</button> */}

      {role === "superAdmin" && (
        <Button onClick={() => openCreateSessionDialog()}>add</Button>
      )}
      {/* {id === "Research" && (
        <button onClick={addResearchNewFields}>click me to add fields</button>
      )} */}
      <Dialog
        open={isOpenAlert}
        //onClose= {handleClose}
        sx={{
          top: "-80%",
        }}
      >
        <AlertDialog message={popUpMsg} duration={1500} />
      </Dialog>
      <br />
      {/* delete dialog */}
      <Dialog
        open={isLoading}
        // onClose={handleCloseLoading}
        fullWidth
      // style={{ width: "100px", height: "100px" }}
      >
        <FuseLoading />
      </Dialog>
      <Dialog
        open={deleteDialog}
        onClose={deleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <>
          <DialogTitle id="alert-dialog-title">{`Delete Card`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Confirm Delete Card.
            </DialogContentText>
          </DialogContent>
        </>

        <DialogActions>
          <Button onClick={deleteCard} autoFocus>
            Delete
          </Button>
          <Button onClick={deleteDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDoubleConfirmClear}
        onClose={closeDoubleConfirmClearMedia}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <>
          <DialogTitle id="alert-dialog-title">{`Delete Card`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Confirm Clear Media.
            </DialogContentText>
          </DialogContent>
        </>

        <DialogActions>
          <Button onClick={clearMedia} autoFocus>
            Clear
          </Button>
          <Button onClick={closeDoubleConfirmClearMedia}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={doubleConfirm}
        onClose={doubleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <>
          <DialogTitle id="alert-dialog-title">{`Delete Sub Session`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Confirm Delete Sub Session.
            </DialogContentText>
          </DialogContent>
        </>

        <DialogActions>
          <Button onClick={deleteTreeView} autoFocus>
            Delete
          </Button>
          <Button onClick={doubleConfirmClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/* delete dialog */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {moduleNumber && (
          <Typography
            sx={{
              fontSize: 18,
              marginLeft: "15px",
              position: "relative",
              left: 50,
            }}
            color="text.secondary"
            gutterBottom
          >
            CMS - Facilitator-Guided ACT - Module{moduleNumber}
          </Typography>
        )}
        {parentID != "" &&
          id != parentID &&
          id != "Recruitment" &&
          id != "Cooperation" &&
          id != "Volunteer" && (
            <Typography
              sx={{
                fontSize: 18,
                marginLeft: "15px",
                position: "relative",
                left: 50,
              }}
              color="text.secondary"
              gutterBottom
            >
              CMS - {parentID} - {id}
            </Typography>
          )}
        {parentID != "" && id != parentID && id === "Recruitment" && (
          <Typography
            sx={{
              fontSize: 18,
              marginLeft: "15px",
              position: "relative",
              left: 50,
            }}
            color="text.secondary"
            gutterBottom
          >
            CMS - {parentID} - Positions Available
          </Typography>
        )}
        {parentID != "" && id != parentID && id === "Cooperation" && (
          <Typography
            sx={{
              fontSize: 18,
              marginLeft: "15px",
              position: "relative",
              left: 50,
            }}
            color="text.secondary"
            gutterBottom
          >
            CMS - {parentID} - Collaboration
          </Typography>
        )}
        {parentID != "" && id != parentID && id === "Volunteer" && (
          <Typography
            sx={{
              fontSize: 18,
              marginLeft: "15px",
              position: "relative",
              left: 50,
            }}
            color="text.secondary"
            gutterBottom
          >
            CMS - {parentID} - Volunteer and Internship
          </Typography>
        )}
        {(parentID === "" || parentID === id) && !moduleNumber && (
          <Typography
            sx={{
              fontSize: 18,
              marginLeft: "15px",
              position: "relative",
              left: 50,
            }}
            color="text.secondary"
            gutterBottom
          >
            CMS - {id}
          </Typography>
        )}

        {parentID != "" && (
          <div
            style={{
              position: "relative",
              bottom: 6,
              right: 50,
            }}
          >
            <Button
              onClick={() => {
                navigate(`/PagesList/${parentID}`);
              }}
            >
              Back
            </Button>
          </div>
        )}
        {moduleNumber && (
          <div
            style={{
              position: "relative",
              bottom: 6,
              right: 50,
            }}
          >
            <Button
              onClick={() => {
                navigate(`/FG_ACT/Module${moduleNumber}`);
              }}
            >
              Back
            </Button>
          </div>
        )}
      </div>
      <div
        style={{
          height: "10px",
          width: "100vw",
          borderBottom: "1px solid grey",
          marginBottom: "20px",
        }}
      ></div>
      <br />
      <Dialog open={openCreateSession} onClose={closeCreateSession}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => closeCreateSession()}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Session Title"
            type="text"
            name="title"
            fullWidth
            variant="standard"
            value={sessionTitle}
            onChange={(e) => setSessionTitle(e.target.value)}
          />
        </DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            // alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Button variant="contained" component="label" onClick={addSession}>
            Create
          </Button>
        </div>
        <br />
      </Dialog>
      <Dialog
        open={openCreate}
        onClose={closeCreateDialog}
      // fullScreen={fullScreen}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => closeCreateDialog()}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-standard-label">
              Content Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={contentType}
              defaultValue={contentType}
              onChange={handleContentType}
              // onChange={handleUpdateDialogChange}
              label="parent"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {contentTypeList.map((e) => {
                return (
                  <MenuItem value={`${e.value}`} key={`contentType_${e.value}`}>
                    {e.display}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            name="title"
            fullWidth
            variant="standard"
            value={contentTitle}
            onChange={(e) => setContentTitle(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="position"
            label="Position"
            type="text"
            name="position"
            fullWidth
            variant="standard"
            value={contentPosition}
            onChange={(e) => setContentPosition(e.target.value)}
          />
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-standard-label">
              Language
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={contentLanguage}
              defaultValue={contentLanguage}
              onChange={handleContentLanguage}
              // onChange={handleUpdateDialogChange}
              label="parent"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {languageList.map((e) => {
                return (
                  <MenuItem value={`${e.value}`} key={e.value}>
                    {e.display}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            // alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Button
            variant="contained"
            component="label"
            onClick={createSessionContent}
          >
            Create
          </Button>
        </div>
        <br />
      </Dialog>

      {/* <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton aria-label="add" size="small" onClick={openDialog}>
          <AddIcon />
        </IconButton>
      </Stack> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {sessionList.map((e, index) => {
          return (
            <div key={`sessionList_${index}`}>
              {/* <button onClick={() => delSession(e.id)}>delete</button> */}
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
                multiSelect
                sx={{
                  marginBottom: "10px",
                  marginLeft: "30px",
                  marginRight: "30px",
                }}
              >
                <TreeItem nodeId={index.toString()} label={e.title}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {role === "superAdmin" && (
                      <Button
                        onClick={() => openCreateDialog(index)}
                        variant="contained"
                        component="label"
                        style={{ marginTop: "10px" }}
                      >
                        Select Content Type
                      </Button>
                    )}
                  </div>

                  <br />
                  {id === "landing" && e.title === "Session 5 - 序" && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h2>
                        <b>
                          Please note that if there are fewer than 3 cards, only
                          1 card will be displayed. Additionally, all
                          information must be filled out for the display to
                          appear.
                        </b>
                      </h2>
                    </div>
                  )}
                  {id === "landing" && e.title === "Session 7 - Contact Us" && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h2>
                        <b>All information must be filled out</b>
                      </h2>
                    </div>
                  )}
                  {id === "What Is ACT" && e.title === "What Is ACT" && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h2>
                        <b>
                          Session Title should be filled out & Below fields can
                          be empty and the corresponding components will be
                          hidden
                        </b>
                      </h2>
                    </div>
                  )}
                  {id === "Facilitator-Guided ACT" &&
                    e.title === "Introduction" && (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <h2>
                          <b>
                            Below fields can be empty and the corresponding
                            components will be hidden
                          </b>
                        </h2>
                      </div>
                    )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {e.combineContents.map((elements: any, idx: number) => {
                      return (
                        <div
                          key={`combineContent_${idx}`}
                          style={{
                            // minWidth: 600,
                            marginLeft: "10px",
                          }}
                        >
                          {elements.type === "treeView" && (
                            <>
                              <br />
                              <TreeView
                                aria-label="file system navigator"
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                expanded={expanded}
                                selected={selected}
                                onNodeToggle={handleToggle}
                                onNodeSelect={handleSelect}
                                multiSelect
                                sx={{
                                  marginBottom: "10px",
                                  marginLeft: "30px",
                                  marginRight: "30px",
                                }}
                              >
                                <TreeItem
                                  nodeId={"treeItem" + idx.toString()}
                                  label={elements.title}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {role === "superAdmin" && (
                                      <Button
                                        onClick={() =>
                                          openTreeDialog(idx, elements.category)
                                        }
                                        variant="contained"
                                        component="label"
                                        style={{ marginTop: "10px" }}
                                      >
                                        Select Content Type
                                      </Button>
                                    )}
                                  </div>
                                  {elements.treeContents.map(
                                    (treeElements: any, treeIDX: number) => {

                                      return (
                                        <div key={`treeView${treeIDX}`}>
                                          {treeElements.type === "icon" && (
                                            <>
                                              <div
                                                style={{
                                                  border: "solid 3px black",
                                                  padding: "20px",
                                                }}
                                              >
                                                {(treeElements.url ===
                                                  API.getImage ||
                                                  treeElements.url === "") &&
                                                  treeElements.previewUrl ===
                                                  "" && (
                                                    <Avatar
                                                      alt="Remy Sharp"
                                                      src={
                                                        treeElements.previewUrl
                                                      }
                                                      sx={{
                                                        width: 150,
                                                        height: 150,
                                                      }}
                                                    ></Avatar>
                                                  )}
                                                {treeElements.previewUrl !=
                                                  "" && (
                                                    <Avatar
                                                      alt="Remy Sharp"
                                                      src={
                                                        treeElements.previewUrl
                                                      }
                                                      sx={{
                                                        width: 150,
                                                        height: 150,
                                                      }}
                                                    ></Avatar>
                                                  )}
                                                {treeElements.previewUrl ===
                                                  "" &&
                                                  treeElements.url !=
                                                  API.getImage &&
                                                  treeElements.url != "" && (
                                                    <Avatar
                                                      alt="Remy Sharp"
                                                      src={treeElements.url}
                                                      sx={{
                                                        width: 150,
                                                        height: 150,
                                                      }}
                                                    ></Avatar>
                                                  )}
                                                <div>
                                                  <Button
                                                    variant="contained"
                                                    component="label"
                                                  >
                                                    Upload Icon
                                                    <input
                                                      hidden
                                                      accept="image/*"
                                                      multiple
                                                      type="file"
                                                      onChange={handleFileSelect(
                                                        treeElements
                                                      )}
                                                    />
                                                  </Button>
                                                  <Button
                                                    variant="contained"
                                                    component="label"
                                                    onClick={() =>
                                                      doubleConfirmClearMedia(
                                                        treeElements
                                                      )
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    Clear Icon
                                                  </Button>
                                                </div>
                                              </div>
                                            </>
                                          )}

                                          {treeElements.type === "text" && (
                                            <>
                                              <TextField
                                                autoFocus
                                                margin="dense"
                                                id={treeElements.content}
                                                label={treeElements.title}
                                                type="text"
                                                name="content"
                                                fullWidth
                                                variant="standard"
                                                value={treeElements.content}
                                                onChange={(e) =>
                                                  modifyTreeContent(
                                                    treeElements.id,
                                                    idx,
                                                    treeElements.type,
                                                    e.target.value,
                                                    index,
                                                    treeIDX
                                                  )
                                                }
                                              />
                                              {role === "superAdmin" && (
                                                <>
                                                  <br />
                                                  <Button
                                                    variant="contained"
                                                    component="label"
                                                    onClick={() =>
                                                      changeWordEditorToText(
                                                        treeElements
                                                      )
                                                    }
                                                  >
                                                    Change Type
                                                  </Button>
                                                </>
                                              )}

                                              {/* {role === "superAdmin" && (
                                              <>
                                                <br />
                                                <Button
                                                  variant="contained"
                                                  component="label"
                                                  onClick={() =>
                                                    changeLanguage(treeElements)
                                                  }
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  Change Language
                                                </Button>
                                              </>
                                            )} */}
                                            </>
                                          )}
                                          {treeElements.type ===
                                            "wordEditor" && (
                                              <>
                                                <EditorDraft
                                                  onConvertToHtml={setRawHTML(
                                                    idx,
                                                    treeElements
                                                  )}
                                                  htmlRawValue={
                                                    treeElements.content
                                                  }
                                                />
                                                {role === "superAdmin" && (
                                                  <>
                                                    <br />
                                                    <Button
                                                      variant="contained"
                                                      component="label"
                                                      onClick={() =>
                                                        changeWordEditorToText(
                                                          treeElements
                                                        )
                                                      }
                                                    >
                                                      Change Type
                                                    </Button>
                                                  </>
                                                )}
                                                {/* {role === "superAdmin" && (
                                              <>
                                                <br />
                                                <Button
                                                  variant="contained"
                                                  component="label"
                                                  onClick={() =>
                                                    changeLanguage(treeElements)
                                                  }
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  Change Language
                                                </Button>
                                              </>
                                            )} */}
                                              </>
                                            )}
                                          {treeElements.type === "image" && (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center",
                                              }}
                                            >
                                              {treeElements.previewUrl !=
                                                "" && (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      flexDirection: "column",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <CardMedia
                                                      component="img"
                                                      height="194"
                                                      image={
                                                        treeElements.previewUrl
                                                      }
                                                      alt="Please upload image"
                                                    />
                                                  </div>
                                                )}
                                              {treeElements.previewUrl === "" &&
                                                treeElements.url === "" && (
                                                  <CardMedia
                                                    component="img"
                                                    height="194"
                                                    image={
                                                      "/material-ui-static/images/cards/paella.jpg"
                                                    }
                                                    alt="Please upload image"
                                                  />
                                                )}
                                              {treeElements.previewUrl === "" &&
                                                treeElements.url != "" && (
                                                  <CardMedia
                                                    component="img"
                                                    height="194"
                                                    image={treeElements.url}
                                                    alt="Please upload image"
                                                  />
                                                )}
                                              <div>
                                                Maximum Image Size : 10MB
                                              </div>
                                              <br />
                                              {id === "Research" && (
                                                <div>Suggested Ratio : 1:1</div>
                                              )}
                                              {id === "Training" && (
                                                <div>
                                                  Suggested Ratio : 16:9
                                                </div>
                                              )}
                                              {id === "Publicity" &&
                                                e.title ===
                                                "Publicity News" && (
                                                  <div>
                                                    Suggested Ratio : 1:1
                                                  </div>
                                                )}
                                              {/* {id === "Publicity" &&
                                                e.title ===
                                                  "Publicity Radio" && (
                                                  <div>
                                                    Suggested Ratio : 16:9
                                                  </div>
                                                )} */}
                                              {id === "Publicity" &&
                                                (e.title ===
                                                  "Broadcast Interview" ||
                                                  e.title ===
                                                  "Press Releases") && (
                                                  <div>
                                                    Suggested Ratio : 16:9
                                                  </div>
                                                )}
                                              {/* <div>
                                                Suggested Ratio : 1500 x 1000
                                              </div> */}
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  justifyContent: "center",
                                                  marginTop: "20px",
                                                }}
                                              >
                                                <div>
                                                  <Button
                                                    variant="contained"
                                                    component="label"
                                                  >
                                                    Upload Image
                                                    <input
                                                      hidden
                                                      accept="image/*"
                                                      multiple
                                                      type="file"
                                                      onChange={handleFileSelect(
                                                        treeElements
                                                      )}
                                                    />
                                                  </Button>
                                                  <Button
                                                    variant="contained"
                                                    component="label"
                                                    onClick={() =>
                                                      doubleConfirmClearMedia(
                                                        treeElements
                                                      )
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    Clear Image
                                                  </Button>
                                                </div>
                                              </div>
                                              <br />
                                            </div>
                                          )}
                                          {treeElements.type === "audio" && (
                                            <>
                                              <br />

                                              <audio controls>
                                                <source
                                                  src={treeElements.url}
                                                  type="audio/mpeg"
                                                ></source>
                                              </audio>
                                              <br />
                                              <div>
                                                Maximum Audio Size : 10MB
                                              </div>
                                              <br />
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  justifyContent: "center",
                                                  marginTop: "20px",
                                                }}
                                              >
                                                <div>
                                                  <Button
                                                    variant="contained"
                                                    component="label"
                                                  >
                                                    Upload Audio
                                                    <input
                                                      hidden
                                                      accept="audio/*"
                                                      multiple
                                                      type="file"
                                                      onChange={handleFileSelect(
                                                        treeElements
                                                      )}
                                                    />
                                                  </Button>
                                                  <Button
                                                    variant="contained"
                                                    component="label"
                                                    onClick={() =>
                                                      doubleConfirmClearMedia(
                                                        treeElements
                                                      )
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    Clear Audio
                                                  </Button>
                                                </div>
                                              </div>
                                              <br />
                                            </>
                                          )}
                                          {treeElements.type === "video" && (
                                            <>
                                              <br />
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <TextField
                                                  autoFocus
                                                  margin="dense"
                                                  id={treeElements.url + idx}
                                                  label={treeElements.title}
                                                  type="text"
                                                  name="content"
                                                  variant="standard"
                                                  fullWidth
                                                  value={
                                                    treeElements.previewUrl
                                                  }
                                                  onChange={(e) =>
                                                    modifyTreeVideoContent(
                                                      treeElements.id,
                                                      idx,
                                                      treeElements.type,
                                                      e.target.value,
                                                      index,
                                                      treeIDX
                                                    )
                                                  }
                                                />
                                                <div
                                                  style={{
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  Please enter youtube link
                                                </div>
                                                <div
                                                  style={{ marginTop: "20px" }}
                                                >
                                                  <iframe
                                                    src={treeElements.url || ""}
                                                    allow="autoplay; fullscreen"
                                                  ></iframe>
                                                </div>
                                              </div>
                                              {/* {treeElements.previewUrl !=
                                                "" && (
                                                <video controls>
                                                  <source
                                                    src={
                                                      treeElements.previewUrl
                                                    }
                                                    type="video/mp4"
                                                  ></source>
                                                  Your browser does not support
                                                  the video tag.
                                                </video>
                                              )}
                                              {treeElements.url != "" &&
                                                treeElements.previewUrl ===
                                                  "" && (
                                                  <video controls>
                                                    <source
                                                      src={treeElements.url}
                                                      type="video/mp4"
                                                    ></source>
                                                    Your browser does not
                                                    support the video tag.
                                                  </video>
                                                )}
                                              {treeElements.url === "" &&
                                                treeElements.previewUrl ===
                                                  "" && (
                                                  <video controls>
                                                    <source
                                                      src={treeElements.url}
                                                      type="video/mp4"
                                                    ></source>
                                                    Your browser does not
                                                    support the video tag.
                                                  </video>
                                                )} */}
                                              <br />
                                              {/* <div>
                                                Maximum video Size : 10MB
                                              </div>
                                              <br /> */}
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  justifyContent: "center",
                                                  marginTop: "20px",
                                                }}
                                              >
                                                <div>
                                                  <Button
                                                    variant="contained"
                                                    component="label"
                                                  >
                                                    Upload Video
                                                    <input
                                                      hidden
                                                      accept="video/*"
                                                      multiple
                                                      type="file"
                                                      onChange={handleFileSelect(
                                                        treeElements
                                                      )}
                                                    />
                                                  </Button>
                                                  <Button
                                                    variant="contained"
                                                    component="label"
                                                    onClick={() =>
                                                      doubleConfirmClearMedia(
                                                        treeElements
                                                      )
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    Clear Video
                                                  </Button>
                                                </div>
                                              </div>
                                              <br />
                                            </>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                  <br />
                                </TreeItem>
                              </TreeView>
                              <br />
                            </>
                          )}
                          {elements.type === "textWithoutField" && (
                            <>
                              <br />
                              {id === "Publicity" &&
                                (e.title === "Pin to top") && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginLeft: "10px",
                                      width: "73vw"
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: "5px",
                                        color: "blue",
                                      }}
                                    >
                                      Frontend Homepage maximum show 12 cards
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: "blue",
                                        color: "blue",
                                      }}
                                    >
                                      Cards without date and not pinned default won't show on frontend Homepage
                                    </div>
                                  </div>
                                )}
                              <br />
                            </>)}
                          {elements.type === "text" && (
                            <>
                              <br />
                              <TextField
                                autoFocus
                                margin="dense"
                                id={elements.content}
                                label={elements.title}
                                type="text"
                                name="content"
                                variant="standard"
                                fullWidth
                                value={elements.content}
                                onChange={(e) =>
                                  modifyContent(
                                    elements.id,
                                    idx,
                                    elements.type,
                                    e.target.value,
                                    index
                                  )
                                }
                                sx={{ width: "73vw" }}
                              />
                              {id === "Footer" &&
                                e.title === "Social Media" && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <div style={{ color: "blue" }}>
                                      Can empty and the corresponding components
                                      and icon will be hidden
                                    </div>
                                  </div>
                                )}
                              {id === "Footer" && e.title === "Title" && (
                                <div
                                  style={{
                                    marginTop: "15px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <div style={{ color: "blue" }}>
                                    ! Can be empty but their corresponding row
                                    will not be hidden
                                  </div>
                                </div>
                              )}
                              {id === "Research" &&
                                e.title === "Research Introduction" &&
                                (elements.title === "TItle (繁中)" ||
                                  elements.title === "TItle (ENG)") && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "blue",
                                      }}
                                    >
                                      Can empty and the corresponding components
                                      will be hidden
                                    </div>
                                  </div>
                                )}
                              {id === "Recruitment" &&
                                e.title === "Info Session" &&
                                elements.title === "Title (En)" && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: "5px",
                                        color: "blue",
                                      }}
                                    >
                                      Recommend maximum characters count : 30
                                    </div>
                                    <div
                                      style={{
                                        color: "blue",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      Current characters count :{" "}
                                      {elements.content.length}
                                    </div>
                                    <div style={{ color: "blue" }}>
                                      ! Can be empty but their corresponding row
                                      will not be hidden
                                    </div>
                                  </div>
                                )}
                              {id === "Recruitment" &&
                                e.title === "Info Session" &&
                                elements.title === "Title (繁)" && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: "5px",
                                        color: "blue",
                                      }}
                                    >
                                      Recommend maximum characters count :{" "}
                                      {Math.ceil(30 / 2.18)}
                                    </div>
                                    <div
                                      style={{
                                        color: "blue",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      Current characters count :{" "}
                                      {elements.content.length}
                                    </div>
                                    <div style={{ color: "blue" }}>
                                      ! Can be empty but their corresponding row
                                      will not be hidden
                                    </div>
                                  </div>
                                )}
                              {id === "Recruitment" &&
                                e.title === "Info Session" &&
                                elements.title === "Email" && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <div style={{ color: "blue" }}>
                                      ! Can be empty but their corresponding row
                                      will not be hidden
                                    </div>
                                  </div>
                                )}
                              {id === "Publicity" &&
                                (e.title === "What's new?" ||
                                  e.title === "Broadcast Interview" ||
                                  e.title === "Press Releases") && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: "5px",
                                        color: "blue",
                                      }}
                                    >
                                      Recommend maximum characters count : 16
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: "blue",
                                        color: "blue",
                                      }}
                                    >
                                      Current characters count :{" "}
                                      {elements.content.length}
                                    </div>
                                    <div style={{ color: "blue" }}>
                                      Can empty and the corresponding components
                                      will be hidden
                                    </div>
                                  </div>
                                )}
                              {id === "What Is ACT" &&
                                (e.title === "What Is ACT" ||
                                  e.title === "Current Evidence Session" ||
                                  e.title ===
                                  "Introduction To Acceptance And Commitment Therapy Session") &&
                                elements.title === "Session Title (En)" && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: "5px",
                                        color: "blue",
                                      }}
                                    >
                                      Recommend maximum characters count : 70
                                    </div>
                                    <div style={{ color: "blue" }}>
                                      Current characters count :{" "}
                                      {elements.content.length}
                                    </div>
                                  </div>
                                )}
                              {id === "What Is ACT" &&
                                (e.title === "What Is ACT" ||
                                  e.title === "Current Evidence Session" ||
                                  e.title ===
                                  "Introduction To Acceptance And Commitment Therapy Session") &&
                                elements.title === "Session Title (繁)" && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: "5px",
                                        color: "blue",
                                      }}
                                    >
                                      Recommend maximum characters count :{" "}
                                      {Math.ceil(70 / 2.18)}
                                    </div>
                                    <div style={{ color: "blue" }}>
                                      Current characters count :{" "}
                                      {elements.content.length}
                                    </div>
                                  </div>
                                )}
                              {id === "What Is ACT" &&
                                e.title === "What Is ACT" &&
                                elements.title.includes("Caption") &&
                                elements.title.includes("En") && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: "5px",
                                        color: "blue",
                                      }}
                                    >
                                      Recommend maximum characters count : 80
                                    </div>
                                    <div style={{ color: "blue" }}>
                                      Current characters count :{" "}
                                      {elements.content.length}
                                    </div>
                                  </div>
                                )}
                              {id === "What Is ACT" &&
                                e.title === "What Is ACT" &&
                                elements.title.includes("Caption") &&
                                elements.title.includes("繁") && (
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginBottom: "5px",
                                        color: "blue",
                                      }}
                                    >
                                      Recommend maximum characters count :{" "}
                                      {Math.ceil(80 / 2.18)}
                                    </div>
                                    <div style={{ color: "blue" }}>
                                      Current characters count :{" "}
                                      {elements.content.length}
                                    </div>
                                  </div>
                                )}
                              <br />

                              {role === "superAdmin" && (
                                <>
                                  <br />
                                  <Button
                                    variant="contained"
                                    component="label"
                                    onClick={() =>
                                      changeWordEditorToText(elements)
                                    }
                                  >
                                    Change Type
                                  </Button>
                                </>
                              )}
                              {/* {role === "superAdmin" && (
                              <>
                                <br />
                                <Button
                                  variant="contained"
                                  component="label"
                                  onClick={() => changeLanguage(elements)}
                                  style={{ marginTop: "10px" }}
                                >
                                  Change Language
                                </Button>
                              </>
                            )} */}
                              {/* <br /> */}
                            </>
                          )}
                          {elements.type === "wordEditor" && (
                            <>
                              <br />
                              <div
                                style={{
                                  width: "100%",
                                  marginRight: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    // marginLeft: "40px",
                                    // marginRight: "10px",
                                    width: "73vw",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      marginTop: "20px",
                                    }}
                                    color="black"
                                    gutterBottom
                                  >
                                    {elements.title}
                                  </Typography>
                                  <EditorDraft
                                    onConvertToHtml={setRawHTML(idx, elements)}
                                    htmlRawValue={elements.content}
                                  />

                                  {id === "landing" &&
                                    e.title === "Session 1 - Description" &&
                                    elements.title.includes("En") && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          700
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                      </div>
                                    )}
                                  {id === "landing" &&
                                    e.title === "Session 1 - Description" &&
                                    elements.title.includes("繁") && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          {Math.ceil(700 / 2.18)}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                      </div>
                                    )}
                                  {id === "Recruitment" &&
                                    e.title === "Positions Available" &&
                                    elements.title.includes("En") && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          550
                                        </div>
                                        <div
                                          style={{
                                            color: "blue",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Can empty and their corresponding
                                          elements will be hidden
                                        </div>
                                      </div>
                                    )}
                                  {id === "Recruitment" &&
                                    e.title === "Positions Available" &&
                                    elements.title.includes("繁") && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          {Math.ceil(550 / 2.18)}
                                        </div>
                                        <div
                                          style={{
                                            color: "blue",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Can empty and their corresponding
                                          elements will be hidden
                                        </div>
                                      </div>
                                    )}
                                  {(id === "Cooperation" ||
                                    id === "Volunteer") &&
                                    e.title === "Description" &&
                                    elements.title.includes("En") && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          200
                                        </div>
                                        <div
                                          style={{
                                            color: "blue",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Can empty and their corresponding
                                          elements will be hidden
                                        </div>
                                      </div>
                                    )}
                                  {(id === "Cooperation" ||
                                    id === "Volunteer") &&
                                    e.title === "Description" &&
                                    elements.title.includes("繁") && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          {Math.ceil(200 / 2.18)}
                                        </div>
                                        <div
                                          style={{
                                            color: "blue",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Can empty and their corresponding
                                          elements will be hidden
                                        </div>
                                      </div>
                                    )}
                                  {id === "Recruitment" &&
                                    e.title === "Info Session" &&
                                    elements.title === "Content (En)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          590
                                        </div>
                                        <div
                                          style={{
                                            color: "blue",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Can empty and their corresponding
                                          elements will be hidden
                                        </div>
                                      </div>
                                    )}
                                  {id === "Recruitment" &&
                                    e.title === "Info Session" &&
                                    elements.title === "Content (繁)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          {Math.ceil(590 / 2.18)}
                                        </div>
                                        <div
                                          style={{
                                            color: "blue",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Can empty and their corresponding
                                          elements will be hidden
                                        </div>
                                      </div>
                                    )}
                                  {id === "What Is ACT" &&
                                    e.title === "What Is ACT" &&
                                    elements.title === "Content1 (En)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          1100
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                      </div>
                                    )}
                                  {id === "What Is ACT" &&
                                    e.title === "What Is ACT" &&
                                    elements.title === "Content1 (繁)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          {Math.ceil(1100 / 2.18)}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                      </div>
                                    )}
                                  {id === "What Is ACT" &&
                                    e.title ===
                                    "Introduction To Acceptance And Commitment Therapy Session" &&
                                    (elements.title === "Content 3 (En)" ||
                                      elements.title === "Content 1 (En)" ||
                                      elements.title === "Content 2 (En)") && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          1100
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                      </div>
                                    )}
                                  {id === "What Is ACT" &&
                                    e.title ===
                                    "Introduction To Acceptance And Commitment Therapy Session" &&
                                    (elements.title === "Content 3 (繁)" ||
                                      elements.title === "Content 1 (繁)" ||
                                      elements.title === "Content 2 (繁)") && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          {Math.ceil(1100 / 2.18)}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                      </div>
                                    )}
                                  {id === "Facilitator-Guided ACT" &&
                                    e.title === "Introduction" &&
                                    elements.title === "Title (En)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          20
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                      </div>
                                    )}
                                  {id === "Facilitator-Guided ACT" &&
                                    e.title === "Introduction" &&
                                    elements.title === "Title (繁)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          {Math.ceil(20 / 2.18)}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                      </div>
                                    )}
                                  {id === "Facilitator-Guided ACT" &&
                                    e.title === "Introduction" &&
                                    elements.title === "Content (En)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          350
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                      </div>
                                    )}
                                  {id === "Facilitator-Guided ACT" &&
                                    e.title === "Introduction" &&
                                    elements.title === "Content (繁)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          {Math.ceil(350 / 2.18)}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                      </div>
                                    )}
                                  {id === undefined &&
                                    e.title === "Reading Material" &&
                                    elements.title === "Description (En)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          500
                                        </div>
                                        <div
                                          style={{
                                            color: "blue",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          If empty the components are located
                                          will be hidden
                                        </div>
                                      </div>
                                    )}
                                  {id === undefined &&
                                    e.title === "Reading Material" &&
                                    elements.title === "Description (繁)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          {Math.ceil(500 / 2.18)}
                                        </div>
                                        <div
                                          style={{
                                            color: "blue",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          If empty the components are located
                                          will be hidden
                                        </div>
                                      </div>
                                    )}
                                  {id === undefined &&
                                    (e.title === "Story Telling" ||
                                      e.title === "Self-Learning Exercise") &&
                                    elements.title === "Description (En)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          150
                                        </div>
                                        <div
                                          style={{
                                            color: "blue",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          If empty the components are located
                                          will be hidden
                                        </div>
                                      </div>
                                    )}
                                  {id === undefined &&
                                    (e.title === "Story Telling" ||
                                      e.title === "Self-Learning Exercise") &&
                                    elements.title === "Description (繁)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          {Math.ceil(150 / 2.18)}
                                        </div>
                                        <div
                                          style={{
                                            color: "blue",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          If empty the components are located
                                          will be hidden
                                        </div>
                                      </div>
                                    )}
                                  {id === "Research" &&
                                    e.title === "Research Introduction" &&
                                    elements.title === "Content (繁中)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          {Math.ceil(500 / 2.18)}-
                                          {Math.ceil(800 / 2.18)}
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                      </div>
                                    )}
                                  {id === "Research" &&
                                    e.title === "Research Introduction" &&
                                    elements.title === "Content (ENG)" && (
                                      <div
                                        style={{
                                          marginTop: "15px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "blue",
                                          }}
                                        >
                                          Recommend maximum characters count :
                                          500-800
                                        </div>
                                        <div style={{ color: "blue" }}>
                                          Current characters count :{" "}
                                          {elements.content
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&amp;/g, "&")
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">").length -
                                            1 ===
                                            -1
                                            ? 0
                                            : elements.content
                                              .replace(/&nbsp;/g, " ")
                                              .replace(/&amp;/g, "&")
                                              .replace(/<[^>]*>/g, "")
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">").length -
                                            1}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                              <br />
                              {role === "superAdmin" && (
                                <div style={{ marginLeft: "10px" }}>
                                  <Button
                                    variant="contained"
                                    component="label"
                                    onClick={() =>
                                      changeWordEditorToText(elements)
                                    }
                                  >
                                    Change Type
                                  </Button>
                                </div>
                              )}
                              {/* {role === "superAdmin" && (
                              <>
                                <br />
                                <Button
                                  variant="contained"
                                  component="label"
                                  onClick={() => changeLanguage(elements)}
                                  style={{ marginTop: "10px" }}
                                >
                                  Change Language
                                </Button>
                              </>
                            )} */}
                            </>
                          )}
                          {elements.type === "image" && (
                            <>
                              <br />
                              <div
                                style={{
                                  border: "solid black 3px",
                                  padding: "20px",
                                  marginRight: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  {elements.previewUrl != "" && (
                                    <>
                                      <div
                                        style={{
                                          // display: "flex",
                                          // justifyContent: "center",
                                          // width: "800px",
                                          // maxWidth: "800px",
                                          // height: "450px",
                                          // maxHeight: "500px",
                                          border: "solid 3px black",
                                        }}
                                      >
                                        <CardMedia
                                          component="img"
                                          height="194"
                                          image={elements.previewUrl}
                                          alt="Please upload image"
                                        />
                                      </div>
                                    </>
                                  )}
                                  {elements.previewUrl === "" &&
                                    (elements.url === "" ||
                                      elements.url === API.getImage) && (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          width: "800px",
                                          maxWidth: "800px",
                                          height: "450px",
                                          maxHeight: "500px",
                                          border: "solid 3px black",
                                          alignItems: "center",
                                        }}
                                      >
                                        <CardMedia
                                          component="img"
                                          height="194"
                                          image={""}
                                          alt=" Please upload image"
                                        />
                                      </div>
                                    )}
                                  {elements.previewUrl === "" &&
                                    elements.url != "" &&
                                    elements.url != API.getImage && (
                                      <>
                                        <div
                                          style={{
                                            // display: "flex",
                                            // justifyContent: "center",
                                            // width: "800px",
                                            // maxWidth: "800px",
                                            // height: "450px",
                                            // maxHeight: "500px",
                                            border: "solid 3px black",
                                            // alignItems: "center",
                                          }}
                                        >
                                          <CardMedia
                                            component="img"
                                            height="194"
                                            image={elements.url}
                                            alt=" Please upload image"
                                          />
                                        </div>
                                      </>
                                    )}

                                  <br />
                                  <div>Maximum Image Size : 10MB</div>
                                  <br />
                                  {id === "Research" && (
                                    <div>Suggested Ratio : 1:1</div>
                                  )}
                                  {id === "Training" && (
                                    <div>Suggested Ratio : 16:9</div>
                                  )}
                                  {id === "What Is ACT" &&
                                    e.title === "What Is ACT" && (
                                      <div>No limited Ratio</div>
                                    )}
                                  {id === "Publicity" &&
                                    e.title === "What's new?" && (
                                      <div>Suggested Ratio : 1:1</div>
                                    )}
                                  {/* {id === "Publicity" &&
                                    e.title === "Publicity Radio" && (
                                      <div>Suggested Ratio : 16:9</div>
                                    )} */}
                                  {id === "Publicity" &&
                                    (e.title === "Broadcast Interview" ||
                                      e.title === "Press Releases") && (
                                      <div>Suggested Ratio : 16:9</div>
                                    )}
                                  {/* <div>Suggested Ratio : 1500 x 1000</div> */}
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div>
                                      <Button
                                        variant="contained"
                                        component="label"
                                      >
                                        Upload Image
                                        <input
                                          hidden
                                          accept="image/*"
                                          multiple
                                          type="file"
                                          onChange={handleFileSelect(elements)}
                                        />
                                      </Button>
                                      <Button
                                        variant="contained"
                                        component="label"
                                        onClick={() =>
                                          doubleConfirmClearMedia(elements)
                                        }
                                        style={{ marginLeft: "10px" }}
                                      >
                                        Clear Image
                                      </Button>
                                    </div>
                                  </div>
                                  <br />
                                </div>
                              </div>
                            </>
                          )}
                          {elements.type === "audio" && (
                            <>
                              <br />
                              <div
                                style={{
                                  border: "solid black 3px",
                                  padding: "20px",
                                  marginRight: "10px",
                                }}
                              >
                                {elements.url != API.getAudio &&
                                  elements.url != "" &&
                                  elements.previewUrl === "" && (
                                    <audio controls>
                                      <source
                                        src={elements.url}
                                        type="audio/mpeg"
                                      ></source>
                                    </audio>
                                  )}
                                {elements.previewUrl === "" && (
                                  <embed src={elements.previewUrl}></embed>
                                )}
                                {(elements.url === API.getAudio ||
                                  elements.url === "") &&
                                  elements.previewUrl === "" && (
                                    <audio controls>
                                      <source
                                        src={""}
                                        type="audio/mpeg"
                                      ></source>
                                    </audio>
                                  )}

                                <br />
                                <div>Maximum Audio Size : 10MB</div>
                                <br />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <div>
                                    <Button
                                      variant="contained"
                                      component="label"
                                    >
                                      Upload Audio
                                      <input
                                        hidden
                                        accept="audio/*"
                                        multiple
                                        type="file"
                                        onChange={handleFileSelect(elements)}
                                      />
                                    </Button>
                                    <Button
                                      variant="contained"
                                      component="label"
                                      onClick={() =>
                                        doubleConfirmClearMedia(elements)
                                      }
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Clear Audio
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              <br />
                            </>
                          )}
                          {elements.type === "video" && (
                            <>
                              <br />
                              <div
                                style={{
                                  border: "solid black 3px",
                                  padding: "20px",
                                  marginRight: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <TextField
                                    autoFocus
                                    margin="dense"
                                    id={elements.url + idx}
                                    label={elements.title}
                                    type="text"
                                    name="content"
                                    variant="standard"
                                    fullWidth
                                    value={elements.preivewUrl}
                                    onChange={(e) =>
                                      modifyVideoContent(
                                        elements.id,
                                        idx,
                                        elements.type,
                                        e.target.value,
                                        index
                                      )
                                    }
                                  />
                                  <div style={{ marginBottom: "10px" }}>
                                    Please enter youtube link
                                  </div>
                                  <div style={{ marginTop: "20px" }}>
                                    <iframe
                                      src={elements.url || ""}
                                      allow="autoplay; fullscreen"
                                    ></iframe>
                                  </div>
                                  {/* {elements.previewUrl === "" &&
                                    (elements.url === "" ||
                                      elements.url === API.getVideo) && (
                                      // <video controls>
                                      //   <source
                                      //     src={elements.url}
                                      //     type="video/mp4"
                                      //   ></source>
                                      //   Your browser does not support the video
                                      //   tag.
                                      // </video>
                                      // youtube
                                     <></>
                                    )}
                                  {elements.previewUrl != "" && (
                                    <video controls>
                                      <source
                                        src={elements.previewUrl}
                                        type="video/mp4"
                                      ></source>
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  )}
                                  {elements.previewUrl === "" &&
                                    elements.url != "" &&
                                    elements.url != API.getVideo && (
                                      <video controls>
                                        <source
                                          src={elements.url}
                                          type="video/mp4"
                                        ></source>
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    )} */}
                                </div>
                                <br />
                                {/* <div>Maximum Video Size : 10MB</div>
                                <br /> */}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  {/* <div>
                                    <Button
                                      variant="contained"
                                      component="label"
                                    >
                                      Upload Video
                                      <input
                                        hidden
                                        accept="video/*"
                                        multiple
                                        type="file"
                                        onChange={handleFileSelect(elements)}
                                      />
                                    </Button>
                                    <Button
                                      variant="contained"
                                      component="label"
                                      onClick={() =>
                                        doubleConfirmClearMedia(elements)
                                      }
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Clear Video
                                    </Button> */}
                                  {/* </div> */}
                                </div>
                              </div>
                              <br />
                            </>
                          )}
                          {/* [section] cards */}
                          {elements.type === "card" && (
                            <>
                              <br />
                              {!elements.expand && (
                                <div>

                                  <Card
                                    sx={{ width: "250px", height: elements?.pin ? "300px" : "250px" }}
                                  >
                                    <CardContent>

                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        {!elements?.pin && <OpenInFullIcon
                                          style={{
                                            cursor: "pointer",
                                            marginBottom: "5px",
                                          }}
                                          onClick={() => openCard(elements)}
                                        ></OpenInFullIcon>}
                                        {/* pin icon */}
                                        {elements?.pin && <PushPinIcon
                                          style={{
                                            cursor: "pointer",
                                            marginBottom: "5px",
                                            color: elements?.position === "pinned" ? "black" : "lightgrey"
                                          }}
                                          onClick={async () => await pushPinCard(elements, idx)}
                                        ></PushPinIcon>}
                                      </div>
                                      <div
                                        style={{
                                          border: "solid 2px black",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {parentID != "Get Involved" &&
                                          e.title !=
                                          "Training Materials (Login Required)" && (
                                            <CardMedia
                                              component="img"
                                              height="194"
                                              image={elements.minImage}
                                              alt="Please upload image"
                                              sx={{ maxHeight: "100px" }}
                                            />
                                          )}
                                      </div>
                                      {parentID === "Get Involved" &&
                                        elements.cardContent &&
                                        elements.cardContent[1] && (
                                          <div style={{ marginTop: "20px" }}>
                                            {elements.cardContent[1].content}
                                          </div>
                                        )}
                                      {e.title === "Details of Past Events" &&
                                        elements.cardContent[1].type ===
                                        "wordEditor" &&
                                        elements.cardContent[1].content !=
                                        "" && (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                elements.cardContent[1].content,
                                            }}
                                          ></div>
                                        )}
                                      {e.title ===
                                        "Training Materials (Login Required)" && (
                                          <>
                                            <div
                                              style={{
                                                border: "solid 2px black",
                                                marginTop: "10px",
                                              }}
                                            >
                                              <CardMedia
                                                component="img"
                                                height="194"
                                                image={
                                                  elements.cardContent[0]
                                                    .treeContents[4].url
                                                }
                                                alt="Please upload image"
                                                sx={{ maxHeight: "100px" }}
                                              />
                                            </div>
                                            <div style={{ marginTop: "20px" }}>
                                              {
                                                elements.cardContent[0]
                                                  .treeContents[0].content
                                              }
                                            </div>
                                          </>
                                        )}
                                      <div style={{ marginTop: "20px", maxHeight: "6.4rem", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: "3", WebkitBoxOrient: "vertical" }}>
                                        {elements.minTitle}
                                      </div>
                                      {elements?.pin && <div className="landing-smart-box" style={{ marginTop: "7px" }}>
                                        <p className="landing-para-little">
                                          Date: {elements?.dateForPinSection && elements?.dateForPinSection != "" ? dateIntoEngFormatting(elements?.dateForPinSection) : "N/A"}
                                        </p>
                                      </div>}
                                    </CardContent>
                                  </Card>

                                  {!elements?.pin && <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-evenly",
                                      marginTop: "10px",
                                    }}
                                  >

                                    <WestIcon
                                      onClick={() =>
                                        switchCardPriority(
                                          "up",
                                          elements,
                                          idx,
                                          index
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    />

                                    <EastIcon
                                      onClick={() =>
                                        switchCardPriority(
                                          "down",
                                          elements,
                                          idx,
                                          index
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>}

                                  {(elements?.pin && elements?.position === 'pinned') && <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-evenly",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <WestIcon
                                      onClick={() =>
                                        switchPinCardPriority(
                                          "up",
                                          elements,
                                          idx
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    />

                                    <EastIcon
                                      onClick={() =>
                                        switchPinCardPriority(
                                          "down",
                                          elements,
                                          idx
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>}


                                </div>
                              )}

                              {elements.expand && (
                                <div style={{ marginRight: "10px" }}>
                                  <Card sx={{ width: "73vw" }}>
                                    <div
                                      style={{
                                        // minWidth: 900,
                                        // maxWidth: 900,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: 10,
                                      }}
                                    >
                                      <CloseFullscreenIcon
                                        style={{
                                          marginLeft: "10px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => closeCard(elements)}
                                      />
                                      <CloseIcon
                                        style={{
                                          cursor: "pointer",
                                          marginRight: 10,
                                        }}
                                        onClick={() =>
                                          confirmDelete(elements, index)
                                        }
                                      />
                                    </div>
                                    <CardContent
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        // justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {role === "superAdmin" && (
                                          <Button
                                            onClick={() =>
                                              openCreateDialog(
                                                index,
                                                elements.category
                                              )
                                            }
                                            variant="contained"
                                            component="label"
                                            style={{ marginTop: "10px" }}
                                          >
                                            Select Content Type
                                          </Button>
                                        )}
                                      </div>
                                      {id === undefined &&
                                        e.title ===
                                        "Self-Learning Exercise" && (
                                          <h2>
                                            <b>
                                              * Photo is required for the
                                              display to appear
                                            </b>
                                          </h2>
                                        )}
                                      {id === "What Is ACT" &&
                                        e.title ===
                                        "Introduction To Acceptance And Commitment Therapy Session" && (
                                          <h2>
                                            <b>
                                              All information must be filled out
                                              for the display to appear
                                            </b>
                                          </h2>
                                        )}
                                      {id === "landing" &&
                                        e.title === "Session 5 - 序" && (
                                          <h2>
                                            <b>
                                              All information must be filled out
                                              for the display to appear includes
                                              icon and signature
                                            </b>
                                          </h2>
                                        )}
                                      {id === "What Is ACT" &&
                                        e.title ===
                                        "Current Evidence Session" && (
                                          <h2>
                                            <b>
                                              Only Sponsor and Year can be empty
                                              otherwise will not display
                                            </b>
                                          </h2>
                                        )}
                                      <br />
                                      {elements.cardContent &&
                                        elements.cardContent.map(
                                          (
                                            cardElement: any,
                                            cardIdx: number
                                          ) => {
                                            return (
                                              <div
                                                key={`$card${cardIdx}`}
                                                style={
                                                  {
                                                    // maxWidth: 800,
                                                    // minWidth: 600,
                                                    // display: "flex",
                                                    // flexDirection: "row",
                                                  }
                                                }
                                              >
                                                {cardElement.type === "pdf" && (
                                                  <>
                                                    <br />
                                                    <div
                                                      style={{
                                                        width: "65vw",
                                                        border:
                                                          "solid black 3px",
                                                        padding: "20px",
                                                        display: "flex",
                                                        flexDirection: "column",

                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      {cardElement.previewUrl !=
                                                        "" && (
                                                          <div
                                                            style={{
                                                              width: "610px",
                                                              maxHeight: "200px",
                                                              border:
                                                                "1px solid black",
                                                              display: "flex",
                                                              justifyContent:
                                                                "center",
                                                              padding: "5px",
                                                            }}
                                                          >
                                                            <embed
                                                              src={
                                                                cardElement.previewUrl
                                                              }
                                                              width="600"
                                                              height="150"
                                                              type="application/pdf"
                                                            ></embed>
                                                          </div>
                                                        )}
                                                      {cardElement.previewUrl ===
                                                        "" &&
                                                        cardElement.url != "" &&
                                                        cardElement.url !=
                                                        API.getPDF && (
                                                          <div
                                                            style={{
                                                              width: "610px",
                                                              height: "160px",
                                                              border:
                                                                "1px solid black",
                                                              display: "flex",
                                                              justifyContent:
                                                                "center",
                                                              padding: "5px",
                                                            }}
                                                          >
                                                            <embed
                                                              src={
                                                                cardElement.url
                                                              }
                                                              width="600"
                                                              height="150"
                                                              type="application/pdf"
                                                            ></embed>
                                                          </div>
                                                        )}
                                                      <br />
                                                      <div>
                                                        Maximum PDF Size : 10MB
                                                      </div>
                                                      <br />
                                                      {id === "Research" &&
                                                        e.title ===
                                                        "Research Main Content" && (
                                                          <>
                                                            <div>
                                                              Can empty and the
                                                              corresponding
                                                              components will be
                                                              hidden
                                                            </div>
                                                            <br />
                                                          </>
                                                        )}
                                                      {id === "Training" &&
                                                        (e.title ===
                                                          "Details of Upcoming Events" ||
                                                          e.title ===
                                                          "Details of Past Events") && (
                                                          <>
                                                            <div>
                                                              Can empty and the
                                                              corresponding
                                                              components will be
                                                              hidden
                                                            </div>
                                                            <br />
                                                          </>
                                                        )}
                                                      <div>
                                                        <Button
                                                          variant="contained"
                                                          component="label"
                                                        >
                                                          Upload PDF
                                                          <input
                                                            hidden
                                                            accept="application/pdf"
                                                            multiple
                                                            type="file"
                                                            onChange={handleFileSelect(
                                                              cardElement
                                                            )}
                                                          />
                                                        </Button>
                                                        <Button
                                                          variant="contained"
                                                          component="label"
                                                          onClick={() =>
                                                            doubleConfirmClearMedia(
                                                              cardElement
                                                            )
                                                          }
                                                          style={{
                                                            marginLeft: "10px",
                                                          }}
                                                        >
                                                          Clear PDF
                                                        </Button>
                                                      </div>
                                                    </div>
                                                    <br />
                                                  </>
                                                )}
                                                {cardElement.type ===
                                                  "treeView" && (
                                                    <>
                                                      <br />
                                                      <div
                                                        style={{
                                                          width: "71vw",
                                                        }}
                                                      >
                                                        <div
                                                          style={
                                                            {
                                                              // display: "flex",
                                                              // flexDirection: "row",
                                                            }
                                                          }
                                                        >
                                                          <TreeView
                                                            aria-label="file system navigator"
                                                            defaultCollapseIcon={
                                                              <ExpandMoreIcon />
                                                            }
                                                            defaultExpandIcon={
                                                              <ChevronRightIcon />
                                                            }
                                                            expanded={expanded}
                                                            selected={selected}
                                                            onNodeToggle={
                                                              handleToggle
                                                            }
                                                            onNodeSelect={
                                                              handleSelect
                                                            }
                                                            multiSelect
                                                            sx={{
                                                              marginBottom:
                                                                "10px",
                                                              marginLeft: "30px",
                                                              marginRight: "30px",
                                                            }}
                                                          >
                                                            {/* <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "flex-end",
                                                          marginRight: "10px",
                                                        }}
                                                      > */}

                                                            {/* </div> */}
                                                            <TreeItem
                                                              nodeId={
                                                                "cardTreeItem" +
                                                                idx.toString() +
                                                                index.toString() +
                                                                cardElement.category.toString()
                                                              }
                                                              label={
                                                                cardElement.title
                                                              }
                                                            >
                                                              <div
                                                                style={{
                                                                  display: "flex",
                                                                  justifyContent:
                                                                    "flex-end",
                                                                  marginTop:
                                                                    "5px",
                                                                }}
                                                              >
                                                                {moduleNumber &&
                                                                  sub ===
                                                                  "Reading Material" &&
                                                                  cardElement.type ===
                                                                  "treeView" && (
                                                                    <>
                                                                      <button
                                                                        onClick={() =>
                                                                          doubleConfirmDeletePops(
                                                                            index,
                                                                            idx,
                                                                            cardIdx
                                                                          )
                                                                        }
                                                                      >
                                                                        <CloseIcon />
                                                                      </button>
                                                                    </>
                                                                  )}
                                                              </div>
                                                              <div
                                                                style={{
                                                                  display: "flex",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                {role ===
                                                                  "superAdmin" && (
                                                                    <Button
                                                                      onClick={() =>
                                                                        openCardTreeDialog(
                                                                          index,
                                                                          cardElement.category,
                                                                          idx,
                                                                          cardIdx
                                                                        )
                                                                      }
                                                                      variant="contained"
                                                                      component="label"
                                                                      style={{
                                                                        marginTop:
                                                                          "10px",
                                                                      }}
                                                                    >
                                                                      Select Content
                                                                      Type
                                                                    </Button>
                                                                  )}
                                                              </div>
                                                              <br />

                                                              {id === undefined &&
                                                                e.title ===
                                                                "Reading Material" && (
                                                                  <h2>
                                                                    <b>
                                                                      All
                                                                      information
                                                                      must be
                                                                      filled out
                                                                      for the
                                                                      display to
                                                                      appear
                                                                    </b>
                                                                  </h2>
                                                                )}
                                                              {id ===
                                                                "Training" &&
                                                                (e.title ===
                                                                  "Details of Upcoming Events" ||
                                                                  e.title ===
                                                                  "Details of Past Events") &&
                                                                cardElement.title ===
                                                                "More Info Session" && (
                                                                  <h2>
                                                                    <b>
                                                                      At least one
                                                                      of the *
                                                                      fields must
                                                                      be filled
                                                                      out for the
                                                                      Read More
                                                                      section
                                                                      display
                                                                    </b>
                                                                  </h2>
                                                                )}
                                                              {cardElement.treeContents.map(
                                                                (
                                                                  cardTreeElement: any,
                                                                  cardTreeItemIDX: number
                                                                ) => {
                                                                  return (
                                                                    <div
                                                                      key={`cardTreeItem+${cardTreeItemIDX}`}
                                                                    >
                                                                      {cardTreeElement.type ===
                                                                        "text" && (
                                                                          <>
                                                                            <br />
                                                                            <TextField
                                                                              autoFocus
                                                                              margin="dense"
                                                                              id={
                                                                                cardTreeElement.content
                                                                              }
                                                                              label={
                                                                                cardTreeElement.title
                                                                              }
                                                                              type="text"
                                                                              name="content"
                                                                              fullWidth
                                                                              variant="standard"
                                                                              value={
                                                                                cardTreeElement.content
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) =>
                                                                                modifyTreeContent(
                                                                                  cardTreeElement.id,
                                                                                  idx,
                                                                                  cardTreeElement.type,
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                                  index,
                                                                                  cardTreeItemIDX,
                                                                                  cardIdx
                                                                                )
                                                                              }
                                                                            />
                                                                            {id ===
                                                                              "Publicity" &&
                                                                              (e.title ===
                                                                                "What's new?" ||
                                                                                e.title ===
                                                                                "Broadcast Interview" ||
                                                                                e.title ===
                                                                                "Press Releases") &&
                                                                              (cardTreeElement.title ===
                                                                                "Sub Title 1 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Sub Title 2 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Sub Title 3 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Sub Title 4 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Sub Title 5 (En)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    35
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}{" "}
                                                                            {id ===
                                                                              "Publicity" &&
                                                                              (e.title ===
                                                                                "What's new?" ||
                                                                                e.title ===
                                                                                "Broadcast Interview" ||
                                                                                e.title ===
                                                                                "Press Releases") &&
                                                                              (cardTreeElement.title ===
                                                                                "Sub Title 1 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Sub Title 2 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Sub Title 3 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Sub Title 4 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Sub Title 5 (繁)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      35 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              cardTreeElement.title ===
                                                                              "Aims (En)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    1000
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Do
                                                                                    not
                                                                                    leave
                                                                                    this
                                                                                    field
                                                                                    empty
                                                                                    to
                                                                                    avoid
                                                                                    display
                                                                                    empty
                                                                                    space
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              cardTreeElement.title ===
                                                                              "Aims (繁)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      1000 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Do
                                                                                    not
                                                                                    leave
                                                                                    this
                                                                                    field
                                                                                    empty
                                                                                    to
                                                                                    avoid
                                                                                    display
                                                                                    empty
                                                                                    space
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              cardTreeElement.title ===
                                                                              "Project Period" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    {" "}
                                                                                    Format
                                                                                    :
                                                                                    Start
                                                                                    Year
                                                                                    -
                                                                                    End
                                                                                    Year
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Do
                                                                                    not
                                                                                    leave
                                                                                    this
                                                                                    field
                                                                                    empty
                                                                                    to
                                                                                    avoid
                                                                                    display
                                                                                    empty
                                                                                    space
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              (cardTreeElement.title ===
                                                                                "Participants (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Collaborators (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Funder (En)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    150
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Do
                                                                                    not
                                                                                    leave
                                                                                    this
                                                                                    field
                                                                                    empty
                                                                                    to
                                                                                    avoid
                                                                                    display
                                                                                    empty
                                                                                    space
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              (cardTreeElement.title ===
                                                                                "Participants (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Collaborators (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Funder (繁)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      150 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Do
                                                                                    not
                                                                                    leave
                                                                                    this
                                                                                    field
                                                                                    empty
                                                                                    to
                                                                                    avoid
                                                                                    display
                                                                                    empty
                                                                                    space
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              cardTreeElement.title ===
                                                                              "Project Title and Lead Investigator (繁)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      200 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Do
                                                                                    not
                                                                                    leave
                                                                                    this
                                                                                    field
                                                                                    empty
                                                                                    to
                                                                                    avoid
                                                                                    display
                                                                                    empty
                                                                                    space
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              cardTreeElement.title ===
                                                                              "Project Title and Lead Investigator (En)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    200
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Do
                                                                                    not
                                                                                    leave
                                                                                    this
                                                                                    field
                                                                                    empty
                                                                                    to
                                                                                    avoid
                                                                                    display
                                                                                    empty
                                                                                    space
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              (e.title ===
                                                                                "Details of Upcoming Events" ||
                                                                                e.title ===
                                                                                "Details of Past Events") &&
                                                                              cardElement.title ===
                                                                              "Training Sub Session" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              (e.title ===
                                                                                "Details of Upcoming Events" ||
                                                                                e.title ===
                                                                                "Details of Past Events") &&
                                                                              cardElement.title ===
                                                                              "More Info Session" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    *
                                                                                    *
                                                                                    *
                                                                                    *
                                                                                    *
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Publicity" &&
                                                                              (e.title ===
                                                                                "What's new?" ||
                                                                                e.title ===
                                                                                "Broadcast Interview" ||
                                                                                e.title ===
                                                                                "Press Releases") &&
                                                                              cardTreeElement.title ===
                                                                              "Title (繁)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      30 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Publicity" &&
                                                                              (e.title ===
                                                                                "What's new?" ||
                                                                                e.title ===
                                                                                "Broadcast Interview" ||
                                                                                e.title ===
                                                                                "Press Releases") &&
                                                                              cardTreeElement.title ===
                                                                              "Title (En)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    30
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Publicity" &&
                                                                              (e.title ===
                                                                                "What's new?" ||
                                                                                e.title ===
                                                                                "Broadcast Interview" ||
                                                                                e.title ===
                                                                                "Press Releases") &&
                                                                              (cardTreeElement.title ===
                                                                                "Caption 1 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Caption 2 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Caption 3 (En)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    80
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}{" "}
                                                                            {id ===
                                                                              "Publicity" &&
                                                                              (e.title ===
                                                                                "What's new?" ||
                                                                                e.title ===
                                                                                "Broadcast Interview" ||
                                                                                e.title ===
                                                                                "Press Releases") &&
                                                                              (cardTreeElement.title ===
                                                                                "Caption 1 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Caption 2 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Caption 3 (繁)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      80 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Publicity" &&
                                                                              (e.title ===
                                                                                "What's new?" ||
                                                                                e.title ===
                                                                                "Broadcast Interview" ||
                                                                                e.title ===
                                                                                "Press Releases") &&
                                                                              cardTreeElement.title ===
                                                                              "Caption (En)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    80
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Publicity" &&
                                                                              (e.title ===
                                                                                "What's new?" ||
                                                                                e.title ===
                                                                                "Broadcast Interview" ||
                                                                                e.title ===
                                                                                "Press Releases") &&
                                                                              cardTreeElement.title ===
                                                                              "Caption (繁)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      80 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              undefined &&
                                                                              e.title ===
                                                                              "Reading Material" &&
                                                                              cardTreeElement.title ===
                                                                              "Sub Title (En)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :
                                                                                    26
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              undefined &&
                                                                              e.title ===
                                                                              "Reading Material" &&
                                                                              cardTreeElement.title ===
                                                                              "Sub Title (繁)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :
                                                                                    {Math.ceil(
                                                                                      26 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              cardTreeElement.title ===
                                                                              "Project Name (En)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    160
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              cardTreeElement.title ===
                                                                              "Project Name (繁)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      160 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              e.title ===
                                                                              "Training Materials (Login Required)" &&
                                                                              cardTreeElement.title ===
                                                                              "Title (En)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    30
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Do
                                                                                    not
                                                                                    leave
                                                                                    this
                                                                                    field
                                                                                    empty
                                                                                    for
                                                                                    the
                                                                                    card
                                                                                    to
                                                                                    display
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              e.title ===
                                                                              "Training Materials (Login Required)" &&
                                                                              cardTreeElement.title ===
                                                                              "Title (繁)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      30 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Do
                                                                                    not
                                                                                    leave
                                                                                    this
                                                                                    field
                                                                                    empty
                                                                                    for
                                                                                    the
                                                                                    card
                                                                                    to
                                                                                    display
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              e.title ===
                                                                              "Training Materials (Login Required)" &&
                                                                              (cardTreeElement.title ===
                                                                                "iCaption (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "vCaption (En)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    80
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    !
                                                                                    Can
                                                                                    empty
                                                                                    but
                                                                                    not
                                                                                    recommended
                                                                                    {
                                                                                      " (Orange frame will remain if empty)"
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              e.title ===
                                                                              "Training Materials (Login Required)" &&
                                                                              (cardTreeElement.title ===
                                                                                "iCaption (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "vCaption (繁)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      80 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    !
                                                                                    Can
                                                                                    empty
                                                                                    but
                                                                                    not
                                                                                    recommended
                                                                                    {
                                                                                      " (Orange frame will remain if empty)"
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              cardTreeElement.title ===
                                                                              "Duration" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Format
                                                                                    :
                                                                                    Start
                                                                                    Year
                                                                                    -
                                                                                    End
                                                                                    Year
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              (cardTreeElement.title ===
                                                                                "Partner Organization (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Funding (En)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    150
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              (cardTreeElement.title ===
                                                                                "Partner Organization (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Funding (繁)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      150 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              (cardTreeElement.title ===
                                                                                "Main Title (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Title 1 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Title 2 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Title 3 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Title 4 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Title 5 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Sub Title (En)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    30
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              (cardTreeElement.title ===
                                                                                "Main Title (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Title 1 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Title 2 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Title 3 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Title 4 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Title 5 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Sub Title (繁)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      30 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {
                                                                                      cardTreeElement
                                                                                        .content
                                                                                        .length
                                                                                    }
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            <br />
                                                                            {role ===
                                                                              "superAdmin" && (
                                                                                <>
                                                                                  <br />
                                                                                  <Button
                                                                                    variant="contained"
                                                                                    component="label"
                                                                                    onClick={() =>
                                                                                      changeWordEditorToText(
                                                                                        cardTreeElement
                                                                                      )
                                                                                    }
                                                                                  >
                                                                                    Change
                                                                                    Type
                                                                                  </Button>
                                                                                </>
                                                                              )}
                                                                          </>
                                                                        )}
                                                                      {cardTreeElement.type ===
                                                                        "wordEditor" && (
                                                                          <>
                                                                            <br />

                                                                            <Typography
                                                                              sx={{
                                                                                fontSize: 14,
                                                                                marginTop:
                                                                                  "20px",
                                                                              }}
                                                                              color="black"
                                                                              gutterBottom
                                                                            >
                                                                              {
                                                                                cardTreeElement.title
                                                                              }
                                                                            </Typography>
                                                                            <EditorDraft
                                                                              onConvertToHtml={setRawHTML(
                                                                                cardTreeItemIDX,
                                                                                cardTreeElement
                                                                              )}
                                                                              htmlRawValue={
                                                                                cardTreeElement.content
                                                                              }
                                                                            />

                                                                            {id ===
                                                                              "Publicity" &&
                                                                              (e.title ===
                                                                                "What's new?" ||
                                                                                e.title ===
                                                                                "Broadcast Interview" ||
                                                                                e.title ===
                                                                                "Press Releases") &&
                                                                              (cardTreeElement.title ===
                                                                                "Content 1 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 2 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 3 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 4 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 5 (En)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    800
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {cardTreeElement.content
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .replace(
                                                                                        /&amp;/g,
                                                                                        "&"
                                                                                      )
                                                                                      .replace(
                                                                                        /<[^>]*>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&lt;/g,
                                                                                        "<"
                                                                                      )
                                                                                      .replace(
                                                                                        /&gt;/g,
                                                                                        ">"
                                                                                      )
                                                                                      .length -
                                                                                      1 ===
                                                                                      -1
                                                                                      ? 0
                                                                                      : cardTreeElement.content
                                                                                        .replace(
                                                                                          /&nbsp;/g,
                                                                                          " "
                                                                                        )
                                                                                        .replace(
                                                                                          /&amp;/g,
                                                                                          "&"
                                                                                        )
                                                                                        .replace(
                                                                                          /<[^>]*>/g,
                                                                                          ""
                                                                                        )
                                                                                        .replace(
                                                                                          /&lt;/g,
                                                                                          "<"
                                                                                        )
                                                                                        .replace(
                                                                                          /&gt;/g,
                                                                                          ">"
                                                                                        )
                                                                                        .length -
                                                                                      1}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Publicity" &&
                                                                              (e.title ===
                                                                                "What's new?" ||
                                                                                e.title ===
                                                                                "Broadcast Interview" ||
                                                                                e.title ===
                                                                                "Press Releases") &&
                                                                              (cardTreeElement.title ===
                                                                                "Content 1 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 2 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 3 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 4 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 5 (繁)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      800 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {cardTreeElement.content
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .replace(
                                                                                        /&amp;/g,
                                                                                        "&"
                                                                                      )
                                                                                      .replace(
                                                                                        /<[^>]*>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&lt;/g,
                                                                                        "<"
                                                                                      )
                                                                                      .replace(
                                                                                        /&gt;/g,
                                                                                        ">"
                                                                                      )
                                                                                      .length -
                                                                                      1 ===
                                                                                      -1
                                                                                      ? 0
                                                                                      : cardTreeElement.content
                                                                                        .replace(
                                                                                          /&nbsp;/g,
                                                                                          " "
                                                                                        )
                                                                                        .replace(
                                                                                          /&amp;/g,
                                                                                          "&"
                                                                                        )
                                                                                        .replace(
                                                                                          /<[^>]*>/g,
                                                                                          ""
                                                                                        )
                                                                                        .replace(
                                                                                          /&lt;/g,
                                                                                          "<"
                                                                                        )
                                                                                        .replace(
                                                                                          /&gt;/g,
                                                                                          ">"
                                                                                        )
                                                                                        .length -
                                                                                      1}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              e.title ===
                                                                              "Training Materials (Login Required)" &&
                                                                              cardTreeElement.title ===
                                                                              "Content 1 (En)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    50
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {cardTreeElement.content
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .replace(
                                                                                        /&amp;/g,
                                                                                        "&"
                                                                                      )
                                                                                      .replace(
                                                                                        /<[^>]*>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&lt;/g,
                                                                                        "<"
                                                                                      )
                                                                                      .replace(
                                                                                        /&gt;/g,
                                                                                        ">"
                                                                                      )
                                                                                      .length -
                                                                                      1 ===
                                                                                      -1
                                                                                      ? 0
                                                                                      : cardTreeElement.content
                                                                                        .replace(
                                                                                          /&nbsp;/g,
                                                                                          " "
                                                                                        )
                                                                                        .replace(
                                                                                          /&amp;/g,
                                                                                          "&"
                                                                                        )
                                                                                        .replace(
                                                                                          /<[^>]*>/g,
                                                                                          ""
                                                                                        )
                                                                                        .replace(
                                                                                          /&lt;/g,
                                                                                          "<"
                                                                                        )
                                                                                        .replace(
                                                                                          /&gt;/g,
                                                                                          ">"
                                                                                        )
                                                                                        .length -
                                                                                      1}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    {" "}
                                                                                    !
                                                                                    Can
                                                                                    empty
                                                                                    but
                                                                                    not
                                                                                    recommended
                                                                                    {
                                                                                      " (Orange frame will remain if empty)"
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              e.title ===
                                                                              "Training Materials (Login Required)" &&
                                                                              cardTreeElement.title ===
                                                                              "Content 1 (繁)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      50 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {cardTreeElement.content
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .replace(
                                                                                        /&amp;/g,
                                                                                        "&"
                                                                                      )
                                                                                      .replace(
                                                                                        /<[^>]*>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&lt;/g,
                                                                                        "<"
                                                                                      )
                                                                                      .replace(
                                                                                        /&gt;/g,
                                                                                        ">"
                                                                                      )
                                                                                      .length -
                                                                                      1 ===
                                                                                      -1
                                                                                      ? 0
                                                                                      : cardTreeElement.content
                                                                                        .replace(
                                                                                          /&nbsp;/g,
                                                                                          " "
                                                                                        )
                                                                                        .replace(
                                                                                          /&amp;/g,
                                                                                          "&"
                                                                                        )
                                                                                        .replace(
                                                                                          /<[^>]*>/g,
                                                                                          ""
                                                                                        )
                                                                                        .replace(
                                                                                          /&lt;/g,
                                                                                          "<"
                                                                                        )
                                                                                        .replace(
                                                                                          /&gt;/g,
                                                                                          ">"
                                                                                        )
                                                                                        .length -
                                                                                      1}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    {" "}
                                                                                    !
                                                                                    Can
                                                                                    empty
                                                                                    but
                                                                                    not
                                                                                    recommended
                                                                                    {
                                                                                      " (Orange frame will remain if empty)"
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              e.title ===
                                                                              "Training Materials (Login Required)" &&
                                                                              (cardTreeElement.title ===
                                                                                "Content (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 2 (En)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    300
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {cardTreeElement.content
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .replace(
                                                                                        /&amp;/g,
                                                                                        "&"
                                                                                      )
                                                                                      .replace(
                                                                                        /<[^>]*>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&lt;/g,
                                                                                        "<"
                                                                                      )
                                                                                      .replace(
                                                                                        /&gt;/g,
                                                                                        ">"
                                                                                      )
                                                                                      .length -
                                                                                      1 ===
                                                                                      -1
                                                                                      ? 0
                                                                                      : cardTreeElement.content
                                                                                        .replace(
                                                                                          /&nbsp;/g,
                                                                                          " "
                                                                                        )
                                                                                        .replace(
                                                                                          /&amp;/g,
                                                                                          "&"
                                                                                        )
                                                                                        .replace(
                                                                                          /<[^>]*>/g,
                                                                                          ""
                                                                                        )
                                                                                        .replace(
                                                                                          /&lt;/g,
                                                                                          "<"
                                                                                        )
                                                                                        .replace(
                                                                                          /&gt;/g,
                                                                                          ">"
                                                                                        )
                                                                                        .length -
                                                                                      1}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    !
                                                                                    Can
                                                                                    empty
                                                                                    but
                                                                                    not
                                                                                    recommended
                                                                                    {
                                                                                      " (Orange frame will remain if empty)"
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              e.title ===
                                                                              "Training Materials (Login Required)" &&
                                                                              (cardTreeElement.title ===
                                                                                "Content (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 2 (繁)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {Math.ceil(
                                                                                      300 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {cardTreeElement.content
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .replace(
                                                                                        /&amp;/g,
                                                                                        "&"
                                                                                      )
                                                                                      .replace(
                                                                                        /<[^>]*>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&lt;/g,
                                                                                        "<"
                                                                                      )
                                                                                      .replace(
                                                                                        /&gt;/g,
                                                                                        ">"
                                                                                      )
                                                                                      .length -
                                                                                      1 ===
                                                                                      -1
                                                                                      ? 0
                                                                                      : cardTreeElement.content
                                                                                        .replace(
                                                                                          /&nbsp;/g,
                                                                                          " "
                                                                                        )
                                                                                        .replace(
                                                                                          /&amp;/g,
                                                                                          "&"
                                                                                        )
                                                                                        .replace(
                                                                                          /<[^>]*>/g,
                                                                                          ""
                                                                                        )
                                                                                        .replace(
                                                                                          /&lt;/g,
                                                                                          "<"
                                                                                        )
                                                                                        .replace(
                                                                                          /&gt;/g,
                                                                                          ">"
                                                                                        )
                                                                                        .length -
                                                                                      1}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    !
                                                                                    Can
                                                                                    empty
                                                                                    but
                                                                                    not
                                                                                    recommended
                                                                                    {
                                                                                      " (Orange frame will remain if empty)"
                                                                                    }
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              (e.title ===
                                                                                "Details of Upcoming Events" ||
                                                                                e.title ===
                                                                                "Details of Past Events") &&
                                                                              cardTreeElement.title ===
                                                                              "Description (En)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    *
                                                                                    *
                                                                                    *
                                                                                    *
                                                                                    *
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :
                                                                                    120
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {cardTreeElement.content
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .replace(
                                                                                        /&amp;/g,
                                                                                        "&"
                                                                                      )
                                                                                      .replace(
                                                                                        /<[^>]*>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&lt;/g,
                                                                                        "<"
                                                                                      )
                                                                                      .replace(
                                                                                        /&gt;/g,
                                                                                        ">"
                                                                                      )
                                                                                      .length -
                                                                                      1 ===
                                                                                      -1
                                                                                      ? 0
                                                                                      : cardTreeElement.content
                                                                                        .replace(
                                                                                          /&nbsp;/g,
                                                                                          " "
                                                                                        )
                                                                                        .replace(
                                                                                          /&amp;/g,
                                                                                          "&"
                                                                                        )
                                                                                        .replace(
                                                                                          /<[^>]*>/g,
                                                                                          ""
                                                                                        )
                                                                                        .replace(
                                                                                          /&lt;/g,
                                                                                          "<"
                                                                                        )
                                                                                        .replace(
                                                                                          /&gt;/g,
                                                                                          ">"
                                                                                        )
                                                                                        .length -
                                                                                      1}
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              (e.title ===
                                                                                "Details of Upcoming Events" ||
                                                                                e.title ===
                                                                                "Details of Past Events") &&
                                                                              cardTreeElement.title ===
                                                                              "Description (繁)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    *
                                                                                    *
                                                                                    *
                                                                                    *
                                                                                    *
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :
                                                                                    {Math.ceil(
                                                                                      120 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {cardTreeElement.content
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .replace(
                                                                                        /&amp;/g,
                                                                                        "&"
                                                                                      )
                                                                                      .replace(
                                                                                        /<[^>]*>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&lt;/g,
                                                                                        "<"
                                                                                      )
                                                                                      .replace(
                                                                                        /&gt;/g,
                                                                                        ">"
                                                                                      )
                                                                                      .length -
                                                                                      1 ===
                                                                                      -1
                                                                                      ? 0
                                                                                      : cardTreeElement.content
                                                                                        .replace(
                                                                                          /&nbsp;/g,
                                                                                          " "
                                                                                        )
                                                                                        .replace(
                                                                                          /&amp;/g,
                                                                                          "&"
                                                                                        )
                                                                                        .replace(
                                                                                          /<[^>]*>/g,
                                                                                          ""
                                                                                        )
                                                                                        .replace(
                                                                                          /&lt;/g,
                                                                                          "<"
                                                                                        )
                                                                                        .replace(
                                                                                          /&gt;/g,
                                                                                          ">"
                                                                                        )
                                                                                        .length -
                                                                                      1}
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              (e.title ===
                                                                                "Details of Upcoming Events" ||
                                                                                e.title ===
                                                                                "Details of Past Events") &&
                                                                              cardTreeElement.title ===
                                                                              "Paragraph (En)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :
                                                                                    400
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {cardTreeElement.content
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .replace(
                                                                                        /&amp;/g,
                                                                                        "&"
                                                                                      )
                                                                                      .replace(
                                                                                        /<[^>]*>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&lt;/g,
                                                                                        "<"
                                                                                      )
                                                                                      .replace(
                                                                                        /&gt;/g,
                                                                                        ">"
                                                                                      )
                                                                                      .length -
                                                                                      1 ===
                                                                                      -1
                                                                                      ? 0
                                                                                      : cardTreeElement.content
                                                                                        .replace(
                                                                                          /&nbsp;/g,
                                                                                          " "
                                                                                        )
                                                                                        .replace(
                                                                                          /&amp;/g,
                                                                                          "&"
                                                                                        )
                                                                                        .replace(
                                                                                          /<[^>]*>/g,
                                                                                          ""
                                                                                        )
                                                                                        .replace(
                                                                                          /&lt;/g,
                                                                                          "<"
                                                                                        )
                                                                                        .replace(
                                                                                          /&gt;/g,
                                                                                          ">"
                                                                                        )
                                                                                        .length -
                                                                                      1}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Training" &&
                                                                              (e.title ===
                                                                                "Details of Upcoming Events" ||
                                                                                e.title ===
                                                                                "Details of Past Events") &&
                                                                              cardTreeElement.title ===
                                                                              "Paragraph (繁)" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :
                                                                                    {Math.ceil(
                                                                                      400 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {cardTreeElement.content
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .replace(
                                                                                        /&amp;/g,
                                                                                        "&"
                                                                                      )
                                                                                      .replace(
                                                                                        /<[^>]*>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&lt;/g,
                                                                                        "<"
                                                                                      )
                                                                                      .replace(
                                                                                        /&gt;/g,
                                                                                        ">"
                                                                                      )
                                                                                      .length -
                                                                                      1 ===
                                                                                      -1
                                                                                      ? 0
                                                                                      : cardTreeElement.content
                                                                                        .replace(
                                                                                          /&nbsp;/g,
                                                                                          " "
                                                                                        )
                                                                                        .replace(
                                                                                          /&amp;/g,
                                                                                          "&"
                                                                                        )
                                                                                        .replace(
                                                                                          /<[^>]*>/g,
                                                                                          ""
                                                                                        )
                                                                                        .replace(
                                                                                          /&lt;/g,
                                                                                          "<"
                                                                                        )
                                                                                        .replace(
                                                                                          /&gt;/g,
                                                                                          ">"
                                                                                        )
                                                                                        .length -
                                                                                      1}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              undefined &&
                                                                              e.title ===
                                                                              "Reading Material" &&
                                                                              cardTreeElement.title ===
                                                                              "Content" && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :
                                                                                    100
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {cardTreeElement.content
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .replace(
                                                                                        /&amp;/g,
                                                                                        "&"
                                                                                      )
                                                                                      .replace(
                                                                                        /<[^>]*>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&lt;/g,
                                                                                        "<"
                                                                                      )
                                                                                      .replace(
                                                                                        /&gt;/g,
                                                                                        ">"
                                                                                      )
                                                                                      .length -
                                                                                      1 ===
                                                                                      -1
                                                                                      ? 0
                                                                                      : cardTreeElement.content
                                                                                        .replace(
                                                                                          /&nbsp;/g,
                                                                                          " "
                                                                                        )
                                                                                        .replace(
                                                                                          /&amp;/g,
                                                                                          "&"
                                                                                        )
                                                                                        .replace(
                                                                                          /<[^>]*>/g,
                                                                                          ""
                                                                                        )
                                                                                        .replace(
                                                                                          /&lt;/g,
                                                                                          "<"
                                                                                        )
                                                                                        .replace(
                                                                                          /&gt;/g,
                                                                                          ">"
                                                                                        )
                                                                                        .length -
                                                                                      1}
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              (cardTreeElement.title ===
                                                                                "Content 1 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 1 (en)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 2 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 3 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 3 (en)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 4 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 5 (En)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content (En)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :
                                                                                    750
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {cardTreeElement.content
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .replace(
                                                                                        /&amp;/g,
                                                                                        "&"
                                                                                      )
                                                                                      .replace(
                                                                                        /<[^>]*>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&lt;/g,
                                                                                        "<"
                                                                                      )
                                                                                      .replace(
                                                                                        /&gt;/g,
                                                                                        ">"
                                                                                      )
                                                                                      .length -
                                                                                      1 ===
                                                                                      -1
                                                                                      ? 0
                                                                                      : cardTreeElement.content
                                                                                        .replace(
                                                                                          /&nbsp;/g,
                                                                                          " "
                                                                                        )
                                                                                        .replace(
                                                                                          /&amp;/g,
                                                                                          "&"
                                                                                        )
                                                                                        .replace(
                                                                                          /<[^>]*>/g,
                                                                                          ""
                                                                                        )
                                                                                        .replace(
                                                                                          /&lt;/g,
                                                                                          "<"
                                                                                        )
                                                                                        .replace(
                                                                                          /&gt;/g,
                                                                                          ">"
                                                                                        )
                                                                                        .length -
                                                                                      1}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            {id ===
                                                                              "Research" &&
                                                                              e.title ===
                                                                              "Research Main Content" &&
                                                                              (cardTreeElement.title ===
                                                                                "Content 1 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 2 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 3 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 4 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content 5 (繁)" ||
                                                                                cardTreeElement.title ===
                                                                                "Content (繁)") && (
                                                                                <div
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "15px",
                                                                                    marginLeft:
                                                                                      "10px",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Recommend
                                                                                    maximum
                                                                                    word
                                                                                    count
                                                                                    :
                                                                                    {Math.ceil(
                                                                                      750 /
                                                                                      2.18
                                                                                    )}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      marginBottom:
                                                                                        "5px",
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Current
                                                                                    word
                                                                                    count
                                                                                    :{" "}
                                                                                    {cardTreeElement.content
                                                                                      .replace(
                                                                                        /&nbsp;/g,
                                                                                        " "
                                                                                      )
                                                                                      .replace(
                                                                                        /&amp;/g,
                                                                                        "&"
                                                                                      )
                                                                                      .replace(
                                                                                        /<[^>]*>/g,
                                                                                        ""
                                                                                      )
                                                                                      .replace(
                                                                                        /&lt;/g,
                                                                                        "<"
                                                                                      )
                                                                                      .replace(
                                                                                        /&gt;/g,
                                                                                        ">"
                                                                                      )
                                                                                      .length -
                                                                                      1 ===
                                                                                      -1
                                                                                      ? 0
                                                                                      : cardTreeElement.content
                                                                                        .replace(
                                                                                          /&nbsp;/g,
                                                                                          " "
                                                                                        )
                                                                                        .replace(
                                                                                          /&amp;/g,
                                                                                          "&"
                                                                                        )
                                                                                        .replace(
                                                                                          /<[^>]*>/g,
                                                                                          ""
                                                                                        )
                                                                                        .replace(
                                                                                          /&lt;/g,
                                                                                          "<"
                                                                                        )
                                                                                        .replace(
                                                                                          /&gt;/g,
                                                                                          ">"
                                                                                        )
                                                                                        .length -
                                                                                      1}
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "blue",
                                                                                    }}
                                                                                  >
                                                                                    Can
                                                                                    empty
                                                                                    and
                                                                                    the
                                                                                    corresponding
                                                                                    components
                                                                                    will
                                                                                    be
                                                                                    hidden
                                                                                  </div>
                                                                                </div>
                                                                              )}
                                                                            <br />
                                                                            {role ===
                                                                              "superAdmin" && (
                                                                                <>
                                                                                  <br />
                                                                                  <Button
                                                                                    variant="contained"
                                                                                    component="label"
                                                                                    onClick={() =>
                                                                                      changeWordEditorToText(
                                                                                        cardTreeElement
                                                                                      )
                                                                                    }
                                                                                  >
                                                                                    Change
                                                                                    Type
                                                                                  </Button>
                                                                                </>
                                                                              )}
                                                                          </>
                                                                        )}
                                                                      {cardTreeElement.type ===
                                                                        "image" && (
                                                                          <>
                                                                            <br />
                                                                            <div
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                flexDirection:
                                                                                  "column",
                                                                                justifyContent:
                                                                                  "center",
                                                                                alignItems:
                                                                                  "center",
                                                                                border:
                                                                                  "solid black 3px",
                                                                                padding:
                                                                                  "20px",
                                                                              }}
                                                                            >
                                                                              <div
                                                                                style={{
                                                                                  display:
                                                                                    "flex",
                                                                                  justifyContent:
                                                                                    "center",
                                                                                  flexDirection:
                                                                                    "column",
                                                                                  alignItems:
                                                                                    "center",
                                                                                }}
                                                                              >
                                                                                {cardTreeElement.previewUrl !=
                                                                                  "" && (
                                                                                    <>
                                                                                      <div
                                                                                        style={{
                                                                                          border:
                                                                                            "solid 3px black",
                                                                                        }}
                                                                                      >
                                                                                        <CardMedia
                                                                                          component="img"
                                                                                          height="194"
                                                                                          image={
                                                                                            cardTreeElement.previewUrl
                                                                                          }
                                                                                          alt=" Please upload image"
                                                                                        />
                                                                                      </div>
                                                                                    </>
                                                                                  )}
                                                                                {cardTreeElement.previewUrl ===
                                                                                  "" &&
                                                                                  (cardTreeElement.url ===
                                                                                    "" ||
                                                                                    cardTreeElement.url ===
                                                                                    API.getImage) && (
                                                                                    <>
                                                                                      <div
                                                                                        style={{
                                                                                          height:
                                                                                            "400px",
                                                                                          maxHeight:
                                                                                            "400px",
                                                                                          width:
                                                                                            "500px",
                                                                                          maxWidth:
                                                                                            "500px",
                                                                                          border:
                                                                                            "solid 3px black",
                                                                                          display:
                                                                                            "flex",
                                                                                          justifyContent:
                                                                                            "center",
                                                                                          alignItems:
                                                                                            "center",
                                                                                        }}
                                                                                      >
                                                                                        <div>
                                                                                          <CardMedia
                                                                                            component="img"
                                                                                            height="194"
                                                                                            image={
                                                                                              ""
                                                                                            }
                                                                                            alt="Please upload image"
                                                                                          />
                                                                                        </div>
                                                                                      </div>
                                                                                    </>
                                                                                  )}
                                                                                {cardTreeElement.previewUrl ===
                                                                                  "" &&
                                                                                  cardTreeElement.url !=
                                                                                  "" &&
                                                                                  cardTreeElement.url !=
                                                                                  API.getImage && (
                                                                                    <>
                                                                                      <div
                                                                                        style={{
                                                                                          border:
                                                                                            "solid 3px black",
                                                                                        }}
                                                                                      >
                                                                                        <CardMedia
                                                                                          component="img"
                                                                                          height="194"
                                                                                          image={
                                                                                            cardTreeElement.url
                                                                                          }
                                                                                          alt="Please upload image"
                                                                                        />
                                                                                      </div>
                                                                                    </>
                                                                                  )}

                                                                                <br />
                                                                                <div>
                                                                                  Maximum
                                                                                  Image
                                                                                  Size
                                                                                  :
                                                                                  10MB
                                                                                </div>
                                                                                <br />
                                                                                <div>
                                                                                  Suggested
                                                                                  Resolution
                                                                                  :
                                                                                  1500
                                                                                  x
                                                                                  1000
                                                                                </div>
                                                                                {id ===
                                                                                  "Publicity" &&
                                                                                  (e.title ===
                                                                                    "What's new?" ||
                                                                                    e.title ===
                                                                                    "Broadcast Interview" ||
                                                                                    e.title ===
                                                                                    "Press Releases") && (
                                                                                    <>
                                                                                      <br />
                                                                                      <div>
                                                                                        Suggested
                                                                                        Ratio
                                                                                        :
                                                                                        3:2
                                                                                      </div>
                                                                                      <br />
                                                                                      <div>
                                                                                        Can
                                                                                        empty
                                                                                        and
                                                                                        the
                                                                                        corresponding
                                                                                        components
                                                                                        will
                                                                                        be
                                                                                        hidden
                                                                                      </div>
                                                                                    </>
                                                                                  )}
                                                                                {id ===
                                                                                  "Training" &&
                                                                                  (e.title ===
                                                                                    "Details of Upcoming Events" ||
                                                                                    e.title ===
                                                                                    "Details of Past Events") &&
                                                                                  cardElement.title ===
                                                                                  "Training Sub Session" && (
                                                                                    <div
                                                                                      style={{
                                                                                        marginTop:
                                                                                          "15px",
                                                                                        marginLeft:
                                                                                          "10px",
                                                                                      }}
                                                                                    >
                                                                                      {" "}
                                                                                      <div>
                                                                                        Can
                                                                                        empty
                                                                                        and
                                                                                        the
                                                                                        corresponding
                                                                                        components
                                                                                        will
                                                                                        be
                                                                                        hidden
                                                                                      </div>
                                                                                    </div>
                                                                                  )}
                                                                                <div
                                                                                  style={{
                                                                                    display:
                                                                                      "flex",
                                                                                    flexDirection:
                                                                                      "row",
                                                                                    justifyContent:
                                                                                      "center",
                                                                                    marginTop:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  <div>
                                                                                    <Button
                                                                                      variant="contained"
                                                                                      component="label"
                                                                                    >
                                                                                      Upload
                                                                                      Image
                                                                                      <input
                                                                                        hidden
                                                                                        accept="image/*"
                                                                                        multiple
                                                                                        type="file"
                                                                                        onChange={handleFileSelect(
                                                                                          cardTreeElement
                                                                                        )}
                                                                                      />
                                                                                    </Button>
                                                                                    <Button
                                                                                      variant="contained"
                                                                                      component="label"
                                                                                      onClick={() =>
                                                                                        doubleConfirmClearMedia(
                                                                                          cardTreeElement
                                                                                        )
                                                                                      }
                                                                                      style={{
                                                                                        marginLeft:
                                                                                          "10px",
                                                                                      }}
                                                                                    >
                                                                                      Clear
                                                                                      Image
                                                                                    </Button>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                              <br />
                                                                            </div>
                                                                          </>
                                                                        )}
                                                                      {cardTreeElement.type ===
                                                                        "pdf" && (
                                                                          <>
                                                                            <br />
                                                                            <div>
                                                                              <Button
                                                                                variant="contained"
                                                                                component="label"
                                                                              >
                                                                                Upload
                                                                                PDF
                                                                                <input
                                                                                  hidden
                                                                                  accept="application/pdf"
                                                                                  multiple
                                                                                  type="file"
                                                                                  onChange={handleFileSelect(
                                                                                    cardTreeElement
                                                                                  )}
                                                                                />
                                                                              </Button>
                                                                              <Button
                                                                                variant="contained"
                                                                                component="label"
                                                                                onClick={() =>
                                                                                  doubleConfirmClearMedia(
                                                                                    cardTreeElement
                                                                                  )
                                                                                }
                                                                                style={{
                                                                                  marginLeft:
                                                                                    "10px",
                                                                                }}
                                                                              >
                                                                                Clear
                                                                                PDF
                                                                              </Button>
                                                                            </div>
                                                                            <br />
                                                                          </>
                                                                        )}
                                                                      {cardTreeElement.type ===
                                                                        "audio" && (
                                                                          <>
                                                                            <br />
                                                                            <div
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                flexDirection:
                                                                                  "column",
                                                                                justifyContent:
                                                                                  "center",
                                                                                alignItems:
                                                                                  "center",
                                                                                border:
                                                                                  "solid black 3px",
                                                                                padding:
                                                                                  "20px",
                                                                              }}
                                                                            >
                                                                              <div
                                                                                style={{
                                                                                  // height:
                                                                                  //   "60px",
                                                                                  // maxHeight:
                                                                                  //   "60px",
                                                                                  width:
                                                                                    "500px",
                                                                                  // maxWidth:
                                                                                  //   "500px",

                                                                                  display:
                                                                                    "flex",
                                                                                  justifyContent:
                                                                                    "center",
                                                                                  alignItems:
                                                                                    "center",
                                                                                }}
                                                                              >
                                                                                {cardTreeElement.previewUrl !=
                                                                                  "" && (
                                                                                    <embed
                                                                                      src={
                                                                                        cardTreeElement.previewUrl
                                                                                      }
                                                                                    ></embed>
                                                                                  )}
                                                                                {(cardTreeElement.url ===
                                                                                  API.getAudio ||
                                                                                  cardTreeElement.url ===
                                                                                  "") &&
                                                                                  cardTreeElement.previewUrl ===
                                                                                  "" && (
                                                                                    <audio
                                                                                      controls
                                                                                    >
                                                                                      <source
                                                                                        src={
                                                                                          cardTreeElement.url
                                                                                        }
                                                                                        type="audio/mpeg"
                                                                                      ></source>
                                                                                    </audio>
                                                                                  )}
                                                                                {cardTreeElement.url !=
                                                                                  API.getAudio &&
                                                                                  cardTreeElement.url !=
                                                                                  "" &&
                                                                                  cardTreeElement.previewUrl ===
                                                                                  "" && (
                                                                                    <audio
                                                                                      controls
                                                                                    >
                                                                                      <source
                                                                                        src={
                                                                                          cardTreeElement.url
                                                                                        }
                                                                                        type="audio/mpeg"
                                                                                      ></source>
                                                                                    </audio>
                                                                                  )}
                                                                              </div>
                                                                              <br />
                                                                              <div>
                                                                                Maximum
                                                                                Audio
                                                                                Size
                                                                                :
                                                                                10MB
                                                                              </div>
                                                                              <br />
                                                                              <div
                                                                                style={{
                                                                                  display:
                                                                                    "flex",
                                                                                  flexDirection:
                                                                                    "row",
                                                                                  justifyContent:
                                                                                    "center",
                                                                                  marginTop:
                                                                                    "20px",
                                                                                }}
                                                                              >
                                                                                <div>
                                                                                  <Button
                                                                                    variant="contained"
                                                                                    component="label"
                                                                                  >
                                                                                    Upload
                                                                                    Audio
                                                                                    <input
                                                                                      hidden
                                                                                      accept="audio/*"
                                                                                      multiple
                                                                                      type="file"
                                                                                      onChange={handleFileSelect(
                                                                                        cardTreeElement
                                                                                      )}
                                                                                    />
                                                                                  </Button>
                                                                                  <Button
                                                                                    variant="contained"
                                                                                    component="label"
                                                                                    onClick={() =>
                                                                                      doubleConfirmClearMedia(
                                                                                        cardTreeElement
                                                                                      )
                                                                                    }
                                                                                    style={{
                                                                                      marginLeft:
                                                                                        "10px",
                                                                                    }}
                                                                                  >
                                                                                    Clear
                                                                                    Audio
                                                                                  </Button>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                            <br />
                                                                          </>
                                                                        )}
                                                                      {cardTreeElement.type ===
                                                                        "video" && (
                                                                          <>
                                                                            <br />
                                                                            <div
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                flexDirection:
                                                                                  "column",
                                                                                justifyContent:
                                                                                  "center",
                                                                                alignItems:
                                                                                  "center",
                                                                                border:
                                                                                  "solid black 3px",
                                                                                padding:
                                                                                  "20px",
                                                                              }}
                                                                            >
                                                                              <div
                                                                                style={{
                                                                                  display:
                                                                                    "flex",
                                                                                  flexDirection:
                                                                                    "column",
                                                                                  justifyContent:
                                                                                    "center",
                                                                                  alignItems:
                                                                                    "center",
                                                                                }}
                                                                              >
                                                                                <div
                                                                                  style={{
                                                                                    height:
                                                                                      "400px",
                                                                                    maxHeight:
                                                                                      "400px",
                                                                                    width:
                                                                                      "500px",
                                                                                    maxWidth:
                                                                                      "500px",
                                                                                    border:
                                                                                      "solid 3px black",
                                                                                    display:
                                                                                      "flex",
                                                                                    justifyContent:
                                                                                      "center",
                                                                                    alignItems:
                                                                                      "center",
                                                                                  }}
                                                                                >
                                                                                  <div
                                                                                    style={{
                                                                                      display:
                                                                                        "flex",
                                                                                      flexDirection:
                                                                                        "column",
                                                                                      justifyContent:
                                                                                        "center",
                                                                                      alignItems:
                                                                                        "center",
                                                                                    }}
                                                                                  >
                                                                                    <TextField
                                                                                      autoFocus
                                                                                      margin="dense"
                                                                                      id={
                                                                                        cardTreeElement.url +
                                                                                        idx
                                                                                      }
                                                                                      label={
                                                                                        cardTreeElement.title
                                                                                      }
                                                                                      type="text"
                                                                                      name="content"
                                                                                      variant="standard"
                                                                                      fullWidth
                                                                                      value={
                                                                                        cardTreeElement.previewUrl
                                                                                      }
                                                                                      onChange={(
                                                                                        e
                                                                                      ) =>
                                                                                        modifyTreeVideoContent(
                                                                                          cardTreeElement.id,
                                                                                          idx,
                                                                                          cardTreeElement.type,
                                                                                          e
                                                                                            .target
                                                                                            .value,
                                                                                          index,
                                                                                          cardTreeItemIDX,
                                                                                          cardIdx
                                                                                        )
                                                                                      }
                                                                                    />
                                                                                    <div
                                                                                      style={{
                                                                                        marginBottom:
                                                                                          "10px",
                                                                                      }}
                                                                                    >
                                                                                      Please
                                                                                      enter
                                                                                      youtube
                                                                                      link
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        marginTop:
                                                                                          "20px",
                                                                                      }}
                                                                                    >
                                                                                      <iframe
                                                                                        src={
                                                                                          cardTreeElement.url ||
                                                                                          ""
                                                                                        }
                                                                                        allow="autoplay; fullscreen"
                                                                                      ></iframe>
                                                                                    </div>
                                                                                  </div>
                                                                                  {/* {(cardTreeElement.url ===
                                                                                "" ||
                                                                                cardTreeElement.url ===
                                                                                  API.getVideo) &&
                                                                                cardTreeElement.previewUrl ===
                                                                                  "" && (
                                                                                  <video
                                                                                    controls
                                                                                  >
                                                                                    <source
                                                                                      src={
                                                                                        cardTreeElement.url
                                                                                      }
                                                                                      type="video/mp4"
                                                                                    ></source>
                                                                                    Your
                                                                                    browser
                                                                                    does
                                                                                    not
                                                                                    support
                                                                                    the
                                                                                    video
                                                                                    tag.
                                                                                  </video>
                                                                                )}
                                                                              {cardTreeElement.previewUrl !=
                                                                                "" && (
                                                                                <video
                                                                                  controls
                                                                                >
                                                                                  <source
                                                                                    src={
                                                                                      cardTreeElement.previewUrl
                                                                                    }
                                                                                    type="video/mp4"
                                                                                  ></source>
                                                                                  Your
                                                                                  browser
                                                                                  does
                                                                                  not
                                                                                  support
                                                                                  the
                                                                                  video
                                                                                  tag.
                                                                                </video>
                                                                              )}
                                                                              {cardTreeElement.url !=
                                                                                "" &&
                                                                                cardTreeElement.url !=
                                                                                  API.getVideo &&
                                                                                cardTreeElement.previewUrl ===
                                                                                  "" && (
                                                                                  <video
                                                                                    controls
                                                                                  >
                                                                                    <source
                                                                                      src={
                                                                                        cardTreeElement.url
                                                                                      }
                                                                                      type="video/mp4"
                                                                                    ></source>
                                                                                    Your
                                                                                    browser
                                                                                    does
                                                                                    not
                                                                                    support
                                                                                    the
                                                                                    video
                                                                                    tag.
                                                                                  </video>
                                                                                )} */}
                                                                                </div>
                                                                              </div>
                                                                              <br />
                                                                              {/* <div>
                                                                            Maximum
                                                                            Video
                                                                            Size
                                                                            :
                                                                            10MB
                                                                          </div>
                                                                          <br /> */}
                                                                              <div
                                                                                style={{
                                                                                  display:
                                                                                    "flex",
                                                                                  flexDirection:
                                                                                    "row",
                                                                                  justifyContent:
                                                                                    "center",
                                                                                  marginTop:
                                                                                    "20px",
                                                                                }}
                                                                              >
                                                                                {/* <div>
                                                                              <Button
                                                                                variant="contained"
                                                                                component="label"
                                                                              >
                                                                                Upload
                                                                                Video
                                                                                <input
                                                                                  hidden
                                                                                  accept="video/*"
                                                                                  multiple
                                                                                  type="file"
                                                                                  onChange={handleFileSelect(
                                                                                    cardTreeElement
                                                                                  )}
                                                                                />
                                                                              </Button>
                                                                              <Button
                                                                                variant="contained"
                                                                                component="label"
                                                                                onClick={() =>
                                                                                  doubleConfirmClearMedia(
                                                                                    cardTreeElement
                                                                                  )
                                                                                }
                                                                                style={{
                                                                                  marginLeft:
                                                                                    "10px",
                                                                                }}
                                                                              >
                                                                                Clear
                                                                                Video
                                                                              </Button>
                                                                            </div> */}
                                                                              </div>
                                                                            </div>
                                                                            <br />
                                                                          </>
                                                                        )}
                                                                      {cardTreeElement.type ===
                                                                        "icon" && (
                                                                          <>
                                                                            <br />
                                                                            <div
                                                                              style={{
                                                                                border:
                                                                                  "solid black 3px",
                                                                                padding:
                                                                                  "20px",
                                                                              }}
                                                                            >
                                                                              {cardTreeElement.url !=
                                                                                API.getImage &&
                                                                                cardTreeElement.url !=
                                                                                "" &&
                                                                                cardTreeElement.preivewUrl ===
                                                                                "" && (
                                                                                  <Avatar
                                                                                    alt="Remy Sharp"
                                                                                    src={
                                                                                      cardTreeElement.url
                                                                                    }
                                                                                    sx={{
                                                                                      width: 150,
                                                                                      height: 150,
                                                                                    }}
                                                                                  ></Avatar>
                                                                                )}
                                                                              {cardTreeElement.preivewUrl !=
                                                                                "" && (
                                                                                  <Avatar
                                                                                    alt="Remy Sharp"
                                                                                    src={
                                                                                      cardTreeElement.previewUrl
                                                                                    }
                                                                                    sx={{
                                                                                      width: 150,
                                                                                      height: 150,
                                                                                    }}
                                                                                  ></Avatar>
                                                                                )}
                                                                              {(cardTreeElement.url ===
                                                                                API.getImage ||
                                                                                cardTreeElement.url ===
                                                                                "") &&
                                                                                cardTreeElement.preivewUrl ===
                                                                                "" && (
                                                                                  <Avatar
                                                                                    alt="Remy Sharp"
                                                                                    src={
                                                                                      ""
                                                                                    }
                                                                                    sx={{
                                                                                      width: 150,
                                                                                      height: 150,
                                                                                    }}
                                                                                  ></Avatar>
                                                                                )}
                                                                              <br />

                                                                              <div
                                                                                style={{
                                                                                  display:
                                                                                    "flex",
                                                                                  flexDirection:
                                                                                    "row",
                                                                                  justifyContent:
                                                                                    "center",
                                                                                  marginTop:
                                                                                    "20px",
                                                                                }}
                                                                              >
                                                                                <div>
                                                                                  <Button
                                                                                    variant="contained"
                                                                                    component="label"
                                                                                  >
                                                                                    Upload
                                                                                    Icon
                                                                                    <input
                                                                                      hidden
                                                                                      accept="image/*"
                                                                                      multiple
                                                                                      type="file"
                                                                                      onChange={handleFileSelect(
                                                                                        cardTreeElement
                                                                                      )}
                                                                                    />
                                                                                  </Button>
                                                                                  <Button
                                                                                    variant="contained"
                                                                                    component="label"
                                                                                    onClick={() =>
                                                                                      doubleConfirmClearMedia(
                                                                                        cardTreeElement
                                                                                      )
                                                                                    }
                                                                                    style={{
                                                                                      marginLeft:
                                                                                        "10px",
                                                                                    }}
                                                                                  >
                                                                                    Clear
                                                                                    Icon
                                                                                  </Button>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                            <br />
                                                                          </>
                                                                        )}
                                                                    </div>
                                                                  );
                                                                }
                                                              )}
                                                            </TreeItem>
                                                          </TreeView>
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}
                                                {cardElement.type ===
                                                  "text" && (
                                                    <>
                                                      <br />
                                                      <TextField
                                                        autoFocus
                                                        margin="dense"
                                                        id={cardElement.content}
                                                        label={cardElement.title}
                                                        type="text"
                                                        name="content"
                                                        fullWidth
                                                        variant="standard"
                                                        value={
                                                          cardElement.content
                                                        }
                                                        onChange={(e) =>
                                                          modifyContent(
                                                            cardElement.id,
                                                            idx,
                                                            cardElement.type,
                                                            e.target.value,
                                                            index,
                                                            cardIdx,
                                                            cardElement.title
                                                          )
                                                        }
                                                        sx={{ width: "65vw" }}
                                                      />
                                                      {(id === "Cooperation" ||
                                                        id === "Volunteer") &&
                                                        e.title === "Card" &&
                                                        cardElement.title ===
                                                        "Title (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              30
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                                marginBottom:
                                                                  "5px",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              ! Can be empty but
                                                              their corresponding
                                                              row will not be
                                                              hidden
                                                            </div>
                                                          </div>
                                                        )}
                                                      {(id === "Cooperation" ||
                                                        id === "Volunteer") &&
                                                        e.title === "Card" &&
                                                        cardElement.title ===
                                                        "Title (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                30 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                                marginBottom:
                                                                  "5px",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              ! Can be empty but
                                                              their corresponding
                                                              row will not be
                                                              hidden
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "landing" &&
                                                        e.title ===
                                                        "Session 5 - 序" &&
                                                        cardElement.title ===
                                                        "Name (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              22
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "landing" &&
                                                        e.title ===
                                                        "Session 5 - 序" &&
                                                        cardElement.title ===
                                                        "Name (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                22 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "landing" &&
                                                        e.title ===
                                                        "Session 5 - 序" &&
                                                        cardElement.title ===
                                                        "Title (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              68
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "landing" &&
                                                        e.title ===
                                                        "Session 5 - 序" &&
                                                        cardElement.title ===
                                                        "Title (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                68 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "landing" &&
                                                        e.title ===
                                                        "Session 5 - 序" &&
                                                        cardElement.title ===
                                                        "Preface (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              60
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "landing" &&
                                                        e.title ===
                                                        "Session 5 - 序" &&
                                                        cardElement.title ===
                                                        "Preface (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                60 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "What Is ACT" &&
                                                        e.title ===
                                                        "Introduction To Acceptance And Commitment Therapy Session" &&
                                                        cardElement.title ===
                                                        "Title (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              45
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "What Is ACT" &&
                                                        e.title ===
                                                        "Introduction To Acceptance And Commitment Therapy Session" &&
                                                        cardElement.title ===
                                                        "Title (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                45 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "What Is ACT" &&
                                                        e.title ===
                                                        "Introduction To Acceptance And Commitment Therapy Session" &&
                                                        (cardElement.title ===
                                                          "Hand Gestures (En)" ||
                                                          cardElement.title ===
                                                          "Symbolizing (En)") && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              132
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "What Is ACT" &&
                                                        e.title ===
                                                        "Introduction To Acceptance And Commitment Therapy Session" &&
                                                        (cardElement.title ===
                                                          "Hand Gestures (繁)" ||
                                                          cardElement.title ===
                                                          "Symbolizing (繁)") && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                132 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "What Is ACT" &&
                                                        e.title ===
                                                        "Current Evidence Session" &&
                                                        cardElement.title ===
                                                        "Sponsor (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              105
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "What Is ACT" &&
                                                        e.title ===
                                                        "Current Evidence Session" &&
                                                        cardElement.title ===
                                                        "Sponsor (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                105 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "What Is ACT" &&
                                                        e.title ===
                                                        "Current Evidence Session" &&
                                                        cardElement.title ===
                                                        "Title (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              28
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "What Is ACT" &&
                                                        e.title ===
                                                        "Current Evidence Session" &&
                                                        cardElement.title ===
                                                        "Title (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                28 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === undefined &&
                                                        (e.title ===
                                                          "Reading Material" ||
                                                          e.title ===
                                                          "Self-Learning Exercise") &&
                                                        (cardElement.title ===
                                                          "Title (繁)" ||
                                                          cardElement.title ===
                                                          "Title (En)") && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              12
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                                marginBottom:
                                                                  "5px",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              If empty the
                                                              components are
                                                              located will be
                                                              hidden
                                                            </div>
                                                          </div>
                                                        )}

                                                      {id === "Research" &&
                                                        e.title ===
                                                        "Research Main Content" &&
                                                        cardElement.title ===
                                                        "Topic (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              160
                                                            </div>
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Do not leave this
                                                              field empty to avoid
                                                              display empty space
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "Research" &&
                                                        e.title ===
                                                        "Research Main Content" &&
                                                        cardElement.title ===
                                                        "Topic (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                160 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Do not leave this
                                                              field empty to avoid
                                                              display empty space
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "Research" &&
                                                        e.title ===
                                                        "Research Main Content" &&
                                                        cardElement.title ===
                                                        "Sponsor (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              150
                                                            </div>
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Do not leave this
                                                              field empty to avoid
                                                              display empty space
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "Research" &&
                                                        e.title ===
                                                        "Research Main Content" &&
                                                        cardElement.title ===
                                                        "Sponsor (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                150 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Do not leave this
                                                              field empty to avoid
                                                              display empty space
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "Publicity" &&
                                                        (e.title ===
                                                          "What's new?" ||
                                                          e.title ===
                                                          "Broadcast Interview" ||
                                                          e.title ===
                                                          "Press Releases") &&
                                                        cardElement.title ===
                                                        "Title (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              45
                                                            </div>
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>

                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Do not leave this
                                                              field empty for the
                                                              card to display
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "Publicity" &&
                                                        (e.title ===
                                                          "What's new?" ||
                                                          e.title ===
                                                          "Broadcast Interview" ||
                                                          e.title ===
                                                          "Press Releases") &&
                                                        cardElement.title ===
                                                        "Title (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                45 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>

                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Do not leave this
                                                              field empty for the
                                                              card to display
                                                            </div>
                                                          </div>
                                                        )}
                                                      {(id === "Publicity" ||
                                                        id === "Recruitment") &&
                                                        (e.title ===
                                                          "What's new?" ||
                                                          e.title ===
                                                          "Broadcast Interview" ||
                                                          e.title ===
                                                          "Press Releases" ||
                                                          e.title === "Jobs") &&
                                                        (cardElement.title ===
                                                          "Date" ||
                                                          cardElement.title ===
                                                          "Published date") && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Format : DD/MM/YYYY
                                                            </div>
                                                          </div>
                                                        )}

                                                      {id === "Publicity" &&
                                                        (e.title ===
                                                          "What's new?" ||
                                                          e.title ===
                                                          "Broadcast Interview" ||
                                                          e.title ===
                                                          "Press Releases") &&
                                                        cardElement.title ===
                                                        "Date" && (
                                                          <div
                                                            style={{
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              {" "}
                                                              Can empty and the
                                                              corresponding
                                                              components will be
                                                              hidden
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "Recruitment" &&
                                                        e.title === "Jobs" &&
                                                        cardElement.title ===
                                                        "Open Positions (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              60
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                                marginBottom:
                                                                  "5px",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "Recruitment" &&
                                                        e.title === "Jobs" &&
                                                        cardElement.title ===
                                                        "Open Positions (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                60 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                                marginBottom:
                                                                  "5px",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "Research" &&
                                                        e.title ===
                                                        "Research Main Content" &&
                                                        cardElement.title ===
                                                        "Duration" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Format : Start Year
                                                              - End Year
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Do not leave this
                                                              field empty to avoid
                                                              display empty space
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "Training" &&
                                                        (e.title ===
                                                          "Details of Upcoming Events" ||
                                                          e.title ===
                                                          "Details of Past Events") &&
                                                        cardElement.title ===
                                                        "Title 1 (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              50
                                                            </div>
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Do not leave this
                                                              field empty to avoid
                                                              display empty space
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "Training" &&
                                                        (e.title ===
                                                          "Details of Upcoming Events" ||
                                                          e.title ===
                                                          "Details of Past Events") &&
                                                        cardElement.title ===
                                                        "Title 1 (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                50 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {
                                                                cardElement
                                                                  .content.length
                                                              }
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Do not leave this
                                                              field empty to avoid
                                                              display empty space
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "Training" &&
                                                        (e.title ===
                                                          "Details of Upcoming Events" ||
                                                          e.title ===
                                                          "Details of Past Events") &&
                                                        cardElement.title ===
                                                        "Time" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Format : 00:00 -
                                                              23:59
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Can empty and the
                                                              corresponding
                                                              components will be
                                                              hidden
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "Training" &&
                                                        (e.title ===
                                                          "Details of Upcoming Events" ||
                                                          e.title ===
                                                          "Details of Past Events") &&
                                                        cardElement.title ===
                                                        "Date" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Format : DD/MM/YYYY
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Can empty and the
                                                              corresponding
                                                              components will be
                                                              hidden
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "Recruitment" &&
                                                        e.title === "Jobs" &&
                                                        (cardElement.title ===
                                                          "Published date" ||
                                                          cardElement.title ===
                                                          "Open Positions (繁)" ||
                                                          cardElement.title ===
                                                          "Open Positions (En)") && (
                                                          <div
                                                            style={{
                                                              color: "blue",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            ! Can be empty but
                                                            their corresponding
                                                            row will not be hidden
                                                          </div>
                                                        )}

                                                      <br />

                                                      {role === "superAdmin" && (
                                                        <>
                                                          <br />
                                                          <Button
                                                            variant="contained"
                                                            component="label"
                                                            onClick={() =>
                                                              changeWordEditorToText(
                                                                cardElement
                                                              )
                                                            }
                                                          >
                                                            Change Type
                                                          </Button>
                                                        </>
                                                      )}
                                                      {/* {role === "superAdmin" && (
                                                  <>
                                                    <br />
                                                    <Button
                                                      variant="contained"
                                                      component="label"
                                                      onClick={() =>
                                                        changeLanguage(
                                                          cardElement
                                                        )
                                                      }
                                                      style={{
                                                        marginTop: "10px",
                                                      }}
                                                    >
                                                      Change Language
                                                    </Button>
                                                  </>
                                                )} */}
                                                    </>
                                                  )}
                                                {cardElement.type ===
                                                  "wordEditor" && (
                                                    <>
                                                      <br />
                                                      <div
                                                        style={{
                                                          width: "65vw",
                                                        }}
                                                      >
                                                        <Typography
                                                          sx={{
                                                            fontSize: 14,
                                                            marginTop: "20px",
                                                          }}
                                                          color="black"
                                                          gutterBottom
                                                        >
                                                          {cardElement.title}
                                                        </Typography>
                                                        <EditorDraft
                                                          onConvertToHtml={setRawHTML(
                                                            cardIdx,
                                                            cardElement
                                                          )}
                                                          htmlRawValue={
                                                            cardElement.content
                                                          }
                                                        />
                                                      </div>
                                                      {id === "landing" &&
                                                        e.title ===
                                                        "Session 5 - 序" &&
                                                        cardElement.title ===
                                                        "Preface (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              60
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {cardElement.content
                                                                .replace(
                                                                  /&nbsp;/g,
                                                                  " "
                                                                )
                                                                .replace(
                                                                  /&amp;/g,
                                                                  "&"
                                                                )
                                                                .replace(
                                                                  /<[^>]*>/g,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /&lt;/g,
                                                                  "<"
                                                                )
                                                                .replace(
                                                                  /&gt;/g,
                                                                  ">"
                                                                ).length -
                                                                1 ===
                                                                -1
                                                                ? 0
                                                                : cardElement.content
                                                                  .replace(
                                                                    /&nbsp;/g,
                                                                    " "
                                                                  )
                                                                  .replace(
                                                                    /&amp;/g,
                                                                    "&"
                                                                  )
                                                                  .replace(
                                                                    /<[^>]*>/g,
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    /&lt;/g,
                                                                    "<"
                                                                  )
                                                                  .replace(
                                                                    /&gt;/g,
                                                                    ">"
                                                                  ).length - 1}
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "landing" &&
                                                        e.title ===
                                                        "Session 5 - 序" &&
                                                        cardElement.title ===
                                                        "Preface (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                60 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {cardElement.content
                                                                .replace(
                                                                  /&nbsp;/g,
                                                                  " "
                                                                )
                                                                .replace(
                                                                  /&amp;/g,
                                                                  "&"
                                                                )
                                                                .replace(
                                                                  /<[^>]*>/g,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /&lt;/g,
                                                                  "<"
                                                                )
                                                                .replace(
                                                                  /&gt;/g,
                                                                  ">"
                                                                ).length -
                                                                1 ===
                                                                -1
                                                                ? 0
                                                                : cardElement.content
                                                                  .replace(
                                                                    /&nbsp;/g,
                                                                    " "
                                                                  )
                                                                  .replace(
                                                                    /&amp;/g,
                                                                    "&"
                                                                  )
                                                                  .replace(
                                                                    /<[^>]*>/g,
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    /&lt;/g,
                                                                    "<"
                                                                  )
                                                                  .replace(
                                                                    /&gt;/g,
                                                                    ">"
                                                                  ).length - 1}
                                                            </div>
                                                          </div>
                                                        )}
                                                      {(id === "Cooperation" ||
                                                        id === "Volunteer") &&
                                                        e.title === "Card" &&
                                                        cardElement.title.includes(
                                                          "En"
                                                        ) && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              700
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                                marginBottom:
                                                                  "5px",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {cardElement.content
                                                                .replace(
                                                                  /&nbsp;/g,
                                                                  " "
                                                                )
                                                                .replace(
                                                                  /&amp;/g,
                                                                  "&"
                                                                )
                                                                .replace(
                                                                  /<[^>]*>/g,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /&lt;/g,
                                                                  "<"
                                                                )
                                                                .replace(
                                                                  /&gt;/g,
                                                                  ">"
                                                                ).length -
                                                                1 ===
                                                                -1
                                                                ? 0
                                                                : cardElement.content
                                                                  .replace(
                                                                    /&nbsp;/g,
                                                                    " "
                                                                  )
                                                                  .replace(
                                                                    /&amp;/g,
                                                                    "&"
                                                                  )
                                                                  .replace(
                                                                    /<[^>]*>/g,
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    /&lt;/g,
                                                                    "<"
                                                                  )
                                                                  .replace(
                                                                    /&gt;/g,
                                                                    ">"
                                                                  ).length - 1}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              ! Can be empty but
                                                              their corresponding
                                                              row will not be
                                                              hidden
                                                            </div>
                                                          </div>
                                                        )}
                                                      {(id === "Cooperation" ||
                                                        id === "Volunteer") &&
                                                        e.title === "Card" &&
                                                        cardElement.title.includes(
                                                          "繁"
                                                        ) && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                700 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                                marginBottom:
                                                                  "5px",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {cardElement.content
                                                                .replace(
                                                                  /&nbsp;/g,
                                                                  " "
                                                                )
                                                                .replace(
                                                                  /&amp;/g,
                                                                  "&"
                                                                )
                                                                .replace(
                                                                  /<[^>]*>/g,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /&lt;/g,
                                                                  "<"
                                                                )
                                                                .replace(
                                                                  /&gt;/g,
                                                                  ">"
                                                                ).length -
                                                                1 ===
                                                                -1
                                                                ? 0
                                                                : cardElement.content
                                                                  .replace(
                                                                    /&nbsp;/g,
                                                                    " "
                                                                  )
                                                                  .replace(
                                                                    /&amp;/g,
                                                                    "&"
                                                                  )
                                                                  .replace(
                                                                    /<[^>]*>/g,
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    /&lt;/g,
                                                                    "<"
                                                                  )
                                                                  .replace(
                                                                    /&gt;/g,
                                                                    ">"
                                                                  ).length - 1}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              ! Can be empty but
                                                              their corresponding
                                                              row will not be
                                                              hidden
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "What Is ACT" &&
                                                        e.title ===
                                                        "Current Evidence Session" &&
                                                        cardElement.title ===
                                                        "Description (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              190
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {cardElement.content
                                                                .replace(
                                                                  /&nbsp;/g,
                                                                  " "
                                                                )
                                                                .replace(
                                                                  /&amp;/g,
                                                                  "&"
                                                                )
                                                                .replace(
                                                                  /<[^>]*>/g,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /&lt;/g,
                                                                  "<"
                                                                )
                                                                .replace(
                                                                  /&gt;/g,
                                                                  ">"
                                                                ).length -
                                                                1 ===
                                                                -1
                                                                ? 0
                                                                : cardElement.content
                                                                  .replace(
                                                                    /&nbsp;/g,
                                                                    " "
                                                                  )
                                                                  .replace(
                                                                    /&amp;/g,
                                                                    "&"
                                                                  )
                                                                  .replace(
                                                                    /<[^>]*>/g,
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    /&lt;/g,
                                                                    "<"
                                                                  )
                                                                  .replace(
                                                                    /&gt;/g,
                                                                    ">"
                                                                  ).length - 1}
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === "What Is ACT" &&
                                                        e.title ===
                                                        "Current Evidence Session" &&
                                                        cardElement.title ===
                                                        "Description (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                190 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {cardElement.content
                                                                .replace(
                                                                  /&nbsp;/g,
                                                                  " "
                                                                )
                                                                .replace(
                                                                  /&amp;/g,
                                                                  "&"
                                                                )
                                                                .replace(
                                                                  /<[^>]*>/g,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /&lt;/g,
                                                                  "<"
                                                                )
                                                                .replace(
                                                                  /&gt;/g,
                                                                  ">"
                                                                ).length -
                                                                1 ===
                                                                -1
                                                                ? 0
                                                                : cardElement.content
                                                                  .replace(
                                                                    /&nbsp;/g,
                                                                    " "
                                                                  )
                                                                  .replace(
                                                                    /&amp;/g,
                                                                    "&"
                                                                  )
                                                                  .replace(
                                                                    /<[^>]*>/g,
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    /&lt;/g,
                                                                    "<"
                                                                  )
                                                                  .replace(
                                                                    /&gt;/g,
                                                                    ">"
                                                                  ).length - 1}
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === undefined &&
                                                        e.title ===
                                                        "Story Telling" &&
                                                        cardElement.title ===
                                                        "Content (En)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Mobile Layout could
                                                              only show 40 word
                                                              count without
                                                              scrolling
                                                            </div>

                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              100
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                                marginBottom:
                                                                  "5px",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {cardElement.content
                                                                .replace(
                                                                  /&nbsp;/g,
                                                                  " "
                                                                )
                                                                .replace(
                                                                  /&amp;/g,
                                                                  "&"
                                                                )
                                                                .replace(
                                                                  /<[^>]*>/g,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /&lt;/g,
                                                                  "<"
                                                                )
                                                                .replace(
                                                                  /&gt;/g,
                                                                  ">"
                                                                ).length -
                                                                1 ===
                                                                -1
                                                                ? 0
                                                                : cardElement.content
                                                                  .replace(
                                                                    /&nbsp;/g,
                                                                    " "
                                                                  )
                                                                  .replace(
                                                                    /&amp;/g,
                                                                    "&"
                                                                  )
                                                                  .replace(
                                                                    /<[^>]*>/g,
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    /&lt;/g,
                                                                    "<"
                                                                  )
                                                                  .replace(
                                                                    /&gt;/g,
                                                                    ">"
                                                                  ).length - 1}
                                                            </div>

                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              ! Can be empty but
                                                              will have empty
                                                              space
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === undefined &&
                                                        e.title ===
                                                        "Story Telling" &&
                                                        cardElement.title ===
                                                        "Content (繁)" && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Mobile Layout could
                                                              only show{" "}
                                                              {Math.ceil(
                                                                40 / 2.18
                                                              )}{" "}
                                                              word count without
                                                              scrolling
                                                            </div>

                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              {Math.ceil(
                                                                100 / 2.18
                                                              )}
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                                marginBottom:
                                                                  "5px",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {cardElement.content
                                                                .replace(
                                                                  /&nbsp;/g,
                                                                  " "
                                                                )
                                                                .replace(
                                                                  /&amp;/g,
                                                                  "&"
                                                                )
                                                                .replace(
                                                                  /<[^>]*>/g,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /&lt;/g,
                                                                  "<"
                                                                )
                                                                .replace(
                                                                  /&gt;/g,
                                                                  ">"
                                                                ).length -
                                                                1 ===
                                                                -1
                                                                ? 0
                                                                : cardElement.content
                                                                  .replace(
                                                                    /&nbsp;/g,
                                                                    " "
                                                                  )
                                                                  .replace(
                                                                    /&amp;/g,
                                                                    "&"
                                                                  )
                                                                  .replace(
                                                                    /<[^>]*>/g,
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    /&lt;/g,
                                                                    "<"
                                                                  )
                                                                  .replace(
                                                                    /&gt;/g,
                                                                    ">"
                                                                  ).length - 1}
                                                            </div>

                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              ! Can be empty but
                                                              will have empty
                                                              space
                                                            </div>
                                                          </div>
                                                        )}
                                                      {id === undefined &&
                                                        e.title ===
                                                        "Self-Learning Exercise" &&
                                                        (cardElement.title ===
                                                          "Content 1 (繁)" ||
                                                          cardElement.title ===
                                                          "Content 2 (繁)" ||
                                                          cardElement.title ===
                                                          "Content 3 (繁)" ||
                                                          cardElement.title ===
                                                          "Content 4 (繁)") && (
                                                          <div
                                                            style={{
                                                              marginTop: "15px",
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Recommend maximum
                                                              characters count :
                                                              800
                                                            </div>
                                                            <div
                                                              style={{
                                                                color: "blue",
                                                              }}
                                                            >
                                                              Current characters
                                                              count :{" "}
                                                              {cardElement.content
                                                                .replace(
                                                                  /&nbsp;/g,
                                                                  " "
                                                                )
                                                                .replace(
                                                                  /&amp;/g,
                                                                  "&"
                                                                )
                                                                .replace(
                                                                  /<[^>]*>/g,
                                                                  ""
                                                                )
                                                                .replace(
                                                                  /&lt;/g,
                                                                  "<"
                                                                )
                                                                .replace(
                                                                  /&gt;/g,
                                                                  ">"
                                                                ).length -
                                                                1 ===
                                                                -1
                                                                ? 0
                                                                : cardElement.content
                                                                  .replace(
                                                                    /&nbsp;/g,
                                                                    " "
                                                                  )
                                                                  .replace(
                                                                    /&amp;/g,
                                                                    "&"
                                                                  )
                                                                  .replace(
                                                                    /<[^>]*>/g,
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    /&lt;/g,
                                                                    "<"
                                                                  )
                                                                  .replace(
                                                                    /&gt;/g,
                                                                    ">"
                                                                  ).length - 1}
                                                            </div>
                                                          </div>
                                                        )}
                                                      <br />
                                                      {role === "superAdmin" && (
                                                        <>
                                                          <br />
                                                          <Button
                                                            variant="contained"
                                                            component="label"
                                                            onClick={() =>
                                                              changeWordEditorToText(
                                                                cardElement
                                                              )
                                                            }
                                                          >
                                                            Change Type
                                                          </Button>
                                                        </>
                                                      )}
                                                      {/* {role === "superAdmin" && (
                                                  <>
                                                    <br />
                                                    <Button
                                                      variant="contained"
                                                      component="label"
                                                      onClick={() =>
                                                        changeLanguage(
                                                          cardElement
                                                        )
                                                      }
                                                      style={{
                                                        marginTop: "10px",
                                                      }}
                                                    >
                                                      Change Language
                                                    </Button>
                                                  </>
                                                )} */}
                                                    </>
                                                  )}
                                                {cardElement.type ===
                                                  "image" && (
                                                    <>
                                                      <br />
                                                      <div
                                                        style={{
                                                          border:
                                                            "solid black 3px",
                                                          padding: "20px",

                                                          width: "65vw",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            flexDirection:
                                                              "column",
                                                            alignItems: "center",
                                                          }}
                                                        >
                                                          {cardElement.previewUrl !=
                                                            "" && (
                                                              <>
                                                                <div
                                                                  style={{
                                                                    display: "flex",
                                                                    justifyContent:
                                                                      "center",
                                                                    // width: "800px",
                                                                    maxWidth:
                                                                      "800px",
                                                                    height: "450px",
                                                                    maxHeight:
                                                                      "500px",
                                                                    border:
                                                                      "solid 3px black",
                                                                    // objectFit: "contain",
                                                                  }}
                                                                >
                                                                  <CardMedia
                                                                    component="img"
                                                                    height="194"
                                                                    image={
                                                                      cardElement.previewUrl
                                                                    }
                                                                    alt=" Please upload image"
                                                                  />
                                                                </div>
                                                              </>
                                                            )}
                                                          {cardElement.previewUrl ===
                                                            "" &&
                                                            (cardElement.url ===
                                                              "" ||
                                                              cardElement.url ===
                                                              API.getImage) && (
                                                              <>
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "center",
                                                                    // width: "800px",
                                                                    minWidth:
                                                                      "50vw",
                                                                    maxWidth:
                                                                      "800px",
                                                                    height:
                                                                      "450px",
                                                                    maxHeight:
                                                                      "500px",
                                                                    border:
                                                                      "solid 3px black",
                                                                    alignItems:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <div>
                                                                    <CardMedia
                                                                      component="img"
                                                                      height="194"
                                                                      image={""}
                                                                      alt=" Please upload image"
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </>
                                                            )}
                                                          {cardElement.previewUrl ===
                                                            "" &&
                                                            cardElement.url !=
                                                            "" &&
                                                            cardElement.url !=
                                                            API.getImage && (
                                                              <>
                                                                <div
                                                                  style={{
                                                                    // display: "flex",
                                                                    // justifyContent: "center",
                                                                    // width: "800px",
                                                                    // maxWidth: "800px",
                                                                    // height: "450px",
                                                                    // maxHeight: "500px",
                                                                    border:
                                                                      "solid 3px black",
                                                                    // objectFit: "contain",
                                                                  }}
                                                                >
                                                                  <CardMedia
                                                                    component="img"
                                                                    height="194"
                                                                    image={
                                                                      cardElement.url
                                                                    }
                                                                    alt="Please upload image"
                                                                  />
                                                                </div>
                                                              </>
                                                            )}
                                                          <br />
                                                          <div>
                                                            Maximum Image Size :
                                                            10MB
                                                          </div>
                                                          <br />
                                                          {id === "Training" &&
                                                            (e.title ===
                                                              "Details of Upcoming Events" ||
                                                              e.title ===
                                                              "Details of Past Events") && (
                                                              <>
                                                                <div>
                                                                  Recommend Size :{" "}
                                                                  {"> "}
                                                                  634x375
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Do not leave
                                                                  this field empty
                                                                  to avoid display
                                                                  empty space
                                                                </div>
                                                                <br />
                                                              </>
                                                            )}
                                                          {id === undefined &&
                                                            e.title ===
                                                            "Story Telling" && (
                                                              <>
                                                                {" "}
                                                                <div>
                                                                  Suggested Ratio
                                                                  : 16:9
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Recommend Size :{" "}
                                                                  960x540
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Recommend
                                                                  Minimum Size :
                                                                  659x371
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  ! Can empty but
                                                                  not recommended
                                                                </div>
                                                              </>
                                                            )}
                                                          {/* {id === "Research" && (
                                                          <div>
                                                            Suggested Ratio :
                                                            1:1
                                                          </div>
                                                        )} */}
                                                          {id === "What Is ACT" &&
                                                            e.title ===
                                                            "Introduction To Acceptance And Commitment Therapy Session" && (
                                                              <>
                                                                <div>
                                                                  Recommend Size :
                                                                  width = 1000
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  No Limited Ratio
                                                                </div>
                                                              </>
                                                            )}
                                                          {id === "Training" && (
                                                            <div>
                                                              Suggested Ratio :
                                                              16:9
                                                            </div>
                                                          )}
                                                          {id === "Publicity" &&
                                                            e.title ===
                                                            "What's new?" && (
                                                              <>
                                                                <div>
                                                                  Suggested Ratio
                                                                  : 1:1
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Recommend Size :
                                                                  440x440
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Do not leave
                                                                  this field empty
                                                                  for the card to
                                                                  display
                                                                </div>
                                                              </>
                                                            )}
                                                          {id === "What Is ACT" &&
                                                            e.title ===
                                                            "Current Evidence Session" && (
                                                              <>
                                                                <div>
                                                                  Suggested Ratio
                                                                  : 16:9
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Recommend Size :{" "}
                                                                  600x338
                                                                </div>
                                                              </>
                                                            )}
                                                          {id === undefined &&
                                                            e.title ===
                                                            "Reading Material" && (
                                                              <>
                                                                <div>
                                                                  Suggested Ratio
                                                                  : 5:3
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Recommend Size :{" "}
                                                                  1500x999
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Recommend
                                                                  Minimum Size :
                                                                  928x557
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  ! Can empty but
                                                                  not recommended
                                                                </div>
                                                              </>
                                                            )}
                                                          {id === undefined &&
                                                            e.title ===
                                                            "Self-Learning Exercise" && (
                                                              <>
                                                                <div>
                                                                  Suggested Ratio
                                                                  : 16:9
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Recommend Size :{" "}
                                                                  1500x1000
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Recommend
                                                                  Minimum Size :
                                                                  959x539
                                                                </div>
                                                              </>
                                                            )}
                                                          {id === "landing" &&
                                                            e.title ===
                                                            "Session 5 - 序" && (
                                                              <>
                                                                <div>
                                                                  Recommend upload
                                                                  a transparent
                                                                  background image
                                                                </div>
                                                              </>
                                                            )}
                                                          {id === "Research" &&
                                                            e.title ===
                                                            "Research Main Content" && (
                                                              <>
                                                                <div>
                                                                  Suggested Ratio
                                                                  : 1:1
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Recommend
                                                                  Minimum Size :
                                                                  425x425
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Do not leave
                                                                  this field empty
                                                                  to avoid display
                                                                  empty space
                                                                </div>
                                                              </>
                                                            )}
                                                          {/* {id === "Publicity" &&
                                                          e.title ===
                                                            "Publicity Radio" && (
                                                            <div>
                                                              Suggested Ratio :
                                                              16:9
                                                            </div>
                                                          )} */}
                                                          {id === "Publicity" &&
                                                            (e.title ===
                                                              "Broadcast Interview" ||
                                                              e.title ===
                                                              "Press Releases") && (
                                                              <>
                                                                <div>
                                                                  Suggested Ratio
                                                                  : 1:1
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Recommend Size :
                                                                  440x440
                                                                </div>
                                                                <br />
                                                                <div>
                                                                  Do not leave
                                                                  this field empty
                                                                  for the card to
                                                                  display
                                                                </div>
                                                              </>
                                                            )}
                                                          {id === undefined &&
                                                            e.title ===
                                                            "Self-Learning Exercise" && (
                                                              <>
                                                                <br />
                                                                <div>
                                                                  * If missing
                                                                  photo the card
                                                                  would not appear
                                                                </div>
                                                              </>
                                                            )}
                                                          {/* <div>
                                                          Suggested Ratio : 1500
                                                          x 1000
                                                        </div> */}
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              flexDirection:
                                                                "row",
                                                              justifyContent:
                                                                "center",
                                                              marginTop: "20px",
                                                            }}
                                                          >
                                                            <div>
                                                              <Button
                                                                variant="contained"
                                                                component="label"
                                                              >
                                                                Upload Image
                                                                <input
                                                                  hidden
                                                                  accept="image/*"
                                                                  multiple
                                                                  type="file"
                                                                  onChange={handleFileSelect(
                                                                    cardElement
                                                                  )}
                                                                />
                                                              </Button>
                                                              <Button
                                                                variant="contained"
                                                                component="label"
                                                                onClick={() =>
                                                                  doubleConfirmClearMedia(
                                                                    cardElement
                                                                  )
                                                                }
                                                                style={{
                                                                  marginLeft:
                                                                    "10px",
                                                                }}
                                                              >
                                                                Clear Image
                                                              </Button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <br />
                                                      </div>
                                                      <br />
                                                    </>
                                                  )}
                                                {cardElement.type ===
                                                  "audio" && (
                                                    <>
                                                      <br />
                                                      {cardElement.url !=
                                                        API.getAudio &&
                                                        cardElement.url != "" &&
                                                        cardElement.previewUrl ===
                                                        "" && (
                                                          <audio controls>
                                                            <source
                                                              src={
                                                                cardElement.url
                                                              }
                                                              type="audio/mpeg"
                                                            ></source>
                                                          </audio>
                                                        )}
                                                      {(cardElement.url ===
                                                        API.getAudio ||
                                                        cardElement.url === "") &&
                                                        cardElement.previewUrl ===
                                                        "" && (
                                                          <audio controls>
                                                            <source
                                                              src={""}
                                                              type="audio/mpeg"
                                                            ></source>
                                                          </audio>
                                                        )}
                                                      {cardElement.previewUrl !=
                                                        "" && (
                                                          <embed
                                                            src={
                                                              cardElement.previewUrl
                                                            }
                                                          ></embed>
                                                        )}
                                                      <br />
                                                      <div>
                                                        Maximum Audio Size : 10MB
                                                      </div>
                                                      <br />
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          justifyContent:
                                                            "center",
                                                          marginTop: "20px",
                                                        }}
                                                      >
                                                        <div>
                                                          <Button
                                                            variant="contained"
                                                            component="label"
                                                          >
                                                            Upload Audio
                                                            <input
                                                              hidden
                                                              accept="audio/*"
                                                              multiple
                                                              type="file"
                                                              onChange={handleFileSelect(
                                                                cardElement
                                                              )}
                                                            />
                                                          </Button>
                                                          <Button
                                                            variant="contained"
                                                            component="label"
                                                            onClick={() =>
                                                              doubleConfirmClearMedia(
                                                                cardElement
                                                              )
                                                            }
                                                            style={{
                                                              marginLeft: "10px",
                                                            }}
                                                          >
                                                            Clear Audio
                                                          </Button>
                                                        </div>
                                                      </div>
                                                      <br />
                                                    </>
                                                  )}
                                                {cardElement.type ===
                                                  "video" && (
                                                    <>
                                                      <br />
                                                      <div
                                                        style={{
                                                          width: "65vw",
                                                          border:
                                                            "solid 3px black",
                                                          padding: "20px",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            flexDirection:
                                                              "column",
                                                            alignItems: "center",
                                                            justifyContent:
                                                              "center",
                                                            // width: "800px",
                                                            // maxWidth: "800px",
                                                            // height: "450px",
                                                            // maxHeight: "500px",
                                                            border:
                                                              "solid 3px black",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              flexDirection:
                                                                "column",
                                                            }}
                                                          >
                                                            <TextField
                                                              autoFocus
                                                              margin="dense"
                                                              id={
                                                                cardElement.url +
                                                                cardIdx
                                                              }
                                                              label={
                                                                cardElement.title
                                                              }
                                                              type="text"
                                                              name="content"
                                                              variant="standard"
                                                              fullWidth
                                                              value={
                                                                cardElement.previewUrl
                                                              }
                                                              onChange={(e) =>
                                                                modifyVideoContent(
                                                                  cardElement.id,
                                                                  idx,
                                                                  cardElement.type,
                                                                  e.target.value,
                                                                  index,
                                                                  cardIdx
                                                                )
                                                              }
                                                            />
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "10px",
                                                              }}
                                                            >
                                                              Please enter youtube
                                                              link
                                                            </div>
                                                            <div
                                                              style={{
                                                                marginTop: "20px",
                                                              }}
                                                            >
                                                              <iframe
                                                                src={
                                                                  cardElement.url ||
                                                                  ""
                                                                }
                                                                allow="autoplay; fullscreen"
                                                              ></iframe>
                                                            </div>
                                                          </div>
                                                          {/* {(cardElement.url ===
                                                          "" ||
                                                          cardElement.url ===
                                                            API.getVideo) &&
                                                          cardElement.previewUrl ===
                                                            "" && (
                                                            <video controls>
                                                              <source
                                                                src={""}
                                                                type="video/mp4"
                                                              ></source>
                                                              Your browser does
                                                              not support the
                                                              video tag.
                                                            </video>
                                                          )}
                                                        {cardElement.previewUrl !=
                                                          "" && (
                                                          <video controls>
                                                            <source
                                                              src={
                                                                cardElement.previewUrl
                                                              }
                                                              type="video/mp4"
                                                            ></source>
                                                            Your browser does
                                                            not support the
                                                            video tag.
                                                          </video>
                                                        )}
                                                        {cardElement.url !=
                                                          "" &&
                                                          cardElement.url !=
                                                            API.getVideo &&
                                                          cardElement.previewUrl ===
                                                            "" && (
                                                            <video controls>
                                                              <source
                                                                src={
                                                                  cardElement.url
                                                                }
                                                                type="video/mp4"
                                                              ></source>
                                                              Your browser does
                                                              not support the
                                                              video tag.
                                                            </video>
                                                          )} */}
                                                        </div>
                                                        <br />
                                                        {/* <div>
                                                        Maximum Video Size :
                                                        10MB
                                                      </div> */}
                                                        <br />
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent:
                                                              "center",
                                                            marginTop: "20px",
                                                          }}
                                                        >
                                                          {/* <div>
                                                          <Button
                                                            variant="contained"
                                                            component="label"
                                                          >
                                                            Upload Video
                                                            <input
                                                              hidden
                                                              accept="video/*"
                                                              multiple
                                                              type="file"
                                                              onChange={handleFileSelect(
                                                                cardElement
                                                              )}
                                                            />
                                                          </Button>
                                                          <Button
                                                            variant="contained"
                                                            component="label"
                                                            onClick={() =>
                                                              doubleConfirmClearMedia(
                                                                cardElement
                                                              )
                                                            }
                                                            style={{
                                                              marginLeft:
                                                                "10px",
                                                            }}
                                                          >
                                                            Clear Video
                                                          </Button>
                                                        </div> */}
                                                        </div>
                                                      </div>
                                                      <br />
                                                    </>
                                                  )}
                                                {cardElement.type ===
                                                  "icon" && (
                                                    <>
                                                      <div
                                                        style={{
                                                          width: "65vw",
                                                          border:
                                                            "solid black 3px",
                                                          padding: "20px",
                                                          display: "flex",
                                                          flexDirection: "column",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        {cardElement.previewUrl ===
                                                          "" &&
                                                          (cardElement.url ===
                                                            "" ||
                                                            cardElement.url ===
                                                            API.getImage) && (
                                                            <Avatar
                                                              alt="Remy Sharp"
                                                              src={""}
                                                              sx={{
                                                                width: 150,
                                                                height: 150,
                                                              }}
                                                            ></Avatar>
                                                          )}
                                                        {cardElement.previewUrl !=
                                                          "" && (
                                                            <Avatar
                                                              alt="Remy Sharp"
                                                              src={
                                                                cardElement.previewUrl ||
                                                                ""
                                                              }
                                                              sx={{
                                                                width: 150,
                                                                height: 150,
                                                              }}
                                                            ></Avatar>
                                                          )}
                                                        {cardElement.url != "" &&
                                                          cardElement.url !=
                                                          API.getImage &&
                                                          cardElement.previewUrl ===
                                                          "" && (
                                                            <Avatar
                                                              alt="Remy Sharp"
                                                              src={
                                                                cardElement.url ||
                                                                ""
                                                              }
                                                              sx={{
                                                                width: 150,
                                                                height: 150,
                                                              }}
                                                            ></Avatar>
                                                          )}
                                                        {id === "landing" &&
                                                          e.title ===
                                                          "Session 5 - 序" && (
                                                            <>
                                                              {" "}
                                                              <div
                                                                style={{
                                                                  marginTop:
                                                                    "15px",
                                                                }}
                                                              >
                                                                Suggested Ratio :
                                                                1:1
                                                              </div>
                                                              <br />
                                                              <div>
                                                                Recommend Minimum
                                                                Size : 128x128
                                                              </div>
                                                            </>
                                                          )}
                                                        <br />
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent:
                                                              "center",
                                                            marginTop: "20px",
                                                          }}
                                                        >
                                                          <div>
                                                            <Button
                                                              variant="contained"
                                                              component="label"
                                                            >
                                                              Upload Icon
                                                              <input
                                                                hidden
                                                                accept="image/*"
                                                                multiple
                                                                type="file"
                                                                onChange={handleFileSelect(
                                                                  cardElement
                                                                )}
                                                              />
                                                            </Button>
                                                            <Button
                                                              variant="contained"
                                                              component="label"
                                                              onClick={() =>
                                                                doubleConfirmClearMedia(
                                                                  cardElement
                                                                )
                                                              }
                                                              style={{
                                                                marginLeft:
                                                                  "10px",
                                                              }}
                                                            >
                                                              Clear Icon
                                                            </Button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <br />
                                                    </>
                                                  )}
                                              </div>
                                            );
                                          }
                                        )}
                                      {id === undefined &&
                                        e.title ===
                                        "Self-Learning Exercise" && (
                                          <h2>
                                            <b>
                                              * Photo is required for the
                                              display to appear
                                            </b>
                                          </h2>
                                        )}
                                      {id === "What Is ACT" &&
                                        e.title ===
                                        "Current Evidence Session" && (
                                          <h2>
                                            <b>
                                              Only Sponsor and Year can be empty
                                              otherwise will not display
                                            </b>
                                          </h2>
                                        )}
                                      {id === "What Is ACT" &&
                                        e.title ===
                                        "Introduction To Acceptance And Commitment Therapy Session" && (
                                          <>
                                            <br />
                                            <b>
                                              Hand Gestures & Symbolizing
                                              overall characters count : {"<"}{" "}
                                              224
                                            </b>

                                            <br />
                                            <h2>
                                              <b>
                                                * All information must be filled
                                                out for the display to appear
                                              </b>
                                            </h2>
                                          </>
                                        )}
                                      {id === "landing" &&
                                        e.title === "Session 5 - 序" && (
                                          <h2>
                                            <b>
                                              * All information must be filled
                                              out for the display to appear
                                              includes icon and signature
                                            </b>
                                          </h2>
                                        )}
                                      {moduleNumber &&
                                        sub === "Reading Material" && (
                                          <>
                                            <Button
                                              variant="contained"
                                              component="label"
                                              onClick={() =>
                                                createReadingMaterialTreeView(
                                                  index,
                                                  idx
                                                )
                                              }
                                            >
                                              Add Sub Session
                                            </Button>
                                          </>
                                        )}
                                    </CardContent>
                                  </Card>
                                </div>
                              )}

                              <br />
                            </>
                          )}
                          {elements.type === "icon" && (
                            <>
                              <br />
                              <div
                                style={{
                                  border: "solid black 3px",
                                  padding: "20px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                {elements.previewUrl === "" &&
                                  elements.url === API.getImage && (
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={""}
                                      sx={{ width: 150, height: 150 }}
                                    ></Avatar>
                                  )}
                                {elements.previewUrl != "" && (
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={elements.previewUrl}
                                    sx={{ width: 150, height: 150 }}
                                  ></Avatar>
                                )}
                                {elements.url != "" &&
                                  elements.previewUrl === "" && (
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={elements.url}
                                      sx={{ width: 150, height: 150 }}
                                    ></Avatar>
                                  )}

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <div>
                                    <Button
                                      variant="contained"
                                      component="label"
                                    >
                                      Upload Icon
                                      <input
                                        hidden
                                        accept="image/*"
                                        multiple
                                        type="file"
                                        onChange={handleFileSelect(elements)}
                                      />
                                    </Button>
                                    <Button
                                      variant="contained"
                                      component="label"
                                      onClick={() =>
                                        doubleConfirmClearMedia(elements)
                                      }
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Clear Icon
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              <br />
                            </>
                          )}
                        </div>
                      );
                    })}
                    {id === "What Is ACT" && e.title === "What Is ACT" && (
                      <div
                        style={{
                          marginTop: "25px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <h2>
                          <b>
                            Session Title should be filled out & Above fields
                            can be empty and the corresponding components will
                            be hidden
                          </b>
                        </h2>
                      </div>
                    )}
                    {id === "Facilitator-Guided ACT" &&
                      e.title === "Introduction" && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <h2>
                            <b>
                              Below fields can be empty and the corresponding
                              components will be hidden
                            </b>
                          </h2>
                        </div>
                      )}
                  </div>
                  <br />
                  {e.title === "Session 5 - 序" && (
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => createLandingOrderCard(index)}
                    >
                      Add Card
                    </Button>
                  )}
                  {e.title === "Current Evidence Session" && (
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => createWhatIsACT_CESCard(index)}
                    >
                      Add Card
                    </Button>
                  )}
                  {e.title === "Training Materials (Login Required)" && (
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => createTrainingMaterialsCard(index)}
                    >
                      Add Card
                    </Button>
                  )}
                  {e.title === "Story Telling" && moduleNumber && (
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => createModuleStoryTellingCard(index)}
                    >
                      Add Card
                    </Button>
                  )}
                  {e.title === "Reading Material" && moduleNumber && (
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => createModuleReadingMaterialCard(index)}
                    >
                      Add Card
                    </Button>
                  )}
                  {e.title === "Self-Learning Exercise" && moduleNumber && (
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => createSelfLearningCard(index)}
                    >
                      Add Card
                    </Button>
                  )}
                  {(e.title === "What's new?" ||
                    e.title === "Broadcast Interview" ||
                    e.title === "Press Releases") && (
                      <Button
                        variant="contained"
                        component="label"
                        onClick={() => createPublicityCard(index)}
                      >
                        Add Card
                      </Button>
                    )}
                  {e.title === "Research Main Content" && (
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => createResearchCard(index)}
                    >
                      Add Card
                    </Button>
                  )}

                  {e.title ===
                    "Introduction To Acceptance And Commitment Therapy Session" &&
                    index != 0 && (
                      <Button
                        variant="contained"
                        component="label"
                        onClick={() => createWhatIsACTGestureCard(index)}
                      >
                        Add Card
                      </Button>
                    )}
                  {e.title === "Details of Upcoming Events" && (
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => createTrainingCard(index)}
                    >
                      Add Card
                    </Button>
                  )}
                  {e.title === "Details of Past Events" && (
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => createTrainingCard(index)}
                    >
                      Add Card
                    </Button>
                  )}
                  {e.title === "Jobs" && (
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => createJobsCard(index)}
                    >
                      Add Card
                    </Button>
                  )}
                  {e.title === "Card" && (
                    <Button
                      variant="contained"
                      component="label"
                      onClick={() => createCardCard(index)}
                    >
                      Add Card
                    </Button>
                  )}
                  {/* {id === "News" && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        component="label"
                        onClick={() => clearNewsImage()}
                      >
                        Clear
                      </Button>
                    </div>
                  )} */}
                </TreeItem>
              </TreeView>
            </div>
          );
        })}
      </div>
      <Button
        variant="contained"
        component="label"
        onClick={moveToTheTop}
        style={{
          position: "fixed",
          bottom: "75px",
          right: "15px",
          // cursor: "pointer",
        }}
      >
        <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
      </Button>

      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={handleCloseDetailsDialog}
          fullWidth
          fullScreen={fullScreen}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              aria-label="close"
              size="small"
              onClick={handleCloseDetailsDialog}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <DialogContent>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Content
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={parent}
                onChange={() => {
                  // console.log("todo");
                }}
                label="parent"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {templateList.templateList.map((e: any, idx: number) => {
                  return (
                    <MenuItem value={e} key={`template_${idx}`}>
                      {e}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TemplateDisplay selectedTemplate={""}></TemplateDisplay>
          </DialogContent>
        </Dialog>
      )}
      <br />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          component="label"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "15px",
          }}
          onClick={() => updatePageContent()}
          disabled={isDisabled}
        >
          Save
        </Button>
      </div>
      <br />
    </>
  );
};

export default PagesContent;
