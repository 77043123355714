import { Dialog, Button, useTheme, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import MUIDataTable from "mui-datatables";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Form, Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import API from "src/app/helper/ApiHelper.ts";
import FuseLoading from "@fuse/core/FuseLoading";

type FullMenu = {
  id: string;
  priority: number;
  title: string;
  parentTitle: string;
  displayTitle: string;
  displayTitleEng: string;
  url: string;
  parentID: string;
};

type ChildMenu = {
  id: string;
  title: string;
  url: string;
  priority: number;
  displayTitle: string;
  displayTitleEng: string;
};

type Menu = {
  id: string;
  title: string;
  url: string;
  priority: number;
  child: ChildMenu[];
};

type Page = { id: string; title: string; master: boolean; contentId: string };

const PagesList: React.FC = () => {
  const { id } = useParams();

  // const [pageList, setPageList] = useState([] as any);
  // const [isOpen, setIsOpen] = useState(false);
  // const [image, setImage] = useState() as any;
  // const [dialogContent, setDialogContent] = useState("");
  const [menuParent, setMenuParent] = useState({} as Menu);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([] as any);
  // const API = require("../../../../api.config.json");
  const token = Cookies.get("jwt_authorization") || "not_login";

  const endPoint = API.local;
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };
  const navigate = useNavigate();

  async function getCurrentPageList() {
    // console.log(id);
    setIsLoading(true);
    let currentPageWholeList = [] as Array<FullMenu>;
    const currentPage = `{
      menuBar(where: $where) {
        url
        title
        displayName
        displayNameEng
        priority
        id
        child(orderBy: $orderBy) {
          title
          priority
          displayName
          displayNameEng
          id
          url
        }
      }
    }`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [ChildMenuOrderByInput!]!) ${currentPage}`,
      variables: {
        where: { title: id },
        orderBy: [{ priority: "asc" }],
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        console.log(res);
        if (res.data.data.menuBar) {
          currentPageWholeList.push({
            id: res.data.data.menuBar.id,
            priority: res.data.data.menuBar.priority,
            title: res.data.data.menuBar.title,
            displayTitle: res.data.data.menuBar.displayName,
            displayTitleEng: res.data.data.menuBar.displayNameEng,
            url: res.data.data.menuBar.url,
            parentTitle: "",
            parentID: "",
          });
          res.data.data.menuBar.child.forEach((e: any) => {
            currentPageWholeList.push({
              id: e.id,
              priority: e.priority,
              title: e.title,
              displayTitle: e.displayName,
              displayTitleEng: e.displayNameEng,
              url: e.url,
              parentID: res.data.data.menuBar.id,
              parentTitle: res.data.data.menuBar.title,
            });
          });
          setMenuParent(res.data.data.menuBar);
          setData(currentPageWholeList);
          setIsLoading(false);
        } else {
          setMenuParent({} as Menu);
          setData([{}]);
          setIsLoading(false);
        }
      })

      .catch((err: any) => console.error(err));
  }

  async function getPageList() {
    const allPage = `{menuBars(orderBy: $orderBy) {
        id,
        title,
        url,
        priority,
        parentContent {
            id
        }
        child(orderBy: $childOrderBy) {
          id,
          title,
          url,
          priority,
          childContent {
            id
         }
        }
      }
    }`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($orderBy: [MenuBarOrderByInput!]!, $childOrderBy:[ChildMenuOrderByInput!]!) ${allPage}`,
      variables: {
        orderBy: [
          {
            priority: "asc",
          },
        ],
        childOrderBy: [
          {
            priority: "asc",
          },
        ],
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(res.data.data);
        setMenuParent(res.data.data.menuBars);
        let wholeList = [] as Page[];
        res.data.data.menuBars.forEach((e: any) => {
          if (e.child.length < 1) {
            wholeList.push({
              id: e.id,
              title: e.title,
              master: true,
              contentId: e.parentContent,
            });
          } else {
            wholeList.push({
              id: e.id,
              title: e.title,
              master: true,
              contentId: e.parentContent,
            });
            e.child.forEach((c: any) => {
              wholeList.push({
                id: c.id,
                title: c.title,
                master: false,
                contentId: c.childContent,
              });
            });
          }
        });
        // setPageList(wholeList);
        setData(wholeList);
      })
      .catch((err) => console.error(err));
  }

  async function displayPageDetails(page: Page) {
    if (!page.contentId && page.master === false) {
      const request = `{createPageContent(data: $data) {
            title,
            belongToChild {
                id
            }
        }}`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($data: PageContentCreateInput!) ${request}`,
        variables: {
          data: {
            title: page.title,
            belongToChild: { connect: { id: page.id } },
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => getPageList())
        .catch((err) => console.error(err));
    } else if (!page.contentId && page.master === true) {
      const request = `{createPageContent(data: $data) {
            title,
            belongToParent {
                id
            }
        }}`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($data: PageContentCreateInput!) ${request}`,
        variables: {
          data: {
            title: page.title,
            belongToParent: { connect: { id: page.id } },
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => getPageList())
        .catch((err) => console.error(err));
    } else {
      // console.log("already existed");
    }
    // setIsOpen(true);
    // if (page.master) {
    //   const request = `{menuBar(where: $where){
    //         parentContent {
    //             contents {
    //                 content,
    //                 category,
    //                 id,
    //                 priority,
    //             }
    //             images {
    //                 url,
    //                 category,
    //                 priority,
    //                 id
    //             }
    //             backgroundImage,
    //             pattern,
    //             title,
    //             id
    //         }
    //     }}`;
    //   const graphqlQuery = {
    //     operationName: "Query",
    //     query: `query Query($where: MenuBarWhereUniqueInput!) ${request}`,
    //     variables: {
    //       where: {
    //         id: page.id,
    //       },
    //     },
    //   };
    //   axios({
    //     url: endPoint,
    //     method: "post",
    //     data: graphqlQuery,
    //     headers: headers,
    //   })
    //     .then((res) => console.log(res.data.data))
    //     .catch((err) => console.log(err));
    // } else {
    //   const request = `{
    //         childMenu(where: $where) {
    //           childContent {
    //             title,
    //             pattern,
    //             backgroundImage,
    //             id,
    //             contents {
    //               priority,
    //               id,
    //               content,
    //               category
    //             }
    //             images {
    //               url,
    //               priority,
    //               id,
    //               category
    //             }
    //           }
    //         }
    //       }`;
    //   const graphqlQuery = {
    //     operationName: "Query",
    //     query: `query Query($where: ChildMenuWhereUniqueInput!) ${request}`,
    //     variables: {
    //       where: {
    //         id: page.id,
    //       },
    //     },
    //   };
    //   axios({
    //     url: endPoint,
    //     method: "post",
    //     data: graphqlQuery,
    //     headers: headers,
    //   })
    //     .then((res) => console.log(res.data.data))
    //     .catch((err) => console.log(err));
    // }
  }

  useEffect(() => {
    // getPageList();
    getCurrentPageList();
  }, [id]);

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  const columns = [
    {
      label: "Title",
      name: "displayTitleEng",
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <div
              onClick={() => {
                //   displayPageDetails(e);
                navigate(`/PagesContent/${data[dataIndex].title}`);
              }}
              style={{ cursor: "pointer" }}
            >
              {data[dataIndex].displayTitleEng || data[dataIndex].title}
            </div>
          );
        },
      },
    },
  ];

  const [addDialogTitle, setAddDialogTitle] = useState("");
  const [addDialogUrl, setAddDialogUrl] = useState("");
  const [parent, setParent] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  async function addMenuItem() {
    // console.log(menuParent);
    const priority = (): number => {
      if (menuParent.child) {
        return menuParent.child[menuParent.child.length - 1].priority + 1;
      } else {
        return 1;
      }
    };
    const request = `{createChildMenu(data: $data){
        title,
        url,
        priority,
        parent {
          id
        }
      }}`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($data: ChildMenuCreateInput!) ${request}`,
      variables: {
        data: {
          parent: { connect: { id: menuParent.id } },
          title: addDialogTitle,
          url: addDialogUrl,
          priority: priority(),
        },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {})
      .catch((err) => console.error("err", err));
  }

  const [dialogData, setDialogData] = useState({} as FullMenu);
  const [openDialog, setOpenDialog] = useState(false);

  function handleOpenDialog() {
    // console.log("open");
    setDialogData({
      id: "",
      priority: 0,
      title: "",
      displayTitle: "",
      displayTitleEng: "",
      url: "",
      parentTitle: "",
      parentID: "",
    });
    setOpenDialog(true);
  }

  const handleAddDialogChange = (event) => {
    setParent(event.target.value);
  };

  return (
    <>
      <div
        style={{
          marginTop: "50px",
          maxWidth: "1600px",
          marginLeft: "25px",
          marginRight: "10px",
        }}
      >
        <Typography
          sx={{ fontSize: 18, marginLeft: "15px" }}
          color="text.secondary"
          gutterBottom
        >
          CMS - {id}
        </Typography>

        <Dialog
          open={isLoading}
          // onClose={handleCloseLoading}
          fullWidth
          // style={{ width: "100px", height: "100px" }}
        >
          <FuseLoading />
        </Dialog>
        <MUIDataTable
          columns={columns}
          data={data}
          title={""}
          options={{
            selectableRows: "none",
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15],
            download: false,
            print: false,
            responsive: "standard",
            // search: false,
          }}
        ></MUIDataTable>
      </div>
      {openDialog && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          fullScreen={fullScreen}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => handleCloseDialog()}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <DialogContent>
            <Formik onSubmit={addMenuItem} initialValues={dialogData}>
              <Form>
                <TextField
                  autoFocus
                  margin="dense"
                  id="title"
                  label="Title"
                  type="text"
                  name="text"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAddDialogTitle(e.target.value)}
                />
                <TextField
                  margin="dense"
                  id="url"
                  label="Url"
                  type="text"
                  name="url"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAddDialogUrl(e.target.value)}
                />
                {menuParent.title && (
                  <TextField
                    margin="dense"
                    id="parent"
                    label="Category"
                    type="text"
                    name="parent"
                    fullWidth
                    variant="standard"
                    value={menuParent.title}
                  />
                )}

                <Button type="submit">submit</Button>
              </Form>
            </Formik>
          </DialogContent>
        </Dialog>
      )}
      {/* {pageList.map((e: Page, idx: number) => {
        return (
          <div>
            <button
              key={idx}
              onClick={() => {
                //   displayPageDetails(e);
                navigate(`/PageContent/${e.title}`);
              }}
            >
              {e.title}
            </button>
          </div>
        );
      })} */}
      {/* {isOpen && (
        <Dialog open={isOpen} onClose={handleCloseDialog}>
          <form
            onSubmit={(e) => {
              submitUpload(e);
            }}
          >
            <div onClick={handleCloseDialog}>close</div>
            <input
              value={dialogContent}
              onChange={(e) => setDialogContent(e.target.value)}
            ></input>
            <input
              type="file"
              name="photo"
              onChange={(e) => setImage(e.target.files?.[0])}
            ></input>
            <input onChange={(e) => console.log(e)}></input>
            <input onChange={(e) => console.log(e)}></input>
            <button type="submit"></button>
          </form>
        </Dialog>
      )} */}
    </>
  );
};

export default PagesList;
