import {
  Button,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "src/app/helper/ApiHelper.ts";

const ForgotPassword: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const endPoint = API.local;
  const [info, setInfo] = useState({} as any);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);
  const [rePasswordValid, setRePasswordValid] = useState(true);

  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  function checkTimeAndUser() {
    const request = `{
        forgotPassword(where: $where) {
          id
          requestTime
          senderEmail
        }
      }`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: ForgotPasswordWhereUniqueInput!) ${request}`,
      variables: {
        where: { id: id },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
    })
      .then((res) => {
        let now = Date.now();
        // console.log(
        //   now - new Date(res.data.data.forgotPassword.requestTime).getTime() <=
        //     15 * 60 * 1000
        // );
        if (
          now - new Date(res.data.data.forgotPassword.requestTime).getTime() <=
          15 * 60 * 1000
        ) {
          setInfo(res.data.data.forgotPassword);
          setEmail(res.data.data.senderEmail);
        } else {
          alert("token expired");
        }
      })
      .catch((err) => console.error("err", err));
  }

  useEffect(() => {
    checkTimeAndUser();
  }, []);

  function openDialog() {
    setIsOpen(true);
  }

  function closeDialog() {
    setIsOpen(false);
  }

  function changePassword() {
    //console.log(info.senderEmail);
    if (passwordValid && rePasswordValid) {
      // console.log("hi");
      if (info.senderEmail === "") {
        alert("Email cannot be empty");
        return;
      }
      if (newPassword != reNewPassword) {
        alert("Please confirm your password");
        return;
      }
      const request = `{
          updateWebUser(where: $where, data: $data) {
            password {
              isSet
            }
            email
          }
        }`;
      const graphqlQuery = {
        operationName: "UpdateWebUser",
        query: `mutation UpdateWebUser($where: WebUserWhereUniqueInput!, $data: WebUserUpdateInput!) ${request}`,
        variables: {
          where: { email: info.senderEmail },
          data: {
            password: newPassword,
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => {
          // console.log(res);
          if (res.data.errors) {
            alert("User does not exist");
          } else {
            alert("Password is updated");
          }
        })
        .catch((err) => console.error(err));
    } else {
      // console.log("bye");
    }
  }

  return (
    <>
      {/* <Dialog
        open={isOpen}
        onClose={closeDialog}
        fullWidth
        // style={{ width: "100px", height: "100px" }}
      > */}
      <div></div>
      <br />
      <br />
      <Typography
        sx={{
          fontSize: 18,
          marginLeft: "15px",
          position: "relative",
          left: "25%",
        }}
        color="text.secondary"
        gutterBottom
      >
        Forget Password
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "50%",
          marginLeft: "25%",
        }}
      >
        <br />
        <br />
        <TextField
          value={info.senderEmail || ""}
          className="mb-24"
          label="Email"
          autoFocus
          type="text"
          //   error={!!errors.email}
          //   helperText={errors?.email?.message}
          variant="outlined"
          required
          fullWidth
          style={{ marginBottom: "15px" }}
        />
        <TextField
          value={newPassword}
          className="mb-24"
          label="Enter New Password"
          autoFocus
          type="password"
          //   error={!!errors.email}
          //   helperText={errors?.email?.message}
          variant="outlined"
          required
          fullWidth
          style={{ marginBottom: "15px" }}
          onChange={(e) => {
            setNewPassword(e.target.value);
            const passwordRegex =
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            setPasswordValid(passwordRegex.test(e.target.value));
          }}
        />
        {!passwordValid && (
          <>
            <p style={{ color: "red", marginLeft: "10px" }}>
              Use 8 or more characters with a mix of upper & lowercase letters,
              numbers & symbols.
            </p>
            <br />
          </>
        )}
        <TextField
          value={reNewPassword}
          className="mb-24"
          label="Confirm Password"
          autoFocus
          type="password"
          //   error={!!errors.email}
          //   helperText={errors?.email?.message}
          variant="outlined"
          required
          fullWidth
          style={{ marginBottom: "15px" }}
          onChange={(e) => {
            setReNewPassword(e.target.value);
            setRePasswordValid(e.target.value === newPassword);
          }}
        />
        {!rePasswordValid && (
          <>
            <p style={{ color: "red", marginLeft: "10px" }}>
              Password is not match
            </p>

            <br />
          </>
        )}
        {/* <DialogActions> */}
        <Button onClick={() => changePassword()} autoFocus>
          Submit
        </Button>
      </div>
      {/* </DialogActions> */}
      {/* </Dialog> */}
      {/* <Button onClick={() => openDialog()}>Forgot Password</Button> */}
    </>
  );
};

export default ForgotPassword;
