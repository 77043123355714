import React from "react";
import { useState, useEffect } from "react";
import "./Alert.css";
import { boolean } from "yup";



function AlertDialog({ message, duration }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setIsOpen(true);
      const timeoutId = setTimeout(() => {
        setIsOpen(false);
      }, duration);
      return () => clearTimeout(timeoutId);
    }
  }, [message, duration]);

  return (
    <>
      {isOpen && (
        <div className="alert-dialog-container">
          <div className="alert-dialog">
            <div className="alert-content">
              <p>{message}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AlertDialog;
