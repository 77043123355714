import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import Cookies from "js-cookie";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Dialog, useTheme } from "@mui/material";
import "./MenuBar.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field } from "formik";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import API from "src/app/helper/ApiHelper.ts";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";

type Menu = {
  id: string;
  title: string;
  url: string;
  priority: number;
  displayName: string;
  displayNameEng: string;
  child: ChildMenu[];
};

type FullMenu = {
  id: string;
  priority: number;
  title: string;
  parentTitle: string;
  url: string;
  parentID: string;
  displayName: string;
  displayNameEng: string;
};

type Option = {
  value: string;
  label: string;
  child?: ChildMenu[];
};

type ChildMenu = {
  id: string;
  title: string;
  url: string;
  priority: number;
  displayName: string;
  displayNameEng: string;
};

type DropDownItem = {
  value: string;
  label: string;
  child: ChildMenu[];
};

const MenuBar: React.FC = () => {
  const [menuParent, setMenuParent] = useState<[Menu]>([] as any);
  const [wholeMenu, setWholeMenu] = useState<[FullMenu]>([] as any);

  //Dialog
  const [isOpen, setIsOpen] = useState(false);
  const [detailDialogData, setDetailDialogData] = useState({} as FullMenu);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const token = Cookies.get("jwt_authorization") || "not_login";
  // const API = require("../../../../api.config.json");
  const endPoint = API.local;
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };

  const navigate = useNavigate();

  const currentUserInfo: any = jwtDecode(token);

  async function menuTitle() {
    const AllMenuItems = `{menuBars(orderBy: $orderBy) {
        id,
        title,
        url,
        priority,
        child(orderBy: $childOrderBy) {
          id,
          title,
          url,
          priority,
          displayName,
        displayNameEng,
        },
        displayName,
        displayNameEng,
      }
    }`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($orderBy: [MenuBarOrderByInput!]!, $childOrderBy:[ChildMenuOrderByInput!]!) ${AllMenuItems}`,
      variables: {
        orderBy: [
          {
            priority: "asc",
          },
        ],
        childOrderBy: [
          {
            priority: "asc",
          },
        ],
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        setMenuParent(res.data.data.menuBars);
        let sortedList = res.data.data.menuBars.map((e: Menu) => {
          return e;
        });
        let wholeList = [] as any;
        for (let i = 0; i < sortedList.length; i++) {
          if (sortedList[i].child.length >= 1) {
            wholeList.push({
              id: sortedList[i].id,
              priority: sortedList[i].priority,
              title: sortedList[i].title,
              url: sortedList[i].url,
              parentTitle: "",
              parentID: "",
              displayName: sortedList[i].displayName,
              displayNameEng: sortedList[i].displayNameEng,
            });
            sortedList[i].child.forEach((e: any) => {
              wholeList.push({
                id: e.id,
                priority: sortedList[i].priority + "." + e.priority,
                title: e.title,
                url: e.url,
                parentTitle: sortedList[i].title,
                parentID: sortedList[i].id,
                displayName: e.displayName,
                displayNameEng: e.displayNameEng,
              });
            });
          } else {
            wholeList.push({
              id: sortedList[i].id,
              priority: sortedList[i].priority,
              title: sortedList[i].title,
              url: sortedList[i].url,
              parentTitle: "",
              parentID: "",
              displayName: sortedList[i].displayName,
              displayNameEng: sortedList[i].displayNameEng,
            });
          }
        }
        // console.log("whole", wholeList);
        setWholeMenu(wholeList);
      })
      .catch((err) => console.error("err", err));
  }
  useEffect(() => {
    if (currentUserInfo.role === "superAdmin") {
      menuTitle();
    } else {
      navigate("/404");
    }
  }, []);

  async function switchPriority(action: string, index: number) {
    if (action === "up") {
      if (
        wholeMenu[index].priority.toString().includes(".") &&
        wholeMenu[index - 1].priority.toString().includes(".")
      ) {
        const request = `{updateChildMenu(where: $where, data: $data){
          priority,
        }}`;
        const graphqlQuery = {
          operationName: "UpdateChildMenu",
          query: `mutation UpdateChildMenu($where: ChildMenuWhereUniqueInput!, $data: ChildMenuUpdateInput!) ${request}`,
          variables: {
            data: {
              priority: +wholeMenu[index - 1].priority.toString().split(".")[1],
            },
            where: { id: wholeMenu[index].id },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        })
          .then((res) => {
            const secRequest = `{updateChildMenu(where: $where, data: $data){
            priority,
          }}`;
            const secGraphqlQuery = {
              operationName: "UpdateChildMenu",
              query: `mutation UpdateChildMenu($where: ChildMenuWhereUniqueInput!, $data: ChildMenuUpdateInput!) ${secRequest}`,
              variables: {
                data: {
                  priority: +wholeMenu[index].priority.toString().split(".")[1],
                },
                where: { id: wholeMenu[index - 1].id },
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: secGraphqlQuery,
              headers: headers,
            })
              .then((res) => menuTitle())
              .catch((err) => console.error("err", err));
          })
          .catch((err) => console.error("err", err));
      } else {
        if (!wholeMenu[index].priority.toString().includes(".")) {
          for (let i = 0; i < menuParent.length; i++) {
            if (menuParent[i].priority === wholeMenu[index].priority) {
              if (!menuParent[i - 1]) {
                return;
              }
              const request = `{updateMenuBar(where: $where, data: $data){
                priority,
              }}`;
              const graphqlQuery = {
                operationName: "UpdateMenuBar",
                query: `mutation UpdateMenuBar($where: MenuBarWhereUniqueInput!, $data: MenuBarUpdateInput!) ${request}`,
                variables: {
                  data: { priority: +menuParent[i - 1].priority },
                  where: { id: wholeMenu[index].id },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              })
                .then((res) => {
                  console.log(res);
                  const request = `{updateMenuBar(where: $where, data: $data){
                  priority,
                }}`;
                  const graphqlQuery = {
                    operationName: "UpdateMenuBar",
                    query: `mutation UpdateMenuBar($where: MenuBarWhereUniqueInput!, $data: MenuBarUpdateInput!) ${request}`,
                    variables: {
                      data: { priority: +wholeMenu[index].priority },
                      where: { id: menuParent[i - 1].id },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((res) => menuTitle())
                    .catch((err) => console.error("err", err));
                })
                .catch((err) => console.error("err", err));
            }
          }
        } else {
          return;
        }
      }
      // console.log("current", wholeMenu[index], "target", wholeMenu[index - 1]);
    } else if (action === "down") {
      if (
        wholeMenu[index].priority.toString().includes(".") &&
        wholeMenu[index + 1].priority.toString().includes(".")
      ) {
        const request = `{updateChildMenu(where: $where, data: $data){
          priority,
        }}`;
        const graphqlQuery = {
          operationName: "UpdateChildMenu",
          query: `mutation UpdateChildMenu($where: ChildMenuWhereUniqueInput!, $data: ChildMenuUpdateInput!) ${request}`,
          variables: {
            data: {
              priority: +wholeMenu[index + 1].priority.toString().split(".")[1],
            },
            where: { id: wholeMenu[index].id },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        })
          .then((res) => {
            const secRequest = `{updateChildMenu(where: $where, data: $data){
            priority,
          }}`;
            const secGraphqlQuery = {
              operationName: "UpdateChildMenu",
              query: `mutation UpdateChildMenu($where: ChildMenuWhereUniqueInput!, $data: ChildMenuUpdateInput!) ${secRequest}`,
              variables: {
                data: {
                  priority: +wholeMenu[index].priority.toString().split(".")[1],
                },
                where: { id: wholeMenu[index + 1].id },
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: secGraphqlQuery,
              headers: headers,
            })
              .then((res) => menuTitle())
              .catch((err) => console.error("err", err));
          })
          .catch((err) => console.error("err", err));
      } else {
        if (!wholeMenu[index].priority.toString().includes(".")) {
          for (let i = 0; i < menuParent.length; i++) {
            if (menuParent[i].priority === wholeMenu[index].priority) {
              if (!menuParent[i + 1]) {
                return;
              }
              // console.log(menuParent[i + 1].priority);
              const request = `{updateMenuBar(where: $where, data: $data){
                priority,
              }}`;
              const graphqlQuery = {
                operationName: "UpdateMenuBar",
                query: `mutation UpdateMenuBar($where: MenuBarWhereUniqueInput!, $data: MenuBarUpdateInput!) ${request}`,
                variables: {
                  data: { priority: +menuParent[i + 1].priority },
                  where: { id: wholeMenu[index].id },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              })
                .then((res) => {
                  // console.log(res);
                  const request = `{updateMenuBar(where: $where, data: $data){
                  priority,
                }}`;
                  const graphqlQuery = {
                    operationName: "UpdateMenuBar",
                    query: `mutation UpdateMenuBar($where: MenuBarWhereUniqueInput!, $data: MenuBarUpdateInput!) ${request}`,
                    variables: {
                      data: { priority: +wholeMenu[index].priority },
                      where: { id: menuParent[i + 1].id },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((res) => menuTitle())
                    .catch((err) => console.error("err", err));
                })
                .catch((err) => console.error("err", err));
            }
          }
        }
      }
    }
  }
  // update dialog
  const [updateTitle, setUpdateTitle] = useState("");
  const [updateUrl, setUpdateUrl] = useState("");
  const [updateDisplayName, setUpdateDisplayName] = useState("");
  const [updateDisplayNameEng, setUpdateDisplayNameEng] = useState("");
  function handleOpenUpdateMenu(arrayIndex: number) {
    // console.log("hi?");
    let arrayNumber: any;
    // console.log(wholeMenu[arrayIndex]);
    // console.log(menuParent);
    if (wholeMenu[arrayIndex].parentID) {
      // console.log("?????");
      for (let menu in menuParent) {
        if (menuParent[menu].id === wholeMenu[arrayIndex].parentID) {
          arrayNumber = menu;
        }
      }
      // console.log(arrayNumber);

      setUpdateTitle(wholeMenu[arrayIndex].title);
      setUpdateUrl(wholeMenu[arrayIndex].url);
      setUpdateDisplayName(wholeMenu[arrayIndex].displayName);
      setUpdateDisplayNameEng(wholeMenu[arrayIndex].displayNameEng);
      setUpdateParent(wholeMenu[arrayIndex].parentID + "+" + arrayNumber);
      // console.log(wholeMenu[arrayIndex].parentID + "+" + arrayNumber);
      setDetailDialogData({
        id: wholeMenu[arrayIndex].id,
        title: wholeMenu[arrayIndex].title,
        parentTitle: wholeMenu[arrayIndex].parentTitle,
        priority: +wholeMenu[arrayIndex].priority.toString().split(".")[1],
        url: wholeMenu[arrayIndex].url,
        parentID: wholeMenu[arrayIndex].parentID,
        displayName: wholeMenu[arrayIndex].displayName,
        displayNameEng: wholeMenu[arrayIndex].displayNameEng,
      });
    } else {
      setUpdateTitle(wholeMenu[arrayIndex].title);
      setUpdateUrl(wholeMenu[arrayIndex].url);
      setUpdateDisplayName(wholeMenu[arrayIndex].displayName);
      setUpdateDisplayNameEng(wholeMenu[arrayIndex].displayNameEng);
      setDetailDialogData({
        id: wholeMenu[arrayIndex].id,
        title: wholeMenu[arrayIndex].title,
        parentTitle: wholeMenu[arrayIndex].parentTitle,
        priority: +wholeMenu[arrayIndex].priority,
        url: wholeMenu[arrayIndex].url,
        parentID: wholeMenu[arrayIndex].parentID,
        displayName: wholeMenu[arrayIndex].displayName,
        displayNameEng: wholeMenu[arrayIndex].displayNameEng,
      });
    }
    setIsOpen(true);
  }

  const handleUpdateDialogChange = (event) => {
    setUpdateParent(event.target.value);
  };

  function handleCloseDetailsDialog() {
    setIsOpen(false);
  }

  async function updateMenu(originalData: FullMenu) {
    let childList = [];
    let childLength = 0;
    if (updateParent) {
      childLength = menuParent[+updateParent.split("+")[1]].child.length;
      childList = menuParent[+updateParent.split("+")[1]].child;
    }

    let priority: number;
    if (childList[childLength - 1]) {
      priority = childList[childLength - 1].priority + 1;
    } else {
      priority = 1;
    }
    if (originalData.parentID !== "") {
      if (originalData.parentID !== updateParent.split("+")[0]) {
        const request = `{updateChildMenu(where: $where, data: $data){
          title,
          url,
          priority,
          parent {
            id
          },
          displayName,
          displayNameEng
        }}`;
        const graphqlQuery = {
          operationName: "UpdateChildMenu",
          query: `mutation UpdateChildMenu($where: ChildMenuWhereUniqueInput!, $data: ChildMenuUpdateInput!) ${request}`,
          variables: {
            data: {
              title: updateTitle,
              url: updateUrl,
              priority: priority,
              parent: { connect: { id: updateParent.split("+")[0] } },
            },
            where: { id: originalData.id },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        })
          .then(
            (res) => {}
            // console.log(res.data)
          )
          .catch((err) => console.error("err", err));
      } else {
        const request = `{updateChildMenu(where: $where, data: $data){
        title,
        url,
        displayName,
        displayNameEng,
      }}`;
        const graphqlQuery = {
          operationName: "UpdateChildMenu",
          query: `mutation UpdateChildMenu($where: ChildMenuWhereUniqueInput!, $data: ChildMenuUpdateInput!) ${request}`,
          variables: {
            data: {
              title: updateTitle,
              url: updateUrl,
              displayName: updateDisplayName,
              displayNameEng: updateDisplayNameEng,
            },
            where: { id: originalData.id },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        })
          .then((res) => {})
          .catch((err) => console.log("err", err));
      }
    } else {
      const request = `{updateMenuBar(where: $where, data: $data){
        title,
        url,
        displayName,
        displayNameEng,
      }}`;
      const graphqlQuery = {
        operationName: "UpdateMenuBar",
        query: `mutation UpdateMenuBar($where: MenuBarWhereUniqueInput!, $data: MenuBarUpdateInput!) ${request}`,
        variables: {
          data: {
            title: updateTitle,
            url: updateUrl,
            displayName: updateDisplayName,
            displayNameEng: updateDisplayNameEng,
          },
          where: { id: originalData.id },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => console.log(res.data))
        .catch((err) => console.log("err", err));
    }
  }

  async function delMenu(menuIndex: number) {
    console.log(wholeMenu[menuIndex]);
    if (!wholeMenu[menuIndex].parentID) {
      const request = `{deleteMenuBar(where: $where){
        url,
        title,
        priority,
        id,
        child {
          title,
          priority,
          url,
          id
        }
      }}`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($where: MenuBarWhereUniqueInput!) ${request}`,
        variables: {
          where: { id: wholeMenu[menuIndex].id },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => console.log(res.data))
        .catch((err) => console.log("err", err));
    } else {
      const request = `{deleteChildMenu(where: $where){
        title,
        priority,
        url,
        id
      }}`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($where: ChildMenuWhereUniqueInput!) ${request}`,
        variables: {
          where: { id: wholeMenu[menuIndex].id },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => console.log(res.data))
        .catch((err) => console.log("err", err));
    }
  }
  //Add Dialog
  const [dialogData, setDialogData] = useState({} as FullMenu);
  const [openDialog, setOpenDialog] = useState(false);
  // const [parent, setParent] = useState({} as DropDownItem);
  const [parent, setParent] = useState("");
  const [updateParent, setUpdateParent] = useState("");

  const handleAddDialogChange = (event) => {
    setParent(event.target.value);
  };

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  function handleOpenDialog() {
    console.log("open");
    setDialogData({
      id: "",
      priority: 0,
      title: "",
      url: "",
      parentTitle: "",
      parentID: "",
      displayName: "",
      displayNameEng: "",
    });
    setOpenDialog(true);
  }

  //create new MenuItem
  const [addDialogTitle, setAddDialogTitle] = useState("");
  const [addDialogUrl, setAddDialogUrl] = useState("");
  async function addMenuItem() {
    // console.log(parent.value);
    if (parent) {
      const priority = (): number => {
        console.log(parent);
        if (menuParent[+parent.split("+")[1]].child.length <= 0) {
          return 1;
        } else {
          return (
            +menuParent[+parent.split("+")[1]].child[
              +menuParent[+parent.split("+")[1]].child.length - 1
            ].priority + 1
          );
        }
      };
      const request = `{createChildMenu(data: $data){
        title,
        url,
        priority,
        parent {
          id
        }
      }}`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($data: ChildMenuCreateInput!) ${request}`,
        variables: {
          data: {
            parent: { connect: { id: menuParent[+parent.split("+")[1]].id } },
            title: addDialogTitle,
            url: addDialogUrl,
            priority: priority(),
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => {
          console.log("res", res);
          menuTitle();
        })
        .catch((err) => console.log("err", err));
    } else {
      const priority = (): number => {
        if (menuParent.length <= 0) {
          return 1;
        } else {
          console.log(+menuParent[+menuParent.length - 1].priority + 1);
          return +menuParent[+menuParent.length - 1].priority + 1;
        }
      };
      const request = `{createMenuBar(data: $data){
              title,
              url,
              priority,
          }}`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($data: MenuBarCreateInput!) ${request}`,
        variables: {
          data: {
            title: addDialogTitle,
            url: addDialogUrl,
            priority: priority(),
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => console.log(res.data))
        .catch((err) => console.log("err", err));
    }
  }

  const columns = [
    {
      label: "Priority",
      name: "priority",
    },
    { label: "Category Title", name: "title" },
    { label: "Display Name (繁)", name: "displayName" },

    { label: "Display Name (En)", name: "displayNameEng" },

    { label: "Category Heading", name: "parentTitle" },
    {
      label: "Position",
      name: "position",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <div className="upDownContainer">
              <div
                className="upDownButton"
                onClick={() => {
                  switchPriority("up", dataIndex);
                }}
              >
                <ArrowCircleUpIcon />
              </div>
              <div
                className="upDownButton"
                onClick={() => {
                  switchPriority("down", dataIndex);
                }}
              >
                <ArrowCircleDownIcon />
              </div>
            </div>
          );
        },
      },
    },
    {
      label: "Action",
      name: "action",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <div className="dataTable-button">
              {/* <button
                  className="actionButton"
                  onClick={() => handleOpenUpdateMenu(dataIndex)}
                >
                  Edit
                </button> */}
              <div className="actionButton">
                <div className="trueActionButton">
                  <ModeEditOutlineIcon
                    onClick={() => handleOpenUpdateMenu(dataIndex)}
                  />
                </div>
              </div>
              {/* <button
                  className="actionButton"
                  onClick={() => delMenu(dataIndex)}
                >
                  Delete
                </button> */}
              <div className="actionButton">
                <div className="trueActionButton">
                  <DeleteIcon onClick={() => delMenu(dataIndex)} />
                </div>
              </div>
            </div>
          );
        },
      },
    },
  ];
  return (
    <>
      <div
        style={{
          marginTop: "50px",
          maxWidth: "1600px",
          marginLeft: "25px",
          marginRight: "10px",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            aria-label="add"
            size="small"
            onClick={() => handleOpenDialog()}
          >
            <AddIcon />
          </IconButton>
        </Stack>
        <MUIDataTable
          columns={columns}
          data={wholeMenu}
          title={""}
          options={{
            selectableRows: "none",
            rowsPerPage: 6,
            rowsPerPageOptions: [6],
            download: false,
            print: false,
            responsive: "standard",
            // search: false,
          }}
        ></MUIDataTable>
      </div>
      {openDialog && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          fullScreen={fullScreen}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => handleCloseDialog()}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <DialogContent>
            <Formik onSubmit={addMenuItem} initialValues={dialogData}>
              <Form>
                <TextField
                  autoFocus
                  margin="dense"
                  id="title"
                  label="Title"
                  type="text"
                  name="text"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAddDialogTitle(e.target.value)}
                />
                <TextField
                  margin="dense"
                  id="url"
                  label="Url"
                  type="text"
                  name="url"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAddDialogUrl(e.target.value)}
                />

                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={parent}
                    onChange={handleAddDialogChange}
                    label="parent"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {menuParent.map((e, idx) => {
                      return (
                        <MenuItem value={`${e.title + "+" + idx}`} key={e.id}>
                          {e.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Button type="submit">submit</Button>
              </Form>
            </Formik>
          </DialogContent>
        </Dialog>
      )}
      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={handleCloseDetailsDialog}
          fullScreen={fullScreen}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => handleCloseDetailsDialog()}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <DialogContent>
            <Formik
              onSubmit={(values) => {
                updateMenu(detailDialogData);
              }}
              initialValues={detailDialogData}
            >
              <Form>
                <TextField
                  autoFocus
                  margin="dense"
                  id="title"
                  label="Title"
                  type="text"
                  name="text"
                  fullWidth
                  variant="standard"
                  value={updateTitle}
                  onChange={(e) => setUpdateTitle(e.target.value)}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="displayName"
                  label="Display Name (繁)"
                  type="text"
                  name="text"
                  fullWidth
                  variant="standard"
                  value={updateDisplayName}
                  onChange={(e) => setUpdateDisplayName(e.target.value)}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="displayNameEng"
                  label="Display Name (En)"
                  type="text"
                  name="text"
                  fullWidth
                  variant="standard"
                  value={updateDisplayNameEng}
                  onChange={(e) => setUpdateDisplayNameEng(e.target.value)}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="url"
                  label="url"
                  type="text"
                  name="text"
                  fullWidth
                  variant="standard"
                  value={updateUrl}
                  onChange={(e) => setUpdateUrl(e.target.value)}
                />
                {detailDialogData.parentID !== "" && (
                  <FormControl variant="standard" sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={updateParent}
                      defaultValue={updateParent}
                      onChange={handleUpdateDialogChange}
                      label="parent"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {menuParent.map((e, idx) => {
                        return (
                          <MenuItem
                            value={`${e.id + "+" + idx}`}
                            key={`child_${e.id}`}
                          >
                            {e.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
                <Button type="submit">submit</Button>
              </Form>
            </Formik>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default MenuBar;
