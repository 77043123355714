const createParentPageContentRequest = `{createPageContent(data: $data){
    id
    title,
    session,
    sessionImage,
    template,
    belongToParent {
        id
    }
}}`;

const createChildPageContentRequest = `{createPageContent(data: $data){
    id
    title,
    session,
    sessionImage,
    template,
    belongToChild {
        id
    }
}}`;

const createContentDetailWithLanguageRequest = `{
    createContentDetail(data: $data) {
        priority
        position
        content
        category
        master {
          id
        }
        title
        langLabel{
          id
        }
        type
    }
}`;

const createContentDetailRequest = `{
    createContentDetail(data: $data) {
        priority
        position
        content
        category
        type
        master {
          id
        }
        title
       
    }
}`;

const createImageDetailRequest = `{createImageDetail(data:$data){
    url
    category
    priority
    master{
      id
    }
    position
    title
    type
    }}`;

const createVideoDetailRequest = `{createVideoDetail(data:$data){
    url
    category
    priority
    master{
      id
    }
    position
    title
    type
  }}`;

const createAudioDetailRequest = `{createAudioDetail(data: $data){
    url
    category
    priority
    master{
      id
    }
    position
    title
    type
  }}`;

const updateContentDetailRequest = `{
    updateContentDetail(where: $where, data: $data){
    content
    priority
    id
    type
  }
}`;

const updateImageDetailRequest = `{
    updateImageDetail(where: $where, data: $data){
      url
      priority
      id
    }
  }`;

const updateVideoDetailRequest = `{
    updateVideoDetail(where: $where, data: $data){
      url
      priority
      id
    }
  }`;

const createPDFDetailRequest = `{createPdfDetail(data: $data){
    url
    category
    priority
    master{
      id
    }
    position
    title
    type
    originalFileName
  }}`;

const updateAudioDetailRequest = `{
    updateAudioDetail(where: $where, data: $data){
      url
      priority
      id
    }
  }`;

const updatePDFDetailRequest = `{
    updatePdfDetail(where: $where, data: $data){
      url
      priority
      id
      originalFileName
    }
  }`;

export {
  createParentPageContentRequest,
  createChildPageContentRequest,
  createContentDetailWithLanguageRequest,
  createContentDetailRequest,
  createImageDetailRequest,
  createVideoDetailRequest,
  createAudioDetailRequest,
  createPDFDetailRequest,
  updateContentDetailRequest,
  updateImageDetailRequest,
  updateVideoDetailRequest,
  updateAudioDetailRequest,
  updatePDFDetailRequest,
};
