import { PhotoCamera } from "@mui/icons-material";
import {
  Card,
  TextField,
  CardMedia,
  Button,
  IconButton,
  Avatar,
  Stack,
  CardContent,
  Box,
  CardActions,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditorDraft from "../Components/EditorDraft.tsx";
import CloseIcon from "@mui/icons-material/Close";
import FuseLoading from "@fuse/core/FuseLoading";
import API from "src/app/helper/ApiHelper.ts";
import AlertDialog from "../Components/Alert.tsx";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import compressImage from "browser-image-compression";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface TextFieldElement extends HTMLElement {
  value: string;
}

type AwardInfo = {
  id: string;
  engID: string;
  yearID: string;
  contextID: string;
  year: string;
  context: string;
  contextEng: string;
  yearEng: string;
  contextEngID: string;
  yearEngID: string;
  position: string;
};

type ContextInfo = {
  id: string;
  context: string;
};

const TeamInChange: React.FC = () => {
  const dispatch = useDispatch();

  const [previewUrl, setPreviewUrl] = useState(null);
  const [muDisplayImage, setMuDisplayImage] = useState("");
  const [awardList, setAwardList] = useState([]);
  const [master, setMaster] = useState(false);
  const [session, setSession] = useState() as any;
  const { id } = useParams();
  // const API = require("../../../../api.config.json");
  const templateList = require("../../../../template.json");

  const token = Cookies.get("jwt_authorization") || "not_login";
  const endPoint = API.local;
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  const [languageList, setLanguageList] = useState([] as any);

  async function getLangLabel() {
    const request = `{languages{
      lang
      index
      id
    }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query ${request}`,
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        setLanguageList(res.data.data.languages);
      })
      .catch((err) => console.error("err", err));
  }

  function addAward() {
    try {
      let currentReward = awardList.length;
      setAwardList([
        ...awardList,
        {
          id: "reward+" + currentReward + 1,
          engID: "rewardEng" + currentReward + 1,
          yearID: "year+" + currentReward + 1,
          contextID: "newCreate+" + currentReward + 1,
          context: "",
          year: "",
          contextEng: "",
          yearEng: "",
          contextEngID: "newCreateEng+" + currentReward + 1,
          yearEngID: "yearEng+" + currentReward + 1,
          position: "",
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  }
  async function checkChildOrParent() {
    const check = `{menuBar(where: $where){
        title
    }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!) ${check}`,
      variables: {
        where: {
          title: id,
        },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        if (!res.data.data.menuBar) {
          const request = `{childMenu(where: $where){
            id,
            title
            childContent {
                title,
                session,
                template,
                images {
                    url,
                    priority,
                    id,
                    category
                }
                id
                contents(where: $contentsWhere2) {
                    category,
                    content,
                    id,
                    priority,
                    position,
                    isDeleted,
                    langLabel{
                      id
                      lang
                      index
                    }
                }
                sessionImage
            }
          }}`;
          const graphqlQuery = {
            operationName: "Query",
            query: `query Query($where: ChildMenuWhereUniqueInput!, $contentsWhere2: ContentDetailWhereInput!) ${request}`,
            variables: {
              where: { title: id },
              contentsWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              setSession(res.data.data.childMenu);
              // console.log(res.data.data.childMenu);
              let awardArr: [AwardInfo] = [] as any;
              let allIDsList: any = [] as any;
              if (res.data.data.childMenu.childContent[0]) {
                res.data.data.childMenu.childContent[0].contents.forEach(
                  (e: any, idx: number) => {
                    if (e.position.includes("ticAward")) {
                      allIDsList.push(e.position);
                    } else if (
                      e.position === "1stTitle" &&
                      e.langLabel[0].lang === "zh_hant"
                    ) {
                      setStTitle(e.content);
                      setOldStTitle({ id: e.id, context: e.content });
                    } else if (
                      e.position === "1stContext" &&
                      e.langLabel[0].lang === "zh_hant"
                    ) {
                      setStContext(e.content);
                      setOldStContext({ id: e.id, context: e.content });
                    } else if (
                      e.position === "2ndTitle" &&
                      e.langLabel[0].lang === "zh_hant"
                    ) {
                      setNdTitle(e.content);
                      setOldNdTitle({ id: e.id, context: e.content });
                    } else if (
                      e.position === "2ndContext" &&
                      e.langLabel[0].lang === "zh_hant"
                    ) {
                      setNdContext(e.content);
                      setOldNdContext({ id: e.id, context: e.content });
                    } else if (e.position === "TICEmail") {
                      setTicEmail(e.content);
                      setOldTicEmail({ id: e.id, context: e.content });
                    } else if (e.position === "TICPhone") {
                      setTicPhone(e.content);
                      setOldTicPhone({ id: e.id, context: e.content });
                    } else if (e.position === "TICLink") {
                      setTicLink(e.content);
                      setOldTicLink({ id: e.id, context: e.content });
                    } else if (
                      e.position === "TICAddress" &&
                      e.langLabel[0].lang === "zh_hant"
                    ) {
                      setTicAddress(e.content);
                      setOldTicAddress({ id: e.id, context: e.content });
                    } else if (
                      e.position === "AwardTitle" &&
                      e.langLabel[0].lang === "zh_hant"
                    ) {
                      setAwardTitle(e.content);
                      setOldAwardTitle({ id: e.id, context: e.content });
                    } else if (
                      e.position === "3rdContext" &&
                      e.langLabel[0].lang === "zh_hant"
                    ) {
                      setRdContext(e.content);
                      setOldRdContext({ id: e.id, context: e.content });
                    } else if (
                      e.position === "3rd2Context" &&
                      e.langLabel[0].lang === "zh_hant"
                    ) {
                      setRd2Context(e.content);
                      setOldRd2Context({ id: e.id, context: e.content });
                    }
                    // English version
                    else if (
                      e.position === "1stTitle" &&
                      e.langLabel[0].lang === "en"
                    ) {
                      setStTitleEng(e.content);
                      setOldStTitleEng({ id: e.id, context: e.content });
                    } else if (
                      e.position === "1stContext" &&
                      e.langLabel[0].lang === "en"
                    ) {
                      setStContextEng(e.content);
                      setOldStContextEng({ id: e.id, context: e.content });
                    } else if (
                      e.position === "2ndTitle" &&
                      e.langLabel[0].lang === "en"
                    ) {
                      setNdTitleEng(e.content);
                      setOldNdTitleEng({ id: e.id, context: e.content });
                    } else if (
                      e.position === "2ndContext" &&
                      e.langLabel[0].lang === "en"
                    ) {
                      setNdContextEng(e.content);
                      setOldNdContextEng({ id: e.id, context: e.content });
                    } else if (
                      e.position === "TICAddress" &&
                      e.langLabel[0].lang === "en"
                    ) {
                      setTicAddressEng(e.content);
                      setOldTicAddressEng({ id: e.id, context: e.content });
                    } else if (
                      e.position === "AwardTitle" &&
                      e.langLabel[0].lang === "en"
                    ) {
                      setAwardTitleEng(e.content);
                      setOldAwardTitleEng({ id: e.id, context: e.content });
                    } else if (
                      e.position === "3rdContext" &&
                      e.langLabel[0].lang === "en"
                    ) {
                      setRdContextEng(e.content);
                      setOldRdContextEng({ id: e.id, context: e.content });
                    } else if (
                      e.position === "3rd2Context" &&
                      e.langLabel[0].lang === "en"
                    ) {
                      setRd2ContextEng(e.content);
                      setOldRd2ContextEng({ id: e.id, context: e.content });
                    } else if (e.position === "TICTwitter") {
                      setTicTwitter(e.content);
                      setOldTicTwitter({ id: e.id, context: e.content });
                    } else if (e.position === "TICLinkedIn") {
                      setTicLinkedin(e.content);
                      setOldTicLinkedin({ id: e.id, context: e.content });
                    }
                  }
                );
              }
              if (res.data.data.childMenu.childContent[0]) {
                if (res.data.data.childMenu.childContent[0].images.length > 0) {
                  setMuDisplayImage(
                    `${API.getImage}${res.data.data.childMenu.childContent[0].images[0].url}`
                  );
                }
              }
              let totalIDsList = allIDsList.reduce((acc: any, curr: any) => {
                let key = curr;
                if (key in acc) {
                  acc[key]++;
                } else {
                  acc[key] = 1;
                }
                return acc;
              }, {});
              console.log(totalIDsList);
              for (const [key, value] of Object.entries(totalIDsList)) {
                awardArr.push({
                  id: "",
                  engID: "",
                  yearID: "",
                  contextID: "",
                  context: "",
                  year: "",
                  contextEng: "",
                  yearEng: "",
                  contextEngID: "",
                  yearEngID: "",
                  position: key,
                });
                console.log(awardArr);
              }
              if (res.data.data.childMenu.childContent[0]) {
                res.data.data.childMenu.childContent[0].contents.forEach(
                  (e: any, idx: number) => {
                    if (e.position.includes("ticAward")) {
                      awardArr.forEach((award: any, awardIDX: number) => {
                        if (
                          e.position === award.position &&
                          e.langLabel[0].lang === "zh_hant"
                        ) {
                          award.id = e.id;
                          award.yearID = `${
                            e.content.split("!@#$%^&*()_+")[1]
                          }+${idx + 1}`;
                          award.contextID = `existItem+${idx + 1}`;
                          award.context = e.content.split("!@#$%^&*()_+")[0];
                          award.year = e.content.split("!@#$%^&*()_+")[1];
                        } else if (
                          e.position === award.position &&
                          e.langLabel[0].lang === "en"
                        ) {
                          award.engID = e.id;
                          award.yearEngID = `ENGyear${
                            e.content.split("!@#$%^&*()_+")[1]
                          }+${idx + 1}`;
                          award.contextEngID = `ENGcontent${
                            e.content.split("!@#$%^&*()_+")[0]
                          }+${idx + 1}`;
                          award.contextEng = e.content.split("!@#$%^&*()_+")[0];
                          award.yearEng = e.content.split("!@#$%^&*()_+")[1];
                        }
                      });
                    }
                  }
                );
              }
              setAwardList(awardArr);
              setMaster(false);
            })
            .catch((err) => console.error("err", err));
        } else {
          const request = `{menuBar(where: $where) {
            id,
            title,
            parentContent {
              title
              session
              template
              images {
                url
                priority
                category
                id
              }
              id
              contents {
                priority
                id
                content
                category
                position
              }
              sessionImage
            }
          }
        }`;
          const graphqlQuery = {
            operationName: "Query",
            query: `query Query($where: MenuBarWhereUniqueInput!) ${request}`,
            variables: {
              where: { title: id },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              setMaster(true);
              setSession(res.data.data.menuBar);
            })
            .catch((err) => console.error("err", err));
        }
      })
      .catch((err) => console.error(err));
  }

  // for reload the page after any action user have take
  const [render, setRender] = useState(false);

  useEffect(() => {
    checkChildOrParent();
    getLangLabel();
  }, [render]);

  // for enter page scroll to the top
  const [firstEnter, setFirstEnter] = useState(true);

  useEffect(() => {
    // Check if the user has scrolled down
    // if (firstEnter) {

    // if (window.scrollY > 0) {
    //   console.log(window.scrollY);
    // If the user has scrolled down, reset the scroll position to the top of the page
    window.scrollTo({ top: 0, behavior: "instant" });
    // }
    // }
    setFirstEnter(false);

    // Call handleScroll to reset the scroll position when the component is mounted

    // Add an event listener to the window object to prevent scrolling
    // window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component is unmounted
    // window.removeEventListener("scroll", handleScroll);
  }, []);

  const [ticImage, setTicImage] = useState(null);
  const [stTitle, setStTitle] = useState("");
  const [oldStTitle, setOldStTitle] = useState({} as ContextInfo);
  const [stContext, setStContext] = useState("");
  const [oldStContext, setOldStContext] = useState({} as ContextInfo);
  const [ndTitle, setNdTitle] = useState("");
  const [oldNdTitle, setOldNdTitle] = useState({} as ContextInfo);
  const [ndContext, setNdContext] = useState("");
  const [oldNdContext, setOldNdContext] = useState({} as ContextInfo);
  const [rdContext, setRdContext] = useState("");
  const [oldRdContext, setOldRdContext] = useState({} as ContextInfo);
  const [rd2Context, setRd2Context] = useState("");
  const [oldRd2Context, setOldRd2Context] = useState({} as ContextInfo);
  const [ticEmail, setTicEmail] = useState("");
  const [oldTicEmail, setOldTicEmail] = useState({} as ContextInfo);
  const [ticPhone, setTicPhone] = useState("");
  const [oldTicPhone, setOldTicPhone] = useState({} as ContextInfo);
  const [ticAddress, setTicAddress] = useState("");
  const [oldTicAddress, setOldTicAddress] = useState({} as ContextInfo);
  const [awardTitle, setAwardTitle] = useState("");
  const [oldAwardTitle, setOldAwardTitle] = useState({} as ContextInfo);
  const [oldTicLink, setOldTicLink] = useState({} as ContextInfo);
  const [ticTwitter, setTicTwitter] = useState("");
  const [oldTicTwitter, setOldTicTwitter] = useState({} as ContextInfo);
  const [ticLinkedin, setTicLinkedin] = useState("");
  const [oldTicLinkedin, setOldTicLinkedin] = useState({} as ContextInfo);
  // English version
  const [stTitleEng, setStTitleEng] = useState("");
  const [oldStTitleEng, setOldStTitleEng] = useState({} as ContextInfo);
  const [stContextEng, setStContextEng] = useState("");
  const [oldStContextEng, setOldStContextEng] = useState({} as ContextInfo);
  const [ndTitleEng, setNdTitleEng] = useState("");
  const [oldNdTitleEng, setOldNdTitleEng] = useState({} as ContextInfo);
  const [ndContextEng, setNdContextEng] = useState("");
  const [oldNdContextEng, setOldNdContextEng] = useState({} as ContextInfo);
  const [rdContextEng, setRdContextEng] = useState("");
  const [oldRdContextEng, setOldRdContextEng] = useState({} as ContextInfo);
  const [rd2ContextEng, setRd2ContextEng] = useState("");
  const [oldRd2ContextEng, setOldRd2ContextEng] = useState({} as ContextInfo);
  // const [ticEmailEng, setTicEmailEng] = useState("");
  // const [oldTicEmailEng, setOldTicEmailEng] = useState({} as ContextInfo);
  // const [ticPhoneEng, setTicPhoneEng] = useState("");
  // const [oldTicPhoneEng, setOldTicPhoneEng] = useState({} as ContextInfo);
  const [ticAddressEng, setTicAddressEng] = useState("");
  const [ticLink, setTicLink] = useState("");
  const [oldTicAddressEng, setOldTicAddressEng] = useState({} as ContextInfo);
  const [awardTitleEng, setAwardTitleEng] = useState("");
  const [oldAwardTitleEng, setOldAwardTitleEng] = useState({} as ContextInfo);

  const handleFileSelect = async (event: any) => {
    const options = { maxSizeMB: 1, maxWidthOrHeight: 1500 };
    const file = await compressImage(event.target.files[0], options);
    // console.log(file);
    setTicImage(file);
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewUrl(reader.result);
      dispatch(
        showMessage({
          message: `Selected Image is not Save`,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "warning",
        })
      );
    };
    reader.readAsDataURL(file);
  };

  // Alert Pop up
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [popUpMsg, setPopUpMsg] = useState("");

  // due to structure problem this is a function will run every sec to check the value is true for turn off the loading screen
  function checkValues(values: any[]) {
    // console.log(values);
    return new Promise<void>((resolve, reject) => {
      if (values.every((value) => value === true)) {
        resolve();
      }
    });
  }

  const [isLoading, setIsLoading] = useState(false);

  async function modifyTICInfo() {
    setIsOpenAlert(false);

    // console.log(session);
    // console.log(session.childContent[0]);
    const chinese_zh_id = languageList.filter(
      (obj: any) => obj.lang === "zh_hant"
    )[0].id;
    const eng_id = languageList.filter((obj: any) => obj.lang === "en")[0].id;

    // this values are for checking update complete or not
    let imageUpdate = false;
    let p1TitleUpdate = false;
    let p1TitleEngUpdate = false;
    let p1ContentUpdate = false;
    let p1ContentEngUpdate = false;
    let p2TitleUpdate = false;
    let p2TitleEngUpdate = false;
    let p2ContentUpdate = false;
    let p2ContentEngUpdate = false;
    let p3ContentUpdate = false;
    let p3ContentEngUpdate = false;
    let emailUpdate = false;
    let phoneUpdate = false;
    let addressUpdate = false;
    let addressEngUpdate = false;
    let personalPortfolioLinkUpdate = false;
    let awardTitleUpdate = false;
    let awardTitleEngUpdate = false;
    let awardContentUpdate = false;
    let awardContentEngUpdate = false;

    setIsLoading(true);
    if (!oldTicLinkedin.id && !oldTicTwitter.id) {
      const newPersonalInfoRequest = `{createContentDetails(data: $data){
        content
        position
        category
        master {
          id
        }

      }}`;
      const newPersonalInfoGraphqlQuery = {
        operationName: "CreateContentDetails",
        query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${newPersonalInfoRequest}`,
        variables: {
          data: [
            {
              content: ticTwitter,
              position: "TICTwitter",
              category: id,
              master: {
                connect: { id: session.childContent[0].id },
              },
            },
            {
              content: ticLinkedin,
              position: "TICLinkedIn",
              category: id,
              master: {
                connect: { id: session.childContent[0].id },
              },
            },
          ],
        },
      };
      await axios({
        url: endPoint,
        method: "post",
        data: newPersonalInfoGraphqlQuery,
        headers: headers,
      });
    } else {
      if (oldTicLinkedin.context != ticLinkedin) {
        const updateLinkedIn = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateLinkedInGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateLinkedIn}`,
          variables: {
            data: {
              content: ticLinkedin,
            },
            where: {
              id: oldTicLinkedin.id,
            },
          },
        };
        await axios({
          url: endPoint,
          method: "post",
          data: updateLinkedInGraphqlQuery,
          headers: headers,
        });
      }
      if (oldTicTwitter.context != ticTwitter) {
        const updateTwitter = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateTwitterGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateTwitter}`,
          variables: {
            data: {
              content: ticTwitter,
            },
            where: {
              id: oldTicTwitter.id,
            },
          },
        };
        await axios({
          url: endPoint,
          method: "post",
          data: updateTwitterGraphqlQuery,
          headers: headers,
        });
      }
    }
    if (session.childContent[0]) {
      // console.log("start");

      if (ticImage) {
        let formData = new FormData();

        formData.append("image", ticImage);
        axios
          .post(API.image, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((photoRes) => {
            const request = `{updateImageDetail(where: $where, data: $data) {
              url
            }}`;
            const graphqlQuery = {
              operationName: "UpdateImageDetail",
              query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, 
                $data: ImageDetailUpdateInput!) ${request}`,
              variables: {
                data: {
                  url: photoRes.data.url,
                },
                where: {
                  id: session.childContent[0].images[0].id,
                },
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            })
              .then((res) => {
                // console.log("image", res);
                imageUpdate = true;
              })
              .catch((err) => console.error("image", err));
          })
          .catch((photoErr) => {
            console.error(photoErr);
          });
      } else {
        imageUpdate = true;
      }
      if (oldStTitle.context != stTitle) {
        const updateStT = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateStTGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateStT}`,
          variables: {
            data: {
              content: stTitle,
            },
            where: {
              id: oldStTitle.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateStTGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            p1TitleUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        p1TitleUpdate = true;
      }
      if (oldStContext.context != stContext) {
        const updateStC = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateStCGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateStC}`,
          variables: {
            data: {
              content: stContext,
            },
            where: {
              id: oldStContext.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateStCGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            p1ContentUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        p1ContentUpdate = true;
      }
      if (oldNdTitle.context != ndTitle) {
        const updateNdT = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateNdTGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateNdT}`,
          variables: {
            data: {
              content: ndTitle,
            },
            where: {
              id: oldNdTitle.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateNdTGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            p2TitleUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        p2TitleUpdate = true;
      }
      if (oldNdContext.context != ndContext) {
        const updateNdC = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateNdCGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateNdC}`,
          variables: {
            data: {
              content: ndContext,
            },
            where: {
              id: oldNdContext.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateNdCGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            p2ContentUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        p2ContentUpdate = true;
      }
      if (oldRdContext.context != rdContext) {
        const updateRdC = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateRdCGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateRdC}`,
          variables: {
            data: {
              content: rdContext,
            },
            where: {
              id: oldRdContext.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateRdCGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            p3ContentUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        p3ContentUpdate = true;
      }
      if (oldRd2Context.context != rd2Context) {
        const updateRdC2 = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateRdC2GraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateRdC2}`,
          variables: {
            data: {
              content: rd2Context,
            },
            where: {
              id: oldRd2Context.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateRdC2GraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {})
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      }
      if (oldTicEmail.context != ticEmail) {
        const updateEmail = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateEmailGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateEmail}`,
          variables: {
            data: {
              content: ticEmail,
            },
            where: {
              id: oldTicEmail.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateEmailGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            emailUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        emailUpdate = true;
      }
      if (oldTicPhone.context != ticPhone) {
        const updatePhone = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updatePhoneGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updatePhone}`,
          variables: {
            data: {
              content: ticPhone,
            },
            where: {
              id: oldTicPhone.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updatePhoneGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            phoneUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        phoneUpdate = true;
      }
      if (oldTicAddress.context != ticAddress) {
        // console.log("here");
        const updateAddress = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateAddressGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateAddress}`,
          variables: {
            data: {
              content: ticAddress,
            },
            where: {
              id: oldTicAddress.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateAddressGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            addressUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        addressUpdate = true;
      }
      if (oldAwardTitle.context != awardTitle) {
        const updateAwardTitle = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateAwardTitleGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateAwardTitle}`,
          variables: {
            data: {
              content: awardTitle,
            },
            where: {
              id: oldAwardTitle.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateAwardTitleGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            awardTitleUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        awardTitleUpdate = true;
      }
      //English
      if (oldStTitleEng.context != stTitleEng) {
        const updateStT = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateStTGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateStT}`,
          variables: {
            data: {
              content: stTitleEng,
            },
            where: {
              id: oldStTitleEng.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateStTGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            p1TitleEngUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        p1TitleEngUpdate = true;
      }
      if (oldStContextEng.context != stContextEng) {
        const updateStC = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateStCGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateStC}`,
          variables: {
            data: {
              content: stContextEng,
            },
            where: {
              id: oldStContextEng.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateStCGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            p1ContentEngUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        p1ContentEngUpdate = true;
      }
      if (oldNdTitleEng.context != ndTitleEng) {
        const updateNdT = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateNdTGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateNdT}`,
          variables: {
            data: {
              content: ndTitleEng,
            },
            where: {
              id: oldNdTitleEng.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateNdTGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            p2TitleEngUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        p2TitleEngUpdate = true;
      }
      if (oldNdContextEng.context != ndContextEng) {
        const updateNdC = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateNdCGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateNdC}`,
          variables: {
            data: {
              content: ndContextEng,
            },
            where: {
              id: oldNdContextEng.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateNdCGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            p2ContentEngUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        p2ContentEngUpdate = true;
      }
      if (oldRdContextEng.context != rdContextEng) {
        const updateRdC = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateRdCGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateRdC}`,
          variables: {
            data: {
              content: rdContextEng,
            },
            where: {
              id: oldRdContextEng.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateRdCGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            p3ContentEngUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        p3ContentEngUpdate = true;
      }
      if (oldRd2ContextEng.context != rd2ContextEng) {
        const updateRdC2 = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateRdC2GraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateRdC2}`,
          variables: {
            data: {
              content: rd2ContextEng,
            },
            where: {
              id: oldRd2ContextEng.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateRdC2GraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {})
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      }

      if (oldTicAddressEng.context != ticAddressEng) {
        // console.log("here");
        const updateAddress = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateAddressGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateAddress}`,
          variables: {
            data: {
              content: ticAddressEng,
            },
            where: {
              id: oldTicAddressEng.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateAddressGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            addressEngUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        addressEngUpdate = true;
      }
      if (oldTicLink.context != ticLink) {
        // console.log("here");
        const updateAddress = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateAddressGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateAddress}`,
          variables: {
            data: {
              content: ticLink,
            },
            where: {
              id: oldTicLink.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateAddressGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            personalPortfolioLinkUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        personalPortfolioLinkUpdate = true;
      }
      if (oldAwardTitleEng.context != awardTitleEng) {
        const updateAwardTitle = `{updateContentDetail(where: $where, data: $data){
          id
          content
        }}`;
        const updateAwardTitleGraphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
            $data: ContentDetailUpdateInput!) ${updateAwardTitle}`,
          variables: {
            data: {
              content: awardTitleEng,
            },
            where: {
              id: oldAwardTitleEng.id,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: updateAwardTitleGraphqlQuery,
          headers: headers,
        })
          .then((update1TitleRes) => {
            // console.log("content1", update1TitleRes);
            awardTitleEngUpdate = true;
          })
          .catch((update1TitleErr) =>
            console.error("content1", update1TitleErr)
          );
      } else {
        awardTitleEngUpdate = true;
      }
      let promiseArray = [];
      await Promise.all(
        awardList.map((e: any, idx) => {
          // console.log("looping");
          if (e.contextID.split("+")[0] === "newCreate") {
            let value = document.getElementById(
              e.contextID
            ) as TextFieldElement;
            let year = document.getElementById(e.yearID) as TextFieldElement;
            let valueEng = document.getElementById(
              e.contextEngID
            ) as TextFieldElement;
            let yearEng = document.getElementById(
              e.yearEngID
            ) as TextFieldElement;
            // console.log(value.value);
            // console.log(year.value);
            const titleRequest = `{createContentDetail(data:$data){
                      content
                      category
                      position
                      master{
                        id
                      }
                      langLabel{
                        id
                      }
                    }}`;
            const titleGraphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data: ContentDetailCreateInput!) ${titleRequest}`,
              variables: {
                data: {
                  content: `${value.value}!@#$%^&*()_+${year.value}`,
                  category: id,
                  position: `ticAward${idx}`,
                  master: {
                    connect: { id: session.childContent[0].id },
                  },
                  langLabel: {
                    connect: { id: chinese_zh_id },
                  },
                },
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: titleGraphqlQuery,
              headers: headers,
            })
              .then((titleRes) => {
                // console.log(`award${e.id.split("+")[1]}`, titleRes);
                promiseArray.push({
                  content: `${value.value}!@#$%^&*()_+${year.value}`,
                });
              })
              .catch((titleErr) =>
                console.error(`award${e.id.split("+")[1]}`, titleErr)
              );
            const titleEngRequest = `{createContentDetail(data:$data){
              content
              category
              position
              master{
                id
              }
              langLabel{
                id
              }
            }}`;
            const titleEngGraphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data: ContentDetailCreateInput!) ${titleEngRequest}`,
              variables: {
                data: {
                  content: `${valueEng.value}!@#$%^&*()_+${yearEng.value}`,
                  category: id,
                  position: `ticAward${idx}`,
                  master: {
                    connect: { id: session.childContent[0].id },
                  },
                  langLabel: {
                    connect: { id: eng_id },
                  },
                },
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: titleEngGraphqlQuery,
              headers: headers,
            })
              .then((titleRes) => {
                // console.log(`award${e.id.split("+")[1]}`, titleRes);
                promiseArray.push({
                  content: `${valueEng.value}!@#$%^&*()_+${yearEng.value}`,
                });
              })
              .catch((titleErr) =>
                console.error(`award${e.id.split("+")[1]}`, titleErr)
              );
          } else if (e.contextID.split("+")[0] === "existItem") {
            // console.log(e);
            let value = document.getElementById(
              e.contextID
            ) as TextFieldElement;
            let year = document.getElementById(e.yearID) as TextFieldElement;
            let valueEng = document.getElementById(
              e.contextEngID
            ) as TextFieldElement;
            let yearEng = document.getElementById(
              e.yearEngID
            ) as TextFieldElement;
            // console.log(yearEng.value);
            const updateAwardRequest = `{updateContentDetail(where: $where, data: $data){
            id
            content
          }}`;
            const updateAwardGraphqlQuery = {
              operationName: "UpdateContentDetail",
              query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${updateAwardRequest}`,
              variables: {
                data: {
                  content: `${value.value}!@#$%^&*()_+${year.value}`,
                },
                where: {
                  id: e.id,
                },
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: updateAwardGraphqlQuery,
              headers: headers,
            })
              .then((update1TitleRes) => {
                // console.log(update1TitleRes);
                promiseArray.push({
                  content: `${value.value}!@#$%^&*()_+${year.value}`,
                });
              })
              .catch((update1TitleErr) => console.error(update1TitleErr));
            const updateAwardEngRequest = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
            const updateAwardEngGraphqlQuery = {
              operationName: "UpdateContentDetail",
              query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${updateAwardEngRequest}`,
              variables: {
                data: {
                  content: `${valueEng.value}!@#$%^&*()_+${yearEng.value}`,
                },
                where: {
                  id: e.engID,
                },
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: updateAwardEngGraphqlQuery,
              headers: headers,
            })
              .then((update1TitleRes) => {
                // console.log(update1TitleRes);
                promiseArray.push({
                  content: `${valueEng.value}!@#$%^&*()_+${yearEng.value}`,
                });
              })
              .catch((update1TitleErr) => console.error(update1TitleErr));
          }
        })
      );

      // for checking awards update process
      Promise.all(promiseArray).then((res) => {
        awardContentUpdate = true;
        awardContentEngUpdate = true;
      });
      setTimeout(() => {
        checkValues([
          imageUpdate,
          p1TitleUpdate,
          p1TitleEngUpdate,
          p1ContentUpdate,
          p1ContentEngUpdate,
          p2TitleUpdate,
          p2TitleEngUpdate,
          p2ContentUpdate,
          p2ContentEngUpdate,
          p3ContentUpdate,
          p3ContentEngUpdate,
          emailUpdate,
          phoneUpdate,
          addressUpdate,
          addressEngUpdate,
          personalPortfolioLinkUpdate,
          awardTitleUpdate,
          awardTitleEngUpdate,
          awardContentUpdate,
          awardContentEngUpdate,
        ]).then(() => {
          setIsLoading(false);
          setTimeout(() => {
            dispatch(
              showMessage({
                message: `Team In Charge is updated`,
                autoHideDuration: 6000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                variant: "success",
              })
            );
          }, 2000);
          setRender(!render);
        });
      }, 1000);
    } else {
      // if (
      //   session.childContent[0].contents.length === 0 &&
      //   session.childContent[0].images.length === 0
      // ) {

      // for check create process
      let ticImageUpdate = false;
      let ticContents = false;

      if (!ticImage) {
        dispatch(
          showMessage({
            message: `Please Upload a Photo`,
            autoHideDuration: 6000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "error",
          })
        );
        return;
      }
      const request = `{createPageContent(data: $data){
          id,
          belongToChild {
            id
          }
        }}`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($data: PageContentCreateInput!) ${request}`,
        variables: {
          data: {
            belongToChild: {
              connect: { id: session.id },
            },
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => {
          // console.log("here");
          // console.log(res);
          let formData = new FormData();

          formData.append("image", ticImage);
          axios
            .post(API.image, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((photoRes) => {
              const request = `{createImageDetail(data:$data){
                url
                category
                position
                master{
                  id
                }
              }}`;
              const graphqlQuery = {
                operationName: "Mutation",
                query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    url: photoRes.data.url,
                    category: id,
                    position: `meetUsPhoto`,
                    master: {
                      connect: { id: res.data.data.createPageContent.id },
                    },
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              })
                .then((createPRes) => {
                  // console.log(createPRes);
                  ticImageUpdate = true;
                })
                .catch((createPErr) => console.error(createPErr));
            })
            .catch((photoErr) => console.error(photoErr));

          const contextDetailsRequest = `{createContentDetails(data: $data){
            content
            position
            category
            master {
              id
            }
            langLabel{
              id
            }
          }}`;
          const contextGraphqlQuery = {
            operationName: "CreateContentDetails",
            query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
            variables: {
              data: [
                {
                  content: stTitle,
                  position: `1stTitle`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: chinese_zh_id },
                  },
                },
                {
                  content: stContext,
                  position: `1stContext`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: chinese_zh_id },
                  },
                },
                {
                  content: ndTitle,
                  position: `2ndTitle`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: chinese_zh_id },
                  },
                },
                {
                  content: ndContext,
                  position: `2ndContext`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: chinese_zh_id },
                  },
                },
                {
                  content: rdContext,
                  position: `3rdContext`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: chinese_zh_id },
                  },
                },
                {
                  content: ticEmail,
                  position: `TICEmail`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                },
                {
                  content: ticPhone,
                  position: `TICPhone`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                },
                {
                  content: ticAddress,
                  position: `TICAddress`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: chinese_zh_id },
                  },
                },
                {
                  content: ticLink,
                  position: `TICLink`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                },
                {
                  content: awardTitle,
                  position: `AwardTitle`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: chinese_zh_id },
                  },
                },
                //English
                {
                  content: stTitleEng,
                  position: `1stTitle`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: eng_id },
                  },
                },
                {
                  content: stContextEng,
                  position: `1stContext`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: eng_id },
                  },
                },
                {
                  content: ndTitleEng,
                  position: `2ndTitle`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: eng_id },
                  },
                },
                {
                  content: ndContextEng,
                  position: `2ndContext`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: eng_id },
                  },
                },
                {
                  content: rdContextEng,
                  position: `3rdContext`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: eng_id },
                  },
                },
                {
                  content: ticAddressEng,
                  position: `TICAddress`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: eng_id },
                  },
                },
                {
                  content: awardTitleEng,
                  position: `AwardTitle`,
                  category: id,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: eng_id },
                  },
                },
              ],
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: contextGraphqlQuery,
            headers: headers,
          })
            .then((createContextRes) => {
              // console.log("createContextRes", createContextRes);
              ticContents = true;
            })
            .catch((createContextErr) => console.error(createContextErr));

          let promiseArray = [];
          awardList.forEach((e: any, idx) => {
            if (e.contextID.split("+")[0] === "newCreate") {
              let value = document.getElementById(
                e.contextID
              ) as TextFieldElement;
              let year = document.getElementById(e.yearID) as TextFieldElement;
              let valueEng = document.getElementById(
                e.contextEngID
              ) as TextFieldElement;
              let yearEng = document.getElementById(
                e.yearEngID
              ) as TextFieldElement;
              // console.log(value.value);
              // console.log(year.value);
              const titleRequest = `{createContentDetail(data:$data){
                        content
                        category
                        position
                        master{
                          id
                        }
                        langLabel{
                          id
                        }
                      }}`;
              const titleGraphqlQuery = {
                operationName: "Mutation",
                query: `mutation Mutation($data: ContentDetailCreateInput!) ${titleRequest}`,
                variables: {
                  data: {
                    content: `${value.value}!@#$%^&*()_+${year.value}`,
                    category: id,
                    position: `ticAward${idx}`,
                    master: {
                      connect: { id: res.data.data.createPageContent.id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: titleGraphqlQuery,
                headers: headers,
              })
                .then((titleRes) => {
                  // console.log(`award${e.id.split("+")[1]}`, titleRes),
                  promiseArray.push({
                    content: `${value.value}!@#$%^&*()_+${year.value}`,
                  });
                })
                .catch((titleErr) =>
                  console.error(`award${e.id.split("+")[1]}`, titleErr)
                );
              const titleEngRequest = `{createContentDetail(data:$data){
                  content
                  category
                  position
                  master{
                    id
                  }
                  langLabel{
                    id
                  }
                }}`;
              const titleEngGraphqlQuery = {
                operationName: "Mutation",
                query: `mutation Mutation($data: ContentDetailCreateInput!) ${titleEngRequest}`,
                variables: {
                  data: {
                    content: `${valueEng.value}!@#$%^&*()_+${yearEng.value}`,
                    category: id,
                    position: `ticAward${idx}`,
                    master: {
                      connect: { id: res.data.data.createPageContent.id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: titleEngGraphqlQuery,
                headers: headers,
              })
                .then((titleRes) => {
                  // console.log(`award${e.id.split("+")[1]}`, titleRes);
                  promiseArray.push({
                    content: `${valueEng.value}!@#$%^&*()_+${yearEng.value}`,
                  });
                })
                .catch((titleErr) =>
                  console.error(`award${e.id.split("+")[1]}`, titleErr)
                );
            }
          });
          Promise.all(promiseArray).then((res) => {
            awardContentUpdate = true;
            awardContentEngUpdate = true;
          });
        })
        .catch((err) => {
          alert("Please Contact Sengital with:" + err);
        });
      // }
      setTimeout(() => {
        checkValues([
          ticImageUpdate,
          ticContents,
          awardContentUpdate,
          awardContentEngUpdate,
        ]).then(() => {
          setIsLoading(false);
          setTimeout(() => {
            setPopUpMsg("Team In Charge is updated");
            setIsOpenAlert(true);
          }, 2000);
          setRender(!render);
        });
      }, 1000);
    }
  }

  function rewardDetail() {
    awardList.forEach((e: any, idx: number) => {
      // console.log(idx);
      let value = document.getElementById(e.contextID) as TextFieldElement;
      let year = document.getElementById(e.yearID) as TextFieldElement;
      // console.log(value.value);
      // console.log(year.value);
    });
  }

  const handleInputChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      // console.log(name, value);
      // console.log(index);
      const newAwards = [...awardList];
      newAwards[index][name] = value;
      setAwardList(newAwards);
    };

  const setStTitleRawHTML = (html: string) => {
    setStTitle(html);
  };

  const setStContextRawHTML = (html: string) => {
    setStContext(html);
  };

  const setNdTitleRawHTML = (html: string) => {
    setNdTitle(html);
  };

  const setNdContextRawHTML = (html: string) => {
    setNdContext(html);
    // console.log(html);
  };

  const setRdContextRawHTML = (html: string) => {
    setRdContext(html);
  };

  const setAwardTitleRawHTML = (html: string) => {
    setAwardTitle(html);
  };

  // English version
  const setStTitleEngRawHTML = (html: string) => {
    setStTitleEng(html);
  };

  const setStContextEngRawHTML = (html: string) => {
    setStContextEng(html);
  };

  const setNdTitleEngRawHTML = (html: string) => {
    setNdTitleEng(html);
  };

  const setNdContextEngRawHTML = (html: string) => {
    setNdContextEng(html);
  };

  const setRdContextEngRawHTML = (html: string) => {
    setRdContextEng(html);
  };

  const setAwardTitleEngRawHTML = (html: string) => {
    setAwardTitleEng(html);
  };

  // double confirm delete function to pop up a dialog
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [currentSelectedCard, setCurrentSelectedCard] = useState({} as any);
  const [currentArrayNumber, setCurrentArrayNumber] = useState(null as number);

  const confirmDelete = (cardDetails: any, idx: number) => {
    setDeleteDialog(true);
    setCurrentSelectedCard(cardDetails);
    if (cardDetails.id != "") {
      setCurrentArrayNumber(idx);
    }
  };

  const deleteDialogClose = () => {
    setDeleteDialog(false);
  };

  // axios delete mutation
  const deleteCard = async () => {
    setIsOpenAlert(false);
    // delete member who already created in database
    // console.log(currentSelectedCard);
    if (!currentSelectedCard.id.includes("reward")) {
      // console.log("async delete");
      const request = `{
        updateContentDetails(data:$data){
          isDeleted
          id
          }
        }`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
        variables: {
          data: [
            {
              where: { id: currentSelectedCard.id },
              data: { isDeleted: true },
            },
            {
              where: { id: currentSelectedCard.engID },
              data: { isDeleted: true },
            },
          ],
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
      if (currentSelectedCard.iconID != "") {
        const imageRequest = `{updateImageDetails(data: $data) {
          isDeleted
          id
        }}`;
        const imageGraphqlQuery = {
          operationName: "Mutation",
          query: `mutation Mutation($data: [ImageDetailUpdateArgs!]!) ${imageRequest}`,
          variables: {
            data: [
              {
                where: { id: currentSelectedCard.iconID },
                data: { isDeleted: true },
              },
            ],
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: imageGraphqlQuery,
          headers: headers,
        })
          .then((res) => {
            setRender(!render);
          })
          .catch((err) => console.error(err));
      }
    } else {
      // deleted member who is not create in database
      awardList.splice(currentArrayNumber, 1);
    }

    setDeleteDialog(false);
    dispatch(
      showMessage({
        message: `Award is deleted`,
        autoHideDuration: 6000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        variant: "success",
      })
    );
  };

  function moveToTheTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <>
      {isOpenAlert && <AlertDialog message={popUpMsg} duration={3000} />}
      {/* <button
        onClick={() => {
          console.log(session);
        }}
      >
        session
      </button> */}
      <Dialog
        open={isLoading}
        // onClose={handleCloseLoading}
        fullWidth
        // style={{ width: "100px", height: "100px" }}
      >
        <FuseLoading />
      </Dialog>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: "10px",
        }}
      >
        {/* delete dialog */}
        <Dialog
          open={deleteDialog}
          onClose={deleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Delete Award`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Confirm Delete Award.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={deleteCard} autoFocus>
              Delete
            </Button>
            <Button onClick={deleteDialogClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {/* delete dialog */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {previewUrl != null && muDisplayImage != previewUrl && (
            <Avatar
              alt="Remy Sharp"
              src={previewUrl}
              sx={{ width: 150, height: 150 }}
            />
          )}
          {previewUrl === null && muDisplayImage === "" && (
            <Avatar alt="Remy Sharp" src="" sx={{ width: 150, height: 150 }} />
          )}
          {muDisplayImage != "" && previewUrl === null && (
            <Avatar
              alt="Remy Sharp"
              src={muDisplayImage}
              sx={{ width: 150, height: 150 }}
            />
          )}
        </div>
        <br />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ marginTop: "10px" }}>Suggested Ratio : 1:1</div>
        <div style={{ marginTop: "10px" }}>Recommend Size : 500x500</div>
        <div style={{ marginTop: "10px" }}>Rendered Size : 275x275</div>
        <div style={{ marginTop: "10px" }}>
          * Cannot be empty as their corresponding row and icon will not be
          hidden
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Button variant="contained" component="label">
          Upload
          <input
            hidden
            accept="image/*"
            multiple
            type="file"
            onChange={handleFileSelect}
          />
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "40px",
          marginRight: "40px",
        }}
      >
        <Typography
          sx={{ fontSize: 14, marginTop: "20px" }}
          color="black"
          gutterBottom
        >
          Paragraph 1 Title (繁中)
        </Typography>
        <EditorDraft
          onConvertToHtml={setStTitleRawHTML}
          htmlRawValue={stTitle}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            * Cannot be empty as their corresponding row and icon will not be
            hidden
          </div>
        </div>
        <div
          style={{
            width: "100vw",
            backgroundColor: "#f0f5f9",
            height: "15px",
            borderLeft: "0.5px solid #f0f5f9",
          }}
        ></div>
        <Typography
          sx={{ fontSize: 14, marginTop: "20px" }}
          color="black"
          gutterBottom
        >
          Paragraph 1 Title (English)
        </Typography>
        <EditorDraft
          onConvertToHtml={setStTitleEngRawHTML}
          htmlRawValue={stTitleEng}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            * Cannot be empty as their corresponding row and icon will not be
            hidden
          </div>
        </div>
        <div
          style={{
            width: "100vw",
            backgroundColor: "#f0f5f9",
            height: "15px",
            borderLeft: "0.5px solid #f0f5f9",
          }}
        ></div>
        <Typography
          sx={{ fontSize: 14, marginTop: "20px" }}
          color="black"
          gutterBottom
        >
          Paragraph 1 (繁中)
        </Typography>
        <EditorDraft
          onConvertToHtml={setStContextRawHTML}
          htmlRawValue={stContext}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            Recommend maximum characters count : {Math.ceil(26 / 2.18)}
          </div>
          <div
            style={{
              color: "blue",
              marginBottom: "5px",
            }}
          >
            Current characters count :{" "}
            {stContext
              .replace(/&nbsp;/g, " ")
              .replace(/&amp;/g, "&")
              .replace(/<[^>]*>/g, "").length -
              1 ===
            -1
              ? 0
              : stContext
                  .replace(/&nbsp;/g, " ")
                  .replace(/&amp;/g, "&")
                  .replace(/<[^>]*>/g, "").length - 1}
          </div>
          <div style={{ color: "blue" }}>
            Can empty and their corresponding elements will be hidden
          </div>
        </div>
        <div
          style={{
            width: "100vw",
            backgroundColor: "#f0f5f9",
            height: "15px",
            borderLeft: "0.5px solid #f0f5f9",
          }}
        ></div>
        <Typography
          sx={{ fontSize: 14, marginTop: "20px" }}
          color="black"
          gutterBottom
        >
          Paragraph 1 (English)
        </Typography>
        <EditorDraft
          onConvertToHtml={setStContextEngRawHTML}
          htmlRawValue={stContextEng}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            Recommend maximum characters count : 26
          </div>
          <div
            style={{
              color: "blue",
              marginBottom: "5px",
            }}
          >
            Current characters count :{" "}
            {stContextEng
              .replace(/&nbsp;/g, " ")
              .replace(/&amp;/g, "&")
              .replace(/<[^>]*>/g, "").length -
              1 ===
            -1
              ? 0
              : stContextEng
                  .replace(/&nbsp;/g, " ")
                  .replace(/&amp;/g, "&")
                  .replace(/<[^>]*>/g, "").length - 1}
          </div>
          <div style={{ color: "blue" }}>
            Can empty and their corresponding elements will be hidden
          </div>
        </div>
        <div
          style={{
            width: "100vw",
            backgroundColor: "#f0f5f9",
            height: "15px",
            borderLeft: "0.5px solid #f0f5f9",
          }}
        ></div>
        <Typography
          sx={{ fontSize: 14, marginTop: "20px" }}
          color="black"
          gutterBottom
        >
          Paragraph 2 Title (繁中)
        </Typography>
        <EditorDraft
          onConvertToHtml={setNdTitleRawHTML}
          htmlRawValue={ndTitle}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            * Cannot be empty as their corresponding row and icon will not be
            hidden
          </div>
        </div>
        <div
          style={{
            width: "100vw",
            backgroundColor: "#f0f5f9",
            height: "15px",
            borderLeft: "0.5px solid #f0f5f9",
          }}
        ></div>
        <Typography
          sx={{ fontSize: 14, marginTop: "20px" }}
          color="black"
          gutterBottom
        >
          Paragraph 2 Title (English)
        </Typography>
        <EditorDraft
          onConvertToHtml={setNdTitleEngRawHTML}
          htmlRawValue={ndTitleEng}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            * Cannot be empty as their corresponding row and icon will not be
            hidden
          </div>
        </div>
        <div
          style={{
            width: "100vw",
            backgroundColor: "#f0f5f9",
            height: "15px",
            borderLeft: "0.5px solid #f0f5f9",
          }}
        ></div>
        <Typography
          sx={{ fontSize: 14, marginTop: "20px" }}
          color="black"
          gutterBottom
        >
          Paragraph 2 (繁中)
        </Typography>
        <EditorDraft
          onConvertToHtml={setNdContextRawHTML}
          htmlRawValue={ndContext}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            Recommend maximum characters count : {Math.ceil(20 / 2.18)}
          </div>
          <div
            style={{
              color: "blue",
              marginBottom: "5px",
            }}
          >
            Current characters count :{" "}
            {ndContext
              .replace(/&nbsp;/g, " ")
              .replace(/&amp;/g, "&")
              .replace(/<[^>]*>/g, "").length -
              1 ===
            -1
              ? 0
              : ndContext
                  .replace(/&nbsp;/g, " ")
                  .replace(/&amp;/g, "&")
                  .replace(/<[^>]*>/g, "").length - 1}
          </div>
          <div style={{ color: "blue" }}>
            Can empty and their corresponding elements will be hidden
          </div>
        </div>
        <div
          style={{
            width: "100vw",
            backgroundColor: "#f0f5f9",
            height: "15px",
            borderLeft: "0.5px solid #f0f5f9",
          }}
        ></div>
        <Typography
          sx={{ fontSize: 14, marginTop: "20px" }}
          color="black"
          gutterBottom
        >
          Paragraph 2 (English)
        </Typography>
        <EditorDraft
          onConvertToHtml={setNdContextEngRawHTML}
          htmlRawValue={ndContextEng}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            Recommend maximum characters count : 20
          </div>
          <div
            style={{
              color: "blue",
              marginBottom: "5px",
            }}
          >
            Current characters count :{" "}
            {ndContextEng
              .replace(/&nbsp;/g, " ")
              .replace(/&amp;/g, "&")
              .replace(/<[^>]*>/g, "").length -
              1 ===
            -1
              ? 0
              : ndContextEng
                  .replace(/&nbsp;/g, " ")
                  .replace(/&amp;/g, "&")
                  .replace(/<[^>]*>/g, "").length - 1}
          </div>
          <div style={{ color: "blue" }}>
            Can empty and their corresponding elements will be hidden
          </div>
        </div>
        <div
          style={{
            width: "100vw",
            backgroundColor: "#f0f5f9",
            height: "15px",
            borderLeft: "0.5px solid #f0f5f9",
          }}
        ></div>
        <Typography
          sx={{ fontSize: 14, marginTop: "20px" }}
          color="black"
          gutterBottom
        >
          Paragraph 3 (繁中)
        </Typography>
        <EditorDraft
          onConvertToHtml={setRdContextRawHTML}
          htmlRawValue={rdContext}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            Recommend maximum characters count : {Math.ceil(1300 / 2.18)}
          </div>
          <div
            style={{
              color: "blue",
              marginBottom: "5px",
            }}
          >
            Current characters count :{" "}
            {rdContext
              .replace(/&nbsp;/g, " ")
              .replace(/&amp;/g, "&")
              .replace(/<[^>]*>/g, "").length -
              1 ===
            -1
              ? 0
              : rdContext
                  .replace(/&nbsp;/g, " ")
                  .replace(/&amp;/g, "&")
                  .replace(/<[^>]*>/g, "").length - 1}
          </div>
          <div style={{ color: "blue" }}>
            Can empty and their corresponding elements will be hidden
          </div>
        </div>
        <Typography
          sx={{ fontSize: 14, marginTop: "20px" }}
          color="black"
          gutterBottom
        >
          Paragraph 3 (English)
        </Typography>
        <EditorDraft
          onConvertToHtml={setRdContextEngRawHTML}
          htmlRawValue={rdContextEng}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            Recommend maximum characters count : 1300
          </div>
          <div
            style={{
              color: "blue",
              marginBottom: "5px",
            }}
          >
            Current characters count :{" "}
            {rdContextEng
              .replace(/&nbsp;/g, " ")
              .replace(/&amp;/g, "&")
              .replace(/<[^>]*>/g, "").length -
              1 ===
            -1
              ? 0
              : rdContextEng
                  .replace(/&nbsp;/g, " ")
                  .replace(/&amp;/g, "&")
                  .replace(/<[^>]*>/g, "").length - 1}
          </div>
          <div style={{ color: "blue" }}>
            Can empty and their corresponding elements will be hidden
          </div>
        </div>
        <br />
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email"
          type="text"
          name="text"
          fullWidth
          variant="standard"
          value={ticEmail}
          onChange={(e) => setTicEmail(e.target.value)}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            * Cannot be empty as their corresponding row and icon will not be
            hidden
          </div>
        </div>
        <TextField
          autoFocus
          margin="dense"
          id="phone"
          label="Phone"
          type="text"
          name="text"
          fullWidth
          variant="standard"
          value={ticPhone}
          onChange={(e) => setTicPhone(e.target.value)}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            * Cannot be empty as their corresponding row and icon will not be
            hidden
          </div>
        </div>
        <TextField
          autoFocus
          margin="dense"
          id="address"
          label="Address (繁中)"
          type="text"
          name="text"
          fullWidth
          variant="standard"
          value={ticAddress}
          onChange={(e) => {
            setTicAddress(e.target.value);
          }}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            * Cannot be empty as their corresponding row and icon will not be
            hidden
          </div>
        </div>
        <TextField
          autoFocus
          margin="dense"
          id="address"
          label="Address (English)"
          type="text"
          name="text"
          fullWidth
          variant="standard"
          value={ticAddressEng}
          onChange={(e) => {
            setTicAddressEng(e.target.value);
          }}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            * Cannot be empty as their corresponding row and icon will not be
            hidden
          </div>
        </div>
        <TextField
          autoFocus
          margin="dense"
          id="twitter"
          label="Twitter"
          type="text"
          name="text"
          fullWidth
          variant="standard"
          value={ticTwitter}
          onChange={(e) => {
            setTicTwitter(e.target.value);
          }}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            * Cannot be empty as their corresponding row and icon will not be
            hidden
          </div>
        </div>
        <TextField
          autoFocus
          margin="dense"
          id="linkedin"
          label="LinkedIn"
          type="text"
          name="text"
          fullWidth
          variant="standard"
          value={ticLinkedin}
          onChange={(e) => {
            setTicLinkedin(e.target.value);
          }}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            * Cannot be empty as their corresponding row and icon will not be
            hidden
          </div>
        </div>
        <TextField
          autoFocus
          margin="dense"
          id="link"
          label="Personal Portfolio Link"
          type="text"
          name="link"
          fullWidth
          variant="standard"
          value={ticLink}
          onChange={(e) => {
            setTicLink(e.target.value);
          }}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            * Cannot be empty as their corresponding row and icon will not be
            hidden
          </div>
        </div>
        <Typography
          sx={{ fontSize: 14, marginTop: "20px" }}
          color="black"
          gutterBottom
        >
          Award Title (繁中)
        </Typography>
        <EditorDraft
          onConvertToHtml={setAwardTitleRawHTML}
          htmlRawValue={awardTitle}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            Recommend maximum characters count : {Math.ceil(18 / 2.18)}{" "}
            {"(Better without :)"}
          </div>
          <div
            style={{
              color: "blue",
              marginBottom: "5px",
            }}
          >
            Current characters count :{" "}
            {awardTitle
              .replace(/&nbsp;/g, " ")
              .replace(/&amp;/g, "&")
              .replace(/<[^>]*>/g, "").length -
              1 ===
            -1
              ? 0
              : awardTitleEng
                  .replace(/&nbsp;/g, " ")
                  .replace(/&amp;/g, "&")
                  .replace(/<[^>]*>/g, "").length - 1}
          </div>
          <div style={{ color: "blue" }}>
            Can empty and their corresponding elements will be hidden
          </div>
        </div>
        <Typography
          sx={{ fontSize: 14, marginTop: "20px" }}
          color="black"
          gutterBottom
        >
          Award Title (English)
        </Typography>
        <EditorDraft
          onConvertToHtml={setAwardTitleEngRawHTML}
          htmlRawValue={awardTitleEng}
        />
        <div
          style={{
            marginTop: "15px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              marginBottom: "5px",
              color: "blue",
            }}
          >
            Recommend maximum characters count : 18 {"(Better without :)"}
          </div>
          <div
            style={{
              color: "blue",
              marginBottom: "5px",
            }}
          >
            Current characters count :{" "}
            {awardTitleEng
              .replace(/&nbsp;/g, " ")
              .replace(/&amp;/g, "&")
              .replace(/<[^>]*>/g, "").length -
              1 ===
            -1
              ? 0
              : awardTitleEng
                  .replace(/&nbsp;/g, " ")
                  .replace(/&amp;/g, "&")
                  .replace(/<[^>]*>/g, "").length - 1}
          </div>
          <div style={{ color: "blue" }}>
            Can empty and their corresponding elements will be hidden
          </div>
        </div>
      </div>
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => addAward()}
          variant="contained"
          component="label"
        >
          Add Awards
        </Button>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginLeft: "20px",
        }}
      >
        {awardList.map((e, idx) => {
          return (
            <div
              style={{ marginRight: "10px", marginBottom: "10px" }}
              key={idx}
            >
              <Card sx={{ minWidth: 275, maxWidth: 300 }}>
                <div
                  style={{
                    minWidth: 275,
                    maxWidth: 300,
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 10,
                  }}
                >
                  <CloseIcon
                    style={{
                      cursor: "pointer",
                      marginRight: 10,
                    }}
                    onClick={() => confirmDelete(e, idx)}
                  />
                </div>
                <CardContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id={e.contextID}
                    label="Award Context (繁中)"
                    type="text"
                    name="context"
                    fullWidth
                    variant="standard"
                    value={e.context}
                    onChange={handleInputChange(idx)}
                  />
                  <div
                    style={{
                      marginTop: "15px",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        color: "blue",
                        marginBottom: "5px",
                      }}
                    >
                      Current characters count : {e.context.length}
                    </div>
                    <div
                      style={{
                        color: "blue",
                      }}
                    >
                      * Cannot be empty as their corresponding row will not be
                      hidden
                    </div>
                  </div>
                  <TextField
                    autoFocus
                    margin="dense"
                    id={e.contextEngID}
                    label="Award Context (English)"
                    type="text"
                    name="contextEng"
                    fullWidth
                    variant="standard"
                    value={e.contextEng}
                    onChange={handleInputChange(idx)}
                  />
                  <div
                    style={{
                      marginTop: "15px",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        color: "blue",
                        marginBottom: "5px",
                      }}
                    >
                      Current characters count : {e.contextEng.length}
                    </div>
                    <div
                      style={{
                        color: "blue",
                      }}
                    >
                      * Cannot be empty as their corresponding row will not be
                      hidden
                    </div>
                  </div>
                  <TextField
                    autoFocus
                    margin="dense"
                    id={e.yearID}
                    label="Award Year (繁中)"
                    type="text"
                    name="year"
                    fullWidth
                    variant="standard"
                    value={e.year}
                    onChange={handleInputChange(idx)}
                  />
                  <div
                    style={{
                      marginTop: "15px",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        color: "blue",
                        marginBottom: "5px",
                      }}
                    >
                      Current characters count : {e.year.length}
                    </div>
                    <div
                      style={{
                        marginBottom: "5px",
                        color: "blue",
                      }}
                    >
                      Format : Organization, YYYY
                    </div>
                    <div style={{ color: "blue" }}>
                      * Cannot be empty as their corresponding row will not be
                      hidden
                    </div>
                  </div>
                  <TextField
                    autoFocus
                    margin="dense"
                    id={e.yearEngID}
                    label="Award Year (English)"
                    type="text"
                    name="yearEng"
                    fullWidth
                    variant="standard"
                    value={e.yearEng}
                    onChange={handleInputChange(idx)}
                  />
                  <div
                    style={{
                      marginTop: "15px",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        color: "blue",
                        marginBottom: "5px",
                      }}
                    >
                      Current characters count : {e.yearEng.length}
                    </div>
                    <div
                      style={{
                        marginBottom: "5px",
                        color: "blue",
                      }}
                    >
                      Format : Organization, YYYY
                    </div>
                    <div style={{ color: "blue" }}>
                      * Cannot be empty as their corresponding row will not be
                      hidden
                    </div>
                  </div>
                  <br />
                  <h3>
                    <b>
                      If Award Context + Year {">"} 120 scroll bar will appear{" "}
                      {"(En)"}
                    </b>
                    <b>
                      If Award Context + Year {">"} {Math.ceil(120 / 2.18)}{" "}
                      scroll bar will appear {"(繁)"}
                    </b>
                  </h3>
                </CardContent>
              </Card>
            </div>
          );
        })}
      </div>
      <Button
        variant="contained"
        component="label"
        onClick={moveToTheTop}
        style={{
          position: "fixed",
          bottom: "75px",
          right: "15px",
          // cursor: "pointer",
        }}
      >
        <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
      </Button>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={modifyTICInfo}
          variant="contained"
          component="label"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "15px",
          }}
        >
          Save
        </Button>
      </div>
      <br />
    </>
  );
};

export default TeamInChange;
