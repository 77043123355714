import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
  InputAdornment,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { Formik, Form } from "formik";
import API from "src/app/helper/ApiHelper.ts";
import AlertDialog from "../Components/Alert.tsx";
import { showMessage } from "app/store/fuse/messageSlice";
import { useDispatch, useSelector } from "react-redux";

type UserInfo = {
  createdAt: string;
  email: string;
  id: string;
  name: string;
  role: string;
};

const UserManagement: React.FC = () => {
  const token = Cookies.get("jwt_authorization") || "not_login";
  // const API = require("../../../../api.config.json");
  const endPoint = API.local;
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  const [userList, setUserList] = useState([]);

  // Alert Pop up
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [popUpMsg, setPopUpMsg] = useState("");

  const [passwordValid, setPasswordValid] = useState(true);
  const [rePasswordValid, setRePasswordValid] = useState(true);
  const [nameValid, setNameValid] = useState(true);

  // delete user by select arrayNumber
  const [delUserIndex, setDelUserIndex] = useState(null as number);

  const dispatch = useDispatch();

  async function getAllUser() {
    const request = `{users {
            role,
            name,
            email,
            createdAt,
            id
        }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query ${request}`,
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        // console.log(res.data.data.users);
        res.data.data.users.forEach((user: UserInfo, idx: number) => {
          if (user.role === "superAdmin") {
            res.data.data.users.splice(idx, 1);
          }
        });
        setUserList(res.data.data.users);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // for reload the page after any action user have take
  const [render, setRender] = useState(false);

  useEffect(() => {
    getAllUser();
  }, [render]);

  async function delUser() {
    setIsOpenAlert(false);
    // console.log(userList[userIndex]);
    const request = `{deleteUser(where: $where){
      id
    }}`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($where: UserWhereUniqueInput!) ${request}`,
      variables: {
        where: { id: userList[delUserIndex].id },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        setDeleteDialog(false);
        setRender(!render);
        dispatch(
          showMessage({
            message: `Portal User is deleted`,
            autoHideDuration: 6000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "success",
          })
        );
      })
      .catch((err) => {
        alert("Please Contact Sengital with:" + err);
        setDeleteDialog(false);
      });
  }

  // for update user info
  const [updateUsername, setUpdateUsername] = useState("");
  const [updatePassword, setUpdatePassword] = useState("");
  const [updateRePassword, setUpdateRePassword] = useState("");
  const [updateRole, setUpdateRole] = useState("");
  const [updateEmail, setUpdateEmail] = useState("");
  const [updateID, setUpdateID] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [detailDialogData, setDetailDialogData] = useState({} as any);

  function handleUpdateUser(userIndex: number) {
    // console.log(userList[userIndex]);
    setPasswordValid(true);
    setRePasswordValid(true);
    setUpdateUsername(userList[userIndex].name);
    setUpdateRole(userList[userIndex].role);
    setUpdateEmail(userList[userIndex].email);
    setUpdateID(userList[userIndex].id);
    setUpdatePassword("");
    setUpdateRePassword("");
    setDetailDialogData(userList[userIndex]);

    setIsOpen(true);
  }

  const columns = [
    // {
    //   label: "Role",
    //   name: "role",
    // },
    { label: "Username", name: "name" },
    { label: "Email", name: "email" },
    {
      label: "Action",
      name: "action",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <ModeEditOutlineIcon
                  onClick={() => handleUpdateUser(dataIndex)}
                  style={{
                    marginRight: 30,
                    cursor: "pointer",
                    color: "#378BB5",
                  }}
                />
                <DeleteIcon
                  onClick={() => confirmDelete(dataIndex)}
                  style={{ cursor: "pointer", color: "#FF413A" }}
                />
              </div>
            </>
            // <div className="dataTable-button">
            //   <div className="trueActionButton">
            //     <ModeEditOutlineIcon
            //       onClick={() => handleUpdateUser(dataIndex)}
            //     />
            //   </div>

            //   <div style={{ minWidth: "10px", maxWidth: "10px" }}></div>

            //   <div className="trueActionButton">
            //     <DeleteIcon onClick={() => confirmDelete(dataIndex)} />
            //   </div>
            // </div>
          );
        },
      },
    },
  ];

  const [createAcOpen, setCreateAcOpen] = useState(false);
  const [dialogData, setDialogData] = useState() as any;
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  function handleOpenDialog() {
    setPasswordValid(true);
    setRePasswordValid(true);
    setCreateAcOpen(true);
    setDialogData({
      id: "",
      role: "",
      username: "",
      password: "",
      rePassword: "",
      email: "",
    });
  }

  const roleList = [
    { display: "Admin", value: "admin" },
    // { display: "NGO", value: "ngo" },
    { display: "Counsellor", value: "counsellor" },
    // { display: "SuperAdmin", value: "super_admin" },
    // { display: "Developer", value: "developer" },
    // { display: "Trainee", value: "trainee" },
    // { display: "Participant", value: "participant" },
  ];

  const handleAddDialogChange = (event: any) => {
    setRole(event.target.value);
    // console.log(event.target.value);
  };

  function handleCloseDialog() {
    setCreateAcOpen(false);
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  async function addUser() {
    setIsOpenAlert(false);
    if (
      username === "" ||
      email === "" ||
      // role === "" ||
      password === "" ||
      rePassword === ""
    ) {
      // console.log("todo");
      dispatch(
        showMessage({
          message: `Please Enter all Info`,
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "error",
        })
      );

      // setCreateAcOpen(false);
      return;
    }
    if (password != rePassword) {
      // console.log("todo");
      dispatch(
        showMessage({
          message: `Password and rePassword is not match`,
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "error",
        })
      );
      // alert("Password and rePassword Not Match");
      // setCreateAcOpen(false);
      return;
    }
    // console.log("username", username);
    // console.log("email", email);
    // console.log("role", role);
    if (passwordValid && rePasswordValid) {
      const request = `{createUser(data: $data) {
          role
          password {
            isSet
          }
          name
          email
        }}`;
      const graphqlQuery = {
        operationName: "CreateUser",
        query: `mutation CreateUser($data: UserCreateInput!) ${request}`,
        variables: {
          data: {
            role: "admin",
            password: password,
            name: username,
            email: email,
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => {
          // console.log(res);
          if (res.data.errors) {
            if (res.data.errors[0].message.includes("name")) {
              dispatch(
                showMessage({
                  message: `Username already exist`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "error",
                })
              );
              return;
            } else if (res.data.errors[0].message.includes("email")) {
              dispatch(
                showMessage({
                  message: `Email already exist`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "error",
                })
              );
              return;
            } else if (
              res.data.errors[0].message.includes(
                "Password must be at least 8 characters long"
              )
            ) {
              dispatch(
                showMessage({
                  message: `Password must be at least 8 characters`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "error",
                })
              );
              return;
            }
          } else {
            dispatch(
              showMessage({
                message: `User is created`,
                autoHideDuration: 6000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                variant: "success",
              })
            );
            setCreateAcOpen(false);
            setRender(!render);
          }
        })
        .catch((err) => {
          alert("Please Contact Sengital with:" + err);
          setCreateAcOpen(false);
        });
    }
  }

  function handleCloseDetailsDialog() {
    setIsOpen(false);
  }

  const handleUpdateUserRole = (event: any) => {
    setUpdateRole(event.target.value);
  };

  async function updateUserInfo(detailDialogData: any) {
    setIsOpenAlert(false);
    // console.log(detailDialogData);
    if (updatePassword === "") {
      const request = `{updateUser(where: $where, data: $data){
        id
        name
        role
        email
      }}`;
      const graphqlQuery = {
        operationName: "UpdateUser",
        query: `mutation UpdateUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) ${request}`,
        variables: {
          where: { id: detailDialogData.id },
          data: {
            name: updateUsername,
            role: updateRole,
            email: updateEmail,
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => {
          dispatch(
            showMessage({
              message: `Portal User is updated`,
              autoHideDuration: 6000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "success",
            })
          );
          // alert("Update Complete !");
          setIsOpen(false);
          setRender(!render);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      if (updatePassword != updateRePassword) {
        dispatch(
          showMessage({
            message: `Password and rePassword is not match`,
            autoHideDuration: 6000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "error",
          })
        );
        // alert("Password and rePassword not match");
        return;
      }
      if (passwordValid && rePasswordValid) {
        const request = `{updateUser(where: $where, data: $data){
          password {
            isSet
          }
          id
          name
          role
          email
        }}`;
        const graphqlQuery = {
          operationName: "UpdateUser",
          query: `mutation UpdateUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) ${request}`,
          variables: {
            where: { id: detailDialogData.id },
            data: {
              name: updateUsername,
              password: updatePassword,
              role: updateRole,
              email: updateEmail,
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        })
          .then((res) => {
            dispatch(
              showMessage({
                message: `Portal User is updated`,
                autoHideDuration: 6000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                variant: "success",
              })
            );
            setIsOpenAlert(true);

            setRender(!render);
          })
          .catch((err) => {
            alert("Please Contact Sengital with:" + err);
            setIsOpen(false);
          });
      }
    }
  }

  // double confirm delete function to pop up a dialog
  const [deleteDialog, setDeleteDialog] = useState(false);

  const confirmDelete = (idx: number) => {
    setDeleteDialog(true);
    setDelUserIndex(idx);
  };

  const deleteDialogClose = () => {
    setDeleteDialog(false);
  };

  useEffect(() => {
    if (isOpenAlert) {
      setIsOpenAlert(true);
      const timeoutId = setTimeout(() => {
        setIsOpenAlert(false);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpenAlert]);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <Dialog
        open={isOpenAlert}
        //onClose= {handleClose}
        sx={{
          top: "-80%",
        }}
      >
        <AlertDialog message={popUpMsg} duration={3000} />
      </Dialog>
      <div
        style={{
          marginTop: "50px",
          maxWidth: "1600px",
          marginLeft: "50px",
          marginRight: "10px",
        }}
      >
        {/* delete dialog */}
        <Dialog
          open={deleteDialog}
          onClose={deleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {userList[delUserIndex] && (
            <DialogTitle id="alert-dialog-title">{`Delete User ${userList[delUserIndex].name}`}</DialogTitle>
          )}
          {!userList[delUserIndex] && (
            <DialogTitle id="alert-dialog-title">{`Delete User`}</DialogTitle>
          )}

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Confirm Delete User.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={delUser} autoFocus>
              Delete
            </Button>
            <Button onClick={deleteDialogClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {/* delete dialog */}

        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            aria-label="add"
            size="small"
            onClick={() => handleOpenDialog()}
            style={{ border: "solid 3px" }}
          >
            <AddIcon />
          </IconButton>
        </Stack>
        <br />
        <MUIDataTable
          columns={columns}
          data={userList}
          title={""}
          options={{
            selectableRows: "none",
            rowsPerPage: 6,
            rowsPerPageOptions: [6],
            download: false,
            print: false,
            responsive: "standard",
            // search: false,
          }}
        ></MUIDataTable>
      </div>
      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={handleCloseDetailsDialog}
          fullScreen={fullScreen}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => handleCloseDetailsDialog()}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <DialogContent>
            <Formik
              onSubmit={(values) => {
                updateUserInfo(detailDialogData);
              }}
              initialValues={detailDialogData}
            >
              <Form>
                <TextField
                  autoFocus
                  margin="dense"
                  id="username"
                  label="Username"
                  type="text"
                  name="text"
                  fullWidth
                  variant="standard"
                  value={updateUsername}
                  onChange={(e) => setUpdateUsername(e.target.value)}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="email"
                  label="Email"
                  type="text"
                  name="text"
                  fullWidth
                  variant="standard"
                  value={updateEmail}
                  onChange={(e) => setUpdateEmail(e.target.value)}
                />

                {/* <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={updateRole}
                    defaultValue={updateRole}
                    onChange={handleUpdateUserRole}
                    // onChange={handleUpdateDialogChange}
                    label="parent"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {roleList.map((e, idx) => {
                      return (
                        <MenuItem value={`${e.value}`} key={e.value}>
                          {e.display}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}

                <TextField
                  autoFocus
                  margin="dense"
                  id="password"
                  label="Password"
                  name="text"
                  fullWidth
                  variant="standard"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={updatePassword}
                  onChange={(e) => {
                    setUpdatePassword(e.target.value);
                    const passwordRegex =
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
                    setPasswordValid(passwordRegex.test(e.target.value));
                  }}
                />
                {!passwordValid && (
                  <>
                    <p style={{ color: "red", marginLeft: "10px" }}>
                      - Minimum of 8 characters <br></br>- Use both uppercase
                      and lowercase letters <br></br>- At least one number is
                      required & include one symbol
                      {" (!,@,#,$,%,^,&,*)"}
                    </p>

                    <br />
                  </>
                )}
                <TextField
                  autoFocus
                  margin="dense"
                  id="rePassword"
                  label="Re-password"
                  name="text"
                  fullWidth
                  variant="standard"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={updateRePassword}
                  onChange={(e) => {
                    setUpdateRePassword(e.target.value);
                    setRePasswordValid(e.target.value === updatePassword);
                  }}
                />
                {!rePasswordValid && (
                  <>
                    <p style={{ color: "red", marginLeft: "10px" }}>
                      Password is not match
                    </p>
                    <br />
                  </>
                )}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "lightgrey",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Formik>
          </DialogContent>
        </Dialog>
      )}
      {createAcOpen && (
        <Dialog
          open={createAcOpen}
          onClose={handleCloseDialog}
          fullWidth
          fullScreen={fullScreen}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => handleCloseDialog()}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <DialogContent>
            <Formik onSubmit={addUser} initialValues={dialogData}>
              <Form>
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  {/* <InputLabel id="demo-simple-select-standard-label">
                    Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={role}
                    onChange={handleAddDialogChange}
                    label="parent"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {roleList.map((e, idx) => {
                      return (
                        <MenuItem value={e.value} key={e.display}>
                          {e.display}
                        </MenuItem>
                      );
                    })}
                  </Select> */}
                </FormControl>
                <TextField
                  autoFocus
                  margin="dense"
                  id="username"
                  label="Username"
                  type="text"
                  name="username"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  margin="dense"
                  id="email"
                  label="Email"
                  type="text"
                  name="email"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="dense"
                  id="password"
                  label="Password"
                  name="password"
                  fullWidth
                  variant="standard"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    const passwordRegex =
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
                    setPasswordValid(passwordRegex.test(e.target.value));
                  }}
                />
                {!passwordValid && (
                  <>
                    <p style={{ color: "red", marginLeft: "10px" }}>
                      - Minimum of 8 characters <br></br>- Use both uppercase
                      and lowercase letters <br></br>- At least one number is
                      required & include one symbol
                      {" (!,@,#,$,%,^,&,*)"}
                    </p>

                    <br />
                  </>
                )}
                <TextField
                  margin="dense"
                  id="rePassword"
                  label="re-Password"
                  name="rePassword"
                  fullWidth
                  variant="standard"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setRePassword(e.target.value);
                    setRePasswordValid(e.target.value === password);
                  }}
                />
                {!rePasswordValid && (
                  <>
                    <p style={{ color: "red", marginLeft: "10px" }}>
                      Password is not match
                    </p>

                    <br />
                  </>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "lightgrey",
                    }}
                  >
                    Create
                  </Button>
                </div>
              </Form>
            </Formik>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default UserManagement;
