import i18next from "i18next";
import en from "./i18n/en";
import tr from "./i18n/tr";
import ar from "./i18n/ar";
import MainPage from "./Main";
import MenuBar from "./MenuBar/MenuBar.tsx";
import PageList from "./PageList/PageList.tsx";
import PageContent from "./PageContent/PageContent.tsx";
import UserManagement from "./User/UserManagement.tsx";
import PagesList from "./PageList/PagesList.tsx";
import PagesContent from "./PageContent/PagesContent.tsx";
import WebUserManagement from "./WebUser/WebUserManagement.tsx";
import FG_ACT from "./PageList/FG_ACT.tsx";
import FG_Module from "./PageList/FG_Module.tsx";
import ForgotPassword from "./ForgotPassword/ForgotPassword.tsx";
import DeletePageContentPanel from "./PageContent/DeletePage.tsx";

i18next.addResourceBundle("en", "examplePage", en);
i18next.addResourceBundle("tr", "examplePage", tr);
i18next.addResourceBundle("ar", "examplePage", ar);

const requireAuth = (currentUser) => (next) => (action) => {
  currentUser = localStorage.getItem("role");
  console.log(currentUser);
  console.log("???");
  if (!currentUser) {
    return;
  }
  return next(action);
};

const dynamicRoute = {
  pageContent: (id) => (id ? `/PageContent/${id}` : `/PageContent`),
  pageList: (id) => (id ? `/PageList/${id}` : `/PageList`),
};

const dynamicPageRoute = {
  pagesContent: (id) => (id ? `/PagesContent/${id}` : `/PagesContent`),
  pagesList: (id) => (id ? `/PagesList/${id}` : `/PagesList`),
  fg_act: (id) => (id ? `/FG_ACT/${id}` : `/FG_ACT`),

};

const MainConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "example",
      element: <MainPage />,
    },
    {
      path: "menu",
      element: <MenuBar />,
    },
    {
      path: `${dynamicRoute.pageList(":id")}`,
      element: <PageList />,
    },
    {
      path: `${dynamicRoute.pageContent(":id")}`,
      element: <PageContent />,
    },
    {
      path: `${dynamicPageRoute.pagesList(":id")}`,
      element: <PagesList />,
    },
    {
      path: `${dynamicPageRoute.pagesContent(":id")}`,
      element: <PagesContent />,
    },
    {
      path: "User",
      element: <UserManagement />,
    },
    {
      path: "WebUser",
      element: <WebUserManagement />,
    },
    {
      path: "Facilitator-Guided_ACT",
      element: <FG_ACT />
    },
    {
      path: `${dynamicPageRoute.fg_act(":id")}`,
      element: <FG_Module />
    },
    {
      path: "FG_ACT_SUB",
      element: <PagesContent />
    },
    {
      path: "DeletePanel",
      element: <DeletePageContentPanel />
    }
  ],
};

export default MainConfig;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const Example = lazy(() => import('./Example'));

const ExampleConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'example',
      element: <Example />,
    },
  ],
};

export default ExampleConfig;
*/
