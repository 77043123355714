export const condense = (gql: string) =>
  gql
    .replace(/#.*\n/g, "")
    .replace(/[\s|,]*\n+[\s|,]*/g, " ")
    .replace(/:\s/g, ":")
    .replace(/,\s/g, ",")
    .replace(/\)\s\{/g, "){")
    .replace(/\}\s/g, "}")
    .replace(/\{\s/g, "{")
    .replace(/\s\}/g, "}")
    .replace(/\s\{/g, "{")
    .replace(/\)\s/g, ")")
    .replace(/\(\s/g, "(")
    .replace(/\s\)/g, ")")
    .replace(/\s\(/g, "(")
    .replace(/=\s/g, "=")
    .replace(/\s=/g, "=")
    .replace(/@\s/g, "@")
    .replace(/\s@/g, "@")
    .replace(/\s\$/g, "$")
    .replace(/\s\./g, ".")
    .trim();
