import i18next from "i18next";
import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

const navigationConfig = [
  {
    id: "content-management-system",
    title: "Content Management System",
    type: "collapse",
    children: [
      {
        id: "content-management-system-assessment-link",
        title: "Meet Us",
        url: "/PageList/MeetUs",
        type: "item",
      },
      {
        id: "content-management-system-assessment-link-training",
        title: "Training",
        url: "/PagesContent/Training",
        type: "item",
      },
      {
        id: "content-management-system-assessment-link-FG-ACT",
        title: "Facilitator-Guided ACT",
        url: "/Facilitator-Guided_ACT",
        type: "item",
      },
      {
        id: "content-management-system-assessment-link-Research",
        title: "Research",
        url: "/PagesContent/Research",
        type: "item",
      },
      {
        id: "content-management-system-assessment-link-ACT",
        title: "What Is ACT",
        url: "/PagesContent/What Is ACT",
        type: "item",
      },
      {
        id: "content-management-system-assessment-link-Publicity",
        title: "Publicity",
        url: "/PagesContent/Publicity",
        type: "item",
      },
      {
        id: "content-management-system-assessment-link-getInvolved",
        title: "Get Involved",
        url: "/PagesList/Get Involved",
        type: "item",
      },
      {
        id: "content-management-system-assessment-link-landing",
        title: "Landing",
        url: "/PagesContent/landing",
        type: "item",
      },
      {
        id: "content-management-system-assessment-link-footer",
        title: "Footer",
        url: "/PagesList/Footer",
        type: "item",
      },
      // {
      //   id: "content-management-system-assessment-link-news",
      //   title: "News",
      //   url: "/PagesContent/News",
      //   type: "item",
      // },
    ],
  },
  {
    id: "user-management-system",
    title: "User Management System",
    type: "collapse",
    children: [
      {
        id: "user-management-system-portal-user",
        title: "Portal User Management",
        url: "/User",
        type: "item",
      },
      {
        id: "user-management-system-website-user",
        title: "Web User Management",
        url: "/WebUser",
        type: "item",
      },
    ],
  },
  // { id: "records", title: "Records", url: "/records", type: "item" },
];

export default navigationConfig;
