import axios from "axios";
import { Form, Formik, Field } from "formik";
import { Button, Dialog, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";

import React, { useState } from "react";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import jwtService from "src/app/auth/services/jwtService/jwtService";
import API from "src/app/helper/ApiHelper.ts";
import AlertDialog from "../Components/Alert.tsx";

type JWTCookies = {
  user: string;
  role: string;
  expires: number;
};

type LoginDetails = {
  username: String;
  email: String;
  password: String;
};

type SignUpDetails = {
  username: String;
  email: String;
  password: String;
  rePassword: String;
  role: string;
};

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const [error, setError] = useState({} as any);
  const defaultValues = {
    email: "",
    password: "",
  };
  const { handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues,
  });

  // const API = require("../../../../api.config.json");
  const endPoint = API.local;
  const headers = {
    "Content-Type": "application/json",
  };
  const [openDialog, setOpenDialog] = useState(false);

  const details: LoginDetails = {
    username: "",
    email: "",
    password: "",
  };

  const signUpDetails: SignUpDetails = {
    username: "",
    email: "",
    password: "",
    rePassword: "",
    role: "",
  };

  function openSignUp() {
    setOpenDialog(true);
  }

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  const [isOpenAlert, setIsOpenAlert] = useState(false);

  async function login({ email, password }: any) {
    setIsOpenAlert(false);
    try {
      const token = await axios.post(`${API.login}`, {
        email: email,
        password: password,
      });

      // console.log(token);
      // console.log("token", token.data.role);
      if (token.data.res) {
        jwtService
          .signInWithEmailAndPassword(email, token.data.res, token.data.role)
          .then((user) => {
            // No need to do anything, user data will be set at app/auth/AuthContext
            // console.log(user);
          })
          .catch((_errors) => {
            console.error(_errors);
          });
        navigate("/PageList/MeetUs");
        const decoded: JWTCookies = jwtDecode(token.data.res);
        if (!decoded) {
          return { status: 404, message: "failed to verify" };
        }
        cookies.set("jwt_authorization", token.data.res, {
          expires: new Date(Date.now() + decoded.expires),
        });
        // props.login();
      } else {
        setIsOpenAlert(true);
        alert("Email or Password Incorrect");
        return { status: "404", message: "user not find" };
      }
    } catch (error) {
      return setIsOpenAlert(true);
      // console.error(error);
    }

    //   .catch((err) => console.log("err", err));
  }

  // async function signUp(values: SignUpDetails) {
  //   if (values.password.length < 8) {
  //     alert("Password must be at least 8 characters long");
  //   }
  //   if (values.password != values.rePassword) {
  //     alert("password not match");
  //     return;
  //   }
  //   const checkRequest = `{user(where: $where) {
  //       email
  //     }
  //   }`;
  //   const checkGraphqlQuery = {
  //     operationName: "Query",
  //     query: `query Query($where: UserWhereUniqueInput!) ${checkRequest}`,
  //     variables: {
  //       where: { email: values.email },
  //     },
  //   };
  //   axios({
  //     url: endPoint,
  //     method: "post",
  //     data: checkGraphqlQuery,
  //     headers: headers,
  //   })
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.data.user) {
  //         alert("Email has been used");
  //         return;
  //       } else {
  //         const request = `{createUser(data: $data){
  //               name,
  //               password {
  //                   isSet
  //               },
  //               email,
  //               role
  //           }}`;
  //         const graphqlQuery = {
  //           operationName: "Mutation",
  //           query: `mutation Mutation($data: UserCreateInput!) ${request}`,
  //           variables: {
  //             data: {
  //               name: values.username,
  //               password: values.password,
  //               email: values.email,
  //               role: values.role,
  //             },
  //           },
  //         };
  //         axios({
  //           url: endPoint,
  //           method: "post",
  //           data: graphqlQuery,
  //           headers: headers,
  //         })
  //           .then((res) => {
  //             console.log(res);
  //           })
  //           .catch((err) => console.log("err", err));
  //       }
  //     })
  //     .catch((err) => console.log("err", err));
  // }

  return (
    <>
      {isOpenAlert && (
        <AlertDialog message="Invalid Email or Password." duration={3000} />
      )}
      <div className="loginBody">
        <div className="loginContainer">
          <h1>
            <b>Sign in</b>
          </h1>
          <Formik onSubmit={handleSubmit(login) as any} initialValues={details}>
            <Form>
              <div className="loginColumn">
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      value={value}
                      className="mb-24"
                      label="Email"
                      autoFocus
                      type="text"
                      //   error={!!errors.email}
                      //   helperText={errors?.email?.message}
                      variant="outlined"
                      required
                      fullWidth
                      style={{ marginBottom: "15px" }}
                      onChange={(ev) => {
                        onChange(ev.target.value);
                      }}
                    />
                  )}
                />
              </div>
              <div className="loginColumn">
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      value={value}
                      className="mb-24"
                      label="Password"
                      autoFocus
                      type="password"
                      //   error={!!errors.email}
                      //   helperText={errors?.email?.message}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={(ev) => {
                        onChange(ev.target.value);
                      }}
                    />
                  )}
                />
              </div>
              {/* <Field id="email" name="email" className="loginColumn" /> */}
              <div className="btnContainer">
                <button type="submit" className="actionBtn">
                  Login
                </button>
                {/* <button onClick={openSignUp} className="actionBtn">
                  SignUp
                </button> */}
              </div>
            </Form>
          </Formik>
        </div>
        {/* {openDialog && (
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <Formik
              onSubmit={(values) => {
                signUp(values);
              }}
              initialValues={signUpDetails}
            >
              <Form>
                <Field id="email" name="email" />
                <Field id="username" name="username" />
                <Field id="password" name="password" type="password" />
                <Field id="rePassword" name="rePassword" type="password" />
                <Field id="role" name="role" />
                <Button type="submit">SignUp</Button>
              </Form>
            </Formik>
          </Dialog>
        )} */}
      </div>
    </>
  );
};

export default LoginPage;
