import LoginPage from "./Login.tsx";
import authRoles from "../../../auth/authRoles.js";
import React from "react";
import ForgotPassword from "../ForgotPassword/ForgotPassword.tsx";

const LoginConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },

  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "login",
      element: <LoginPage />,
    },
  ],
};

export default LoginConfig;
