import {
  Alert,
  AlertTitle,
  createTheme,
  Dialog,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import MUIDataTable from "mui-datatables";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import TemplateDisplay from "../Components/TemplateDisplay.tsx";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from "@mui/material/DialogContent";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
// import IconButton from '@mui/material/IconButton';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import TeamInChange from "./TeamInChange.tsx";
import AboutCollaborator from "./AboutCollaborator.tsx";
import OurCounsellorAndTeam from "./OurCounsellorAndTeam.tsx";
import FuseLoading from "@fuse/core/FuseLoading";

import EditorDraft from "../Components/EditorDraft.tsx";
import { StringChain } from "lodash";
import API from "src/app/helper/ApiHelper.ts";
import AlertDialog from "../Components/Alert.tsx";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import compressImage from "browser-image-compression";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const PageContent: React.FC = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  // const API = require("../../../../api.config.json");
  const templateList = require("../../../../template.json");

  const token = Cookies.get("jwt_authorization") || "not_login";
  const endPoint = API.local;
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  const [master, setMaster] = useState(false);
  const [session, setSession] = useState() as any;
  const [sessionType, setSessionType] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSessionOpen, setIsSessionOpen] = useState("");
  const [currentSessionType, setCurrentSessionType] = useState("");
  const [openAddContent, setOpenAddContent] = useState(false);
  const [contentLastPriority, setContentLastPriority] = useState(0);
  const [imageLastPriority, setImageLastPriority] = useState(0);
  const [currentSessionID, setCurrentSessionID] = useState("");
  const [position, setPosition] = useState("");
  const [image, setImage] = useState() as any;
  const [content, setContent] = useState("");
  const [template, setTemplate] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // MeetUs Page
  const [beforeMUContent, setBeforeMUContent] = useState("");
  const [beforeMUContentEng, setBeforeMUContentEng] = useState("");
  const [afterMUContentEng, setAfterMUContentEng] = useState("");
  const [afterMUContent, setAfterMUContent] = useState("");
  const [muTitle, setMuTitle] = useState("");
  const [muTitleEng, setMuTitleEng] = useState("");
  const [muImage, setMuImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [muDisplayImage, setMuDisplayImage] = useState("");
  const [muOldTitle, setMuOldTitle] = useState("");
  const [muOldTitleEng, setMuOldTitleEng] = useState("");
  const [muOldContent1, setMuOldContent1] = useState("");
  const [muOldContent1Eng, setMuOldContent1Eng] = useState("");
  const [muOldContent2Eng, setMuOldContent2Eng] = useState("");
  const [muOldContent2, setMuOldContent2] = useState("");

  const [isDisabled, setIsDisabled] = useState(false);

  // language
  const [languageList, setLanguageList] = useState([] as any);

  const handleTemplateChange = (event: any) => {
    setTemplate(event.target.value);
  };

  async function checkChildOrParent() {
    const check = `{menuBar(where: $where){
        title
    }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!) ${check}`,
      variables: {
        where: {
          title: id,
        },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        if (!res.data.data.menuBar) {
          const request = `{childMenu(where: $where){
            id,
            childContent {
                title,
                session,
                template,
                images {
                    url,
                    priority,
                    id,
                    category
                }
                id
                contents {
                    category,
                    content,
                    id,
                    priority,
                    position,
                    langLabel{
                      id,
                      lang,
                      index,
                    }
                }
                sessionImage
            }
          }}`;
          const graphqlQuery = {
            operationName: "Query",
            query: `query Query($where: ChildMenuWhereUniqueInput!) ${request}`,
            variables: {
              where: { title: id },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              setSession(res.data.data.childMenu);
              setMaster(false);
            })
            .catch((err) => console.error("err", err));
        } else {
          const request = `{menuBar(where: $where) {
            id,
            title,
            parentContent {
              title
              session
              template
              images {
                url
                priority
                category
                id
              }
              id
              contents {
                priority
                id
                content
                category
                position
                langLabel{
                  id
                  lang
                  index
                }
              }
              sessionImage
            }
          }
        }`;
          const graphqlQuery = {
            operationName: "Query",
            query: `query Query($where: MenuBarWhereUniqueInput!) ${request}`,
            variables: {
              where: { title: id },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              setMaster(true);
              setSession(res.data.data.menuBar);
              // console.log(res.data.data.menuBar);
              if (res.data.data.menuBar.title === "MeetUs") {
                if (res.data.data.menuBar.parentContent.length != 0) {
                  res.data.data.menuBar.parentContent[0].contents.forEach(
                    (e: any) => {
                      // console.log("e", e);
                      if (
                        e.position === "title" &&
                        e.langLabel[0].lang === "zh_hant"
                      ) {
                        setMuTitle(e.content);
                        setMuOldTitle(e.content);
                      } else if (
                        e.position === "content1" &&
                        e.langLabel[0].lang === "zh_hant"
                      ) {
                        setBeforeMUContent(e.content);
                        setMuOldContent1(e.content);
                      } else if (
                        e.position === "content2" &&
                        e.langLabel[0].lang === "zh_hant"
                      ) {
                        setAfterMUContent(e.content);
                        setMuOldContent2(e.content);
                      } else if (
                        e.position === "title" &&
                        e.langLabel[0].lang === "en"
                      ) {
                        setMuTitleEng(e.content);
                        setMuOldTitleEng(e.content);
                      } else if (
                        e.position === "content1" &&
                        e.langLabel[0].lang === "en"
                      ) {
                        setBeforeMUContentEng(e.content);
                        setMuOldContent1Eng(e.content);
                      } else if (
                        e.position === "content2" &&
                        e.langLabel[0].lang === "en"
                      ) {
                        setAfterMUContentEng(e.content);
                        setMuOldContent2Eng(e.content);
                      }
                      return;
                    }
                  );
                  if (
                    res.data.data.menuBar.parentContent[0].images.length > 0
                  ) {
                    setMuDisplayImage(
                      res.data.data.menuBar.parentContent[0].images[0].url
                    );
                  }
                }
              }
            })
            .catch((err) => console.error("err", err));
        }
      })
      .catch((err) => console.error(err));
  }

  async function getLangLabel() {
    const request = `{languages{
      lang
      index
      id
    }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query ${request}`,
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        setLanguageList(res.data.data.languages);
      })
      .catch((err) => console.error("err", err));
  }

  async function addSession(sessionDetails: any) {
    if (image) {
      let formData = new FormData();

      formData.append("image", image);
      axios
        .post(API.image, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          let sessionNumber = 0;
          if (sessionDetails.parentContent.length === 0) {
            sessionNumber = 1;
          } else {
            sessionNumber = sessionDetails.parentContent.length + 1;
          }
          // console.log(sessionNumber);
          const request = `{createPageContent(data: $data){
                title,
                session,
                sessionImage,
                template,
                belongToParent {
                    id
                }
              }}`;
          const graphqlQuery = {
            operationName: "Mutation",
            query: `mutation Mutation($data: PageContentCreateInput!) ${request}`,
            variables: {
              data: {
                title: content,
                session: sessionNumber,
                sessionImage: res.data.url,
                template: sessionType,
                belongToParent: {
                  connect: { id: session.id },
                },
              },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {})
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    }
    if (sessionDetails.childContent) {
    }
  }

  function openDialog() {
    setIsOpen(true);
  }

  function handleCloseDetailsDialog() {
    setIsOpen(false);
  }

  function handleCloseAddSessionDialog() {
    setOpenAddContent(false);
  }

  const updateSelectedValue = (value: any) => {
    setSessionType(value.value);
  };

  const updatePosition = (value: any) => {
    setPosition(value.value);
  };

  // for reload the page while user clicked submit
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    checkChildOrParent();
    getLangLabel();
  }, [isSubmitted]);

  // const option: { value: string; label: string }[] = [
  //   { value: "textOnly", label: "純文字" },
  //   { value: "textAndPhoto", label: "圖加文" },
  //   { value: "photoOnly", label: "純圖" },
  // ];
  // const textOption: { value: string; label: string }[] = [
  //   { value: "top", label: "上" },
  //   { value: "bottom", label: "下" },
  //   { value: "left", label: "左" },
  //   { value: "right", label: "右" },
  //   { value: "middle", label: "中" },
  //   { value: "topLeft", label: "左上" },
  //   { value: "topRight", label: "右上" },
  //   { value: "bottomLeft", label: "左下" },
  //   { value: "bottomRight", label: "右下" },
  // ];

  const handleTextOnlySubmit = async (event: any) => {
    event.preventDefault();
    const formElements = event.target.elements;
    const inputName = "content";
    const input = formElements[inputName];
    const categoryName = "category";
    const secondInput = formElements[categoryName];

    const request = `{
        createContentDetail(data: $data) {
          priority
          position
          content
          category
          master {
            id
          }
        }
      }`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($data: ContentDetailCreateInput!) ${request}`,
      variables: {
        data: {
          priority: contentLastPriority,
          position: position,
          content: input.value,
          category: secondInput.value,
          master: {
            connect: { id: currentSessionID },
          },
        },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {})
      .catch((err) => console.error(err));
  };

  const [data, setData] = useState([{ id: "" }] as any);

  function openSession(e: any) {
    console.log(e.contents);
    setIsSessionOpen(e.id);
    setData(e.contents);
  }

  function openSessionDialog(e: any) {
    // console.log(e);
    setCurrentSessionID(e.id);
    setCurrentSessionType(e.template);
    setContentLastPriority(e.contents.length + 1);
    setImageLastPriority(e.images.length + 1);

    setOpenAddContent(true);
  }

  const columns = [
    {
      label: "content",
      name: "content",
    },
    {
      label: "position",
      name: "position",
    },
    {
      label: "priority",
      name: "priority",
    },
  ];

  // const getMuiTheme = () =>
  //   createTheme({
  //     components: {
  //       MUIDataTable: {
  //         styleOverrides: {
  //           root: {
  //             //   minWidth: "200px",
  //             maxWidth: "1020px",
  //           },
  //         },
  //       },
  //       MUIDataTableBodyCell: {
  //         styleOverrides: {
  //           root: {
  //             // backgroundColor: "#FF0000",
  //           },
  //         },
  //       },
  //       MUIDataTablePagination: {
  //         styleOverrides: {
  //           root: {
  //             // backgroundColor: "#000",
  //             // color: "#fff",
  //           },
  //         },
  //       },
  //     },
  //   });

  // MeetUsFunction
  const handleFileSelect = async (event: any) => {
    const options = { maxSizeMB: 1, maxWidthOrHeight: 1500 };
    const file = await compressImage(event.target.files[0], options);
    setMuImage(file);
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewUrl(reader.result);
      dispatch(
        showMessage({
          message: `Selected Image is not Save`,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "warning",
        })
      );
    };
    reader.readAsDataURL(file);
  };

  let parentID = "MeetUs";

  const navigate = useNavigate();

  // Alert Pop up
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [popUpMsg, setPopUpMsg] = useState("");

  // due to structure problem this is a function will run every sec to check the value is true for turn off the loading screen
  function checkValues(values: any[]) {
    return new Promise<void>((resolve, reject) => {
      if (values.every((value) => value === true)) {
        resolve();
      }
    });
  }

  async function modifyMeetUSContent() {
    try {
      // for open loading screen
      setIsDisabled(true);
      setIsLoading(true);
      setIsOpenAlert(false);
      // for checking update complete or not to close the loading screen
      let imageUpdate = false;
      let titleEngUpdate = false;
      let titleUpdate = false;
      let content1Update = false;
      let content1EngUpdate = false;
      let content2Update = false;
      let content2EngUpdate = false;

      const chinese_zh_id = languageList.filter(
        (obj: any) => obj.lang === "zh_hant"
      )[0].id;
      const eng_id = languageList.filter((obj: any) => obj.lang === "en")[0].id;

      if (session.parentContent.length === 0) {
        if (!muImage) {
          // to alert user while missing photo

          dispatch(
            showMessage({
              message: `Missing Photo, Please Upload a Photo`,
              autoHideDuration: 6000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "success",
            })
          );

          return;
        }
        const request = `{createPageContent(data: $data){
        id,
        belongToParent {
          id
         }
        }}`;
        const graphqlQuery = {
          operationName: "Mutation",
          query: `mutation Mutation($data: PageContentCreateInput!) ${request}`,
          variables: {
            data: {
              belongToParent: {
                connect: { id: session.id },
              },
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        })
          .then((res) => {
            const titleEngRequest = `{createContentDetail(data:$data){
              content
              category
              position
              master{
                id
              }
              langLabel{
                id
              }
            }}`;
            const titleEngGraphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data: ContentDetailCreateInput!) ${titleEngRequest}`,
              variables: {
                data: {
                  content: muTitleEng,
                  category: id,
                  position: `title`,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: eng_id },
                  },
                },
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: titleEngGraphqlQuery,
              headers: headers,
            })
              .then((titleRes) => {
                // console.log("title", titleRes);
                titleEngUpdate = true;
              })
              .catch((titleErr) => console.error("title", titleErr));
            const titleRequest = `{createContentDetail(data:$data){
              content
              category
              position
              master{
                id
              }
              langLabel{
                id
              }
            }}`;
            const titleGraphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data: ContentDetailCreateInput!) ${titleRequest}`,
              variables: {
                data: {
                  content: muTitle,
                  category: id,
                  position: `title`,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: chinese_zh_id },
                  },
                },
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: titleGraphqlQuery,
              headers: headers,
            })
              .then((titleRes) => {
                // console.log("title", titleRes);
                titleUpdate = true;
              })
              .catch((titleErr) => console.error("title", titleErr));
            const request = `{createContentDetail(data:$data){
              content
              category
              position
              master{
                id
              }
              langLabel{
                id
              }
            }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data: ContentDetailCreateInput!) ${request}`,
              variables: {
                data: {
                  content: beforeMUContent,
                  category: id,
                  position: `content1`,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: chinese_zh_id },
                  },
                },
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            })
              .then((createRes) => {
                // console.log("content", createRes);
                content1Update = true;
              })
              .catch((createErr) => console.error("content", createErr));
            const requestEng = `{createContentDetail(data:$data){
                content
                category
                position
                master{
                  id
                }
                langLabel{
                  id
                }
              }}`;
            const graphqlEngQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data: ContentDetailCreateInput!) ${requestEng}`,
              variables: {
                data: {
                  content: beforeMUContentEng,
                  category: id,
                  position: `content1`,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: eng_id },
                  },
                },
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: graphqlEngQuery,
              headers: headers,
            })
              .then((createRes) => {
                // console.log("content", createRes);
                content1EngUpdate = true;
              })
              .catch((createErr) => console.error("content", createErr));
            const requestForSecond = `{createContentDetail(data:$data){
                content
                category
                position
                master{
                  id
                }
                langLabel{
                  id
                }
              }}`;
            const secondGraphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data: ContentDetailCreateInput!) ${requestForSecond}`,
              variables: {
                data: {
                  content: afterMUContent,
                  category: id,
                  position: `content2`,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: chinese_zh_id },
                  },
                },
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: secondGraphqlQuery,
              headers: headers,
            })
              .then((create2Res) => {
                // console.log("content2", create2Res);
                content2Update = true;
              })
              .catch((create2Err) => console.error("content2", create2Err));
            const requestForSecondEng = `{createContentDetail(data:$data){
                content
                category
                position
                master{
                  id
                }
                langLabel{
                  id
                }
              }}`;
            const secondGraphqlQueryEng = {
              operationName: "Mutation",
              query: `mutation Mutation($data: ContentDetailCreateInput!) ${requestForSecondEng}`,
              variables: {
                data: {
                  content: afterMUContentEng,
                  category: id,
                  position: `content2`,
                  master: {
                    connect: { id: res.data.data.createPageContent.id },
                  },
                  langLabel: {
                    connect: { id: eng_id },
                  },
                },
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: secondGraphqlQueryEng,
              headers: headers,
            })
              .then((create2Res) => {
                // console.log("content2", create2Res);
                content2EngUpdate = true;
              })
              .catch((create2Err) => console.error("content2", create2Err));
            let formData = new FormData();

            formData.append("image", muImage);
            axios
              .post(API.image, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((photoRes) => {
                const request = `{createImageDetail(data:$data){
                    url
                    category
                    position
                    master{
                      id
                    }
                  }}`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                  variables: {
                    data: {
                      url: photoRes.data.url,
                      category: id,
                      position: `meetUsPhoto`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((photoRes) => {
                    // console.log("photo", photoRes);
                    imageUpdate = true;
                    if (
                      imageUpdate &&
                      titleEngUpdate &&
                      titleUpdate &&
                      content1Update &&
                      content1EngUpdate &&
                      content2Update &&
                      content2EngUpdate
                    ) {
                      setIsLoading(false);
                      setIsSubmitted(!isSubmitted);
                      setTimeout(() => {
                        dispatch(
                          showMessage({
                            message: `MeetUs is updated`,
                            autoHideDuration: 6000,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                            variant: "success",
                          })
                        );
                      }, 2000);
                    }
                  })
                  .catch((photoResErr) => console.error("photo", photoResErr));
              });
          })
          .catch((err) => alert("Please Contact Sengital with :" + err));
      } else {
        let titleID = "";
        let titleEngID = "";
        let content1ID = "";
        let content1EngID = "";
        let content2ID = "";
        let content2EngID = "";
        let imageID = session.parentContent[0].images[0].id;

        session.parentContent[0].contents.forEach((e: any) => {
          if (e.position === "title" && e.langLabel[0].lang === "zh_hant") {
            titleID = e.id;
          } else if (
            e.position === "content1" &&
            e.langLabel[0].lang === "zh_hant"
          ) {
            content1ID = e.id;
          } else if (
            e.position === "content2" &&
            e.langLabel[0].lang === "zh_hant"
          ) {
            content2ID = e.id;
          } else if (e.position === "title" && e.langLabel[0].lang === "en") {
            titleEngID = e.id;
          } else if (
            e.position === "content1" &&
            e.langLabel[0].lang === "en"
          ) {
            content1EngID = e.id;
          } else if (
            e.position === "content2" &&
            e.langLabel[0].lang === "en"
          ) {
            content2EngID = e.id;
          }

          return;
        });
        if (muImage) {
          let formData = new FormData();

          formData.append("image", muImage);
          axios
            .post(API.image, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((photoRes) => {
              const request = `{updateImageDetail(where: $where, data: $data) {
                url
              }}`;
              const graphqlQuery = {
                operationName: "UpdateImageDetail",
                query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!,
                  $data: ImageDetailUpdateInput!) ${request}`,
                variables: {
                  data: {
                    url: photoRes.data.url,
                  },
                  where: {
                    id: imageID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              })
                .then((res) => {
                  // console.log("image", res);
                  imageUpdate = true;
                })
                .catch((err) => console.error("image", err));
            });
        } else {
          imageUpdate = true;
        }
        if (beforeMUContent != muOldContent1) {
          const request = `{updateContentDetail(where: $where, data: $data) {
            id
            content
          }}`;
          const graphqlQuery = {
            operationName: "UpdateContentDetail",
            query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!,
              $data: ContentDetailUpdateInput!) ${request}`,
            variables: {
              data: {
                content: beforeMUContent,
              },
              where: {
                id: content1ID,
              },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              // console.log("content1", res);
              content1Update = true;
            })
            .catch((err) => console.error("content1", err));
        } else {
          content1Update = true;
        }
        if (afterMUContent != muOldContent2) {
          const request = `{ updateContentDetail(where: $where, data: $data) {
            id
            content
          }}`;
          const graphqlQuery = {
            operationName: "UpdateContentDetail",
            query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!,
               $data: ContentDetailUpdateInput!) ${request}`,
            variables: {
              data: {
                content: afterMUContent,
              },
              where: {
                id: content2ID,
              },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              // console.log("content2", res);
              content2Update = true;
            })
            .catch((err) => console.error("content2", err));
        } else {
          content2Update = true;
        }
        if (muTitle != muOldTitle) {
          const request = `{updateContentDetail(where: $where, data: $data) {
            id
            content
          }
        }`;
          const graphqlQuery = {
            operationName: "UpdateContentDetail",
            query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!,
               $data: ContentDetailUpdateInput!) ${request}`,
            variables: {
              data: {
                content: muTitle,
              },
              where: {
                id: titleID,
              },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              // console.log("title", res);
              titleUpdate = true;
            })
            .catch((err) => console.error("title", err));
        } else {
          titleUpdate = true;
        }
        if (beforeMUContentEng != muOldContent1Eng) {
          const request = `{updateContentDetail(where: $where, data: $data) {
            id
            content
          }}`;
          const graphqlQuery = {
            operationName: "UpdateContentDetail",
            query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!,
              $data: ContentDetailUpdateInput!) ${request}`,
            variables: {
              data: {
                content: beforeMUContentEng,
              },
              where: {
                id: content1EngID,
              },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              // console.log("content1", res);
              content1EngUpdate = true;
            })
            .catch((err) => console.error("content1", err));
        } else {
          content1EngUpdate = true;
        }
        if (afterMUContentEng != muOldContent2Eng) {
          const request = `{ updateContentDetail(where: $where, data: $data) {
            id
            content
          }}`;
          const graphqlQuery = {
            operationName: "UpdateContentDetail",
            query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!,
               $data: ContentDetailUpdateInput!) ${request}`,
            variables: {
              data: {
                content: afterMUContentEng,
              },
              where: {
                id: content2EngID,
              },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              // console.log("content2", res);
              content2EngUpdate = true;
            })
            .catch((err) => console.error("content2", err));
        } else {
          content2EngUpdate = true;
        }
        if (muTitleEng != muOldTitleEng) {
          const request = `{updateContentDetail(where: $where, data: $data) {
            id
            content
          }
        }`;
          const graphqlQuery = {
            operationName: "UpdateContentDetail",
            query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!,
               $data: ContentDetailUpdateInput!) ${request}`,
            variables: {
              data: {
                content: muTitleEng,
              },
              where: {
                id: titleEngID,
              },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              // console.log("title", res);
              titleEngUpdate = true;
            })
            .catch((err) => console.error("title", err));
        } else {
          titleEngUpdate = true;
        }
        // for keep checking the update process once it done turn off the loading screen
        setTimeout(() => {
          checkValues([
            imageUpdate,
            titleEngUpdate,
            titleEngUpdate,
            content1Update,
            content1EngUpdate,
            content2Update,
            content2EngUpdate,
          ]).then(() => {
            setIsLoading(false);
            setIsSubmitted(!isSubmitted);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `MeetUs is updated`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
            }, 2000);
          });
        }, 1000);
      }
    } catch (error) {
      alert("Please Contact Sengital with:" + error);
      console.error(error);
    }
    setIsDisabled(false);
  }

  const [isLoading, setIsLoading] = useState(false);

  const [htmlContent, setHtmlContent] = useState("");

  const setMuTitleRawHTML = (html: string) => {
    setMuTitle(html);
  };
  const setMuTitleEngRawHTML = (html: string) => {
    setMuTitleEng(html);
  };
  const setMuContentFirstRawHTML = (html: string) => {
    setBeforeMUContent(html);
  };
  const setMuContentEngFirstRawHTML = (html: string) => {
    setBeforeMUContentEng(html);
  };

  const setMuContentSecondRawHTML = (html: string) => {
    setAfterMUContent(html);
  };
  const setMuContentEngSecondRawHTML = (html: string) => {
    setAfterMUContentEng(html);
  };

  const handleCloseLoading = () => {
    setIsLoading(false);
  };

  function moveToTheTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <>
      {/* <button onClick={() => setIsLoading(true)}>loading</button> */}
      {isOpenAlert && <AlertDialog message={popUpMsg} duration={3000} />}

      <Dialog
        open={isLoading}
        // onClose={handleCloseLoading}
        fullWidth
        // style={{ width: "100px", height: "100px" }}
      >
        <FuseLoading />
      </Dialog>

      <div style={{ marginBottom: "30px" }}></div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {id === "MeetUs" && (
          <Typography
            sx={{
              fontSize: 18,
              marginLeft: "15px",
              position: "relative",
              left: 50,
            }}
            color="text.secondary"
            gutterBottom
          >
            CMS - About Us - About Us
          </Typography>
        )}
        {id === "Team Head" && (
          <Typography
            sx={{
              fontSize: 18,
              marginLeft: "15px",
              position: "relative",
              left: 50,
            }}
            color="text.secondary"
            gutterBottom
          >
            CMS - About Us - Team Lead
          </Typography>
        )}
        {id === "Collaborators" && (
          <Typography
            sx={{
              fontSize: 18,
              marginLeft: "15px",
              position: "relative",
              left: 50,
            }}
            color="text.secondary"
            gutterBottom
          >
            CMS - About Us - Our Collaborators
          </Typography>
        )}
        {id === "Counsellors" && (
          <Typography
            sx={{
              fontSize: 18,
              marginLeft: "15px",
              position: "relative",
              left: 50,
            }}
            color="text.secondary"
            gutterBottom
          >
            CMS - About Us - Our People
          </Typography>
        )}

        <div
          style={{
            position: "relative",
            bottom: 6,
            right: 50,
          }}
        >
          <Button
            onClick={() => {
              navigate(`/PageList/${parentID}`);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div
        style={{
          height: "10px",
          width: "100vw",
          borderBottom: "1px solid grey",
          marginBottom: "20px",
        }}
      ></div>
      {/* <button onClick={() => console.log(languageList)}>language</button> */}
      {id === "Counsellors" && <OurCounsellorAndTeam />}
      {id === "Team Head" && <TeamInChange />}
      {id === "Collaborators" && <AboutCollaborator />}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {/* <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton aria-label="add" size="small" onClick={openDialog}>
          <AddIcon />
        </IconButton>
      </Stack> */}
        {/* <button
        onClick={() =>
          console.log(muDisplayImage.split("/.")[1].split("/image/")[1])
        }
      >
        ClickME
      </button> */}

        {id === "MeetUs" && (
          <>
            <div style={{ maxWidth: 800, minWidth: 600 }}>
              <TextField
                autoFocus
                margin="dense"
                id="muTitle"
                label="Title (繁中)"
                type="text"
                name="text"
                fullWidth
                variant="standard"
                value={muTitle}
                onChange={(e) => setMuTitle(e.target.value)}
              />
              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Recommend maximum characters count : {Math.ceil(40 / 2.18)}
                </div>
                <div
                  style={{
                    color: "blue",
                    marginBottom: "5px",
                  }}
                >
                  Current characters count : {muTitle.length}
                </div>
                <div style={{ color: "blue" }}>
                  can be empty and the corresponding components will be hidden
                </div>
              </div>

              <div
                style={{
                  width: "100vw",
                  backgroundColor: "#f0f5f9",
                  height: "30px",
                  borderLeft: "0.5px solid #f0f5f9",
                }}
              ></div>

              <TextField
                autoFocus
                margin="dense"
                id="muTitleEng"
                label="Title (English)"
                type="text"
                name="text"
                fullWidth
                variant="standard"
                value={muTitleEng}
                onChange={(e) => setMuTitleEng(e.target.value)}
              />
              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Recommend maximum characters count : 40
                </div>
                <div
                  style={{
                    color: "blue",
                    marginBottom: "5px",
                  }}
                >
                  Current characters count : {muTitleEng.length}
                </div>
                <div style={{ color: "blue" }}>
                  can be empty and the corresponding components will be hidden
                </div>
              </div>

              <div
                style={{
                  width: "100vw",
                  backgroundColor: "#f0f5f9",
                  height: "30px",
                  borderLeft: "0.5px solid #f0f5f9",
                }}
              ></div>
              <Typography
                sx={{ fontSize: 14, marginLeft: "15px" }}
                color="black"
                gutterBottom
              >
                Content (繁中)
              </Typography>
              <EditorDraft
                onConvertToHtml={setMuContentFirstRawHTML}
                htmlRawValue={beforeMUContent}
              />
              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Recommend maximum characters count : {Math.ceil(2000 / 2.18)}
                </div>
                <div
                  style={{
                    color: "blue",
                    marginBottom: "5px",
                  }}
                >
                  Current characters count :{" "}
                  {beforeMUContent
                    .replace(/&nbsp;/g, " ")
                    .replace(/&amp;/g, "&")
                    .replace(/<[^>]*>/g, "")
                    .replace(/&lt;/g, "<")
                    .replace(/&gt;/g, ">").length -
                    1 ===
                  -1
                    ? 0
                    : beforeMUContent
                        .replace(/&nbsp;/g, " ")
                        .replace(/&amp;/g, "&")
                        .replace(/<[^>]*>/g, "")
                        .replace(/&lt;/g, "<")
                        .replace(/&gt;/g, ">").length - 1}
                </div>
                <div style={{ color: "blue" }}>
                  can be empty and the corresponding components will be hidden
                </div>
              </div>
              <div
                style={{
                  width: "100vw",
                  backgroundColor: "#f0f5f9",
                  height: "30px",
                  borderLeft: "0.5px solid #f0f5f9",
                }}
              ></div>
              <Typography
                sx={{ fontSize: 14, marginLeft: "15px" }}
                color="black"
                gutterBottom
              >
                Content (English)
              </Typography>
              <EditorDraft
                onConvertToHtml={setMuContentEngFirstRawHTML}
                htmlRawValue={beforeMUContentEng}
              />
              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Recommend maximum characters count : 2000
                </div>
                <div
                  style={{
                    color: "blue",
                    marginBottom: "5px",
                  }}
                >
                  Current characters count :{" "}
                  {beforeMUContentEng
                    .replace(/&nbsp;/g, " ")
                    .replace(/&amp;/g, "&")
                    .replace(/<[^>]*>/g, "")
                    .replace(/&lt;/g, "<")
                    .replace(/&gt;/g, ">").length -
                    1 ===
                  -1
                    ? 0
                    : beforeMUContentEng
                        .replace(/&nbsp;/g, " ")
                        .replace(/&amp;/g, "&")
                        .replace(/<[^>]*>/g, "")
                        .replace(/&lt;/g, "<")
                        .replace(/&gt;/g, ">").length - 1}
                </div>
                <div style={{ color: "blue" }}>
                  can be empty and the corresponding components will be hidden
                </div>
              </div>

              <div
                style={{
                  width: "100vw",
                  backgroundColor: "#f0f5f9",
                  height: "30px",
                  borderLeft: "0.5px solid #f0f5f9",
                }}
              ></div>
              {previewUrl != null && (
                <CardMedia
                  component="img"
                  height="194"
                  image={previewUrl}
                  alt="Paella dish"
                />
              )}
              {previewUrl === null && muDisplayImage === "" && (
                <CardMedia
                  component="img"
                  height="194"
                  image={"/material-ui-static/images/cards/paella.jpg"}
                  alt="Paella dish"
                />
              )}
              {previewUrl === null && muDisplayImage != "" && (
                <CardMedia
                  component="img"
                  height="194"
                  image={`${API.getImage}${muDisplayImage}`}
                  alt="Paella dish"
                />
              )}
              <div
                style={{
                  width: "100vw",
                  backgroundColor: "#f0f5f9",
                  height: "15px",
                  borderLeft: "0.5px solid #f0f5f9",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div>Maximum Image Size : 10MB</div>
                <br />
                <div>Suggested Ratio : 3:2</div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" component="label">
                  Upload
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={handleFileSelect}
                  />
                </Button>
              </div>
              <div
                style={{
                  width: "100vw",
                  backgroundColor: "#f0f5f9",
                  height: "15px",
                  borderLeft: "0.5px solid #f0f5f9",
                }}
              ></div>
              <Typography
                sx={{ fontSize: 14, marginLeft: "15px", marginTop: "10px" }}
                color="black"
                gutterBottom
              >
                Content (繁中)
              </Typography>
              <EditorDraft
                onConvertToHtml={setMuContentSecondRawHTML}
                htmlRawValue={afterMUContent}
              />
              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Recommend maximum characters count : {Math.ceil(2000 / 2.18)}
                </div>
                <div
                  style={{
                    color: "blue",
                    marginBottom: "5px",
                  }}
                >
                  Current characters count :{" "}
                  {afterMUContent
                    .replace(/&nbsp;/g, " ")
                    .replace(/&amp;/g, "&")
                    .replace(/<[^>]*>/g, "")
                    .replace(/&lt;/g, "<")
                    .replace(/&gt;/g, ">").length -
                    1 ===
                  -1
                    ? 0
                    : afterMUContent
                        .replace(/&nbsp;/g, " ")
                        .replace(/&amp;/g, "&")
                        .replace(/<[^>]*>/g, "")
                        .replace(/&lt;/g, "<")
                        .replace(/&gt;/g, ">").length - 1}
                </div>
                <div style={{ color: "blue" }}>
                  can be empty and the corresponding components will be hidden
                </div>
              </div>
              <div
                style={{
                  width: "100vw",
                  backgroundColor: "#f0f5f9",
                  height: "15px",
                  borderLeft: "0.5px solid #f0f5f9",
                }}
              ></div>
              <Typography
                sx={{ fontSize: 14, marginLeft: "15px", marginTop: "10px" }}
                color="black"
                gutterBottom
              >
                Content (English)
              </Typography>
              <EditorDraft
                onConvertToHtml={setMuContentEngSecondRawHTML}
                htmlRawValue={afterMUContentEng}
              />
              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Recommend maximum characters count : 2000
                </div>
                <div
                  style={{
                    color: "blue",
                    marginBottom: "5px",
                  }}
                >
                  Current characters count :{" "}
                  {afterMUContentEng
                    .replace(/&nbsp;/g, " ")
                    .replace(/&amp;/g, "&")
                    .replace(/<[^>]*>/g, "")
                    .replace(/&lt;/g, "<")
                    .replace(/&gt;/g, ">").length -
                    1 ===
                  -1
                    ? 0
                    : afterMUContentEng
                        .replace(/&nbsp;/g, " ")
                        .replace(/&amp;/g, "&")
                        .replace(/<[^>]*>/g, "")
                        .replace(/&lt;/g, "<")
                        .replace(/&gt;/g, ">").length - 1}
                </div>
                <div style={{ color: "blue" }}>
                  can be empty and the corresponding components will be hidden
                </div>
              </div>
              <br />
              <Button
                variant="contained"
                component="label"
                onClick={moveToTheTop}
                style={{
                  position: "fixed",
                  bottom: "75px",
                  right: "15px",
                  // cursor: "pointer",
                }}
              >
                <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
              </Button>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => modifyMeetUSContent()}
                  variant="contained"
                  component="label"
                  style={{
                    position: "fixed",
                    bottom: "20px",
                    right: "15px",
                  }}
                >
                  Save
                </Button>
              </div>
              <div
                style={{
                  width: "100vw",
                  backgroundColor: "#f0f5f9",
                  height: "15px",
                  borderLeft: "0.5px solid #f0f5f9",
                }}
              ></div>
              {/* </Card> */}
            </div>
            {/* </div> */}
          </>
        )}

        {isOpen && (
          <Dialog
            open={isOpen}
            onClose={handleCloseDetailsDialog}
            fullWidth
            fullScreen={fullScreen}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton
                aria-label="close"
                size="small"
                onClick={handleCloseDetailsDialog}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <DialogContent>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Template
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={parent}
                  onChange={handleTemplateChange}
                  label="parent"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {templateList.templateList.map((e, idx) => {
                    return (
                      <MenuItem value={e} key={idx}>
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TemplateDisplay selectedTemplate={template}></TemplateDisplay>
            </DialogContent>
          </Dialog>
        )}
        {openAddContent && (
          <Dialog open={openAddContent} onClose={handleCloseAddSessionDialog}>
            <div onClick={handleCloseAddSessionDialog}>close</div>
            {currentSessionType === "textOnly" && (
              <form onSubmit={handleTextOnlySubmit}>
                <input name="content"></input>
                <input name="category"></input>
                {/* <Dropdown
                placeHolder="Select..."
                options={textOption}
                getSelectedValue={updatePosition}
              /> */}
                <button type="submit">Submit</button>
              </form>
            )}
            {currentSessionType === "textAndPhoto" && (
              <div>
                <input></input>
                <input type="file"></input>
              </div>
            )}
            {currentSessionType === "photoOnly" && (
              <div>
                <input type="file"></input>
              </div>
            )}
          </Dialog>
        )}
      </div>
    </>
  );
};

export default PageContent;
