import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import React from "react";
import API from "src/app/helper/ApiHelper.ts";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Typography,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Stack } from "immutable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import FuseLoading from "@fuse/core/FuseLoading";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

type FullMenu = {
  id: string;
  priority: number;
  title: string;
  parentTitle: string;
  url: string;
  parentID: string;
  title_zh_hant: string;
  title_en: string;
  redirectUrl: string;
  title_zh_hant_id: string;
  title_en_id: string;
  redirectUrl_id: string;
  timeLock: string;
  timeLock_id: string;
};

type ChildMenu = {
  id: string;
  title: string;
  url: string;
  priority: number;
};

type Menu = {
  id: string;
  title: string;
  url: string;
  priority: number;
  child: ChildMenu[];
};

const FG_ACT: React.FC = () => {
  // const token = Cookies.get("jwt_authorization") || "not_login";
  const [menuParent, setMenuParent] = useState({} as Menu);
  const navigate = useNavigate();

  const [data, setData] = useState([] as any);

  const [render, setRender] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);

  const [timeChange, setTimeChange] = useState(false);

  const dispatch = useDispatch();

  const endPoint = API.local;
  const headers = {
    "Content-Type": "application/json",
    //   authorization: `Bearer ${token}`,
  };
  function getData() {
    setIsLoading(true);
    let currentPageWholeList = [] as Array<FullMenu>;

    const request = `{
        menuBar(where :$where){
            url
            title
            priority
            id
            child(orderBy: $orderBy){
                title
                priority
                id
                url
                childContent(where: $childContentWhere2) {
                    id
                    title
                    contents {
                      langLabel {
                        lang
                        index
                        id
                      }
                      title
                      content
                      position
                      category
                      id
                    }
                  }
            }
        }
    }`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput! ,$orderBy: [ChildMenuOrderByInput!]!,$childContentWhere2: PageContentWhereInput!) ${request}`,
      variables: {
        where: { title: "Facilitator-Guided ACT" },
        childContentWhere2: {
          title: { equals: "Main Page Information" },
        },
        orderBy: [{ priority: "asc" }],
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        console.log(res);
        if (res.data.data.menuBar) {
          currentPageWholeList.push({
            id: res.data.data.menuBar.id,
            priority: res.data.data.menuBar.priority,
            title: res.data.data.menuBar.title,
            url: res.data.data.menuBar.url,
            parentTitle: "",
            parentID: "",
            title_zh_hant: "N/A",
            title_en: "N/A",
            redirectUrl: "N/A",
            title_zh_hant_id: "",
            title_en_id: "",
            redirectUrl_id: "",
            timeLock: "",
            timeLock_id: "",
          });
          res.data.data.menuBar.child.forEach((e: any) => {
            if (
              e.title === "Module0" ||
              e.title === "Module5" ||
              e.title === "Module6"
            ) {
              let titleZH = "";
              let titleZH_id = "";
              let titleEN = "";
              let titleEN_id = "";
              let mainPageUrl = "";
              let mainPageUrl_id = "";
              let timeLock = "";
              let timeLock_id = "";
              // console.log(e);
              e.childContent[0].contents.forEach((childElement: any) => {
                if (childElement.langLabel[0]) {
                  if (childElement.langLabel[0].lang === "zh_hant") {
                    titleZH = childElement.content;
                    titleZH_id = childElement.id;
                  }
                  if (childElement.langLabel[0].lang === "en") {
                    titleEN = childElement.content;
                    titleEN_id = childElement.id;
                  }
                }
                if (childElement.position === "url") {
                  mainPageUrl = childElement.content;
                  mainPageUrl_id = childElement.id;
                }
                if (childElement.position === "timeLock") {
                  timeLock = childElement.content;
                  timeLock_id = childElement.id;
                }
              });
              currentPageWholeList.push({
                id: e.id,
                priority: e.priority,
                title: e.title,
                url: e.url,
                parentID: res.data.data.menuBar.id,
                parentTitle: res.data.data.menuBar.title,
                title_zh_hant: titleZH,
                title_en: titleEN,
                redirectUrl: mainPageUrl,
                title_zh_hant_id: titleZH_id,
                title_en_id: titleEN_id,
                redirectUrl_id: mainPageUrl_id,
                timeLock: timeLock,
                timeLock_id: timeLock_id,
              });
            } else {
              let titleZH = "";
              let titleZH_id = "";
              let titleEN = "";
              let titleEN_id = "";
              let timeLock = "";
              let timeLock_id = "";

              // console.log(e);
              e.childContent[0].contents.forEach((childElement: any) => {
                if (childElement.langLabel[0]) {
                  if (childElement.langLabel[0].lang === "zh_hant") {
                    titleZH = childElement.content;
                    titleZH_id = childElement.id;
                  }
                  if (childElement.langLabel[0].lang === "en") {
                    titleEN = childElement.content;
                    titleEN_id = childElement.id;
                  }
                }
                if (childElement.position === "timeLock") {
                  timeLock = childElement.content;
                  timeLock_id = childElement.id;
                }
              });
              currentPageWholeList.push({
                id: e.id,
                priority: e.priority,
                title: e.title,
                url: e.url,
                parentID: res.data.data.menuBar.id,
                parentTitle: res.data.data.menuBar.title,
                title_zh_hant: titleZH,
                title_en: titleEN,
                redirectUrl: "N/A",
                title_zh_hant_id: titleZH_id,
                title_en_id: titleEN_id,
                redirectUrl_id: "",
                timeLock: timeLock,
                timeLock_id: timeLock_id,
              });
            }
          });
          // console.log(currentPageWholeList);

          setMenuParent(res.data.data.menuBar);
          setData(currentPageWholeList);
          setIsLoading(false);
        } else {
          setMenuParent({} as Menu);
          setData([{}]);
          setIsLoading(false);
        }
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    getData();
  }, [render]);

  function renderTime(date: any, dataIndex: number) {
    data[dataIndex].timeLock = date.toISOString();
    setTimeChange(!timeChange);
  }

  async function changeUnlockTime(dataIndex: number) {
    setIsLoading(true);
    try {
      console.log(data[dataIndex]);
      const request = `{updateContentDetail(where: $where, data: $data){
        id
        content
      }}`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${request}`,
        variables: {
          where: { id: data[dataIndex].timeLock_id },
          data: { content: `${data[dataIndex].timeLock}` },
        },
      };
      await axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      });

      setIsLoading(false);
      dispatch(
        showMessage({
          message: `${data[dataIndex].title} unlock time had been updated.`,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        })
      );
    } catch (error) {
      setIsLoading(false);
      dispatch(
        showMessage({
          message: `${error}`,
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "error",
        })
      );
    }
  }

  const columns = [
    {
      label: "Module",
      name: "title",
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <div style={{ marginLeft: "5px" }}>
              {data[dataIndex].title === "Module1" ||
              data[dataIndex].title === "Module2" ||
              data[dataIndex].title === "Module3" ||
              data[dataIndex].title === "Module4" ? (
                <div
                // onClick={() => {
                //   //   displayPageDetails(e);
                //   navigate(`/PageContent/${data[dataIndex].title}`);
                // }}
                // style={{ cursor: "pointer" }}
                >
                  {data[dataIndex].title}
                </div>
              ) : (
                <div style={{ maxWidth: "80px" }}>{data[dataIndex].title}</div>
              )}
            </div>
          );
        },
      },
    },
    {
      label: "Title (繁)",
      name: "title_zh_hant",
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <div style={{ maxWidth: "150px" }}>
              {data[dataIndex].title_zh_hant}
            </div>
          );
        },
      },
    },
    // { label: "Title (En)", name: "title_en" },
    {
      label: "Unlock Time",
      name: "timeLock",
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <>
              {data[dataIndex].title === "Facilitator-Guided ACT" ||
              data[dataIndex].title === "Module0" ? (
                <></>
              ) : (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ color: "white" }}>
                      <DateTimePicker
                        renderInput={(params) => (
                          <TextField {...params}></TextField>
                        )}
                        label="Module Unlock Time"
                        value={dayjs(data[dataIndex].timeLock)}
                        onChange={(newValue) => {
                          renderTime(newValue, dataIndex);
                        }}
                      />
                    </div>
                  </LocalizationProvider>

                  <Button
                    style={{ marginTop: "8px" }}
                    onClick={() => changeUnlockTime(dataIndex)}
                  >
                    Save
                  </Button>
                </div>
              )}
            </>
          );
        },
      },
    },
    {
      label: "Url",
      name: "redirectUrl",
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <div style={{ maxWidth: "150px", wordWrap: "break-word" }}>
              {data[dataIndex].redirectUrl}
            </div>
          );
        },
      },
    },
    {
      label: "Action",
      name: "action",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <>
              {data[dataIndex].title === "Facilitator-Guided ACT" && (
                <VisibilityIcon
                  onClick={() =>
                    navigate(`/PagesContent/${data[dataIndex].title}`)
                  }
                  style={{ cursor: "pointer", color: "#FF413A" }}
                />
              )}
              {(data[dataIndex].title === "Module0" ||
                data[dataIndex].title === "Module5" ||
                data[dataIndex].title === "Module6") && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <ModeEditOutlineIcon
                    onClick={() => openDialog(dataIndex)}
                    style={{
                      marginRight: 30,
                      cursor: "pointer",
                      color: "#378BB5",
                    }}
                  />
                </div>
              )}
              {(data[dataIndex].title === "Module1" ||
                data[dataIndex].title === "Module2" ||
                data[dataIndex].title === "Module3" ||
                data[dataIndex].title === "Module4") && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <ModeEditOutlineIcon
                    onClick={() => openDialog(dataIndex)}
                    style={{
                      marginRight: 30,
                      cursor: "pointer",
                      color: "#378BB5",
                    }}
                  />
                  <VisibilityIcon
                    onClick={() => navigate(`/FG_ACT/${data[dataIndex].title}`)}
                    style={{ cursor: "pointer", color: "#FF413A" }}
                  />
                </div>
              )}
            </>
          );
        },
      },
    },
  ];

  const [title, setTitle] = useState("");
  const [titleID, setTitleID] = useState("");
  const [titleEng, setTitleEng] = useState("");
  const [titleEngID, setTitleEngID] = useState("");
  const [url, setUrl] = useState("");
  const [urlID, setUrlID] = useState("");
  const [currentModule, setCurrentModule] = useState("");
  const [currentSelectModule, setCurrentSelectModule] = useState("");

  const openDialog = (index: number) => {
    setCurrentModule(data[index].title);
    setTitle(data[index].title_zh_hant);
    setTitleID(data[index].title_zh_hant_id);
    setUrl(data[index].redirectUrl);
    setUrlID(data[index].redirectUrl_id);
    setTitleEng(data[index].title_en);
    setTitleEngID(data[index].title_en_id);
    setCurrentSelectModule(data[index].id);
    setOpenEdit(true);
  };

  const closeDialog = () => {
    setOpenEdit(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  function modifyTitleAndUrl() {
    setIsLoading(true);
    // console.log(currentSelectModule);
    if (titleID != "") {
      const request = `{
            updateContentDetail(where: $where, data: $data) {
              content
              id
            }
          }`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${request}`,
        variables: {
          data: {
            content: title,
          },
          where: {
            id: titleID,
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => {})
        .catch((err) => console.error(err));
    }
    if (titleEngID != "") {
      const request = `{
              updateContentDetail(where: $where, data: $data) {
                content
                id
              }
            }`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${request}`,
        variables: {
          data: {
            content: titleEng,
          },
          where: {
            id: titleEngID,
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => {})
        .catch((err) => console.error(err));
    }
    if (urlID != "") {
      const request = `{
              updateContentDetail(where: $where, data: $data) {
                content
                id
              }
            }`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${request}`,
        variables: {
          data: {
            content: url,
          },
          where: {
            id: urlID,
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => {})
        .catch((err) => console.error(err));
    }
    setTimeout(() => {
      setRender(!render);
      setOpenEdit(false);
      setIsLoading(false);
      dispatch(
        showMessage({
          message: `${currentModule} is updated`,
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        })
      );
    }, 200);
  }

  async function addTimeLock() {
    setIsLoading(true);
    try {
      await Promise.all(
        menuParent.child.map(async (e: any) => {
          if (e.title.includes("Module")) {
            console.log(e);
            let masterID = e.childContent[0].id;
            const request = `{createContentDetail(data: $data) {
            category
            content
            position
            title
            master {
                id
              }
          }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data: ContentDetailCreateInput!) ${request}`,
              variables: {
                data: {
                  category: e.title,
                  content: "2023-10-13T11:01:00Z",
                  position: "timeLock",
                  title: `${e.title}_timeLock`,
                  master: {
                    connect: { id: masterID },
                  },
                },
              },
            };
            await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });
          }
        })
      );
      setIsLoading(false);
      dispatch(
        showMessage({
          message: `time lock is added.`,
          autoHideDuration: 1000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        })
      );
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  return (
    <>
      <Dialog
        open={isLoading}
        // onClose={handleCloseLoading}
        fullWidth
        // style={{ width: "100px", height: "100px" }}
      >
        <FuseLoading />
      </Dialog>
      <div
        style={{
          marginTop: "50px",
          maxWidth: "1600px",
          marginLeft: "50px",
          marginRight: "10px",
        }}
      >
        <Typography
          sx={{ fontSize: 18, marginLeft: "15px" }}
          color="text.secondary"
          gutterBottom
        >
          CMS - Facilitator-Guided ACT
        </Typography>
        <br />
        <h2>
          <b>
            Title can be empty and the corresponding components will be hidden
          </b>
        </h2>
        {/* <button onClick={addTimeLock}>click me</button> */}
        {/* Edit dialog */}

        <Dialog
          open={openEdit}
          onClose={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            {title != "N/A" && (
              <>
                <TextField
                  autoFocus
                  margin="dense"
                  id="title"
                  label="Title (繁)"
                  type="text"
                  name="text"
                  fullWidth
                  variant="standard"
                  value={title}
                  onChange={(e: any) => setTitle(e.target.value)}
                />
                <div style={{ color: "blue" }}>
                  {" "}
                  Recommend characters count : 4 or 6-8 Maximum: 16
                </div>
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Current characters count : {title.length}
                </div>
              </>
            )}
            {/* {titleEng != "N/A" && (
              <>
                <TextField
                  autoFocus
                  margin="dense"
                  id="titleEng"
                  label="Title (En)"
                  type="text"
                  name="text"
                  fullWidth
                  variant="standard"
                  value={titleEng}
                  onChange={(e: any) => setTitleEng(e.target.value)}
                />
                <div style={{ color: "blue" }}>
                  {" "}
                  Recommend characters count : 4 or 6-8 Maximum: 16
                </div>
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Current characters count : {title.length}
                </div>
              </>
            )} */}
            {url != "N/A" && (
              <TextField
                autoFocus
                margin="dense"
                id="url"
                label="Url"
                type="text"
                name="text"
                fullWidth
                variant="standard"
                value={url}
                onChange={(e: any) => setUrl(e.target.value)}
              />
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={modifyTitleAndUrl} autoFocus>
              Submit
            </Button>
            <Button onClick={closeDialog}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {/* delete dialog */}

        {/* <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            aria-label="add"
            size="small"
            onClick={() => handleOpenDialog()}
            style={{ border: "solid 3px" }}
          >
            <AddIcon />
          </IconButton>
        </Stack> */}
        <br />
        <MUIDataTable
          columns={columns}
          data={data}
          title={""}
          options={{
            selectableRows: "none",
            rowsPerPage: 8,
            rowsPerPageOptions: [8, 16],
            download: false,
            print: false,
            responsive: "standard",
            // search: false,
          }}
        ></MUIDataTable>
      </div>
    </>
  );
};

export default FG_ACT;
