import FuseUtils from "@fuse/utils/FuseUtils";
import axios from "axios";
import jwtDecode from "jwt-decode";
import jwtServiceConfig from "./jwtServiceConfig";
import Cookies from "js-cookie";
import API from "src/app/helper/ApiHelper.ts";

/* eslint-disable camelcase */



class JwtService extends FuseUtils.EventEmitter {
  init() {
    // this.setInterceptors();
    this.handleAuthentication();
  }

  // setInterceptors = () => {
  // axios.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   (err) => {
  //     console.log(err);
  //     return new Promise((resolve, reject) => {
  //       if (
  //         err.response &&

  //         err.config &&
  //         !err.config.__isRetryRequest
  //       ) {
  //         // if you ever get an unauthorized response, logout the user
  //         this.emit("onAutoLogout", "Invalid access_token");
  //         this.setSession(null);
  //       }
  //       throw err;
  //     });
  //   }
  // );
  // };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
          this.emit("onLogin", response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, token, role) => {
    return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.signIn, {
          data: {
            email,
            token,
            role,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.user) {
            this.setSession(response.data.token);
            resolve(response.data.user);
            this.emit("onLogin", response.data.user);
          } else {
            reject(response.data.error);
          }
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {

      axios
        .get(jwtServiceConfig.accessToken, {
          data: {
            access_token: this.getAccessToken(),
          },
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.access_token);
            resolve(response.data.user);
          } else {
            // this.logout();
            // reject(new Error("Failed to login with token."));
          }
        })
        .catch((error) => {
          this.logout();
          // reject(new Error("Failed to login with token."));
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      const token = Cookies.get("jwt_authorization") || "not_login";
      if (token != "not_login") {
        // const API = require("../../../../api.config.json")
        const endPoint = API.local
        const headers = {
          "Content-Type": "application/json",
          // authorization: `Bearer ${token}`,
        };
        console.log(id)
        const { id } = jwtDecode(token)

        const request = `{user(where: $where){
          id,
          email
        }}`;

        const graphqlQuery = {
          operationName: "User",
          query: `query User($where: UserWhereUniqueInput!) ${request}`,
          variables: {
            where: { id: id }
          }
        }
        axios({
          url: endPoint,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        }).then((res) => {
          console.log(res)
          if (res.data.data.user.id) {
            resolve("hi")
          }
        })
      }
    })
  }

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      // localStorage.setItem("jwt_access_token", "on hold");
      // axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    this.emit("onLogout", "Logged out");
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };
}

const instance = new JwtService();

export default instance;
