import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import API from "src/app/helper/ApiHelper.ts";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Dialog, Button, useTheme, Typography } from "@mui/material";
import FuseLoading from "@fuse/core/FuseLoading";

type DataTableColumns = {
  sessionName: string;
};

const FG_Module: React.FC = () => {
  const { id } = useParams();

  const endPoint = API.local;
  const headers = {
    "Content-Type": "application/json",
    //   authorization: `Bearer ${token}`,
  };
  const navigate = useNavigate();

  const [data, setData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);

  function getData() {
    setIsLoading(true);
    let sessionListArr = [] as Array<DataTableColumns>;
    const request = `{
        childMenu(where: $where) {
            url
            title
            priority
            childContent(where: $childContentWhere2, orderBy: $orderBy) {
              title
              session
              id
              contents(orderBy: $contentsOrderBy2) {
                title
                position
                priority
                langLabel {
                  lang
                  index
                  id
                }
                id
                content
                category
              }
              audios(orderBy: $audiosOrderBy2) {
                url
                type
                title
                priority
                position
                id
                category
              }
              images(orderBy: $imagesOrderBy2) {
                url
                type
                title
                priority
                position
                id
                category
              }
              videos(orderBy: $videosOrderBy2) {
                url
                type
                title
                priority
                position
                id
                category
              }
            }
            id
        }
        }`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: ChildMenuWhereUniqueInput!, $childContentWhere2: PageContentWhereInput!, $orderBy: [PageContentOrderByInput!]!,$contentsOrderBy2: [ContentDetailOrderByInput!]!, $imagesOrderBy2: [ImageDetailOrderByInput!]!,
        $audiosOrderBy2: [AudioDetailOrderByInput!]!, $videosOrderBy2: [VideoDetailOrderByInput!]!) ${request}`,
      variables: {
        where: { title: id },
        childContentWhere2: {
          title: {
            notIn: "Main Page Information",
          },
        },
        orderBy: [
          {
            session: "asc",
          },
        ],
        contentsOrderBy2: [
          {
            priority: "asc",
          },
        ],
        audiosOrderBy2: [
          {
            priority: "asc",
          },
        ],
        imagesOrderBy2: [
          {
            priority: "asc",
          },
        ],
        videosOrderBy2: [
          {
            priority: "asc",
          },
        ],
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    }).then((res) => {
      // console.log(res.data.data.childMenu);
      res.data.data.childMenu.childContent.forEach((e: any) => {
        sessionListArr.push({
          sessionName: e.title,
        });
      });
      setData(sessionListArr);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getData();
  }, [id]);

  const columns = [
    {
      label: "Session Title",
      name: "sessionName",
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          return (
            <div
              onClick={() => {
                //   displayPageDetails(e);
                navigate(
                  `/FG_ACT_SUB/?module=${id.split("e")[1]}&sub=${
                    data[dataIndex].sessionName
                  }`
                );
              }}
              style={{ cursor: "pointer" }}
            >
              {data[dataIndex].sessionName}
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <div
        style={{
          marginTop: "50px",
          maxWidth: "1600px",
          marginLeft: "25px",
          marginRight: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ fontSize: 18, marginLeft: "15px" }}
            color="text.secondary"
            gutterBottom
          >
            CMS - {id}
          </Typography>
          <div style={{ marginRight: "30px", position: "relative", bottom: 6 }}>
            <Button
              onClick={() => {
                navigate(`/Facilitator-Guided_ACT`);
              }}
            >
              Back
            </Button>
          </div>
        </div>
        {/* <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            aria-label="add"
            size="small"
            onClick={() => handleOpenDialog()}
          >
            <AddIcon />
          </IconButton>
        </Stack> */}
        <Dialog
          open={isLoading}
          // onClose={handleCloseLoading}
          fullWidth
          // style={{ width: "100px", height: "100px" }}
        >
          <FuseLoading />
        </Dialog>
        <MUIDataTable
          columns={columns}
          data={data}
          title={""}
          options={{
            selectableRows: "none",
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 15],
            download: false,
            print: false,
            responsive: "standard",
            // search: false,
          }}
        ></MUIDataTable>
      </div>
    </>
  );
};

export default FG_Module;
