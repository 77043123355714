import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import {
  Card,
  TextField,
  CardMedia,
  Button,
  IconButton,
  Avatar,
  Stack,
  CardContent,
  Box,
  CardActions,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import FuseLoading from "@fuse/core/FuseLoading";
import API from "src/app/helper/ApiHelper.ts";
import AlertDialog from "../Components/Alert.tsx";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import DefaultIcon from "../Icon/defaultIcon.webp";

type cardDetails = {
  id: string;
  icon: any;
  originalIcon: string;
  iconID: string;
  previewPhoto: any;
  category: string;
  nameID: string;
  fullName: string;
  originalFullName: string;
  nameEngID: string;
  fullNameEng: string;
  originalFullNameEng: string;
  positionID: string;
  position: string;
  originalPosition: string;
  positionEngID: string;
  positionEng: string;
  originalPositionEng: string;
  educationID: string;
  education: string;
  originalEducation: string;
  educationEngID: string;
  educationEng: string;
  originalEducationEng: string;
  linkID: string;
  link: string;
  originalLink: string;
  cardPositionID: string;
  cardPosition: number;
  expand: boolean;
};

interface TextFieldElement extends HTMLElement {
  value: string;
}

const AboutCollaborator: React.FC = () => {
  const dispatch = useDispatch();

  const [internationalList, setInternationalList] = useState([]);
  const [localList, setLocalList] = useState([]);
  const { id } = useParams();
  // const API = require("../../../../api.config.json");
  const [pageInfo, setPageInfo] = useState() as any;
  const [changeDetected, setChangeDetected] = useState(false);
  // const [forRender, setForRender] = useState(false);
  const token = Cookies.get("jwt_authorization") || "not_login";
  const endPoint = API.local;
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  // for reload the page after any action user have take
  const [modified, setModified] = useState(false);

  const [render, setRender] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  async function checkChildOrParent() {
    const check = `{menuBar(where: $where){
        title
    }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!) ${check}`,
      variables: {
        where: {
          title: id,
        },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        if (!res.data.data.menuBar) {
          const request = `{childMenu(where: $where){
            id,
            title
            childContent {
                title,
                session,
                template,
                images {
                    url,
                    priority,
                    id,
                    category
                }
                id
                contents(where: $contentsWhere2) {
                    category,
                    content,
                    id,
                    priority,
                    position,
                    isDeleted,
                    langLabel{
                      id
                      lang
                      index
                    }
                }
                sessionImage
            }
          }}`;
          const graphqlQuery = {
            operationName: "Query",
            query: `query Query($where: ChildMenuWhereUniqueInput!,$contentsWhere2: ContentDetailWhereInput!) ${request}`,
            variables: {
              where: { title: id },
              contentsWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              console.log(res.data.data.childMenu);
              setPageInfo(res.data.data.childMenu);
              let receivedData = res.data.data.childMenu;
              let internationalArr: [cardDetails] = [] as any;
              let localArr: [cardDetails] = [] as any;
              if (res.data.data.childMenu.childContent[0]) {
                if (
                  res.data.data.childMenu.childContent[0].contents.length > 0
                ) {
                  let idList = [];
                  receivedData.childContent[0].contents.forEach((e: any) => {
                    idList.push({ id: e.category });
                  });
                  // console.log(idList);
                  let totalList = idList.reduce((acc: any, curr: any) => {
                    // console.log(acc);
                    let key = `${curr.id}`;
                    if (key in acc) {
                      acc[key]++;
                    } else {
                      acc[key] = 1;
                    }
                    return acc;
                  }, {});
                  for (const [key, value] of Object.entries(totalList)) {
                    if (key.split("_")[0] === "international") {
                      internationalArr.push({
                        icon: "",
                        originalIcon: "",
                        iconID: "",
                        previewPhoto: "",
                        id: "",
                        nameID: "",
                        fullName: "",
                        originalFullName: "",
                        nameEngID: "",
                        fullNameEng: "",
                        originalFullNameEng: "",
                        category: key,
                        positionID: "",
                        position: "",
                        originalPosition: "",
                        positionEngID: "",
                        positionEng: "",
                        originalPositionEng: "",
                        educationID: "",
                        education: "",
                        originalEducation: "",
                        educationEngID: "",
                        educationEng: "",
                        originalEducationEng: "",
                        linkID: "",
                        link: "",
                        originalLink: "",
                        cardPositionID: "",
                        cardPosition: null,
                        expand: false,
                      });
                    } else if (key.split("_")[0] === "local") {
                      localArr.push({
                        icon: "",
                        originalIcon: "",
                        iconID: "",
                        previewPhoto: "",
                        id: "",
                        nameID: "",
                        fullName: "",
                        originalFullName: "",
                        nameEngID: "",
                        fullNameEng: "",
                        originalFullNameEng: "",
                        category: key,
                        positionID: "",
                        position: "",
                        originalPosition: "",
                        positionEngID: "",
                        positionEng: "",
                        originalPositionEng: "",
                        educationID: "",
                        education: "",
                        originalEducation: "",
                        educationEngID: "",
                        educationEng: "",
                        originalEducationEng: "",
                        linkID: "",
                        link: "",
                        originalLink: "",
                        cardPositionID: "",
                        cardPosition: null,
                        expand: false,
                      });
                    }
                  }
                  receivedData.childContent[0].contents.forEach((e: any) => {
                    internationalArr.forEach(
                      (international: any, idx: number) => {
                        if (e.category === international.category) {
                          if (
                            e.position === "fullName" &&
                            e.langLabel[0].lang === "zh_hant"
                          ) {
                            internationalArr[idx]["fullName"] = e.content;
                            internationalArr[idx]["nameID"] = e.id;
                            internationalArr[idx]["originalFullName"] =
                              e.content;
                          } else if (
                            e.position === "position" &&
                            e.langLabel[0].lang === "zh_hant"
                          ) {
                            internationalArr[idx]["position"] = e.content;
                            internationalArr[idx]["positionID"] = e.id;
                            internationalArr[idx]["originalPosition"] =
                              e.content;
                          } else if (
                            e.position === "education" &&
                            e.langLabel[0].lang === "zh_hant"
                          ) {
                            internationalArr[idx]["education"] = e.content;
                            internationalArr[idx]["educationID"] = e.id;
                            internationalArr[idx]["originalEducation"] =
                              e.content;
                          } else if (e.position === "link") {
                            internationalArr[idx]["link"] = e.content;
                            internationalArr[idx]["linkID"] = e.id;
                            internationalArr[idx]["originalLink"] = e.content;
                          }
                          // English version
                          else if (
                            e.position === "fullNameEng" &&
                            e.langLabel[0].lang === "en"
                          ) {
                            internationalArr[idx]["fullNameEng"] = e.content;
                            internationalArr[idx]["nameEngID"] = e.id;
                            internationalArr[idx]["originalFullNameEng"] =
                              e.content;
                          } else if (
                            e.position === "positionEng" &&
                            e.langLabel[0].lang === "en"
                          ) {
                            internationalArr[idx]["positionEng"] = e.content;
                            internationalArr[idx]["positionEngID"] = e.id;
                            internationalArr[idx]["originalPositionEng"] =
                              e.content;
                          } else if (
                            e.position === "educationEng" &&
                            e.langLabel[0].lang === "en"
                          ) {
                            internationalArr[idx]["educationEng"] = e.content;
                            internationalArr[idx]["educationEngID"] = e.id;
                            internationalArr[idx]["originalEducationEng"] =
                              e.content;
                          } else if (e.position === "cardPosition") {
                            internationalArr[idx]["cardPosition"] = +e.content;
                            internationalArr[idx]["cardPositionID"] = e.id;
                          }
                        }
                      }
                    );
                    localArr.forEach((local: any, idx: number) => {
                      if (e.category === local.category) {
                        if (
                          e.position === "fullName" &&
                          e.langLabel[0].lang === "zh_hant"
                        ) {
                          localArr[idx]["fullName"] = e.content;
                          localArr[idx]["nameID"] = e.id;
                          localArr[idx]["originalFullName"] = e.content;
                        } else if (
                          e.position === "position" &&
                          e.langLabel[0].lang === "zh_hant"
                        ) {
                          localArr[idx]["position"] = e.content;
                          localArr[idx]["positionID"] = e.id;
                          localArr[idx]["originalPosition"] = e.content;
                        } else if (
                          e.position === "education" &&
                          e.langLabel[0].lang === "zh_hant"
                        ) {
                          localArr[idx]["education"] = e.content;
                          localArr[idx]["educationID"] = e.id;
                          localArr[idx]["originalEducation"] = e.content;
                        } else if (e.position === "link") {
                          localArr[idx]["link"] = e.content;
                          localArr[idx]["linkID"] = e.id;
                          localArr[idx]["originalLink"] = e.content;
                        }
                        // English version
                        else if (
                          e.position === "fullNameEng" &&
                          e.langLabel[0].lang === "en"
                        ) {
                          localArr[idx]["fullNameEng"] = e.content;
                          localArr[idx]["nameEngID"] = e.id;
                          localArr[idx]["originalFullNameEng"] = e.content;
                        } else if (
                          e.position === "positionEng" &&
                          e.langLabel[0].lang === "en"
                        ) {
                          // console.log("positionLocal", e);
                          localArr[idx]["positionEng"] = e.content;
                          localArr[idx]["positionEngID"] = e.id;
                          localArr[idx]["originalPositionEng"] = e.content;
                        } else if (
                          e.position === "educationEng" &&
                          e.langLabel[0].lang === "en"
                        ) {
                          localArr[idx]["educationEng"] = e.content;
                          localArr[idx]["educationEngID"] = e.id;
                          localArr[idx]["originalEducationEng"] = e.content;
                        } else if (e.position === "cardPosition") {
                          localArr[idx]["cardPosition"] = +e.content;
                          localArr[idx]["cardPositionID"] = e.id;
                        }
                      }
                    });
                  });
                  receivedData.childContent[0].images.forEach((e: any) => {
                    internationalArr.forEach(
                      (international: any, idx: number) => {
                        if (
                          e.category === international.category &&
                          e.url != ""
                        ) {
                          internationalArr[idx][
                            "icon"
                          ] = `${API.getImage}${e.url}`;
                          internationalArr[idx]["iconID"] = e.id;
                          internationalArr[idx][
                            "originalIcon"
                          ] = `${API.getImage}${e.url}`;
                        } else if (
                          e.category === international.category &&
                          e.url === ""
                        ) {
                          internationalArr[idx]["icon"] = DefaultIcon;
                          internationalArr[idx]["iconID"] = e.id;
                          internationalArr[idx]["originalIcon"] = DefaultIcon;
                        }
                      }
                    );
                    localArr.forEach((local: any, idx: number) => {
                      if (e.category === local.category && e.url != "") {
                        localArr[idx]["icon"] = `${API.getImage}${e.url}`;
                        localArr[idx]["iconID"] = e.id;
                        localArr[idx][
                          "originalIcon"
                        ] = `${API.getImage}${e.url}`;
                      } else if (
                        e.category === local.category &&
                        e.url === ""
                      ) {
                        localArr[idx]["icon"] = DefaultIcon;
                        localArr[idx]["iconID"] = e.id;
                        localArr[idx]["originalIcon"] = DefaultIcon;
                      }
                    });
                  });
                }
              }
              // console.log(internationalArr);
              // console.log(localArr);
              internationalArr.sort(
                (b, a) => +a.cardPosition - +b.cardPosition //[back]
                // (a, b) => +a.cardPosition - +b.cardPosition //[back]
              );
              localArr.sort((a, b) => +a.cardPosition - +b.cardPosition);

              setInternationalList(internationalArr);
              setLocalList(localArr);
            })
            .catch((err) => console.error("err", err));
        } else {
          const request = `{menuBar(where: $where) {
            id,
            title,
            parentContent {
              title
              session
              template
              images {
                url
                priority
                category
                id
              }
              id
              contents {
                priority
                id
                content
                category
                position
              }
              sessionImage
            }
          }
        }`;
          const graphqlQuery = {
            operationName: "Query",
            query: `query Query($where: MenuBarWhereUniqueInput!) ${request}`,
            variables: {
              where: { title: id },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => { })
            .catch((err) => console.error("err", err));
        }
      })
      .catch((err) => console.error(err));
  }
  const [languageList, setLanguageList] = useState([] as any);

  const [newCardArr, setNewCardArr] = useState([] as any);

  async function getLangLabel() {
    const request = `{languages{
      lang
      index
      id
    }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query ${request}`,
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        setLanguageList(res.data.data.languages);
      })
      .catch((err) => console.error("err", err));
  }

  async function deleteAllContent() {
    const request = `{deletePageContent(where: $where) {
      id
    }}`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($where:PageContentWhereUniqueInput!) ${request}`,
      variables: {
        where: {
          id: "cliba1z82004kny0iy0h83g82",
        },
      },
    };
    const result = await axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    });
    console.log(result);
  }

  useEffect(() => {
    checkChildOrParent();
    getLangLabel();
  }, [render]);

  useEffect(() => {
    if (changeDetected) {
      setInternationalList(internationalList);
      setLocalList(localList);
    }
    setChangeDetected(false);
  }, [changeDetected]);

  // to create a empty card
  function addInternational() {
    try {
      let currentInternational = internationalList.length;
      let newCardID =
        "international+" +
        `${currentInternational + 1}` +
        +new Date(Date.now());
      setInternationalList([
        ...internationalList,
        {
          id: newCardID,
          icon: "",
          originalIcon: "",
          iconID:
            "icon+" + `${currentInternational + 1}` + +new Date(Date.now()),
          previewPhoto: "",
          category: "",
          nameID:
            "name+" + `${currentInternational + 1}` + +new Date(Date.now()),
          fullName: "",
          originalFullName: "",
          nameEngID:
            "nameEng+" + `${currentInternational + 1}` + +new Date(Date.now()),
          fullNameEng: "",
          originalFullNameEng: "",
          positionID:
            "position+" + `${currentInternational + 1}` + +new Date(Date.now()),
          position: "",
          originalPosition: "",
          positionEngID:
            "positionEng+" +
            `${currentInternational + 1}` +
            +new Date(Date.now()),
          positionEng: "",
          originPositionEng: "",
          educationID:
            "education+" +
            `${currentInternational + 1}` +
            +new Date(Date.now()),
          education: "",
          originalEducation: "",
          educationEngID:
            "educationEng+" +
            `${currentInternational + 1}` +
            +new Date(Date.now()),
          educationEng: "",
          originalEducationEng: "",
          linkID:
            "link+" + `${currentInternational + 1}` + +new Date(Date.now()),
          link: "",
          originalLink: "",
          cardPosition: currentInternational + 1,
          cardPositionID: "",
        },
      ]);
      setNewCardArr([...newCardArr, { newCardID: newCardID }]);
    } catch (error) {
      console.error(error);
    }
  }

  function addLocal() {
    try {
      let currentLocal = localList.length;
      let newCardID = "local+" + `${currentLocal + 1}` + +new Date(Date.now());
      setLocalList([
        ...localList,
        {
          id: "local+" + `${currentLocal + 1}` + +new Date(Date.now()),
          icon: "",
          originalIcon: "",
          iconID: "icon+" + `${currentLocal + 1}` + +new Date(Date.now()),
          previewPhoto: "",
          category: "",
          nameID: "name+" + `${currentLocal + 1}` + +new Date(Date.now()),
          fullName: "",
          originalFullName: "",
          nameEngID: "nameEng+" + `${currentLocal + 1}` + +new Date(Date.now()),
          fullNameEng: "",
          originalFullNameEng: "",
          positionID:
            "position+" + `${currentLocal + 1}` + +new Date(Date.now()),
          position: "",
          originalPosition: "",
          positionEngID:
            "positionEng+" + `${currentLocal + 1}` + +new Date(Date.now()),
          positionEng: "",
          originPositionEng: "",
          educationID:
            "education+" + `${currentLocal + 1}` + +new Date(Date.now()),
          education: "",
          originalEducation: "",
          educationEngID:
            "educationEng+" + `${currentLocal + 1}` + +new Date(Date.now()),
          educationEng: "",
          originalEducationEng: "",
          linkID: "link+" + `${currentLocal + 1}` + +new Date(Date.now()),
          link: "",
          originalLink: "",
          cardPosition: currentLocal + 1,
          cardPositionID: "",
        },
      ]);
      setNewCardArr([...newCardArr, { newCardID: newCardID }]);
    } catch (error) {
      console.error(error);
    }
  }
  // to handle multi photo
  const handleFileSelect = (event: any, target: string, idx: number) => {
    try {
      if (target === "international") {
        const file = event.target.files[0];
        // setTicImage(file);
        const reader = new FileReader();
        internationalList[idx].icon = file;
        reader.onload = () => {
          internationalList[idx].previewPhoto = reader.result;
          setChangeDetected(true);
          dispatch(
            showMessage({
              message: `Selected Image is not Save`,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "warning",
            })
          );
        };
        reader.readAsDataURL(file);
      } else if (target === "local") {
        const file = event.target.files[0];
        localList[idx].icon = file;
        // setTicImage(file);
        const reader = new FileReader();
        reader.onload = () => {
          localList[idx].previewPhoto = reader.result;
          setChangeDetected(true);
          dispatch(
            showMessage({
              message: `Selected Image is not Save`,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "warning",
            })
          );
        };

        reader.readAsDataURL(file);
      }
    } catch (error) { }
  };

  // due to structure problem this is a function will run every sec to check the value is true to turn off the loading screen
  function checkValues(values: any[]) {
    return new Promise<void>((resolve, reject) => {
      if (values.every((value) => value === true)) {
        resolve();
      }
    });
  }

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [popUpMsg, setPopUpMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function handleScroll() {
      // Check if the user has scrolled down
      // if (firstEnter) {
      if (window.scrollY > 0) {
        // console.log(window.scrollY);
        // If the user has scrolled down, reset the scroll position to the top of the page
        window.scrollTo({ top: 10, behavior: "instant" });
      }
      // }
      // setFirstEnter(false);
    }

    // Call handleScroll to reset the scroll position when the component is mounted
    handleScroll();

    // Add an event listener to the window object to prevent scrolling
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component is unmounted
    window.removeEventListener("scroll", handleScroll);
  }, []);

  // to modify the card data
  async function modifyAboutCollaborator() {
    setIsDisabled(true);
    setIsLoading(true);
    setIsOpenAlert(false);
    // console.log(pageInfo);
    const chinese_zh_id = languageList.filter(
      (obj: any) => obj.lang === "zh_hant"
    )[0].id;
    const eng_id = languageList.filter((obj: any) => obj.lang === "en")[0].id;

    let internationalUpdate = false;
    let localUpdate = false;

    let promiseArray = [];
    let missingFields = false;
    if (pageInfo.childContent[0]) {
      await Promise.all(
        internationalList.map(async (international: any) => {
          if (international.id === "") {
            if (
              international.iconID === "" &&
              typeof international.icon != "string"
            ) {
              let formData = new FormData();

              formData.append("image", international.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(async (iconRes) => {
                  const request = `{createImageDetail(data: $data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: iconRes.data.url,
                        category: international.category,
                        position: `collaboratorIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  await axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  });
                });
            }
            if (
              international.icon != international.originalIcon &&
              international.iconID != ""
            ) {
              let formData = new FormData();

              formData.append("image", international.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  const request = `{updateImageDetail(where: $where, data: $data) {
              url
            }}`;
                  const graphqlQuery = {
                    operationName: "UpdateImageDetail",
                    query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, 
                $data: ImageDetailUpdateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                      },
                      where: {
                        id: international.iconID,
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((res) => {
                      // console.log("image", res);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((err) => console.error("image", err));
                })
                .catch((photoErr) => {
                  console.error(photoErr);
                });
            }
            if (international.fullName != international.originalFullName) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: international.fullName,
                  },
                  where: {
                    id: international.nameID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: international.fullName });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (international.position != international.originalPosition) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: international.position,
                  },
                  where: {
                    id: international.positionID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: international.position });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (international.education != international.originalEducation) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: international.education,
                  },
                  where: {
                    id: international.educationID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: international.education });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            //English version
            if (
              international.fullNameEng != international.originalFullNameEng
            ) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: international.fullNameEng,
                  },
                  where: {
                    id: international.nameEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: international.fullNameEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (
              international.positionEng != international.originalPositionEng
            ) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: international.positionEng,
                  },
                  where: {
                    id: international.positionEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: international.positionEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (
              international.educationEng != international.originalEducationEng
            ) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: international.educationEng,
                  },
                  where: {
                    id: international.educationEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: international.educationEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (international.link != international.originalLink) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: international.link,
                  },
                  where: {
                    id: international.linkID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: international.link });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
          } else if (international.id.includes("international")) {
            //missing icon
            // console.log("photo upload");
            if (international.icon != "") {
              let formData = new FormData();
              formData.append("image", international.icon);
              // console.log("run");

              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  // console.log("uploaded");
                  // console.log(photoRes);
                  const request = `{createImageDetail(data:$data){
                url
                category
                position
                master{
                  id
                }
              }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                        category: `international_${international.nameID.split("+")[1]
                          }`,
                        position: `collaboratorIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((createPRes) => {
                      // console.log(createPRes);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((createPErr) => console.error(createPErr));
                })
                .catch((photoErr) => {
                  // console.log("here?");
                  console.error(photoErr);
                });
            } else if (international.icon === "") {
              const request = `{createImageDetail(data:$data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
              const graphqlQuery = {
                operationName: "Mutation",
                query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    url: "",
                    category: `international_${international.nameID.split("+")[1]
                      }`,
                    position: `collaboratorIcon`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              })
                .then((createPRes) => {
                  // console.log(createPRes);
                  promiseArray.push({ content: "" });
                })
                .catch((createPErr) => console.error(createPErr));
            }

            const contextDetailsRequest = `{createContentDetails(data: $data){
                    content
                    position
                    category
                    master {
                      id
                    }
                    langLabel{
                      id
                      lang
                      index
                    }
                  }}`;
            const contextGraphqlQuery = {
              operationName: "CreateContentDetails",
              query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
              variables: {
                data: [
                  {
                    content: `${international.cardPosition}`,
                    position: "cardPosition",
                    category: `international_${international.nameID.split("+")[1]
                      }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: international.fullName,
                    position: "fullName",
                    category: `international_${international.nameID.split("+")[1]
                      }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: international.position,
                    position: "position",
                    category: `international_${international.positionID.split("+")[1]
                      }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: international.education,
                    position: "education",
                    category: `international_${international.educationID.split("+")[1]
                      }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  // English
                  {
                    content: international.fullNameEng,
                    position: "fullNameEng",
                    category: `international_${international.nameEngID.split("+")[1]
                      }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: international.positionEng,
                    position: "positionEng",
                    category: `international_${international.positionEngID.split("+")[1]
                      }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: international.educationEng,
                    position: "educationEng",
                    category: `international_${international.educationEngID.split("+")[1]
                      }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: international.link,
                    position: "link",
                    category: `international_${international.linkID.split("+")[1]
                      }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                ],
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: contextGraphqlQuery,
              headers: headers,
            })
              .then((createContextRes) => {
                console.log(createContextRes);
                promiseArray.push({ content: "doneUpdate" });
              })
              .catch((createContextErr) => console.error(createContextErr));
          }
        })
      );

      await Promise.all(
        localList.map(async (local: any) => {
          if (local.id === "") {
            if (local.iconID === "" && typeof local.icon != "string") {
              let formData = new FormData();

              formData.append("image", local.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(async (iconRes) => {
                  const request = `{createImageDetail(data: $data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: iconRes.data.url,
                        category: local.category,
                        position: `collaboratorIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  await axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  });
                });
            }
            if (local.icon != local.originalIcon && local.iconID != "") {
              let formData = new FormData();

              formData.append("image", local.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  const request = `{updateImageDetail(where: $where, data: $data) {
                  url
                }}`;
                  const graphqlQuery = {
                    operationName: "UpdateImageDetail",
                    query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, 
                    $data: ImageDetailUpdateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                      },
                      where: {
                        id: local.iconID,
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((res) => {
                      // console.log("image", res);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((err) => console.error("image", err));
                })
                .catch((photoErr) => {
                  console.error(photoErr);
                });
            }
            if (local.fullName != local.originalFullName) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                  id
                  content
                }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                    $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: local.fullName,
                  },
                  where: {
                    id: local.nameID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: local.fullName });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (local.position != local.originalPosition) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                  id
                  content
                }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                    $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: local.position,
                  },
                  where: {
                    id: local.positionID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: local.position });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (local.education != local.originalEducation) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                  id
                  content
                }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                    $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: local.education,
                  },
                  where: {
                    id: local.educationID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: local.education });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            // English
            if (local.fullNameEng != local.originalFullNameEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                  id
                  content
                }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                    $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: local.fullNameEng,
                  },
                  where: {
                    id: local.nameEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: local.fullNameEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (local.positionEng != local.originalPositionEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                  id
                  content
                }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                    $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: local.positionEng,
                  },
                  where: {
                    id: local.positionEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: local.positionEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (local.educationEng != local.originalEducationEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                  id
                  content
                }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                    $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: local.educationEng,
                  },
                  where: {
                    id: local.educationEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: local.educationEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (local.link != local.originalLink) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                  id
                  content
                }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                    $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: local.link,
                  },
                  where: {
                    id: local.linkID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: local.link });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
          } else if (local.id.includes("local")) {
            // missing icon

            // console.log("photo upload");
            if (local.icon != "") {
              let formData = new FormData();
              formData.append("image", local.icon);
              // console.log("run");

              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  // console.log("uploaded");
                  // console.log(photoRes);
                  const request = `{createImageDetail(data:$data){
                    url
                    category
                    position
                    master{
                      id
                    }
                  }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                        category: `local_${local.nameID.split("+")[1]}`,
                        position: `collaboratorIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((createPRes) => {
                      // console.log(createPRes);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((createPErr) => console.error(createPErr));
                })
                .catch((photoErr) => {
                  console.error("here?");
                  console.error(photoErr);
                });
            } else if (local.icon === "") {
              const request = `{createImageDetail(data:$data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
              const graphqlQuery = {
                operationName: "Mutation",
                query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    url: "",
                    category: `local_${local.nameID.split("+")[1]}`,
                    position: `collaboratorIcon`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              })
                .then((createPRes) => {
                  // console.log(createPRes);
                  promiseArray.push({ content: "" });
                })
                .catch((createPErr) => console.error(createPErr));
            }

            const contextDetailsRequest = `{createContentDetails(data: $data){
                        content
                        position
                        category
                        master {
                          id
                        }
                        langLabel{
                          id
                          lang
                          index
                        }
                      }}`;
            const contextGraphqlQuery = {
              operationName: "CreateContentDetails",
              query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
              variables: {
                data: [
                  {
                    content: `${local.cardPosition}`,
                    position: "cardPosition",
                    category: `local_${local.nameID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: local.fullName,
                    position: "fullName",
                    category: `local_${local.nameID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: local.position,
                    position: "position",
                    category: `local_${local.positionID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: local.education,
                    position: "education",
                    category: `local_${local.educationID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  // English version
                  {
                    content: local.fullNameEng,
                    position: "fullNameEng",
                    category: `local_${local.nameEngID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: local.positionEng,
                    position: "positionEng",
                    category: `local_${local.positionEngID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: local.educationEng,
                    position: "educationEng",
                    category: `local_${local.educationEngID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: local.link,
                    position: "link",
                    category: `local_${local.linkID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                ],
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: contextGraphqlQuery,
              headers: headers,
            })
              .then((createContextRes) => {
                // console.log(createContextRes);
                promiseArray.push({ content: "done update" });
              })
              .catch((createContextErr) => console.error(createContextErr));
          }
        })
      );

      Promise.all(promiseArray).then((res) => {
        internationalUpdate = true;
        localUpdate = true;
      });
      if (!missingFields) {
        setTimeout(() => {
          checkValues([internationalUpdate, localUpdate]).then(() => {
            setRender(!render);
            setIsDisabled(false);

            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `International & Local is updated`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
            }, 2000);
          });
        }, 1000);
      }
    } else {
      const request = `{createPageContent(data: $data){
        id,
        belongToChild {
          id
        }
      }}`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($data: PageContentCreateInput!) ${request}`,
        variables: {
          data: {
            belongToChild: {
              connect: { id: pageInfo.id },
            },
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then(async (res) => {
          // console.log("create res", res);
          await Promise.all(
            internationalList.map(async (international: any) => {
              //missing icon

              if (international.icon != "") {
                let formData = new FormData();
                formData.append("image", international.icon);
                // console.log("run");

                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((photoRes) => {
                    // console.log("uploaded");
                    // console.log(photoRes);
                    const request = `{createImageDetail(data:$data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
                    const graphqlQuery = {
                      operationName: "Mutation",
                      query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                      variables: {
                        data: {
                          url: photoRes.data.url,
                          category: `international_${international.nameID.split("+")[1]
                            }`,
                          position: `collaboratorIcon`,
                          master: {
                            connect: { id: res.data.data.createPageContent.id },
                          },
                        },
                      },
                    };
                    axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    })
                      .then((createPRes) => {
                        // console.log(createPRes);
                        promiseArray.push({ content: photoRes.data.url });
                      })
                      .catch((createPErr) => console.error(createPErr));
                  })
                  .catch((photoErr) => {
                    console.error(photoErr);
                  });
              } else if (international.icon === "") {
                const request = `{createImageDetail(data:$data){
                    url
                    category
                    position
                    master{
                      id
                    }
                  }}`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                  variables: {
                    data: {
                      url: "",
                      category: `international_${international.nameID.split("+")[1]
                        }`,
                      position: `collaboratorIcon`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((createPRes) => {
                    // console.log(createPRes);
                    promiseArray.push({ content: "" });
                  })
                  .catch((createPErr) => console.error(createPErr));
              }
              // console.log("photo upload");

              const contextDetailsRequest = `{createContentDetails(data: $data){
                      content
                      position
                      category
                      master {
                        id
                      }
                      langLabel{
                        id
                        lang
                        index
                      }
                    }}`;
              const contextGraphqlQuery = {
                operationName: "CreateContentDetails",
                query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
                variables: {
                  data: [
                    {
                      content: `${international.cardPosition}`,
                      position: "cardPosition",
                      category: `international_${international.nameID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                    {
                      content: international.fullName,
                      position: "fullName",
                      category: `international_${international.nameID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: international.position,
                      position: "position",
                      category: `international_${international.positionID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: international.education,
                      position: "education",
                      category: `international_${international.educationID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    // English version
                    {
                      content: international.fullNameEng,
                      position: "fullNameEng",
                      category: `international_${international.nameID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: international.positionEng,
                      position: "positionEng",
                      category: `international_${international.positionEngID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: international.educationEng,
                      position: "educationEng",
                      category: `international_${international.educationEngID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: international.link,
                      position: "link",
                      category: `international_${international.linkID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                  ],
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: contextGraphqlQuery,
                headers: headers,
              })
                .then((createContextRes) => {
                  // console.log(createContextRes);
                  promiseArray.push({ content: "done update" });
                })
                .catch((createContextErr) => console.error(createContextErr));
            })
          );

          await Promise.all(
            localList.map(async (local: any) => {
              //missing icon

              if (local.icon != "") {
                let formData = new FormData();
                formData.append("image", local.icon);
                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((photoRes) => {
                    const request = `{createImageDetail(data:$data){
                      url
                      category
                      position
                      master{
                        id
                      }
                    }}`;
                    const graphqlQuery = {
                      operationName: "Mutation",
                      query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                      variables: {
                        data: {
                          url: photoRes.data.url,
                          category: `local_${local.nameID.split("+")[1]}`,
                          position: `collaboratorIcon`,
                          master: {
                            connect: { id: res.data.data.createPageContent.id },
                          },
                        },
                      },
                    };
                    axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    })
                      .then((createPRes) => {
                        // console.log(createPRes);
                        promiseArray.push({ content: photoRes.data.url });
                      })
                      .catch((createPErr) => console.error(createPErr));
                  })
                  .catch((photoErr) => console.error(photoErr));
              } else if (local.icon === "") {
                const request = `{createImageDetail(data:$data){
                    url
                    category
                    position
                    master{
                      id
                    }
                  }}`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                  variables: {
                    data: {
                      url: "",
                      category: `local_${local.nameID.split("+")[1]}`,
                      position: `collaboratorIcon`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((createPRes) => {
                    // console.log(createPRes);
                    promiseArray.push({ content: "" });
                  })
                  .catch((createPErr) => console.error(createPErr));
              }

              const contextDetailsRequest = `{createContentDetails(data: $data){
                      content
                      position
                      category
                      master {
                        id
                      }
                      langLabel{
                        id
                        lang
                        index
                      }
                    }}`;
              const contextGraphqlQuery = {
                operationName: "CreateContentDetails",
                query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
                variables: {
                  data: [
                    {
                      content: `${local.cardPosition}`,
                      position: "cardPosition",
                      category: `local_${local.nameID.split("+")[1]}`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                    },
                    {
                      content: local.fullName,
                      position: "fullName",
                      category: `local_${local.nameID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: local.position,
                      position: "position",
                      category: `local_${local.positionID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: local.education,
                      position: "education",
                      category: `local_${local.educationID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    // English version
                    {
                      content: local.fullNameEng,
                      position: "fullNameEng",
                      category: `local_${local.nameID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: local.positionEng,
                      position: "positionEng",
                      category: `local_${local.positionEngID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: local.educationEng,
                      position: "educationEng",
                      category: `local_${local.educationEngID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: local.link,
                      position: "link",
                      category: `local_${local.linkID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                  ],
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: contextGraphqlQuery,
                headers: headers,
              })
                .then((createContextRes) => {
                  // console.log(createContextRes);
                  promiseArray.push({ content: "done update" });
                })
                .catch((createContextErr) => console.error(createContextErr));
            })
          );
        })
        .catch((err) => {
          console.error(err);
        });
      Promise.all(promiseArray).then((res) => {
        internationalUpdate = true;
        localUpdate = true;
      });
      if (!missingFields) {
        setTimeout(() => {
          checkValues([internationalUpdate, localUpdate]).then(() => {
            setRender(!render);
            setIsDisabled(false);
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `International & Local is updated`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
            }, 2000);
          });
        }, 1000);
      }
    }
  }

  // autosave

  async function autosave() {
    try {
      setIsDisabled(true);
      setIsLoading(true);
      setIsOpenAlert(false);
      // console.log(pageInfo);
      const chinese_zh_id = languageList.filter(
        (obj: any) => obj.lang === "zh_hant"
      )[0].id;
      const eng_id = languageList.filter((obj: any) => obj.lang === "en")[0].id;

      let internationalUpdate = false;
      let localUpdate = false;

      let promiseArray = [];
      let missingFields = false;
      if (pageInfo.childContent[0]) {
        await Promise.all(
          internationalList.map(async (international: any) => {
            if (international.id === "") {
              if (
                international.iconID === "" &&
                typeof international.icon != "string"
              ) {
                let formData = new FormData();

                formData.append("image", international.icon);
                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then(async (iconRes) => {
                    const request = `{createImageDetail(data: $data){
                    url
                    category
                    position
                    master{
                      id
                    }
                  }}`;
                    const graphqlQuery = {
                      operationName: "Mutation",
                      query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                      variables: {
                        data: {
                          url: iconRes.data.url,
                          category: international.category,
                          position: `collaboratorIcon`,
                          master: {
                            connect: { id: pageInfo.childContent[0].id },
                          },
                        },
                      },
                    };
                    await axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    });
                  });
              }
              if (
                international.icon != international.originalIcon &&
                international.iconID != ""
              ) {
                let formData = new FormData();

                formData.append("image", international.icon);
                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((photoRes) => {
                    const request = `{updateImageDetail(where: $where, data: $data) {
                url
              }}`;
                    const graphqlQuery = {
                      operationName: "UpdateImageDetail",
                      query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, 
                  $data: ImageDetailUpdateInput!) ${request}`,
                      variables: {
                        data: {
                          url: photoRes.data.url,
                        },
                        where: {
                          id: international.iconID,
                        },
                      },
                    };
                    axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    })
                      .then((res) => {
                        // console.log("image", res);
                        promiseArray.push({ content: photoRes.data.url });
                      })
                      .catch((err) => console.error("image", err));
                  })
                  .catch((photoErr) => {
                    console.error(photoErr);
                  });
              }
              if (international.fullName != international.originalFullName) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                id
                content
              }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                  $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: international.fullName,
                    },
                    where: {
                      id: international.nameID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: international.fullName });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
              if (international.position != international.originalPosition) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                id
                content
              }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                  $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: international.position,
                    },
                    where: {
                      id: international.positionID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: international.position });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
              if (international.education != international.originalEducation) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                id
                content
              }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                  $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: international.education,
                    },
                    where: {
                      id: international.educationID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: international.education });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
              //English version
              if (
                international.fullNameEng != international.originalFullNameEng
              ) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                id
                content
              }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                  $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: international.fullNameEng,
                    },
                    where: {
                      id: international.nameEngID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: international.fullNameEng });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
              if (
                international.positionEng != international.originalPositionEng
              ) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                id
                content
              }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                  $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: international.positionEng,
                    },
                    where: {
                      id: international.positionEngID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: international.positionEng });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
              if (
                international.educationEng != international.originalEducationEng
              ) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                id
                content
              }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                  $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: international.educationEng,
                    },
                    where: {
                      id: international.educationEngID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: international.educationEng });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
              if (international.link != international.originalLink) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                id
                content
              }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                  $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: international.link,
                    },
                    where: {
                      id: international.linkID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: international.link });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
            } else if (international.id.includes("international")) {
              //missing icon
              // console.log("photo upload");
              if (international.icon != "") {
                let formData = new FormData();
                formData.append("image", international.icon);
                // console.log("run");

                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((photoRes) => {
                    // console.log("uploaded");
                    // console.log(photoRes);
                    const request = `{createImageDetail(data:$data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
                    const graphqlQuery = {
                      operationName: "Mutation",
                      query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                      variables: {
                        data: {
                          url: photoRes.data.url,
                          category: `international_${international.nameID.split("+")[1]
                            }`,
                          position: `collaboratorIcon`,
                          master: {
                            connect: { id: pageInfo.childContent[0].id },
                          },
                        },
                      },
                    };
                    axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    })
                      .then((createPRes) => {
                        // console.log(createPRes);
                        promiseArray.push({ content: photoRes.data.url });
                      })
                      .catch((createPErr) => console.error(createPErr));
                  })
                  .catch((photoErr) => {
                    // console.log("here?");
                    console.error(photoErr);
                  });
              } else if (international.icon === "") {
                const request = `{createImageDetail(data:$data){
                    url
                    category
                    position
                    master{
                      id
                    }
                  }}`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                  variables: {
                    data: {
                      url: "",
                      category: `international_${international.nameID.split("+")[1]
                        }`,
                      position: `collaboratorIcon`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((createPRes) => {
                    // console.log(createPRes);
                    promiseArray.push({ content: "" });
                  })
                  .catch((createPErr) => console.error(createPErr));
              }

              const contextDetailsRequest = `{createContentDetails(data: $data){
                      content
                      position
                      category
                      master {
                        id
                      }
                      langLabel{
                        id
                        lang
                        index
                      }
                    }}`;
              const contextGraphqlQuery = {
                operationName: "CreateContentDetails",
                query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
                variables: {
                  data: [
                    {
                      content: `${international.cardPosition}`,
                      position: "cardPosition",
                      category: `international_${international.nameID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                    },
                    {
                      content: international.fullName,
                      position: "fullName",
                      category: `international_${international.nameID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: international.position,
                      position: "position",
                      category: `international_${international.positionID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: international.education,
                      position: "education",
                      category: `international_${international.educationID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    // English
                    {
                      content: international.fullNameEng,
                      position: "fullNameEng",
                      category: `international_${international.nameEngID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: international.positionEng,
                      position: "positionEng",
                      category: `international_${international.positionEngID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: international.educationEng,
                      position: "educationEng",
                      category: `international_${international.educationEngID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: international.link,
                      position: "link",
                      category: `international_${international.linkID.split("+")[1]
                        }`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                    },
                  ],
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: contextGraphqlQuery,
                headers: headers,
              })
                .then((createContextRes) => {
                  // console.log(createContextRes);
                  promiseArray.push({ content: "doneUpdate" });
                })
                .catch((createContextErr) => console.error(createContextErr));
            }
          })
        );

        await Promise.all(
          localList.map(async (local: any) => {
            if (local.id === "") {
              if (local.iconID === "" && typeof local.icon != "string") {
                let formData = new FormData();

                formData.append("image", local.icon);
                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then(async (iconRes) => {
                    const request = `{createImageDetail(data: $data){
                    url
                    category
                    position
                    master{
                      id
                    }
                  }}`;
                    const graphqlQuery = {
                      operationName: "Mutation",
                      query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                      variables: {
                        data: {
                          url: iconRes.data.url,
                          category: local.category,
                          position: `collaboratorIcon`,
                          master: {
                            connect: { id: pageInfo.childContent[0].id },
                          },
                        },
                      },
                    };
                    await axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    });
                  });
              }
              if (local.icon != local.originalIcon && local.iconID != "") {
                let formData = new FormData();

                formData.append("image", local.icon);
                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((photoRes) => {
                    const request = `{updateImageDetail(where: $where, data: $data) {
                    url
                  }}`;
                    const graphqlQuery = {
                      operationName: "UpdateImageDetail",
                      query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, 
                      $data: ImageDetailUpdateInput!) ${request}`,
                      variables: {
                        data: {
                          url: photoRes.data.url,
                        },
                        where: {
                          id: local.iconID,
                        },
                      },
                    };
                    axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    })
                      .then((res) => {
                        // console.log("image", res);
                        promiseArray.push({ content: photoRes.data.url });
                      })
                      .catch((err) => console.error("image", err));
                  })
                  .catch((photoErr) => {
                    console.error(photoErr);
                  });
              }
              if (local.fullName != local.originalFullName) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: local.fullName,
                    },
                    where: {
                      id: local.nameID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: local.fullName });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
              if (local.position != local.originalPosition) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: local.position,
                    },
                    where: {
                      id: local.positionID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: local.position });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
              if (local.education != local.originalEducation) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: local.education,
                    },
                    where: {
                      id: local.educationID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: local.education });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
              // English
              if (local.fullNameEng != local.originalFullNameEng) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: local.fullNameEng,
                    },
                    where: {
                      id: local.nameEngID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: local.fullNameEng });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
              if (local.positionEng != local.originalPositionEng) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: local.positionEng,
                    },
                    where: {
                      id: local.positionEngID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: local.positionEng });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
              if (local.educationEng != local.originalEducationEng) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: local.educationEng,
                    },
                    where: {
                      id: local.educationEngID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: local.educationEng });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
              if (local.link != local.originalLink) {
                const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
                const updateStTGraphqlQuery = {
                  operationName: "UpdateContentDetail",
                  query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                  variables: {
                    data: {
                      content: local.link,
                    },
                    where: {
                      id: local.linkID,
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: updateStTGraphqlQuery,
                  headers: headers,
                })
                  .then((update1TitleRes) => {
                    // console.log("content1", update1TitleRes);
                    promiseArray.push({ content: local.link });
                  })
                  .catch((update1TitleErr) =>
                    console.error("content1", update1TitleErr)
                  );
              }
            } else if (local.id.includes("local")) {
              // missing icon

              // console.log("photo upload");
              if (local.icon != "") {
                let formData = new FormData();
                formData.append("image", local.icon);
                // console.log("run");

                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((photoRes) => {
                    // console.log("uploaded");
                    // console.log(photoRes);
                    const request = `{createImageDetail(data:$data){
                      url
                      category
                      position
                      master{
                        id
                      }
                    }}`;
                    const graphqlQuery = {
                      operationName: "Mutation",
                      query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                      variables: {
                        data: {
                          url: photoRes.data.url,
                          category: `local_${local.nameID.split("+")[1]}`,
                          position: `collaboratorIcon`,
                          master: {
                            connect: { id: pageInfo.childContent[0].id },
                          },
                        },
                      },
                    };
                    axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    })
                      .then((createPRes) => {
                        // console.log(createPRes);
                        promiseArray.push({ content: photoRes.data.url });
                      })
                      .catch((createPErr) => console.error(createPErr));
                  })
                  .catch((photoErr) => {
                    console.error("here?");
                    console.error(photoErr);
                  });
              } else if (local.icon === "") {
                const request = `{createImageDetail(data:$data){
                    url
                    category
                    position
                    master{
                      id
                    }
                  }}`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                  variables: {
                    data: {
                      url: "",
                      category: `local_${local.nameID.split("+")[1]}`,
                      position: `collaboratorIcon`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((createPRes) => {
                    // console.log(createPRes);
                    promiseArray.push({ content: "" });
                  })
                  .catch((createPErr) => console.error(createPErr));
              }

              const contextDetailsRequest = `{createContentDetails(data: $data){
                          content
                          position
                          category
                          master {
                            id
                          }
                          langLabel{
                            id
                            lang
                            index
                          }
                        }}`;
              const contextGraphqlQuery = {
                operationName: "CreateContentDetails",
                query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
                variables: {
                  data: [
                    {
                      content: `${local.cardPosition}`,
                      position: "cardPosition",
                      category: `local_${local.nameID.split("+")[1]}`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                    },
                    {
                      content: local.fullName,
                      position: "fullName",
                      category: `local_${local.nameID.split("+")[1]}`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: local.position,
                      position: "position",
                      category: `local_${local.positionID.split("+")[1]}`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: local.education,
                      position: "education",
                      category: `local_${local.educationID.split("+")[1]}`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    // English version
                    {
                      content: local.fullNameEng,
                      position: "fullNameEng",
                      category: `local_${local.nameEngID.split("+")[1]}`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: local.positionEng,
                      position: "positionEng",
                      category: `local_${local.positionEngID.split("+")[1]}`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: local.educationEng,
                      position: "educationEng",
                      category: `local_${local.educationEngID.split("+")[1]}`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: local.link,
                      position: "link",
                      category: `local_${local.linkID.split("+")[1]}`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                    },
                  ],
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: contextGraphqlQuery,
                headers: headers,
              })
                .then((createContextRes) => {
                  // console.log(createContextRes);
                  promiseArray.push({ content: "done update" });
                })
                .catch((createContextErr) => console.error(createContextErr));
            }
          })
        );

        Promise.all(promiseArray).then((res) => {
          internationalUpdate = true;
          localUpdate = true;
        });
        setNewCardArr([]);

        return "done all";
      } else {
        const request = `{createPageContent(data: $data){
          id,
          belongToChild {
            id
          }
        }}`;
        const graphqlQuery = {
          operationName: "Mutation",
          query: `mutation Mutation($data: PageContentCreateInput!) ${request}`,
          variables: {
            data: {
              belongToChild: {
                connect: { id: pageInfo.id },
              },
            },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        })
          .then(async (res) => {
            // console.log("create res", res);
            await Promise.all(
              internationalList.map(async (international: any) => {
                //missing icon

                if (international.icon != "") {
                  let formData = new FormData();
                  formData.append("image", international.icon);
                  // console.log("run");

                  axios
                    .post(API.image, formData, {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    })
                    .then((photoRes) => {
                      // console.log("uploaded");
                      // console.log(photoRes);
                      const request = `{createImageDetail(data:$data){
                    url
                    category
                    position
                    master{
                      id
                    }
                  }}`;
                      const graphqlQuery = {
                        operationName: "Mutation",
                        query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                        variables: {
                          data: {
                            url: photoRes.data.url,
                            category: `international_${international.nameID.split("+")[1]
                              }`,
                            position: `collaboratorIcon`,
                            master: {
                              connect: {
                                id: res.data.data.createPageContent.id,
                              },
                            },
                          },
                        },
                      };
                      axios({
                        url: endPoint,
                        method: "post",
                        data: graphqlQuery,
                        headers: headers,
                      })
                        .then((createPRes) => {
                          // console.log(createPRes);
                          promiseArray.push({ content: photoRes.data.url });
                        })
                        .catch((createPErr) => console.error(createPErr));
                    })
                    .catch((photoErr) => {
                      console.error(photoErr);
                    });
                } else if (international.icon === "") {
                  const request = `{createImageDetail(data:$data){
                      url
                      category
                      position
                      master{
                        id
                      }
                    }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: "",
                        category: `international_${international.nameID.split("+")[1]
                          }`,
                        position: `collaboratorIcon`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((createPRes) => {
                      // console.log(createPRes);
                      promiseArray.push({ content: "" });
                    })
                    .catch((createPErr) => console.error(createPErr));
                }
                // console.log("photo upload");

                const contextDetailsRequest = `{createContentDetails(data: $data){
                        content
                        position
                        category
                        master {
                          id
                        }
                        langLabel{
                          id
                          lang
                          index
                        }
                      }}`;
                const contextGraphqlQuery = {
                  operationName: "CreateContentDetails",
                  query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
                  variables: {
                    data: [
                      {
                        content: international.fullName,
                        position: "fullName",
                        category: `international_${international.nameID.split("+")[1]
                          }`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                        langLabel: {
                          connect: { id: chinese_zh_id },
                        },
                      },
                      {
                        content: international.position,
                        position: "position",
                        category: `international_${international.positionID.split("+")[1]
                          }`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                        langLabel: {
                          connect: { id: chinese_zh_id },
                        },
                      },
                      {
                        content: international.education,
                        position: "education",
                        category: `international_${international.educationID.split("+")[1]
                          }`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                        langLabel: {
                          connect: { id: chinese_zh_id },
                        },
                      },
                      // English version
                      {
                        content: international.fullNameEng,
                        position: "fullNameEng",
                        category: `international_${international.nameID.split("+")[1]
                          }`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                        langLabel: {
                          connect: { id: eng_id },
                        },
                      },
                      {
                        content: international.positionEng,
                        position: "positionEng",
                        category: `international_${international.positionEngID.split("+")[1]
                          }`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                        langLabel: {
                          connect: { id: eng_id },
                        },
                      },
                      {
                        content: international.educationEng,
                        position: "educationEng",
                        category: `international_${international.educationEngID.split("+")[1]
                          }`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                        langLabel: {
                          connect: { id: eng_id },
                        },
                      },
                      {
                        content: international.link,
                        position: "link",
                        category: `international_${international.linkID.split("+")[1]
                          }`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                      },
                    ],
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: contextGraphqlQuery,
                  headers: headers,
                })
                  .then((createContextRes) => {
                    // console.log(createContextRes);
                    promiseArray.push({ content: "done update" });
                  })
                  .catch((createContextErr) => console.error(createContextErr));
              })
            );

            await Promise.all(
              localList.map(async (local: any) => {
                //missing icon

                if (local.icon != "") {
                  let formData = new FormData();
                  formData.append("image", local.icon);
                  axios
                    .post(API.image, formData, {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    })
                    .then((photoRes) => {
                      const request = `{createImageDetail(data:$data){
                        url
                        category
                        position
                        master{
                          id
                        }
                      }}`;
                      const graphqlQuery = {
                        operationName: "Mutation",
                        query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                        variables: {
                          data: {
                            url: photoRes.data.url,
                            category: `local_${local.nameID.split("+")[1]}`,
                            position: `collaboratorIcon`,
                            master: {
                              connect: {
                                id: res.data.data.createPageContent.id,
                              },
                            },
                          },
                        },
                      };
                      axios({
                        url: endPoint,
                        method: "post",
                        data: graphqlQuery,
                        headers: headers,
                      })
                        .then((createPRes) => {
                          // console.log(createPRes);
                          promiseArray.push({ content: photoRes.data.url });
                        })
                        .catch((createPErr) => console.error(createPErr));
                    })
                    .catch((photoErr) => console.error(photoErr));
                } else if (local.icon === "") {
                  const request = `{createImageDetail(data:$data){
                      url
                      category
                      position
                      master{
                        id
                      }
                    }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: "",
                        category: `local_${local.nameID.split("+")[1]}`,
                        position: `collaboratorIcon`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((createPRes) => {
                      // console.log(createPRes);
                      promiseArray.push({ content: "" });
                    })
                    .catch((createPErr) => console.error(createPErr));
                }

                const contextDetailsRequest = `{createContentDetails(data: $data){
                        content
                        position
                        category
                        master {
                          id
                        }
                        langLabel{
                          id
                          lang
                          index
                        }
                      }}`;
                const contextGraphqlQuery = {
                  operationName: "CreateContentDetails",
                  query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
                  variables: {
                    data: [
                      {
                        content: local.fullName,
                        position: "fullName",
                        category: `local_${local.nameID.split("+")[1]}`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                        langLabel: {
                          connect: { id: chinese_zh_id },
                        },
                      },
                      {
                        content: local.position,
                        position: "position",
                        category: `local_${local.positionID.split("+")[1]}`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                        langLabel: {
                          connect: { id: chinese_zh_id },
                        },
                      },
                      {
                        content: local.education,
                        position: "education",
                        category: `local_${local.educationID.split("+")[1]}`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                        langLabel: {
                          connect: { id: chinese_zh_id },
                        },
                      },
                      // English version
                      {
                        content: local.fullNameEng,
                        position: "fullNameEng",
                        category: `local_${local.nameID.split("+")[1]}`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                        langLabel: {
                          connect: { id: eng_id },
                        },
                      },
                      {
                        content: local.positionEng,
                        position: "positionEng",
                        category: `local_${local.positionEngID.split("+")[1]}`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                        langLabel: {
                          connect: { id: eng_id },
                        },
                      },
                      {
                        content: local.educationEng,
                        position: "educationEng",
                        category: `local_${local.educationEngID.split("+")[1]}`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                        langLabel: {
                          connect: { id: eng_id },
                        },
                      },
                      {
                        content: local.link,
                        position: "link",
                        category: `local_${local.linkID.split("+")[1]}`,
                        master: {
                          connect: { id: res.data.data.createPageContent.id },
                        },
                      },
                    ],
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: contextGraphqlQuery,
                  headers: headers,
                })
                  .then((createContextRes) => {
                    // console.log(createContextRes);
                    promiseArray.push({ content: "done update" });
                  })
                  .catch((createContextErr) => console.error(createContextErr));
              })
            );
          })
          .catch((err) => {
            console.error(err);
          });
        Promise.all(promiseArray).then((res) => {
          internationalUpdate = true;
          localUpdate = true;
        });
        setNewCardArr([]);

        return "done all";
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleInputChange =
    (index: number, target: string) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (target === "international") {
          const { name, value } = event.target;
          // console.log(name, value);
          // console.log(index);
          const newList = [...internationalList];

          newList[index][name] = value;

          setInternationalList(newList);
        } else if (target === "local") {
          const { name, value } = event.target;
          // console.log(name, value);
          // console.log(index);
          const newList = [...localList];

          newList[index][name] = value;

          setLocalList(newList);
        }
      };

  // double confirm delete function to pop up a dialog
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [currentSelectedCard, setCurrentSelectedCard] = useState({} as any);
  const [currentArrayNumber, setCurrentArrayNumber] = useState(null as number);

  const confirmDelete = (cardDetails: any, idx: number) => {
    setDeleteDialog(true);
    setCurrentSelectedCard(cardDetails);
    if (cardDetails.id != "") {
      setCurrentArrayNumber(idx);
    }
  };

  const deleteDialogClose = () => {
    setDeleteDialog(false);
  };

  // axios delete mutation

  const deleteCard = async () => {
    setIsOpenAlert(false);
    setIsLoading(true);
    // delete member who already created in database
    // console.log(currentSelectedCard);
    if (newCardArr.length > 0) {
      await autosave();
    }
    if (currentSelectedCard.id === "") {
      const request = `{updateContentDetails(data: $data) {
        id
        isDeleted
      }}`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
        variables: {
          data: [
            {
              where: {
                id: currentSelectedCard.cardPositionID,
              },
              data: {
                isDeleted: true,
              },
            },
            {
              where: {
                id: currentSelectedCard.nameID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.nameEngID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.positionID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.positionEngID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.educationID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.educationEngID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.linkID,
              },
              data: { isDeleted: true },
            },
          ],
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => { })
        .catch((err) => console.error(err));
      if (currentSelectedCard.iconID != "") {
        const imageRequest = `{deleteImageDetail(where: $where) {
          id
        }}`;
        const imageGraphqlQuery = {
          operationName: "DeleteImageDetail",
          query: `mutation DeleteImageDetail($where: ImageDetailWhereUniqueInput!) ${imageRequest}`,
          variables: {
            where: { id: currentSelectedCard.iconID },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: imageGraphqlQuery,
          headers: headers,
        })
          .then((res) => { })
          .catch((err) => console.error(err));
        setTimeout(() => {
          dispatch(
            showMessage({
              message: `Delete Complete`,
              autoHideDuration: 6000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "success",
            })
          );
        }, 2000);
        setDeleteDialog(false);
        setRender(!render);
      }
    } else {
      // deleted member who is not create in database
      let selectNewCardID: string;
      if (currentSelectedCard.id.includes("international")) {
        selectNewCardID = internationalList[currentArrayNumber].id;
        internationalList.splice(currentArrayNumber, 1);
      } else if (currentSelectedCard.id.includes("local")) {
        selectNewCardID = localList[currentArrayNumber].id;
        localList.splice(currentArrayNumber, 1);
      }
      newCardArr.forEach((e: any, newCardIDX: number) => {
        if (e.newCardID === selectNewCardID) {
          newCardArr.splice(newCardIDX, 1);
        }
      });
      setDeleteDialog(false);
      setChangeDetected(true);
      dispatch(
        showMessage({
          message: `Collaborator is deleted`,
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        })
      );
    }
    setIsLoading(false);
    setIsDisabled(false);
  };

  function moveToTheTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  function openCard(element: any) {
    element.expand = true;
    setModified(!modified);
  }

  function closeCard(element: any) {
    element.expand = false;
    setModified(!modified);
  }

  async function switchCardPriority(
    position: string,
    direction: string,
    cardIDX: number
  ) {
    // console.log(newCardArr.length);
    if (newCardArr.length === 0) {
      setIsLoading(true);

      if (position === "international" && direction === "up") {
        if (
          internationalList[cardIDX - 1] &&
          internationalList[cardIDX - 1].cardPositionID
        ) {
          if (
            internationalList[cardIDX - 1] &&
            internationalList[cardIDX - 1].cardPositionID != "" &&
            internationalList[cardIDX].cardPositionID != ""
          ) {
            const currentCard = internationalList[cardIDX];
            const previousCard = internationalList[cardIDX - 1];
            if (currentCard.cardPosition === previousCard.cardPosition) {
              currentCard.cardPosition = currentCard.cardPosition + 0.1;
            }
            const request = `{updateContentDetails(data: $data){
              id,
              content
            }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
              variables: {
                data: [
                  {
                    where: { id: currentCard.cardPositionID },
                    data: {
                      content: `${previousCard.cardPosition}`,
                    },
                  },
                  {
                    where: { id: previousCard.cardPositionID },
                    data: {
                      content: `${currentCard.cardPosition}`,
                    },
                  },
                ],
              },
            };
            let contentPromise = await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });

            await Promise.all([contentPromise]);
            setRender(!render);
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          } else {
            // console.log("you are the top");
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          }
        } else {
          setIsLoading(false);
        }
      } else if (position === "international" && direction === "down") {
        if (
          internationalList[cardIDX + 1] &&
          internationalList[cardIDX + 1].cardPositionID
        ) {
          if (
            internationalList[cardIDX + 1] &&
            internationalList[cardIDX + 1].cardPositionID != "" &&
            internationalList[cardIDX].cardPositionID != ""
          ) {
            const currentCard = internationalList[cardIDX];
            const previousCard = internationalList[cardIDX + 1];
            if (currentCard.cardPosition === previousCard.cardPosition) {
              previousCard.cardPosition = previousCard.cardPosition + 0.1;
            }
            const request = `{updateContentDetails(data: $data){
              id,
              content
            }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
              variables: {
                data: [
                  {
                    where: { id: currentCard.cardPositionID },
                    data: {
                      content: `${previousCard.cardPosition}`,
                    },
                  },
                  {
                    where: { id: previousCard.cardPositionID },
                    data: {
                      content: `${currentCard.cardPosition}`,
                    },
                  },
                ],
              },
            };
            let contentPromise = await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });
            await Promise.all([contentPromise]);
            setRender(!render);
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          } else {
            // console.log("you are the bottom");
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          }
        } else {
          setIsLoading(false);
        }
      } else if (position === "local" && direction === "up") {
        if (localList[cardIDX - 1] && localList[cardIDX - 1].cardPositionID) {
          if (
            localList[cardIDX - 1] &&
            localList[cardIDX - 1].cardPositionID != "" &&
            localList[cardIDX].cardPositionID != ""
          ) {
            const currentCard = localList[cardIDX];
            const previousCard = localList[cardIDX - 1];
            if (currentCard.cardPosition === previousCard.cardPosition) {
              currentCard.cardPosition = currentCard.cardPosition + 0.1;
            }
            const request = `{updateContentDetails(data: $data){
              id,
              content
            }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
              variables: {
                data: [
                  {
                    where: { id: currentCard.cardPositionID },
                    data: {
                      content: `${previousCard.cardPosition}`,
                    },
                  },
                  {
                    where: { id: previousCard.cardPositionID },
                    data: {
                      content: `${currentCard.cardPosition}`,
                    },
                  },
                ],
              },
            };
            let contentPromise = await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });

            await Promise.all([contentPromise]);
            setRender(!render);
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          } else {
            // console.log("you are the top");
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          }
        } else {
          setIsLoading(false);
        }
      } else if (position === "local" && direction === "down") {
        if (localList[cardIDX + 1] && localList[cardIDX + 1].cardPositionID) {
          if (
            localList[cardIDX + 1] &&
            localList[cardIDX + 1].cardPositionID != "" &&
            localList[cardIDX].cardPositionID != ""
          ) {
            const currentCard = localList[cardIDX];
            const previousCard = localList[cardIDX + 1];
            if (currentCard.cardPosition === previousCard.cardPosition) {
              previousCard.cardPosition = previousCard.cardPosition + 0.1;
            }
            const request = `{updateContentDetails(data: $data){
              id,
              content
            }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
              variables: {
                data: [
                  {
                    where: { id: currentCard.cardPositionID },
                    data: {
                      content: `${previousCard.cardPosition}`,
                    },
                  },
                  {
                    where: { id: previousCard.cardPositionID },
                    data: {
                      content: `${currentCard.cardPosition}`,
                    },
                  },
                ],
              },
            };
            let contentPromise = await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });

            await Promise.all([contentPromise]);
            setRender(!render);
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          } else {
            // console.log("you are the top");
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          }
        } else {
          setIsLoading(false);
        }
      }
    } else if (newCardArr.length > 0) {
      await autosave();
      newCardArr.splice(0, newCardArr.length);
      console.log(newCardArr);

      switchCardPriority(position, direction, cardIDX);
      setIsDisabled(false);
    }
  }

  async function addCardPosition(list: any) {
    console.log(pageInfo);
    console.log(list);
    setIsLoading(true);
    await Promise.all(
      list.map(async (listElement: any, idx: number) => {
        // console.log(listElement.category);
        // console.log(idx);
        const request = `{
          createContentDetail(data: $data) {
            priority
            position
            content
            category
            master {
              id
            }
          }
        }`;
        const graphqlQuery = {
          operationName: "CreateContentDetail",
          query: `mutation CreateContentDetail($data: ContentDetailCreateInput!) ${request}`,
          variables: {
            data: {
              content: `${+idx + 1}`,
              position: "cardPosition",
              category: listElement.category,
              master: {
                connect: { id: pageInfo.childContent[0].id },
              },
            },
          },
        };
        let result = await axios({
          url: endPoint,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        });
        console.log(result);
      })
    );
    setTimeout(() => {
      dispatch(
        showMessage({
          message: `Card Position has added`,
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        })
      );
      setIsLoading(false);
    }, 500);
  }

  return (
    <>
      {isOpenAlert && <AlertDialog message={popUpMsg} duration={3000} />}

      {/* <button onClick={() => addCardPosition(internationalList)}>
        international
      </button>
      <button onClick={() => addCardPosition(localList)}>local</button> */}
      <Dialog
        open={isLoading}
        // onClose={handleCloseLoading}
        fullWidth
      // style={{ width: "100px", height: "100px" }}
      >
        <FuseLoading />
      </Dialog>
      <div style={{ marginBottom: "20px", marginLeft: "20px" }}>
        <h2>
          <b>International</b>
        </h2>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}
      >
        {/* delete dialog */}
        <Dialog
          open={deleteDialog}
          onClose={deleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {currentSelectedCard.id === "" &&
            currentSelectedCard.category.includes("international") && (
              <>
                <DialogTitle id="alert-dialog-title">{`Delete International Collaborator`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Confirm Delete International Collaborator.
                  </DialogContentText>
                </DialogContent>
              </>
            )}
          {currentSelectedCard.id === "" &&
            currentSelectedCard.category.includes("local") && (
              <>
                <DialogTitle id="alert-dialog-title">{`Delete local Collaborator`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Confirm Delete local Collaborator.
                  </DialogContentText>
                </DialogContent>
              </>
            )}
          {currentSelectedCard.id != "" &&
            currentSelectedCard.id &&
            currentSelectedCard.id.includes("international") && (
              <>
                <DialogTitle id="alert-dialog-title">{`Delete International Collaborator`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Confirm Delete International Collaborator.
                  </DialogContentText>
                </DialogContent>
              </>
            )}
          {currentSelectedCard.id != "" &&
            currentSelectedCard.id &&
            currentSelectedCard.id.includes("local") && (
              <>
                <DialogTitle id="alert-dialog-title">{`Delete local Collaborator`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Confirm Delete local Collaborator.
                  </DialogContentText>
                </DialogContent>
              </>
            )}

          <DialogActions>
            <Button onClick={deleteCard} autoFocus>
              Delete
            </Button>
            <Button onClick={deleteDialogClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {/* delete dialog */}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {internationalList.map((e, idx) => {
            return (
              <div
                style={{ marginRight: "10px", marginBottom: "10px" }}
                key={`international_${idx}`}
              >
                {!e.expand && (
                  <div>
                    <Card sx={{ width: "250px", height: "250px" }}>
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <OpenInFullIcon
                            style={{
                              cursor: "pointer",
                              marginBottom: "5px",
                            }}
                            onClick={() => openCard(e)}
                          ></OpenInFullIcon>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          {e.previewPhoto != "" && (
                            <Avatar
                              alt="Remy Sharp"
                              src={e.previewPhoto}
                              sx={{ width: 150, height: 150 }}
                            ></Avatar>
                          )}

                          {typeof e.icon === "string" && (
                            <Avatar
                              alt="Remy Sharp"
                              src={e.icon}
                              sx={{ width: 150, height: 150 }}
                            ></Avatar>
                          )}

                          <div style={{ marginTop: "20px" }}>
                            {e.fullNameEng}
                          </div>
                          <div style={{ marginTop: "10px" }}></div>
                        </div>
                      </CardContent>
                    </Card>
                    {e.id === "" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginTop: "10px",
                        }}
                      >
                        <WestIcon
                          onClick={() =>
                            switchCardPriority("international", "up", idx)
                          }
                          style={{ cursor: "pointer" }}
                        />

                        <EastIcon
                          onClick={() =>
                            switchCardPriority("international", "down", idx)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {e.expand && (
                  <Card sx={{ minWidth: 275, maxWidth: 275 }}>
                    <div
                      style={{
                        minWidth: 275,
                        maxWidth: 27,
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                      }}
                    >
                      <CloseFullscreenIcon
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => closeCard(e)}
                      />
                      <CloseIcon
                        style={{
                          cursor: "pointer",
                          marginRight: 10,
                        }}
                        onClick={() => confirmDelete(e, idx)}
                      />
                    </div>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {e.previewPhoto != "" &&
                        e.previewPhoto != DefaultIcon && (
                          <Avatar
                            alt="Remy Sharp"
                            src={e.previewPhoto}
                            sx={{ width: 150, height: 150 }}
                          ></Avatar>
                        )}
                      {e.previewPhoto === DefaultIcon &&
                        e.icon != DefaultIcon && (
                          <Avatar
                            alt="Remy Sharp"
                            src={e.icon}
                            sx={{ width: 150, height: 150 }}
                          ></Avatar>
                        )}
                      {e.icon === DefaultIcon && e.previewPhoto === "" && (
                        <Avatar
                          alt="Remy Sharp"
                          src={DefaultIcon}
                          sx={{ width: 150, height: 150 }}
                        ></Avatar>
                      )}
                      <div style={{ marginTop: "10px" }}>
                        Suggested Ratio : 1:1
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        Recommend Size : 500x500
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        Rendered Size : 275x275
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Button variant="contained" component="label">
                          Upload
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={(e) =>
                              handleFileSelect(e, "international", idx)
                            }
                          />
                        </Button>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.nameID}
                        label="名字 (繁中)"
                        type="text"
                        name="fullName"
                        fullWidth
                        variant="standard"
                        value={e.fullName || ""}
                        onChange={handleInputChange(idx, "international")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count :{" "}
                          {Math.ceil(26 / 2.18)}
                          {` (Each word could not exceed ${Math.ceil(
                            14 / 2.18
                          )} characters)`}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.fullName.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>

                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.nameEngID}
                        label="Name (English)"
                        type="text"
                        name="fullNameEng"
                        fullWidth
                        variant="standard"
                        value={e.fullNameEng || ""}
                        onChange={handleInputChange(idx, "international")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 26
                          {" (Each word could not exceed 14 characters)"}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.fullNameEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.positionID}
                        label="職位 (繁中)"
                        type="text"
                        name="position"
                        fullWidth
                        variant="standard"
                        value={e.position || ""}
                        onChange={handleInputChange(idx, "international")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count :{" "}
                          {Math.ceil(100 / 2.18)}
                          {` (Each word could not exceed ${Math.ceil(
                            22 / 2.18
                          )} characters)`}
                        </div>
                        <div
                          style={{
                            color: "blue",
                          }}
                        >
                          Current characters count : {e.position.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.positionEngID}
                        label="Position (English)"
                        type="text"
                        name="positionEng"
                        fullWidth
                        variant="standard"
                        value={e.positionEng || ""}
                        onChange={handleInputChange(idx, "international")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 100
                          {" (Each word could not exceed 22 characters)"}
                        </div>
                        <div
                          style={{
                            color: "blue",
                          }}
                        >
                          Current characters count : {e.positionEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.educationID}
                        label="學歷 (繁中)"
                        type="text"
                        name="education"
                        fullWidth
                        variant="standard"
                        value={e.education || ""}
                        onChange={handleInputChange(idx, "international")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count :{" "}
                          {Math.ceil(175 / 2.18)}
                          {`(Each word could not exceed ${Math.ceil(
                            22 / 2.18
                          )} characters)`}
                        </div>
                        <div
                          style={{
                            color: "blue",
                          }}
                        >
                          Current characters count : {e.education.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.educationEngID}
                        label="Education (English)"
                        type="text"
                        name="educationEng"
                        fullWidth
                        variant="standard"
                        value={e.educationEng || ""}
                        onChange={handleInputChange(idx, "international")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 175
                          {" (Each word could not exceed 22 characters)"}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.educationEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.linkID}
                        label="Link"
                        type="text"
                        name="link"
                        fullWidth
                        variant="standard"
                        value={e.link || ""}
                        onChange={handleInputChange(idx, "international")}
                      ></TextField>
                    </CardContent>
                  </Card>
                )}
              </div>
            );
          })}
          <Card
            sx={{ minWidth: 250, maxWidth: 250, minHeight: 70, maxHeight: 70 }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                // top: 150,
                alignItems: "center",
              }}
            >
              <Button onClick={() => addInternational()}>
                Add International
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
      <div
        style={{ marginBottom: "20px", marginTop: "20px", marginLeft: "20px" }}
      >
        <h2>
          <b>Local</b>
        </h2>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {localList.map((e, idx) => {
            return (
              <div
                style={{ marginRight: "10px", marginBottom: "10px" }}
                key={`local_${idx}`}
              >
                {!e.expand && (
                  <div>
                    <Card sx={{ width: "250px", height: "250px" }}>
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <OpenInFullIcon
                            style={{
                              cursor: "pointer",
                              marginBottom: "5px",
                            }}
                            onClick={() => openCard(e)}
                          ></OpenInFullIcon>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          {e.previewPhoto != "" && (
                            <Avatar
                              alt="Remy Sharp"
                              src={e.previewPhoto}
                              sx={{ width: 150, height: 150 }}
                            ></Avatar>
                          )}

                          {typeof e.icon === "string" && (
                            <Avatar
                              alt="Remy Sharp"
                              src={e.icon}
                              sx={{ width: 150, height: 150 }}
                            ></Avatar>
                          )}

                          <div style={{ marginTop: "20px" }}>
                            {e.fullNameEng}
                          </div>
                          <div style={{ marginTop: "10px" }}></div>
                        </div>
                      </CardContent>
                    </Card>
                    {e.id === "" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginTop: "10px",
                        }}
                      >
                        <WestIcon
                          onClick={() => switchCardPriority("local", "up", idx)}
                          style={{ cursor: "pointer" }}
                        />

                        <EastIcon
                          onClick={() =>
                            switchCardPriority("local", "down", idx)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {e.expand && (
                  <Card sx={{ minWidth: 275, maxWidth: 275 }}>
                    <div
                      style={{
                        minWidth: 275,
                        maxWidth: 27,
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                      }}
                    >
                      <CloseFullscreenIcon
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => closeCard(e)}
                      />
                      <CloseIcon
                        style={{
                          cursor: "pointer",
                          marginRight: 10,
                        }}
                        onClick={() => confirmDelete(e, idx)}
                      />
                    </div>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {e.previewPhoto != "" &&
                        e.previewPhoto != DefaultIcon && (
                          <Avatar
                            alt="Remy Sharp"
                            src={e.previewPhoto}
                            sx={{ width: 150, height: 150 }}
                          ></Avatar>
                        )}
                      {e.previewPhoto === DefaultIcon &&
                        e.icon != DefaultIcon && (
                          <Avatar
                            alt="Remy Sharp"
                            src={e.icon}
                            sx={{ width: 150, height: 150 }}
                          ></Avatar>
                        )}
                      {e.icon === DefaultIcon && e.previewPhoto === "" && (
                        <Avatar
                          alt="Remy Sharp"
                          src={DefaultIcon}
                          sx={{ width: 150, height: 150 }}
                        ></Avatar>
                      )}
                      <div style={{ marginTop: "10px" }}>
                        Suggested Ratio : 1:1
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        Recommend Size : 500x500
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        Rendered Size : 275x275
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Button variant="contained" component="label">
                          Upload
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={(e) => handleFileSelect(e, "local", idx)}
                          />
                        </Button>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.nameID}
                        label="名字 (繁中)"
                        type="text"
                        name="fullName"
                        fullWidth
                        variant="standard"
                        value={e.fullName || ""}
                        onChange={handleInputChange(idx, "local")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count :{" "}
                          {Math.ceil(26 / 2.18)}
                          {` (Each word could not exceed ${Math.ceil(
                            14 / 2.18
                          )} characters)`}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.fullName.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>

                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.nameEngID}
                        label="Name (English)"
                        type="text"
                        name="fullNameEng"
                        fullWidth
                        variant="standard"
                        value={e.fullNameEng || ""}
                        onChange={handleInputChange(idx, "local")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 26
                          {" (Each word could not exceed 14 characters)"}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.fullNameEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.positionID}
                        label="職位 (繁中)"
                        type="text"
                        name="position"
                        fullWidth
                        variant="standard"
                        value={e.position || ""}
                        onChange={handleInputChange(idx, "local")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count :{" "}
                          {Math.ceil(100 / 2.18)}
                          {`(Each word could not exceed ${Math.ceil(
                            22 / 2.18
                          )} characters)`}
                        </div>
                        <div
                          style={{
                            color: "blue",
                          }}
                        >
                          Current characters count : {e.position.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.positionEngID}
                        label="Position (English)"
                        type="text"
                        name="positionEng"
                        fullWidth
                        variant="standard"
                        value={e.positionEng || ""}
                        onChange={handleInputChange(idx, "local")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 100
                          {" (Each word could not exceed 22 characters)"}
                        </div>
                        <div
                          style={{
                            color: "blue",
                          }}
                        >
                          Current characters count : {e.positionEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.educationID}
                        label="學歷 (繁中)"
                        type="text"
                        name="education"
                        fullWidth
                        variant="standard"
                        value={e.education || ""}
                        onChange={handleInputChange(idx, "local")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count :{" "}
                          {Math.ceil(175 / 2.18)}
                          {`(Each word could not exceed ${Math.ceil(
                            22 / 2.18
                          )} characters)`}
                        </div>
                        <div
                          style={{
                            color: "blue",
                          }}
                        >
                          Current characters count : {e.education.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.educationEngID}
                        label="Education (English)"
                        type="text"
                        name="educationEng"
                        fullWidth
                        variant="standard"
                        value={e.educationEng || ""}
                        onChange={handleInputChange(idx, "local")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 175
                          {" (Each word could not exceed 22 characters)"}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.educationEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.linkID}
                        label="Link"
                        type="text"
                        name="link"
                        fullWidth
                        variant="standard"
                        value={e.link || ""}
                        onChange={handleInputChange(idx, "local")}
                      ></TextField>
                    </CardContent>
                  </Card>
                )}
              </div>
            );
          })}
          <Card
            sx={{ minWidth: 250, maxWidth: 250, minHeight: 70, maxHeight: 70 }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                position: "relative",
                // top: 150,
                alignItems: "center",
              }}
            >
              <Button onClick={() => addLocal()}>Add Local</Button>
            </CardContent>
          </Card>
        </div>
      </div>
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={modifyAboutCollaborator}
          variant="contained"
          component="label"
          disabled={isDisabled}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "15px",
          }}
        >
          Save
        </Button>
      </div>
      <br />
      <Button
        variant="contained"
        component="label"
        onClick={moveToTheTop}
        style={{
          position: "fixed",
          bottom: "75px",
          right: "15px",
          // cursor: "pointer",
        }}
      >
        <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
      </Button>
    </>
  );
};

export default AboutCollaborator;
