import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { selectUser } from "app/store/userSlice";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import axios from "axios";
import API from "src/app/helper/ApiHelper.ts";

function UserMenu(props) {
  // let user = useSelector(selectUser);
  // console.log(user);


  const [user, setUser] = useState(null)

  // user = { data: { displayName: "scott", email: "hihihi", photoURL: "" }, role: "admin" }
  const token = Cookies.get("jwt_authorization") || "not_login";
  // console.log(token)
  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  // console.log(Link);
  const jwtUserInfo = async () => {
    if (token === "not_login") {
      // console.log("not login")
      return
    }
    // const API = require("../../../api.config.json")
    const endPoint = API.local
    const headers = {
      "Content-Type": "application/json",
      // authorization: `Bearer ${token}`,
    };
    const { id } = jwtDecode(token)
    // console.log("id", jwtDecode(token))
    const request = `{user(where: $where){
              id,
              email,
              role,
              name
            }}`;

    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: UserWhereUniqueInput!) ${request}`,
      variables: {
        where: { id: id }
      }
    }
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    }).then((res) => {
      // console.log(res)

      if (res.data.data.user) {

        setUser({ data: { displayName: res.data.data.user.email, email: res.data.data.user.email, photoURL: "" }, role: res.data.data.user.role })
      }
    }).catch((err) => console.error(err))

  }

  useEffect(() => {
    jwtUserInfo()
  }, [token])

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >

        <div className="hidden md:flex flex-col mx-4 items-end">
          {user && <Typography component="span" className="font-semibold flex">
            {user.data.displayName}

          </Typography>}
          {user && <Typography
            className="text-11 font-medium capitalize"
            color="text.secondary"
          >
            {user.role.toString()}
            {(!user.role ||
              (Array.isArray(user.role) && user.role.length === 0)) &&
              "Guest"}
          </Typography>}

        </div>
        {user && <div> {user.data.photoURL ? (
          <Avatar
            className="md:mx-4"
            alt="user photo"
            src={user.data.photoURL}
          />
        ) : (
          <Avatar className="md:mx-4">{user.data.displayName[0]}</Avatar>
        )}</div>}

      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {user && <div>  {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem component={Link} to="/sign-in" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:lock-closed</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </MenuItem>
            {/* <MenuItem component={Link} to="/sign-up" role="button">
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-add </FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign up" />
            </MenuItem> */}
          </>
        ) : (
          <>
            {/* <MenuItem
              component={Link}
              to="/apps/profile"
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/apps/mailbox"
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:mail-open</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Inbox" />
            </MenuItem> */}
            <MenuItem
              component={NavLink}
              to="/sign-out"
              onClick={() => {
                userMenuClose();
                Cookies.remove("jwt_authorization");
              }}
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </MenuItem>
          </>
        )}</div>}

      </Popover>
    </>
  );
}

export default UserMenu;
