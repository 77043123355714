import React, { useEffect, useState } from "react";
import { condense } from "../graphql.ts";
import axios from "axios";
import API from "src/app/helper/ApiHelper.ts";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const DeletePageContentPanel: React.FC = () => {
  const token = Cookies.get("jwt_authorization") || "not_login";

  const currentUserInfo: any = jwtDecode(token);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUserInfo.role === "superAdmin") {
      console.log("superAdmin");
    } else {
      navigate("/404");
    }
  }, []);

  const endPoint = API.local;
  const headers = {
    "Content-Type": "application/json",
    // authorization: `Bearer ${token}`,
  };
  const [title, setTitle] = useState("");

  const [pageContent, setPageContent] = useState([]) as any;

  const [pageTitle, setPageTitle] = useState("");

  const [contentTitle, setContentTitle] = useState("");

  const [parentBoolean, setParentBoolean] = useState("");

  async function checkChildOrParent(id: string) {
    // if (token != "not_login") {
    //   let userInfo: any = jwtDecode(token);
    //   if (userInfo) {
    //     setRole(userInfo.role);
    //   }
    // }
    // console.log(id);
    // if (id === undefined) {
    //   if (moduleNumber) {
    //     id = `Module${moduleNumber}`;
    //   } else {
    //     id = "";
    //   }
    // }

    const check = `{menuBar(where: $where){
            title
        }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: condense(`query Query($where: MenuBarWhereUniqueInput!) ${check}`),
      variables: {
        where: {
          title: id,
        },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        if (!res.data.data.menuBar) {
          const request = `{childMenu(where: $where){
                id,
                childContent(orderBy: $orderBy) {
                  title
                  session
                  template
                  images(orderBy: $imagesOrderBy2,where:$imagesWhere2) {
                    url
                    priority
                    category
                    id
                    title
                    type
                    isDeleted
                  }
                  id
                  contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
                    priority
                    id
                    content
                    category
                    position
                    title
                    type
                    langLabel {
                      lang
                      index
                      id
                    }
                    isDeleted
                  }
                  videos(orderBy: $videosOrderBy2, where: $videosWhere2) {
                    url
                    priority
                    position
                    category
                    title
                    id
                    type
                    isDeleted
                  }
                  audios(orderBy: $audiosOrderBy2, where: $audiosWhere2) {
                    url
                    priority
                    position
                    category
                    title
                    id
                    type
                    isDeleted
                  }
                  pdf(orderBy: $pdfOrderBy2, where: $pdfWhere2){
                    originalFileName
                    position
                    priority
                    title
                    url
                    category
                    id
                    type
                    isDeleted
                  }
                  sessionImage
                }
              }}`;
          const graphqlQuery = {
            operationName: "Query",
            query:
              condense(`query Query($where: ChildMenuWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!,$contentsWhere2: ContentDetailWhereInput!, $imagesOrderBy2: [ImageDetailOrderByInput!]!,$imagesWhere2:ImageDetailWhereInput!,
                   $audiosOrderBy2: [AudioDetailOrderByInput!]!,$audiosWhere2: AudioDetailWhereInput!, $videosOrderBy2: [VideoDetailOrderByInput!]!,$videosWhere2: VideoDetailWhereInput!, $pdfOrderBy2: [PdfDetailOrderByInput!]!,$pdfWhere2: PdfDetailWhereInput) ${request}`),
            variables: {
              where: { title: id },
              contentsWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              imagesWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              videosWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              audiosWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              pdfWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              orderBy: [
                {
                  session: "asc",
                },
              ],
              contentsOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              imagesOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              audiosOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              videosOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              pdfOrderBy2: [
                {
                  priority: "asc",
                },
              ],
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              console.log(res);
              setPageContent(res.data.data.childMenu.childContent);
            })
            .catch((err) => console.error("err", err));
        } else {
          const request = `{menuBar(where: $where) {
                id,
                title,
                parentContent(orderBy: $orderBy) {
                  title
                  session
                  template
                  images(orderBy: $imagesOrderBy2,where:$imagesWhere2) {
                    url
                    priority
                    category
                    id
                    title
                    type
                    isDeleted
                  }
                  id
                  contents(orderBy: $contentsOrderBy2,where: $contentsWhere2) {
                    priority
                    id
                    content
                    category
                    position
                    title
                    type
                    langLabel {
                      lang
                      index
                      id
                    }
                    isDeleted
                  }
                  videos(orderBy: $videosOrderBy2, where: $videosWhere2) {
                    url
                    priority
                    position
                    category
                    title
                    id
                    type
                    isDeleted
                  }
                  audios(orderBy: $audiosOrderBy2, where: $audiosWhere2) {
                    url
                    priority
                    position
                    category
                    title
                    id
                    type
                    isDeleted
                  }
                  pdf(orderBy: $pdfOrderBy2, where: $pdfWhere2){
                    originalFileName
                    position
                    priority
                    title
                    url
                    category
                    id
                    type
                    isDeleted
                  }
                  sessionImage
                }
              }
            }`;
          const graphqlQuery = {
            operationName: "Query",
            query: condense(
              `query Query($where: MenuBarWhereUniqueInput!, $orderBy: [PageContentOrderByInput!]!, $contentsOrderBy2: [ContentDetailOrderByInput!]!,$contentsWhere2: ContentDetailWhereInput!, $imagesOrderBy2: [ImageDetailOrderByInput!]!,$imagesWhere2:ImageDetailWhereInput!,
                    $audiosOrderBy2: [AudioDetailOrderByInput!]!,$audiosWhere2: AudioDetailWhereInput!, $videosOrderBy2: [VideoDetailOrderByInput!]!,$videosWhere2: VideoDetailWhereInput!, $pdfOrderBy2: [PdfDetailOrderByInput!]!,$pdfWhere2: PdfDetailWhereInput) ${request}`
            ),
            variables: {
              where: { title: id },
              contentsWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              imagesWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              videosWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              audiosWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              pdfWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
              orderBy: [
                {
                  session: "asc",
                },
              ],
              contentsOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              imagesOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              audiosOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              videosOrderBy2: [
                {
                  priority: "asc",
                },
              ],
              pdfOrderBy2: [
                {
                  priority: "asc",
                },
              ],
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              console.log(res);
              setPageContent(res.data.data.menuBar.parentContent);
            })
            .catch((err) => console.error("err", err));
        }
      })
      .catch((err) => console.error(err));
  }

  const [showContent, setShowContent] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [editData, setEditData] = useState([] as any);

  const [modifyTitle, setModifyTitle] = useState("");

  async function hardDeleteContent(element: any) {
    const request = `{
        deleteContentDetail(where: $where){
            id
          }
    }`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($where: ContentDetailWhereUniqueInput!) ${request}`,
      variables: {
        where: { id: element.id },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => console.log(res))
      .catch((err) => {
        console.error("deleteError", err);
      });
    checkChildOrParent(title);
  }

  async function hardDeleteImage(element: any) {
    const request = `{
        deleteImageDetail(where: $where){
            id
          }
    }`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($where: ImageDetailWhereUniqueInput!) ${request}`,
      variables: {
        where: { id: element.id },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => console.log(res))
      .catch((err) => {
        console.error("deleteError", err);
      });
    checkChildOrParent(title);
  }

  async function hardDeleteAudio(element: any) {
    const request = `{
        deleteAudioDetail(where: $where){
            id
          }
    }`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($where: AudioDetailWhereUniqueInput!) ${request}`,
      variables: {
        where: { id: element.id },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => console.log(res))
      .catch((err) => {
        console.error("deleteError", err);
      });
    checkChildOrParent(title);
  }

  async function hardDeleteVideo(element: any) {
    const request = `{
        deleteVideoDetail(where: $where){
            id
          }
    }`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($where: VideoDetailWhereUniqueInput!) ${request}`,
      variables: {
        where: { id: element.id },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => console.log(res))
      .catch((err) => {
        console.error("deleteError", err);
      });
    checkChildOrParent(title);
  }

  async function hardDeletePdf(element: any) {
    const request = `{
        deletePdfDetail(where: $where){
            id
          }
    }`;
    const graphqlQuery = {
      operationName: "Mutation",
      query: `mutation Mutation($where: PdfDetailWhereUniqueInput!) ${request}`,
      variables: {
        where: { id: element.id },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => console.log(res))
      .catch((err) => {
        console.error("deleteError", err);
      });
    checkChildOrParent(title);
  }

  async function getSelectedContent() {
    if (parentBoolean === "y") {
      const request = `{menuBar(where: $where) {
        id,
        title,
        parentContent{
          title
          session
          template
          id
          contents(where: $contentsWhere2) {
            priority
            id
            content
            category
            position
            title
            type
            langLabel {
              lang
              index
              id
            }
            isDeleted
          }
          sessionImage
        }
      }
    }`;
      const graphqlQuery = {
        operationName: "Query",
        query: `query Query($where: MenuBarWhereUniqueInput!,$contentsWhere2: ContentDetailWhereInput!) ${request}`,
        variables: {
          where: { title: pageTitle },
          contentsWhere2: {
            title: { equals: contentTitle },
          },
        },
      };
      let result = await axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      });
      console.log(result);
      setEditData(result.data.data.menuBar);
    } else if (parentBoolean === "n") {
      const request = `{childMenu(where: $where) {
        id,
        title,
        childContent{
          title
          session
          template
          id
          contents(where: $contentsWhere2) {
            priority
            id
            content
            category
            position
            title
            type
            langLabel {
              lang
              index
              id
            }
            isDeleted
          }
          sessionImage
        }
      }
    }`;
      const graphqlQuery = {
        operationName: "Query",
        query: `query Query($where: ChildMenuWhereUniqueInput!,$contentsWhere2: ContentDetailWhereInput!) ${request}`,
        variables: {
          where: { title: pageTitle },
          contentsWhere2: {
            title: { equals: contentTitle },
          },
        },
      };
      let result = await axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      });
      console.log(result);
      setEditData(result.data.data.childMenu);
    } else {
      console.log("wrong password you should not be able to access this page");
    }
  }

  async function modifyMuliTitle(waitingForModifyList: any) {
    console.log(waitingForModifyList);
    await Promise.all(
      waitingForModifyList.contents.map(async (e: any) => {
        const request = `{updateContentDetail(where: $where, data:$data){
        title
        id
      }}`;
        const graphqlQuery = {
          operationName: "UpdateContentDetail",
          query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, $data: ContentDetailUpdateInput!) ${request}`,
          variables: {
            where: { id: e.id },
            data: {
              title: modifyTitle,
            },
          },
        };
        await axios({
          url: endPoint,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        });
      })
    );
    console.log("done all");
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <div>
          <textarea onChange={(e) => setTitle(e.target.value)}></textarea>
          <button onClick={() => checkChildOrParent(title)}>submit</button>
        </div>
        <div>
          PageTitle
          <textarea onChange={(e) => setPageTitle(e.target.value)}></textarea>
          ContentTitle
          <textarea
            onChange={(e) => setContentTitle(e.target.value)}
          ></textarea>
          <textarea
            onChange={(e) => setParentBoolean(e.target.value)}
          ></textarea>
          <button onClick={getSelectedContent}>submit</button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <button onClick={() => setShowContent(!showContent)}>
          Show Content
        </button>
        <button onClick={() => setShowImage(!showImage)}>Show Image</button>
        <button onClick={() => setShowVideo(!showVideo)}>Show Video</button>
        <button onClick={() => setShowAudio(!showAudio)}>Show Audio</button>
        <button onClick={() => setShowPdf(!showPdf)}>Show PDF</button>
        <button onClick={() => setShowEdit(!showEdit)}>Show Edit</button>
      </div>

      {showContent &&
        pageContent.map((e: any, index: number) => {
          return (
            <div
              key={"outer_" + index}
              style={{
                border: "3px solid black",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {"sessionNumber___" + e.session}
              {e.contents.map((content: any, idx: number) => {
                return (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                    }}
                  >
                    <div>{content.title} ___</div>

                    <div>{content.content} ___</div>
                    <div>{content.category} ___</div>
                    <div>{content.priority} </div>

                    <button onClick={() => hardDeleteContent(content)}>
                      Delete
                    </button>
                  </div>
                );
              })}
            </div>
          );
        })}
      {showImage &&
        pageContent.map((e: any, index: number) => {
          return (
            <div
              key={"outer_" + index}
              style={{
                border: "3px solid black",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {"sessionNumber___" + e.session}
              {e.images.map((content: any, idx: number) => {
                return (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                    }}
                  >
                    <div>{content.url} ___</div>
                    <div>{content.category} ___</div>
                    <div>{content.priority} </div>
                    <button onClick={() => hardDeleteImage(content)}>
                      delete
                    </button>
                  </div>
                );
              })}
            </div>
          );
        })}
      {showVideo &&
        pageContent.map((e: any, index: number) => {
          return (
            <div
              key={"outer_" + index}
              style={{
                border: "3px solid black",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {"sessionNumber___" + e.session}
              {e.videos.map((content: any, idx: number) => {
                return (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                    }}
                  >
                    <div>{content.url} ___</div>
                    <div>{content.category} ___</div>
                    <div>{content.priority} </div>
                    <button onClick={() => hardDeleteVideo(content)}>
                      delete
                    </button>
                  </div>
                );
              })}
            </div>
          );
        })}
      {showAudio &&
        pageContent.map((e: any, index: number) => {
          return (
            <div
              key={"outer_" + index}
              style={{
                border: "3px solid black",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {"sessionNumber___" + e.session}
              {e.audios.map((content: any, idx: number) => {
                return (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                    }}
                  >
                    <div>{content.url} ___</div>
                    <div>{content.category} ___</div>
                    <div>{content.priority} </div>
                    <button onClick={() => hardDeleteAudio(content)}>
                      delete
                    </button>
                  </div>
                );
              })}
            </div>
          );
        })}
      {showPdf &&
        pageContent.map((e: any, index: number) => {
          return (
            <div
              key={"outer_" + index}
              style={{
                border: "3px solid black",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {"sessionNumber___" + e.session}
              {e.pdf.map((content: any, idx: number) => {
                return (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                    }}
                  >
                    <div>{content.url} ___</div>
                    <div>{content.category} ___</div>
                    <div>{content.priority} </div>
                    <button onClick={() => hardDeletePdf(content)}>
                      delete
                    </button>
                  </div>
                );
              })}
            </div>
          );
        })}
      {showEdit &&
        editData.parentContent &&
        editData.parentContent.map((e: any, index: number) => {
          return (
            <div
              key={`edit${index}`}
              style={{
                border: "3px solid black",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {"sessionNumber___" + e.session}
              {e.contents.map((parentElement: any, parentIDX: number) => {
                return (
                  <div key={`parent${parentIDX}`}>{parentElement.content}</div>
                );
              })}
              <textarea
                onChange={(e) => setModifyTitle(e.target.value)}
              ></textarea>
              <button
                onClick={() => {
                  modifyMuliTitle(e);
                }}
              >
                update
              </button>
            </div>
          );
        })}
      {showEdit &&
        editData.childContent &&
        editData.childContent.map((e: any, index: number) => {
          return (
            <>
              <div
                key={`edit${index}`}
                style={{
                  border: "3px solid black",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                {"sessionNumber___" + e.session}
                {e.contents.map((parentElement: any, parentIDX: number) => {
                  return (
                    <div key={`child${parentIDX}`}>{parentElement.content}</div>
                  );
                })}
                <textarea
                  onChange={(e) => setModifyTitle(e.target.value)}
                ></textarea>
                <button
                  onClick={() => {
                    modifyMuliTitle(e);
                  }}
                >
                  update
                </button>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default DeletePageContentPanel;
