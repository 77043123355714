import React, { useEffect, useState } from "react";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
  ContentBlock,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { List } from "immutable";

import { stateFromHTML } from "draft-js-import-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./App.css";
import DOMPurify from "dompurify";

interface EditorDraftProps {
  onConvertToHtml: (html: string) => void;
  htmlRawValue: string;
  cardMaxWidth?: number;
}

function EditorDraft({
  onConvertToHtml,
  htmlRawValue,
  cardMaxWidth,
}: EditorDraftProps) {
  // const blocksFromHTML = convertFromHTML(`<div>${htmlRawValue || ""}</div>`);
  //   const contentState = ContentState.createFromBlockArray(
  //     blocksFromHTML.contentBlocks,
  //     blocksFromHTML.entityMap
  //   );
  const contentBlocks = htmlToDraft(htmlRawValue || "");
  const contentState = ContentState.createFromBlockArray(contentBlocks as any);

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (htmlRawValue && !loading) {
      setEditorState(EditorState.createWithContent(contentState));
    }
    if (htmlRawValue) {
      setLoading(true);
    }
    if (cardMaxWidth) {
    }
  }, [htmlRawValue, loading]);
  //   const handleHtmlImport = (html: string) => {
  //     const contentState = stateFromHTML(html);
  //     const newEditorState = EditorState.createWithContent(contentState);
  //     setEditorState(newEditorState);
  //   };

  const handleConvertToHtml = () => {
    const currentContent = editorState.getCurrentContent();
    const html = draftToHtml(convertToRaw(currentContent));
    // console.log(html);
    onConvertToHtml(html);
  };

  const config = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "list",
      "textAlign",
      // "colorPicker",
      "link",
      // "emoji",
      // "image",
      "remove",
      "history",
    ],
    fontSize: {
      options: [
        8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 48,
        60, 72, 96,
      ],
    },
  };

  return (
    <div className="App">
      {/* <header className="App-header">Rich Text Editor Example</header> */}
      {/* <button onClick={() => handleConvertToHtml()}>Convert to HTML</button> */}
      {/* <button onClick={() => console.log(htmlRawValue)}>click me</button> */}
      {/* <button onClick={() => console.log(editorState)}>click me</button> */}
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        onChange={handleConvertToHtml}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={config}
      />
      {/* <button onClick={() => handleHtmlImport("<p>Sample content state</p>")}>
        Import HTML
      </button> */}
    </div>
  );
}

export default EditorDraft;
