/**
 * Authorization Roles
 */
const authRoles = {
  admin: ["admin"],
  staff: ["admin", "staff"],
  user: ["admin", "staff", "user"],
  onlyGuest: ["guest"],
  allUser: ["admin", "staff", "user", "guest"]
};

export default authRoles;
