import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { Formik, Form } from "formik";

const TemplateDisplay: React.FC<{ selectedTemplate: string }> = (props: {
  selectedTemplate: string;
}) => {
  const [t1Title, setT1Title] = useState("");
  const [t1Content, setT1Content] = useState("");
  const dialogData = {};

  async function addTemplate() {}

  return (
    <>
      {props.selectedTemplate === "Template_1" && (
        <Formik onSubmit={addTemplate} initialValues={dialogData}>
          <Form>
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Title"
              type="text"
              name="text"
              fullWidth
              variant="standard"
              onChange={(e) => setT1Title(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Content"
              type="text"
              name="text"
              fullWidth
              variant="standard"
              onChange={(e) => setT1Content(e.target.value)}
            />
            <Button type="submit">submit</Button>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default TemplateDisplay;
