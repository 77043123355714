import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import {
  Card,
  TextField,
  CardMedia,
  Button,
  IconButton,
  Avatar,
  Stack,
  CardContent,
  Box,
  CardActions,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import EditorDraft from "../Components/EditorDraft.tsx";
import CloseIcon from "@mui/icons-material/Close";
import FuseLoading from "@fuse/core/FuseLoading";
import API from "src/app/helper/ApiHelper.ts";
import AlertDialog from "../Components/Alert.tsx";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from "app/store/fuse/messageSlice";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import DefaultIcon from "../Icon/defaultIcon.webp";

type cardDetails = {
  id: string;
  iconID: string;
  icon: any;
  previewPhoto: any;
  category: string;
  originalIcon: string;
  nameID: string;
  nameEngID: string;
  fullName: string;
  fullNameEng: string;
  originalFullName: string;
  originalFullNameEng: string;
  positionID: string;
  positionEngID: string;
  position: string;
  positionEng: string;
  originalPosition: string;
  originalPositionEng: string;
  educationID: string;
  educationEngID: string;
  education: string;
  educationEng: string;
  originalEducation: string;
  originalEducationEng: string;
  emailID: string;
  email: string;
  originalEmail: string;
  phoneID: string;
  phone: number;
  originalPhone: number;
  moreInfoID: string;
  moreInfoEngID: string;
  moreInfo: string;
  moreInfoEng: string;
  originalMoreInfo: string;
  originalMoreInfoEng: string;
  expand: boolean;
  cardPosition: number;
  cardPositionID: string;
};

const OurCounsellorAndTeam: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  // const API = require("../../../../api.config.json");
  const [pageInfo, setPageInfo] = useState() as any;
  const [counsellorList, setCounsellorList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [studentHelperList, setStudentHelperList] = useState([]);
  const [changeDetected, setChangeDetected] = useState(false);
  const [newCardArr, setNewCardArr] = useState([] as any);
  const [currentCounsellorMoreInfoWord, setCurrentCounsellorMoreInfoWord] =
    useState(0);
  const [
    currentCounsellorMoreInfoEngWord,
    setCurrentCounsellorMoreInfoEngWord,
  ] = useState(0);
  const [currentTeamMoreInfoWord, setCurrentTeamMoreInfoWord] = useState(0);
  const [currentTeamMoreInfoEngWord, setCurrentTeamMoreInfoEngWord] =
    useState(0);
  const [currentSHMoreInfoWord, setCurrentSHMoreInfoWord] = useState(0);
  const [currentSHMoreInfoEngWord, setCurrentSHMoreInfoEngWord] = useState(0);
  const token = Cookies.get("jwt_authorization") || "not_login";

  const endPoint = API.local;
  const headers = {
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  };

  // for reload the page after any action user have take
  const [render, setRender] = useState(false);

  async function checkChildOrParent() {
    const check = `{menuBar(where: $where){
            title
        }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query($where: MenuBarWhereUniqueInput!) ${check}`,
      variables: {
        where: {
          title: id,
        },
      },
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        if (!res.data.data.menuBar) {
          const request = `{childMenu(where: $where){
                id,
                title
                childContent {
                    title,
                    session,
                    template,
                    images {
                        url,
                        priority,
                        id,
                        category
                    }
                    id
                    contents(where: $contentsWhere2) {
                        category,
                        content,
                        id,
                        priority,
                        position,
                        isDeleted,
                        langLabel{
                          id
                          lang
                          index
                        }
                    }
                    sessionImage
                }
              }}`;
          const graphqlQuery = {
            operationName: "Query",
            query: `query Query($where: ChildMenuWhereUniqueInput!,$contentsWhere2: ContentDetailWhereInput!) ${request}`,
            variables: {
              where: { title: id },
              contentsWhere2: {
                isDeleted: {
                  equals: false,
                },
              },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {
              console.log(res.data.data);
              setPageInfo(res.data.data.childMenu);
              let receivedData = res.data.data.childMenu;
              let counsellorArr: [cardDetails] = [] as any;
              let teamArr: [cardDetails] = [] as any;
              let studentHelperArr: [cardDetails] = [] as any;
              if (res.data.data.childMenu.childContent[0]) {
                if (
                  res.data.data.childMenu.childContent[0].contents.length > 0
                ) {
                  let idList = [];
                  receivedData.childContent[0].contents.forEach((e: any) => {
                    idList.push({ id: e.category });
                  });
                  // console.log(idList);
                  let totalList = idList.reduce((acc: any, curr: any) => {
                    // console.log(acc);
                    let key = `${curr.id}`;
                    if (key in acc) {
                      acc[key]++;
                    } else {
                      acc[key] = 1;
                    }
                    return acc;
                  }, {});
                  for (const [key, value] of Object.entries(totalList)) {
                    if (key.split("_")[0] === "counsellor") {
                      counsellorArr.push({
                        icon: "",
                        originalIcon: "",
                        iconID: "",
                        previewPhoto: "",
                        id: "",
                        nameID: "",
                        nameEngID: "",
                        fullName: "",
                        fullNameEng: "",
                        originalFullName: "",
                        originalFullNameEng: "",
                        category: key,
                        positionID: "",
                        positionEngID: "",
                        position: "",
                        positionEng: "",
                        originalPosition: "",
                        originalPositionEng: "",
                        educationID: "",
                        educationEngID: "",
                        education: "",
                        educationEng: "",
                        originalEducation: "",
                        originalEducationEng: "",
                        emailID: "",
                        email: "",
                        originalEmail: "",
                        phoneID: "",
                        phone: null,
                        originalPhone: null,
                        moreInfoID: "",
                        moreInfoEngID: "",
                        moreInfo: "",
                        moreInfoEng: "",
                        originalMoreInfo: "",
                        originalMoreInfoEng: "",
                        cardPosition: null,
                        cardPositionID: "",
                        expand: false,
                      });
                    } else if (key.split("_")[0] === "team") {
                      teamArr.push({
                        icon: "",
                        originalIcon: "",
                        iconID: "",
                        previewPhoto: "",
                        id: "",
                        nameID: "",
                        nameEngID: "",
                        fullName: "",
                        fullNameEng: "",
                        originalFullName: "",
                        originalFullNameEng: "",
                        category: key,
                        positionID: "",
                        positionEngID: "",
                        position: "",
                        positionEng: "",
                        originalPosition: "",
                        originalPositionEng: "",
                        educationID: "",
                        educationEngID: "",
                        education: "",
                        educationEng: "",
                        originalEducation: "",
                        originalEducationEng: "",
                        emailID: "",
                        email: "",
                        originalEmail: "",
                        phoneID: "",
                        phone: null,
                        originalPhone: null,
                        moreInfoID: "",
                        moreInfoEngID: "",
                        moreInfo: "",
                        moreInfoEng: "",
                        originalMoreInfo: "",
                        originalMoreInfoEng: "",
                        cardPosition: null,
                        cardPositionID: "",
                        expand: false,
                      });
                    } else if (key.split("_")[0] === "studentHelper") {
                      studentHelperArr.push({
                        icon: "",
                        originalIcon: "",
                        iconID: "",
                        previewPhoto: "",
                        id: "",
                        nameID: "",
                        nameEngID: "",
                        fullName: "",
                        fullNameEng: "",
                        originalFullName: "",
                        originalFullNameEng: "",
                        category: key,
                        positionID: "",
                        positionEngID: "",
                        position: "",
                        positionEng: "",
                        originalPosition: "",
                        originalPositionEng: "",
                        educationID: "",
                        educationEngID: "",
                        education: "",
                        educationEng: "",
                        originalEducation: "",
                        originalEducationEng: "",
                        emailID: "",
                        email: "",
                        originalEmail: "",
                        phoneID: "",
                        phone: null,
                        originalPhone: null,
                        moreInfoID: "",
                        moreInfoEngID: "",
                        moreInfo: "",
                        moreInfoEng: "",
                        originalMoreInfo: "",
                        originalMoreInfoEng: "",
                        cardPosition: null,
                        cardPositionID: "",
                        expand: false,
                      });
                    }
                  }
                  // console.log(receivedData.childContent[0].contents);
                  receivedData.childContent[0].contents.forEach((e: any) => {
                    counsellorArr.forEach((counsellor: any, idx: number) => {
                      if (e.category === counsellor.category) {
                        if (
                          e.position === "fullName" &&
                          e.langLabel[0].lang === "zh_hant"
                        ) {
                          counsellorArr[idx]["fullName"] = e.content;
                          counsellorArr[idx]["nameID"] = e.id;
                          counsellorArr[idx]["originalFullName"] = e.content;
                        } else if (
                          e.position === "position" &&
                          e.langLabel[0].lang === "zh_hant"
                        ) {
                          counsellorArr[idx]["position"] = e.content;
                          counsellorArr[idx]["positionID"] = e.id;
                          counsellorArr[idx]["originalPosition"] = e.content;
                        } else if (
                          e.position === "education" &&
                          e.langLabel[0].lang === "zh_hant"
                        ) {
                          counsellorArr[idx]["education"] = e.content;
                          counsellorArr[idx]["educationID"] = e.id;
                          counsellorArr[idx]["originalEducation"] = e.content;
                        } else if (
                          e.position === "moreInfo" &&
                          e.langLabel[0].lang === "zh_hant"
                        ) {
                          counsellorArr[idx]["moreInfo"] = e.content;
                          counsellorArr[idx]["moreInfoID"] = e.id;
                          counsellorArr[idx]["originalMoreInfo"] = e.content;
                        }
                        // English version
                        else if (
                          e.position === "fullNameEng" &&
                          e.langLabel[0].lang === "en"
                        ) {
                          counsellorArr[idx]["fullNameEng"] = e.content;
                          counsellorArr[idx]["nameEngID"] = e.id;
                          counsellorArr[idx]["originalFullNameEng"] = e.content;
                        } else if (
                          e.position === "positionEng" &&
                          e.langLabel[0].lang === "en"
                        ) {
                          counsellorArr[idx]["positionEng"] = e.content;
                          counsellorArr[idx]["positionEngID"] = e.id;
                          counsellorArr[idx]["originalPositionEng"] = e.content;
                        } else if (
                          e.position === "educationEng" &&
                          e.langLabel[0].lang === "en"
                        ) {
                          // console.log("education", e);
                          counsellorArr[idx]["educationEng"] = e.content;
                          counsellorArr[idx]["educationEngID"] = e.id;
                          counsellorArr[idx]["originalEducationEng"] =
                            e.content;
                        } else if (
                          e.position === "moreInfoEng" &&
                          e.langLabel[0].lang === "en"
                        ) {
                          counsellorArr[idx]["moreInfoEng"] = e.content;
                          counsellorArr[idx]["moreInfoEngID"] = e.id;
                          counsellorArr[idx]["originalMoreInfoEng"] = e.content;
                        } else if (e.position === "email") {
                          counsellorArr[idx]["email"] = e.content;
                          counsellorArr[idx]["emailID"] = e.id;
                          counsellorArr[idx]["originalEmail"] = e.content;
                        } else if (e.position === "phone") {
                          // console.log(e);
                          counsellorArr[idx]["phone"] = e.content;
                          counsellorArr[idx]["phoneID"] = e.id;
                          counsellorArr[idx]["originalPhone"] = e.content;
                        } else if (e.position === "cardPosition") {
                          counsellorArr[idx]["cardPosition"] = +e.content;
                          counsellorArr[idx]["cardPositionID"] = e.id;
                        }
                      }
                    });
                    teamArr.forEach((team: any, idx: number) => {
                      if (e.category === team.category) {
                        if (
                          e.position === "fullName" &&
                          e.langLabel[0].lang === "zh_hant"
                        ) {
                          teamArr[idx]["fullName"] = e.content;
                          teamArr[idx]["nameID"] = e.id;
                          teamArr[idx]["originalFullName"] = e.content;
                        } else if (
                          e.position === "position" &&
                          e.langLabel[0].lang === "zh_hant"
                        ) {
                          teamArr[idx]["position"] = e.content;
                          teamArr[idx]["positionID"] = e.id;
                          teamArr[idx]["originalPosition"] = e.content;
                        } else if (
                          e.position === "education" &&
                          e.langLabel[0].lang === "zh_hant"
                        ) {
                          teamArr[idx]["education"] = e.content;
                          teamArr[idx]["educationID"] = e.id;
                          teamArr[idx]["originalEducation"] = e.content;
                        } else if (
                          e.position === "moreInfo" &&
                          e.langLabel[0].lang === "zh_hant"
                        ) {
                          teamArr[idx]["moreInfo"] = e.content;
                          teamArr[idx]["moreInfoID"] = e.id;
                          teamArr[idx]["originalMoreInfo"] = e.content;
                        }
                        // English Version
                        else if (
                          e.position === "fullNameEng" &&
                          e.langLabel[0].lang === "en"
                        ) {
                          teamArr[idx]["fullNameEng"] = e.content;
                          teamArr[idx]["nameEngID"] = e.id;
                          teamArr[idx]["originalFullNameEng"] = e.content;
                        } else if (
                          e.position === "positionEng" &&
                          e.langLabel[0].lang === "en"
                        ) {
                          // console.log("team position", e);
                          teamArr[idx]["positionEng"] = e.content;
                          teamArr[idx]["positionEngID"] = e.id;
                          teamArr[idx]["originalPositionEng"] = e.content;
                        } else if (
                          e.position === "educationEng" &&
                          e.langLabel[0].lang === "en"
                        ) {
                          teamArr[idx]["educationEng"] = e.content;
                          teamArr[idx]["educationEngID"] = e.id;
                          teamArr[idx]["originalEducationEng"] = e.content;
                        } else if (
                          e.position === "moreInfoEng" &&
                          e.langLabel[0].lang === "en"
                        ) {
                          teamArr[idx]["moreInfoEng"] = e.content;
                          teamArr[idx]["moreInfoEngID"] = e.id;
                          teamArr[idx]["originalMoreInfoEng"] = e.content;
                        } else if (e.position === "email") {
                          teamArr[idx]["email"] = e.content;
                          teamArr[idx]["emailID"] = e.id;
                          teamArr[idx]["originalEmail"] = e.content;
                        } else if (e.position === "phone") {
                          teamArr[idx]["phone"] = e.content;
                          teamArr[idx]["phoneID"] = e.id;
                          teamArr[idx]["originalPhone"] = e.content;
                        } else if (e.position === "cardPosition") {
                          teamArr[idx]["cardPosition"] = +e.content;
                          teamArr[idx]["cardPositionID"] = e.id;
                        }
                      }
                    });
                    studentHelperArr.forEach(
                      (studentHelper: any, idx: number) => {
                        if (e.category === studentHelper.category) {
                          if (
                            e.position === "fullName" &&
                            e.langLabel[0].lang === "zh_hant"
                          ) {
                            studentHelperArr[idx]["fullName"] = e.content;
                            studentHelperArr[idx]["nameID"] = e.id;
                            studentHelperArr[idx]["originalFullName"] =
                              e.content;
                          } else if (
                            e.position === "position" &&
                            e.langLabel[0].lang === "zh_hant"
                          ) {
                            studentHelperArr[idx]["position"] = e.content;
                            studentHelperArr[idx]["positionID"] = e.id;
                            studentHelperArr[idx]["originalPosition"] =
                              e.content;
                          } else if (
                            e.position === "education" &&
                            e.langLabel[0].lang === "zh_hant"
                          ) {
                            studentHelperArr[idx]["education"] = e.content;
                            studentHelperArr[idx]["educationID"] = e.id;
                            studentHelperArr[idx]["originalEducation"] =
                              e.content;
                          } else if (
                            e.position === "moreInfo" &&
                            e.langLabel[0].lang === "zh_hant"
                          ) {
                            studentHelperArr[idx]["moreInfo"] = e.content;
                            studentHelperArr[idx]["moreInfoID"] = e.id;
                            studentHelperArr[idx]["originalMoreInfo"] =
                              e.content;
                          }
                          // English Version
                          else if (
                            e.position === "fullNameEng" &&
                            e.langLabel[0].lang === "en"
                          ) {
                            studentHelperArr[idx]["fullNameEng"] = e.content;
                            studentHelperArr[idx]["nameEngID"] = e.id;
                            studentHelperArr[idx]["originalFullNameEng"] =
                              e.content;
                          } else if (
                            e.position === "positionEng" &&
                            e.langLabel[0].lang === "en"
                          ) {
                            // console.log("team position", e);
                            studentHelperArr[idx]["positionEng"] = e.content;
                            studentHelperArr[idx]["positionEngID"] = e.id;
                            studentHelperArr[idx]["originalPositionEng"] =
                              e.content;
                          } else if (
                            e.position === "educationEng" &&
                            e.langLabel[0].lang === "en"
                          ) {
                            studentHelperArr[idx]["educationEng"] = e.content;
                            studentHelperArr[idx]["educationEngID"] = e.id;
                            studentHelperArr[idx]["originalEducationEng"] =
                              e.content;
                          } else if (
                            e.position === "moreInfoEng" &&
                            e.langLabel[0].lang === "en"
                          ) {
                            studentHelperArr[idx]["moreInfoEng"] = e.content;
                            studentHelperArr[idx]["moreInfoEngID"] = e.id;
                            studentHelperArr[idx]["originalMoreInfoEng"] =
                              e.content;
                          } else if (e.position === "email") {
                            studentHelperArr[idx]["email"] = e.content;
                            studentHelperArr[idx]["emailID"] = e.id;
                            studentHelperArr[idx]["originalEmail"] = e.content;
                          } else if (e.position === "phone") {
                            studentHelperArr[idx]["phone"] = e.content;
                            studentHelperArr[idx]["phoneID"] = e.id;
                            studentHelperArr[idx]["originalPhone"] = e.content;
                          } else if (e.position === "cardPosition") {
                            studentHelperArr[idx]["cardPosition"] = +e.content;
                            studentHelperArr[idx]["cardPositionID"] = e.id;
                          }
                        }
                      }
                    );
                  });
                  // console.log(
                  //   "receivedData.childContent[0].images",
                  //   receivedData.childContent[0].images
                  // );
                  receivedData.childContent[0].images.forEach((e: any) => {
                    counsellorArr.forEach((counsellor: any, idx: number) => {
                      if (e.category === counsellor.category && e.url != "") {
                        counsellorArr[idx]["icon"] = `${API.getImage}${e.url}`;
                        counsellorArr[idx]["iconID"] = e.id;
                        counsellorArr[idx][
                          "originalIcon"
                        ] = `${API.getImage}${e.url}`;
                      } else if (
                        e.category === counsellor.category &&
                        e.url === ""
                      ) {
                        counsellorArr[idx]["icon"] = DefaultIcon;
                        counsellorArr[idx]["iconID"] = e.id;
                        counsellorArr[idx]["originalIcon"] = DefaultIcon;
                      }
                    });
                    teamArr.forEach((team: any, idx: number) => {
                      if (e.category === team.category && e.url != "") {
                        teamArr[idx]["icon"] = `${API.getImage}${e.url}`;
                        teamArr[idx]["iconID"] = e.id;
                        teamArr[idx][
                          "originalIcon"
                        ] = `${API.getImage}${e.url}`;
                      } else if (e.category === team.category && e.url === "") {
                        teamArr[idx]["icon"] = DefaultIcon;
                        teamArr[idx]["iconID"] = e.id;
                        teamArr[idx]["originalIcon"] = DefaultIcon;
                      }
                    });
                    studentHelperArr.forEach(
                      (studentHelper: any, idx: number) => {
                        if (
                          e.category === studentHelper.category &&
                          e.url != ""
                        ) {
                          studentHelperArr[idx][
                            "icon"
                          ] = `${API.getImage}${e.url}`;
                          studentHelperArr[idx]["iconID"] = e.id;
                          studentHelperArr[idx][
                            "originalIcon"
                          ] = `${API.getImage}${e.url}`;
                        } else if (
                          e.category === studentHelper.category &&
                          e.url === ""
                        ) {
                          studentHelperArr[idx]["icon"] = DefaultIcon;
                          studentHelperArr[idx]["iconID"] = e.id;
                          studentHelperArr[idx]["originalIcon"] = DefaultIcon;
                        }
                      }
                    );
                  });
                }
              }
              // console.log(counsellorArr);
              counsellorArr.sort((a, b) => +a.cardPosition - +b.cardPosition);
              teamArr.sort((a, b) => +a.cardPosition - +b.cardPosition);
              studentHelperArr.sort(
                (a, b) => +a.cardPosition - +b.cardPosition
              );
              // console.log(teamArr);
              setCounsellorList(counsellorArr);
              setTeamList(teamArr);
              setStudentHelperList(studentHelperArr);
            })
            .catch((err) => console.error("err", err));
        } else {
          const request = `{menuBar(where: $where) {
                id,
                title,
                parentContent {
                  title
                  session
                  template
                  images {
                    url
                    priority
                    category
                    id
                  }
                  id
                  contents {
                    priority
                    id
                    content
                    category
                    position
                  }
                  sessionImage
                }
              }
            }`;
          const graphqlQuery = {
            operationName: "Query",
            query: `query Query($where: MenuBarWhereUniqueInput!) ${request}`,
            variables: {
              where: { title: id },
            },
          };
          axios({
            url: endPoint,
            method: "post",
            data: graphqlQuery,
            headers: headers,
          })
            .then((res) => {})
            .catch((err) => console.log("err", err));
        }
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {
    checkChildOrParent();
    getLangLabel();
  }, [render, changeDetected]);

  // due to structure problem this is a function will run every sec to check the value is true to turn off the loading screen
  function checkValues(values: any[]) {
    return new Promise<void>((resolve, reject) => {
      if (values.every((value) => value === true)) {
        resolve();
      }
    });
  }

  const [isLoading, setIsLoading] = useState(false);

  const [openWordEditor, setOpenWordEditor] = useState(false);

  const [modified, setModified] = useState(false);

  // to handle multi photo
  const handleFileSelect = (event: any, target: string, idx: number) => {
    try {
      if (target === "counsellor") {
        const file = event.target.files[0];
        counsellorList[idx].icon = file;
        // setTicImage(file);
        const reader = new FileReader();
        reader.onload = () => {
          counsellorList[idx].previewPhoto = reader.result;
          setModified(!modified);
          dispatch(
            showMessage({
              message: `Selected Image is not Save`,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "warning",
            })
          );
        };
        reader.readAsDataURL(file);
      } else if (target === "team") {
        const file = event.target.files[0];
        teamList[idx].icon = file;
        // setTicImage(file);
        const reader = new FileReader();
        reader.onload = () => {
          teamList[idx].previewPhoto = reader.result;
          setModified(!modified);
          dispatch(
            showMessage({
              message: `Selected Image is not Save`,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "warning",
            })
          );
        };

        reader.readAsDataURL(file);
      } else if (target === "studentHelper") {
        const file = event.target.files[0];
        studentHelperList[idx].icon = file;
        // setTicImage(file);
        const reader = new FileReader();
        reader.onload = () => {
          studentHelperList[idx].previewPhoto = reader.result;
          setModified(!modified);
          dispatch(
            showMessage({
              message: `Selected Image is not Save`,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "warning",
            })
          );
        };

        reader.readAsDataURL(file);
      }
    } catch (error) {}
  };

  const handleMoreInfo = (
    index: number,
    target: string,
    moreInfo: boolean,
    html: string,
    lang: string
  ) => {
    if (moreInfo && lang === "zh_hant") {
      // console.log("here", html);
      if (target === "counsellor") {
        const newList = [...counsellorList];
        newList[index]["moreInfo"] = html;
        // console.log(newList[index]);

        setCounsellorList(newList);
      } else if (target === "team") {
        const newList = [...teamList];
        newList[index]["moreInfo"] = html;
        setTeamList(newList);
      } else if (target === "studentHelper") {
        const newList = [...studentHelperList];
        newList[index]["moreInfo"] = html;
        setStudentHelperList(newList);
      }
    } else if (moreInfo && lang === "en") {
      // console.log(html);

      if (target === "counsellor") {
        const newList = [...counsellorList];
        newList[index]["moreInfoEng"] = html;
        // console.log(newList[index]);
        setCounsellorList(newList);
      } else if (target === "team") {
        const newList = [...teamList];
        newList[index]["moreInfoEng"] = html;
        // console.log(newList[index]);
        setTeamList(newList);
      } else if (target === "studentHelper") {
        const newList = [...studentHelperList];
        newList[index]["moreInfoEng"] = html;
        // console.log(newList[index]);
        setStudentHelperList(newList);
      }
    }
  };

  const handleInputChange =
    (index: number, target: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (target === "counsellor") {
        const { name, value } = event.target;
        // console.log(name, value);
        // console.log(index);
        const newList = [...counsellorList];

        newList[index][name] = value;

        setCounsellorList(newList);
      } else if (target === "team") {
        const { name, value } = event.target;
        // console.log(name, value);
        // console.log(index);
        const newList = [...teamList];

        newList[index][name] = value;

        setTeamList(newList);
      } else if (target === "studentHelper") {
        const { name, value } = event.target;
        // console.log(name, value);
        // console.log(index);
        const newList = [...studentHelperList];

        newList[index][name] = value;

        setStudentHelperList(newList);
      }
    };

  function addCounsellor() {
    try {
      // console.log("hi?");
      let currentCounsellor = counsellorList.length;
      let newCardID =
        "counsellor+" + `${currentCounsellor + 1}` + +new Date(Date.now());
      setCounsellorList([
        ...counsellorList,
        {
          id: newCardID,
          iconID: "icon+" + `${currentCounsellor + 1}` + +new Date(Date.now()),
          icon: "",
          originalIcon: "",
          previewPhoto: "",
          category: "",
          nameID: "name+" + `${currentCounsellor + 1}` + +new Date(Date.now()),
          nameEngID:
            "nameEng+" + `${currentCounsellor + 1}` + +new Date(Date.now()),
          fullName: "",
          fullNameEng: "",
          originalFullName: "",
          originalFullNameEng: "",
          positionID:
            "position+" + `${currentCounsellor + 1}` + +new Date(Date.now()),
          positionEngID:
            "positionEng+" + `${currentCounsellor + 1}` + +new Date(Date.now()),
          position: "",
          positionEng: "",
          originalPosition: "",
          originalPositionEng: "",
          educationID:
            "education+" + `${currentCounsellor + 1}` + +new Date(Date.now()),
          educationEngID:
            "educationEng+" +
            `${currentCounsellor + 1}` +
            +new Date(Date.now()),
          education: "",
          educationEng: "",
          originalEducation: "",
          // originalEducationID: "",
          emailID:
            "email+" + `${currentCounsellor + 1}` + +new Date(Date.now()),
          email: "",
          originalEmail: "",
          phoneID:
            "phone+" + `${currentCounsellor + 1}` + +new Date(Date.now()),
          phone: null,
          originalPhone: null,
          moreInfoID:
            "moreInfo+" + `${currentCounsellor + 1}` + +new Date(Date.now()),
          moreInfoEngID:
            "moreInfoEng+" + `${currentCounsellor + 1}` + +new Date(Date.now()),
          moreInfo: "",
          moreInfoEng: "",
          originalMoreInfo: "",
          originalMoreInfoEng: "",
          cardPosition: currentCounsellor + 1,
          cardPositionID: "",
        },
      ]);
      setNewCardArr([...newCardArr, { newCardID: newCardID }]);
    } catch (error) {
      console.error(error);
    }
  }

  function addTeam() {
    try {
      let currentTeam = teamList.length;
      let newCardID = "team+" + `${currentTeam + 1}` + +new Date(Date.now());
      setTeamList([
        ...teamList,
        {
          id: newCardID,
          iconID: "icon+" + `${currentTeam + 1}` + +new Date(Date.now()),
          icon: "",
          originalIcon: "",
          previewPhoto: "",
          category: "",
          nameID: "name+" + `${currentTeam + 1}` + +new Date(Date.now()),
          nameEngID: "nameEng+" + `${currentTeam + 1}` + +new Date(Date.now()),
          fullName: "",
          fullNameEng: "",
          originalFullName: "",
          originalFullNameEng: "",
          positionID:
            "position+" + `${currentTeam + 1}` + +new Date(Date.now()),
          positionEngID:
            "positionEng+" + `${currentTeam + 1}` + +new Date(Date.now()),
          position: "",
          positionEng: "",
          originalPosition: "",
          originalPositionEng: "",
          educationID:
            "education+" + `${currentTeam + 1}` + +new Date(Date.now()),
          educationEngID:
            "educationEng+" + `${currentTeam + 1}` + +new Date(Date.now()),
          education: "",
          educationEng: "",
          originalEducation: "",
          // originalEducationID: "",
          emailID: "email+" + `${currentTeam + 1}` + +new Date(Date.now()),
          email: "",
          originalEmail: "",
          phoneID: "phone+" + `${currentTeam + 1}` + +new Date(Date.now()),
          phone: null,
          originalPhone: null,
          moreInfoID:
            "moreInfo+" + `${currentTeam + 1}` + +new Date(Date.now()),
          moreInfoEngID:
            "moreInfoEng+" + `${currentTeam + 1}` + +new Date(Date.now()),
          moreInfo: "",
          moreInfoEng: "",
          originalMoreInfo: "",
          originalMoreInfoEng: "",
          cardPosition: currentTeam + 1,
          cardPositionID: "",
        },
      ]);

      setNewCardArr([...newCardArr, { newCardID: newCardID }]);
    } catch (error) {
      console.error(error);
    }
  }

  function addStudentHelper() {
    try {
      // console.log("hi?");
      let currentStudent = studentHelperList.length;
      let newCardID =
        "studentHelper+" + `${currentStudent + 1}` + +new Date(Date.now());
      setStudentHelperList([
        ...studentHelperList,
        {
          id: newCardID,
          iconID: "icon+" + `${currentStudent + 1}` + +new Date(Date.now()),
          icon: "",
          originalIcon: "",
          previewPhoto: "",
          category: "",
          nameID: "name+" + `${currentStudent + 1}` + +new Date(Date.now()),
          nameEngID:
            "nameEng+" + `${currentStudent + 1}` + +new Date(Date.now()),
          fullName: "",
          fullNameEng: "",
          originalFullName: "",
          originalFullNameEng: "",
          positionID:
            "position+" + `${currentStudent + 1}` + +new Date(Date.now()),
          positionEngID:
            "positionEng+" + `${currentStudent + 1}` + +new Date(Date.now()),
          position: "",
          positionEng: "",
          originalPosition: "",
          originalPositionEng: "",
          educationID:
            "education+" + `${currentStudent + 1}` + +new Date(Date.now()),
          educationEngID:
            "educationEng+" + `${currentStudent + 1}` + +new Date(Date.now()),
          education: "",
          educationEng: "",
          originalEducation: "",
          // originalEducationID: "",
          emailID: "email+" + `${currentStudent + 1}` + +new Date(Date.now()),
          email: "",
          originalEmail: "",
          phoneID: "phone+" + `${currentStudent + 1}` + +new Date(Date.now()),
          phone: null,
          originalPhone: null,
          moreInfoID:
            "moreInfo+" + `${currentStudent + 1}` + +new Date(Date.now()),
          moreInfoEngID:
            "moreInfoEng+" + `${currentStudent + 1}` + +new Date(Date.now()),
          moreInfo: "",
          moreInfoEng: "",
          originalMoreInfo: "",
          originalMoreInfoEng: "",
          cardPosition: currentStudent + 1,
          cardPositionID: "",
        },
      ]);
      setNewCardArr([...newCardArr, { newCardID: newCardID }]);
    } catch (error) {
      console.error(error);
    }
  }

  const [deleteRender, setDeleteRender] = useState(false);

  useEffect(() => {
    if (deleteRender) {
      setCounsellorList(counsellorList);
      setTeamList(teamList);
    }
    setDeleteRender(false);
  }, [deleteRender]);

  useEffect(() => {
    function handleScroll() {
      // Check if the user has scrolled down
      // if (firstEnter) {
      if (window.scrollY > 0) {
        // console.log(window.scrollY);
        // If the user has scrolled down, reset the scroll position to the top of the page
        window.scrollTo({ top: 10, behavior: "instant" });
      }
      // }
      // setFirstEnter(false);
    }

    // Call handleScroll to reset the scroll position when the component is mounted
    handleScroll();

    // Add an event listener to the window object to prevent scrolling
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component is unmounted
    window.removeEventListener("scroll", handleScroll);
  }, []);

  // to get the language list
  const [languageList, setLanguageList] = useState([] as any);

  async function getLangLabel() {
    const request = `{languages{
      lang
      index
      id
    }}`;
    const graphqlQuery = {
      operationName: "Query",
      query: `query Query ${request}`,
    };
    axios({
      url: endPoint,
      method: "post",
      data: graphqlQuery,
      headers: headers,
    })
      .then((res) => {
        setLanguageList(res.data.data.languages);
      })
      .catch((err) => console.error("err", err));
  }

  // Alert Pop up
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [popUpMsg, setPopUpMsg] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  // to modify the card data
  async function modifyAboutThisPage() {
    setIsDisabled(true);
    setIsLoading(true);
    const chinese_zh_id = languageList.filter(
      (obj: any) => obj.lang === "zh_hant"
    )[0].id;
    const eng_id = languageList.filter((obj: any) => obj.lang === "en")[0].id;
    // console.log(pageInfo);

    let counsellorUpdate = false;
    let teamUpdate = false;
    // for checking update process, if done will push any object into it.
    let promiseArray = [];
    let missingFields = false;
    if (pageInfo.childContent[0]) {
      await Promise.all(
        counsellorList.map(async (counsellor: any) => {
          if (counsellor.id === "") {
            if (counsellor.phoneID === "") {
              const request = `{
              createContentDetail(data: $data) {
                priority
                position
                content
                category
                master {
                  id
                }
              }
            }`;
              const graphqlQuery = {
                operationName: "CreateContentDetail",
                query: `mutation CreateContentDetail($data: ContentDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    content: counsellor.phone,
                    position: "phone",
                    category: counsellor.category,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              await axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              });
              // console.log("result", result);
            }
            if (
              counsellor.iconID === "" &&
              typeof counsellor.icon != "string"
            ) {
              let formData = new FormData();

              formData.append("image", counsellor.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(async (iconRes) => {
                  const request = `{createImageDetail(data: $data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: iconRes.data.url,
                        category: counsellor.category,
                        position: `counsellorIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  let result = await axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  });
                  // console.log("hereeeeeeee", result);
                });
            }
            if (
              counsellor.icon != counsellor.originalIcon &&
              counsellor.iconID != ""
            ) {
              let formData = new FormData();

              formData.append("image", counsellor.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  const request = `{updateImageDetail(where: $where, data: $data) {
              url
            }}`;
                  const graphqlQuery = {
                    operationName: "UpdateImageDetail",
                    query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, 
                $data: ImageDetailUpdateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                      },
                      where: {
                        id: counsellor.iconID,
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((res) => {
                      // console.log("image", res);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((err) => console.error("image", err));
                })
                .catch((photoErr) => {
                  console.error(photoErr);
                });
            }

            if (counsellor.fullName != counsellor.originalFullName) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.fullName,
                  },
                  where: {
                    id: counsellor.nameID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.fullName });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.position != counsellor.originalPosition) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.position,
                  },
                  where: {
                    id: counsellor.positionID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.position });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.education != counsellor.originalEducation) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.education,
                  },
                  where: {
                    id: counsellor.educationID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.education });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.moreInfo != counsellor.originalMoreInfo) {
              // console.log("counsellor moreInfo", counsellor.moreInfo);
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.moreInfo,
                  },
                  where: {
                    id: counsellor.moreInfoID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.moreInfo });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.email != counsellor.originalEmail) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.email,
                  },
                  where: {
                    id: counsellor.emailID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.email });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.phone != counsellor.originalPhone) {
              // console.log("hello");
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.phone,
                  },
                  where: {
                    id: counsellor.phoneID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.phone });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            // English Version
            if (counsellor.fullNameEng != counsellor.originalFullNameEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.fullNameEng,
                  },
                  where: {
                    id: counsellor.nameEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.fullNameEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.positionEng != counsellor.originalPositionEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.positionEng,
                  },
                  where: {
                    id: counsellor.positionEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.positionEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.educationEng != counsellor.originalEducationEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.educationEng,
                  },
                  where: {
                    id: counsellor.educationEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.educationEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.moreInfoEng != counsellor.originalMoreInfoEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.moreInfoEng,
                  },
                  where: {
                    id: counsellor.moreInfoEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.moreInfoEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
          } else if (counsellor.id.includes("counsellor")) {
            //missing icon
            if (counsellor.icon != "") {
              let formData = new FormData();
              formData.append("image", counsellor.icon);
              // console.log("run");

              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  // console.log("uploaded");
                  // console.log(photoRes);
                  const request = `{createImageDetail(data:$data){
                url
                category
                position
                master{
                  id
                }
                
              }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                        category: `counsellor_${
                          counsellor.nameID.split("+")[1]
                        }`,
                        position: `counsellorIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((createPRes) => {
                      // console.log(createPRes);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((createPErr) => console.error(createPErr));
                })
                .catch((photoErr) => {
                  // console.log("here?");
                  console.error(photoErr);
                });
            } else if (counsellor.icon === "") {
              const request = `{createImageDetail(data:$data){
                  url
                  category
                  position
                  master{
                    id
                  }
                  
                }}`;
              const graphqlQuery = {
                operationName: "Mutation",
                query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    url: "",
                    category: `counsellor_${counsellor.nameID.split("+")[1]}`,
                    position: `counsellorIcon`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              })
                .then((createPRes) => {
                  // console.log(createPRes);
                  promiseArray.push({ content: "" });
                })
                .catch((createPErr) => console.error(createPErr));
            }
            // console.log("photo upload");

            const contextDetailsRequest = `{createContentDetails(data: $data){
                    content
                    position
                    category
                    master {
                      id
                    }
                    langLabel{
                      id
                      lang
                      index
                    }
                  }}`;
            const contextGraphqlQuery = {
              operationName: "CreateContentDetails",
              query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
              variables: {
                data: [
                  {
                    content: `${counsellor.cardPosition}`,
                    position: "cardPosition",
                    category: `counsellor_${counsellor.nameID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: counsellor.fullName,
                    position: "fullName",
                    category: `counsellor_${counsellor.nameID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: counsellor.position,
                    position: "position",
                    category: `counsellor_${
                      counsellor.positionID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: counsellor.education,
                    position: "education",
                    category: `counsellor_${
                      counsellor.educationID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: counsellor.moreInfo,
                    position: "moreInfo",
                    category: `counsellor_${
                      counsellor.moreInfoID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: counsellor.email,
                    position: "email",
                    category: `counsellor_${counsellor.emailID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: counsellor.phone,
                    position: "phone",
                    category: `counsellor_${counsellor.phoneID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  // English Version
                  {
                    content: counsellor.fullNameEng,
                    position: "fullNameEng",
                    category: `counsellor_${
                      counsellor.nameEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: counsellor.positionEng,
                    position: "positionEng",
                    category: `counsellor_${
                      counsellor.positionEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: counsellor.educationEng,
                    position: "educationEng",
                    category: `counsellor_${
                      counsellor.educationEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: counsellor.moreInfoEng,
                    position: "moreInfoEng",
                    category: `counsellor_${
                      counsellor.moreInfoEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                ],
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: contextGraphqlQuery,
              headers: headers,
            })
              .then((createContextRes) => {
                // console.log(createContextRes);
                promiseArray.push({ content: "done update" });
              })
              .catch((createContextErr) => console.error(createContextErr));
          }
        })
      );
      await Promise.all(
        teamList.map(async (team: any) => {
          if (team.id === "") {
            if (team.phoneID === "") {
              const request = `{
                      createContentDetail(data: $data) {
                        priority
                        position
                        content
                        category
                        master {
                          id
                        }
                      }
                    }`;
              const graphqlQuery = {
                operationName: "CreateContentDetail",
                query: `mutation CreateContentDetail($data: ContentDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    content: team.phone,
                    position: "phone",
                    category: team.category,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              await axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              });
              // console.log("result", result);
            }
            if (team.iconID === "" && typeof team.icon != "string") {
              let formData = new FormData();

              formData.append("image", team.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(async (iconRes) => {
                  const request = `{createImageDetail(data: $data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: iconRes.data.url,
                        category: team.category,
                        position: `collaboratorIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  await axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  });
                });
            }
            if (team.icon != team.originalIcon && team.iconID != "") {
              let formData = new FormData();

              formData.append("image", team.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  const request = `{updateImageDetail(where: $where, data: $data) {
                      url
                    }}`;
                  const graphqlQuery = {
                    operationName: "UpdateImageDetail",
                    query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, 
                        $data: ImageDetailUpdateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                      },
                      where: {
                        id: team.iconID,
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((res) => {
                      // console.log("image", res);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((err) => console.error("image", err));
                })
                .catch((photoErr) => {
                  console.error(photoErr);
                });
            }
            if (team.fullName != team.originalFullName) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.fullName,
                  },
                  where: {
                    id: team.nameID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.fullName });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.position != team.originalPosition) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.position,
                  },
                  where: {
                    id: team.positionID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.position });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.education != team.originalEducation) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.education,
                  },
                  where: {
                    id: team.educationID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.education });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.moreInfo != team.originalMoreInfo) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.moreInfo,
                  },
                  where: {
                    id: team.moreInfoID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.moreInfo });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.email != team.originalEmail) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.email,
                  },
                  where: {
                    id: team.emailID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.email });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.phone != team.originalPhone) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.phone,
                  },
                  where: {
                    id: team.phoneID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.phone });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            // English version
            if (team.fullNameEng != team.originalFullNameEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.fullNameEng,
                  },
                  where: {
                    id: team.nameEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.fullNameEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.positionEng != team.originalPositionEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.positionEng,
                  },
                  where: {
                    id: team.positionEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.positionEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.educationEng != team.originalEducationEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.educationEng,
                  },
                  where: {
                    id: team.educationEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.educationEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.moreInfoEng != team.originalMoreInfoEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.moreInfoEng,
                  },
                  where: {
                    id: team.moreInfoEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.moreInfoEng });
                })
                .catch((update1TitleErr) =>
                  console.log("content1", update1TitleErr)
                );
            }
          } else if (team.id.includes("team")) {
            //missing icon
            if (team.icon != "") {
              // console.log("photo upload");
              let formData = new FormData();
              formData.append("image", team.icon);
              // console.log("run");

              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  // console.log("uploaded");
                  // console.log(photoRes);
                  const request = `{createImageDetail(data:$data){
                        url
                        category
                        position
                        master{
                          id
                        }
                      }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                        category: `team_${team.nameID.split("+")[1]}`,
                        position: `collaboratorIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((createPRes) => {
                      // console.log(createPRes);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((createPErr) => console.error(createPErr));
                })
                .catch((photoErr) => {
                  // console.log("here?");
                  console.error(photoErr);
                });
            } else if (team.icon === "") {
              const request = `{createImageDetail(data:$data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
              const graphqlQuery = {
                operationName: "Mutation",
                query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    url: "",
                    category: `team_${team.nameID.split("+")[1]}`,
                    position: `collaboratorIcon`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              })
                .then((createPRes) => {
                  // console.log(createPRes);
                  promiseArray.push({ content: "" });
                })
                .catch((createPErr) => console.error(createPErr));
            }
            const contextDetailsRequest = `{createContentDetails(data: $data){
                            content
                            position
                            category
                            master {
                              id
                            }
                            langLabel{
                              id
                              lang
                              index
                            }
                          }}`;
            const contextGraphqlQuery = {
              operationName: "CreateContentDetails",
              query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
              variables: {
                data: [
                  {
                    content: `${team.cardPosition}`,
                    position: "cardPosition",
                    category: `team_${team.nameID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: team.fullName,
                    position: "fullName",
                    category: `team_${team.nameID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: team.position,
                    position: "position",
                    category: `team_${team.positionID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: team.education,
                    position: "education",
                    category: `team_${team.educationID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: team.email,
                    position: "email",
                    category: `team_${team.emailID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: team.phone,
                    position: "phone",
                    category: `team_${team.phoneID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: team.moreInfo,
                    position: "moreInfo",
                    category: `team_${team.moreInfoID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  // English version
                  {
                    content: team.fullNameEng,
                    position: "fullNameEng",
                    category: `team_${team.nameEngID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: team.positionEng,
                    position: "positionEng",
                    category: `team_${team.positionEngID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: team.educationEng,
                    position: "educationEng",
                    category: `team_${team.educationEngID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: team.moreInfoEng,
                    position: "moreInfoEng",
                    category: `team_${team.moreInfoEngID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                ],
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: contextGraphqlQuery,
              headers: headers,
            })
              .then((createContextRes) => {
                // console.log(createContextRes);
                promiseArray.push({ content: "done update" });
              })
              .catch((createContextErr) => console.error(createContextErr));
          }
        })
      );
      await Promise.all(
        studentHelperList.map(async (studentHelper: any) => {
          if (studentHelper.id === "") {
            if (studentHelper.phoneID === "") {
              const request = `{
                    createContentDetail(data: $data) {
                      priority
                      position
                      content
                      category
                      master {
                        id
                      }
                    }
                  }`;
              const graphqlQuery = {
                operationName: "CreateContentDetail",
                query: `mutation CreateContentDetail($data: ContentDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    content: studentHelper.phone,
                    position: "phone",
                    category: studentHelper.category,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              await axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              });
              // console.log("result", result);
            }
            if (
              studentHelper.iconID === "" &&
              typeof studentHelper.icon != "string"
            ) {
              let formData = new FormData();

              formData.append("image", studentHelper.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(async (iconRes) => {
                  const request = `{createImageDetail(data: $data){
                url
                category
                position
                master{
                  id
                }
              }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: iconRes.data.url,
                        category: studentHelper.category,
                        position: `studentHelperIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  await axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  });
                });
            }
            if (
              studentHelper.icon != studentHelper.originalIcon &&
              studentHelper.iconID != ""
            ) {
              let formData = new FormData();

              formData.append("image", studentHelper.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  const request = `{updateImageDetail(where: $where, data: $data) {
                    url
                  }}`;
                  const graphqlQuery = {
                    operationName: "UpdateImageDetail",
                    query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, 
                      $data: ImageDetailUpdateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                      },
                      where: {
                        id: studentHelper.iconID,
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((res) => {
                      // console.log("image", res);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((err) => console.error("image", err));
                })
                .catch((photoErr) => {
                  console.error(photoErr);
                });
            }
            if (studentHelper.fullName != studentHelper.originalFullName) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.fullName,
                  },
                  where: {
                    id: studentHelper.nameID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.fullName });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (studentHelper.position != studentHelper.originalPosition) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.position,
                  },
                  where: {
                    id: studentHelper.positionID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.position });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (studentHelper.education != studentHelper.originalEducation) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.education,
                  },
                  where: {
                    id: studentHelper.educationID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.education });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (studentHelper.moreInfo != studentHelper.originalMoreInfo) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.moreInfo,
                  },
                  where: {
                    id: studentHelper.moreInfoID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.moreInfo });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (studentHelper.email != studentHelper.originalEmail) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.email,
                  },
                  where: {
                    id: studentHelper.emailID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.email });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (studentHelper.phone != studentHelper.originalPhone) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.phone,
                  },
                  where: {
                    id: studentHelper.phoneID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.phone });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            // English version
            if (
              studentHelper.fullNameEng != studentHelper.originalFullNameEng
            ) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.fullNameEng,
                  },
                  where: {
                    id: studentHelper.nameEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.fullNameEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (
              studentHelper.positionEng != studentHelper.originalPositionEng
            ) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.positionEng,
                  },
                  where: {
                    id: studentHelper.positionEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.positionEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (
              studentHelper.educationEng != studentHelper.originalEducationEng
            ) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.educationEng,
                  },
                  where: {
                    id: studentHelper.educationEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.educationEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (
              studentHelper.moreInfoEng != studentHelper.originalMoreInfoEng
            ) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.moreInfoEng,
                  },
                  where: {
                    id: studentHelper.moreInfoEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.moreInfoEng });
                })
                .catch((update1TitleErr) =>
                  console.log("content1", update1TitleErr)
                );
            }
          } else if (studentHelper.id.includes("studentHelper")) {
            //missing icon
            if (studentHelper.icon != "") {
              // console.log("photo upload");
              let formData = new FormData();
              formData.append("image", studentHelper.icon);
              // console.log("run");

              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  // console.log("uploaded");
                  // console.log(photoRes);
                  const request = `{createImageDetail(data:$data){
                      url
                      category
                      position
                      master{
                        id
                      }
                    }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                        category: `studentHelper_${
                          studentHelper.nameID.split("+")[1]
                        }`,
                        position: `studentHelperIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((createPRes) => {
                      // console.log(createPRes);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((createPErr) => console.error(createPErr));
                })
                .catch((photoErr) => {
                  // console.log("here?");
                  console.error(photoErr);
                });
            } else if (studentHelper.icon === "") {
              const request = `{createImageDetail(data:$data){
                url
                category
                position
                master{
                  id
                }
              }}`;
              const graphqlQuery = {
                operationName: "Mutation",
                query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    url: "",
                    category: `studentHelper_${
                      studentHelper.nameID.split("+")[1]
                    }`,
                    position: `studentHelperIcon`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              })
                .then((createPRes) => {
                  // console.log(createPRes);
                  promiseArray.push({ content: "" });
                })
                .catch((createPErr) => console.error(createPErr));
            }
            const contextDetailsRequest = `{createContentDetails(data: $data){
                          content
                          position
                          category
                          master {
                            id
                          }
                          langLabel{
                            id
                            lang
                            index
                          }
                        }}`;
            const contextGraphqlQuery = {
              operationName: "CreateContentDetails",
              query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
              variables: {
                data: [
                  {
                    content: `${studentHelper.cardPosition}`,
                    position: "cardPosition",
                    category: `studentHelper_${
                      studentHelper.nameID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: studentHelper.fullName,
                    position: "fullName",
                    category: `studentHelper_${
                      studentHelper.nameID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: studentHelper.position,
                    position: "position",
                    category: `studentHelper_${
                      studentHelper.positionID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: studentHelper.education,
                    position: "education",
                    category: `studentHelper_${
                      studentHelper.educationID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: studentHelper.email,
                    position: "email",
                    category: `studentHelper_${
                      studentHelper.emailID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: studentHelper.phone,
                    position: "phone",
                    category: `studentHelper_${
                      studentHelper.phoneID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: studentHelper.moreInfo,
                    position: "moreInfo",
                    category: `studentHelper_${
                      studentHelper.moreInfoID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  // English version
                  {
                    content: studentHelper.fullNameEng,
                    position: "fullNameEng",
                    category: `studentHelper_${
                      studentHelper.nameEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: studentHelper.positionEng,
                    position: "positionEng",
                    category: `studentHelper_${
                      studentHelper.positionEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: studentHelper.educationEng,
                    position: "educationEng",
                    category: `studentHelper_${
                      studentHelper.educationEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: studentHelper.moreInfoEng,
                    position: "moreInfoEng",
                    category: `studentHelper_${
                      studentHelper.moreInfoEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                ],
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: contextGraphqlQuery,
              headers: headers,
            })
              .then((createContextRes) => {
                // console.log(createContextRes);
                promiseArray.push({ content: "done update" });
              })
              .catch((createContextErr) => console.error(createContextErr));
          }
        })
      );
      Promise.all(promiseArray).then((res) => {
        counsellorUpdate = true;
        teamUpdate = true;
      });
      if (!missingFields) {
        setTimeout(() => {
          checkValues([counsellorUpdate, teamUpdate]).then(() => {
            setChangeDetected(!changeDetected);
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Counsellor & Team is updated`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
            }, 2000);
          });
        }, 1000);
      }
    } else {
      const request = `{createPageContent(data: $data){
        id,
        belongToChild {
          id
        }
      }}`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($data: PageContentCreateInput!) ${request}`,
        variables: {
          data: {
            belongToChild: {
              connect: { id: pageInfo.id },
            },
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then(async (res) => {
          // console.log("create res", res);
          await Promise.all(
            counsellorList.map(async (counsellor: any) => {
              //missing icon
              if (counsellor.icon != "") {
                let formData = new FormData();
                formData.append("image", counsellor.icon);
                // console.log("run");

                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((photoRes) => {
                    // console.log("uploaded");
                    // console.log(photoRes);
                    const request = `{createImageDetail(data:$data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
                    const graphqlQuery = {
                      operationName: "Mutation",
                      query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                      variables: {
                        data: {
                          url: photoRes.data.url,
                          category: `counsellor_${
                            counsellor.nameID.split("+")[1]
                          }`,
                          position: `counsellorIcon`,
                          master: {
                            connect: {
                              id: res.data.data.createPageContent.id,
                            },
                          },
                        },
                      },
                    };
                    axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    })
                      .then((createPRes) => {
                        // console.log(createPRes);
                        promiseArray.push({ content: photoRes.data.url });
                      })
                      .catch((createPErr) => console.log(createPErr));
                  })
                  .catch((photoErr) => {
                    // console.log("here?");
                    console.log(photoErr);
                  });
              } else if (counsellor.icon === "") {
                const request = `{createImageDetail(data:$data){
                    url
                    category
                    position
                    master{
                      id
                    }
                  }}`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                  variables: {
                    data: {
                      url: "",
                      category: `counsellor_${counsellor.nameID.split("+")[1]}`,
                      position: `counsellorIcon`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((createPRes) => {
                    // console.log(createPRes);
                    promiseArray.push({ content: "" });
                  })
                  .catch((createPErr) => console.log(createPErr));
              }
              // console.log("photo upload");
              const contextDetailsRequest = `{createContentDetails(data: $data){
                      content
                      position
                      category
                      master {
                        id
                      }
                      langLabel{
                        id
                        lang
                        index
                      }
                    }}`;
              const contextGraphqlQuery = {
                operationName: "CreateContentDetails",
                query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
                variables: {
                  data: [
                    {
                      content: `${counsellor.cardPosition}`,
                      position: "cardPosition",
                      category: `counsellor_${counsellor.nameID.split("+")[1]}`,
                      master: {
                        connect: { id: pageInfo.childContent[0].id },
                      },
                    },
                    {
                      content: counsellor.fullName,
                      position: "fullName",
                      category: `counsellor_${counsellor.nameID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: counsellor.position,
                      position: "position",
                      category: `counsellor_${
                        counsellor.positionID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: counsellor.education,
                      position: "education",
                      category: `counsellor_${
                        counsellor.educationID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: counsellor.email,
                      position: "email",
                      category: `counsellor_${
                        counsellor.emailID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                    {
                      content: counsellor.phone,
                      position: "phone",
                      category: `counsellor_${
                        counsellor.phoneID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                    {
                      content: counsellor.moreInfo,
                      position: "moreInfo",
                      category: `counsellor_${
                        counsellor.moreInfoID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    // English Version
                    {
                      content: counsellor.fullNameEng,
                      position: "fullNameEng",
                      category: `counsellor_${
                        counsellor.nameEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: counsellor.positionEng,
                      position: "positionEng",
                      category: `counsellor_${
                        counsellor.positionEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: counsellor.educationEng,
                      position: "educationEng",
                      category: `counsellor_${
                        counsellor.educationEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: counsellor.moreInfoEng,
                      position: "moreInfoEng",
                      category: `counsellor_${
                        counsellor.moreInfoID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                  ],
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: contextGraphqlQuery,
                headers: headers,
              })
                .then((createContextRes) => {
                  {
                    // console.log(createContextRes);
                    promiseArray.push({ content: "done update" });
                  }
                })
                .catch((createContextErr) => console.log(createContextErr));
            })
          );
          await Promise.all(
            teamList.map(async (team) => {
              //missing icon
              if (team.icon != "") {
                let formData = new FormData();
                formData.append("image", team.icon);
                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((photoRes) => {
                    const request = `{createImageDetail(data:$data){
                      url
                      category
                      position
                      master{
                        id
                      }
                      
                    }}`;
                    const graphqlQuery = {
                      operationName: "Mutation",
                      query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                      variables: {
                        data: {
                          url: photoRes.data.url,
                          category: `team_${team.nameID.split("+")[1]}`,
                          position: `teamIcon`,
                          master: {
                            connect: {
                              id: res.data.data.createPageContent.id,
                            },
                          },
                        },
                      },
                    };
                    axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    })
                      .then((createPRes) => {
                        // console.log(createPRes);
                        promiseArray.push({ content: photoRes.data.url });
                      })
                      .catch((createPErr) => console.log(createPErr));
                  })
                  .catch((photoErr) => console.log(photoErr));
              } else if (team.icon === "") {
                const request = `{createImageDetail(data:$data){
                    url
                    category
                    position
                    master{
                      id
                    }
                    
                  }}`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                  variables: {
                    data: {
                      url: "",
                      category: `team_${team.nameID.split("+")[1]}`,
                      position: `teamIcon`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((createPRes) => {
                    // console.log(createPRes);
                    promiseArray.push({ content: "" });
                  })
                  .catch((createPErr) => console.log(createPErr));
              }
              const contextDetailsRequest = `{createContentDetails(data: $data){
                      content
                      position
                      category
                      master {
                        id
                      }
                      langLabel{
                        id
                        lang
                        index
                      }
                    }}`;
              const contextGraphqlQuery = {
                operationName: "CreateContentDetails",
                query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
                variables: {
                  data: [
                    {
                      content: team.fullName,
                      position: "fullName",
                      category: `team_${team.nameID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: team.position,
                      position: "position",
                      category: `team_${team.positionID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: team.education,
                      position: "education",
                      category: `team_${team.educationID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: team.email,
                      position: "email",
                      category: `team_${team.emailID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                    {
                      content: team.phone,
                      position: "phone",
                      category: `team_${team.phoneID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                    {
                      content: team.moreInfo,
                      position: "moreInfo",
                      category: `team_${team.moreInfoID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    // English Version
                    {
                      content: team.fullNameEng,
                      position: "fullNameEng",
                      category: `team_${team.nameEngID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: team.positionEng,
                      position: "positionEng",
                      category: `team_${team.positionEngID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: team.educationEng,
                      position: "educationEng",
                      category: `team_${team.educationEngID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: team.moreInfoEng,
                      position: "moreInfoEng",
                      category: `team_${team.moreInfoEngID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                  ],
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: contextGraphqlQuery,
                headers: headers,
              })
                .then((createContextRes) => {
                  // console.log(createContextRes);
                  promiseArray.push({ content: "done update" });
                })
                .catch((createContextErr) => console.log(createContextErr));
            })
          );
          await Promise.all(
            studentHelperList.map(async (studentHelper) => {
              //missing icon
              if (studentHelper.icon != "") {
                let formData = new FormData();
                formData.append("image", studentHelper.icon);
                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((photoRes) => {
                    const request = `{createImageDetail(data:$data){
                      url
                      category
                      position
                      master{
                        id
                      }
                      
                    }}`;
                    const graphqlQuery = {
                      operationName: "Mutation",
                      query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                      variables: {
                        data: {
                          url: photoRes.data.url,
                          category: `studentHelper_${
                            studentHelper.nameID.split("+")[1]
                          }`,
                          position: `studentHelperIcon`,
                          master: {
                            connect: {
                              id: res.data.data.createPageContent.id,
                            },
                          },
                        },
                      },
                    };
                    axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    })
                      .then((createPRes) => {
                        // console.log(createPRes);
                        promiseArray.push({ content: photoRes.data.url });
                      })
                      .catch((createPErr) => console.log(createPErr));
                  })
                  .catch((photoErr) => console.log(photoErr));
              } else if (studentHelper.icon === "") {
                const request = `{createImageDetail(data:$data){
                    url
                    category
                    position
                    master{
                      id
                    }
                    
                  }}`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                  variables: {
                    data: {
                      url: "",
                      category: `studentHelper_${
                        studentHelper.nameID.split("+")[1]
                      }`,
                      position: `studentHelperIcon`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((createPRes) => {
                    // console.log(createPRes);
                    promiseArray.push({ content: "" });
                  })
                  .catch((createPErr) => console.log(createPErr));
              }
              const contextDetailsRequest = `{createContentDetails(data: $data){
                      content
                      position
                      category
                      master {
                        id
                      }
                      langLabel{
                        id
                        lang
                        index
                      }
                    }}`;
              const contextGraphqlQuery = {
                operationName: "CreateContentDetails",
                query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
                variables: {
                  data: [
                    {
                      content: studentHelper.fullName,
                      position: "fullName",
                      category: `studentHelper_${
                        studentHelper.nameID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: studentHelper.position,
                      position: "position",
                      category: `studentHelper_${
                        studentHelper.positionID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: studentHelper.education,
                      position: "education",
                      category: `studentHelper_${
                        studentHelper.educationID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: studentHelper.email,
                      position: "email",
                      category: `studentHelper_${
                        studentHelper.emailID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                    {
                      content: studentHelper.phone,
                      position: "phone",
                      category: `studentHelper_${
                        studentHelper.phoneID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                    {
                      content: studentHelper.moreInfo,
                      position: "moreInfo",
                      category: `studentHelper_${
                        studentHelper.moreInfoID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    // English Version
                    {
                      content: studentHelper.fullNameEng,
                      position: "fullNameEng",
                      category: `studentHelper_${
                        studentHelper.nameEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: studentHelper.positionEng,
                      position: "positionEng",
                      category: `studentHelper_${
                        studentHelper.positionEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: studentHelper.educationEng,
                      position: "educationEng",
                      category: `studentHelper_${
                        studentHelper.educationEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: studentHelper.moreInfoEng,
                      position: "moreInfoEng",
                      category: `studentHelper_${
                        studentHelper.moreInfoEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                  ],
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: contextGraphqlQuery,
                headers: headers,
              })
                .then((createContextRes) => {
                  // console.log(createContextRes);
                  promiseArray.push({ content: "done update" });
                })
                .catch((createContextErr) => console.log(createContextErr));
            })
          );
        })
        .catch((err) => {
          console.error(err);
        });
      Promise.all(promiseArray).then((res) => {
        counsellorUpdate = true;
        teamUpdate = true;
      });
      if (!missingFields) {
        setTimeout(() => {
          checkValues([counsellorUpdate, teamUpdate]).then(() => {
            setChangeDetected(!changeDetected);
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Counsellor & Team is updated`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
            }, 2000);
          });
        }, 1000);
      }
    }
    setIsDisabled(false);
  }

  // autosave
  async function autosave() {
    setIsDisabled(true);
    setIsLoading(true);
    const chinese_zh_id = languageList.filter(
      (obj: any) => obj.lang === "zh_hant"
    )[0].id;
    const eng_id = languageList.filter((obj: any) => obj.lang === "en")[0].id;
    // console.log(pageInfo);

    let counsellorUpdate = false;
    let teamUpdate = false;
    // for checking update process, if done will push any object into it.
    let promiseArray = [];
    let missingFields = false;
    if (pageInfo.childContent[0]) {
      await Promise.all(
        counsellorList.map(async (counsellor: any) => {
          if (counsellor.id === "") {
            if (counsellor.phoneID === "") {
              const request = `{
              createContentDetail(data: $data) {
                priority
                position
                content
                category
                master {
                  id
                }
              }
            }`;
              const graphqlQuery = {
                operationName: "CreateContentDetail",
                query: `mutation CreateContentDetail($data: ContentDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    content: counsellor.phone,
                    position: "phone",
                    category: counsellor.category,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              await axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              });
              // console.log("result", result);
            }
            if (
              counsellor.iconID === "" &&
              typeof counsellor.icon != "string"
            ) {
              let formData = new FormData();

              formData.append("image", counsellor.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(async (iconRes) => {
                  const request = `{createImageDetail(data: $data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: iconRes.data.url,
                        category: counsellor.category,
                        position: `counsellorIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  let result = await axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  });
                  // console.log("hereeeeeeee", result);
                });
            }
            if (
              counsellor.icon != counsellor.originalIcon &&
              counsellor.iconID != ""
            ) {
              let formData = new FormData();

              formData.append("image", counsellor.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  const request = `{updateImageDetail(where: $where, data: $data) {
              url
            }}`;
                  const graphqlQuery = {
                    operationName: "UpdateImageDetail",
                    query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, 
                $data: ImageDetailUpdateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                      },
                      where: {
                        id: counsellor.iconID,
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((res) => {
                      // console.log("image", res);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((err) => console.error("image", err));
                })
                .catch((photoErr) => {
                  console.error(photoErr);
                });
            }

            if (counsellor.fullName != counsellor.originalFullName) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.fullName,
                  },
                  where: {
                    id: counsellor.nameID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.fullName });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.position != counsellor.originalPosition) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.position,
                  },
                  where: {
                    id: counsellor.positionID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.position });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.education != counsellor.originalEducation) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.education,
                  },
                  where: {
                    id: counsellor.educationID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.education });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.moreInfo != counsellor.originalMoreInfo) {
              // console.log("counsellor moreInfo", counsellor.moreInfo);
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.moreInfo,
                  },
                  where: {
                    id: counsellor.moreInfoID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.moreInfo });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.email != counsellor.originalEmail) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.email,
                  },
                  where: {
                    id: counsellor.emailID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.email });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.phone != counsellor.originalPhone) {
              // console.log("hello");
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.phone,
                  },
                  where: {
                    id: counsellor.phoneID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.phone });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            // English Version
            if (counsellor.fullNameEng != counsellor.originalFullNameEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.fullNameEng,
                  },
                  where: {
                    id: counsellor.nameEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.fullNameEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.positionEng != counsellor.originalPositionEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.positionEng,
                  },
                  where: {
                    id: counsellor.positionEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.positionEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.educationEng != counsellor.originalEducationEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.educationEng,
                  },
                  where: {
                    id: counsellor.educationEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.educationEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (counsellor.moreInfoEng != counsellor.originalMoreInfoEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
              id
              content
            }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: counsellor.moreInfoEng,
                  },
                  where: {
                    id: counsellor.moreInfoEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: counsellor.moreInfoEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
          } else if (counsellor.id.includes("counsellor")) {
            //missing icon
            if (counsellor.icon != "") {
              let formData = new FormData();
              formData.append("image", counsellor.icon);
              // console.log("run");

              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  // console.log("uploaded");
                  // console.log(photoRes);
                  const request = `{createImageDetail(data:$data){
                url
                category
                position
                master{
                  id
                }
                
              }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                        category: `counsellor_${
                          counsellor.nameID.split("+")[1]
                        }`,
                        position: `counsellorIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((createPRes) => {
                      // console.log(createPRes);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((createPErr) => console.error(createPErr));
                })
                .catch((photoErr) => {
                  // console.log("here?");
                  console.error(photoErr);
                });
            } else if (counsellor.icon === "") {
              const request = `{createImageDetail(data:$data){
                  url
                  category
                  position
                  master{
                    id
                  }
                  
                }}`;
              const graphqlQuery = {
                operationName: "Mutation",
                query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    url: "",
                    category: `counsellor_${counsellor.nameID.split("+")[1]}`,
                    position: `counsellorIcon`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              })
                .then((createPRes) => {
                  // console.log(createPRes);
                  promiseArray.push({ content: "" });
                })
                .catch((createPErr) => console.error(createPErr));
            }
            // console.log("photo upload");

            const contextDetailsRequest = `{createContentDetails(data: $data){
                    content
                    position
                    category
                    master {
                      id
                    }
                    langLabel{
                      id
                      lang
                      index
                    }
                  }}`;
            const contextGraphqlQuery = {
              operationName: "CreateContentDetails",
              query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
              variables: {
                data: [
                  {
                    content: `${counsellor.cardPosition}`,
                    position: "cardPosition",
                    category: `counsellor_${counsellor.nameID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: counsellor.fullName,
                    position: "fullName",
                    category: `counsellor_${counsellor.nameID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: counsellor.position,
                    position: "position",
                    category: `counsellor_${
                      counsellor.positionID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: counsellor.education,
                    position: "education",
                    category: `counsellor_${
                      counsellor.educationID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: counsellor.moreInfo,
                    position: "moreInfo",
                    category: `counsellor_${
                      counsellor.moreInfoID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: counsellor.email,
                    position: "email",
                    category: `counsellor_${counsellor.emailID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: counsellor.phone,
                    position: "phone",
                    category: `counsellor_${counsellor.phoneID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  // English Version
                  {
                    content: counsellor.fullNameEng,
                    position: "fullNameEng",
                    category: `counsellor_${
                      counsellor.nameEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: counsellor.positionEng,
                    position: "positionEng",
                    category: `counsellor_${
                      counsellor.positionEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: counsellor.educationEng,
                    position: "educationEng",
                    category: `counsellor_${
                      counsellor.educationEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: counsellor.moreInfoEng,
                    position: "moreInfoEng",
                    category: `counsellor_${
                      counsellor.moreInfoEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                ],
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: contextGraphqlQuery,
              headers: headers,
            })
              .then((createContextRes) => {
                // console.log(createContextRes);
                promiseArray.push({ content: "done update" });
              })
              .catch((createContextErr) => console.error(createContextErr));
          }
        })
      );
      await Promise.all(
        teamList.map(async (team: any) => {
          if (team.id === "") {
            if (team.phoneID === "") {
              const request = `{
                      createContentDetail(data: $data) {
                        priority
                        position
                        content
                        category
                        master {
                          id
                        }
                      }
                    }`;
              const graphqlQuery = {
                operationName: "CreateContentDetail",
                query: `mutation CreateContentDetail($data: ContentDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    content: team.phone,
                    position: "phone",
                    category: team.category,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              await axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              });
              // console.log("result", result);
            }
            if (team.iconID === "" && typeof team.icon != "string") {
              let formData = new FormData();

              formData.append("image", team.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(async (iconRes) => {
                  const request = `{createImageDetail(data: $data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: iconRes.data.url,
                        category: team.category,
                        position: `collaboratorIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  await axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  });
                });
            }
            if (team.icon != team.originalIcon && team.iconID != "") {
              let formData = new FormData();

              formData.append("image", team.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  const request = `{updateImageDetail(where: $where, data: $data) {
                      url
                    }}`;
                  const graphqlQuery = {
                    operationName: "UpdateImageDetail",
                    query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, 
                        $data: ImageDetailUpdateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                      },
                      where: {
                        id: team.iconID,
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((res) => {
                      // console.log("image", res);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((err) => console.error("image", err));
                })
                .catch((photoErr) => {
                  console.error(photoErr);
                });
            }
            if (team.fullName != team.originalFullName) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.fullName,
                  },
                  where: {
                    id: team.nameID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.fullName });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.position != team.originalPosition) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.position,
                  },
                  where: {
                    id: team.positionID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.position });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.education != team.originalEducation) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.education,
                  },
                  where: {
                    id: team.educationID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.education });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.moreInfo != team.originalMoreInfo) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.moreInfo,
                  },
                  where: {
                    id: team.moreInfoID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.moreInfo });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.email != team.originalEmail) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.email,
                  },
                  where: {
                    id: team.emailID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.email });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.phone != team.originalPhone) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.phone,
                  },
                  where: {
                    id: team.phoneID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.phone });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            // English version
            if (team.fullNameEng != team.originalFullNameEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.fullNameEng,
                  },
                  where: {
                    id: team.nameEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.fullNameEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.positionEng != team.originalPositionEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.positionEng,
                  },
                  where: {
                    id: team.positionEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.positionEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.educationEng != team.originalEducationEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.educationEng,
                  },
                  where: {
                    id: team.educationEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.educationEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (team.moreInfoEng != team.originalMoreInfoEng) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                      id
                      content
                    }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                        $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: team.moreInfoEng,
                  },
                  where: {
                    id: team.moreInfoEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  console.log("content1", update1TitleRes);
                  promiseArray.push({ content: team.moreInfoEng });
                })
                .catch((update1TitleErr) =>
                  console.log("content1", update1TitleErr)
                );
            }
          } else if (team.id.includes("team")) {
            //missing icon
            if (team.icon != "") {
              // console.log("photo upload");
              let formData = new FormData();
              formData.append("image", team.icon);
              // console.log("run");

              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  // console.log("uploaded");
                  // console.log(photoRes);
                  const request = `{createImageDetail(data:$data){
                        url
                        category
                        position
                        master{
                          id
                        }
                      }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                        category: `team_${team.nameID.split("+")[1]}`,
                        position: `collaboratorIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((createPRes) => {
                      // console.log(createPRes);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((createPErr) => console.error(createPErr));
                })
                .catch((photoErr) => {
                  // console.log("here?");
                  console.error(photoErr);
                });
            } else if (team.icon === "") {
              const request = `{createImageDetail(data:$data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
              const graphqlQuery = {
                operationName: "Mutation",
                query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    url: "",
                    category: `team_${team.nameID.split("+")[1]}`,
                    position: `collaboratorIcon`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              })
                .then((createPRes) => {
                  // console.log(createPRes);
                  promiseArray.push({ content: "" });
                })
                .catch((createPErr) => console.error(createPErr));
            }
            const contextDetailsRequest = `{createContentDetails(data: $data){
                            content
                            position
                            category
                            master {
                              id
                            }
                            langLabel{
                              id
                              lang
                              index
                            }
                          }}`;
            const contextGraphqlQuery = {
              operationName: "CreateContentDetails",
              query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
              variables: {
                data: [
                  {
                    content: `${team.cardPosition}`,
                    position: "cardPosition",
                    category: `team_${team.nameID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: team.fullName,
                    position: "fullName",
                    category: `team_${team.nameID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: team.position,
                    position: "position",
                    category: `team_${team.positionID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: team.education,
                    position: "education",
                    category: `team_${team.educationID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: team.email,
                    position: "email",
                    category: `team_${team.emailID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: team.phone,
                    position: "phone",
                    category: `team_${team.phoneID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: team.moreInfo,
                    position: "moreInfo",
                    category: `team_${team.moreInfoID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  // English version
                  {
                    content: team.fullNameEng,
                    position: "fullNameEng",
                    category: `team_${team.nameEngID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: team.positionEng,
                    position: "positionEng",
                    category: `team_${team.positionEngID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: team.educationEng,
                    position: "educationEng",
                    category: `team_${team.educationEngID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: team.moreInfoEng,
                    position: "moreInfoEng",
                    category: `team_${team.moreInfoEngID.split("+")[1]}`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                ],
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: contextGraphqlQuery,
              headers: headers,
            })
              .then((createContextRes) => {
                // console.log(createContextRes);
                promiseArray.push({ content: "done update" });
              })
              .catch((createContextErr) => console.error(createContextErr));
          }
        })
      );
      await Promise.all(
        studentHelperList.map(async (studentHelper: any) => {
          if (studentHelper.id === "") {
            if (studentHelper.phoneID === "") {
              const request = `{
                    createContentDetail(data: $data) {
                      priority
                      position
                      content
                      category
                      master {
                        id
                      }
                    }
                  }`;
              const graphqlQuery = {
                operationName: "CreateContentDetail",
                query: `mutation CreateContentDetail($data: ContentDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    content: studentHelper.phone,
                    position: "phone",
                    category: studentHelper.category,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              await axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              });
              // console.log("result", result);
            }
            if (
              studentHelper.iconID === "" &&
              typeof studentHelper.icon != "string"
            ) {
              let formData = new FormData();

              formData.append("image", studentHelper.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(async (iconRes) => {
                  const request = `{createImageDetail(data: $data){
                url
                category
                position
                master{
                  id
                }
              }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: iconRes.data.url,
                        category: studentHelper.category,
                        position: `studentHelperIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  await axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  });
                });
            }
            if (
              studentHelper.icon != studentHelper.originalIcon &&
              studentHelper.iconID != ""
            ) {
              let formData = new FormData();

              formData.append("image", studentHelper.icon);
              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  const request = `{updateImageDetail(where: $where, data: $data) {
                    url
                  }}`;
                  const graphqlQuery = {
                    operationName: "UpdateImageDetail",
                    query: `mutation UpdateImageDetail($where: ImageDetailWhereUniqueInput!, 
                      $data: ImageDetailUpdateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                      },
                      where: {
                        id: studentHelper.iconID,
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((res) => {
                      // console.log("image", res);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((err) => console.error("image", err));
                })
                .catch((photoErr) => {
                  console.error(photoErr);
                });
            }
            if (studentHelper.fullName != studentHelper.originalFullName) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.fullName,
                  },
                  where: {
                    id: studentHelper.nameID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.fullName });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (studentHelper.position != studentHelper.originalPosition) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.position,
                  },
                  where: {
                    id: studentHelper.positionID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.position });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (studentHelper.education != studentHelper.originalEducation) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.education,
                  },
                  where: {
                    id: studentHelper.educationID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.education });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (studentHelper.moreInfo != studentHelper.originalMoreInfo) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.moreInfo,
                  },
                  where: {
                    id: studentHelper.moreInfoID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.moreInfo });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (studentHelper.email != studentHelper.originalEmail) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.email,
                  },
                  where: {
                    id: studentHelper.emailID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.email });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (studentHelper.phone != studentHelper.originalPhone) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.phone,
                  },
                  where: {
                    id: studentHelper.phoneID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.phone });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            // English version
            if (
              studentHelper.fullNameEng != studentHelper.originalFullNameEng
            ) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.fullNameEng,
                  },
                  where: {
                    id: studentHelper.nameEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.fullNameEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (
              studentHelper.positionEng != studentHelper.originalPositionEng
            ) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.positionEng,
                  },
                  where: {
                    id: studentHelper.positionEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.positionEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (
              studentHelper.educationEng != studentHelper.originalEducationEng
            ) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.educationEng,
                  },
                  where: {
                    id: studentHelper.educationEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  // console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.educationEng });
                })
                .catch((update1TitleErr) =>
                  console.error("content1", update1TitleErr)
                );
            }
            if (
              studentHelper.moreInfoEng != studentHelper.originalMoreInfoEng
            ) {
              const updateStT = `{updateContentDetail(where: $where, data: $data){
                    id
                    content
                  }}`;
              const updateStTGraphqlQuery = {
                operationName: "UpdateContentDetail",
                query: `mutation UpdateContentDetail($where: ContentDetailWhereUniqueInput!, 
                      $data: ContentDetailUpdateInput!) ${updateStT}`,
                variables: {
                  data: {
                    content: studentHelper.moreInfoEng,
                  },
                  where: {
                    id: studentHelper.moreInfoEngID,
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: updateStTGraphqlQuery,
                headers: headers,
              })
                .then((update1TitleRes) => {
                  console.log("content1", update1TitleRes);
                  promiseArray.push({ content: studentHelper.moreInfoEng });
                })
                .catch((update1TitleErr) =>
                  console.log("content1", update1TitleErr)
                );
            }
          } else if (studentHelper.id.includes("studentHelper")) {
            //missing icon
            if (studentHelper.icon != "") {
              // console.log("photo upload");
              let formData = new FormData();
              formData.append("image", studentHelper.icon);
              // console.log("run");

              axios
                .post(API.image, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((photoRes) => {
                  // console.log("uploaded");
                  // console.log(photoRes);
                  const request = `{createImageDetail(data:$data){
                      url
                      category
                      position
                      master{
                        id
                      }
                    }}`;
                  const graphqlQuery = {
                    operationName: "Mutation",
                    query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                    variables: {
                      data: {
                        url: photoRes.data.url,
                        category: `studentHelper_${
                          studentHelper.nameID.split("+")[1]
                        }`,
                        position: `studentHelperIcon`,
                        master: {
                          connect: { id: pageInfo.childContent[0].id },
                        },
                      },
                    },
                  };
                  axios({
                    url: endPoint,
                    method: "post",
                    data: graphqlQuery,
                    headers: headers,
                  })
                    .then((createPRes) => {
                      // console.log(createPRes);
                      promiseArray.push({ content: photoRes.data.url });
                    })
                    .catch((createPErr) => console.error(createPErr));
                })
                .catch((photoErr) => {
                  // console.log("here?");
                  console.error(photoErr);
                });
            } else if (studentHelper.icon === "") {
              const request = `{createImageDetail(data:$data){
                url
                category
                position
                master{
                  id
                }
              }}`;
              const graphqlQuery = {
                operationName: "Mutation",
                query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                variables: {
                  data: {
                    url: "",
                    category: `studentHelper_${
                      studentHelper.nameID.split("+")[1]
                    }`,
                    position: `studentHelperIcon`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: graphqlQuery,
                headers: headers,
              })
                .then((createPRes) => {
                  // console.log(createPRes);
                  promiseArray.push({ content: "" });
                })
                .catch((createPErr) => console.error(createPErr));
            }
            const contextDetailsRequest = `{createContentDetails(data: $data){
                          content
                          position
                          category
                          master {
                            id
                          }
                          langLabel{
                            id
                            lang
                            index
                          }
                        }}`;
            const contextGraphqlQuery = {
              operationName: "CreateContentDetails",
              query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
              variables: {
                data: [
                  {
                    content: `${studentHelper.cardPosition}`,
                    position: "cardPosition",
                    category: `studentHelper_${
                      studentHelper.nameID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: studentHelper.fullName,
                    position: "fullName",
                    category: `studentHelper_${
                      studentHelper.nameID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: studentHelper.position,
                    position: "position",
                    category: `studentHelper_${
                      studentHelper.positionID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: studentHelper.education,
                    position: "education",
                    category: `studentHelper_${
                      studentHelper.educationID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  {
                    content: studentHelper.email,
                    position: "email",
                    category: `studentHelper_${
                      studentHelper.emailID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: studentHelper.phone,
                    position: "phone",
                    category: `studentHelper_${
                      studentHelper.phoneID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                  },
                  {
                    content: studentHelper.moreInfo,
                    position: "moreInfo",
                    category: `studentHelper_${
                      studentHelper.moreInfoID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: chinese_zh_id },
                    },
                  },
                  // English version
                  {
                    content: studentHelper.fullNameEng,
                    position: "fullNameEng",
                    category: `studentHelper_${
                      studentHelper.nameEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: studentHelper.positionEng,
                    position: "positionEng",
                    category: `studentHelper_${
                      studentHelper.positionEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: studentHelper.educationEng,
                    position: "educationEng",
                    category: `studentHelper_${
                      studentHelper.educationEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                  {
                    content: studentHelper.moreInfoEng,
                    position: "moreInfoEng",
                    category: `studentHelper_${
                      studentHelper.moreInfoEngID.split("+")[1]
                    }`,
                    master: {
                      connect: { id: pageInfo.childContent[0].id },
                    },
                    langLabel: {
                      connect: { id: eng_id },
                    },
                  },
                ],
              },
            };
            axios({
              url: endPoint,
              method: "post",
              data: contextGraphqlQuery,
              headers: headers,
            })
              .then((createContextRes) => {
                // console.log(createContextRes);
                promiseArray.push({ content: "done update" });
              })
              .catch((createContextErr) => console.error(createContextErr));
          }
        })
      );
      Promise.all(promiseArray).then((res) => {
        counsellorUpdate = true;
        teamUpdate = true;
      });
      newCardArr.splice(0, newCardArr.length);

      return "done all";
    } else {
      const request = `{createPageContent(data: $data){
        id,
        belongToChild {
          id
        }
      }}`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($data: PageContentCreateInput!) ${request}`,
        variables: {
          data: {
            belongToChild: {
              connect: { id: pageInfo.id },
            },
          },
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then(async (res) => {
          // console.log("create res", res);
          await Promise.all(
            counsellorList.map(async (counsellor: any) => {
              //missing icon
              if (counsellor.icon != "") {
                let formData = new FormData();
                formData.append("image", counsellor.icon);
                // console.log("run");

                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((photoRes) => {
                    // console.log("uploaded");
                    // console.log(photoRes);
                    const request = `{createImageDetail(data:$data){
                  url
                  category
                  position
                  master{
                    id
                  }
                }}`;
                    const graphqlQuery = {
                      operationName: "Mutation",
                      query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                      variables: {
                        data: {
                          url: photoRes.data.url,
                          category: `counsellor_${
                            counsellor.nameID.split("+")[1]
                          }`,
                          position: `counsellorIcon`,
                          master: {
                            connect: {
                              id: res.data.data.createPageContent.id,
                            },
                          },
                        },
                      },
                    };
                    axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    })
                      .then((createPRes) => {
                        // console.log(createPRes);
                        promiseArray.push({ content: photoRes.data.url });
                      })
                      .catch((createPErr) => console.log(createPErr));
                  })
                  .catch((photoErr) => {
                    // console.log("here?");
                    console.log(photoErr);
                  });
              } else if (counsellor.icon === "") {
                const request = `{createImageDetail(data:$data){
                    url
                    category
                    position
                    master{
                      id
                    }
                  }}`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                  variables: {
                    data: {
                      url: "",
                      category: `counsellor_${counsellor.nameID.split("+")[1]}`,
                      position: `counsellorIcon`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((createPRes) => {
                    // console.log(createPRes);
                    promiseArray.push({ content: "" });
                  })
                  .catch((createPErr) => console.log(createPErr));
              }
              // console.log("photo upload");
              const contextDetailsRequest = `{createContentDetails(data: $data){
                      content
                      position
                      category
                      master {
                        id
                      }
                      langLabel{
                        id
                        lang
                        index
                      }
                    }}`;
              const contextGraphqlQuery = {
                operationName: "CreateContentDetails",
                query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
                variables: {
                  data: [
                    {
                      content: counsellor.fullName,
                      position: "fullName",
                      category: `counsellor_${counsellor.nameID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: counsellor.position,
                      position: "position",
                      category: `counsellor_${
                        counsellor.positionID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: counsellor.education,
                      position: "education",
                      category: `counsellor_${
                        counsellor.educationID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: counsellor.email,
                      position: "email",
                      category: `counsellor_${
                        counsellor.emailID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                    {
                      content: counsellor.phone,
                      position: "phone",
                      category: `counsellor_${
                        counsellor.phoneID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                    {
                      content: counsellor.moreInfo,
                      position: "moreInfo",
                      category: `counsellor_${
                        counsellor.moreInfoID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    // English Version
                    {
                      content: counsellor.fullNameEng,
                      position: "fullNameEng",
                      category: `counsellor_${
                        counsellor.nameEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: counsellor.positionEng,
                      position: "positionEng",
                      category: `counsellor_${
                        counsellor.positionEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: counsellor.educationEng,
                      position: "educationEng",
                      category: `counsellor_${
                        counsellor.educationEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: counsellor.moreInfoEng,
                      position: "moreInfoEng",
                      category: `counsellor_${
                        counsellor.moreInfoID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                  ],
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: contextGraphqlQuery,
                headers: headers,
              })
                .then((createContextRes) => {
                  {
                    // console.log(createContextRes);
                    promiseArray.push({ content: "done update" });
                  }
                })
                .catch((createContextErr) => console.log(createContextErr));
            })
          );
          await Promise.all(
            teamList.map(async (team) => {
              //missing icon
              if (team.icon != "") {
                let formData = new FormData();
                formData.append("image", team.icon);
                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((photoRes) => {
                    const request = `{createImageDetail(data:$data){
                      url
                      category
                      position
                      master{
                        id
                      }
                      
                    }}`;
                    const graphqlQuery = {
                      operationName: "Mutation",
                      query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                      variables: {
                        data: {
                          url: photoRes.data.url,
                          category: `team_${team.nameID.split("+")[1]}`,
                          position: `teamIcon`,
                          master: {
                            connect: {
                              id: res.data.data.createPageContent.id,
                            },
                          },
                        },
                      },
                    };
                    axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    })
                      .then((createPRes) => {
                        // console.log(createPRes);
                        promiseArray.push({ content: photoRes.data.url });
                      })
                      .catch((createPErr) => console.log(createPErr));
                  })
                  .catch((photoErr) => console.log(photoErr));
              } else if (team.icon === "") {
                const request = `{createImageDetail(data:$data){
                    url
                    category
                    position
                    master{
                      id
                    }
                    
                  }}`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                  variables: {
                    data: {
                      url: "",
                      category: `team_${team.nameID.split("+")[1]}`,
                      position: `teamIcon`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((createPRes) => {
                    // console.log(createPRes);
                    promiseArray.push({ content: "" });
                  })
                  .catch((createPErr) => console.log(createPErr));
              }
              const contextDetailsRequest = `{createContentDetails(data: $data){
                      content
                      position
                      category
                      master {
                        id
                      }
                      langLabel{
                        id
                        lang
                        index
                      }
                    }}`;
              const contextGraphqlQuery = {
                operationName: "CreateContentDetails",
                query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
                variables: {
                  data: [
                    {
                      content: team.fullName,
                      position: "fullName",
                      category: `team_${team.nameID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: team.position,
                      position: "position",
                      category: `team_${team.positionID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: team.education,
                      position: "education",
                      category: `team_${team.educationID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: team.email,
                      position: "email",
                      category: `team_${team.emailID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                    {
                      content: team.phone,
                      position: "phone",
                      category: `team_${team.phoneID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                    {
                      content: team.moreInfo,
                      position: "moreInfo",
                      category: `team_${team.moreInfoID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    // English Version
                    {
                      content: team.fullNameEng,
                      position: "fullNameEng",
                      category: `team_${team.nameEngID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: team.positionEng,
                      position: "positionEng",
                      category: `team_${team.positionEngID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: team.educationEng,
                      position: "educationEng",
                      category: `team_${team.educationEngID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: team.moreInfoEng,
                      position: "moreInfoEng",
                      category: `team_${team.moreInfoEngID.split("+")[1]}`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                  ],
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: contextGraphqlQuery,
                headers: headers,
              })
                .then((createContextRes) => {
                  // console.log(createContextRes);
                  promiseArray.push({ content: "done update" });
                })
                .catch((createContextErr) => console.log(createContextErr));
            })
          );
          await Promise.all(
            studentHelperList.map(async (studentHelper) => {
              //missing icon
              if (studentHelper.icon != "") {
                let formData = new FormData();
                formData.append("image", studentHelper.icon);
                axios
                  .post(API.image, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((photoRes) => {
                    const request = `{createImageDetail(data:$data){
                      url
                      category
                      position
                      master{
                        id
                      }
                      
                    }}`;
                    const graphqlQuery = {
                      operationName: "Mutation",
                      query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                      variables: {
                        data: {
                          url: photoRes.data.url,
                          category: `studentHelper_${
                            studentHelper.nameID.split("+")[1]
                          }`,
                          position: `studentHelperIcon`,
                          master: {
                            connect: {
                              id: res.data.data.createPageContent.id,
                            },
                          },
                        },
                      },
                    };
                    axios({
                      url: endPoint,
                      method: "post",
                      data: graphqlQuery,
                      headers: headers,
                    })
                      .then((createPRes) => {
                        // console.log(createPRes);
                        promiseArray.push({ content: photoRes.data.url });
                      })
                      .catch((createPErr) => console.log(createPErr));
                  })
                  .catch((photoErr) => console.log(photoErr));
              } else if (studentHelper.icon === "") {
                const request = `{createImageDetail(data:$data){
                    url
                    category
                    position
                    master{
                      id
                    }
                    
                  }}`;
                const graphqlQuery = {
                  operationName: "Mutation",
                  query: `mutation Mutation($data: ImageDetailCreateInput!) ${request}`,
                  variables: {
                    data: {
                      url: "",
                      category: `studentHelper_${
                        studentHelper.nameID.split("+")[1]
                      }`,
                      position: `studentHelperIcon`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                  },
                };
                axios({
                  url: endPoint,
                  method: "post",
                  data: graphqlQuery,
                  headers: headers,
                })
                  .then((createPRes) => {
                    // console.log(createPRes);
                    promiseArray.push({ content: "" });
                  })
                  .catch((createPErr) => console.log(createPErr));
              }
              const contextDetailsRequest = `{createContentDetails(data: $data){
                      content
                      position
                      category
                      master {
                        id
                      }
                      langLabel{
                        id
                        lang
                        index
                      }
                    }}`;
              const contextGraphqlQuery = {
                operationName: "CreateContentDetails",
                query: `mutation CreateContentDetails ($data: [ContentDetailCreateInput!]!) ${contextDetailsRequest}`,
                variables: {
                  data: [
                    {
                      content: studentHelper.fullName,
                      position: "fullName",
                      category: `studentHelper_${
                        studentHelper.nameID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: studentHelper.position,
                      position: "position",
                      category: `studentHelper_${
                        studentHelper.positionID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: studentHelper.education,
                      position: "education",
                      category: `studentHelper_${
                        studentHelper.educationID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    {
                      content: studentHelper.email,
                      position: "email",
                      category: `studentHelper_${
                        studentHelper.emailID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                    {
                      content: studentHelper.phone,
                      position: "phone",
                      category: `studentHelper_${
                        studentHelper.phoneID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                    },
                    {
                      content: studentHelper.moreInfo,
                      position: "moreInfo",
                      category: `studentHelper_${
                        studentHelper.moreInfoID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: chinese_zh_id },
                      },
                    },
                    // English Version
                    {
                      content: studentHelper.fullNameEng,
                      position: "fullNameEng",
                      category: `studentHelper_${
                        studentHelper.nameEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: studentHelper.positionEng,
                      position: "positionEng",
                      category: `studentHelper_${
                        studentHelper.positionEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: studentHelper.educationEng,
                      position: "educationEng",
                      category: `studentHelper_${
                        studentHelper.educationEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                    {
                      content: studentHelper.moreInfoEng,
                      position: "moreInfoEng",
                      category: `studentHelper_${
                        studentHelper.moreInfoEngID.split("+")[1]
                      }`,
                      master: {
                        connect: { id: res.data.data.createPageContent.id },
                      },
                      langLabel: {
                        connect: { id: eng_id },
                      },
                    },
                  ],
                },
              };
              axios({
                url: endPoint,
                method: "post",
                data: contextGraphqlQuery,
                headers: headers,
              })
                .then((createContextRes) => {
                  // console.log(createContextRes);
                  promiseArray.push({ content: "done update" });
                })
                .catch((createContextErr) => console.log(createContextErr));
            })
          );
        })
        .catch((err) => {
          console.error(err);
        });
      Promise.all(promiseArray).then((res) => {
        counsellorUpdate = true;
        teamUpdate = true;
      });
      newCardArr.splice(0, newCardArr.length);

      return "done all";
    }
  }

  const setCounsellorMoreInfoRawHTML =
    (idx: number, lang: string) => (html: string) => {
      // setRdContext(html);
      // console.log("html", html);
      if (lang === "zh_hant") {
        setCurrentCounsellorMoreInfoWord(
          html
            .replace(/&nbsp;/g, " ")
            .replace(/&amp;/g, "&")
            .replace(/<[^>]*>/g, "").length - 1
        );
      } else if (lang === "en") {
        setCurrentCounsellorMoreInfoEngWord(
          html
            .replace(/&nbsp;/g, " ")
            .replace(/&amp;/g, "&")
            .replace(/<[^>]*>/g, "").length - 1
        );
      }
      handleMoreInfo(idx, "counsellor", true, html, lang);
    };

  const setTeamMoreInfoRawHTML =
    (idx: number, lang: string) => (html: string) => {
      // setRdContext(html);
      // console.log("html", html);
      if (lang === "zh_hant") {
        setCurrentTeamMoreInfoWord(
          html
            .replace(/&nbsp;/g, " ")
            .replace(/&amp;/g, "&")
            .replace(/<[^>]*>/g, "").length - 1
        );
      } else if (lang === "en") {
        setCurrentTeamMoreInfoEngWord(
          html
            .replace(/&nbsp;/g, " ")
            .replace(/&amp;/g, "&")
            .replace(/<[^>]*>/g, "").length - 1
        );
      }
      handleMoreInfo(idx, "team", true, html, lang);
    };

  const setSHMoreInfoRawHTML =
    (idx: number, lang: string) => (html: string) => {
      // setRdContext(html);
      // console.log("html", html);
      if (lang === "zh_hant") {
        setCurrentSHMoreInfoWord(
          html
            .replace(/&nbsp;/g, " ")
            .replace(/&amp;/g, "&")
            .replace(/<[^>]*>/g, "").length - 1
        );
      } else if (lang === "en") {
        setCurrentSHMoreInfoEngWord(
          html
            .replace(/&nbsp;/g, " ")
            .replace(/&amp;/g, "&")
            .replace(/<[^>]*>/g, "").length - 1
        );
      }
      handleMoreInfo(idx, "studentHelper", true, html, lang);
    };
  // double confirm delete function to pop up a dialog
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [currentSelectedCard, setCurrentSelectedCard] = useState({} as any);
  const [currentArrayNumber, setCurrentArrayNumber] = useState(null as number);

  const confirmDelete = (cardDetails: any, idx: number) => {
    setDeleteDialog(true);
    setCurrentSelectedCard(cardDetails);
    if (cardDetails.id != "") {
      setCurrentArrayNumber(idx);
    }
  };

  const deleteDialogClose = () => {
    setDeleteDialog(false);
  };

  // axios delete mutation
  const deleteCard = async () => {
    setIsOpenAlert(false);
    setIsLoading(true);
    // delete member who already created in database
    if (newCardArr.length > 0) {
      await autosave();
    }
    if (currentSelectedCard.id === "") {
      // console.log("async delete");
      const request = `{updateContentDetails(data: $data) {
        isDeleted
        id
      }}`;
      const graphqlQuery = {
        operationName: "Mutation",
        query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
        variables: {
          data: [
            {
              where: {
                id: currentSelectedCard.nameID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.nameEngID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.positionID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.positionEngID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.educationID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.educationEngID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.emailID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.phoneID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.moreInfoID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.moreInfoEngID,
              },
              data: { isDeleted: true },
            },
            {
              where: {
                id: currentSelectedCard.cardPositionID,
              },
              data: { isDeleted: true },
            },
          ],
        },
      };
      axios({
        url: endPoint,
        method: "post",
        data: graphqlQuery,
        headers: headers,
      })
        .then((res) => {})
        .catch((err) => console.error(err));
      if (currentSelectedCard.iconID != "") {
        const imageRequest = `{deleteImageDetail(where: $where) {
          id
        }}`;
        const imageGraphqlQuery = {
          operationName: "DeleteImageDetail",
          query: `mutation DeleteImageDetail($where: ImageDetailWhereUniqueInput!) ${imageRequest}`,
          variables: {
            where: { id: currentSelectedCard.iconID },
          },
        };
        axios({
          url: endPoint,
          method: "post",
          data: imageGraphqlQuery,
          headers: headers,
        })
          .then((res) => {
            setRender(!render);
          })
          .catch((err) => console.error(err));
      }
    } else {
      // deleted member who is not create in database
      let selectNewCardID: string;
      if (currentSelectedCard.id.includes("counsellor")) {
        selectNewCardID = counsellorList[currentArrayNumber].id;
        counsellorList.splice(currentArrayNumber, 1);
      } else if (currentSelectedCard.id.includes("team")) {
        selectNewCardID = teamList[currentArrayNumber].id;

        teamList.splice(currentArrayNumber, 1);
      }
      newCardArr.forEach((e: any, newCardIDX: number) => {
        if (e.newCardID === selectNewCardID) {
          newCardArr.splice(newCardIDX, 1);
        }
      });
    }
    setIsLoading(false);
    setIsDisabled(false);
    setDeleteDialog(false);
    setDeleteRender(true);
    dispatch(
      showMessage({
        message: `Member is deleted.`,
        autoHideDuration: 6000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        variant: "success",
      })
    );
  };

  // to open the word editor for more info
  const [moreInfo, setMoreInfo] = useState("");
  const [moreInfoEng, setMoreInfoEng] = useState("");
  const [cardPosition, setCardPosition] = useState("");
  const [idx, setIdx] = useState(0);

  function openEdit(
    moreInfoContent: string,
    moreInfoContentEng: string,
    position: string,
    idx: number
  ) {
    setMoreInfo(moreInfoContent);
    setMoreInfoEng(moreInfoContentEng);
    setCurrentCounsellorMoreInfoEngWord(
      moreInfoContentEng
        .replace(/&nbsp;/g, " ")
        .replace(/&amp;/g, "&")
        .replace(/<[^>]*>/g, "").length - 1
    );
    setCurrentCounsellorMoreInfoWord(
      moreInfoContent
        .replace(/&nbsp;/g, " ")
        .replace(/&amp;/g, "&")
        .replace(/<[^>]*>/g, "").length - 1
    );
    setCurrentTeamMoreInfoEngWord(
      moreInfoContentEng
        .replace(/&nbsp;/g, " ")
        .replace(/&amp;/g, "&")
        .replace(/<[^>]*>/g, "").length - 1
    );
    setCurrentTeamMoreInfoWord(
      moreInfoContent
        .replace(/&nbsp;/g, " ")
        .replace(/&amp;/g, "&")
        .replace(/<[^>]*>/g, "").length - 1
    );
    setCurrentSHMoreInfoEngWord(
      moreInfoContentEng
        .replace(/&nbsp;/g, " ")
        .replace(/&amp;/g, "&")
        .replace(/<[^>]*>/g, "").length - 1
    );
    setCurrentSHMoreInfoWord(
      moreInfoContent
        .replace(/&nbsp;/g, " ")
        .replace(/&amp;/g, "&")
        .replace(/<[^>]*>/g, "").length - 1
    );
    setCardPosition(position);
    setIdx(idx);
    setOpenWordEditor(true);
  }

  const handleCloseEdit = () => {
    setOpenWordEditor(false);
  };

  function openCard(element: any) {
    element.expand = true;
    setModified(!modified);
  }

  function closeCard(element: any) {
    element.expand = false;
    setModified(!modified);
  }

  function moveToTheTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  async function switchCardPriority(
    position: string,
    direction: string,
    cardIDX: number
  ) {
    setIsLoading(true);
    // console.log(newCardArr);
    if (newCardArr.length === 0) {
      if (position === "counsellors" && direction === "up") {
        // console.log(counsellorList[cardIDX]);
        // console.log(counsellorList[cardIDX - 1]);
        if (
          counsellorList[cardIDX - 1] &&
          counsellorList[cardIDX - 1].cardPositionID
        ) {
          if (
            counsellorList[cardIDX - 1] &&
            counsellorList[cardIDX - 1].cardPositionID != "" &&
            counsellorList[cardIDX].cardPositionID != ""
          ) {
            const currentCard = counsellorList[cardIDX];
            const previousCard = counsellorList[cardIDX - 1];
            if (currentCard.cardPosition === previousCard.cardPosition) {
              currentCard.cardPosition = currentCard.cardPosition + 0.1;
            }
            const request = `{updateContentDetails(data: $data){
              id,
              content
            }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
              variables: {
                data: [
                  {
                    where: { id: currentCard.cardPositionID },
                    data: {
                      content: `${previousCard.cardPosition}`,
                    },
                  },
                  {
                    where: { id: previousCard.cardPositionID },
                    data: {
                      content: `${currentCard.cardPosition}`,
                    },
                  },
                ],
              },
            };
            let contentPromise = await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });
            await Promise.all([contentPromise]);
            setRender(!render);
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          } else {
            // console.log("you are the top");
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          }
        } else {
          setIsLoading(false);
        }
      } else if (position === "counsellors" && direction === "down") {
        if (
          counsellorList[cardIDX + 1] &&
          counsellorList[cardIDX + 1].cardPositionID
        ) {
          if (
            counsellorList[cardIDX + 1] &&
            counsellorList[cardIDX + 1].cardPositionID != "" &&
            counsellorList[cardIDX].cardPositionID != ""
          ) {
            const currentCard = counsellorList[cardIDX];
            const previousCard = counsellorList[cardIDX + 1];
            if (currentCard.cardPosition === previousCard.cardPosition) {
              previousCard.cardPosition = previousCard.cardPosition + 0.1;
            }
            const request = `{updateContentDetails(data: $data){
              id,
              content
            }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
              variables: {
                data: [
                  {
                    where: { id: currentCard.cardPositionID },
                    data: {
                      content: `${previousCard.cardPosition}`,
                    },
                  },
                  {
                    where: { id: previousCard.cardPositionID },
                    data: {
                      content: `${currentCard.cardPosition}`,
                    },
                  },
                ],
              },
            };
            let contentPromise = await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });

            await Promise.all([contentPromise]);
            setRender(!render);
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          } else {
            // console.log("you are the bottom");
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          }
        } else {
          setIsLoading(false);
        }
      } else if (position === "team" && direction === "up") {
        if (teamList[cardIDX - 1] && teamList[cardIDX - 1].cardPositionID) {
          if (
            teamList[cardIDX - 1] &&
            teamList[cardIDX - 1].cardPositionID != "" &&
            teamList[cardIDX].cardPositionID != ""
          ) {
            // console.log("hello?");
            const currentCard = teamList[cardIDX];
            const previousCard = teamList[cardIDX - 1];
            if (currentCard.cardPosition === previousCard.cardPosition) {
              currentCard.cardPosition = currentCard.cardPosition + 0.1;
            }
            const request = `{updateContentDetails(data: $data){
              id,
              content
            }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
              variables: {
                data: [
                  {
                    where: { id: currentCard.cardPositionID },
                    data: {
                      content: `${previousCard.cardPosition}`,
                    },
                  },
                  {
                    where: { id: previousCard.cardPositionID },
                    data: {
                      content: `${currentCard.cardPosition}`,
                    },
                  },
                ],
              },
            };
            let contentPromise = await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });

            await Promise.all([contentPromise]);
            setRender(!render);
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          } else {
            // console.log("you are the top");
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          }
        } else {
          setIsLoading(false);
        }
      } else if (position === "team" && direction === "down") {
        if (teamList[cardIDX + 1] && teamList[cardIDX + 1].cardPositionID) {
          if (
            teamList[cardIDX + 1] &&
            teamList[cardIDX + 1].cardPositionID != "" &&
            teamList[cardIDX].cardPositionID != ""
          ) {
            const currentCard = teamList[cardIDX];
            const previousCard = teamList[cardIDX + 1];
            if (currentCard.cardPosition === previousCard.cardPosition) {
              previousCard.cardPosition = previousCard.cardPosition + 0.1;
            }
            const request = `{updateContentDetails(data: $data){
              id,
              content
            }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
              variables: {
                data: [
                  {
                    where: { id: currentCard.cardPositionID },
                    data: {
                      content: `${previousCard.cardPosition}`,
                    },
                  },
                  {
                    where: { id: previousCard.cardPositionID },
                    data: {
                      content: `${currentCard.cardPosition}`,
                    },
                  },
                ],
              },
            };
            let contentPromise = await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });
            // console.log(contentPromise);
            await Promise.all([contentPromise]);
            setRender(!render);
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          } else {
            // console.log("you are the top");
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          }
        } else {
          setIsLoading(false);
        }
      } else if (position === "studentHelper" && direction === "up") {
        if (
          studentHelperList[cardIDX - 1] &&
          studentHelperList[cardIDX - 1].cardPositionID
        ) {
          if (
            studentHelperList[cardIDX - 1] &&
            studentHelperList[cardIDX - 1].cardPositionID != "" &&
            studentHelperList[cardIDX].cardPositionID != ""
          ) {
            // console.log("hello?");
            const currentCard = studentHelperList[cardIDX];
            const previousCard = studentHelperList[cardIDX - 1];
            if (currentCard.cardPosition === previousCard.cardPosition) {
              currentCard.cardPosition = currentCard.cardPosition + 0.1;
            }
            const request = `{updateContentDetails(data: $data){
              id,
              content
            }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
              variables: {
                data: [
                  {
                    where: { id: currentCard.cardPositionID },
                    data: {
                      content: `${previousCard.cardPosition}`,
                    },
                  },
                  {
                    where: { id: previousCard.cardPositionID },
                    data: {
                      content: `${currentCard.cardPosition}`,
                    },
                  },
                ],
              },
            };
            let contentPromise = await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });

            await Promise.all([contentPromise]);
            setRender(!render);
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          } else {
            // console.log("you are the top");
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          }
        } else {
          setIsLoading(false);
        }
      } else if (position === "studentHelper" && direction === "down") {
        if (
          studentHelperList[cardIDX + 1] &&
          studentHelperList[cardIDX + 1].cardPositionID
        ) {
          if (
            studentHelperList[cardIDX + 1] &&
            studentHelperList[cardIDX + 1].cardPositionID != "" &&
            studentHelperList[cardIDX].cardPositionID != ""
          ) {
            const currentCard = studentHelperList[cardIDX];
            const previousCard = studentHelperList[cardIDX + 1];
            if (currentCard.cardPosition === previousCard.cardPosition) {
              previousCard.cardPosition = previousCard.cardPosition + 0.1;
            }
            const request = `{updateContentDetails(data: $data){
              id,
              content
            }}`;
            const graphqlQuery = {
              operationName: "Mutation",
              query: `mutation Mutation($data:[ContentDetailUpdateArgs!]!) ${request}`,
              variables: {
                data: [
                  {
                    where: { id: currentCard.cardPositionID },
                    data: {
                      content: `${previousCard.cardPosition}`,
                    },
                  },
                  {
                    where: { id: previousCard.cardPositionID },
                    data: {
                      content: `${currentCard.cardPosition}`,
                    },
                  },
                ],
              },
            };
            let contentPromise = await axios({
              url: endPoint,
              method: "post",
              data: graphqlQuery,
              headers: headers,
            });
            // console.log(contentPromise);
            await Promise.all([contentPromise]);
            setRender(!render);
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          } else {
            // console.log("you are the top");
            setIsLoading(false);
            setTimeout(() => {
              dispatch(
                showMessage({
                  message: `Card Position has changed`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  variant: "success",
                })
              );
              setIsLoading(false);
            }, 500);
          }
        } else {
          setIsLoading(false);
        }
      }
    } else if (newCardArr.length > 0) {
      await autosave();
      newCardArr.splice(0, newCardArr.length);
      // console.log(newCardArr);
      switchCardPriority(position, direction, cardIDX);
      setIsDisabled(false);
    }
  }

  async function addCardPosition(list: any) {
    // console.log(pageInfo);
    // console.log(list);
    setIsLoading(true);
    await Promise.all(
      list.map(async (listElement: any, idx: number) => {
        // console.log(listElement.category);
        // console.log(idx);
        const request = `{
          createContentDetail(data: $data) {
            priority
            position
            content
            category
            master {
              id
            }
          }
        }`;
        const graphqlQuery = {
          operationName: "CreateContentDetail",
          query: `mutation CreateContentDetail($data: ContentDetailCreateInput!) ${request}`,
          variables: {
            data: {
              content: `${+idx + 1}`,
              position: "cardPosition",
              category: listElement.category,
              master: {
                connect: { id: pageInfo.childContent[0].id },
              },
            },
          },
        };
        let result = await axios({
          url: endPoint,
          method: "post",
          data: graphqlQuery,
          headers: headers,
        });
        // console.log(result);
      })
    );
    setTimeout(() => {
      dispatch(
        showMessage({
          message: `Card Position has added`,
          autoHideDuration: 6000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        })
      );
      setIsLoading(false);
    }, 500);
  }

  return (
    <>
      {isOpenAlert && <AlertDialog message={popUpMsg} duration={3000} />}
      {/* <button onClick={() => addCardPosition(counsellorList)}>
        Counsellor
      </button>
      <button onClick={() => addCardPosition(teamList)}>team</button> */}
      <div>
        <Dialog
          open={openWordEditor}
          onClose={handleCloseEdit}
          fullWidth
          // style={{ width: "100px", height: "100px" }}
        >
          {cardPosition === "counsellor" && (
            <>
              <Typography
                sx={{ fontSize: 14, marginTop: "20px", marginLeft: "20px" }}
                color="black"
                gutterBottom
              >
                更多資訊 (繁中)
              </Typography>
              <EditorDraft
                onConvertToHtml={setCounsellorMoreInfoRawHTML(idx, "zh_hant")}
                htmlRawValue={moreInfo}
              />
              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Recommend maximum characters count : {Math.ceil(500 / 2.18)}
                </div>
                <div
                  style={{
                    color: "blue",
                    marginBottom: "5px",
                  }}
                >
                  Current characters count :{" "}
                  {currentCounsellorMoreInfoWord === -1
                    ? 0
                    : currentCounsellorMoreInfoWord}
                </div>
                <div style={{ color: "blue" }}>
                  ! Can be empty but Read More session will not be hidden
                </div>
              </div>
              <br />

              <Typography
                sx={{ fontSize: 14, marginTop: "20px", marginLeft: "20px" }}
                color="black"
                gutterBottom
              >
                Read More (English)
              </Typography>
              <EditorDraft
                onConvertToHtml={setCounsellorMoreInfoRawHTML(idx, "en")}
                htmlRawValue={moreInfoEng}
              />
              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Recommend maximum characters count : 500
                </div>
                <div
                  style={{
                    color: "blue",
                    marginBottom: "5px",
                  }}
                >
                  Current characters count :{" "}
                  {currentCounsellorMoreInfoEngWord === -1
                    ? 0
                    : currentCounsellorMoreInfoEngWord}
                </div>
                <div style={{ color: "blue" }}>
                  ! Can be empty but Read More session will not be hidden
                </div>
              </div>
            </>
          )}
          {cardPosition === "team" && (
            <>
              <Typography
                sx={{
                  fontSize: 14,
                  marginTop: "20px",
                  marginLeft: "20px",
                }}
                color="black"
                gutterBottom
              >
                更多資訊 (繁中)
              </Typography>

              <EditorDraft
                onConvertToHtml={setTeamMoreInfoRawHTML(idx, "zh_hant")}
                htmlRawValue={moreInfo}
              />
              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Recommend maximum characters count : {Math.ceil(500 / 2.18)}
                </div>
                <div
                  style={{
                    color: "blue",
                    marginBottom: "5px",
                  }}
                >
                  Current characters count :{" "}
                  {currentTeamMoreInfoWord === -1 ? 0 : currentTeamMoreInfoWord}
                </div>
                <div style={{ color: "blue" }}>
                  ! Can be empty but Read More session will not be hidden
                </div>
              </div>
              <br />
              <Typography
                sx={{
                  fontSize: 14,
                  marginTop: "20px",
                  marginLeft: "20px",
                }}
                color="black"
                gutterBottom
              >
                Read More (English)
              </Typography>

              <EditorDraft
                onConvertToHtml={setTeamMoreInfoRawHTML(idx, "en")}
                htmlRawValue={moreInfoEng}
              />
              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Recommend maximum characters count : 500
                </div>
                <div
                  style={{
                    color: "blue",
                    marginBottom: "5px",
                  }}
                >
                  Current characters count :{" "}
                  {currentTeamMoreInfoEngWord === -1
                    ? 0
                    : currentTeamMoreInfoEngWord}
                </div>
                <div style={{ color: "blue" }}>
                  ! Can be empty but Read More session will not be hidden
                </div>
              </div>
            </>
          )}
          {cardPosition === "studentHelper" && (
            <>
              <Typography
                sx={{
                  fontSize: 14,
                  marginTop: "20px",
                  marginLeft: "20px",
                }}
                color="black"
                gutterBottom
              >
                更多資訊 (繁中)
              </Typography>

              <EditorDraft
                onConvertToHtml={setSHMoreInfoRawHTML(idx, "zh_hant")}
                htmlRawValue={moreInfo}
              />
              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Recommend maximum characters count : {Math.ceil(500 / 2.18)}
                </div>
                <div
                  style={{
                    color: "blue",
                    marginBottom: "5px",
                  }}
                >
                  Current characters count :{" "}
                  {currentSHMoreInfoWord === -1 ? 0 : currentSHMoreInfoWord}
                </div>
                <div style={{ color: "blue" }}>
                  ! Can be empty but Read More session will not be hidden
                </div>
              </div>
              <br />
              <Typography
                sx={{
                  fontSize: 14,
                  marginTop: "20px",
                  marginLeft: "20px",
                }}
                color="black"
                gutterBottom
              >
                Read More (English)
              </Typography>

              <EditorDraft
                onConvertToHtml={setSHMoreInfoRawHTML(idx, "en")}
                htmlRawValue={moreInfoEng}
              />
              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "10px",
                }}
              >
                <div
                  style={{
                    marginBottom: "5px",
                    color: "blue",
                  }}
                >
                  Recommend maximum characters count : 500
                </div>
                <div
                  style={{
                    color: "blue",
                    marginBottom: "5px",
                  }}
                >
                  Current characters count :{" "}
                  {currentSHMoreInfoEngWord === -1
                    ? 0
                    : currentSHMoreInfoEngWord}
                </div>
                <div style={{ color: "blue" }}>
                  ! Can be empty but Read More session will not be hidden
                </div>
              </div>
            </>
          )}
        </Dialog>

        <Dialog
          open={isLoading}
          // onClose={handleCloseLoading}
          fullWidth
          // style={{ width: "100px", height: "100px" }}
        >
          <FuseLoading />
        </Dialog>
        {/* delete dialog */}
        <Dialog
          open={deleteDialog}
          onClose={deleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {currentSelectedCard.id === "" &&
            currentSelectedCard.category.includes("counsellor") && (
              <>
                <DialogTitle id="alert-dialog-title">{`Delete Counsellor`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Confirm Delete Counsellor.
                  </DialogContentText>
                </DialogContent>
              </>
            )}
          {currentSelectedCard.id === "" &&
            currentSelectedCard.category.includes("team") && (
              <>
                <DialogTitle id="alert-dialog-title">{`Delete Team Member`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Confirm Delete Team Member.
                  </DialogContentText>
                </DialogContent>
              </>
            )}
          {currentSelectedCard.id != "" &&
            currentSelectedCard.id &&
            currentSelectedCard.id.includes("counsellor") && (
              <>
                <DialogTitle id="alert-dialog-title">{`Delete Counsellor`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Confirm Delete Counsellor.
                  </DialogContentText>
                </DialogContent>
              </>
            )}
          {currentSelectedCard.id != "" &&
            currentSelectedCard.id &&
            currentSelectedCard.id.includes("team") && (
              <>
                <DialogTitle id="alert-dialog-title">{`Delete Team Member`}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Confirm Delete Team Member.
                  </DialogContentText>
                </DialogContent>
              </>
            )}

          <DialogActions>
            <Button onClick={deleteCard} autoFocus>
              Delete
            </Button>
            <Button onClick={deleteDialogClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
        {/* delete dialog */}

        <div
          style={{
            marginBottom: "20px",
            marginTop: "20px",
            marginLeft: "20px",
          }}
        >
          <h2>
            <b>Our Research Team</b>
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "20px",
            flexWrap: "wrap",
          }}
        >
          {teamList.map((e, idx) => {
            return (
              <div
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
                key={`team_${idx}`}
              >
                {!e.expand && (
                  <div>
                    <Card sx={{ width: "250px", height: "250px" }}>
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <OpenInFullIcon
                            style={{
                              cursor: "pointer",
                              marginBottom: "5px",
                            }}
                            onClick={() => openCard(e)}
                          ></OpenInFullIcon>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          {e.previewPhoto != "" && (
                            <Avatar
                              alt="Remy Sharp"
                              src={e.previewPhoto}
                              sx={{ width: 150, height: 150 }}
                            ></Avatar>
                          )}

                          {typeof e.icon === "string" && (
                            <Avatar
                              alt="Remy Sharp"
                              src={e.icon}
                              sx={{ width: 150, height: 150 }}
                            ></Avatar>
                          )}

                          <div style={{ marginTop: "20px" }}>
                            {e.fullNameEng}
                          </div>
                          <div style={{ marginTop: "10px" }}></div>
                        </div>
                      </CardContent>
                    </Card>
                    {e.id === "" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginTop: "10px",
                        }}
                      >
                        <WestIcon
                          onClick={() => switchCardPriority("team", "up", idx)}
                          style={{ cursor: "pointer" }}
                        />

                        <EastIcon
                          onClick={() =>
                            switchCardPriority("team", "down", idx)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {e.expand && (
                  <Card sx={{ minWidth: 450, maxWidth: 450 }}>
                    <div
                      style={{
                        minWidth: 450,
                        maxWidth: 450,
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                      }}
                    >
                      <CloseFullscreenIcon
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => closeCard(e)}
                      />
                      <CloseIcon
                        style={{
                          cursor: "pointer",
                          marginRight: 10,
                        }}
                        onClick={() => confirmDelete(e, idx)}
                      />
                    </div>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {typeof e.icon === "string" && (
                        <Avatar
                          alt="R"
                          src={e.icon}
                          sx={{ width: 150, height: 150 }}
                        ></Avatar>
                      )}
                      {e.previewPhoto != "" &&
                        e.previewPhoto != DefaultIcon && (
                          <Avatar
                            alt="Remy Sharp"
                            src={e.previewPhoto}
                            sx={{ width: 150, height: 150 }}
                          ></Avatar>
                        )}
                      {e.previewPhoto === DefaultIcon &&
                        e.icon != DefaultIcon && (
                          <Avatar
                            alt="Remy Sharp"
                            src={e.icon}
                            sx={{ width: 150, height: 150 }}
                          ></Avatar>
                        )}
                      <div style={{ marginTop: "10px" }}>
                        Suggested Ratio : 1:1
                      </div>

                      <div style={{ marginTop: "10px" }}>
                        Rendered Size : 157x157
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Button variant="contained" component="label">
                          Upload
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={(e) => handleFileSelect(e, "team", idx)}
                          />
                        </Button>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.nameID}
                        label="名字 (繁中)"
                        type="text"
                        name="fullName"
                        fullWidth
                        variant="standard"
                        value={e.fullName || ""}
                        onChange={handleInputChange(idx, "team")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : {"<"}{" "}
                          {Math.ceil(38 / 2.18)}{" "}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.fullName.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.nameEngID}
                        label="Name (English)"
                        type="text"
                        name="fullNameEng"
                        fullWidth
                        variant="standard"
                        value={e.fullNameEng || ""}
                        onChange={handleInputChange(idx, "team")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : {"<"} 38{" "}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.fullNameEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.positionID}
                        label="職位 (繁中)"
                        type="text"
                        name="position"
                        fullWidth
                        variant="standard"
                        value={e.position || ""}
                        onChange={handleInputChange(idx, "team")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : {"<"}{" "}
                          {Math.ceil(30 / 2.18)}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.position.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.positionEngID}
                        label="Position (English)"
                        type="text"
                        name="positionEng"
                        fullWidth
                        variant="standard"
                        value={e.positionEng || ""}
                        onChange={handleInputChange(idx, "team")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 30
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.positionEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.educationID}
                        label="學歷 (繁中)"
                        type="text"
                        name="education"
                        fullWidth
                        variant="standard"
                        value={e.education || ""}
                        onChange={handleInputChange(idx, "team")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count :{" "}
                          {Math.ceil(82 / 2.18)}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.education.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.educationEngID}
                        label="Education (English)"
                        type="text"
                        name="educationEng"
                        fullWidth
                        variant="standard"
                        value={e.educationEng || ""}
                        onChange={handleInputChange(idx, "team")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 82
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.educationEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.emailID}
                        label="Email"
                        type="text"
                        name="email"
                        fullWidth
                        variant="standard"
                        value={e.email || ""}
                        onChange={handleInputChange(idx, "team")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div style={{ color: "blue" }}>
                          Can empty and their corresponding elements will be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.phoneID}
                        label="Phone"
                        type="text"
                        name="phone"
                        fullWidth
                        variant="standard"
                        value={e.phone || ""}
                        onChange={handleInputChange(idx, "team")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div style={{ color: "blue" }}>
                          Can empty and their corresponding elements will be
                          hidden
                        </div>
                      </div>
                      <br />
                      <Button
                        onClick={() =>
                          openEdit(e.moreInfo, e.moreInfoEng, "team", idx)
                        }
                      >
                        Edit Read More
                      </Button>
                    </CardContent>
                  </Card>
                )}
              </div>
            );
          })}
          <Card
            sx={{
              minWidth: 250,
              maxWidth: 250,
              minHeight: 70,
              maxHeight: 70,
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                position: "relative",
                alignItems: "center",
              }}
            >
              <Button onClick={() => addTeam()}>
                Add Research Team Member
              </Button>
            </CardContent>
          </Card>
        </div>
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "20px",
          }}
        >
          <h2>
            <b>Our Counsellor</b>
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "20px",
            flexWrap: "wrap",
          }}
        >
          {counsellorList.map((e, idx) => {
            return (
              <div
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
                key={`counsellor_${idx}`}
              >
                {!e.expand && (
                  <div>
                    <Card sx={{ width: "250px", height: "250px" }}>
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <OpenInFullIcon
                            style={{
                              cursor: "pointer",
                              marginBottom: "5px",
                            }}
                            onClick={() => openCard(e)}
                          ></OpenInFullIcon>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          {e.previewPhoto != "" && (
                            <Avatar
                              alt="Remy Sharp"
                              src={e.previewPhoto}
                              sx={{ width: 150, height: 150 }}
                            ></Avatar>
                          )}

                          {typeof e.icon === "string" && (
                            <Avatar
                              alt="Remy Sharp"
                              src={e.icon}
                              sx={{ width: 150, height: 150 }}
                            ></Avatar>
                          )}

                          <div style={{ marginTop: "20px" }}>
                            {e.fullNameEng}
                          </div>
                          <div style={{ marginTop: "10px" }}></div>
                        </div>
                      </CardContent>
                    </Card>
                    {e.id === "" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginTop: "10px",
                        }}
                      >
                        <WestIcon
                          onClick={() =>
                            switchCardPriority("counsellors", "up", idx)
                          }
                          style={{ cursor: "pointer" }}
                        />

                        <EastIcon
                          onClick={() =>
                            switchCardPriority("counsellors", "down", idx)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {e.expand && (
                  <Card sx={{ minWidth: 450, maxWidth: 450 }}>
                    <div
                      style={{
                        minWidth: 450,
                        maxWidth: 450,
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                      }}
                    >
                      <CloseFullscreenIcon
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => closeCard(e)}
                      />
                      <CloseIcon
                        style={{
                          cursor: "pointer",
                          marginRight: 10,
                        }}
                        onClick={() => confirmDelete(e, idx)}
                      />
                    </div>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {typeof e.icon === "string" && (
                        <Avatar
                          alt="R"
                          src={e.icon}
                          sx={{ width: 150, height: 150 }}
                        ></Avatar>
                      )}
                      {e.previewPhoto != "" &&
                        e.previewPhoto != DefaultIcon && (
                          <Avatar
                            alt="Remy Sharp"
                            src={e.previewPhoto}
                            sx={{ width: 150, height: 150 }}
                          ></Avatar>
                        )}
                      {e.previewPhoto === DefaultIcon &&
                        e.icon != DefaultIcon && (
                          <Avatar
                            alt="Remy Sharp"
                            src={e.icon}
                            sx={{ width: 150, height: 150 }}
                          ></Avatar>
                        )}
                      <div style={{ marginTop: "10px" }}>
                        Suggested Ratio : 1:1
                      </div>

                      <div style={{ marginTop: "10px" }}>
                        Rendered Size : 157x157
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Button variant="contained" component="label">
                          Upload
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={(e) =>
                              handleFileSelect(e, "counsellor", idx)
                            }
                          />
                        </Button>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.nameID}
                        label="名字 (繁中)"
                        type="text"
                        name="fullName"
                        fullWidth
                        variant="standard"
                        value={e.fullName || ""}
                        onChange={handleInputChange(idx, "counsellor")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count :{" "}
                          {Math.ceil(38 / 2.18)}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.fullName.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.nameEngID}
                        label="Name (English)"
                        type="text"
                        name="fullNameEng"
                        fullWidth
                        variant="standard"
                        value={e.fullNameEng || ""}
                        onChange={handleInputChange(idx, "counsellor")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 38
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.fullNameEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.positionID}
                        label="職位 (繁中)"
                        type="text"
                        name="position"
                        fullWidth
                        variant="standard"
                        value={e.position || ""}
                        onChange={handleInputChange(idx, "counsellor")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count :{" "}
                          {Math.ceil(30 / 2.18)}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.position.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.positionEngID}
                        label="Position (English)"
                        type="text"
                        name="positionEng"
                        fullWidth
                        variant="standard"
                        value={e.positionEng || ""}
                        onChange={handleInputChange(idx, "counsellor")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 30
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.positionEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.educationID}
                        label="學歷 (繁中)"
                        type="text"
                        name="education"
                        fullWidth
                        variant="standard"
                        value={e.education || ""}
                        onChange={handleInputChange(idx, "counsellor")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count :{" "}
                          {Math.ceil(82 / 2.18)}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.education.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.educationEngID}
                        label="Education (English)"
                        type="text"
                        name="educationEng"
                        fullWidth
                        variant="standard"
                        value={e.educationEng || ""}
                        onChange={handleInputChange(idx, "counsellor")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 82
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.educationEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.emailID}
                        label="Email"
                        type="text"
                        name="email"
                        fullWidth
                        variant="standard"
                        value={e.email || ""}
                        onChange={handleInputChange(idx, "counsellor")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div style={{ color: "blue" }}>
                          Can empty and their corresponding elements will be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.phoneID}
                        label="Phone"
                        type="text"
                        name="phone"
                        fullWidth
                        variant="standard"
                        value={e.phone || ""}
                        onChange={handleInputChange(idx, "counsellor")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div style={{ color: "blue" }}>
                          Can empty and their corresponding elements will be
                          hidden
                        </div>
                      </div>
                      <br />
                      <Button
                        onClick={() =>
                          openEdit(e.moreInfo, e.moreInfoEng, "counsellor", idx)
                        }
                      >
                        Edit Read More
                      </Button>
                    </CardContent>
                  </Card>
                )}
              </div>
            );
          })}
          <Card
            sx={{
              minWidth: 250,
              maxWidth: 250,
              minHeight: 70,
              maxHeight: 70,
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                position: "relative",
                // top: 150,
                alignItems: "center",
              }}
            >
              <Button onClick={() => addCounsellor()}>Add Counsellor</Button>
            </CardContent>
          </Card>
        </div>
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "20px",
          }}
        >
          <h2>
            <b>Our Student Helper</b>
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "20px",
            flexWrap: "wrap",
          }}
        >
          {studentHelperList.map((e, idx) => {
            return (
              <div
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
                key={`studentHelper_${idx}`}
              >
                {!e.expand && (
                  <div>
                    <Card sx={{ width: "250px", height: "250px" }}>
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <OpenInFullIcon
                            style={{
                              cursor: "pointer",
                              marginBottom: "5px",
                            }}
                            onClick={() => openCard(e)}
                          ></OpenInFullIcon>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          {e.previewPhoto != "" && (
                            <Avatar
                              alt="Remy Sharp"
                              src={e.previewPhoto}
                              sx={{ width: 150, height: 150 }}
                            ></Avatar>
                          )}

                          {typeof e.icon === "string" && (
                            <Avatar
                              alt="Remy Sharp"
                              src={e.icon}
                              sx={{ width: 150, height: 150 }}
                            ></Avatar>
                          )}

                          <div style={{ marginTop: "20px" }}>
                            {e.fullNameEng}
                          </div>
                          <div style={{ marginTop: "10px" }}></div>
                        </div>
                      </CardContent>
                    </Card>
                    {e.id === "" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginTop: "10px",
                        }}
                      >
                        <WestIcon
                          onClick={() =>
                            switchCardPriority("studentHelper", "up", idx)
                          }
                          style={{ cursor: "pointer" }}
                        />

                        <EastIcon
                          onClick={() =>
                            switchCardPriority("studentHelper", "down", idx)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {e.expand && (
                  <Card sx={{ minWidth: 450, maxWidth: 450 }}>
                    <div
                      style={{
                        minWidth: 450,
                        maxWidth: 450,
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                      }}
                    >
                      <CloseFullscreenIcon
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => closeCard(e)}
                      />
                      <CloseIcon
                        style={{
                          cursor: "pointer",
                          marginRight: 10,
                        }}
                        onClick={() => confirmDelete(e, idx)}
                      />
                    </div>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {typeof e.icon === "string" && (
                        <Avatar
                          alt="R"
                          src={e.icon}
                          sx={{ width: 150, height: 150 }}
                        ></Avatar>
                      )}
                      {e.previewPhoto != "" &&
                        e.previewPhoto != DefaultIcon && (
                          <Avatar
                            alt="Remy Sharp"
                            src={e.previewPhoto}
                            sx={{ width: 150, height: 150 }}
                          ></Avatar>
                        )}
                      {e.previewPhoto === DefaultIcon &&
                        e.icon != DefaultIcon && (
                          <Avatar
                            alt="Remy Sharp"
                            src={e.icon}
                            sx={{ width: 150, height: 150 }}
                          ></Avatar>
                        )}
                      {/* {e.icon === DefaultIcon && e.previewPhoto === "" && (
                        <Avatar
                          alt="Remy Sharp"
                          src={DefaultIcon}
                          sx={{ width: 150, height: 150 }}
                        ></Avatar>
                      )} */}
                      <div style={{ marginTop: "10px" }}>
                        Suggested Ratio : 1:1
                      </div>

                      <div style={{ marginTop: "10px" }}>
                        Rendered Size : 157x157
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Button variant="contained" component="label">
                          Upload
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={(e) =>
                              handleFileSelect(e, "studentHelper", idx)
                            }
                          />
                        </Button>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.nameID}
                        label="名字 (繁中)"
                        type="text"
                        name="fullName"
                        fullWidth
                        variant="standard"
                        value={e.fullName || ""}
                        onChange={handleInputChange(idx, "studentHelper")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count :{" "}
                          {Math.ceil(38 / 2.18)}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.fullName.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.nameEngID}
                        label="Name (English)"
                        type="text"
                        name="fullNameEng"
                        fullWidth
                        variant="standard"
                        value={e.fullNameEng || ""}
                        onChange={handleInputChange(idx, "studentHelper")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 38
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.fullNameEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.positionID}
                        label="職位 (繁中)"
                        type="text"
                        name="position"
                        fullWidth
                        variant="standard"
                        value={e.position || ""}
                        onChange={handleInputChange(idx, "studentHelper")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count :{" "}
                          {Math.ceil(30 / 2.18)}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.position.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.positionEngID}
                        label="Position (English)"
                        type="text"
                        name="positionEng"
                        fullWidth
                        variant="standard"
                        value={e.positionEng || ""}
                        onChange={handleInputChange(idx, "studentHelper")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 30
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.positionEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.educationID}
                        label="學歷 (繁中)"
                        type="text"
                        name="education"
                        fullWidth
                        variant="standard"
                        value={e.education || ""}
                        onChange={handleInputChange(idx, "studentHelper")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count :{" "}
                          {Math.ceil(82 / 2.18)}
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.education.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.educationEngID}
                        label="Education (English)"
                        type="text"
                        name="educationEng"
                        fullWidth
                        variant="standard"
                        value={e.educationEng || ""}
                        onChange={handleInputChange(idx, "studentHelper")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "5px",
                            color: "blue",
                          }}
                        >
                          Recommend maximum characters count : 82
                        </div>
                        <div
                          style={{
                            color: "blue",
                            marginBottom: "5px",
                          }}
                        >
                          Current characters count : {e.educationEng.length}
                        </div>
                        <div style={{ color: "blue" }}>
                          ! Can be empty but their corresponding row will not be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.emailID}
                        label="Email"
                        type="text"
                        name="email"
                        fullWidth
                        variant="standard"
                        value={e.email || ""}
                        onChange={handleInputChange(idx, "studentHelper")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div style={{ color: "blue" }}>
                          Can empty and their corresponding elements will be
                          hidden
                        </div>
                      </div>
                      <TextField
                        autoFocus
                        margin="dense"
                        id={e.phoneID}
                        label="Phone"
                        type="text"
                        name="phone"
                        fullWidth
                        variant="standard"
                        value={e.phone || ""}
                        onChange={handleInputChange(idx, "studentHelper")}
                      ></TextField>
                      <div
                        style={{
                          marginTop: "15px",
                          marginLeft: "10px",
                        }}
                      >
                        <div style={{ color: "blue" }}>
                          Can empty and their corresponding elements will be
                          hidden
                        </div>
                      </div>
                      <br />
                      <Button
                        onClick={() =>
                          openEdit(
                            e.moreInfo,
                            e.moreInfoEng,
                            "studentHelper",
                            idx
                          )
                        }
                      >
                        Edit Read More
                      </Button>
                    </CardContent>
                  </Card>
                )}
              </div>
            );
          })}
          <Card
            sx={{
              minWidth: 250,
              maxWidth: 250,
              minHeight: 70,
              maxHeight: 70,
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                position: "relative",
                // top: 150,
                alignItems: "center",
              }}
            >
              <Button onClick={() => addStudentHelper()}>
                Add Student Helper
              </Button>
            </CardContent>
          </Card>
        </div>
        <Button
          variant="contained"
          component="label"
          onClick={moveToTheTop}
          style={{
            position: "fixed",
            bottom: "75px",
            right: "15px",
            // cursor: "pointer",
          }}
        >
          <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
        </Button>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            component="label"
            onClick={modifyAboutThisPage}
            disabled={isDisabled}
            style={{
              position: "fixed",
              bottom: "20px",
              right: "15px",
            }}
          >
            Save
          </Button>
        </div>
      </div>
      <br />
    </>
  );
};

export default OurCounsellorAndTeam;
