import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import SignInConfig from "../main/sign-in/SignInConfig";
import SignUpConfig from "../main/sign-up/SignUpConfig";
import SignOutConfig from "../main/sign-out/SignOutConfig";
import Error404Page from "../main/404/Error404Page";
import MainConfig from "../main/realMain/MainConfig";
import LoginConfig from "../main/realMain/Login/LoginConfig.tsx";
import ForgotPasswordConfig from "../main/realMain/ForgotPassword/ForgotPasswordConfig.tsx";

const routeConfigs = [
  MainConfig,
  SignOutConfig,
  SignInConfig,
  SignUpConfig,
  LoginConfig,
  ForgotPasswordConfig
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "/",
    element: <Navigate to="/login" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "*",
    element: <Navigate to="404" />,
  },
];

export default routes;
